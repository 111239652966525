import { Box, Button, Fade, Flex, Input, Text } from '@chakra-ui/react';
import { Table } from 'antd';
import axios from 'axios';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function BGUWorkShiftsEdit() {
    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Бренд генераторв',
            dataIndex: 'generator_brand',
            key: 'generator_brand'
        },
        {
            title: 'Сайт',
            dataIndex: 'siteId',
            key: 'siteId'
        },
        {
            title: 'Начало смены(дата)',
            dataIndex: 'bgu_start',
            key: 'bgu_start'
        },
        {
            title: 'Конец смены(дата)',
            dataIndex: 'bgu_finish',
            key: 'bgu_finish'
        },
        {
            title: '',
            key: 'key',
            dataIndex: 'key',
            render: (text: any, record: any) => (
                <Link to={`bgu-work-shift/${record.id}`}>
                    <Button
                        style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}
                        colorScheme="purple"
                    >
                        Редактировать
                    </Button>
                </Link>
            )
        }
    ];

    const [data, setData] = useState<Array<any>>([]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [generatorBrand, setgeneratorBrand] = useState('');
    const [userName, setUserName] = useState('');
    const [date, setDate] = useState('');

    const debouncedUserName = useDebounce(userName, 500);

    const debouncedGeneratorBrand = useDebounce(generatorBrand, 500);

    const debouncedDate = useDebounce(date, 500);

    const getData = async () => {
        try {
            setIsLoading(true);

            let req = `/bgu-worksheefts/count?_start=${start}&_limit=10&_sort=created_at:DESC`;

            if (debouncedGeneratorBrand !== '')
                req += `&_where[bgu.generator_brand_contains]=${debouncedGeneratorBrand}`;

            if (debouncedUserName !== '') req += `&_where[user.full_name_contains]=${debouncedUserName}`;

            if (debouncedDate !== '')
                req = req
                    .split('&_sort=created_at:DESC')
                    .join(
                        `&_sort=created_at:DESC&_where[bgu_start_gte]=${moment(debouncedDate).format(
                            'YYYY-MM-DD'
                        )}&_where[bgu_start_lte]=${moment(debouncedDate).add(1, 'days').format('YYYY-MM-DD')}`
                    );

            const [count, bguWorkShifts] = await Promise.all<[number, Array<any>]>([
                (await axios.get(req)).data,
                (await axios.get(req.split('count').join('find-without-pagination'))).data
            ]);

            const tmpData = bguWorkShifts.map((item: any) => ({
                name: item.user.full_name,
                generator_brand: item.bgu.generator_brand,
                siteId: item.siteID,
                bgu_start: moment(item.bgu_start).format('DD.MM.YYYY HH:mm'),
                bgu_finish: item.bgu_finish
                    ? moment(item.bgu_finish).format('DD.MM.YYYY HH:mm')
                    : 'Смена не закрыта',
                id: item.id
            }));
            setData(tmpData);
            setCount(count);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [start, debouncedGeneratorBrand, debouncedUserName, debouncedDate]);

    const onPaginationChange = (page: number) => {
        setStart((page - 1) * 10);
    };
    const onPageSizeChange = (current: number) => {
        setStart((current - 1) * 10);
    };

    return (
        <Fade in>
            <Table
                bordered
                loading={isLoading}
                scroll={{ x: true }}
                pagination={{
                    pageSize: 10,
                    total: count || 1,
                    onChange: onPaginationChange,
                    onShowSizeChange: onPageSizeChange
                }}
                title={() => (
                    <Flex alignItems="center">
                        <Text fontSize="md" fontWeight={600}>
                            Список ГСМ ДГУ
                        </Text>
                        <Input
                            type="text"
                            placeholder="Имя пользователя"
                            maxWidth="250"
                            ml="10"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                        <Input
                            type="text"
                            placeholder="Бренд генератора"
                            maxWidth="250"
                            ml="10"
                            value={generatorBrand}
                            onChange={(e) => setgeneratorBrand(e.target.value)}
                        />
                        <Box ml="10" display="flex" alignItems="center">
                            <Text fontSize="md" fontWeight={600} minWidth="170">
                                Дата начала смены:
                            </Text>
                            <Input
                                type="date"
                                maxWidth="250"
                                value={moment(date).format('YYYY-MM-DD')}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </Box>
                    </Flex>
                )}
                dataSource={data}
                columns={columns}
            />
        </Fade>
    );
}
