import { ChangeEvent, FC, HTMLInputTypeAttribute } from 'react';
import { Box, Input, Text } from '@chakra-ui/react';

interface FormInputFieldProps {
    value: string;
    name: string;
    label: string;
    isRequired: boolean;
    isDisabled: boolean;
    error?: string;
    type?: HTMLInputTypeAttribute;
    placeholder?: string;
    autoFocus?: boolean;
    autoComplete?: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * @component
 * @param { string } value Input value
 * @param { string } name Input name and id
 * @param { string } label Label showed above the input
 * @param { boolean } isRequired If true will show red asterisk after label
 * @param { boolean } isDisabled If true input will be disabled
 * @param { string } error Error showed below the input
 * @param { HTMLInputTypeAttribute } type HTML input type
 * @param { string } placeholder
 * @param { boolean } autoFocus
 * @param { string } autoComplete HTML input autocomplete type
 * @param { (e: ChangeEvent<HTMLInputElement>) => void } handleChange Formik handleChange function
 */
const FormInputField: FC<FormInputFieldProps> = ({
    value,
    name,
    label,
    isRequired,
    isDisabled,
    error,
    type,
    placeholder,
    autoFocus,
    autoComplete,
    handleChange
}) => (
    <Box>
        <Text as="label" display="flex" mb="3px" htmlFor={name} cursor="pointer">
            {label}
            {isRequired && <Text color="red.500">*</Text>}
        </Text>
        <Input
            value={value}
            id={name}
            name={name}
            placeholder={placeholder}
            disabled={isDisabled}
            type={type}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            onChange={handleChange}
        />
        {error && (
            <Text mt={1} color="red">
                {error}
            </Text>
        )}
    </Box>
);

export default FormInputField;
