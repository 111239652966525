import { useState } from 'react';
import axios from 'axios';
// chakra-ui
import { Textarea, Text, Input, Button, List, ListItem, useToast, Box } from '@chakra-ui/react';
// antd
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

const Comment = ({ nextStep, handleChange, values }: any) => (
    <>
        <Text fontWeight="medium" mb="0.5rem">
            Введите комментарий:
        </Text>
        <Textarea
            paddingInline="1rem"
            paddingY="8px"
            defaultValue={values.comment}
            onChange={(e) => handleChange('comment', e.target.value)}
        />
        <Box textAlign="right" mt="1rem" mb="0.5rem">
            <Button colorScheme="purple" onClick={() => nextStep()}>
                Далее
            </Button>
        </Box>
    </>
);

const Confirmation = ({ prevStep, values, onConfirm, loading }: any) => (
    <>
        <Text fontWeight="medium">Информация о ключе:</Text>
        <List mt={1}>
            <ListItem>Выдано для: {values.certInfo.fieldcert.WhomIssued.replace('CN=', '')}</ListItem>
            <ListItem>Страна: {values.certInfo.fieldcert.Country.replace('C=', '')}</ListItem>
            <ListItem>Дата выдачи: {values.certInfo.fieldcert.Date1.replace('notBefore=', '')}</ListItem>
            <ListItem>Дата истечения: {values.certInfo.fieldcert.Date2.replace('notAfter=', '')}</ListItem>
            <ListItem>Кем выдано: {values.certInfo.fieldcert.IssuedBy.replace('CN=', '')}</ListItem>
        </List>
        <Box textAlign="right" mt="1rem" mb="0.5rem">
            <Button disabled={loading} onClick={() => prevStep()} mr="0.75rem">
                Назад
            </Button>
            <Button isLoading={loading} disabled={loading} onClick={onConfirm} colorScheme="purple">
                Подписать
            </Button>
        </Box>
    </>
);

const Upload = ({ prevStep, nextStep, handleChange, values }: any) => {
    const [loading, setLoading] = useState(false);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const next = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('file', values.esign.file);
        formData.append('password', values.password);

        axios
            .post('/esigns/createSignNew', formData)
            .then((res) => {
                handleChange('certInfo', res.data);
                nextStep();
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                toast({
                    status: 'error',
                    title: err.response.data.message
                });
                setLoading(false);
            });
    };

    return (
        <>
            <Box>
                <Dragger
                    defaultFileList={values.esign?.fileList || []}
                    disabled={loading}
                    name="file"
                    onChange={(info) => {
                        if (!info.fileList.length) {
                            handleChange('esign', null);
                        } else {
                            handleChange('esign', info);
                        }
                    }}
                    maxCount={1}
                    beforeUpload={() => false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Загрузите ключ ЭЦП</p>
                </Dragger>
                {!!values.esign && (
                    <Box mt="1rem">
                        <Text fontWeight="medium" mb="0.5rem">
                            Введите пароль от ключа ЭЦП:
                        </Text>
                        <Input
                            autoComplete="new-password"
                            placeholder="Пароль"
                            onChange={(e) => handleChange('password', e.target.value)}
                            type="password"
                            defaultValue={values.password}
                        />
                    </Box>
                )}
            </Box>
            <Box textAlign="right" mt="1rem" mb="0.5rem">
                <Button disabled={loading} onClick={() => prevStep()} mr="0.75rem">
                    Назад
                </Button>
                <Button
                    colorScheme="purple"
                    isLoading={loading}
                    disabled={loading || !values.esign}
                    onClick={next}
                >
                    Подтвердить
                </Button>
            </Box>
        </>
    );
};

interface SignProps {
    workId: string | number;
    field: string;
    onSign: Function;
}

export default function WorkSign({ workId, field, onSign }: SignProps) {
    const [step, setStep] = useState(1);

    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState<any>({
        comment: '',
        esign: null,
        password: '',
        certInfo: null
    });

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const handleChange = (input: string, value: any) => {
        setValues({ ...values, [input]: value });
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const onConfirm = async () => {
        setLoading(true);
        try {
            const data = { work_id: workId, value: 1, field, comment: values.comment };
            await axios.post('/works/makeDecision', data);

            const res = await axios.get(`/works/${workId}`);
            const matListsId = res.data.data.mat_lists.id;
            await axios.post('/esigns/signDocs', {
                data: values.certInfo,
                cert: values.certInfo.cert,
                password: values.password,
                id: matListsId
            });

            setLoading(false);
            toast({
                status: 'success',
                title: 'Успешно подписано'
            });
            onSign();
        } catch (err) {
            console.error(err);
            setLoading(false);
            toast({
                status: 'error',
                title: 'Произошла ошибка'
            });
        }
    };

    return step === 1 ? (
        <Comment nextStep={nextStep} handleChange={handleChange} values={values} />
    ) : step === 2 ? (
        <Upload prevStep={prevStep} nextStep={nextStep} handleChange={handleChange} values={values} />
    ) : step === 3 ? (
        <Confirmation
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange}
            values={values}
            onConfirm={onConfirm}
            loading={loading}
        />
    ) : null;
}
