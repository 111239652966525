// eslint-disable-next-line import/prefer-default-export
export { default as siteService } from './site-service';
export { default as reportService } from './report-service';
export { default as workService } from './work-service';
export { default as orderService } from './order-service';
export { default as actService } from './act-service';
export { default as reviewService } from './review-service';
export { default as rsdService } from './rsd-service';
export { default as equipBalancesService } from './equip-balances-service';
export { default as equipmentsService } from './equipments';
export { default as matListsService } from './matLists.service';
export { default as matListTrsService } from './matListTrs.service';
export { default as ordersProjectsService } from './ordersProjects.service';
export { default as subcsService } from './subcs-service';
