// @chakra-ui
import { Stack, Box, Heading, Text, Link } from '@chakra-ui/react';
// components
import DecisionHistory from 'components/DecisionHistory';
//
import WorkRltTssrFiles from './WorkRltTssrFiles';
import WorkRltTssrStatus from './WorkRltTssrStatus';

// -----------------------------------------------------------------------

interface WorkRltTssrProps {
    work: any;
    rsd: any;
    updateWork: () => void;
}

export default function WorkRltTssr({ work, rsd, updateWork }: WorkRltTssrProps) {
    return (
        <Box>
            <Text p={1.5} fontSize="sm" fontStyle="italic" color="green" lineHeight={1.5} width="full">
                Инструкции по названию файлов TSSR:
                <br />
                TSSR_Название сайта_RO Дополнительная информация_vНомер версии документа.xlsx
                <br />
                Пример - TSSR_UK9152_ROT_GU_v4.xlsx
                <br />
                Дополнительная информация - опционально.
                <Text fontWeight="bold">
                    Ожидаемое название документа для ЭАП - TSSR_{work.s_new_site_id}_RO_Дополнительная
                    информация_vНомер версии документа
                </Text>
            </Text>
            <WorkRltTssrFiles work={work} updateWork={updateWork} />
            <WorkRltTssrStatus work={work} updateWork={updateWork} />
            <Box p={1.5}>
                <DecisionHistory history={work.s_tssr_status_cmnt || ''} />
            </Box>
            <Stack fontSize="sm" width="full" p={1.5}>
                <Heading as="h4" fontSize="md" textAlign="center">
                    RSD
                </Heading>
                {rsd.id ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '6px'
                        }}
                    >
                        <Link
                            color="blue"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                                rsd?.from_sad
                                    ? `https://sad.tele2.kz/tele2${rsd.doc_link.replace('.', '', 1)}`
                                    : `${process.env.REACT_APP_API_BASE_URL}${rsd.doc_link}`
                            }
                            key={rsd.id}
                            style={{ marginBottom: '8px' }}
                        >
                            {rsd.file_name}
                        </Link>
                    </div>
                ) : (
                    <Text>RSD не загружен</Text>
                )}
            </Stack>
            <Stack fontSize="sm" width="full" p={1.5}>
                <Heading as="h4" fontSize="md" textAlign="center">
                    TSD
                </Heading>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '6px' }}>
                    {work.s_tsd?.esigns ? (
                        work.s_tsd.esigns.map((link: any) => (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '6px'
                                }}
                            >
                                <Link
                                    color="blue"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        link?.from_sad
                                            ? `https://sad.tele2.kz/tele2${link.doc_link.replace('.', '', 1)}`
                                            : `${process.env.REACT_APP_API_BASE_URL}${link.doc_link}`
                                    }
                                    key={link.id}
                                    style={{ marginBottom: '8px' }}
                                >
                                    {link.file_name}
                                </Link>
                            </div>
                        ))
                    ) : (
                        <Text>TSD не загружен</Text>
                    )}
                </div>
            </Stack>
        </Box>
    );
}
