import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, usePagination, Column } from 'react-table';
import { TableContainer, Flex, Spinner, Link as ChakraLink, Box, Badge } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { fetcher } from 'utils/client';
import createQueryString from 'utils/createQueryString';
import { userSelector } from 'redux/reducers/userSlice';
import TableCustom from 'components/react-table/TableCustom';
import TablePagination from 'components/react-table/TablePagination';
import LoadingOverlay from 'components/LoadingOverlay';
import useFilter from 'hooks/useFilter';
import { orderStatusesMap, orderWorkStatusesMap } from 'Routes/Order/utils/maps';
import { EOrderStatuses, EOrderWorkStatuses } from 'Routes/Order/utils/enums';

interface IProps {
    siteId: string;
}

const SiteOrderList: React.FC<IProps> = ({ siteId }) => {
    const userData = useSelector(userSelector);
    const subc = userData.role?.type === 'other' ? userData.data?.subc?.id : null;

    const [isLoading, setIsLoading] = useState(false);
    const [parametersOrders, setParametersOrders] = useState('');
    const { data: orders } = useSWR(parametersOrders ? `/orders?${parametersOrders}` : null, fetcher);

    const [parametersTotalOrders, setParametersTotalOrders] = useState('');
    const { data: totalOrders } = useSWR(
        parametersTotalOrders ? `/orders/count?${parametersTotalOrders}` : `/orders/count`,
        fetcher
    );

    const [_pageCount, setPageCount] = useState(0);

    const data = useMemo(() => orders, [orders]);

    const getColumns = () => {
        const columns = [
            {
                accessor: 'id',
                Header: 'ID заказа',
                Cell: ({ cell: { value } }: any) => (
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        as={Link}
                        target="_blank"
                        className="order-link"
                        to={`/orders/${value}`}
                    >
                        {value}
                    </ChakraLink>
                )
            },
            {
                accessor: 'work.site.s_reg',
                Header: 'Регион'
            },
            {
                accessor: 'code',
                Header: 'Код заказа'
            },
            {
                accessor: 'work.site_id',
                Header: 'ID сайта'
            },
            {
                accessor: 'work.sitename',
                Header: 'sitename'
            },
            {
                accessor: 'work.id',
                Header: 'ID ЭАП',
                Cell: ({ cell: { value } }: any) => (
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        as={Link}
                        target="_blank"
                        to={`/works/${value}`}
                    >
                        {value}
                    </ChakraLink>
                )
            },
            {
                accessor: 'status',
                Header: 'Статус заказа',
                Cell: ({ cell: { value } }: { cell: { value: EOrderStatuses } }) => (
                    <Badge colorScheme={orderStatusesMap[value]?.color || ''}>
                        {orderStatusesMap[value]?.label || value}
                    </Badge>
                )
            },
            {
                accessor: 'workStatus',
                Header: 'Статус работы',
                Cell: ({ cell: { value } }: { cell: { value: EOrderWorkStatuses } }) => (
                    <Badge colorScheme={orderWorkStatusesMap[value]?.color || ''}>
                        {orderWorkStatusesMap[value]?.label || value}
                    </Badge>
                )
            },
            {
                accessor: 'work.work_type',
                Header: 'Тип ЭАП',
                Cell: ({ cell: { value } }: any) => <Box whiteSpace="break-spaces">{value}</Box>
            },
            {
                accessor: 'workType.title',
                Header: 'Тип работ'
            },
            {
                accessor: 'updated_at',
                Header: 'Дата изменения',
                Cell: ({ cell: { value } }: any) => new Date(value).toLocaleString('ru')
            }
        ];

        if (!subc) {
            const newColumn: any = {
                accessor: 'subc.name',
                disableSortBy: true,
                Header: 'Подрядчик'
            };
            columns.splice(3, 0, newColumn);
        }
        return columns;
    };

    const columns = useMemo(() => getColumns(), []) as Column[];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            data: data || [],
            columns,
            initialState: {
                pageIndex: 0,
                sortBy: [{ id: 'updated_at', desc: true }]
            },
            stateReducer(newState, action) {
                if (action.type === 'toggleSortBy') {
                    return { ...newState, pageIndex: 0 };
                }
                return newState;
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount: _pageCount,
            autoResetPage: false,
            autoResetSortBy: false
        },
        useSortBy,
        usePagination
    );

    const { filter, debouncedFilter } = useFilter({
        onChangeCallback: () => {
            gotoPage(0);
        }
    });

    const getOrdersSWR = () => {
        const params: any = {
            '_start': pageSize * pageIndex,
            '_limit': pageSize,
            '_sort': sortBy.length ? `${sortBy[0].id}:${sortBy[0].desc ? 'DESC' : 'ASC'}` : null,
            'work.site_id_eq': siteId,
            ...debouncedFilter
        };

        const queryString = createQueryString(params);
        const countQueryString = createQueryString({ 'work.site_id': siteId, ...filter });

        setParametersOrders(queryString);
        setParametersTotalOrders(countQueryString);
    };

    useEffect(() => {
        getOrdersSWR();
    }, [pageIndex, pageSize, sortBy, debouncedFilter]);

    useEffect(() => {
        if (orders) {
            setIsLoading(false);
        }
    }, [orders]);

    useEffect(() => {
        if (totalOrders) {
            setPageCount(Math.ceil(totalOrders / pageSize));
        }
    }, [totalOrders]);

    return (
        <LoadingOverlay
            Spinner={<Spinner color="blue.500" />}
            active={isLoading}
            styles={{
                wrapper: (base) => ({
                    ...base,
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column'
                })
            }}
        >
            <Flex h="100%" direction="column" fontSize="initial">
                <TableContainer
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        height: '100%',
                        padding: 1.5
                    }}
                >
                    <TableCustom
                        size="sm"
                        getTableProps={getTableProps}
                        headerGroups={headerGroups}
                        getTableBodyProps={getTableBodyProps}
                        page={page}
                        prepareRow={prepareRow}
                    />
                </TableContainer>

                <TablePagination
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    pageCount={pageCount}
                    previousPage={previousPage}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pageSizeOptions={[5, 10, 25]}
                    setPageSize={setPageSize}
                />
            </Flex>
        </LoadingOverlay>
    );
};

export default SiteOrderList;
