import {
    Badge,
    Button,
    Flex,
    Grid,
    GridItem,
    Input,
    InputGroup,
    InputLeftElement,
    Select
} from '@chakra-ui/react';
import React, { ChangeEventHandler, FC } from 'react';
import { Search2Icon } from '@chakra-ui/icons';
import HasAccess from 'guards/HasAccess';
import HasNotAccess from 'guards/HasNotAccess';
import { ERoleNames, TAG_FILTERS_MAP } from '../constants';

interface IInputElement {
    type: 'input';
    placeholder: string;
    value: string;
    name: string;
}

interface ISelectElement {
    type: 'select';
    name: string;
    value: string;
    emptyValue: string;
    options: Array<{ value: string; label: string }>;
    accessControl?: {
        type: 'HasAccess' | 'HasNotAccess';
        roles: ERoleNames[];
    };
}

export type TFilterElement = IInputElement | ISelectElement;

interface IProps {
    filterElements: TFilterElement[];
    onChangeFilter: ChangeEventHandler<Element>;
    readonly?: boolean;
    filters: { [key: string]: string };
    onReset?: () => void;
}

// TODO сделать FilterContext
const Filters: FC<IProps> = ({ onReset, filterElements, onChangeFilter, readonly, filters }) => (
    <div>
        <Grid w="100%" templateColumns="repeat(4, 1fr)" gap={2}>
            {filterElements.map((element, index) =>
                !readonly || element.value ? (
                    <GridItem key={index}>
                        {element.type === 'input' ? (
                            <InputGroup size="sm">
                                <InputLeftElement pointerEvents="none">
                                    <Search2Icon color="gray.300" />
                                </InputLeftElement>
                                <Input
                                    disabled={readonly}
                                    placeholder={element.placeholder}
                                    defaultValue={element.value}
                                    name={element.name}
                                    onChange={onChangeFilter}
                                />
                            </InputGroup>
                        ) : element.type === 'select' ? (
                            (() => {
                                if (readonly && !element.value) return null;

                                const selectComponent = (
                                    <Select
                                        size="sm"
                                        onChange={onChangeFilter}
                                        value={element.value || ''}
                                        name={element.name}
                                        disabled={readonly}
                                    >
                                        <option selected value="">
                                            {element.emptyValue}
                                        </option>
                                        {element.options.map((option, idx) => (
                                            <option key={idx} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                );

                                if (element.accessControl) {
                                    return element.accessControl.type === 'HasNotAccess' ? (
                                        <HasNotAccess roleNames={element.accessControl.roles}>
                                            {selectComponent}
                                        </HasNotAccess>
                                    ) : (
                                        <HasAccess roleNames={element.accessControl.roles}>
                                            {selectComponent}
                                        </HasAccess>
                                    );
                                }
                                return selectComponent;
                            })()
                        ) : null}
                    </GridItem>
                ) : null
            )}
        </Grid>
        <Flex flexWrap="wrap" gap={1}>
            {Object.entries(filters).map(([key, value]) => {
                if (key in TAG_FILTERS_MAP && value in TAG_FILTERS_MAP[key]) {
                    return (
                        <Badge my={1} p={0.5}>
                            {TAG_FILTERS_MAP[key][value]}
                        </Badge>
                    );
                }
                return null;
            })}
        </Flex>
        {Object.keys(filters).length > 0 && onReset && (
            <div>
                <Button size="xs" mt={1} onClick={onReset} variant="outline">
                    Сбросить фильтры
                </Button>
            </div>
        )}
    </div>
);
export default Filters;
