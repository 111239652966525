import axios from 'axios';
import { useState } from 'react';

import {
    Text,
    Modal as ChakraModal,
    Button as ChakraButton,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Textarea,
    ModalFooter,
    useToast
} from '@chakra-ui/react';

interface ActDeclienModalProps {
    onClose: () => void;
    updateData: () => void;
    isOpen: boolean;
    actId: number | null;
}

export default function ActDeclineModal({ onClose, isOpen, updateData, actId }: ActDeclienModalProps) {
    const [isDecisionPending, setIsDecisionPending] = useState(false);
    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    return (
        <ChakraModal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Комментирование отказа</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={1}>Комментарий</Text>
                    <Textarea id="commentsTextarea" paddingInlineStart={2} paddingInlineEnd={2} />
                </ModalBody>
                <ModalFooter>
                    <ChakraButton colorScheme="purple" mr="auto" onClick={onClose}>
                        Закрыть
                    </ChakraButton>
                    <ChakraButton
                        colorScheme="red"
                        isLoading={isDecisionPending}
                        loadingText="Обработка..."
                        onClick={async () => {
                            setIsDecisionPending(true);
                            if (
                                // @ts-ignore
                                !document.getElementById('commentsTextarea')?.value
                            ) {
                                toast({
                                    status: 'error',
                                    title: 'Заполните комментарий при отказе!'
                                });
                                setIsDecisionPending(false);
                            } else {
                                await axios
                                    .put(`/acts/rejectAgreedAct/${actId}`, {
                                        // @ts-ignore
                                        comments: document.getElementById('commentsTextarea').value
                                    })
                                    .then(() => {
                                        updateData();
                                        toast({
                                            title: 'Заказ успешно отклонен',
                                            status: 'success'
                                        });
                                        onClose();
                                    })
                                    .catch((err) => {
                                        toast({
                                            title: err.response.data.message,
                                            status: 'error'
                                        });
                                    })
                                    .finally(() => {
                                        setIsDecisionPending(false);
                                    });
                            }
                        }}
                    >
                        Отклонить
                    </ChakraButton>
                </ModalFooter>
            </ModalContent>
        </ChakraModal>
    );
}
