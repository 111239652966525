// form
import { Formik, Form } from 'formik';

// ----------------------------------------------------------------------

export default function FormProvider({ initialValues, children, onSubmit, innerRef }: any) {
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} innerRef={innerRef}>
            <Form>{children}</Form>
        </Formik>
    );
}
