import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import CreateGenerator from 'components/GSM/CreateGenerator';
import FuelRemainders from 'components/GSM/FuelRemainders';
import CreateCar from 'components/GSM/СreateCar';
import CreateFuelCards from 'components/GSM/СreateFuelCards';
import useHeaderTitle from 'hooks/useHeaderTitle';

const screens = ['Карта', 'Авто', 'Генератор', 'Канистра'];

export default function CreateCarAndFuelCards() {
    useHeaderTitle(`Регистрация топливных карт, авто и генераторов`);

    return (
        <Box>
            <Box display="flex" justifyContent="center" mt="4">
                <Tabs
                    variant="soft-rounded"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    colorScheme="purple"
                >
                    <TabList>
                        {screens.map((item) => (
                            <Tab>{item}</Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <CreateFuelCards />
                        </TabPanel>
                        <TabPanel>
                            <CreateCar />
                        </TabPanel>
                        <TabPanel>
                            <CreateGenerator />
                        </TabPanel>
                        <TabPanel>
                            <FuelRemainders />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    );
}
