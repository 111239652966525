import React, { FC } from 'react';
import { Badge } from '@chakra-ui/react';
import { IOrderProject } from '../OrderProject';

interface IProps {
    project: IOrderProject;
}

const OrderEquipment: FC<IProps> = ({ project }) => (
    <>
        {project.isEquipmentRequired ? (
            <Badge colorScheme="purple">С оборудованием</Badge>
        ) : (
            <Badge colorScheme="green">Без оборудования</Badge>
        )}
    </>
);

export default OrderEquipment;
