import { Button, ButtonGroup } from '@chakra-ui/react';
import { FC } from 'react';
import HasAccess from 'guards/HasAccess';
import { ERoleNames } from '../../../../constants';
import { TMatListStatuses } from '../../utils/types/MatList';

interface IProps {
    matListStatus: TMatListStatuses;
    onAddEquipment: () => void;
    isDisabledApprove?: boolean;
    hasEditEquipmentRoles: ERoleNames[];
    isDisabledStartProcess?: boolean;
    isDisabledReject?: boolean;
    isDisabled?: boolean;
    onApprove: () => void;
    onReject: () => void;
}

enum EActionType {
    Approve,
    Reject,
    StartProcess
}

const matListActionButtons: FC<IProps> = ({
    onApprove,
    matListStatus,
    isDisabledStartProcess,
    onAddEquipment,
    hasEditEquipmentRoles,
    isDisabledReject,
    onReject,
    isDisabled,
    isDisabledApprove
}) => {
    const getApproveRoles = (actionType: EActionType) => {
        const rolesMap: Partial<Record<TMatListStatuses, ERoleNames[]>> = {
            created: actionType === EActionType.StartProcess ? [ERoleNames.SUBC, ERoleNames.EXECUTOR] : [],
            confirmation_on_tr: [ERoleNames.TR],
            confirmation_on_ko: [ERoleNames.KO],
            confirmation_on_oe: [ERoleNames.OE]
        };
        return rolesMap[matListStatus] || [];
    };

    return (
        <ButtonGroup spacing={2}>
            <HasAccess roleNames={hasEditEquipmentRoles}>
                <Button
                    isDisabled={isDisabled}
                    variant="outline"
                    colorScheme="purple"
                    onClick={onAddEquipment}
                    type="button"
                >
                    + Добавить оборудование
                </Button>
            </HasAccess>
            <HasAccess roleNames={[ERoleNames.SUBC, ERoleNames.EXECUTOR]}>
                {matListStatus === 'created' && (
                    <Button
                        isDisabled={isDisabledStartProcess || isDisabled}
                        colorScheme="purple"
                        onClick={onApprove}
                        type="button"
                    >
                        Начать процесс
                    </Button>
                )}
            </HasAccess>
            <HasAccess roleNames={getApproveRoles(EActionType.Approve)}>
                <Button
                    isDisabled={isDisabledApprove || isDisabled}
                    colorScheme="purple"
                    onClick={onApprove}
                    type="button"
                >
                    Подтвердить
                </Button>
            </HasAccess>
            <HasAccess roleNames={getApproveRoles(EActionType.Reject)}>
                <Button
                    colorScheme="red"
                    variant="outline"
                    isDisabled={isDisabledReject || isDisabled}
                    onClick={onReject}
                    type="button"
                >
                    Отклонить
                </Button>
            </HasAccess>
        </ButtonGroup>
    );
};
export default matListActionButtons;
