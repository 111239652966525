// @chakra-ui
import { Box } from '@chakra-ui/react';
// components
import DecisionHistory from 'components/DecisionHistory';
//
import WorkRltAokFiles from './WorkRltAOKFiles';
import WorkRltAokStatus from './WorkRltAOKStatus';

// ----------------------------------------------------------------------

interface WorkRltAokProps {
    work: any;
    updateWork: () => void;
}

export default function WorkRltAok({ work, updateWork }: WorkRltAokProps) {
    return (
        <Box>
            <WorkRltAokFiles work={work} updateWork={updateWork} />
            <WorkRltAokStatus work={work} updateWork={updateWork} />
            <Box width="full" p={1.5}>
                <DecisionHistory history={work.s_cand_abs_prot_cmnt || ''} />
            </Box>
        </Box>
    );
}
