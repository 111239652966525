// @ts-nocheck
import axios from 'axios';
import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useToast,
    Stack
} from '@chakra-ui/react';
import Dragger from 'antd/lib/upload/Dragger';
import { Badge, Collapse, Divider } from 'antd';
import { RootState } from 'redux/rootReducer.types';
import { useSelector } from 'react-redux';
import { IOrder } from 'interfaces/orders';
import { FC, useState } from 'react';
import { ERoleNames } from '../constants';

const DEPARTMENTS = [
    {
        name: ERoleNames.DESIGN,
        key: 'dt'
    },
    {
        name: ERoleNames.LEGAL,
        key: 'legal'
    },
    {
        name: ERoleNames.OE,
        key: 'oe'
    },
    {
        name: ERoleNames.LEASING,
        key: 'leasing'
    },
    {
        name: ERoleNames.TR,
        key: 'tr'
    },
    {
        name: ERoleNames.RNP,
        key: 'rp'
    }
];

interface IProps {
    data: IOrder;
    mutate: () => void;
}

const TSSRstatuses: FC<IProps> = ({ data, mutate }) => {
    const user = useSelector((state: RootState) => state.user);
    const [commentsModalOpened, setCommentsModalOpened] = useState(false);
    const [lastSelectedDecision, setLastSelectedDecision] = useState(1);
    const [loadedFile, setLoadedFile] = useState();
    const [isDecisionPending, setIsDecisionPending] = useState(false);
    const [lastSelectedField, setLastSelectedField] = useState('');
    const roleName = user.role?.name || ERoleNames.SUBC;
    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const handleClick = (decision: number, field: string) => {
        setLastSelectedField(field);
        setLastSelectedDecision(decision);
        setCommentsModalOpened(true);
    };
    return (
        <>
            <Box>
                <h2
                    style={{
                        fontSize: '24px',
                        textAlign: 'center'
                    }}
                >
                    Статус согласования TSSR
                </h2>
                {data.tssr_status !== null ? (
                    <Box textAlign="center">
                        {DEPARTMENTS.map((department) => (
                            <>
                                <Box marginTop="6px">
                                    <Badge
                                        style={
                                            data[`tssr_${department.key}_approve`] !== null
                                                ? data[`tssr_${department.key}_approve`].status === false
                                                    ? { backgroundColor: 'red' }
                                                    : { backgroundColor: '#52c41a' }
                                                : { backgroundColor: '#3FCBFF' }
                                        }
                                        count={
                                            data[`tssr_${department.key}_approve`] !== null
                                                ? data[`tssr_${department.key}_approve`].status === true
                                                    ? `${department.name}: Согласовано | Дата: ${new Date(
                                                          data[`tssr_${department.key}_approve`].updated_at
                                                      ).toLocaleString('ru-RU')}`
                                                    : `${department.name}: Отказано | Дата: ${new Date(
                                                          data[`tssr_${department.key}_approve`].updated_at
                                                      ).toLocaleString('ru-RU')}`
                                                : `${department.name}: На рассмотрении`
                                        }
                                    />
                                </Box>
                                {(department.name === roleName || roleName === ERoleNames.ADMIN) &&
                                data[`tssr_${department.key}_approve`] === null ? (
                                    <>
                                        <Button
                                            style={{
                                                color: '#52c41a',
                                                marginRight: '6px',
                                                marginTop: '12px'
                                            }}
                                            onClick={() => handleClick(1, `tssr_${department.key}_approve`)}
                                        >
                                            Принять
                                        </Button>
                                        <Button
                                            style={{ color: 'red', marginTop: '12px' }}
                                            onClick={() => handleClick(0, `tssr_${department.key}_approve`)}
                                        >
                                            Отклонить
                                        </Button>
                                    </>
                                ) : null}
                            </>
                        ))}
                    </Box>
                ) : (
                    <Box textAlign="center">
                        <Badge count="Ожидает начало процесса" style={{ width: '200px' }} />
                    </Box>
                )}
                <Modal isOpen={commentsModalOpened} onClose={() => setCommentsModalOpened(false)} size="xl">
                    <ModalOverlay />

                    <ModalContent>
                        <ModalHeader>
                            Комментирование {lastSelectedDecision === 1 ? 'принятия' : 'отказа'}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text mb={1}>Комментарий</Text>
                            <Textarea id="commentsTextarea" paddingInlineStart={2} paddingInlineEnd={2} />
                            {lastSelectedDecision !== 1 && (
                                <Dragger
                                    name="file"
                                    maxCount={1}
                                    onChange={async (info) => {
                                        setLoadedFile(info);
                                    }}
                                    beforeUpload={() => false}
                                >
                                    <p className="ant-upload-text">
                                        Нажмите или переместите сюда файл отказа для загрузки
                                    </p>
                                </Dragger>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                colorScheme="purple"
                                mr="auto"
                                onClick={() => setCommentsModalOpened(false)}
                            >
                                Закрыть
                            </Button>
                            <Button
                                colorScheme={lastSelectedDecision === 1 ? 'green' : 'red'}
                                isLoading={isDecisionPending}
                                loadingText="Обработка..."
                                onClick={async () => {
                                    setIsDecisionPending(true);
                                    if (
                                        lastSelectedDecision === 0 &&
                                        // @ts-ignore
                                        !document.getElementById('commentsTextarea')?.value
                                    ) {
                                        toast({
                                            status: 'error',
                                            title: 'Заполните комментарий при отказе!'
                                        });
                                        setIsDecisionPending(false);
                                    } else {
                                        const formData = new FormData();
                                        formData.append('file', loadedFile?.file);
                                        formData.append('orderId', data.id);
                                        formData.append('field', lastSelectedField);
                                        formData.append('value', lastSelectedDecision);
                                        formData.append(
                                            'comment',
                                            document.getElementById('commentsTextarea').value
                                        );
                                        await axios
                                            .post('/orders/makeDecision', formData)
                                            .then(() => {
                                                mutate();
                                                toast({
                                                    title: `TSSR успешно ${
                                                        lastSelectedDecision === 1 ? 'принято' : 'отклонено'
                                                    }`,
                                                    status: 'success'
                                                });
                                                setCommentsModalOpened(false);
                                            })
                                            .catch(() => {
                                                toast({
                                                    title: 'Ошибка при обновлении',
                                                    status: 'error'
                                                });
                                            })
                                            .finally(() => {
                                                setIsDecisionPending(false);
                                            });
                                    }
                                }}
                            >
                                {lastSelectedDecision === 1 ? 'Принять' : 'Отклонить'}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
            <Divider style={{ background: '#1F2229' }} />
            <Collapse style={{ width: '100%' }} defaultActiveKey="0">
                <Collapse.Panel header="TSSR Комментарии" key="1">
                    <>
                        <span dangerouslySetInnerHTML={{ __html: data.tssr_cmnt }} />
                        <span>
                            {data.orderAprroveStatuses.map((apprStatus) => (
                                <>
                                    <span dangerouslySetInnerHTML={{ __html: apprStatus.comment }} />
                                    {apprStatus?.document && (
                                        <a
                                            target="_blank"
                                            href={
                                                apprStatus?.document?.from_sad
                                                    ? `https://sad.tele2.kz/tele2${apprStatus?.document?.doc_link.replace(
                                                          '.',
                                                          '',
                                                          1
                                                      )}`
                                                    : `${process.env.REACT_APP_API_BASE_URL}${apprStatus?.document?.doc_link}`
                                            }
                                            key={apprStatus?.document?.id}
                                            rel="noreferrer"
                                            style={{
                                                padding: '3px',
                                                borderBottom: '1px solid gray',
                                                color: '#008abd'
                                            }}
                                        >
                                            {apprStatus?.document?.file_name}
                                        </a>
                                    )}
                                </>
                            ))}
                        </span>
                    </>
                </Collapse.Panel>
            </Collapse>
        </>
    );
};
export default TSSRstatuses;
