import { Box, Button } from '@chakra-ui/react';
import axios from 'axios';
import Input from 'components/Input/Input';
import { useFormik } from 'formik';
import { useState } from 'react';
import { getState } from 'utils/localStorage';

interface IInfo {
    messages: Array<string>;
    type: string;
}
const initialValues = {
    card_number: '',
    pin_code: ''
};

export default function CreateFuelCards() {
    const state = getState();

    const [info, setInfo] = useState<IInfo>({
        messages: [],
        type: ''
    });

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { resetForm }) => {
            const errors: IInfo = { messages: [], type: '' };

            if (values.card_number === '') {
                errors.messages.push('поле номера карты не заполнено');
                errors.type = 'error';
            }

            if (values.pin_code === '') {
                errors.messages.push('поле пинкода не заполнено');
                errors.type = 'error';
            }

            if (errors.messages.length === 0) {
                const result = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fuel-cards`, {
                    user: state.user.data.id,
                    ...values
                });

                if (result.status === 200) {
                    setInfo({ messages: ['Карта успешно зарегистрирована'], type: 'success' });
                    resetForm();
                } else {
                    setInfo({ messages: ['Возникла проблема, попробуйте позднее'], type: 'error' });
                }
            } else {
                setInfo(errors);
            }
        }
    });

    return (
        <Box display="flex" justifyContent="center" mt="14">
            <Box backgroundColor="white" padding="5" borderRadius="10px" width="30%">
                <form onSubmit={formik.handleSubmit}>
                    <span
                        style={{
                            color: info.type === 'error' ? 'red' : 'green',
                            visibility: info.type === '' ? 'hidden' : 'visible',
                            fontSize: '14px'
                        }}
                    >
                        *{info.messages.join(', ')}
                    </span>
                    <Box>
                        <Input
                            placeholder="номер карты"
                            value={formik.values.card_number}
                            onChange={(e) => formik.setFieldValue('card_number', e.target.value)}
                        />
                    </Box>
                    <Box mt={2} width="40%">
                        <Input
                            placeholder="пинкод"
                            value={formik.values.pin_code}
                            onChange={(e) => formik.setFieldValue('pin_code', e.target.value)}
                        />
                    </Box>
                    <Button colorScheme="purple" marginTop="3" type="submit">
                        Зарегистрировать карту
                    </Button>
                </form>
            </Box>
        </Box>
    );
}
