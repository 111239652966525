// @chakra-ui
import { Tr, Th, Thead, Text } from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

// ----------------------------------------------------------------------

export default function TableHeadCustom({ headerGroups, sx }: any) {
    return (
        <Thead bg="aliceblue" sx={sx}>
            {headerGroups.map((headerGroup: any) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                        <Th
                            {...column.getHeaderProps({
                                className: column.headerClassName,

                                ...(column.getSortByToggleProps ? { ...column.getSortByToggleProps() } : {})
                            })}
                            isNumeric={column.isNumeric}
                        >
                            {column.render('Header')}

                            <Text as="span" pl={1}>
                                {column.isSorted ? (
                                    column.isSortedDesc ? (
                                        <TriangleDownIcon aria-label="sorted descending" />
                                    ) : (
                                        <TriangleUpIcon aria-label="sorted ascending" />
                                    )
                                ) : null}
                            </Text>
                        </Th>
                    ))}
                </Tr>
            ))}
        </Thead>
    );
}
