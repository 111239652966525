import { Navigate, Routes, Route, BrowserRouter } from 'react-router-dom';
import OrderCreateNew from 'Routes/Order/OrderCreate/OrderCreate';
import ReportOrders from 'Routes/Reports/ReportOrders';
import Balance from 'Routes/Balance/Balance';
import Equipments from 'Routes/Equipments/Equipments';
import Equipment from 'Routes/Equipments/Equipment';
import CreateEquipment from 'Routes/Equipments/CreateEquipment';
import Signing from 'components/Signing';
import Site from 'components/Site';
import SiteStructureDetails from 'components/SiteStructureDetails';
import Task from 'components/Task';
import CreateSite from 'Routes/Sites/CreateSite';
import CreateWork from 'Routes/Works/CreateWork';
/* import OrderListing from 'components/OrderListing'; */
import NotFound from 'Routes/NotFound/NotFound';
import Health from 'Routes/Health/Health';
import Work from 'Routes/Works/Work';
import Layout from 'parts/Layout/Layout';
import Welcome from 'Routes/Welcome/Welcome';
import ExportJobListing from 'components/ExportJobListing';
import Login from 'parts/Auth/Login/Login';
import ResetPassword from 'parts/Auth/ResetPassword/ResetPassword';
import SetNewPassword from 'parts/Auth/SetNewPassword/SetNewPassword';
import AuthWrapper from 'parts/Auth/AuthWrapper';
import Administration from 'components/Administration/Administration';
import Requests from 'components/Administration/Requests';
import RequestAccount from 'parts/Auth/RequestAccount/RequestAccount';
import CreateCarAndFuelCards from 'Routes/GSM/CreateCarAndFuelCards';
import MailConfirm from 'parts/Auth/MailConfirm/MailConfirm';
import BGUGSMEdit from 'Routes/GSM/BGUGSMEdit';
import DGUGSMEdit from 'Routes/GSM/DGUGSMEdit';
import EditingGSM from 'Routes/GSM/EditingGSM';
import { WorkList } from 'Routes/Works';
import { TaskList } from 'Routes/Tasks';
import { SiteList, SiteWrapper } from 'Routes/Sites';
import { Orders } from 'Routes/Order';
import { Acts } from 'Routes/Acts';
import WorkShiftEditing from 'Routes/GSM/WorkShiftEditing';
import CarGSMEdit from 'Routes/GSM/CarGSMEdit';
import GasReportsPerPeriod from 'Routes/GSM/GasReportsPerPeriod';
import Kanban from 'components/Administration/Kanban';
import EditBguWorkshifts from 'Routes/GSM/EditBguWorkshift';
import WorkIntegration from 'Routes/Works/WorkIntegrations';
import Main from 'Routes/Main/Main';
import AuthGuard from '../guards/AuthGuard';
import ActCreate from './Acts/ActCreate';
import WorkMatListPage from './Order/WorkMatListPage';
import IssuanceTr from './Order/MatList/IssuanceTr';
import Reports from './Reports/Reports';
import ReportWorks from './Reports/ReportWorks';

const CIPTRoutes = () => (
    <BrowserRouter>
        <Routes>
            {/* Auth */}
            <Route element={<AuthWrapper />}>
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/set-new-password" element={<SetNewPassword />} />
                <Route path="/request-account" element={<RequestAccount />} />
                <Route path="/mail-confirm" element={<MailConfirm />} />
            </Route>
            <Route
                element={
                    <AuthGuard>
                        <Layout />
                    </AuthGuard>
                }
            >
                <Route path="/works" element={<WorkList />} />
                <Route path="/works/:workID" element={<Work />} />
                <Route path="/works/:workID/mat-list" element={<WorkMatListPage />} />
                <Route path="/mat-list/:matListId/trs/:trId/issuance" element={<IssuanceTr />} />
                <Route path="/works/integrations/:integrationID" element={<WorkIntegration />} />
                <Route path="/tasks" element={<TaskList />} />
                <Route path="/tasks/:taskID" element={<Task />} />
                <Route path="/tasks/:taskID/:siteID/:trueSiteID" element={<Site />} />
                <Route path="/tasks/:taskID/:siteID/:trueSiteID/:sad_id" element={<SiteStructureDetails />} />
                <Route path="/sites/:siteID/:trueSiteID" element={<SiteWrapper />} />
                <Route path="/site-structure-details/:sad_id" element={<SiteStructureDetails />} />
                <Route path="/sites" element={<SiteList />} />
                <Route path="/create/site" element={<CreateSite />} />
                <Route path="/create/work" element={<CreateWork />} />
                <Route path="/signing" element={<Signing />} />
                <Route path="/reports" element={<Reports />}>
                    <Route path="/reports/orders" element={<ReportOrders />} />
                    <Route path="/reports/works" element={<ReportWorks />} />
                </Route>
                <Route path="/reports/gas-report" element={<GasReportsPerPeriod />} />
                <Route path="/reports/createCarAndFuelCards" element={<CreateCarAndFuelCards />} />
                <Route path="/reports/editingGSM" element={<EditingGSM />} />
                <Route path="reports/editingGSM/carGSM/:id" element={<CarGSMEdit />} />
                <Route path="reports/editingGSM/work-shift/:id" element={<WorkShiftEditing />} />
                <Route path="reports/editingGSM/bgu-work-shift/:id" element={<EditBguWorkshifts />} />
                <Route path="reports/editingGSM/BGU/:id" element={<BGUGSMEdit />} />
                <Route path="reports/editingGSM/DGU/:id" element={<DGUGSMEdit />} />
                <Route path="/acts" element={<Acts />} />
                <Route path="/acts/create/:orderID" element={<ActCreate />} />
                <Route path="/acts/:actID" element={<ActCreate />} />
                <Route path="/export-jobs" element={<ExportJobListing />} />
                <Route path="/administration" element={<Administration />} />
                <Route path="/requests" element={<Requests />} />
                <Route path="/kanban" element={<Kanban />} />
                <Route path="/balance" element={<Balance />} />
                <Route path="/equipments" element={<Equipments />} />
                <Route path="/equipments/:equipmentId" element={<Equipment />} />
                <Route path="/equipments/create" element={<CreateEquipment />} />
                <Route path="/orders/create/:workID" element={<OrderCreateNew />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orders/:orderID" element={<OrderCreateNew />} />
                <Route path="/main" element={<Main />} />
            </Route>
            <Route path="/health" element={<Health />} />
            <Route path="/" element={<Navigate to="/welcome" />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
);

export default CIPTRoutes;
