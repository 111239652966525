import { Upload } from 'antd';
import { Box, Button, useToast } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { ordersProjectsService } from '../../../api/services';
import { IOrderProject } from '../OrderProject';

interface IProps {
    project: IOrderProject;
    refetchProject: () => void;
    isEdit: boolean;
    isDisabled?: boolean;
}
const UploadProjectFile: FC<IProps> = ({ project, refetchProject, isEdit, isDisabled }) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onUpload = (file: any) => {
        if (!file?.file) return;
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file?.file);
        ordersProjectsService
            .uploadFile(project.id, formData)
            .then(async () => {
                toast({
                    status: 'success',
                    title: 'Файл успешно загружен'
                });
                refetchProject();
            })
            .catch((err) => {
                const resError = err.response.data.message;
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: resError.details || resError || 'Не удалось загрузить файл'
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Box my={2}>
            <Upload
                multiple
                accept=".xlsx"
                name="file"
                disabled={isDisabled}
                showUploadList={false}
                maxCount={1}
                onChange={(file) => onUpload(file)}
                beforeUpload={() => false}
            >
                <Button
                    isDisabled={isDisabled}
                    colorScheme="green"
                    variant={isEdit ? 'outline' : 'solid'}
                    width="100%"
                    isLoading={isLoading} // Добавляем индикатор загрузки на кнопку
                    loadingText="Загрузка..."
                >
                    {isEdit ? 'Изменить' : 'Загрузить'} файл
                </Button>
            </Upload>
        </Box>
    );
};

export default UploadProjectFile;
