import axios from 'axios';
import qs from 'qs';
import { IMatListEquipmentRequest, IRejectMatListFrom } from '../../Routes/Order/utils/interfaces/MatList';

const getByOrderId = ({ orderId, params }: any) =>
    axios.get(`mat-lists/order/${orderId}`, { params, paramsSerializer: (params) => qs.stringify(params) });

const deleteEquipmentFromList = (id: number) => axios.delete(`mat-list-equipments/${id}`);

const addMatListEquipment = (equipment: IMatListEquipmentRequest) =>
    axios.post(`mat-list-equipments/`, equipment);

const getMatListEquipment = (matListId: number) =>
    axios.get(`mat-list-equipments/`, { params: { matList: matListId } });

const approve = (id: number) => axios.post(`mat-lists/${id}/approve`);

const reject = (id: number, data: IRejectMatListFrom) => axios.post(`mat-lists/${id}/reject`, data);

const sendToWarehouse = (id: number) => axios.post(`/mat-lists/${id}/send-to-warehouse`);

const getStatusHistory = (id: number) => axios.get(`/mat-lists/${id}/history/status`);

const changeQty = (id: number, qty: number) => axios.patch(`/mat-list-equipments/${id}/qty`, { qty });

const getComments = (id: number) => axios.get(`/mat-lists/${id}/comments`);

const addComment = (id: number, message: string) => axios.post(`/mat-lists/${id}/comment`, { message });

export default {
    getMatListEquipment,
    changeQty,
    sendToWarehouse,
    deleteEquipmentFromList,
    getByOrderId,
    approve,
    reject,
    getStatusHistory,
    addMatListEquipment,
    getComments,
    addComment
};
