import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, usePagination, Column } from 'react-table';
import _ from 'lodash';
import axios from 'axios';
import { useToast, TableContainer, Flex, Link as ChakraLink, Badge, Spinner, Stack } from '@chakra-ui/react';
import { TableCustom, TablePagination } from 'components/react-table';
import LoadingOverlay from 'components/LoadingOverlay';
import { workService } from 'api/services';
import { WORK_STATUSES } from 'Routes/Works/utils/maps';

interface IProps {
    siteId: string;
}

const SiteWorkList: React.FC<IProps> = ({ siteId }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [works, setWorks] = useState<any[]>([]);
    const [totalWorks, setTotalWorks] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const data = useMemo(() => works, [works]);

    const getColumns = (): Column<any>[] => [
        { accessor: 'site.s_reg', Header: 'Регион' },
        {
            accessor: 'id',
            Header: 'ID ЭАП',
            Cell: ({ cell: { value, row } }) => (
                <Stack gap={1}>
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        as={Link}
                        target="_blank"
                        className="order-link"
                        to={`/works/${row.original.id}`}
                    >
                        {value}
                    </ChakraLink>
                </Stack>
            )
        },
        { accessor: 'site_id', Header: 'ID сайта' },
        { accessor: 'sitename', Header: 'Site name' },
        { accessor: 'project.title', Header: 'Проект' },
        { accessor: 'plannedHW', Header: 'planned HW' },
        { accessor: 'projectDate', Header: 'Год проекта' },
        { accessor: 'plannedBand', Header: 'planned Band' },
        {
            Header: 'Статус ЭАП',
            Cell: ({ cell: { row } }) => {
                const [color, text] = ['red', WORK_STATUSES[`${row.original?.workStatus.current}`]];
                return (
                    <Badge variant="subtle" colorScheme={color}>
                        {text}
                    </Badge>
                );
            }
        },
        { accessor: 'work_type', Header: 'Тип ЭАП' },
        {
            accessor: 'updated_at',
            Header: 'Дата изменения статуса',
            Cell: ({ cell: { value } }) => <>{new Date(value).toLocaleString('ru-RU')}</>
        }
    ];

    const columns = useMemo(() => getColumns(), []) as Column<any>[];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount: pageCountState,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, sortBy: [{ id: 'updated_at', desc: true }] },
            stateReducer(newState, action) {
                if (action.type === 'toggleSortBy') {
                    return { ...newState, pageIndex: 0 };
                }
                return newState;
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount,
            autoResetPage: false,
            autoResetSortBy: false
        },
        useSortBy,
        usePagination
    );

    const getWorks = () => {
        const params = {
            is_new: 'true',
            isRollout: 'false',
            site_id_eq: siteId,
            _limit: pageSize,
            _start: pageSize * pageIndex,
            _sort: sortBy.length ? `${sortBy[0].id}:${sortBy[0].desc ? 'DESC' : 'ASC'}` : null
        };
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
        return workService.list({ params: finalParams });
    };

    const getTotalWorks = () => {
        const params = {
            is_new: 'true',
            isRollout: 'false',
            site_id_eq: siteId
        };
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
        return workService.count({ params: finalParams });
    };

    const fetchAPIData = () => {
        setIsLoading(true);
        const firstPromise = getWorks();
        const secondPromise = getTotalWorks();

        Promise.all([firstPromise, secondPromise])
            .then((responses) => {
                setWorks(responses[0].data);
                setTotalWorks(responses[1].data);
                setPageCount(Math.ceil(responses[1].data / pageSize));
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (axios.isCancel(err)) {
                    return;
                }
                toast({ status: 'error', title: `Не удалось получить список ЭАП` });
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchAPIData();
    }, [pageIndex, pageSize, sortBy]);

    return (
        <>
            <LoadingOverlay
                Spinner={<Spinner color="blue.500" />}
                active={isLoading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        overflow: 'auto',
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column'
                    })
                }}
            >
                <Flex h="100%" direction="column">
                    <TableContainer sx={{ flexGrow: 1, overflowY: 'auto', height: '100%', padding: 1.5 }}>
                        <TableCustom
                            size="sm"
                            getTableProps={getTableProps}
                            headerGroups={headerGroups}
                            getTableBodyProps={getTableBodyProps}
                            page={page}
                            prepareRow={prepareRow}
                        />
                    </TableContainer>
                    <TablePagination
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        pageCount={pageCountState}
                        previousPage={previousPage}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        pageSizeOptions={[5, 10, 25]}
                        setPageSize={setPageSize}
                    />
                </Flex>
            </LoadingOverlay>
        </>
    );
};

export default SiteWorkList;
