import { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Image, Spinner, Text } from '@chakra-ui/react';

export interface AuthWrapperOutletContext {
    animateLogin: () => void;
}

const LEFT_SIDE_TRANSITION = '1.3s';
const LEFT_SIDE_CONTENT_TRANSITION = '0.2s';
const RIGHT_SIDE_TRANSITION = '1.3s';
const TOWER_TRANSITION = '3s';
const SPINNER_TRANSITION = '1s';

/**
 * Wrapper component for auth pages like login, registration, etc.
 * Uses react-router-dom outlet context to pass animateLogin function
 * that called e.g. when successfully login happened.
 *
 * @author Nikita Snetkov
 */
const AuthWrapper = () => {
    /* 
        Using `as` because of React v18 breaking changes after which LegacyRefObject appear
        react/types issue
    */
    const leftSide = useRef() as React.MutableRefObject<HTMLDivElement>;
    const leftSideContent = useRef() as React.MutableRefObject<HTMLDivElement>;
    const rightSide = useRef() as React.MutableRefObject<HTMLDivElement>;
    const towerImage = useRef() as React.MutableRefObject<HTMLImageElement> | undefined;
    const spinner = useRef() as React.MutableRefObject<HTMLDivElement>;

    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

    const animateLogin = () => {
        /* Hide left side */
        leftSide.current.style.width = '0';
        leftSideContent.current.style.opacity = '0';

        /* Expand right side */
        rightSide.current.style.width = '100%';

        /* Move tower to the bottom */
        if (towerImage) towerImage.current.style.top = '3000px';

        /* Show spinner */
        setIsSpinnerVisible(true);
    };

    return (
        <Box display="flex" h="100vh">
            {/* Left side */}
            <Box
                w="700px"
                justifyContent="center"
                alignItems="center"
                position="relative"
                display="flex"
                flexDir="column"
                transition={`width ${LEFT_SIDE_TRANSITION} ease-in-out`}
                ref={leftSide}
                boxShadow="rgba(100, 100, 111, 1) 0px 7px 29px 0px"
                zIndex={1}
            >
                {/* Left side content */}
                <Box
                    w="80%"
                    display="flex"
                    flexDir="column"
                    transition={`opacity ${LEFT_SIDE_CONTENT_TRANSITION} ease-in-out`}
                    ref={leftSideContent}
                >
                    <Outlet context={{ animateLogin }} />
                </Box>
            </Box>
            {/* Right side */}
            <Box
                w="70%"
                padding="64px 0"
                backgroundColor="#000000"
                backgroundImage="linear-gradient(315deg, #000000 0%, #7f8c8d 74%)"
                display="flex"
                justifyContent="center"
                alignItems="baseline"
                ref={rightSide}
                transition={`width ${RIGHT_SIDE_TRANSITION} ease-in-out`}
            >
                <Text
                    position="absolute"
                    fontSize="72px"
                    color="white"
                    fontFamily="Mukta"
                    fontWeight={700}
                    display="inline-flex"
                    alignItems="center"
                >
                    CIP TRACKER
                </Text>
                <Image
                    ref={towerImage}
                    position="absolute"
                    transition={`top ${TOWER_TRANSITION} ease-in-out`}
                    top={10}
                    src="images/tower.png"
                    alt=""
                />
                {isSpinnerVisible && (
                    <Spinner
                        ref={spinner}
                        position="absolute"
                        top="42%"
                        thickness="6px"
                        speed="1.6s"
                        emptyColor="gray.200"
                        color="#0A0B0B"
                        width="128px"
                        height="128px"
                        opacity="1"
                        transition={`opacity ${SPINNER_TRANSITION} ease-in-out`}
                    />
                )}
            </Box>
        </Box>
    );
};

export default AuthWrapper;
