import React, { FC, useState } from 'react';
import { Box, Button, useToast } from '@chakra-ui/react';
import { Select } from 'antd';
import { IEsignWithOrderInfo } from 'interfaces/esigns';
import { ordersProjectsService } from '../../../api/services';
import { IOrderProject } from '../OrderProject';

interface IProps {
    project: IOrderProject;
    existingEsigns: IEsignWithOrderInfo[];
    refetchProject: () => void;
    isDisabled?: boolean;
    onSearch?: (value: string) => void;
    setEditMode: (value: boolean) => void;
}

const SelectExistingFiles: FC<IProps> = ({
    project,
    existingEsigns,
    refetchProject,
    isDisabled,
    onSearch,
    setEditMode
}) => {
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();

    const handleSelectFile = async () => {
        if (selectedFile) {
            setIsLoading(true);
            try {
                const esign = existingEsigns.find((file) => file.id === parseInt(selectedFile, 10));
                if (esign) {
                    await ordersProjectsService.updateProjectFile(project.id, esign.id);
                    toast({
                        status: 'success',
                        title: `Файл выбран`
                    });
                    refetchProject();
                    setEditMode(false);
                }
            } catch (error: any) {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: error.response?.data?.message || 'Не удалось обновить файл'
                });
            } finally {
                setIsLoading(false);
            }
        } else {
            toast({
                status: 'error',
                title: 'Ошибка',
                description: 'Выберите файл'
            });
        }
    };

    return (
        <Box mt={4}>
            <Select
                placeholder="Выберите файл"
                onChange={(value) => setSelectedFile(value)}
                disabled={isDisabled}
                showSearch
                style={{ width: '100%' }}
                onSearch={onSearch}
                filterOption={false}
                options={existingEsigns.map((file) => ({
                    value: file.id.toString(),
                    label: `${file.file_name} - (ID заказа: ${file.order && file.order.id}; Код заказа: ${
                        file.order && file.order.code
                    })`
                }))}
            />
            <Button
                mt={2}
                colorScheme="blue"
                onClick={handleSelectFile}
                isDisabled={isDisabled || !selectedFile}
                isLoading={isLoading}
                loadingText="Загрузка..."
            >
                Подтвердить
            </Button>
        </Box>
    );
};

export default SelectExistingFiles;
