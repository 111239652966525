import React from 'react';
import { Card, Button, Typography } from 'antd';
import { IEsign } from 'interfaces/esigns';
import { Flex } from '@chakra-ui/react';

interface EsignCardProps {
    esign: IEsign;
}

const EsignCard: React.FC<EsignCardProps> = ({ esign }) => (
    <Card title={esign.file_name} style={{ marginBottom: 16 }}>
        <Button type="link" href={`${process.env.REACT_APP_API_BASE_URL}${esign.doc_link}`} target="_blank">
            Скачать
        </Button>
        <Typography style={{ paddingLeft: 15 }}>
            Date: {new Date(esign.date).toLocaleDateString('ru')}
        </Typography>
    </Card>
);

export default EsignCard;
