import { Button, Table, TableColumnType } from 'antd';
import axios from 'axios';
import useHeaderTitle from 'hooks/useHeaderTitle';
import React, { useState, useEffect } from 'react';
import { useTitle } from 'react-use';
import { Box, Fade, Select, useToast } from '@chakra-ui/react';
import { generateTitle } from '../../utils';
import createQueryString from '../../utils/createQueryString';
import Label from '../../new-components/ui/Label';

interface IReportWorkItem {
    id: number;
    project: { key: string | null; title: string };
    awaitingSar: number;
    onLeasing: number;
    awaitingRsd: number;
    awaitingTsd: number;
    readyToOrders: number;
    hasOrdersInProcess: number;
    cancelled: number;
    total: number;
}

const ReportOrders: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [reports, setReports] = useState<IReportWorkItem[] | null>(null);
    const [year, setYear] = useState(new Date().getFullYear());

    useTitle(generateTitle('Отчеты'));
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    useHeaderTitle('Отчеты');

    const getReports = () => {
        setIsLoading(true);
        axios
            .get(`/reports/works?year=${year}`)
            .then((res) => {
                console.log(res);
                setReports(res.data);
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: 'Не удалось получить отчет'
                });
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getReports();
    }, [year]);

    const renderButton = (value: number, record: IReportWorkItem, queryParams: any) => (
        <Button
            disabled={value === 0}
            target="_blank"
            href={`/works?${createQueryString({ ...queryParams, disabledFilter: true, projectDate: year })}`}
            style={{ width: '100%' }}
        >
            {value}
        </Button>
    );

    const createColumn = (
        title: string,
        dataIndex: keyof IReportWorkItem,
        queryParams: object
    ): TableColumnType<IReportWorkItem> => ({
        title,
        key: dataIndex,
        dataIndex,
        render: (value: number, record: IReportWorkItem) =>
            renderButton(value, record, {
                ...queryParams,
                project: record.project?.key,
                project_null: record.project.key === null
            })
    });

    const columns: TableColumnType<IReportWorkItem>[] = [
        {
            title: 'Проект',
            key: 'project',
            dataIndex: ['project', 'title']
        },
        createColumn('Ожидают SAR', 'awaitingSar', {
            'work_type': 'NCP',
            'workStatus.prepareSar_null': 'true'
        }),
        createColumn('На лизинге', 'onLeasing', { 'workStatus.current': 'leasingApprove' }),
        createColumn('Ожидают RSD', 'awaitingRsd', { 'workStatus.uploadRsd_null': true }),
        createColumn('Ожидают TSD', 'awaitingTsd', { 'workStatus.uploadTsd_null': true }),
        createColumn('Готовы к созданию заказов', 'readyToOrders', { 'workStatus.readyForOrder': true }),
        createColumn('Есть заказы в работе', 'hasOrdersInProcess', { 'workStatus.hasOrders': true }),
        createColumn('Отмененные', 'cancelled', { 'workStatus.cancelled': true }),
        createColumn('Всего', 'total', {})
    ];

    const startProjectsYear = 2023;
    return (
        <Fade in>
            <Box my={2}>
                <Label text="Год" />
                <Select width="200px" size="sm" onChange={(e) => setYear(+e.target.value)} value={year}>
                    {[...Array(new Date().getFullYear() - startProjectsYear + 1)].map((_, i) => (
                        // TODO сделать получение годов с бэка
                        <option key={startProjectsYear + i} value={startProjectsYear + i}>
                            {startProjectsYear + i}
                        </option>
                    ))}
                </Select>
            </Box>
            <Table
                bordered
                loading={isLoading}
                size="small"
                indentSize={0}
                scroll={{ x: true }}
                pagination={false}
                rowKey="id"
                dataSource={reports || []}
                columns={columns}
            />
        </Fade>
    );
};

export default ReportOrders;
