import { useAsyncDebounce } from 'react-table';
// @chakra-ui
import {
    NumberInput,
    NumberInputField,
    NumberIncrementStepper,
    NumberInputStepper,
    NumberDecrementStepper
} from '@chakra-ui/react';

// ----------------------------------------------------------------------

export interface TableGoToPageProps {
    gotoPage: Function;
    pageCount: number;
    pageIndex: number;
}

const TableGoToPage = ({ gotoPage, pageCount, pageIndex }: TableGoToPageProps) => {
    const debouncedHandler = useAsyncDebounce((value: any) => {
        const page = value ? value - 1 : 0;
        gotoPage(page);
    }, 500);

    return (
        <NumberInput
            allowMouseWheel
            size="sm"
            min={1}
            width="auto"
            max={pageCount}
            onChange={debouncedHandler}
            defaultValue={!pageCount ? '' : pageIndex + 1}
        >
            <NumberInputField
                placeholder="Номер страницы"
                sx={{
                    borderColor: 'gray.200',
                    paddingInlineStart: 'var(--chakra-space-1-5)'
                }}
            />
            <NumberInputStepper borderColor="gray.200">
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    );
};

export default TableGoToPage;
