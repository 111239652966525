// @chakra-ui
import { Box } from '@chakra-ui/react';
// components
import DecisionHistory from 'components/DecisionHistory';
//
import WorkAfuFiles from './WorkAfuFiles';
import WorkAfuStatus from './WorkAfuStatus';

// ----------------------------------------------------------------------

interface WorkAfuProps {
    work: any;
    updateWork: () => void;
}

export default function WorkAfu({ work, updateWork }: WorkAfuProps) {
    return (
        <Box>
            <WorkAfuFiles work={work} updateWork={updateWork} />
            <WorkAfuStatus work={work} updateWork={updateWork} />
            <Box width="full" p={1.5}>
                <DecisionHistory history={work.s_bs_002_ant_rep_comments || ''} />
            </Box>
        </Box>
    );
}
