// @chakra-ui
import { Stack, Heading, Badge, useToast, Button } from '@chakra-ui/react';
// services
import workService from 'api/services/work-service';
import _ from 'lodash';
import { useState } from 'react';

// ----------------------------------------------------------------------

interface WorkAfuStatusProps {
    work: any;
    updateWork: () => void;
}

export default function WorkAfuStatus({ work, updateWork }: WorkAfuStatusProps) {
    const [isLoading, setIsLoading] = useState(false);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const { ant_rep_status: status } = work;

    const [color, text] =
        status !== null
            ? status === 1
                ? ['yellow', 'На рассмотрении']
                : status === 2
                ? ['green', 'Согласовано']
                : ['red', 'Отклонено']
            : ['gray', 'Ожидает начало процесса'];

    const handleStart = () => {
        setIsLoading(true);
        workService
            .startAfu(work.id)
            .then(() => {
                setIsLoading(false);
                toast({
                    title: 'Процесс согласования успешно запущен',
                    status: 'success'
                });
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
                setIsLoading(false);
            });
    };

    const disableAction = _.includes([1, 2], status) || !work.s_bs_002_ant_rep_recieved?.esigns?.length;

    return (
        <Stack p={1.5} alignItems="center">
            <Button
                disabled={isLoading || disableAction}
                isLoading={isLoading}
                colorScheme="purple"
                onClick={handleStart}
                variant="outline"
            >
                Начать процесс
            </Button>
            <Heading as="h4" fontSize="md">
                Статус согласования
            </Heading>
            <Badge colorScheme={color} fontSize="0.8em">
                {text}
            </Badge>
        </Stack>
    );
}
