// @chakra-ui
import { Box } from '@chakra-ui/react';
import DecisionHistory from 'components/DecisionHistory';
//
import WorkRltSARFiles from './SarFiles';
import WorkRltSARStatus from './SarStatus';

// ----------------------------------------------------------------------

interface WorkRltSarProps {
    work: any;
    updateWork: () => void;
}

export default function WorkRltSar({ work, updateWork }: WorkRltSarProps) {
    return (
        <Box>
            <WorkRltSARFiles work={work} updateWork={updateWork} />
            <WorkRltSARStatus work={work} updateWork={updateWork} />
            <Box p={1.5}>
                <DecisionHistory history={work.candidateStatusCmnt || ''} />
            </Box>
        </Box>
    );
}
