import { FormLabel } from '@chakra-ui/react';
import React, { FC } from 'react';

const formLabelStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#5d5c5c',
    marginBottom: '0.4rem',
    marginRight: 0
};

interface IProps {
    text: string;
}
const Label: FC<IProps> = ({ text }) => <FormLabel style={formLabelStyle}>{text}</FormLabel>;

export default Label;
