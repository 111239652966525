import { Table, Button, Collapse } from 'antd';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTitle } from 'react-use';
import { Fade, useToast, Heading, Box, Flex } from '@chakra-ui/react';
import { generateTitle } from '../../utils';
import createQueryString from '../../utils/createQueryString';

const { Panel } = Collapse;

interface IReport {
    id: number;
    status?: { value: string; key: string };
    workStatus?: { value: string; key: string };
    matListStatus?: { value: string; key: string };
    department?: { value: string; key: string };
    [key: string]: any;
}

const columnDefinitions: Record<string, any> = {
    'smr': {
        status: [{ title: 'Статус заказов по СМР:', key: 'status.value' }],
        workStatus: [{ title: 'Статус работ по СМР:', key: 'workStatus.value' }],
        matListStatuses: [{ title: 'Департамент', key: 'matListStatus.value' }]
    },
    'project': {
        status: [{ title: 'Статус заказов по проектам:', key: 'status.value' }],
        workStatus: [{ title: 'Статус работ по проектам:', key: 'workStatus.value' }],
        tssrs: [{ title: 'Департамент', key: 'department.value' }]
    },
    '': {
        types: [{ title: 'Всего', key: 'title' }]
    },
    'default': [
        { key: 'Almaty', qp: { 'work.site.s_reg': 'Almaty' } },
        { key: 'East', qp: { 'work.site.s_reg': 'East' } },
        { key: 'North&Central', qp: { 'work.site.s_reg': 'North&Central' } },
        { key: 'South', qp: { 'work.site.s_reg': 'South' } },
        { key: 'West', qp: { 'work.site.s_reg': 'West' } },
        { key: 'total', title: 'Всего' }
    ]
};

const ReportOrders: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [reports, setReports] = useState<Record<string, any> | null>(null);
    useTitle(generateTitle('Отчеты'));
    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const getReports = () => {
        setIsLoading(true);
        axios
            .get('/reports/orders')
            .then((res) => {
                setReports(res.data);
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: 'Не удалось получить отчет'
                });
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getReports();
    }, []);

    const generateColumns = (type: string, statusType: string) => {
        const columns = [...columnDefinitions[type][statusType], ...columnDefinitions.default];

        return columns.map((col: any, index: number) => ({
            title: col.title || col.key,
            key: col.key,
            dataIndex: col.key.split('.'),
            render: (value: any, record: IReport) =>
                index === 0 ? (
                    value
                ) : (
                    <Button
                        disabled={value === 0}
                        onClick={() => {
                            let params = {
                                ...col.qp,
                                'disabledFilter': 'true',
                                'status': record.status?.key,
                                'workStatus': record.workStatus?.key,
                                'workType.type': type,
                                'matList.status': record.matListStatus?.key,
                                'detail.isProcessStarted': !!record.workStatus || record.isProcessStarted
                            };
                            if (col.qp) {
                                params = { ...col.qp, ...params };
                            }

                            if (record.department?.key) {
                                params[`${record.department.key}_null`] = true;
                            }

                            window.open(`/orders?${createQueryString(params)}`, '_blank');
                        }}
                        style={{ width: '100%' }}
                    >
                        {value}
                    </Button>
                )
        }));
    };

    const renderTable = (type: string, statusType: string, dataSource: IReport[] | undefined) => (
        <Table
            bordered
            loading={isLoading}
            size="small"
            indentSize={0}
            scroll={{ x: true }}
            pagination={false}
            rowKey="id"
            dataSource={dataSource}
            columns={generateColumns(type, statusType)}
        />
    );

    return (
        <Fade in>
            <Flex direction="column">
                <Box>
                    <Heading mb={2} as="h2" size="md">
                        Проектирование
                    </Heading>
                    <Collapse>
                        <Panel header="Статус заказов" key="1">
                            {renderTable('project', 'status', reports?.statuses?.project)}
                        </Panel>
                        <Panel header="Статус работ" key="2">
                            {renderTable('project', 'workStatus', reports?.workStatuses?.project)}
                        </Panel>
                        <Panel header="Ожидание согласования TSSR" key="3">
                            {renderTable('project', 'tssrs', reports?.tssrStatuses)}
                        </Panel>
                    </Collapse>
                </Box>
                <Box>
                    <Heading my={2} as="h2" size="md">
                        СМР
                    </Heading>
                    <Collapse>
                        <Panel header="Статус заказов" key="1">
                            {renderTable('smr', 'status', reports?.statuses?.smr)}
                        </Panel>
                        <Panel header="Статус работ" key="2">
                            {renderTable('smr', 'workStatus', reports?.workStatuses?.smr)}
                        </Panel>
                        <Panel header="Ожидание согласования мат.листа" key="3">
                            {renderTable('smr', 'matListStatuses', reports?.matListStatuses)}
                        </Panel>
                    </Collapse>
                </Box>
                <Box>
                    <Heading my={2} as="h2" size="md">
                        Итого
                    </Heading>
                    {renderTable('', 'types', reports?.total)}
                </Box>
            </Flex>
        </Fade>
    );
};

export default ReportOrders;
