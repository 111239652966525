import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, usePagination, Column } from 'react-table';
import _ from 'lodash';
import axios from 'axios';
// @chakra-ui
import {
    useToast,
    TableContainer,
    Flex,
    Link as ChakraLink,
    Badge,
    Spinner,
    Box,
    useDisclosure,
    Stack
} from '@chakra-ui/react';
// redux
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
// hooks
import useHeaderTitle from 'hooks/useHeaderTitle';
import { useTitle } from 'react-use';
import useGetExportJobData from 'hooks/useGetExportJobData';
// utils
import { generateTitle } from 'utils';
// components
import { TableCustom, TablePagination } from 'components/react-table';
import LoadingOverlay from 'components/LoadingOverlay';
// services
import { workService } from 'api/services';
import { WORK_STATUSES } from 'Routes/Works/utils/maps';
// sections
import useFilter from 'hooks/useFilter';
import { WorkTableToolbar } from './components';
import UploadModal from '../Sites/components/UploadModal';

export default function WorkList() {
    useTitle(generateTitle('Список ЭАП'));

    useHeaderTitle('Список ЭАП');

    const [isLoading, setIsLoading] = useState(false);

    const [works, setWorks] = useState([]);

    const [totalWorks, setTotalWorks] = useState(0);

    const [_pageCount, setPageCount] = useState(0);

    const [exportWorksJobDataId, setExportWorksJobDataId] = useState(null);

    const { loading: loadingExportWorks } = useGetExportJobData(exportWorksJobDataId, 'Экспорт_ЭАП');

    const { isOpen, onOpen, onClose } = useDisclosure();

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const user = useSelector(userSelector);

    const data = useMemo(() => works, [works]);

    const getColumns = () => [
        {
            accessor: 'site.s_reg',
            Header: 'Регион'
        },
        {
            accessor: 'id',
            Header: 'ID ЭАП',
            Cell: ({ cell: { value, row } }: any) => (
                <Stack gap={1}>
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        as={Link}
                        target="_blank"
                        className="order-link"
                        to={`/works/${row.original.id}`}
                    >
                        {value}
                    </ChakraLink>
                    {!row.original.workStatus?.cancelled && (
                        <ChakraLink
                            color="blue.600"
                            _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                            as={Link}
                            target="_blank"
                            className="order-link"
                            to={`/orders/create/${row.original.id}`}
                        >
                            Создать заказ
                        </ChakraLink>
                    )}
                </Stack>
            )
        },
        {
            accessor: 'site_id',
            Header: 'ID сайта'
        },
        { accessor: 'sitename', Header: 'Site name' },

        { accessor: 'project.title', Header: 'Проект' },
        { accessor: 'plannedHW', Header: 'planned HW' },
        { accessor: 'projectDate', Header: 'Год проекта' },
        { accessor: 'plannedBand', Header: 'planned Band' },
        {
            Header: 'Статус ЭАП',
            Cell: ({ cell: { row } }: any) => {
                const [color, text] = ['red', WORK_STATUSES[`${row.original?.workStatus.current}`]];
                return (
                    <Badge variant="subtle" colorScheme={color}>
                        {text}
                    </Badge>
                );
            }
        },
        { accessor: 'work_type', Header: 'Тип ЭАП' },
        {
            accessor: 'updated_at',
            Header: 'Дата изменения статуса',
            Cell: ({ cell: { value } }: any) => new Date(value).toLocaleString('ru-RU')
        }
    ];

    const columns = useMemo(() => getColumns(), []) as Column[];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                sortBy: [{ id: 'updated_at', desc: true }]
            },
            stateReducer(newState, action) {
                if (action.type === 'toggleSortBy') {
                    return { ...newState, pageIndex: 0 };
                }

                return newState;
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount: _pageCount,
            autoResetPage: false,
            autoResetSortBy: false
        },
        useSortBy,
        usePagination
    );

    const { filter, debouncedFilter, handleSetFilter, isDisabledFilter, resetFilters } = useFilter({
        onChangeCallback: () => {
            gotoPage(0);
        }
    });

    const getWorks = () => {
        const params = {
            is_new: 'true',
            isRollout: 'false',
            _limit: pageSize,
            _start: pageSize * pageIndex,
            _sort: sortBy.length ? `${sortBy[0].id}:${sortBy[0].desc ? 'DESC' : 'ASC'}` : null,
            ...filter
        };

        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

        return workService.list({ params: finalParams });
    };

    const getTotalWorks = () => {
        const params = {
            is_new: 'true',
            isRollout: 'false',
            ...filter
        };

        // Removing empty values from params
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

        return workService.count({ params: finalParams });
    };

    const fetchAPIData = () => {
        setIsLoading(true);

        const firstPromise = getWorks();

        const secondPromise = getTotalWorks();

        Promise.all([firstPromise, secondPromise])
            .then((responses) => {
                setWorks(responses[0].data);
                setTotalWorks(responses[1].data);
                setPageCount(Math.ceil(responses[1].data / pageSize));
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (axios.isCancel(err)) {
                    return;
                }
                toast({
                    status: 'error',
                    title: `Не удалось получить список ЭАП`
                });
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchAPIData();
    }, [pageIndex, pageSize, sortBy, debouncedFilter]);

    const handleExportWorks = () => {
        workService
            .exportData(data, { params: filter })
            .then((res) => {
                toast({
                    description: res.data.message,
                    status: 'success'
                });
                setExportWorksJobDataId(res.data.job_id);
                setExportWorksJobDataId(res.data.job_id);
            })
            .catch((err) => {
                console.error(err);
                toast({
                    description: 'Ошибка при выгрузке данных'
                });
            });
    };

    return (
        <Box bgColor="white" borderRadius={10} p={2}>
            <LoadingOverlay
                Spinner={<Spinner color="blue.500" />}
                active={isLoading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        overflow: 'auto',
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column'
                    })
                }}
            >
                <Flex h="100%" direction="column">
                    <WorkTableToolbar
                        filter={filter}
                        resetFilters={resetFilters}
                        isDisabledFilter={isDisabledFilter}
                        onChangeFilter={handleSetFilter}
                        onExportWorks={handleExportWorks}
                        loadingExportWorks={loadingExportWorks}
                        totalWorks={totalWorks}
                        showUpload={
                            user?.role?.name === 'Отдел строительства' ||
                            user?.role?.name === 'Отдел эксплуатации' ||
                            user?.role?.name === 'Отдел планирования радиосети' ||
                            user?.role?.name === 'Отдел оптимизации сети' ||
                            user?.role?.name === 'Отдел планирования трансмиссионной сети'
                        }
                        upload={onOpen}
                    />

                    <TableContainer sx={{ flexGrow: 1, overflowY: 'auto', height: '100%', padding: 1.5 }}>
                        <TableCustom
                            size="sm"
                            getTableProps={getTableProps}
                            headerGroups={headerGroups}
                            getTableBodyProps={getTableBodyProps}
                            page={page}
                            prepareRow={prepareRow}
                        />
                    </TableContainer>

                    <TablePagination
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        pageCount={pageCount}
                        previousPage={previousPage}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        pageSizeOptions={[5, 10, 25]}
                        setPageSize={setPageSize}
                    />
                </Flex>
            </LoadingOverlay>
            <UploadModal
                onUploadSuccess={fetchAPIData}
                onClose={onClose}
                isOpen={isOpen}
                title="Загрузка ЭАП"
                uploadUrl="/works/massUpload"
                exportBtnLabel="Импорт ЭАП"
            />
        </Box>
    );
}
