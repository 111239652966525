import { EAcceptanceDocsStatuses, EOrderStatuses, EOrderWorkStatuses } from './enums';

// eslint-disable-next-line import/prefer-default-export
export const orderAcceptanceDocsStatusesMap: Record<
    EAcceptanceDocsStatuses,
    { label: string; color: string }
> = {
    awaitingConfirmation: {
        label: 'Ожидает согласования',
        color: 'orange'
    },
    returnedForRevision: {
        label: 'Возвращен на доработку',
        color: 'red'
    },
    confirmed: {
        label: 'Подтвержден',
        color: 'green'
    },
    notLoaded: {
        label: 'Не загружен',
        color: 'gray'
    }
};

export const orderStatusesMap: Record<EOrderStatuses, { label: string; color: string }> = {
    [EOrderStatuses.cancelled]: {
        label: 'Отменен',
        color: 'red'
    },
    [EOrderStatuses.returnedWithComments]: {
        label: 'Возвращен с комментариями',
        color: 'orange'
    },
    [EOrderStatuses.waitingApprove]: {
        label: 'Ожидает подтверждения ГПО',
        color: 'purple'
    },
    [EOrderStatuses.revisionApprove]: {
        label: 'Согласование корректировки заказа с ГПО',
        color: 'purple'
    },
    [EOrderStatuses.approved]: {
        label: 'Согласовано с ГПО',
        color: 'green'
    },
    [EOrderStatuses.onConfirmation]: {
        label: 'Ожидает согласования и проверки',
        color: 'purple'
    },
    [EOrderStatuses.completed]: {
        label: 'Завершён',
        color: 'green'
    },
    [EOrderStatuses.waitingCancelApprove]: {
        label: 'Согласование отмены заказа с ГПО',
        color: 'red'
    },
    [EOrderStatuses.subcSigning]: {
        label: 'На подписании ГПО',
        color: 'purple'
    },
    [EOrderStatuses.signing]: {
        label: 'На подписании Kcell',
        color: 'purple'
    },
    [EOrderStatuses.waitingSubcAssign]: {
        label: 'Ожидает назначения подрядчика отделом проектирования и монтажа',
        color: 'red'
    }
};
export const orderWorkStatusesMap: Record<EOrderWorkStatuses, { label: string; color: string }> = {
    [EOrderWorkStatuses.projectUploaded]: {
        label: 'Проект/эскиз загружен',
        color: 'purple'
    },
    [EOrderWorkStatuses.equipmentAndProjectNotRequired]: {
        label: 'Оборудование и проект не требуется',
        color: 'purple'
    },
    [EOrderWorkStatuses.equipmentNotRequired]: {
        label: 'Оборудование не требуются',
        color: 'purple'
    },
    [EOrderWorkStatuses.cancelled]: {
        label: 'Отменен',
        color: 'red'
    },
    [EOrderWorkStatuses.notStarted]: {
        label: 'Работа не начата',
        color: 'gray'
    },
    [EOrderWorkStatuses.tssrApproval]: {
        label: 'Проект на согласовании',
        color: 'purple'
    },
    [EOrderWorkStatuses.returnedForRevision]: {
        label: 'Проект возвращён на доработку',
        color: 'orange'
    },
    [EOrderWorkStatuses.agreed]: {
        label: 'Проект согласован',
        color: 'purple'
    },
    [EOrderWorkStatuses.matListRework]: {
        label: 'Мат. лист на доработке',
        color: 'orange'
    },
    [EOrderWorkStatuses.matListVerification]: {
        label: 'Мат. лист на проверке',
        color: 'purple'
    },
    [EOrderWorkStatuses.matListApproved]: {
        label: 'Мат. лист согласован',
        color: 'purple'
    },
    [EOrderWorkStatuses.onWarehouse]: {
        label: 'Выдача оборудования',
        color: 'purple'
    },
    [EOrderWorkStatuses.equipmentIssued]: {
        label: 'Оборудование выдано',
        color: 'purple'
    },
    [EOrderWorkStatuses.inProgress]: {
        label: 'В работе',
        color: 'purple'
    },
    [EOrderWorkStatuses.techActVerification]: {
        label: 'Тех. акт на проверке',
        color: 'purple'
    },
    [EOrderWorkStatuses.techActRevision]: {
        label: 'Тех. акт на доработке',
        color: 'orange'
    },
    [EOrderWorkStatuses.techActApproved]: {
        label: 'Тех. акт согласован',
        color: 'purple'
    },
    [EOrderWorkStatuses.commissioningCertUploaded]: {
        label: 'АВЭ загружен',
        color: 'purple'
    },
    [EOrderWorkStatuses.commissioningCertVerification]: {
        label: 'АВЭ на проверке',
        color: 'purple'
    },
    [EOrderWorkStatuses.commissioningCertRevision]: {
        label: 'АВЭ на доработке',
        color: 'orange'
    },
    [EOrderWorkStatuses.commissioningCertApproved]: {
        label: 'АВЭ согласован',
        color: 'purple'
    },
    [EOrderWorkStatuses.completed]: {
        label: 'Работа завершена',
        color: 'green'
    },
    [EOrderWorkStatuses.cancelled]: {
        label: 'Отменена',
        color: 'red'
    }
};

const matListStatusMap = {
    created: 'Создание',
    confirmation_on_oe: 'Эксплуатация',
    confirmation_on_tr: 'Трансмиссия',
    confirmation_on_ko: 'Контроль оборудования',
    waiting_tr: 'Загрузка TR',
    processing_tr: 'Обработка TR',
    done: 'Выдано'
};
export default matListStatusMap;
