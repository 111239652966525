import React, { useEffect, useState } from 'react';
import { Tabs, Spin } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, useToast } from '@chakra-ui/react';
import { siteService } from 'api/services';
import { ESiteActivityStatus, ISite, SiteActivityStatusDictionary } from 'interfaces/sites';

const { TabPane } = Tabs;
interface IProps {
    children: React.ReactNode;
}
const activityStatusColors: { [key: string]: string } = {
    [ESiteActivityStatus.ACTIVE]: 'green',
    [ESiteActivityStatus.DISMANTLED]: 'red',
    [ESiteActivityStatus.READY_TO_DISMANTLE]: 'yellow',
    [ESiteActivityStatus.PENDING]: 'grey'
};
const SiteLayout: React.FC<IProps> = ({ children }) => {
    const { siteID, trueSiteID } = useParams<{ siteID: string; trueSiteID: string }>();
    const [sites, setSites] = useState<ISite[]>([]);
    const [currentSiteActivityStatus, setCurrentSiteActivityStatus] = useState<string>('active');
    const [loading, setLoading] = useState<boolean>(true);
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const navigate = useNavigate();

    const fetchSitesBySiteId = async () => {
        setLoading(true);
        try {
            const params = {
                s_new_site_id_eq: siteID
            };
            const response = await siteService.list({ params });
            const sortedSites = response.data.sort((a: ISite, b: ISite) => {
                const statusOrder = { pending: 1, active: 2, ready_to_dismantle: 3, dismantled: 4 };
                return statusOrder[a.activityStatus] - statusOrder[b.activityStatus];
            });
            setSites(sortedSites);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast({
                status: 'error',
                title: 'Ошибка при получении данных сайтов'
            });
        } finally {
            setLoading(false);
        }
    };

    const findSiteActivityStatus = (trueSiteID: string) => {
        const site = sites.find((site) => site.id === parseInt(trueSiteID, 10));
        return site?.activityStatus || 'active';
    };

    const handleTabChange = (key: string) => {
        navigate(`/sites/${siteID}/${key}`);
    };

    useEffect(() => {
        fetchSitesBySiteId();
    }, [siteID]);

    useEffect(() => {
        if (sites.length === 0) return;
        setCurrentSiteActivityStatus(findSiteActivityStatus(trueSiteID as string) || 'active');
    }, [trueSiteID, sites]);

    return (
        <Spin spinning={loading}>
            <Tabs
                defaultActiveKey={trueSiteID}
                onChange={handleTabChange}
                activeKey={trueSiteID}
                style={{ backgroundColor: 'white', padding: '5px 15px', marginBottom: 15, borderRadius: 15 }}
            >
                {sites.map((site) => (
                    <TabPane
                        tab={`${site.sitename} (${SiteActivityStatusDictionary[site.activityStatus]})`}
                        key={site.id}
                    />
                ))}
            </Tabs>
            <Box
                style={{
                    position: 'relative'
                }}
            >
                {children}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        backgroundColor: activityStatusColors[currentSiteActivityStatus],
                        opacity: 0.1,
                        pointerEvents: 'none',
                        borderRadius: 15
                    }}
                />
            </Box>
        </Spin>
    );
};

export default SiteLayout;
