import { Box } from '@chakra-ui/react';
import Link from 'new-components/Link/Link';

/**
 * @component
 */
const AuthRegistrationLink = () => (
    <Box display="inline-flex">
        Нет аккаунта?&nbsp;
        <Link to="/request-account">Отправьте запрос на регистрацию</Link>
    </Box>
);

export default AuthRegistrationLink;
