interface Constants {
    dropdownData: { [key: string]: Array<string> };
}

const constants: Constants = {
    dropdownData: {
        equipment_s_unit: ['Шт.', 'М'],
        s_reg: ['Almaty', 'North&Central', 'East', 'South', 'West'],
        s_district: [
            'Алматинская обл',
            'Жамбылская обл',
            'Карагандинская обл',
            'Жетысуская обл',
            'Акмолинская обл',
            'Улытауская обл',
            'Северо-Казахстанская',
            'Костанайская обл',
            'Карагандинская обл',
            'Павлодарская обл',
            'Восточно-Казахстанская обл',
            'Абайская обл',
            'Шымкент и Туркестанская обл',
            'Жамбылская обл',
            'Кызылординская обл',
            'Мангистауская обл',
            'Атырауская обл',
            'Актюбинская обл',
            'Западно-Казахстанская обл'
        ],
        s_type: [
            '5G co-location',
            '4G co-location',
            '3G co-location',
            'NCP',
            'Modernization',
            'existing configuration',
            'Swap ZTE to E + 4G co-location',
            'Swap to MIMO',
            'Swap 3000 to 6630',
            'NewNCP on T2',
            'Band Expansion',
            'SWAP ZTE to Ericsson'
        ],
        s_main_accident_rate: ['1', '2', '3', '4']
    }
};

export const ORDER_STATUSES = {
    CANCELED: 'Заказ отклонен',
    CREATED: 'Ожидает подтверждения МТС',
    APPROVED_BY_OS: 'Ожидает согласования в 1C',
    WAITING_HEADS_APPROVE: 'Ожидает подтверждения начальства МТС',
    REVISION_BY_SUBC: 'Ожидает подтверждения ГПО',
    APPROVED_BY_SUBC: 'Принят ГПО',
    WAITINGS_SIGNS_OS: 'Ожидание подписей',
    SIGNED: 'Заказ подписан',
    COMPLETED: 'Завершен'
};

export const ACT_STATUSES = {
    CANCELED: 'Акт отклонен',
    CREATED: 'Ожидает подтверждения МТС',
    APPROVED_BY_OS: 'Ожидает согласования в 1C',
    REVISION_BY_SUBC: 'Ожидает подтверждения ГПО',
    WAITINGS_SIGNS_BY_SUBC: 'Ожидает подписи ГПО',
    REVISION_BY_OR: 'Ожидает подтверждения ОА',
    APPROVED_BY_SUBC: 'Принят ГПО',
    WAITINGS_SIGNS_OS: 'Ожидание подписей',
    SIGNED: 'Акт подписан',
    COMPLETED: 'Завершен'
};

export const ORDER_STEPS = {
    CANCELED: 0,
    CREATED: 1,
    APPROVED: 2,
    AGREED: 3,
    SIGNED: 4
};

export const ACT_STEPS = {
    CANCELED: 0,
    CREATED: 1,
    APPROVED: 2,
    AGREED: 3,
    SIGNED: 4
};

export const ONECSTEPS = {
    WAITING_SYNC: 0,
    INPROCESS: 1,
    COMPLETED: 2,
    FAILED: 3,
    QUEUE: 4
};

export const ONEC_STEPS = {
    NOTSYNCED: 'notSynced',
    INPROCESS: 'inprocess',
    COMPLETED: 'completed',
    FAILED: 'failed',
    QUEUE: 'queue'
};

export const ARRAY_SUM = {
    1: 'до 5 млн',
    2: 'от 5 до 10 млн',
    3: 'от 10 до 20 млн',
    4: 'выше 20 млн'
};

export const REQUEST_STATUSES = {
    NOTSYNCED: 'notSynced',
    INPROCESS: 'inprocess',
    COMPLETED: 'completed',
    FAILED: 'failed'
};

export enum ERoleNames {
    OE = 'Отдел эксплуатации',
    PROJMAN = 'Специалист по проектам',
    LEASING = 'Отдел договоров аренды',
    OS = 'Отдел строительства',
    TR = 'Отдел планирования трансмиссионной сети',
    LEGAL = 'Группа лицензии и разрешительных документов',
    KO = 'Отдел контроля оборудования',
    SKLAD = 'Склад',
    RNP = 'Отдел планирования радиосети',
    RNO = 'Отдел оптимизации сети',
    DESIGN = 'Группа проектирования инфраструктуры сети',
    SUBC = 'Подрядная организация',
    ADMIN = 'ADMIN',
    OPM = 'Отдел проектирования и монтажа',
    EXECUTOR = 'Субподрядчик',
    ADMIN_OE = 'Администратор эксплуатации',
    SZ = 'Группа проверки и согласования заказов'
}

export const ENGINEERS_GROUP_ROLES = [
    ERoleNames.OE,
    ERoleNames.OS,
    ERoleNames.TR,
    ERoleNames.DESIGN,
    ERoleNames.ADMIN_OE
];

export const ACTION_TYPES = {
    upload_doc: 'Загружен документ',
    change_doc: 'Изменен документ',
    change_status: 'Изменен статус',
    generateDoc: 'Документ сгенерирован',
    startProcess: 'Процесс запущен'
};

export const MAT_LIST_STATUSES = {
    created: 'Создание',
    confirmation_on_oe: 'Эксплуатация',
    confirmation_on_tr: 'Трансмиссия',
    confirmation_on_ko: 'Контроль оборудования',
    waiting_tr: 'Загрузка TR',
    processing_tr: 'Обработка TR',
    done: 'Выдано'
};

export const STATUSES = {
    awaitingConfirmation: {
        label: 'Ожидает согласования',
        color: 'orange'
    },
    returnedForRevision: {
        label: 'Возвращен на доработку',
        color: 'orange'
    },
    confirmed: {
        label: 'Подтвержден',
        color: 'green'
    }
};

export const TAG_FILTERS_MAP: { [key: string]: { [value: string]: string } } = {
    'workStatus.uploadRsd_null': {
        true: 'Не загружен RSD'
    },
    'workStatus.uploadTsd_null': {
        true: 'Не загружен TSD'
    },
    'workStatus.prepareSar_null': {
        true: 'Не загружен SAR'
    },
    'workStatus.readyForOrder': {
        true: 'Готов к созданию заказов'
    },
    'workStatus.hasOrders': {
        true: 'Есть заказы в работе'
    },
    'project_null': {
        true: 'Без проекта'
    },
    'workStatus.cancelled': {
        true: 'Отменены'
    },
    'workType.type': {
        project: 'Проектировние',
        smr: 'СМР'
    },
    'detail.isProcessStarted': {
        true: 'Заказ был согласован с ГПО'
    },
    'tssr_dt_approve_null': {
        true: 'TSSR ожидает решения группы проектирования инфраструктуры сети'
    },
    'tssr_legal_approve_null': {
        true: 'TSSR ожидает решения группы лицензии и разрешительных документов'
    },
    'tssr_oe_approve_null': {
        true: 'TSSR ожидает решения отдела эксплуатации'
    },
    'tssr_leasing_approve_null': {
        true: 'TSSR ожидает решения отдела договоров аренды'
    },
    'tssr_tr_approve_null': {
        true: 'TSSR ожидает решения отдела трансимиссионной сети'
    },
    'tssr_rp_approve_null': {
        true: 'TSSR ожидает решения отдела планирования радиосети'
    },
    'matList.status': {
        confirmation_on_ko: 'Мат. лист на проверке отделом контроля оборудования',
        confirmation_on_tr: 'Мат. лист на проверке отделом трансмиссионной сети',
        confirmation_on_oe: 'Мат. лист на проверке отделом эксплуатации'
    }
};

export default constants;
