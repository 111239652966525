import axios from 'axios';
import { IItemsToIssue } from '../../Routes/Order/MatList/IssuanceTr';

const getList = (matList?: number) => axios.get('/mat-list-trs', { params: { matList } });
const getTrWithEquipment = (id: number) => axios.get(`/mat-list-trs/${id}`);
const upload = (formData: FormData) => axios.post('/mat-list-trs/upload', formData);
const nextStep = (id: number, data: { issuanceEquipment?: IItemsToIssue } | FormData) =>
    axios.post<void, { data: { status: string; isAllTrFinished?: boolean } }>(
        `/mat-list-trs/${id}/next-status`,
        data
    );

export default {
    getTrWithEquipment,
    getList,
    upload,
    nextStep
};
