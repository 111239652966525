import axios from 'axios';
import { IEquipmentCore } from 'Routes/Order/utils/interfaces/MatList';

const getByEquipmentId = (equipmentId: string) => axios.get(`equip-directories/${equipmentId}`);
const updateEquipment = (equipmentId: string, field: string, value: string) =>
    axios.put(`equip-directories/${equipmentId}`, { field, value });
const deleteEquipment = (id: string) => axios.delete(`equip-directories/${id}`);
const addEquipment = (equipmentData: IEquipmentCore) => axios.post(`equip-directories/`, equipmentData);

export default {
    getByEquipmentId,
    updateEquipment,
    deleteEquipment,
    addEquipment
};
