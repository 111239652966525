import {
    Button,
    ButtonGroup,
    FormControl,
    FormErrorMessage,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Stack
} from '@chakra-ui/react';
import { Select } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import useDebounce from 'hooks/useDebounce';
import Label from 'new-components/ui/Label';
import { IEquipment, IMatListEquipmentForm } from '../../utils/interfaces/MatList';

const initialValues: IMatListEquipmentForm = {
    equipment: '',
    siteId: '',
    qty: 1
};

interface IProps {
    onCancel: () => void;
    onSubmit: (values: IMatListEquipmentForm) => void;
}
const MatListEquipmentForm: FC<IProps> = ({ onCancel, onSubmit }) => {
    const [equipmentOptions, setEquipmentOptions] = useState<IEquipment[]>([]);
    const [equipmentSearchText, setEquipmentSearchText] = useState<string>('');
    const debouncedSiteIdSearchText = useDebounce(equipmentSearchText, 700);
    const formik = useFormik({
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            onSubmit(values);
        }
    });

    const handleSearchEquipmentOptions = (searchText: string) => {
        if (!searchText) {
            setEquipmentOptions([]);
            return;
        }
        axios.get(`/equip-directories?sap_code_contains=${searchText}`).then((res) => {
            if (res.data.length > 0) {
                setEquipmentOptions(res.data);
            }
        });
    };

    useEffect(() => {
        handleSearchEquipmentOptions(debouncedSiteIdSearchText);
    }, [debouncedSiteIdSearchText]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack alignItems="center" direction="row">
                <FormControl isRequired>
                    <Label text="SAP код" />
                    <Select
                        placeholder="Введите для поиска"
                        style={{ minWidth: '320px' }}
                        optionFilterProp="children"
                        showSearch
                        value={formik.values.equipment}
                        onSearch={(value) => {
                            setEquipmentSearchText(value);
                        }}
                        onChange={(value) => {
                            formik.setFieldValue('equipment', value);
                        }}
                    >
                        {equipmentOptions.map((opt) => (
                            <Select.Option value={opt.id} key={`equipment-opt-${opt.id}`}>
                                {opt.sap_code}
                            </Select.Option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.siteId && (formik.touched.siteId as boolean)}>
                    <Label text="ID сайта" />
                    <Input
                        size="sm"
                        name="siteId"
                        onChange={formik.handleChange}
                        value={formik.values.siteId}
                        disabled={formik.isSubmitting}
                        placeholder="Введите ID сайта"
                    />
                    <FormErrorMessage mt="0.5rem">{formik.errors.siteId}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                    <Label text="Количество" />
                    <NumberInput
                        allowMouseWheel
                        size="sm"
                        min={1}
                        width="auto"
                        name="qty"
                        onChange={(value) => {
                            formik.setFieldValue('qty', value);
                        }}
                        value={formik.values.qty}
                    >
                        <NumberInputField
                            placeholder="Количество"
                            sx={{
                                borderColor: 'gray.200',
                                paddingInlineStart: 'var(--chakra-space-1-5)'
                            }}
                        />
                        <NumberInputStepper borderColor="gray.200">
                            <NumberIncrementStepper onChange={formik.handleChange} />
                            <NumberDecrementStepper onChange={formik.handleChange} />
                        </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <ButtonGroup spacing={3} ml={2}>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="submit" colorScheme="green">
                        Добавить
                    </Button>
                </ButtonGroup>
            </Stack>
        </form>
    );
};

export default MatListEquipmentForm;
