import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { matListTrsService } from 'api/services';
import { ITrDetail } from '../../utils/interfaces/MatList';

interface IProps {
    onClose: () => void;
    trId: number;
}
const TrDetailModal: FC<IProps> = ({ onClose, trId }) => {
    const [tr, setTr] = useState<null | ITrDetail>(null);
    const toast = useToast();

    useEffect(() => {
        matListTrsService
            .getTrWithEquipment(trId)
            .then((res) => {
                setTr(res.data);
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не получить информацию по TR'
                });
            });
    }, []);

    if (!tr) return null;
    return (
        <Modal isOpen onClose={onClose} size="6xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderBottom="1px solid" borderColor="gray.300">
                    Информация о TR #{tr.sapNumber}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>#</Th>
                                    <Th>SAP код</Th>
                                    <Th>код продукта</Th>
                                    <Th>наименование</Th>
                                    <Th>запрошено</Th>
                                    <Th>{tr.status === 'ready_to_receive' ? 'Готово к выдаче' : 'Выдано'}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tr?.trEquipments.map((item, i) => (
                                    <Tr>
                                        <Td>{i + 1}</Td>
                                        {item.matListEquipment.equipment && (
                                            <>
                                                <Td>{item.matListEquipment.equipment.sap_code}</Td>
                                                <Td>{item.matListEquipment.equipment.s_product_code}</Td>
                                                <Td>{item.matListEquipment.equipment.s_equip_title}</Td>
                                                <Td>
                                                    {item.qty} {item.matListEquipment.equipment.s_unit}{' '}
                                                </Td>
                                                <Td>
                                                    {item.issuedQty} {item.matListEquipment.equipment.s_unit}{' '}
                                                </Td>
                                            </>
                                        )}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default TrDetailModal;
