/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import _ from 'lodash';
import { useTable, useSortBy, usePagination, useAsyncDebounce } from 'react-table';
// @chakra-ui
import { useToast, TableContainer, Flex, Button, Box, Spinner, useDisclosure } from '@chakra-ui/react';
// redux
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
// hooks
import { useTitle } from 'react-use';
import useHeaderTitle from 'hooks/useHeaderTitle';
// components
import LoadingOverlay from 'components/LoadingOverlay';
import { TablePagination, TableCustom } from 'components/react-table';
// swr
import useSWR from 'swr';
// utils
import { generateTitle } from 'utils';
import { UploadChangeParam } from 'antd/es/upload';
import { fetcher } from 'utils/client';
import allowedFields from 'Routes/Sites/SiteList/tableSettings';
// services
import { siteService } from 'api/services';
// sections
import { SiteTableToolbar } from '../components';
//
import './SiteList.css';
import RSDErrorsModal from '../components/RSDErrorsModal';
import UploadModal from '../components/UploadModal';

// ----------------------------------------------------------------------

let source: CancelTokenSource | null = null;
interface Field {
    field: string;
    title: string;
    render?: ({ cell: { value, row } }: { cell: { value: any; row: any } }) => JSX.Element;
}
export default function SiteList() {
    useTitle(generateTitle('Сайты'));

    useHeaderTitle('Просмотр списка сайтов');

    const [isLoading, setIsLoading] = useState(false);

    const [parametersSites, setParametersSites] = useState('');

    const [parametersTotalSites, setParametersTotalSites] = useState('');

    const [filterSiteId, setFilterSiteId] = useState('');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filterRegion, setFilterRegion] = useState<Array<string>>([]);

    const [_pageCount, setPageCount] = useState(0);

    const [loadingExportSSD, setLoadingExportSSD] = useState(false);

    const [loadingExportSites, setLoadingExportSites] = useState(false);

    const [loadingExportRSD, setLoadingExportRSD] = useState(false);

    const [RSDErrors, setRSDErrors] = useState({ message: null, details: [] });

    const { isOpen, onOpen, onClose } = useDisclosure();

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const [searchParams] = useSearchParams();

    const department = searchParams.get('department');
    const type = searchParams.get('type');

    const user = useSelector(userSelector);

    const { data: sites } = useSWR(
        parametersSites
            ? `${parametersSites}
            `
            : null,
        fetcher
    );

    const { data: totalSites } = useSWR(
        parametersTotalSites
            ? `${parametersTotalSites}
            `
            : null,
        fetcher
    );

    const data = useMemo(() => sites, [sites]);

    const getColumns = (allowedFields: Field[]) => {
        const cols: any = [];

        if (!allowedFields.length) return cols;

        allowedFields.forEach(({ field, title, render }: Field) => {
            const col = {
                accessor: field,
                Header: title,
                disableSortBy: true, // или поставить false если сортировка допустима
                Cell:
                    render ||
                    // eslint-disable-next-line react/no-unused-prop-types
                    (({ cell: { value } }: { cell: { value: any } }) => {
                        if (typeof value === 'string' || typeof value === 'number') {
                            return value;
                        }
                        return '-';
                    })
            };

            if (field === 's_new_site_id') {
                cols.splice(1, 0, col);
            } else {
                cols.push(col);
            }
        });

        if (cols.length > 1) {
            cols[1].headerClassName = 'sticky_th';
            cols[1].className = 'sticky_td';
        }

        return cols;
    };

    const columns = useMemo(() => getColumns(allowedFields), [allowedFields]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        // pagination
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            columns,
            data: data || [],
            initialState: { pageIndex: 0, sortBy: [{ id: 'updated_at', desc: true }] },
            stateReducer(newState, action) {
                if (action.type === 'toggleSortBy') {
                    return { ...newState, pageIndex: 0 };
                }

                return newState;
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount: _pageCount,
            autoResetPage: false,
            autoResetSortBy: false
        },
        useSortBy,
        usePagination
    );

    const getSitesSWR = ({
        _limit,
        _start,
        _sort,
        s_new_site_id_contains,
        subc,
        s_reg_in,
        department,
        type
    }: any) => {
        const params = {
            is_new: 'true',
            start: _start,
            limit: _limit,
            sort: _sort,
            s_new_site_id_contains,
            subc,
            s_reg_in
        };

        // Removing empty values from params
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
        const finalParamsString = `${
            department && type ? `/report/${department}/${type}?` : `/sites?`
        }_start=${finalParams?.start}&activityStatus_eq=active&_sort=${finalParams?.sort}&_limit=${
            finalParams?.limit
        }${finalParams?.is_new ? `&is_new=${finalParams?.is_new}` : ''}${
            finalParams?.s_new_site_id_contains
                ? `&s_new_site_id_contains=${finalParams?.s_new_site_id_contains}`
                : ''
        }${finalParams?.subc ? `&subc=${finalParams?.subc}` : ''}${
            finalParams?.s_reg_in ? `&s_reg_in=${finalParams?.s_reg_in}` : ''
        }`;
        const finalParamsTotalString = `${
            department && type ? `/report/${department}/${type}/count?` : `/sites/count?`
        }${finalParams?.is_new ? `&is_new=${finalParams?.is_new}` : ''}${
            finalParams?.s_new_site_id_contains
                ? `&s_new_site_id_contains=${finalParams?.s_new_site_id_contains}`
                : ''
        }${finalParams?.subc ? `&subc=${finalParams?.subc}` : ''}${
            finalParams?.s_reg_in ? `&s_reg_in=${finalParams?.s_reg_in}` : ''
        }
                `;

        setParametersSites(finalParamsString);
        setParametersTotalSites(finalParamsTotalString);
    };

    const fetchAPIData = ({
        _limit,
        _start,
        _sort,
        // pageSize,
        s_new_site_id_contains,
        subc,
        s_reg_in,
        department,
        type,
        cancelToken
    }: any) => {
        setIsLoading(true);
        getSitesSWR({
            _limit,
            _start,
            _sort,
            s_new_site_id_contains,
            subc,
            s_reg_in,
            department,
            type,
            cancelToken
        });
    };

    const fetchData = useCallback(
        ({ pageSize, pageIndex, sortBy, filterSiteId, filterRegion, department, type, cancelToken }) => {
            fetchAPIData({
                _limit: pageSize,
                _start: pageSize * pageIndex,
                _sort: sortBy.length ? `${sortBy[0].id}:${sortBy[0].desc ? 'DESC' : 'ASC'}` : null,
                subc: user.role?.type === 'other' ? user.data.subc.id : null,
                s_new_site_id_contains: filterSiteId,
                s_reg_in: filterRegion.length ? filterRegion : null,
                pageSize,
                department,
                type,
                cancelToken
            });
        },
        []
    );

    useEffect(() => {
        if (sites) {
            setIsLoading(false);
        }
    }, [sites]);

    useEffect(() => {
        if (totalSites) {
            setPageCount(Math.ceil(totalSites / pageSize));
        }
    }, [totalSites]);

    const fetchSites = () => {
        if (source) {
            source.cancel();
        }
        source = axios.CancelToken.source();
        const cancelToken = source.token;

        fetchData({ pageIndex, pageSize, sortBy, filterSiteId, filterRegion, department, type, cancelToken });
    };

    useEffect(() => {
        fetchSites();
    }, [fetchData, pageIndex, pageSize, sortBy, filterSiteId, filterRegion, department, type]);

    /*    const handleFilterRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = filterRegion.includes(event.target.value)
            ? filterRegion.filter((value: any) => value !== event.target.value)
            : [...filterRegion, event.target.value];

        setFilterRegion(newValue);
        gotoPage(0);
    }; */

    const handleFilterSiteId = useAsyncDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterSiteId(event.target.value);
        gotoPage(0);
    }, 500);

    const handleExportSites = () => {
        setLoadingExportSites(true);
        const data = department && type ? { department, type } : {};
        siteService
            .exportData(data)
            .then((res) => {
                setLoadingExportSites(false);
                toast({
                    description: res.data.message,
                    status: 'success'
                });
            })
            .catch((err) => {
                console.error(err);
                setLoadingExportSites(false);
                toast({
                    description: 'Ошибка при выгрузке данных',
                    status: 'error'
                });
            });
    };

    const uploadRSD = async (data: UploadChangeParam) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('field', 'files');
        // @ts-ignore
        formData.append('file', data.file);

        try {
            await axios.post('/rsds/massUpload', formData);
        } catch (err: any) {
            setRSDErrors(err.response.data.message);
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
        toast({
            id: 'file-upload-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'RSD успешно импортирован'
        });
    };

    const onModalClose = () => {
        setRSDErrors({ message: null, details: [] });
    };

    const handleExportSSD = () => {
        setLoadingExportSSD(true);
        const data = department && type ? { department, type } : {};
        axios
            .post(`/site-struct-details/exportInExcel`, data)
            .then((res) => {
                if (res) {
                    window.open(`${process.env.REACT_APP_API_BASE_URL}/${res.data.path}`);
                }
                setLoadingExportSSD(false);
            })
            .catch((err) => {
                console.error(err);
                setLoadingExportSSD(false);
                toast({
                    description: 'Ошибка при выгрузке данных',
                    status: 'error'
                });
            });
    };
    const handleExportRSD = () => {
        setLoadingExportRSD(true);
        axios
            .post(`/exportInExcel`)
            .then((res) => {
                if (res) {
                    window.open(`${process.env.REACT_APP_API_BASE_URL}/${res.data.path}`);
                }
                setLoadingExportRSD(false);
            })
            .catch((err) => {
                console.error(err);
                setLoadingExportRSD(false);
                toast({
                    description: 'Ошибка при выгрузке данных',
                    status: 'error'
                });
            });
    };

    return (
        <Box bgColor="white" borderRadius={10} p={2}>
            <LoadingOverlay
                Spinner={<Spinner color="blue.500" />}
                active={isLoading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        overflow: 'auto',
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column'
                    })
                }}
            >
                <Flex h="100%" direction="column">
                    <SiteTableToolbar
                        onFilterSiteId={handleFilterSiteId}
                        onExportSites={handleExportSites}
                        onExportSSD={handleExportSSD}
                        onExportRSD={handleExportRSD}
                        filterSiteId={filterSiteId}
                        filterRegion={filterRegion}
                        totalSites={totalSites}
                        loadingExportSSD={loadingExportSSD}
                        loadingExportSites={loadingExportSites}
                        loadingExportRSD={loadingExportRSD}
                        showExportSSD={user?.role?.name !== 'Подрядная организация'}
                        showExportRSD={_.includes(
                            ['Отдел оптимизации сети', 'Отдел планирования радиосети', 'ADMIN'],
                            user?.role?.name
                        )}
                        showUploadRSD={_.includes(
                            ['Отдел оптимизации сети', 'Отдел планирования радиосети', 'ADMIN'],
                            user?.role?.name
                        )}
                        showUpload={
                            user?.role?.name === 'Отдел строительства' ||
                            user?.role?.name === 'Отдел эксплуатации' ||
                            user?.role?.name === 'Отдел планирования радиосети' ||
                            user?.role?.name === 'ADMIN'
                        }
                        uploadRsd={uploadRSD}
                        upload={onOpen}
                    />

                    <TableContainer sx={{ flexGrow: 1, overflowY: 'auto', height: '100%', padding: 1.5 }}>
                        <TableCustom
                            size="sm"
                            getTableProps={getTableProps}
                            headerGroups={headerGroups}
                            getTableBodyProps={getTableBodyProps}
                            page={page}
                            prepareRow={prepareRow}
                        />
                    </TableContainer>

                    <TablePagination
                        gotoPage={gotoPage}
                        canPreviousPage={canPreviousPage}
                        previousPage={previousPage}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        pageSizeOptions={[5, 10, 25]}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                    />
                </Flex>
            </LoadingOverlay>
            <RSDErrorsModal
                onClose={onModalClose}
                isOpen={Boolean(RSDErrors.message)}
                details={RSDErrors.details}
                message={RSDErrors.message}
            />
            <UploadModal
                onUploadSuccess={fetchSites}
                onClose={onClose}
                isOpen={isOpen}
                title="Загрузка сайтов"
                uploadUrl="/sites/massUpload"
                exportBtnLabel="Импорт сайтов"
            />
        </Box>
    );
}

interface ToggleCustomProps {
    children: JSX.Element;
}

function ToggleCustom({ children }: ToggleCustomProps) {
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => setToggle(!toggle);

    return (
        <Box>
            <Button onClick={handleToggle} size="sm">
                {toggle ? 'Скрыть' : 'Показать'}
            </Button>
            <Box display={toggle ? 'block' : 'none'} mt={1}>
                {children}
            </Box>
        </Box>
    );
}
