import { Box, Flex, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
import { ErrorMessage as EM, FieldAttributes, Field, useField, useFormikContext } from 'formik';
import React from 'react';
import DatePicker, { DatePickerProps } from '../../../components/DatePicker';

const ErrorMessage = EM as FieldAttributes<any>;

const DatePickerField = ({ ...props }: Partial<DatePickerProps>) => {
    const { setFieldValue } = useFormikContext();
    // @ts-ignore
    const [field] = useField(props);
    return (
        <DatePicker
            {...field}
            {...props}
            onChange={(val) => {
                setFieldValue(field.name, val);
            }}
        />
    );
};

interface MainFormProps {
    contragents: { [key: string]: string }[];
    priceRegions: any[];
    disabled?: boolean;
    existingPriceRegions: Record<string, string>[];
    onContragentsChange: (contragent: { [key: string]: string }) => void;
    clearTcp: () => void;
}

const MainForm = ({
    contragents,
    priceRegions,
    disabled,
    existingPriceRegions,
    onContragentsChange,
    clearTcp
}: MainFormProps) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();

    const priceRegionsHash = priceRegions.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
    return (
        <Box shadow="md" borderWidth="1px" my={3} p={3} bg="white" rounded="md">
            <FormControl>
                <Flex flexWrap="wrap">
                    <Box flex="0 1 330px" mr={2} my={1}>
                        <FormLabel htmlFor="startDate">Дата начала выполнения работ</FormLabel>
                        <DatePickerField name="startDate" disabled={disabled} />
                        <Box mt={1} color="red" fontSize="sm">
                            <ErrorMessage name="startDate" component="div" className="field-error" />
                        </Box>
                    </Box>
                    <Box flex="0 1 330px" mr={2} my={1}>
                        <FormLabel htmlFor="completeDate">Дата окончания выполнения работ</FormLabel>
                        <DatePickerField name="completeDate" disabled={disabled} />
                        <Box mt={1} color="red" fontSize="sm">
                            <ErrorMessage name="completeDate" component="div" className="field-error" />
                        </Box>
                    </Box>
                    <Box flex="0 1 330px" mr={2} my={1}>
                        <FormLabel htmlFor="allowedExpDays">Критическая просрочка(в днях)</FormLabel>
                        <Field
                            name="allowedExpDays"
                            type="number"
                            borderColor="gray.400"
                            as={Input}
                            disabled={disabled}
                        />
                        <Box mt={1} color="red" fontSize="sm">
                            <ErrorMessage name="allowedExpDays" component="div" className="field-error" />
                        </Box>
                    </Box>
                    <Box flex="0 1 330px" mr={2} my={1}>
                        <FormLabel htmlFor="contragent">Тип договора</FormLabel>
                        {!disabled ? (
                            <Field
                                id="contragent"
                                name="contragent"
                                bg="white"
                                placeholder="Выберите договор подряда"
                                variant="outline"
                                borderColor="gray.400"
                                as={Select}
                                disabled={disabled}
                                onChange={(e: any) => {
                                    setFieldValue('contragent', e.target.value);
                                    setFieldValue('price_region', '');
                                    clearTcp();
                                    setFieldTouched('price_region', true);
                                    onContragentsChange(contragents[e.target.value]);
                                }}
                            >
                                {Object.entries(contragents).map(([id, treaty_num]: any) => (
                                    <option key={id} value={id}>
                                        {treaty_num}
                                    </option>
                                ))}
                            </Field>
                        ) : (
                            <Box color="#818694" mt={1}>
                                {contragents[values.contragent] || ''}
                            </Box>
                        )}

                        <Box mt={1} color="red" fontSize="sm">
                            <ErrorMessage name="contragent" component="div" className="field-error" />
                        </Box>
                    </Box>
                    <Box flex="0 1 330px" mr={2} my={1}>
                        <FormLabel htmlFor="contragent">Ценовой регион</FormLabel>
                        {!disabled && existingPriceRegions ? (
                            <Field
                                id="price_region"
                                name="price_region"
                                bg="white"
                                placeholder="Выберите ценовой регион"
                                variant="outline"
                                borderColor="gray.400"
                                as={Select}
                                disabled={disabled}
                                onChange={(e: any) => {
                                    setFieldValue('price_region', e.target.value);
                                    clearTcp();
                                }}
                            >
                                {priceRegions
                                    ?.filter(({ name, treaty_num }: any) => {
                                        const filterePriceRegion =
                                            existingPriceRegions?.find(
                                                (exitingPriceRegion) => exitingPriceRegion.region === name
                                            ) && treaty_num === contragents[values.contragent];
                                        return filterePriceRegion;
                                    })
                                    .map(({ id, name }: any) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))}
                            </Field>
                        ) : (
                            <Box color="#818694" mt={1}>
                                {priceRegionsHash[values.price_region] || values.price_region || ''}
                            </Box>
                        )}

                        <Box mt={1} color="red" fontSize="sm">
                            <ErrorMessage name="price_region" component="div" className="field-error" />
                        </Box>
                    </Box>
                    <Box flex="0 1 330px" mr={2} my={1}>
                        <FormLabel htmlFor="exploitationDate">Срок ввода в эксплуатацию</FormLabel>
                        <Field
                            name="exploitationDate"
                            type="number"
                            borderColor="gray.400"
                            as={Input}
                            disabled={disabled}
                            onChange={(e: any) => {
                                setFieldValue('exploitationDate', e.target.value);
                            }}
                        />
                        <Box mt={1} color="red" fontSize="sm">
                            <ErrorMessage name="exploitationDate" component="div" className="field-error" />
                        </Box>
                    </Box>
                </Flex>
            </FormControl>
        </Box>
    );
};

export default MainForm;
