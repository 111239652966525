import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    Checkbox,
    FormLabel,
    Heading,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack
} from '@chakra-ui/react';
import { Upload } from 'antd';
import React, { FC, FormEventHandler, ReactNode, useState } from 'react';
import { EditIcon } from '@chakra-ui/icons';
import Label from 'new-components/ui/Label';
import HasAccess from 'guards/HasAccess';
import DatePicker from 'components/DatePicker';
import moment from 'moment/moment';
import { ERoleNames } from '../../../../constants';
import { EAcceptanceDocsStatuses, EAcceptanceDocsTypes } from '../../utils/enums';
import { IOrderAcceptanceDoc } from '../../utils/interfaces/OrderAcceptance';
import { orderAcceptanceDocsStatusesMap } from '../../utils/maps';
import OrderAcceptanceTechActFormFields from './OrderAcceptanceTechActFormFields';

interface IProps {
    onReject: () => void;
    title: string;
    hasResolveRoles: ERoleNames[];
    isDisabled?: boolean;
    hasUploadRoles: ERoleNames[];
    hasChangeRequired: ERoleNames[];
    onAdd: (id: number, data: FormData) => Promise<void>;
    doc: IOrderAcceptanceDoc;
    disabledStatus?: string | null;
    onApproveDoc: () => void;
    isOrderFinished: boolean;
    onChangeRequired: () => void;
    getHistory: (id: number) => void;
    children?: ReactNode;
}

const OrderAcceptanceDoc: FC<IProps> = ({
    children,
    title,
    isOrderFinished,
    disabledStatus,
    doc,
    onAdd,
    onReject,
    onChangeRequired,
    hasChangeRequired,
    isDisabled,
    hasResolveRoles,
    getHistory,
    onApproveDoc,
    hasUploadRoles
}) => {
    const [file, setFile] = useState<any>();
    const [date, setDate] = useState<Date>();
    const [startDate, setStartDate] = useState<Date>();
    const [signingtDate, setSigningDate] = useState<Date>();
    const [showForm, setShowForm] = useState(false);
    const onAddDocHandler: FormEventHandler = (e) => {
        e.preventDefault();
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file.file);
        if (doc.type === EAcceptanceDocsTypes.commissioningCert && date) {
            formData.append('date', date.toISOString());
        }
        if (doc.type === EAcceptanceDocsTypes.techAct) {
            if (signingtDate) {
                formData.append('signingDate', moment(signingtDate).format('YYYY-MM-DD'));
            }
            if (startDate) {
                formData.append('startDate', moment(startDate).format('YYYY-MM-DD'));
            }
        }
        onAdd(doc.id, formData).then(() => {
            setShowForm(false);
        });
    };

    return (
        <Stack gap={3}>
            {disabledStatus ? (
                <>
                    <Heading as="h3" colorScheme="gray" size="sm">
                        {title}
                        <Badge p={0.5} ml={1}>
                            {disabledStatus}
                        </Badge>
                    </Heading>
                </>
            ) : (
                <>
                    <div>
                        <Stack justifyContent="space-between" direction="row">
                            <Heading as="h3" size="sm">
                                {title}
                            </Heading>
                            <Stack>
                                <HasAccess roleNames={hasChangeRequired}>
                                    {doc.status !== EAcceptanceDocsStatuses.confirmed && (
                                        <Checkbox
                                            isDisabled={isOrderFinished || isDisabled}
                                            onChange={onChangeRequired}
                                            isChecked={!doc?.isRequired}
                                        >
                                            Не требуется
                                        </Checkbox>
                                    )}
                                </HasAccess>
                                {doc.esign && doc.isRequired && (
                                    <Button
                                        size="xs"
                                        onClick={() => getHistory(doc.id)}
                                        variant="outline"
                                        colorScheme="purple"
                                    >
                                        История
                                    </Button>
                                )}
                            </Stack>
                        </Stack>
                        {doc.isRequired ? (
                            <Badge
                                p={0.5}
                                my={0.5}
                                colorScheme={orderAcceptanceDocsStatusesMap[doc.status]?.color}
                            >
                                {orderAcceptanceDocsStatusesMap[doc.status]?.label}
                            </Badge>
                        ) : (
                            <Badge p={0.5} my={0.5} colorScheme="purple">
                                не требуется
                            </Badge>
                        )}
                    </div>
                    {doc.isRequired && (
                        <>
                            {doc.comment && (
                                <Card p={2}>
                                    <Stack>
                                        <Label text="Комментарий" />
                                        <div>{doc.comment}</div>
                                    </Stack>
                                </Card>
                            )}

                            {doc.esign && (
                                <Stack direction="row" alignItems="center">
                                    <Link
                                        color="blue.600"
                                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                                        isExternal
                                        href={`${process.env.REACT_APP_API_BASE_URL}${doc.esign.doc_link}`}
                                    >
                                        {doc.esign.file_name}
                                    </Link>
                                    {doc.status !== EAcceptanceDocsStatuses.confirmed && (
                                        <HasAccess roleNames={hasUploadRoles}>
                                            <Button isDisabled={isDisabled} variant="link">
                                                <EditIcon onClick={() => setShowForm(true)} />
                                            </Button>
                                        </HasAccess>
                                    )}
                                </Stack>
                            )}
                            {children}
                            <HasAccess roleNames={hasResolveRoles}>
                                {doc?.status === EAcceptanceDocsStatuses.awaitingConfirmation && (
                                    <ButtonGroup isDisabled={isDisabled} justifyContent="flex-end">
                                        <Button colorScheme="red" onClick={onReject} variant="outline">
                                            Отклонить
                                        </Button>
                                        <Button onClick={onApproveDoc} colorScheme="green">
                                            Согласовать
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </HasAccess>
                            <HasAccess roleNames={hasUploadRoles}>
                                {showForm && (
                                    <Modal onClose={() => setShowForm(false)} isOpen>
                                        <ModalOverlay />
                                        <ModalContent>
                                            <ModalHeader> Загрузка файла </ModalHeader>
                                            <ModalCloseButton />
                                            <ModalBody>
                                                <form onSubmit={onAddDocHandler}>
                                                    <Stack gap={2}>
                                                        {doc.type ===
                                                            EAcceptanceDocsTypes.commissioningCert &&
                                                            !doc.esign && (
                                                                <div>
                                                                    <FormLabel htmlFor="signingDate">
                                                                        Дата АВЭ
                                                                    </FormLabel>
                                                                    <DatePicker
                                                                        name="date"
                                                                        value={date}
                                                                        onChange={(value) => setDate(value)}
                                                                    />
                                                                </div>
                                                            )}
                                                        {doc.type === EAcceptanceDocsTypes.techAct &&
                                                            !doc.esign && (
                                                                <OrderAcceptanceTechActFormFields
                                                                    onChangeSingingDate={setSigningDate}
                                                                    onChangeStartDate={setStartDate}
                                                                    signingDate={signingtDate}
                                                                    startDate={startDate}
                                                                />
                                                            )}
                                                        <Upload
                                                            name="file"
                                                            showUploadList
                                                            maxCount={1}
                                                            onChange={(file) => setFile(file)}
                                                            beforeUpload={() => false}
                                                        >
                                                            <Button width="100%">Загрузить документ</Button>
                                                        </Upload>
                                                        <Button
                                                            isDisabled={!file}
                                                            colorScheme="green"
                                                            type="submit"
                                                        >
                                                            Подтвердить
                                                        </Button>
                                                    </Stack>
                                                </form>
                                            </ModalBody>
                                        </ModalContent>
                                    </Modal>
                                )}
                                {!doc.esign && (
                                    <Button
                                        onClick={() => setShowForm(true)}
                                        colorScheme="green"
                                        variant="outline"
                                        isDisabled={isDisabled}
                                    >
                                        Загрузить документ
                                    </Button>
                                )}
                            </HasAccess>
                        </>
                    )}
                </>
            )}
        </Stack>
    );
};

export default OrderAcceptanceDoc;
