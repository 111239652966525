import { Box, Button, ScaleFade, Text } from '@chakra-ui/react';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const goToMain = () => {
        navigate('/sites');
    };
    useHeaderTitle('404 - Страница не найдена');

    return (
        <ScaleFade in delay={0.16}>
            <Box
                height="100vh"
                width="100vw"
                display="flex"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
            >
                <img src="/images/Error404.svg" alt="404" style={{ height: '240px' }} />
                <Text fontSize="3xl" textAlign="center" mt={2} fontWeight={600}>
                    Приносим свои извинения, <br /> запрашиваемая страница не была найдена
                </Text>
                <Button colorScheme="purple" size="lg" mt={4} onClick={goToMain}>
                    Перейти на главную
                </Button>
            </Box>
        </ScaleFade>
    );
};

export default NotFound;
