import {
    Text,
    Modal as ChakraModal,
    Button as ChakraButton,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Flex,
    Button,
    useToast,
    Spinner,
    Box
} from '@chakra-ui/react';
import { Upload } from 'antd';
import React, { useReducer, useEffect } from 'react';
import { UploadChangeParam } from 'antd/es/upload';
import axios from 'axios';
import LoadingOverlay from 'components/LoadingOverlay';

interface UploadModalProps {
    onClose: () => void;
    isOpen: boolean;
    title: string;
    uploadUrl: string;
    exportBtnLabel: string;
    onUploadSuccess?: () => void;
}

function reducer(state: any, action: any) {
    const { type, payload } = action;

    switch (type) {
        case 'init':
            return {
                ...state,
                errors: [],
                message: null,
                loading: false,
                completed: false
            };
        case 'loading':
            return {
                ...state,
                errors: [],
                message: null,
                loading: true
            };
        case 'loaded':
            return {
                ...state,
                loading: false
            };
        case 'error':
            return {
                ...state,
                errors: payload.details,
                message: payload.message
            };
        case 'success':
            return {
                ...state,
                message: payload.message,
                completed: true
            };
        default:
            throw new Error();
    }
}

export default function UploadModal({
    onClose,
    isOpen,
    title,
    uploadUrl,
    exportBtnLabel,
    onUploadSuccess
}: UploadModalProps) {
    const [data, dispatchData] = useReducer(reducer, {
        errors: [],
        message: null,
        loading: false,
        completed: false
    });

    useEffect(() => {
        dispatchData({ type: 'init' });
    }, [isOpen]);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const upload = async (data: UploadChangeParam) => {
        dispatchData({ type: 'loading' });
        const formData = new FormData();
        formData.append('field', 'files');
        // @ts-ignore
        formData.append('file', data.file);

        try {
            await axios.post(uploadUrl, formData);
        } catch (err: any) {
            const { message, details } = err.response.data.message;
            dispatchData({
                type: 'error',
                payload: { message, details }
            });
            dispatchData({ type: 'loaded' });
            return;
        }
        dispatchData({
            type: 'success',
            payload: { message: 'Загрузка завершена успешно' }
        });
        dispatchData({ type: 'loaded' });

        toast({
            id: 'file-upload-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Загрузка завершена успешно'
        });

        if (onUploadSuccess) {
            onUploadSuccess();
        }
    };

    const exportSubcs = () => {
        dispatchData({ type: 'loading' });

        axios
            .post(`/subcs/exportInExcel`)
            .then((res) => {
                if (res) {
                    window.open(`${process.env.REACT_APP_API_BASE_URL}/${res.data.path}`);
                }
                dispatchData({ type: 'loaded' });
            })
            .catch((err) => {
                console.error(err);
                dispatchData({ type: 'loaded' });
                toast({
                    description: 'Ошибка при выгрузке данных',
                    status: 'error'
                });
            });
    };

    const exportRespEngineers = () => {
        dispatchData({ type: 'loading' });

        axios
            .post(`/responsible-engineers/exportInExcel`)
            .then((res) => {
                if (res) {
                    window.open(`${process.env.REACT_APP_API_BASE_URL}/${res.data.path}`);
                }
                dispatchData({ type: 'loaded' });
            })
            .catch((err) => {
                console.error(err);
                dispatchData({ type: 'loaded' });
                toast({
                    description: 'Ошибка при выгрузке данных',
                    status: 'error'
                });
            });
    };
    return (
        <ChakraModal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton onClick={onClose} />
                <ModalBody>
                    <LoadingOverlay
                        Spinner={<Spinner color="blue.500" />}
                        active={data.loading}
                        styles={{
                            wrapper: (base) => ({
                                ...base,
                                overflow: 'auto',
                                display: 'flex',
                                height: '100%',
                                flexDirection: 'column'
                            })
                        }}
                    >
                        <Flex>
                            <Button
                                flexShrink={0}
                                aria-label="upload-btn"
                                size="sm"
                                colorScheme="teal"
                                onClick={exportSubcs}
                                mr={2}
                            >
                                Скачать подрядчиков
                            </Button>
                            <Button
                                flexShrink={0}
                                aria-label="upload-btn"
                                size="sm"
                                colorScheme="teal"
                                onClick={exportRespEngineers}
                                mr={2}
                            >
                                Скачать ответственных инженеров
                            </Button>
                            <Upload
                                multiple
                                name="file"
                                showUploadList={false}
                                maxCount={1}
                                onChange={(data: any) => upload(data)}
                                beforeUpload={() => false}
                            >
                                <Button flexShrink={0} aria-label="upload-btn" size="sm" colorScheme="teal">
                                    {exportBtnLabel}
                                </Button>
                            </Upload>
                        </Flex>
                    </LoadingOverlay>
                    {data.message && (
                        <Box>
                            <Text mt={1}>Результат выгрузки:</Text>
                            <Text mt={2} color={data.completed ? 'green' : 'red'}>
                                {data.message}
                            </Text>
                            <Box mt={2}>
                                {data.errors?.length > 0 && (
                                    <Box fontWeight="bold" mb={1}>
                                        Детали:
                                    </Box>
                                )}
                                {data.errors?.map(
                                    (
                                        data: { message: string; path: string; type: string },
                                        index: number
                                    ) => (
                                        <Flex mb={2} key={index}>
                                            <Box mr={2}>{data.message}</Box>
                                            <Box mr={2}>{data.path}</Box>
                                            <Box mr={2}>{data.type}</Box>
                                        </Flex>
                                    )
                                )}
                            </Box>
                        </Box>
                    )}
                </ModalBody>
                <ModalFooter textAlign="right">
                    <ChakraButton colorScheme="purple" onClick={onClose}>
                        Закрыть
                    </ChakraButton>
                </ModalFooter>
            </ModalContent>
        </ChakraModal>
    );
}
