/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Badge,
    Button,
    Collapse,
    DatePicker,
    Form,
    Image,
    Modal,
    Select,
    Spin,
    Table,
    Tabs,
    Upload
} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { userSelector } from 'redux/reducers/userSlice';
import moment from 'moment';
import useDebounce from 'hooks/useDebounce';
import _ from 'lodash';
import useHeaderTitle from 'hooks/useHeaderTitle';
import DecisionHistory from 'components/DecisionHistory';
import {
    Box,
    Badge as ChakraBadge,
    Button as ChakraButton,
    HStack,
    Modal as ChakraModal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tag,
    TagLabel,
    Text,
    Textarea,
    useToast,
    Heading,
    Accordion,
    AccordionButton,
    AccordionPanel,
    AccordionItem,
    AccordionIcon,
    Flex
} from '@chakra-ui/react';
import 'moment/locale/ru';
import DefectiveList from 'components/DefectiveList';
import ObserveImages from 'Routes/Works/components/ObserveImages';
import WorkDocuments from 'Routes/Works/components/WorkDocuments';
import { orderStatusesMap } from '../Order/utils/maps';
import ChangeWorkMainStatus from './components/ChangeWorkMainStatus';

import { generateTitle, getMainWorkStatusByCode } from '../../utils';
import constants from '../../constants';
import WorkRltSar from './components/Sar';
import { WORK_DETAIL_STATUSES } from './utils/maps';

const Work = ({ isRollout = false, workRollout }: { isRollout?: boolean; workRollout?: string }) => {
    const params = useParams();
    const workID = isRollout ? workRollout : params.workID;
    const [work, setWork] = useState<any>(null);
    const [newFeSiteModalOpened, setNewFeSiteModalOpened] = useState(false);
    const [newFeSiteId, setNewFeSiteId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [processState, setProcessState] = useState(null);
    const [isAdditionalFileModalOpened, setIsAdditionalFileModalOpened] = useState(false);
    const [loadedAdditionalFile, setLoadedAdditionalFile] = useState<any>(null);
    const [simpleSaved, setSimpleSaved] = useState<any>(null);
    const [dateSaved, setDateSaved] = useState<any>(null);
    const [dropdownOptions, setDropdownOptions] = useState<any>(null);
    const [siteOptions, setSiteOptions] = useState([]);
    const [searchParams] = useSearchParams();
    const [loadedFile, setLoadedFile] = useState<any>(null);
    const navigate = useNavigate();
    const { role } = useSelector(userSelector);
    const { data: userData } = useSelector(userSelector);

    const [isDecisionPending, setIsDecisionPending] = useState(false);
    const [isNewFeSiteCreating, setIsNewFeSiteCreating] = useState(false);
    const [lastSelectedField, setLastSelectedField] = useState('');
    const [lastSelectedFieldName, setLastSelectedFieldName] = useState('');
    const [lastSelectedDecision, setLastSelectedDecision] = useState(1);
    const [commentsModalOpened, setCommentsModalOpened] = useState(false);
    const [bs002Data, setBs002Data] = useState<any>([]);
    const [projects, setProjects] = useState([]);
    const [siteIdSearchText, setSiteIdSearchText] = useState<string>('');

    const debouncedSiteIdSearchText = useDebounce(siteIdSearchText, 700);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    if (!isRollout)
        useHeaderTitle(isLoading ? 'Загрузка ЭАП' : `Просмотр ЭАП #${workID} || Site ID: ${work?.site_id}`);

    const openFeSiteModal = () => {
        setNewFeSiteModalOpened(true);
    };

    const handleSearchSiteOptions = (searchText: string) => {
        if (!searchText) {
            setSiteOptions([]);
            return;
        }
        axios.get(`/sitesName?site=${searchText}`).then((res) => {
            setSiteOptions(res.data);
        });
    };

    const handleSiteIdSelect = (value: string) => {
        setNewFeSiteId(value);
    };

    useEffect(() => {
        handleSearchSiteOptions(debouncedSiteIdSearchText);
    }, [debouncedSiteIdSearchText]);

    const getWork = async () => {
        setIsLoading(true);
        const department = searchParams.get('department');
        const type = searchParams.get('type');
        const url = department && type ? `/report/${department}/${type}` : `/works/${workID}`;

        await axios
            .get(url)
            .then((res) => {
                setWork(res.data.data);

                const cols: any = [];
                const decisions: {
                    s_bs_002_tssr_decision: boolean | null;
                    s_bs_002_main_mat_decision: boolean | null;
                    s_bs_002_main_decision: boolean | null;
                } = {
                    s_bs_002_tssr_decision: true,
                    s_bs_002_main_mat_decision: true,
                    s_bs_002_main_decision: true
                };

                const mainApproves = res.data.fields.filter(
                    (item: any) =>
                        (item.field === 's_bs_002_main_construct_appr' ||
                            item.field === 's_bs_002_main_maint_appr') &&
                        res.data.data[item.field] !== undefined
                );

                if (mainApproves.length === 2) {
                    if (
                        res.data.data[mainApproves[0].field] === null ||
                        res.data.data[mainApproves[1].field] === null
                    ) {
                        decisions.s_bs_002_main_decision = null;
                    } else {
                        decisions.s_bs_002_main_decision =
                            res.data.data[mainApproves[0].field].status &&
                            res.data.data[mainApproves[1].field].status;
                    }
                }
                res.data.fields.forEach((field: any, i: any) => {
                    if (
                        (field.field === 'tssr_rp_approve' ||
                            field.field === 'tssr_tr_approve' ||
                            field.field === 'tssr_dt_approve' ||
                            field.field === 'tssr_rl_approve') &&
                        res.data.data[field.field] !== undefined
                    ) {
                        if (res.data.data[field.field] === null) {
                            decisions.s_bs_002_tssr_decision = null;
                        } else if (res.data.data[field.field].status === false) {
                            decisions.s_bs_002_tssr_decision = false;
                        }
                    }
                    if (
                        (field.field === 'scans_oe_approve' ||
                            field.field === 'scans_os_approve' ||
                            field.field === 'mat_lists_ol_approve') &&
                        res.data.data[field.field] !== undefined
                    ) {
                        if (res.data.data[field.field] === null) {
                            decisions.s_bs_002_main_mat_decision = null;
                        } else if (res.data.data[field.field].status === false) {
                            decisions.s_bs_002_main_mat_decision = false;
                        }
                    }
                    if (field.type !== 'DELETED') {
                        if (field.title === 'Subcon') {
                            cols.push({
                                key: i,
                                field: field.title,
                                value: res.data.data.subc?.name,
                                type: field.type,
                                grant: field.grant,
                                orgField: field.field
                            });
                        } else if (field.field === 'feSites') {
                            cols.push({
                                key: i,
                                field: field.title,
                                value: res.data.data[field.field],
                                type: field.type,
                                grant: field.grant,
                                orgField: field.field
                            });
                        } else if (
                            field.title === 'TSSR Recieved' ||
                            field.title === 'Antenna Report Received' ||
                            field.type === 'FILE'
                        ) {
                            cols.push({
                                key: i,
                                field: field.title,
                                value: res.data.data[field.field]?.esigns,
                                type: field.type,
                                grant: field.grant,
                                orgField: field.field
                            });
                        } else if (field.type === 'DECISION') {
                            cols.push({
                                key: i,
                                field: field.title,
                                value:
                                    field.field in decisions
                                        ? // @ts-ignore
                                          decisions[field.field]
                                        : res.data.data[field.field],
                                type: field.type,
                                grant: field.grant,
                                orgField: field.field
                            });
                        } else if (field.title === 'TSSR opt comments') {
                            cols.push({
                                key: 9266,
                                field: field.title,
                                value: res.data.data.tssr_cmnt,
                                type: 'SIMPLE',
                                grant: field.grant
                            });
                        } else {
                            cols.push({
                                key: i,
                                field: field.title,
                                value:
                                    typeof res.data.data[field.field] === 'object'
                                        ? res.data.data[field.field]?.status
                                        : res.data.data[field.field],
                                type: field.type,
                                grant: field.grant,
                                orgField: field.field
                            });
                        }
                    }
                });
                setBs002Data(cols);

                const parsedPhotos: { [key: string]: any } = {};

                if (res.data.data?.images?.esigns) {
                    const photosArray = res.data.data?.images?.esigns;
                    photosArray.forEach((photo: any) => {
                        const date = new Date(photo.date || photo.published_at).toLocaleDateString('ru-RU');
                        if (!parsedPhotos[date]) {
                            parsedPhotos[date] = [];
                        }
                        parsedPhotos[date] = [...parsedPhotos[date], photo];
                    });

                    const treeData: any = [];
                    Object.keys(parsedPhotos)
                        .slice(0)
                        .reverse()
                        .forEach((key) => {
                            treeData.push({
                                title: key,
                                key,
                                children: [
                                    {
                                        title: () => (
                                            <Image.PreviewGroup>
                                                {parsedPhotos[key].map((image: any) => (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}
                                                    >
                                                        <Image
                                                            key={image.doc_link}
                                                            width={200}
                                                            src={
                                                                image?.from_sad
                                                                    ? `https://sad.tele2.kz/tele2${image.doc_link.replace(
                                                                          '.',
                                                                          '',
                                                                          1
                                                                      )}`
                                                                    : `${process.env.REACT_APP_API_BASE_URL}${image.doc_link}`
                                                            }
                                                            style={{ paddingRight: '6px' }}
                                                        />
                                                        {image.file_name.slice(0, 20)}...
                                                    </div>
                                                ))}
                                            </Image.PreviewGroup>
                                        ),
                                        isLeaf: true
                                    }
                                ]
                            });
                        });
                }
                // todo: Переписать в уникальный компонент для типа Images
                const afuParsedPhotos: { [key: string]: any } = {};
                if (res.data.data?.afu_images?.esigns) {
                    const afuPhotosArray = res.data.data?.afu_images?.esigns;
                    afuPhotosArray.forEach((photo: any) => {
                        const date = new Date(photo.date || photo.published_at).toLocaleDateString('ru-RU');
                        if (!afuParsedPhotos[date]) {
                            afuParsedPhotos[date] = [];
                        }
                        afuParsedPhotos[date] = [...afuParsedPhotos[date], photo];
                    });

                    const afuTreeData: any = [];
                    Object.keys(afuParsedPhotos)
                        .slice(0)
                        .reverse()
                        .forEach((key) => {
                            afuTreeData.push({
                                title: key,
                                key,
                                children: [
                                    {
                                        title: () => (
                                            <Image.PreviewGroup>
                                                {afuParsedPhotos[key].map((image: any) => (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}
                                                    >
                                                        <Image
                                                            key={image.doc_link}
                                                            width={200}
                                                            src={`${process.env.REACT_APP_API_BASE_URL}${image.doc_link}`}
                                                            style={{ paddingRight: '6px' }}
                                                        />
                                                        {image.file_name.slice(0, 20)}...
                                                    </div>
                                                ))}
                                            </Image.PreviewGroup>
                                        ),
                                        isLeaf: true
                                    }
                                ]
                            });
                        });
                }
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: `Не удалось получить данные по ЭАП`
                });
                setIsLoading(false);
                console.error(err);
            });
        await axios.get(`/process-instances?workID=${workID}`).then((res) => {
            setProcessState(res.data[0] ? res.data[0].state : []);
        });
        await axios.get(`/works/dicrinary`).then((res) => {
            setDropdownOptions(res.data);
        });

        await axios.get('/projects').then((res) => {
            setProjects(res.data);
        });
        setIsLoading(false);
    };

    const onFinish = (values: any, record: any) => {
        axios
            .post('/works/makeDecision', {
                work_id: work.id,
                field: record.orgField,
                value: false,
                comment: values.comment
            })
            .then(() => {
                getWork();
                toast({
                    status: 'success',
                    title: `${record.field} успешно отклонено`
                });
            })
            .catch(() => {
                toast({
                    status: 'error',
                    title: `Произошла ошибка при отклонении`
                });
            });
    };

    const addFeSite = async () => {
        setIsNewFeSiteCreating(true);
        const formData = new FormData();
        if (loadedFile?.file) {
            formData.append('file', loadedFile.file);
        }
        formData.append('work', work.id);
        formData.append('siteId', newFeSiteId);
        await axios
            .post('/fe-sites', formData)
            .then(() => {
                getWork();
            })
            .catch(() => {
                toast({
                    title: 'Ошибка при cоздании',
                    status: 'error'
                });
            })
            .finally(() => {
                setIsNewFeSiteCreating(false);
                setNewFeSiteModalOpened(false);
                setLoadedFile(null);
            });
    };

    const closeFeModal = () => {
        setNewFeSiteModalOpened(false);
        setLoadedFile(null);
    };

    const removeFeSite = async (feSite: any) => {
        await axios.delete(`/fe-sites/${feSite.id}`).then(() => getWork());
    };

    const getDecisionBtnDisabledByStatus = (fieldName: string) => {
        if (
            _.includes(['scans_oe_approve', 'scans_os_approve'], fieldName) &&
            !_.includes([2, 3], work.s_bs_002_main_status)
        ) {
            return true;
        }

        if (_.includes([null, -1], work.candidateStatus) && work.work_type === 'NCP') {
            return true;
        }

        if (_.includes([null, -1], work.candidateAdditionalStatus) && work.work_type !== 'NCP') {
            return true;
        }

        // if (fieldName === 'candidateLeasingApprStatus' && work.work_type !== 'NCP') {
        //     return false;
        // }

        if (fieldName === 'mat_lists_ol_approve' && work.s_bs_002_main_status !== 5) {
            return true;
        }

        if (
            _.includes(['s_bs_002_main_construct_appr', 's_bs_002_main_maint_appr'], fieldName) &&
            !_.includes([2, 3], work.s_bs_002_additional_date_1)
        ) {
            return true;
        }
        if (
            _.includes(
                [
                    'finishWork_maint_approve',
                    'finishWork_tr_approve',
                    'finishWork_ran_approve',
                    'finishWork_powerEngApprove',
                    'finishWork_maintTRapprove',
                    'finishWork_maintTowerApprove'
                ],
                fieldName
            ) &&
            !_.includes([1], work.finishWork_status)
        ) {
            return true;
        }

        return false;
    };

    const bs002Columns = [
        {
            title: 'Поле',
            dataIndex: 'field',
            key: 'field',
            width: 200
        },
        {
            title: ' Значение',
            dataIndex: 'value',
            key: 'value',
            render: (any: any, record: any) =>
                record.field === 'Site Id' ? (
                    <span
                        onClick={() => navigate(`/sites/${work.site.s_sid}/${work.site.id}`)}
                        style={{ color: 'blue', cursor: 'pointer' }}
                    >
                        {record.value}
                    </span>
                ) : record.field === 'Main System Status' || record.field === 'Main System Status (Phot)' ? (
                    <span>{getMainWorkStatusByCode(record.value)}</span>
                ) : /* Expandable Status Comments */
                record.field === 'MR opt approved reason' ||
                  record.field === 'Mat. List comments' ||
                  record.field === 'Acceptance comments' ||
                  record.field === 'Antenna Report Comments' ||
                  record.field === 'NOC Comment' ||
                  record.field === 'TR Comment' ||
                  record.field === 'Комментирование завершения ЭАП' ? (
                    <>
                        <Collapse defaultActiveKey="1">
                            <Collapse.Panel header={record.field} key="1">
                                <span dangerouslySetInnerHTML={{ __html: record.value }} />
                            </Collapse.Panel>
                        </Collapse>
                        {record.grant === 2 && (
                            <Box display="flex">
                                <Textarea
                                    resize="both"
                                    paddingInlineStart={2}
                                    paddingInlineEnd={2}
                                    style={{ minWidth: '230px', marginTop: '6px', maxWidth: '600px' }}
                                    id={record.orgField}
                                    onChange={(e) => setSimpleSaved(e.target.value)}
                                    placeholder="Введите комментарий"
                                />
                                <Button
                                    style={{ marginTop: '6px', marginLeft: '6px' }}
                                    onClick={() => {
                                        const date = new Date().toLocaleString('ru-RU');
                                        axios
                                            .patch('/works', {
                                                work_id: work.id,
                                                field: record.orgField,
                                                value: `${record.value}</br>Комментарий: ${simpleSaved} | ${
                                                    userData.full_name || userData.username
                                                } (Время: ${date})`
                                            })
                                            .then(() => {
                                                getWork();
                                                toast({
                                                    status: 'success',
                                                    title: `${record.field} успешно обновлен`
                                                });
                                            })
                                            .catch(() => {
                                                toast({
                                                    status: 'error',
                                                    title: 'Ошибка при обновлении'
                                                });
                                            });
                                    }}
                                >
                                    Сохранить
                                </Button>
                            </Box>
                        )}
                    </>
                ) : record.field === 'TSSR Recieved' || record.field === 'Antenna Report Received' ? (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {record.value
                                ? record.value.map((item: any) => (
                                      <a
                                          target="_blank"
                                          href={
                                              item?.from_sad
                                                  ? `https://sad.tele2.kz/tele2${item.doc_link.replace(
                                                        '.',
                                                        '',
                                                        1
                                                    )}`
                                                  : `${process.env.REACT_APP_API_BASE_URL}${item.doc_link}`
                                          }
                                          key={item.id}
                                          rel="noreferrer"
                                          style={{
                                              padding: '3px',
                                              borderBottom: '1px solid gray',
                                              color: '#008abd'
                                          }}
                                      >
                                          {item.file_name} ({moment(item.date).format('L')})
                                      </a>
                                  ))
                                : null}
                        </div>
                        {record.value && <br />}
                        {record.grant === 2 && (
                            <Upload
                                multiple
                                name="file"
                                showUploadList={false}
                                maxCount={1}
                                onChange={async (info) => {
                                    setIsLoading(true);
                                    const formData = new FormData();
                                    // sure?
                                    formData.append('work_id', work.id!);
                                    formData.append('field', record.orgField);
                                    // @ts-ignore
                                    formData.append('file', info.file);
                                    await axios
                                        .post('/works/upload', formData)
                                        .then(async () => {
                                            toast({
                                                status: 'success',
                                                title: `${record.field} успешно загружен`
                                            });
                                            getWork();
                                        })
                                        .catch((err) => {
                                            toast({
                                                status: 'error',
                                                title: err.response.data.message
                                            });
                                            setIsLoading(false);
                                        });
                                }}
                                beforeUpload={() => false}
                            >
                                <Button loading={isLoading} icon={<UploadOutlined />}>
                                    Загрузить {record.field}
                                </Button>
                            </Upload>
                        )}
                    </>
                ) : record.type === 'FE_CUSTOM' ? (
                    <>
                        <Flex direction="row" justify="space-between">
                            <Box width="25%">FE SITEID</Box>
                            <Box width="25%" textAlign="center">
                                FE SITENAME
                            </Box>
                            <Box width="25%" textAlign="center">
                                TSD
                            </Box>
                            <Box width="25%" />
                        </Flex>
                        {record?.value.map((feSite: any) => (
                            <Flex direction="row" justify="space-between">
                                <Box width="25%" textAlign="start">
                                    {feSite.siteId}
                                </Box>
                                <Box width="25%" textAlign="center">
                                    {feSite.sitename}
                                </Box>
                                <Box width="25%" textAlign="center">
                                    <a
                                        target="_blank"
                                        href={`${process.env.REACT_APP_API_BASE_URL}${feSite?.tsd?.doc_link}`}
                                        rel="noreferrer"
                                        style={{
                                            padding: '3px',
                                            color: '#008abd'
                                        }}
                                    >
                                        {feSite?.tsd?.file_name}
                                    </a>
                                </Box>
                                <Box width="25%" textAlign="center">
                                    {record.grant === 2 && (
                                        <Button onClick={() => removeFeSite(feSite)}>Удалить</Button>
                                    )}
                                </Box>
                            </Flex>
                        ))}
                        {record.grant === 2 && (
                            <>
                                <Box display="flex" mt={2}>
                                    <Button
                                        onClick={openFeSiteModal}
                                        style={{
                                            marginLeft: '6px'
                                        }}
                                    >
                                        Добавить новый FE site
                                    </Button>
                                </Box>
                            </>
                        )}
                    </>
                ) : record.type === 'DECISION' ? (
                    <>
                        {record.field === 'Статус завершения ЭАП' ||
                        record.field === 'CandidateStatus (SAR)' ? (
                            <>
                                <Badge
                                    style={
                                        record.value !== null
                                            ? record.value === -1
                                                ? { backgroundColor: 'red' }
                                                : record.value === 2
                                                ? { backgroundColor: '#52c41a' }
                                                : { backgroundColor: '#3FCBFF' }
                                            : { backgroundColor: '#3FCBFF' }
                                    }
                                    count={
                                        record.value !== null
                                            ? record.value === -1
                                                ? `Отказано`
                                                : record.value === 2
                                                ? `Принято`
                                                : 'На согласовании'
                                            : 'Процесс не начат'
                                    }
                                />
                            </>
                        ) : (
                            <Badge
                                style={
                                    record.value !== null
                                        ? record.value === false
                                            ? { backgroundColor: 'red' }
                                            : { backgroundColor: '#52c41a' }
                                        : { backgroundColor: '#3FCBFF' }
                                }
                                count={
                                    record.value !== null
                                        ? record.value === false
                                            ? `Отказано`
                                            : `Принято`
                                        : 'На рассмотрении'
                                }
                            />
                        )}
                    </>
                ) : /* SELECT */
                record.type === 'SELECT' ? (
                    <>
                        <Select
                            showSearch={record.orgField === 'work_type'}
                            id={record.orgField === 'order_memo' ? 'memo_edit' : undefined}
                            style={{ minWidth: '230px', maxWidth: 'fit-content' }}
                            mode={record.orgField === 'oe_engineers' ? 'multiple' : undefined}
                            defaultValue={
                                record.orgField === 'oe_engineers'
                                    ? work[record.orgField].map((el: any) => el.id)
                                    : record.orgField === 'os_engineer' || record.orgField === 'order_memo'
                                    ? work[record.orgField]?.id || ''
                                    : record.orgField === 's_main_accident_rate' ||
                                      record.orgField === 's_main_work_category' ||
                                      record.orgField === 's_reg'
                                    ? work.site?.[record.orgField]
                                    : record.orgField === 'project'
                                    ? work[record.orgField]?.title
                                    : record.value
                            }
                            disabled={record.grant !== 2}
                            onChange={(e) => {
                                if (record.orgField === 's_reg') {
                                    axios
                                        .patch(`/sites/${work.site.id}`, {
                                            s_reg: e
                                        })
                                        .then(() => {
                                            getWork();
                                            toast({
                                                status: 'success',
                                                title: `${record.orgField} успешно обновлен`
                                            });
                                        })
                                        .catch(() => {
                                            toast({
                                                status: 'error',
                                                title: 'Ошибка при обновлении'
                                            });
                                        });
                                } else {
                                    axios
                                        .patch('/works', {
                                            work_id: work.id,
                                            field: record.orgField,
                                            value: e
                                        })
                                        .then(() => {
                                            getWork();
                                            toast({
                                                status: 'success',
                                                title: `${record.orgField} успешно обновлен`
                                            });
                                        })
                                        .catch(() => {
                                            toast({
                                                status: 'error',
                                                title: 'Ошибка при обновлении'
                                            });
                                        });
                                }
                            }}
                        >
                            {record.orgField === 'order_memo'
                                ? dropdownOptions?.[record.orgField]?.map((opt: any) => (
                                      <Select.Option key={opt?.id} value={opt.document?.id}>
                                          {opt.name}
                                      </Select.Option>
                                  ))
                                : record.orgField === 's_reg'
                                ? constants.dropdownData.s_reg.map((region: string, i: number) => (
                                      <Select.Option value={region} key={i}>
                                          {region}
                                      </Select.Option>
                                  ))
                                : record.orgField === 'project'
                                ? projects.map((opt: any) => (
                                      <Select.Option value={`${opt.id}`} key={opt.id}>
                                          {opt.title}
                                      </Select.Option>
                                  ))
                                : dropdownOptions?.[record.orgField]?.map((option: any) => (
                                      <Select.Option key={option.id ?? option} value={option.id ?? option}>
                                          {option.name || option}
                                      </Select.Option>
                                  ))}
                        </Select>
                    </>
                ) : record.type === 'ADVANCED_STATUS' && work[record.orgField] ? (
                    <Box>
                        {Object.keys(work[record.orgField]).map((status) => {
                            if (
                                (work?.work_type !== 'NCP' && status === 'prepareSar') ||
                                !WORK_DETAIL_STATUSES[status]
                            ) {
                                return null;
                            }
                            return (
                                <HStack my={1}>
                                    <Box>{WORK_DETAIL_STATUSES[status]}</Box>
                                    {work[record.orgField][status] ? (
                                        <Box>
                                            <ChakraBadge size="xs" colorScheme="green">
                                                Да
                                            </ChakraBadge>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <ChakraBadge size="xs" colorScheme="red">
                                                Нет
                                            </ChakraBadge>
                                        </Box>
                                    )}
                                </HStack>
                            );
                        })}
                    </Box>
                ) : /* SIMPLE */
                record.type === 'SIMPLE' ? (
                    record.grant === 2 ? (
                        <>
                            <Box display="flex">
                                <Textarea
                                    resize="both"
                                    style={{ minHeight: '20px', width: '230px', maxWidth: '600px' }}
                                    defaultValue={
                                        record.orgField === 's_main_accident_rate_cmnt' ||
                                        record.orgField === 's_main_accid_mark_cmnt'
                                            ? work.site?.[record.orgField]
                                            : record.value
                                    }
                                    onChange={(e) => {
                                        if (record.value !== e.target.value) {
                                            setSimpleSaved({
                                                work_id: work.id!,
                                                field: record.orgField,
                                                value: e.target.value
                                            });
                                        }
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        axios
                                            .patch('/works', simpleSaved)
                                            .then((res) => {
                                                if (res.data.status === 'error') {
                                                    toast({
                                                        status: 'error',
                                                        title: res.data.message
                                                    });
                                                } else {
                                                    getWork();
                                                    toast({
                                                        status: 'success',
                                                        title: `${record.field} успешно обновлен`
                                                    });
                                                }
                                            })
                                            .catch(() => {
                                                toast({
                                                    status: 'error',
                                                    title: `При обновлении произошла ошибка`
                                                });
                                            });
                                    }}
                                    style={{
                                        marginLeft: '6px'
                                    }}
                                >
                                    Сохранить
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <span dangerouslySetInnerHTML={{ __html: record.value }} />
                    )
                ) : /* ORDERS */
                record.type === 'ORDERS' ? (
                    <Accordion allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                        Заказы по данному ЭАП
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                paddingBottom="1rem"
                                fontStyle="italic"
                                fontSize="sm"
                                lineHeight={1.5}
                            >
                                <Flex direction="row" justify="space-between">
                                    <Box width="25%">ID</Box>
                                    <Box width="25%" textAlign="center">
                                        Код
                                    </Box>
                                    <Box width="25%" textAlign="center">
                                        Статус
                                    </Box>
                                    <Box width="25%" textAlign="end">
                                        Документ
                                    </Box>
                                </Flex>
                                {work.orders.map((order: any) => (
                                    <Flex direction="row" justify="space-between">
                                        <Box width="25%">{order.id}</Box>
                                        <Box width="25%">{order.code}</Box>

                                        <Box
                                            width="25%"
                                            textAlign="center"
                                            color={
                                                orderStatusesMap[
                                                    order.status as keyof typeof orderStatusesMap
                                                ].color
                                            }
                                        >
                                            {/* @ts-ignore */}
                                            {orderStatusesMap[order.status].label}
                                        </Box>
                                        <Box width="25%" textAlign="end">
                                            {order.status === 'completed'
                                                ? order.tssr &&
                                                  order.tssr.esigns.map(({ from_sad, doc_link }: any) => (
                                                      <a
                                                          target="_blank"
                                                          href={
                                                              from_sad
                                                                  ? `https://sad.tele2.kz/tele2${doc_link.replace(
                                                                        '.',
                                                                        '',
                                                                        1
                                                                    )}`
                                                                  : `${process.env.REACT_APP_API_BASE_URL}${doc_link}`
                                                          }
                                                          rel="noreferrer"
                                                          style={{
                                                              padding: '3px',
                                                              color: '#008abd'
                                                          }}
                                                      >
                                                          Скачать
                                                      </a>
                                                  ))
                                                : ''}
                                        </Box>
                                    </Flex>
                                ))}
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                ) : /* FILE */
                record.type === 'FILE' && record.field !== 'Предварительные фото' ? (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {record.value
                                ? record.value.map((item: any) => (
                                      <div
                                          key={item.id}
                                          style={{ display: 'flex', flexDirection: 'row', marginTop: '6px' }}
                                      >
                                          <a
                                              target="_blank"
                                              href={
                                                  item?.from_sad
                                                      ? `https://sad.tele2.kz/tele2${item.doc_link.replace(
                                                            '.',
                                                            '',
                                                            1
                                                        )}`
                                                      : `${process.env.REACT_APP_API_BASE_URL}${item.doc_link}`
                                              }
                                              rel="noreferrer"
                                              style={{
                                                  padding: '3px',
                                                  color: '#008abd'
                                              }}
                                          >
                                              {item.file_name} (
                                              {moment(item.date ? item.date : item.created_at).format('L')})
                                          </a>
                                          {record.grant === 2 && (
                                              <Button
                                                  loading={isLoading}
                                                  onClick={async () => {
                                                      setIsLoading(true);
                                                      await axios
                                                          .post('/esigns/delete', {
                                                              id: item.id,
                                                              workStatusId: work?.workStatus?.id,
                                                              field: record.orgField
                                                          })
                                                          .then(() => {
                                                              getWork();
                                                              toast({
                                                                  title: `${item.file_name} удален`,
                                                                  status: 'success'
                                                              });
                                                          })
                                                          .catch(() => {
                                                              toast({
                                                                  title: 'Ошибка при удалении',
                                                                  status: 'error'
                                                              });
                                                              setIsLoading(false);
                                                          });
                                                  }}
                                                  style={{ marginLeft: '6px' }}
                                              >
                                                  Удалить
                                              </Button>
                                          )}
                                      </div>
                                  ))
                                : null}
                        </div>
                        {record.value && <br />}
                        {record.grant === 2 && (
                            <>
                                <Upload
                                    multiple
                                    name="file"
                                    showUploadList={false}
                                    maxCount={1}
                                    onChange={async (info) => {
                                        setIsLoading(true);
                                        const formData = new FormData();
                                        // sure?
                                        formData.append('work_id', work.id!);
                                        formData.append('field', record.orgField);
                                        // @ts-ignore
                                        formData.append('file', info.file);
                                        await axios
                                            .post('/works/upload', formData)
                                            .then(async () => {
                                                toast({
                                                    status: 'success',
                                                    title: `${record.field} успешно загружен`
                                                });
                                                getWork();
                                            })
                                            .catch((err) => {
                                                toast({
                                                    status: 'error',
                                                    title: err.response.data.message
                                                });
                                                setIsLoading(false);
                                            });
                                    }}
                                    beforeUpload={() => false}
                                >
                                    <Button loading={isLoading} icon={<UploadOutlined />}>
                                        Загрузить {record.field}
                                    </Button>
                                </Upload>
                            </>
                        )}
                    </>
                ) : record.field === 'Предварительные фото' ? (
                    <ObserveImages work={work} user={userData} updateWork={getWork} />
                ) : record.type === 'APPROVE' ? (
                    <>
                        <Badge
                            style={
                                work[`${record.orgField}`]
                                    ? !work[`${record.orgField}`]?.status
                                        ? { backgroundColor: 'red' }
                                        : { backgroundColor: '#52c41a' }
                                    : { backgroundColor: '#3FCBFF' }
                            }
                            count={
                                work[`${record.orgField}`]
                                    ? !work[`${record.orgField}`].status
                                        ? `Отказано (${moment(work[`${record.orgField}`]?.date).format('L')})`
                                        : `Принято ${
                                              work.subc?.id === 1108 && record.orgField === 'scans_oe_approve'
                                                  ? 'и подписано'
                                                  : ''
                                          } (${moment(work[`${record.orgField}`]?.date).format('L')})`
                                    : 'Решение не принято'
                            }
                        />
                        {record.grant >= 2 && (
                            <>
                                <Form
                                    name="basic"
                                    layout="inline"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    onFinish={(values) => onFinish(values, record)}
                                    autoComplete="off"
                                    style={{ color: '#52c41a' }}
                                >
                                    <Box>
                                        <Button
                                            style={{
                                                color: '#52c41a',
                                                marginRight: '6px',
                                                marginTop: '12px'
                                            }}
                                            disabled={
                                                getDecisionBtnDisabledByStatus(record.orgField) ||
                                                work[`${record.orgField}`] !== null
                                            }
                                            onClick={() => {
                                                if (
                                                    work.subc?.id === 1108 &&
                                                    record.orgField === 'scans_oe_approve'
                                                ) {
                                                    setLastSelectedField(record.orgField);
                                                    setLastSelectedFieldName(record.field);
                                                } else {
                                                    setLastSelectedField(record.orgField);
                                                    setLastSelectedFieldName(record.field);
                                                    setLastSelectedDecision(1);
                                                    setCommentsModalOpened(true);
                                                }
                                            }}
                                        >
                                            {work.subc?.id === 1108 && record.orgField === 'scans_oe_approve'
                                                ? 'Принять и подписать'
                                                : 'Принять'}
                                        </Button>
                                        <Button
                                            style={{ color: 'red', marginTop: '12px' }}
                                            disabled={
                                                getDecisionBtnDisabledByStatus(record.orgField) ||
                                                work[`${record.orgField}`] !== null
                                            }
                                            onClick={() => {
                                                setLastSelectedField(record.orgField);
                                                setLastSelectedFieldName(record.field);
                                                setLastSelectedDecision(0);
                                                setCommentsModalOpened(true);
                                            }}
                                        >
                                            Отклонить
                                        </Button>
                                        {record.grant === 3 && userData.canRevertDecisions && (
                                            <Button
                                                disabled={work[`${record.orgField}`] === null}
                                                style={{ marginTop: '12px' }}
                                                loading={isLoading}
                                                onClick={() => {
                                                    setIsLoading(true);
                                                    axios
                                                        .post('/works/revertDecision', {
                                                            workId: work.id,
                                                            field: record.orgField
                                                        })
                                                        .finally(() => {
                                                            getWork();
                                                            toast({
                                                                status: 'success',
                                                                title: `Решение по ${record.field} успешно обновлено`
                                                            });
                                                        })
                                                        .catch(() => {
                                                            toast({
                                                                status: 'error',
                                                                title: `При обновлении произошла ошибка`
                                                            });
                                                        });
                                                    setIsLoading(false);
                                                }}
                                            >
                                                Отменить решение
                                            </Button>
                                        )}
                                    </Box>
                                </Form>
                                {/* {work[`${record.orgField}`] === null &&
                                getDecisionBtnDisabledByStatus(record.orgField) ? (
                                    <Text>Статус SAR не позволяет принять решение</Text>
                                ) : null} */}
                            </>
                        )}
                        {work?.previousTSSR[`${record.orgField}`] ? (
                            <Collapse
                                style={{ fontSize: '12px', marginTop: '16px', width: '800px' }}
                                defaultActiveKey="0"
                            >
                                <Collapse.Panel header="Предыдущее решение" key="1">
                                    <Text>{`Дата: ${work?.previousTSSR[`${record.orgField}`]?.date}`}</Text>
                                    <Text>{`Автор: ${
                                        !work?.previousTSSR[`${record.orgField}`]?.fullName
                                            ? work?.previousTSSR[`${record.orgField}`]?.username
                                            : work?.previousTSSR[`${record.orgField}`]?.fullName
                                    }`}</Text>
                                    <Text>{`Статус: ${
                                        !work?.previousTSSR[`${record.orgField}`]?.status
                                            ? 'Отказано'
                                            : 'Принято'
                                    }`}</Text>
                                    <Text>{`${
                                        work?.previousTSSR[`${record.orgField}`]?.comment
                                            ? `Комментарий - ${
                                                  work?.previousTSSR[`${record.orgField}`]?.comment
                                              }`
                                            : ''
                                    }
                            `}</Text>
                                </Collapse.Panel>
                            </Collapse>
                        ) : (
                            ''
                        )}
                    </>
                ) : record.type === 'DATETIME' ? (
                    record.grant === 2 ? (
                        <>
                            <input
                                // @ts-ignore
                                defaultValue={
                                    record.value ? moment(record.value).format('YYYY-MM-DDTHH:mm') : ''
                                }
                                type="datetime-local"
                                onChange={(e) => {
                                    setDateSaved({
                                        work_id: work.id,
                                        field: record.orgField,
                                        // @ts-ignore
                                        value: moment(e.target.value).toDate().toISOString()
                                    });
                                }}
                            />
                            <Button
                                onClick={() => {
                                    axios
                                        .patch('/works', dateSaved)
                                        .then(() => {
                                            getWork();
                                            toast({
                                                status: 'success',
                                                title: `${record.field} успешно обновлен`
                                            });
                                        })
                                        .catch(() => {
                                            toast({
                                                status: 'error',
                                                title: `При обновлении произошла ошибка`
                                            });
                                        });
                                }}
                                style={{ marginLeft: '6px' }}
                            >
                                Сохранить
                            </Button>
                        </>
                    ) : (
                        <span>{record.value ? moment(record.value).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
                    )
                ) : /* DATE */
                record.type === 'DATE' ? (
                    record.grant === 2 ? (
                        <>
                            <DatePicker
                                style={{ minWidth: '230px', maxWidth: 'fit-content' }}
                                format="L"
                                // @ts-ignore
                                defaultValue={record.value ? moment(record.value) : null}
                                disabled={record.grant !== 2}
                                onChange={(e) => {
                                    setDateSaved({
                                        work_id: work.id,
                                        field: record.orgField,
                                        value: e?.toDate()
                                    });
                                }}
                            />
                            <Button
                                onClick={() => {
                                    axios
                                        .patch('/works', dateSaved)
                                        .then(() => {
                                            getWork();
                                            toast({
                                                status: 'success',
                                                title: `${record.field} успешно обновлен`
                                            });
                                        })
                                        .catch(() => {
                                            toast({
                                                status: 'error',
                                                title: `При обновлении произошла ошибка`
                                            });
                                        });
                                }}
                                style={{ marginLeft: '6px' }}
                            >
                                Сохранить
                            </Button>
                        </>
                    ) : (
                        <span>{record.value ? moment(record.value).format('L') : ''}</span>
                    )
                ) : record.type === 'COMMENTS' ? (
                    <div dangerouslySetInnerHTML={{ __html: work[`${record.orgField}`] }} />
                ) : (
                    ''
                )
        }
    ];

    if (!isRollout) useTitle(generateTitle(`ЭАП ${workID}`));

    useEffect(() => {
        if (!isRollout) getWork();
        else if (isRollout && workRollout) getWork();
    }, [isRollout, workRollout]);

    return (
        <Box bg="white" pl={3} pt={3}>
            <Tabs>
                <Tabs.TabPane tab="Детальная страница ЭАП" key="1">
                    <Box>
                        <Heading as="h2" size="lg" color="red">
                            {work?.workStatus?.cancelled ? 'ЭАП Отменен' : ''}
                        </Heading>
                        <Box
                            p={2}
                            borderRadius={10}
                            bg="white"
                            display="flex"
                            flexDir="column"
                            opacity={work?.workStatus?.cancelled ? '0.5' : '1'}
                            style={{ pointerEvents: `${work?.workStatus?.cancelled ? 'none' : 'all'}` }}
                        >
                            <DefectiveList
                                isOpen={
                                    commentsModalOpened && lastSelectedField === 's_bs_002_main_maint_appr'
                                }
                                onClose={() => setCommentsModalOpened(false)}
                                decision={lastSelectedDecision}
                                data={work?.defectData || null}
                                isLoading={isDecisionPending}
                                onSubmit={async (values: any) => {
                                    const { comment, ...rest } = values;
                                    if (lastSelectedDecision === 0 && !comment) {
                                        toast({
                                            status: 'error',
                                            title: 'Заполните комментарий при отказе!'
                                        });
                                        return;
                                    }
                                    const data = {
                                        work_id: work.id,
                                        field: lastSelectedField,
                                        value: lastSelectedDecision,
                                        comment,
                                        defectData: {
                                            regEng: [],
                                            mainAl: [],
                                            energyAndCond: [],
                                            design: [],
                                            trans: [],
                                            mts: [],
                                            nocAndContr: []
                                        }
                                    };
                                    if (lastSelectedDecision === 0) {
                                        data.defectData = {
                                            ...rest
                                        };
                                    }
                                    setIsDecisionPending(true);
                                    await axios
                                        .post('/works/makeDecision', data)
                                        .then(() => {
                                            getWork();
                                            toast({
                                                title: `${lastSelectedFieldName} успешно ${
                                                    lastSelectedDecision === 1 ? 'принято' : 'отклонено'
                                                }`,
                                                status: 'success'
                                            });
                                            setCommentsModalOpened(false);
                                            setIsDecisionPending(false);
                                        })
                                        .catch(() => {
                                            toast({
                                                title: 'Ошибка при обновлении',
                                                status: 'error'
                                            });
                                            setIsDecisionPending(false);
                                        });
                                }}
                            />
                            <ChakraModal
                                isOpen={
                                    commentsModalOpened && lastSelectedField !== 's_bs_002_main_maint_appr'
                                }
                                onClose={() => setCommentsModalOpened(false)}
                                size="xl"
                            >
                                <ModalOverlay />

                                <ModalContent>
                                    <ModalHeader>
                                        Комментирование {lastSelectedDecision === 1 ? 'принятия' : 'отказа'}
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text mb={1}>Комментарий</Text>
                                        <Textarea
                                            id="commentsTextarea"
                                            paddingInlineStart={2}
                                            paddingInlineEnd={2}
                                        />
                                    </ModalBody>
                                    <ModalFooter>
                                        <ChakraButton
                                            colorScheme="purple"
                                            mr="auto"
                                            onClick={() => setCommentsModalOpened(false)}
                                        >
                                            Закрыть
                                        </ChakraButton>
                                        <ChakraButton
                                            colorScheme={lastSelectedDecision === 1 ? 'green' : 'red'}
                                            isLoading={isDecisionPending}
                                            loadingText="Обработка..."
                                            onClick={async () => {
                                                setIsDecisionPending(true);
                                                if (
                                                    lastSelectedDecision === 0 &&
                                                    // @ts-ignore
                                                    !document.getElementById('commentsTextarea')?.value
                                                ) {
                                                    toast({
                                                        status: 'error',
                                                        title: 'Заполните комментарий при отказе!'
                                                    });
                                                    setIsDecisionPending(false);
                                                } else {
                                                    await axios
                                                        .post('/works/makeDecision', {
                                                            work_id: work.id,
                                                            field: lastSelectedField,
                                                            value: lastSelectedDecision,
                                                            // @ts-ignore
                                                            comment: (
                                                                document.getElementById(
                                                                    'commentsTextarea'
                                                                ) as HTMLInputElement
                                                            )?.value
                                                        })
                                                        .then(() => {
                                                            getWork();
                                                            toast({
                                                                title: `${lastSelectedFieldName} успешно ${
                                                                    lastSelectedDecision === 1
                                                                        ? 'принято'
                                                                        : 'отклонено'
                                                                }`,
                                                                status: 'success'
                                                            });
                                                            setCommentsModalOpened(false);
                                                        })
                                                        .catch((err) => {
                                                            toast({
                                                                title:
                                                                    err.response.data.message ||
                                                                    'Ошибка при обновлении',
                                                                status: 'error'
                                                            });
                                                        })
                                                        .finally(() => {
                                                            setIsDecisionPending(false);
                                                        });
                                                }
                                            }}
                                        >
                                            {lastSelectedDecision === 1 ? 'Принять' : 'Отклонить'}
                                        </ChakraButton>
                                    </ModalFooter>
                                </ModalContent>
                            </ChakraModal>
                            <ChakraModal
                                isOpen={newFeSiteModalOpened}
                                onClose={() => setNewFeSiteModalOpened(false)}
                                size="xl"
                            >
                                <ModalOverlay />

                                <ModalContent>
                                    <ModalHeader>Добавление нового FE site</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text mb={1}>FE site id</Text>
                                        <Select
                                            placeholder="Введите для поиска"
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            showSearch
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                            onSearch={(value) => {
                                                setSiteIdSearchText(value);
                                            }}
                                            onChange={handleSiteIdSelect}
                                            optionLabelProp="value"
                                        >
                                            {siteOptions.map((opt: any) => (
                                                <Select.Option
                                                    value={`${opt.s_new_site_id}`}
                                                    key={opt.s_new_site_id}
                                                >
                                                    {opt.s_new_site_id}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        <Text mb={1} mt={1}>
                                            TSD
                                        </Text>
                                        <Dragger
                                            name="file"
                                            maxCount={1}
                                            onChange={async (info: any) => {
                                                setLoadedFile(info);
                                            }}
                                            beforeUpload={() => false}
                                        >
                                            <p className="ant-upload-text">
                                                Нажмите или переместите сюда файл отказа для загрузки
                                            </p>
                                        </Dragger>
                                    </ModalBody>
                                    <ModalFooter>
                                        <ChakraButton colorScheme="purple" mr="auto" onClick={closeFeModal}>
                                            Закрыть
                                        </ChakraButton>
                                        <ChakraButton
                                            colorScheme={lastSelectedDecision === 1 ? 'green' : 'red'}
                                            isLoading={isNewFeSiteCreating}
                                            loadingText="Обработка..."
                                            onClick={addFeSite}
                                        >
                                            Сохранить
                                        </ChakraButton>
                                    </ModalFooter>
                                </ModalContent>
                            </ChakraModal>
                            {role?.name === 'Подрядная организация' ? (
                                <>
                                    {work !== null && processState !== null ? (
                                        <>
                                            <Tag size="lg" marginLeft="6px" width="50vw">
                                                <TagLabel>
                                                    <Text>Тип ЭАП: {work.work_type}</Text>
                                                    <Text>
                                                        Общий статус ЭАП:{' '}
                                                        {getMainWorkStatusByCode(work.s_bs_002_main_status)}
                                                    </Text>
                                                </TagLabel>
                                            </Tag>
                                            <Modal
                                                title="Загрузка доп. файлов"
                                                visible={isAdditionalFileModalOpened}
                                                confirmLoading={isLoading}
                                                onCancel={() => setIsAdditionalFileModalOpened(false)}
                                                onOk={async () => {
                                                    setIsLoading(true);
                                                    const data = new FormData();
                                                    data.append('field', 'tssr_files');
                                                    data.append('work_id', workID!);
                                                    data.append('file', loadedAdditionalFile.file);
                                                    await axios
                                                        .post('/works/upload', data)
                                                        .then(async () => {
                                                            toast({
                                                                status: 'success',
                                                                title: `Доп. файл был успешно загружен`
                                                            });
                                                            setIsAdditionalFileModalOpened(false);
                                                            getWork();
                                                        })
                                                        .catch((err) => {
                                                            setIsLoading(false);
                                                            toast({
                                                                status: 'error',
                                                                title: err.response.data.message
                                                            });
                                                            setIsAdditionalFileModalOpened(false);
                                                        });
                                                }}
                                            >
                                                <Dragger
                                                    name="file"
                                                    maxCount={1}
                                                    disabled={isLoading}
                                                    onChange={async (info) => {
                                                        setLoadedAdditionalFile(info);
                                                    }}
                                                    beforeUpload={() => false}
                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">
                                                        Нажмите или переместите сюда файл для загрузки
                                                    </p>
                                                </Dragger>
                                            </Modal>
                                        </>
                                    ) : (
                                        <Spin style={{ margin: 'auto' }} />
                                    )}
                                </>
                            ) : (
                                <>
                                    <Table
                                        tableLayout="fixed"
                                        loading={isLoading}
                                        pagination={false}
                                        bordered
                                        style={{ width: '100%' }}
                                        dataSource={bs002Data}
                                        columns={bs002Columns}
                                    />
                                </>
                            )}
                        </Box>
                        <DecisionHistory history={work?.workCancelCmnt} title="История отмен ЭАП" />
                        <Box p={2}>
                            {(role?.type === 'rnp' || role?.type === 'leasing') && (
                                <ChangeWorkMainStatus work={work} updateWork={getWork} userRole={role} />
                            )}
                        </Box>
                    </Box>
                </Tabs.TabPane>
                <Tabs.TabPane tab={work?.work_type === 'NCP' ? 'SAR' : 'Документы кандидата'} key="2">
                    {work?.work_type === 'NCP' ? (
                        <WorkRltSar work={work} updateWork={getWork} />
                    ) : (
                        <WorkDocuments work={work} updateWork={getWork} />
                    )}
                </Tabs.TabPane>
            </Tabs>
        </Box>
    );
};

export default Work;
