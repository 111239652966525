// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer.types';
// pages
import Site from 'components/Site';
import WorkRlt from '../WorksRollout/WorkRlt';

// ----------------------------------------------------------------------

export default function SiteWrapper() {
    const user = useSelector((state: RootState) => state.user);

    if (user?.role?.name === 'Подрядная организация') {
        return <WorkRlt />;
    }

    return <Site />;
}
