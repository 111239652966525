import { useParams } from 'react-router-dom';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Stack, useToast, Link, Heading } from '@chakra-ui/react';
import { Upload } from 'antd';
import { DeleteIcon } from '@chakra-ui/icons';
import { orderService } from '../../../../api/services';
import { IOrderAcceptanceDoc } from '../../utils/interfaces/OrderAcceptance';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

interface IProps {
    docs: IOrderAcceptanceDoc[];
    isDisabled?: boolean;
    refetchDocs: () => void;
    isOrderFinished: boolean;
}
const OrderAcceptanceOtherDocs: FC<IProps> = ({ isDisabled, isOrderFinished, docs, refetchDocs }) => {
    const { orderID } = useParams();
    const [file, setFile] = useState<any>();
    const alertRef = useRef(null);
    const toast = useToast();
    const [onDeleteItem, setOnDeleteItem] = useState<IOrderAcceptanceDoc | null>(null);
    const addFile = () => {
        if (!orderID) return;
        const formData = new FormData();
        formData.append('file', file.file);
        orderService
            .addAcceptanceOtherDoc(+orderID, formData)
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Успешно!',
                    description: `Файл загружен`
                });
                refetchDocs();
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось загрузить файл'
                });
            })
            .finally(() => {
                setFile(null);
            });
    };

    const deleteFile = () => {
        if (!onDeleteItem) return;
        orderService
            .deleteAcceptanceDoc(onDeleteItem.id)
            .then(() => {
                toast({
                    status: 'info',
                    title: 'Файл удален'
                });
                refetchDocs();
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось удалить файл'
                });
            })
            .finally(() => {
                setOnDeleteItem(null);
            });
    };

    useEffect(() => {
        if (file?.file) {
            addFile();
        }
    }, [file]);

    return (
        <>
            {onDeleteItem && (
                <ConfirmationDialog
                    modalRef={alertRef}
                    onClose={() => setOnDeleteItem(null)}
                    body={`Вы уверены, что хотите удалить документ ${onDeleteItem.esign?.file_name}`}
                    title="Удаление файла"
                    onAccept={deleteFile}
                    declineText="Отменить"
                    acceptText="Удалить"
                    isOpen
                />
            )}
            <Stack gap={2}>
                <Heading size="sm">Дополнительные документы</Heading>
                <Stack>
                    {docs.length === 0 ? (
                        <div>Список пуст</div>
                    ) : (
                        <>
                            {docs.map((item) => (
                                <Stack
                                    direction="row"
                                    py={1}
                                    borderBottom="1px"
                                    borderColor="gray.200"
                                    alignItems="center"
                                >
                                    <Link
                                        color="blue.600"
                                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                                        isExternal
                                        href={`${process.env.REACT_APP_API_BASE_URL}${item.esign?.doc_link}`}
                                    >
                                        {item.esign?.file_name}
                                    </Link>
                                    <Button isDisabled={isDisabled} variant="link">
                                        <DeleteIcon onClick={() => setOnDeleteItem(item)} />
                                    </Button>
                                </Stack>
                            ))}
                        </>
                    )}
                </Stack>
                {!isOrderFinished && (
                    <Upload
                        name="file"
                        maxCount={1}
                        showUploadList={false}
                        onChange={(file) => setFile(file)}
                        beforeUpload={() => false}
                    >
                        <Button isDisabled={isDisabled} variant="outline" colorScheme="purple" width="100%">
                            Загрузить подписанный скан
                        </Button>
                    </Upload>
                )}
            </Stack>
        </>
    );
};
export default OrderAcceptanceOtherDocs;
