/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
// @chakra-ui
import {
    Button,
    Input,
    Text,
    Box,
    Stack,
    IconButton,
    FormControl,
    FormLabel,
    Textarea,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Divider,
    List,
    ListItem,
    ListIcon,
    UnorderedList,
    FormErrorMessage
} from '@chakra-ui/react';
import { AddIcon, MinusIcon, CheckCircleIcon } from '@chakra-ui/icons';

const remarkConfig = [
    {
        header: 'Подробное описание выявленного дефекта по монтажу БС',
        items: [
            {
                key: 'regEng',
                title: 'Замечание от регионального инженера'
            },
            {
                key: 'mainAl',
                title: 'Замечание от эксплуатации Алматы'
            },
            {
                key: 'energyAndCond',
                title: 'Замечание от группы энергетики и кондиционирования'
            },
            {
                key: 'design',
                title: 'Замечание от группы проектирования'
            },
            {
                key: 'trans',
                title: 'Замечание от отдела трансмиссии'
            },
            {
                key: 'mts',
                title: 'Замечание от группы модернизации и технического контроля'
            }
        ]
    },
    {
        header: 'Подробное описание выявленного дефекта по металлоконструкции',
        items: [
            {
                key: 'nocAndContr',
                title: 'Замечание от группы контроля качества и строительства'
            }
        ]
    }
];

const ValidationSchema = Yup.object().shape({
    regEng: Yup.array().of(Yup.string().required('Поле обязательное')),
    mainAl: Yup.array().of(Yup.string().required('Поле обязательное')),
    energyAndCond: Yup.array().of(Yup.string().required('Поле обязательное')),
    design: Yup.array().of(Yup.string().required('Поле обязательное')),
    trans: Yup.array().of(Yup.string().required('Поле обязательное')),
    nocAndContr: Yup.array().of(Yup.string().required('Поле обязательное')),
    mts: Yup.array().of(Yup.string().required('Поле обязательное'))
});

export default function DefectiveList({ onSubmit, isOpen, onClose, decision, data, isLoading }: any) {
    const initialValues = {
        comment: '',
        regEng: data?.regEng || [],
        mainAl: data?.mainAl || [],
        energyAndCond: data?.energyAndCond || [],
        design: data?.design || [],
        trans: data?.trans || [],
        nocAndContr: data?.nocAndContr || [],
        mts: data?.mts || []
    };

    const renderForm = (values: any, errors: any, touched: any) => {
        if (decision === 1) {
            if (data === null) {
                return <Text>Замечаний нет</Text>;
            }

            const items = remarkConfig
                .map((group) => group.items)
                .flat()
                .filter((item) => data[item.key]?.length);

            return (
                <Box lineHeight="1.5">
                    <Text>Вы соглашаетесь с тем, что следующие замечания были исправлены:</Text>
                    <UnorderedList lineHeight={1.15}>
                        {items.map((item, index) => (
                            <ListItem key={index}>
                                {item.title}
                                <List>
                                    {data[item.key].map((value: string, index: number) => (
                                        <ListItem key={index}>
                                            <ListIcon as={CheckCircleIcon} color="green.500" />
                                            {value}
                                        </ListItem>
                                    ))}
                                </List>
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Box>
            );
        }

        return remarkConfig.map((group) => (
            <Box mb={2}>
                <Text fontSize="lg" mb={2}>
                    {group.header}
                </Text>
                {group.items.map((item) => Remarks(values, errors, touched, item.key, item.title))}
            </Box>
        ));
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
                {({ values, errors, touched }) => (
                    <Form>
                        <ModalContent>
                            <ModalHeader>Принятие решения</ModalHeader>
                            <ModalCloseButton top="0.5rem" right="0.75rem" />
                            <ModalBody>
                                <FormControl>
                                    <FormLabel
                                        sx={{
                                            fontSize: '1rem',
                                            fontWeight: 500,
                                            color: '#1A202C',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        Комментарий
                                    </FormLabel>
                                    <Field
                                        as={Textarea}
                                        name="comment"
                                        sx={{
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            paddingInlineStart: '1rem',
                                            paddingInlineEnd: '1rem'
                                        }}
                                    />
                                </FormControl>
                                <Divider my={3} />
                                {renderForm(values, errors, touched)}
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="purple" mr={3} onClick={onClose}>
                                    Закрыть
                                </Button>
                                <Button
                                    type="submit"
                                    colorScheme={decision === 1 ? 'green' : 'red'}
                                    isLoading={isLoading}
                                >
                                    {decision === 1 ? 'Принять' : 'Отклонить'}
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

function Remarks(values: any, errors: any, touched: any, name: string, title: string) {
    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => (
                <Box mt={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1.5}>
                        <Text
                            sx={{
                                fontWeight: 500
                            }}
                        >
                            {title}
                        </Text>
                        <IconButton
                            variant="outline"
                            aria-label="Add remark"
                            size="sm"
                            icon={<AddIcon />}
                            onClick={() => {
                                arrayHelpers.push('');
                            }}
                        />
                    </Stack>

                    {values[name] && values[name].length ? (
                        <Stack direction="column" spacing={1.5}>
                            {values[name].map((remark: string, index: number) => (
                                <Stack direction="row" alignItems="center" key="index">
                                    <IconButton
                                        variant="outline"
                                        aria-label="Remove remark"
                                        size="sm"
                                        icon={<MinusIcon />}
                                        onClick={() => {
                                            arrayHelpers.remove(index);
                                        }}
                                    />
                                    <FormControl
                                        isInvalid={
                                            errors[name] &&
                                            touched[name] &&
                                            !!errors[name][index] &&
                                            (touched[name][index] as boolean)
                                        }
                                        isRequired
                                    >
                                        <Field as={Input} name={`${name}.${index}`} key={index} />
                                        <FormErrorMessage marginTop="0.5rem">
                                            {errors[name] && errors[name][index]}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Stack>
                            ))}
                        </Stack>
                    ) : null}
                </Box>
            )}
        />
    );
}
