/* eslint-disable no-use-before-define */
import { useState } from 'react';
import axios from 'axios';
// chakra-ui
import {
    Text,
    Input,
    Button,
    List,
    ListItem,
    useToast,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton
} from '@chakra-ui/react';
// components
import { UploadSingleFile } from 'components/upload';

// ----------------------------------------------------------------------

interface ActSignModalProps {
    onClose: () => void;
    updateData: () => void;
    isOpen: boolean;
    groupId: number | null;
}

export default function ActSignModal({ onClose, isOpen, groupId, updateData }: ActSignModalProps) {
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered autoFocus={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Подписание акта</ModalHeader>
                <ModalCloseButton
                    top="0.5rem"
                    right="0.75rem"
                    _focus={{
                        boxShadow: 'none'
                    }}
                />
                <ModalBody>
                    <ActSign groupId={groupId} onClose={onClose} updateData={updateData} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

// ----------------------------------------------------------------------

function ActSign({ groupId, onClose, updateData }: any) {
    const [step, setStep] = useState(1);

    const [isLoading, setIsLoading] = useState(false);

    const [values, setValues] = useState<any>({
        file: null,
        password: '',
        certData: null
    });

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const handleChange = (input: string, value: any) => {
        setValues({ ...values, [input]: value });
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const handleDrop = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];

        if (file) {
            setValues({ ...values, file });
        }
    };

    const handleRemove = () => {
        setValues({ ...values, file: null });
    };

    const handleSign = async () => {
        setIsLoading(true);

        const data = {
            id: groupId,
            data: values.certData,
            cert: values.certData.cert,
            password: values.password
        };

        axios
            .post('/esigns/signDocsActs', data)
            .then(() => {
                setIsLoading(false);
                toast({
                    status: 'success',
                    title: 'Документ успешно подписан'
                });
                onClose();
                updateData();
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
                toast({
                    status: 'error',
                    title: err.response.data.message
                });
            });
    };

    const handleUpload = () => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('password', values.password);

        axios
            .post('/esigns/createSignNew', formData)
            .then((res) => {
                handleChange('certData', res.data);
                nextStep();
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                toast({
                    status: 'error',
                    title: err.response.data.message
                });
                setIsLoading(false);
            });
    };

    return step === 1 ? (
        <UploadSign
            file={values.file}
            password={values.password}
            handleChange={handleChange}
            prevStep={prevStep}
            nextStep={nextStep}
            onDrop={handleDrop}
            onRemove={handleRemove}
            onUpload={handleUpload}
            isLoading={isLoading}
        />
    ) : step === 2 ? (
        <Sign
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange}
            values={values}
            onSign={handleSign}
            isLoading={isLoading}
        />
    ) : null;
}

// ----------------------------------------------------------------------

function UploadSign({ file, onDrop, onRemove, handleChange, password, isLoading, onUpload }: any) {
    return (
        <Box pb={2}>
            {!file ? (
                <UploadSingleFile
                    file={file}
                    onRemove={onRemove}
                    accept={{}}
                    onDrop={onDrop}
                    maxSize={51200}
                    callToActionText="Перетащите сюда ЭЦП ключ (RSA***.p12) или нажмите, чтобы просмотреть на вашем устройстве"
                />
            ) : (
                <Box mt={1.5}>
                    <Text mb="0.5rem" fontWeight="medium">
                        Введите пароль от ключа ЭЦП
                    </Text>
                    <Input
                        autoComplete="new-password"
                        placeholder="Пароль"
                        onChange={(e) => handleChange('password', e.target.value)}
                        type="password"
                        value={password}
                    />
                    <Box textAlign="right" mt="1rem" mb="0.5rem">
                        <Button
                            colorScheme="purple"
                            isLoading={isLoading}
                            onClick={onUpload}
                            disabled={!password}
                        >
                            Далее
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

// ----------------------------------------------------------------------

function Sign({ prevStep, values, onSign, isLoading }: any) {
    const { WhomIssued, Country, Date1, Date2, IssuedBy } = values.certData.fieldcert;

    return (
        <>
            <Text fontWeight="semibold" mb="0.5rem">
                Информация о ключе:
            </Text>
            <List>
                <ListItem>Выдано для: {WhomIssued.replace('CN=', '')}</ListItem>
                <ListItem>Страна: {Country.replace('C=', '')}</ListItem>
                <ListItem>Дата выдачи: {Date1.replace('notBefore=', '')}</ListItem>
                <ListItem>Дата истечения: {Date2.replace('notAfter=', '')}</ListItem>
                <ListItem>Кем выдано: {IssuedBy.replace('CN=', '')}</ListItem>
            </List>
            <Box textAlign="right" mt="1rem" mb="0.5rem">
                <Button disabled={isLoading} onClick={prevStep} mr="0.75rem">
                    Назад
                </Button>
                <Button isLoading={isLoading} disabled={isLoading} onClick={onSign} colorScheme="purple">
                    Подписать
                </Button>
            </Box>
        </>
    );
}
