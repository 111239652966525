import { ChangeEvent, FC, memo, useState } from 'react';
import { nanoid } from 'nanoid';
import { InputInner, InputLabel, InputWrapper } from './Input.styles';

interface IInputProps {
    type?: string;
    label?: string;
    placeholder?: string;
    value?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<IInputProps> = ({ type, label, placeholder, value, onChange }) => {
    const [id] = useState<string>(nanoid());

    return (
        <InputWrapper>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <InputInner placeholder={placeholder} type={type} id={id} value={value} onChange={onChange} />
        </InputWrapper>
    );
};

export default memo(Input);
