import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import BGUEdit from 'components/GSM/BGUEdit';
import BGUWorkShiftsEdit from 'components/GSM/BGUWorkShiftsEdit';
import DGUEdit from 'components/GSM/DGUEdit';
import EditCarGSM from 'components/GSM/EditCarGSM';
import WorkShiftEdit from 'components/GSM/WorkShiftEdit';
import useHeaderTitle from 'hooks/useHeaderTitle';

const screens = ['Заправки авто', 'Водительские смены', 'Заправки БГУ', 'Заправки ДГУ', 'Смены БГУ'];

export default function EditingGSM() {
    useHeaderTitle(`Редактирование отчетов`);

    return (
        <Box>
            <Box display="flex" justifyContent="center" mt="4">
                <Tabs
                    variant="soft-rounded"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    colorScheme="purple"
                >
                    <TabList>
                        {screens.map((item) => (
                            <Tab>{item}</Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <EditCarGSM />
                        </TabPanel>
                        <TabPanel>
                            <WorkShiftEdit />
                        </TabPanel>
                        <TabPanel>
                            <BGUEdit />
                        </TabPanel>
                        <TabPanel>
                            <DGUEdit />
                        </TabPanel>
                        <TabPanel>
                            <BGUWorkShiftsEdit />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    );
}
