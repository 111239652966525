import axios, { AxiosError } from 'axios';
import { getState, clearState } from './localStorage';
import store from '../redux/store';
import { clearUserData } from '../redux/reducers/userSlice';

const initConfig = () => {
    axios.interceptors.request.use(
        (config) => {
            const state = getState();
            return {
                ...config,
                baseURL: process.env.REACT_APP_API_BASE_URL,
                headers: {
                    ...config.headers,
                    'Content-Type': 'application/json',
                    ...(state?.user?.token ? { Authorization: `Bearer ${state.user.token}` } : {})
                }
            };
        },
        (error: AxiosError) => Promise.reject(error)
    );
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 401) {
                clearState();
                store.dispatch(clearUserData());
            }
            return Promise.reject(error);
        }
    );
    // @TODO Add the interceptor for response with rules for refresh token after adjustments for strapi auth
    // Example: https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
};

export default initConfig;
