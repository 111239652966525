/* eslint-disable no-use-before-define */
import {
    Box,
    Button,
    Checkbox,
    Flex,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Text,
    Textarea,
    useToast
} from '@chakra-ui/react';
import { Select } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import axios from 'axios';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from 'utils/client';
import { BsCheckSquare, BsXSquare } from 'react-icons/bs';
import { IRegistrationReq } from 'interfaces/registrationReq';
import { IUserRole } from 'redux/reducers/userSlice.types';
import { ERoleNames } from '../../constants';
import { ISubc } from '../../interfaces/subc';

const Requests = () => {
    const columns: ColumnsType<any> = [
        {
            title: 'ID заявки',
            dataIndex: 'id'
        },
        {
            title: 'Фамилия сотрудника',
            dataIndex: 'surname'
        },
        {
            title: 'Имя сотрудника',
            dataIndex: 'name'
        },
        {
            title: 'Отчество',
            dataIndex: 'patronymic'
        },
        {
            title: 'Номер телефона',
            dataIndex: 'phoneNumber'
        },
        {
            title: 'Запрашиваемая роль',
            dataIndex: ['role', 'name']
        },
        {
            title: 'Подрядчик',
            dataIndex: ['role'],
            key: 'subcon',
            render: (role: IUserRole, record: IRegistrationReq) => {
                if ((role.name === ERoleNames.SUBC || role.name === ERoleNames.EXECUTOR) && !record.subcon) {
                    return (
                        <div style={{ width: '150px' }}>
                            <Select
                                placeholder="Выберите подрядчика"
                                style={{ width: '100%' }}
                                onChange={(e) => handleSubconChange(e, record.id)}
                                showSearch
                                value={
                                    subcs[record.id]
                                        ? `${subcs[record.id].id}+${subcs[record.id].name}`
                                        : null
                                }
                            >
                                {subconList?.map((subcon: any) => (
                                    <Select.Option value={`${subcon.id}+${subcon.name}`} key={subcon.id}>
                                        {subcon.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    );
                }
                return record.subcon?.name;
            }
        },
        {
            title: 'Почта',
            dataIndex: 'email'
        },
        {
            title: 'Дата подачи заявки',
            dataIndex: 'created_at',
            render: (text: string) => <span>{new Date(text).toLocaleString('ru-RU')}</span>
        },
        {
            title: 'Статус заявки',
            dataIndex: 'status',
            render: (status: string) =>
                status === 'waiting_for_email_approve'
                    ? 'Ожидает подтверждения email'
                    : status === 'waiting_for_liable_approve'
                    ? 'Ожидает согласования'
                    : status === 'approved'
                    ? 'Создан'
                    : 'Отклонен'
        },
        {
            title: 'Обновлено',
            dataIndex: 'updated_at',
            render: (text: string) => <span>{new Date(text).toLocaleString('ru-RU')}</span>
        },
        {
            title: 'Действие',
            dataIndex: 'id',
            render: (id: string, record: any) => (
                <Box display="flex" flexDir="row">
                    <IconButton
                        variant="ghost"
                        icon={<BsCheckSquare style={{ color: 'green' }} />}
                        mr={2}
                        isLoading={isButtonsPending}
                        onClick={() => approve(id)}
                        isDisabled={
                            record.status !== 'waiting_for_liable_approve' ||
                            (!subcs[record.id] &&
                                (record.role.name === ERoleNames.SUBC ||
                                    record.role.name === ERoleNames.EXECUTOR))
                        }
                        aria-label="approve"
                    />
                    <IconButton
                        variant="ghost"
                        icon={<BsXSquare style={{ color: 'red' }} />}
                        isDisabled={record.status !== 'waiting_for_liable_approve'}
                        isLoading={isButtonsPending}
                        onClick={() => {
                            setIsCommentsModalOpened(true);
                        }}
                        aria-label="decline"
                    />
                    <Modal
                        isOpen={isCommentsModalOpened}
                        onClose={() => setIsCommentsModalOpened(false)}
                        size="xl"
                    >
                        <ModalContent>
                            <ModalHeader>Комментирование отказа</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text mb={1}>Комментарий</Text>
                                <Textarea
                                    paddingInlineStart={2}
                                    paddingInlineEnd={2}
                                    onChange={(e) => {
                                        setDeclineComment(e.target.value);
                                    }}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    colorScheme="purple"
                                    mr="auto"
                                    onClick={() => setIsCommentsModalOpened(false)}
                                >
                                    Закрыть
                                </Button>
                                <Button
                                    disabled={!declineComment}
                                    colorScheme="red"
                                    isLoading={isButtonsPending}
                                    loadingText="Обработка..."
                                    onClick={() => {
                                        reject(id, declineComment);
                                    }}
                                >
                                    Отклонить
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Box>
            )
        }
    ];
    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const [isButtonsPending, setIsButtonsPending] = useState(false);

    const [createNewSubc, setCreateNewSubc] = useState<Record<number, boolean>>({});

    const [isActive, setIsActive] = useState(true);

    const [subcs, setSubcs] = useState<{ [key: string]: { id: number; name: string } }>({});

    const [subconList, setSubconList] = useState<string[]>();

    const [searchInput, setSearchInput] = useState('');

    const [isCommentsModalOpened, setIsCommentsModalOpened] = useState(false);

    const [declineComment, setDeclineComment] = useState('');

    const { data } = useSWR(
        `/registration-requests/?_sort=id:ASC${isActive ? `&status=waiting_for_liable_approve` : ''}${
            searchInput ? `&email_contains=${searchInput}` : ''
        } `,
        fetcher,
        { refreshInterval: 150 }
    );

    useEffect(() => {
        if (!data) return;
        const subcsItems: { [key: string]: ISubc } = {};
        data.forEach((item: { id: number; role: { name: ERoleNames }; subcon?: ISubc }) => {
            if (item.role.name === ERoleNames.SUBC && item.subcon && !subcs[item.id]) {
                subcsItems[item.id] = item.subcon;
            }
        });
        setSubcs({ ...subcs, ...subcsItems });
    }, [data]);

    const handleSubconChange = (e: string, recordId: number) => {
        const [id, name] = e.split('+');
        setSubcs({ ...subcs, [recordId]: { id, name } });
    };

    const getSubconList = () => {
        axios
            .get('/subcs?_isOrganization=true')
            .then((res) => {
                setSubconList(res.data);
            })
            .catch((err) => console.error(err));
    };
    useEffect(() => {
        getSubconList();
    }, []);

    const approve = async (id: any) => {
        setIsButtonsPending(true);

        await axios
            .post('/registration-requests/approve', {
                requestID: Number(id),
                subc: subcs[id]?.id
            })
            .then(async () => {
                toast({ title: 'Заявка согласована', status: 'success' });
                setIsButtonsPending(false);
            })
            .catch((err) => {
                console.log(err);
                toast({ title: 'Не удалось согласовать заявку', status: 'error' });
                setIsButtonsPending(false);
            });
    };

    const reject = async (id: any, comment: string) => {
        setIsButtonsPending(true);

        await axios
            .post('/registration-requests/reject', {
                requestID: Number(id),
                comment
            })
            .then(async () => {
                toast({ title: 'Заявка отклонена', status: 'success' });
                setIsButtonsPending(false);
                setIsCommentsModalOpened(false);
                setDeclineComment('');
            })
            .catch((err) => {
                console.log(err);
                toast({ title: 'Не удалось отклонить заявку', status: 'error' });
                setIsButtonsPending(false);
            });
    };

    return (
        <>
            <Table
                bordered
                size="small"
                indentSize={0}
                scroll={{ x: true }}
                rowKey="id"
                dataSource={data}
                title={() => (
                    <Flex alignItems="center">
                        <Text fontSize="md" fontWeight={600}>
                            Заявки на создание аккаунта
                        </Text>
                        <Checkbox
                            marginLeft={4}
                            defaultChecked
                            onChange={(e) => {
                                setIsActive(e.target.checked);
                            }}
                        >
                            Актуальные заявки
                        </Checkbox>
                        <Input
                            size="sm"
                            value={searchInput}
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                            }}
                            style={{ width: '300px', marginLeft: 'auto' }}
                            placeholder="Поиск по почте"
                        />
                    </Flex>
                )}
                columns={columns}
            />
        </>
    );
};

export default Requests;
