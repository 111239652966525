import React, { FC } from 'react';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text
} from '@chakra-ui/react';
import { ITrEquipment } from '../../utils/interfaces/MatList';

export interface IProps {
    item: ITrEquipment;
    onClose: () => void;
    maxQty: number;
}
const TrEquipmentInvalidDetailModal: FC<IProps> = ({ item, onClose, maxQty }) => (
    <Modal isOpen onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader borderBottom="1px solid" borderColor="gray.300">
                Информация о оборудовании {item.matListEquipment.equipment?.sap_code}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Stack gap={2}>
                    <div>
                        <b>Запрошено в мат. листе:</b> {item.matListEquipment.qty}{' '}
                        {item.matListEquipment.equipment?.s_unit?.toLowerCase()}
                    </div>
                    <div>
                        <b>Ожидает выдачи:</b> {item.matListEquipment.awaitingQty}{' '}
                        {item.matListEquipment.equipment?.s_unit?.toLowerCase()}
                    </div>
                    <div>
                        <b>Выдано:</b> {item.matListEquipment.issuedQty}{' '}
                        {item.matListEquipment.equipment?.s_unit?.toLowerCase()}
                    </div>
                </Stack>
                <Text py={2} fontWeight="bold">
                    Вы не можете выдать более {maxQty}
                    {item.matListEquipment.equipment?.s_unit?.toLowerCase()} оборудования, т.к. в ином случае
                    количество выданного оборудования будет превышать запрошенное.
                </Text>
            </ModalBody>
        </ModalContent>
    </Modal>
);

export default TrEquipmentInvalidDetailModal;
