import {
    Button,
    ButtonGroup,
    FormControl,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Textarea
} from '@chakra-ui/react';
import { FC } from 'react';
import { useFormik } from 'formik';
import Label from './ui/Label';
import { IRejectMatListFrom } from '../Routes/Order/utils/interfaces/MatList';

interface IProps {
    onClose: () => void;
    onSubmit: (comment: string) => void;
    placeholder?: string;
    title?: string;
}

const CommentFormModal: FC<IProps> = ({ onClose, onSubmit, title, placeholder = 'Введите комментарий' }) => {
    const rejectForm = useFormik<IRejectMatListFrom>({
        initialValues: {
            comment: ''
        },
        onSubmit: (values) => {
            onSubmit(values.comment);
        }
    });
    return (
        <Modal size="xl" isOpen onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                {title && <ModalHeader>{title}</ModalHeader>}
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={rejectForm.handleSubmit}>
                        <FormControl>
                            <Label text="Комментарий" />
                            <Textarea
                                name="comment"
                                value={rejectForm.values.comment}
                                onChange={rejectForm.handleChange}
                                placeholder={placeholder || 'Введите комментарий'}
                                size="sm"
                            />
                        </FormControl>
                        <Stack alignItems="end">
                            <ButtonGroup my={1}>
                                <Button type="button" onClick={onClose}>
                                    Отмена
                                </Button>
                                <Button colorScheme="purple" disabled={!rejectForm.isValid} type="submit">
                                    Подтвердить
                                </Button>
                            </ButtonGroup>
                        </Stack>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CommentFormModal;
