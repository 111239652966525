import styled from 'styled-components';

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const InputInner = styled.input`
    width: 100%;
    height: 38px;
    background: #ffffff;
    border: 1px solid #b9b9c3;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 14px;
    outline: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #f3f3f3;
    }

    &:focus {
        background: #ffffff;
        border-color: #1b41c5;
    }

    &::placeholder {
        color: #b9b9c3;
    }
`;

const InputLabel = styled.label`
    margin-bottom: 6px;
    margin-left: 2px;
    margin-right: auto;
    color: #6e6b7b;
    font-size: 12px;
    cursor: pointer;
`;

export { InputWrapper, InputInner, InputLabel };
