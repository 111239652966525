import { ChangeEventHandler, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useDebounce from './useDebounce';

export default function useFilter({ onChangeCallback }: { onChangeCallback?: () => void }) {
    const [filter, setFilter] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [isDisabledFilter, setIsDisabledFilter] = useState(false);

    useEffect(() => {
        if (searchParams.size === 0) return;
        const searchAsObject = Object.fromEntries(new URLSearchParams(searchParams));
        if (searchAsObject.disabledFilter) {
            setIsDisabledFilter(true);
            delete searchAsObject.disabledFilter;
        }
        setFilter({ ...filter, ...searchAsObject });
    }, [searchParams]);

    const debouncedFilter = useDebounce(filter, 500);

    const resetFilters = () => {
        setFilter({});
        setSearchParams({});
        setIsDisabledFilter(false);
        if (onChangeCallback) {
            onChangeCallback();
        }
    };

    const handleSetFilter: ChangeEventHandler = (e) => {
        const target = e.target as HTMLFormElement;
        setFilter({ ...filter, [target.name]: target.value });
        if (onChangeCallback) {
            onChangeCallback();
        }
    };

    return {
        filter,
        isDisabledFilter,
        resetFilters,
        debouncedFilter,
        handleSetFilter
    };
}
