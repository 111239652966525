const addHours = (date: string | null | undefined, h: number) => {
    if (date) {
        const newDate = new Date(date);
        newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
        return newDate.toLocaleString('ru-RU');
    }
    return '';
};

export default addHours;
