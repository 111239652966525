// @chakra-ui
import { Box, Text, Stack } from '@chakra-ui/react';
// assets
import { UploadIllustration } from 'assets';

// ----------------------------------------------------------------------

interface BlockContentProps {
    callToActionText?: string;
}

export default function BlockContent(props: BlockContentProps) {
    const { callToActionText } = props;
    return (
        <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            width="full"
            // textAlign={{ base: 'center', md: 'left' }}
            // direction={{ base: 'column', md: 'row' }}
            // sx={{ width: '100%', textAlign: { base: 'center', md: 'left' } }}
            direction="column"
            textAlign="center"
        >
            <UploadIllustration sx={{ width: 220 }} />

            <Box sx={{ p: 3 }}>
                {/* <Text>Перетащите файлы сюда или нажмите, чтобы просмотреть в вашем компьютере</Text> */}
                <Text>
                    {callToActionText ||
                        'Перетащите файлы сюда или нажмите, чтобы просмотреть на вашем компьютере'}
                </Text>
            </Box>
        </Stack>
    );
}
