/* eslint-disable no-use-before-define */
import { memo } from 'react';
// @chakra-ui
import { Text } from '@chakra-ui/react';

interface WorkTssrInstructionProps {
    siteId: string;
    feSiteId: string;
    workType: string;
}

const WorkTssrInstruction = memo(({ siteId, feSiteId, workType }: WorkTssrInstructionProps) => {
    const replacedWorkType = replaceWorkType(workType);

    let expectedName = feSiteId
        ? `TSSR_NE(${siteId})_FE(${feSiteId})_${replacedWorkType}`
        : `TSSR_${siteId}_${replacedWorkType}`;

    expectedName += '_Дополнительная информация_vНомер версии документа';

    return (
        <Text fontStyle="italic" color="green">
            Инструкции по названию файлов TSSR:
            <br />
            a) Если есть FE сайт:
            <br />
            TSSR_NE(Название сайта NE)_FE(Название файла FE)_Основной тип ЭАП_Дополнительная информация_vНомер
            версии документа.xlsx
            <br />
            Пример - TSSR_NE(KA7906)_FE(KA7900)_Build_1_0_v1.xlsx
            <br />
            a) Если нет FE сайта:
            <br />
            TSSR_Название сайта_Тип ЭАП_Дополнительная информация_vНомер версии документа.xlsx
            <br />
            Пример - TSSR_AL7063_LTE_UPG_RET(9)_v1.xlsx
            <br />
            <br />
            Если в типе ЭАП присутствует символ &quot;:&quot; , то его можно не указывать в названии
            документа.
            <br />
            Если в типе ЭАП присутствуют пробелы или знак &quot;+&quot; то их необходимо заменить на
            &quot;_&quot;.
            <br />
            Дополнительная информация - опционально.
            <Text fontWeight="bold">Ожидаемое название документа для данного ЭАП - {expectedName}</Text>
        </Text>
    );
});

export default WorkTssrInstruction;

function replaceWorkType(workType: string) {
    return workType.replace(/:/g, '').replace(/ |\+/g, '_');
}
