import { FC, ReactNode } from 'react';
import { Button } from '@chakra-ui/react';

interface AuthSubmitButtonProps {
    children: ReactNode;
    isLoading: boolean;
    isDisabled: boolean;
}

/**
 * @component
 * @param { ReactNode } children ReactNode
 * @param { boolean } isLoading If true, the button will show a spinner
 * @param { isDisabled } isDisabled If true, the button will be disabled
 */
const AuthSubmitButton: FC<AuthSubmitButtonProps> = ({ children, isLoading, isDisabled }) => (
    <Button
        backgroundColor="#7c2eab"
        _hover={{
            backgroundColor: '#652d86'
        }}
        transition="all .2s ease-in-out"
        colorScheme="purple"
        type="submit"
        isLoading={isLoading}
        isDisabled={isDisabled}
        loadingText="Подождите..."
    >
        {children}
    </Button>
);

export default AuthSubmitButton;
