import axios from 'axios';
import { IOrderProject } from '../../Routes/Order/OrderProject';

const getByOrderId = (id: number) => axios.get(`orders/${id}/project`);

const update = (id: number, data: Partial<IOrderProject>) => axios.put(`/orders/projects/${id}`, data);

const uploadFile = (id: number, data: FormData) => axios.patch(`/orders/projects/${id}/upload-file`, data);

const startProcess = (id: number) => axios.patch(`/orders/projects/${id}/start-process`);
const updateProjectFile = (projectId: number, esignId: number) =>
    axios.put(`/orders-projects/${projectId}/update-file`, { esignId });

const getHistory = (projectId: number) => axios.get(`/orders/projects/${projectId}/history`);
export default {
    getHistory,
    getByOrderId,
    uploadFile,
    update,
    startProcess,
    updateProjectFile
};
