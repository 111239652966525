import { Box, Button, Fade, Flex, Input, Text } from '@chakra-ui/react';
import { Table } from 'antd';
import axios from 'axios';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function WorkShiftEdit() {
    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Номер машины',
            dataIndex: 'carNumber',
            key: 'carNumber'
        },
        {
            title: 'Начало смены(одометр)',
            dataIndex: 'odometerStart',
            key: 'odometerStart'
        },
        {
            title: 'Конец смены(одометр)',
            dataIndex: 'odometerFinish',
            key: 'odometerFinish'
        },
        {
            title: 'Начало смены(дата)',
            dataIndex: 'shift_start',
            key: 'shift_start'
        },
        {
            title: 'Конец смены(дата)',
            dataIndex: 'shift_end',
            key: 'shift_end'
        },
        {
            title: '',
            key: 'key',
            dataIndex: 'key',
            render: (text: any, record: any) => (
                <Link to={`work-shift/${record.id}`}>
                    <Button
                        style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}
                        colorScheme="purple"
                    >
                        Редактировать
                    </Button>
                </Link>
            )
        }
    ];

    const [data, setData] = useState<Array<any>>([]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [carNumber, setCarNumber] = useState('');
    const [userName, setUserName] = useState('');
    const [date, setDate] = useState('');

    const debouncedUserName = useDebounce(userName, 500);

    const debouncedCarNumber = useDebounce(carNumber, 500);

    const debouncedDate = useDebounce(date, 500);

    const getData = async () => {
        try {
            setIsLoading(true);

            let req = `/driver-work-shifts/count?_start=${start}&_limit=10&_sort=created_at:DESC`;

            if (debouncedCarNumber !== '')
                req += `&_where[carNumber.state_number_contains]=${debouncedCarNumber}`;

            if (debouncedUserName !== '') req += `&_where[name_contains]=${debouncedUserName}`;

            if (debouncedDate !== '')
                req = req
                    .split('&_sort=created_at:DESC')
                    .join(
                        `&_sort=created_at:DESC&_where[shift_start_gte]=${moment(debouncedDate).format(
                            'YYYY-MM-DD'
                        )}&_where[shift_start_lte]=${moment(debouncedDate)
                            .add(1, 'days')
                            .format('YYYY-MM-DD')}`
                    );

            const [count, driverShifts] = await Promise.all<[number, Array<any>]>([
                (await axios.get(req)).data,
                (await axios.get(req.split('count').join('find-without-pagination'))).data
            ]);

            const tmpData = driverShifts.map((item: any) => ({
                name: item.name,
                carNumber: item.carNumber.state_number,
                odometerStart: item.odometerStart,
                odometerFinish: item.odometerFinish === '' ? 'Смена не закрыта' : item.odometerFinish,
                shift_start: moment(item.shift_start).format('DD.MM.YYYY HH:mm'),
                shift_end: item.odometerFinish
                    ? moment(item.shift_end).format('DD.MM.YYYY HH:mm')
                    : 'Смена не закрыта',
                id: item.id
            }));

            setData(tmpData);
            setCount(count);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [start, debouncedCarNumber, debouncedUserName, debouncedDate]);

    const onPaginationChange = (page: number) => {
        console.log(page - 1);
        setStart((page - 1) * 10);
    };
    const onPageSizeChange = (current: number) => {
        setStart((current - 1) * 10);
    };

    return (
        <Fade in>
            <Table
                bordered
                loading={isLoading}
                scroll={{ x: true }}
                pagination={{
                    pageSize: 10,
                    total: count || 1,
                    onChange: onPaginationChange,
                    onShowSizeChange: onPageSizeChange
                }}
                title={() => (
                    <Flex alignItems="center">
                        <Text fontSize="md" fontWeight={600}>
                            Список ГСМ авто
                        </Text>
                        <Input
                            type="text"
                            placeholder="Имя пользователя"
                            maxWidth="250"
                            ml="10"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                        <Input
                            type="text"
                            placeholder="Номер авто"
                            maxWidth="250"
                            ml="10"
                            value={carNumber}
                            onChange={(e) => setCarNumber(e.target.value)}
                        />
                        <Box ml="10" display="flex" alignItems="center">
                            <Text fontSize="md" fontWeight={600} minWidth="170">
                                Дата начала смены:
                            </Text>
                            <Input
                                type="date"
                                maxWidth="250"
                                value={moment(date).format('YYYY-MM-DD')}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </Box>
                    </Flex>
                )}
                dataSource={data}
                columns={columns}
            />
        </Fade>
    );
}
