import { Button, Divider } from 'antd';
import { VStack } from '@chakra-ui/react';
import FileItem from 'components/FileItem';
import HasAccess from '../../../guards/HasAccess';
import { ERoleNames } from '../../../constants';

interface ISurveyListProps {
    order: any;
    isTssrInProcess: boolean;
    onSurveyListUploadClick: () => void;
}

const SurveyList = (props: ISurveyListProps) => {
    const { order, isTssrInProcess, onSurveyListUploadClick } = props;
    console.log('[ORDER]', order);

    console.log('[ISTSSR IN PROCESS]', isTssrInProcess);
    return (
        <>
            {order?.surveyList?.esigns ? (
                <>
                    <Divider style={{ background: '#1F2229' }} />
                    <div style={{ minWidth: '400px' }}>
                        <h2 style={{ fontSize: '24px', textAlign: 'center' }}>Лист обследования</h2>
                        <VStack width="full" spacing={0.5}>
                            {order?.surveyList?.esigns
                                ? order.surveyList.esigns.map((item: any) => (
                                      <FileItem
                                          fileName={item.file_name}
                                          date={item.updated_at}
                                          onDownload={() => {
                                              const url = item?.from_sad
                                                  ? `https://sad.tele2.kz/tele2${item.doc_link.replace(
                                                        '.',
                                                        '',
                                                        1
                                                    )}`
                                                  : `${process.env.REACT_APP_API_BASE_URL}${item.doc_link}`;
                                              window.open(url);
                                          }}
                                          disableRemove={isTssrInProcess || item.banOnDeletion}
                                      />
                                  ))
                                : null}
                        </VStack>
                    </div>
                </>
            ) : null}
            <HasAccess roleNames={[ERoleNames.SUBC, ERoleNames.EXECUTOR]}>
                {!order?.tssr_status && (
                    <Button
                        style={{
                            marginTop: '8px',
                            marginBottom: '8px',
                            background: '#1F2229',
                            color: 'white',
                            borderRadius: '6px'
                        }}
                        onClick={onSurveyListUploadClick}
                    >
                        Загрузить лист обследования
                    </Button>
                )}
            </HasAccess>
        </>
    );
};

export default SurveyList;
