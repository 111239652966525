export const WORK_STATUSES: Record<string, string> = {
    allOrdersCompleted: 'Все заказы выполнены',
    hasOrders: 'Есть заказы в работе',
    leasingApprove: 'Согласование кандидата лизингом',
    prepareSar: 'Подготовка SAR',
    readyForOrder: 'Готов к созданию заказов',
    uploadRsd: 'Загрузка RSD',
    uploadTsd: 'Загрузка TSD',
    cancelled: 'Отменен'
};

export const WORK_DETAIL_STATUSES: Record<string, string> = {
    allOrdersCompleted: 'Все заказы выполнены',
    hasOrders: 'Есть заказы в работе',
    leasingApprove: 'Кандидат согласован лизингом',
    prepareSar: 'SAR подготовлен',
    readyForOrder: 'Готов к созданию заказов',
    uploadRsd: 'RSD загружен',
    uploadTsd: 'TSD загружен'
};
