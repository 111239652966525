import axios from 'axios';

const check = () => axios.post('/reviews/check');

const rate = (payload: any) => axios.post('/reviews/rate', payload);

export default {
    check,
    rate
};
