// @chakra-ui
import { Table, Tbody, Tr, Td } from '@chakra-ui/react';
//
import TableHeadCustom from './TableHeadCustom';

// ----------------------------------------------------------------------

export default function CustomTable({
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    ...other
}: any) {
    return (
        <Table {...getTableProps()} {...other}>
            <TableHeadCustom headerGroups={headerGroups} />
            <Tbody {...getTableBodyProps()}>
                {page.map((row: any) => {
                    prepareRow(row);
                    return (
                        <Tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => (
                                <Td
                                    {...cell.getCellProps({ className: cell.column.className })}
                                    isNumeric={cell.column.isNumeric}
                                >
                                    {cell.render('Cell')}
                                </Td>
                            ))}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
}
