import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyles = createGlobalStyle`
    ${reset}
    
    html * {
        border-style: solid;
    }
    
    html, body {
        scroll-behavior: smooth;
        overflow: hidden;
        color: #1F2229;
        background-color: #F8F8F8;
        font-family: 'Nunito', sans-serif !important;
    }

    a {
        text-decoration: none;
        color: inherit;
        transition: none;

        &:hover {
            color: inherit;
        }
    }

    label {
        user-select: none;
    }

    .ant-tree {
        background: inherit !important;
    }

    .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
        background: inherit !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #1f2229;
    }

    .ant-table th {
        white-space: nowrap;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector { 
        color: inherit;
    }
    
    .ant-input[disabled] {
        color: inherit;
    }

    .ant-picker-input > input[disabled] { 
        color: inherit;
    }

    img { 
        display: initial !important;
    }

    .ant-image-preview-img {
        max-height: 70vh !important;
    }

    .ant-tree-title {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        max-width: calc(100vw - 700px);
    }

    .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
        display: flex;
        flex-direction: row;
    }

    .ant-popover-inner {
        border-radius: 6px !important;
    }

    .chakra-input, .chakra-select, .chakra-textarea {
        border-color: var(--chakra-colors-gray-200) !important;
    }

    // .chakra-input {
    //     border-radius: 6px !important;
    // }

    .chakra-skeleton {
        border-radius: 10px !important;
    }

    .ant-table-title {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    .ant-tabs-content-holder {
        overflow-y: auto;
    }

    .ant-collapse {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .chakra-checkbox__control {
        margin-bottom: 2px;
    }

    .chakra-checkbox__control:not([data-checked]) {
        border-color: var(--chakra-colors-gray-200) !important;
    }

    #chakra-toast-manager-bottom-right {
        bottom: calc(env(safe-area-inset-bottom, 0px) + 16px) !important;
        right: calc(env(safe-area-inset-right, 0px) + 12px) !important;
    }

    input[type=datetime-local] {
        border: 1px solid gray;
        padding: 3px;
        border-radius: 6px;
    }

    .lightrope {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        margin: -15px 0 0 0;
        padding: 0;
        pointer-events: none;
        width: 100%;
      }
      
      .lightrope li {
        position: relative;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;
        width: 12px;
        height: 28px;
        border-radius: 50%;
        margin: 20px;
        display: inline-block;
        background: #00f7a5;
        box-shadow: 0px 4.66667px 24px 3px #00f7a5;
        -webkit-animation-name: flash-1;
        animation-name: flash-1;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
      }
      
      .lightrope li:nth-child(2n+1) {
        background: cyan;
        box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.5);
        -webkit-animation-name: flash-2;
        animation-name: flash-2;
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
      }
      
      .lightrope li:nth-child(4n+2) {
        background: #f70094;
        box-shadow: 0px 4.66667px 24px 3px #f70094;
        -webkit-animation-name: flash-3;
        animation-name: flash-3;
        -webkit-animation-duration: 1.1s;
        animation-duration: 1.1s;
      }
      
      .lightrope li:nth-child(odd) {
        -webkit-animation-duration: 1.8s;
        animation-duration: 1.8s;
      }
      
      .lightrope li:nth-child(3n+1) {
        -webkit-animation-duration: 1.4s;
        animation-duration: 1.4s;
      }
      
      .lightrope li:before {
        content: "";
        position: absolute;
        background: #222;
        width: 10px;
        height: 9.33333px;
        border-radius: 3px;
        top: -4.66667px;
        left: 1px;
      }
      
      .lightrope li:after {
        content: "";
        top: -14px;
        left: 9px;
        position: absolute;
        width: 52px;
        height: 18.66667px;
        border-bottom: solid #222 2px;
        border-radius: 50%;
      }
      
      .lightrope li:last-child:after {
        content: none;
      }
      
      .lightrope li:first-child {
        margin-left: -40px;
      }
      
      @-webkit-keyframes flash-1 {
        0%, 100% {
          background: #00f7a5;
          box-shadow: 0px 4.66667px 24px 3px #00f7a5;
        }
        50% {
          background: rgba(0, 247, 165, 0.4);
          box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
        }
      }
      
      @keyframes flash-1 {
        0%, 100% {
          background: #00f7a5;
          box-shadow: 0px 4.66667px 24px 3px #00f7a5;
        }
        50% {
          background: rgba(0, 247, 165, 0.4);
          box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
        }
      }
      
      @-webkit-keyframes flash-2 {
        0%, 100% {
          background: cyan;
          box-shadow: 0px 4.66667px 24px 3px cyan;
        }
        50% {
          background: rgba(0, 255, 255, 0.4);
          box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
        }
      }
      
      @keyframes flash-2 {
        0%, 100% {
          background: cyan;
          box-shadow: 0px 4.66667px 24px 3px cyan;
        }
        50% {
          background: rgba(0, 255, 255, 0.4);
          box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
        }
      }
      
      @-webkit-keyframes flash-3 {
        0%, 100% {
          background: #f70094;
          box-shadow: 0px 4.66667px 24px 3px #f70094;
        }
        50% {
          background: rgba(247, 0, 148, 0.4);
          box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
        }
      }
      
      @keyframes flash-3 {
        0%, 100% {
          background: #f70094;
          box-shadow: 0px 4.66667px 24px 3px #f70094;
        }
        50% {
          background: rgba(247, 0, 148, 0.4);
          box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
        }
      }
`;

export default GlobalStyles;
