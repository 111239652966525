import { Button, Card, SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import React, { FC, ReactNode, useEffect, useRef, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Label from 'new-components/ui/Label';
import CommentFormModal from 'new-components/CommentFormModal';
import HistoryModal from 'new-components/HistoryModal';
import HasAccess from 'guards/HasAccess';
import { orderService } from 'api/services';
import ConfirmationDialog from 'components/ConfirmationDialog';
import OrderAcceptanceDoc from './components/OrderAcceptance/OrderAcceptanceDoc';
import { orderAcceptanceAccesses } from './utils/accesses';
import OrderAcceptanceOtherDocs from './components/OrderAcceptance/OrderAcceptanceOtherDocs';
import { EAcceptanceDocsStatuses } from './utils/enums';
import OrderAcceptanceTechActDetail from './components/OrderAcceptance/OrderAcceptanceTechActDetail';
import { IOrderAcceptanceDoc, IOrderAcceptanceDocs } from './utils/interfaces/OrderAcceptance';
import { OrderContext } from './OrderCreate/OrderCreate';

interface IProps {
    isOrderFinished: boolean;
}
const OrderAcceptance: FC<IProps> = ({ isOrderFinished }) => {
    const order = useContext(OrderContext);
    const { orderID } = useParams();
    const [orderAcceptanceDocs, setOrderAcceptanceDocs] = useState<null | IOrderAcceptanceDocs>(null);
    const toast = useToast();
    const alertRef = useRef(null);
    const [approveDocId, setApproveDocId] = useState<number | null>(null);
    const [rejectDocId, setRejectDocId] = useState<number | null>(null);
    const [historyDocId, setHistoryDocId] = useState<number | null>();
    const [docToChangeRequired, setDocToChangeRequired] = useState<number | null>(null);
    const fetchAcceptance = () => {
        if (!orderID) return;
        orderService
            .getAcceptanceDocs(+orderID)
            .then((res) => {
                setOrderAcceptanceDocs(res.data);
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось получить документы приемки'
                });
            });
    };

    useEffect(() => {
        fetchAcceptance();
    }, [orderID]);

    const onUploadDoc = (id: number, formData: FormData) =>
        orderService
            .uploadAcceptanceDoc(id, formData)
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Документ успешно загружен'
                });
                fetchAcceptance();
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: e.response?.data?.message?.title || 'Ошибка',
                    description:
                        e.response?.data?.message?.details ||
                        e.response?.data?.message ||
                        'Не удалось загрузить документ'
                });
                throw new Error(e);
            });

    const onRejectDoc = (comment: string) => {
        if (!rejectDocId) return;
        orderService
            .rejectAcceptanceDoc(rejectDocId, { comment })
            .then(() => {
                toast({
                    status: 'info',
                    title: 'Документ отклонен'
                });
                setRejectDocId(null);
                fetchAcceptance();
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: e.response?.data?.message?.title || 'Ошибка',
                    description: e.response?.data?.message?.details || 'Не удалось отклонить документ'
                });
            });
    };

    const approveAcceptanceDoc = (comment: string) => {
        if (!approveDocId) return;
        orderService
            .approveAcceptanceDoc(approveDocId, { comment })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Документ успешно согласован'
                });
                fetchAcceptance();
                setApproveDocId(null);
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: e.response?.data?.message?.title || 'Ошибка',
                    description: e.response?.data?.message?.details || 'Не удалось согласовать документ'
                });
            });
    };

    const onChangeRequired = () => {
        if (!docToChangeRequired) return;
        orderService
            .changeAcceptanceDocRequired(docToChangeRequired)
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Успешно!'
                });
                fetchAcceptance();
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: e.response?.data?.message?.title || 'Ошибка',
                    description: e.response?.data?.message?.details || 'Не удалось изменить'
                });
            });
    };

    const onFinish = () => {
        if (!orderID) return;
        orderService
            .finish(+orderID)
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Работа заверешена!'
                });
                fetchAcceptance();
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: e.response?.data?.message?.title || 'Ошибка',
                    description: e.response?.data?.message?.details || 'Не удалось завершить работу'
                });
            });
    };

    if (!orderAcceptanceDocs || !order) return null;

    const docsData: {
        id?: number;
        title: string;
        children?: ReactNode;
        doc?: IOrderAcceptanceDoc;
        disabledStatus?: string | null;
    }[] = [
        {
            id: orderAcceptanceDocs.techSupervisionAct?.id,
            title: 'Акт тех. надзора',
            doc: orderAcceptanceDocs.techSupervisionAct
        },
        {
            id: orderAcceptanceDocs.techAct?.doc.id,
            disabledStatus:
                orderAcceptanceDocs.techSupervisionAct &&
                orderAcceptanceDocs.techSupervisionAct?.status !== EAcceptanceDocsStatuses.confirmed
                    ? 'Ожидание согласования акта тех. наздора'
                    : null,
            title: 'Тех. акт',
            doc: orderAcceptanceDocs?.techAct.doc,
            children: (
                <OrderAcceptanceTechActDetail
                    onSuccessUpdate={() => fetchAcceptance()}
                    techAct={orderAcceptanceDocs?.techAct}
                />
            )
        },
        {
            id: orderAcceptanceDocs.materialsReport?.id,
            title: 'Отчёт по материалам',
            doc: orderAcceptanceDocs?.materialsReport
        },
        {
            id: orderAcceptanceDocs.commissioningCert?.doc.id,
            disabledStatus:
                orderAcceptanceDocs.techAct.doc.status !== EAcceptanceDocsStatuses.confirmed
                    ? 'Ожидание согласования тех. акта'
                    : null,
            title: 'Акт ввода в эксплуатацию',
            doc: orderAcceptanceDocs.commissioningCert?.doc,
            children: orderAcceptanceDocs.commissioningCert?.date && (
                <Card p={2}>
                    <Stack gap={1}>
                        <Label text="Дополнительная информация" />
                        <div>
                            Дата АВЭ:{' '}
                            {moment(orderAcceptanceDocs.commissioningCert?.date).format('DD.MM.YYYY')}
                        </div>
                    </Stack>
                </Card>
            )
        }
    ];

    return (
        <>
            {historyDocId && (
                <HistoryModal
                    id={historyDocId}
                    getHistory={orderService.getAcceptanceDocHistory}
                    onClose={() => setHistoryDocId(null)}
                />
            )}
            {rejectDocId && (
                <CommentFormModal
                    title="Отклонение документа"
                    placeholder="Введите причину отклонения"
                    onClose={() => setRejectDocId(null)}
                    onSubmit={onRejectDoc}
                />
            )}
            {approveDocId && (
                <CommentFormModal
                    title="Согласование документа"
                    placeholder="Введите дополнительную информацию"
                    onClose={() => setApproveDocId(null)}
                    onSubmit={approveAcceptanceDoc}
                />
            )}
            {docToChangeRequired && (
                <ConfirmationDialog
                    title="Подтверждение"
                    onAccept={onChangeRequired}
                    body="Вы уверены, что хотите изменить требование документа?"
                    acceptButtonColor="green"
                    isOpen
                    modalRef={alertRef}
                    onClose={() => setDocToChangeRequired(null)}
                />
            )}
            <Stack gap={2}>
                <SimpleGrid columns={2} spacing={2}>
                    {docsData.map((item) => {
                        if (!item.doc || !item.id) return null;
                        return (
                            <Card variant="outline" p={2}>
                                <Stack gap={2}>
                                    <OrderAcceptanceDoc
                                        isDisabled={order.displayConfig.isStopProcess}
                                        isOrderFinished={isOrderFinished}
                                        disabledStatus={item.disabledStatus}
                                        getHistory={() => setHistoryDocId(item.id)}
                                        title={item.title}
                                        hasResolveRoles={orderAcceptanceAccesses.docs[item.doc.type].resolve}
                                        hasUploadRoles={orderAcceptanceAccesses.docs[item.doc.type].upload}
                                        hasChangeRequired={
                                            orderAcceptanceAccesses.docs[item.doc.type].changeRequired
                                        }
                                        onAdd={onUploadDoc}
                                        onReject={() => setRejectDocId(item.id!)}
                                        onChangeRequired={() => setDocToChangeRequired(item.id!)}
                                        doc={item.doc}
                                        onApproveDoc={() => setApproveDocId(item.id!)}
                                    >
                                        {item.children}
                                    </OrderAcceptanceDoc>
                                </Stack>
                            </Card>
                        );
                    })}
                    <Card variant="outline" p={2}>
                        <OrderAcceptanceOtherDocs
                            isDisabled={order.displayConfig.isStopProcess}
                            isOrderFinished={isOrderFinished}
                            refetchDocs={fetchAcceptance}
                            docs={orderAcceptanceDocs.other}
                        />
                    </Card>
                    <Stack my={2}>
                        {!isOrderFinished && (
                            <HasAccess roleNames={orderAcceptanceAccesses.finishWork(orderAcceptanceDocs)}>
                                <Button
                                    isDisabled={order.displayConfig.isStopProcess}
                                    onClick={onFinish}
                                    colorScheme="purple"
                                >
                                    Завершить работу
                                </Button>
                            </HasAccess>
                        )}
                    </Stack>
                </SimpleGrid>
            </Stack>
        </>
    );
};
export default OrderAcceptance;
