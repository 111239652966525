// @chakra-ui
import { Stack, Box, IconButton } from '@chakra-ui/react';
import { DownloadIcon, DeleteIcon } from '@chakra-ui/icons';
import { ERoleNames } from '../constants';
import HasAccess from '../guards/HasAccess';

// ----------------------------------------------------------------------

interface FileItemProps {
    fileName: string;
    date: string;
    disableRemove?: boolean;
    hasRemove?: ERoleNames[];
    onDownload: () => void;
    onRemove?: (() => void) | null;
}

export default function FileItem({
    fileName,
    hasRemove,
    date,
    onDownload,
    onRemove,
    disableRemove
}: FileItemProps) {
    return (
        <Stack direction="row" spacing={1} alignItems="center" fontSize="sm" width="full">
            <Box flexGrow={1} wordBreak="break-word">
                {fileName}
            </Box>
            <Box flexShrink={0}>{new Date(date).toLocaleString('ru')}</Box>
            <Box flexShrink={0} />
            <Box flexShrink={0}>
                <IconButton
                    variant="outline"
                    colorScheme="purple"
                    onClick={onDownload}
                    size="xs"
                    aria-label="Download file"
                    icon={<DownloadIcon />}
                    mr="5px"
                />
                <HasAccess roleNames={hasRemove || null}>
                    {onRemove && (
                        <IconButton
                            variant="outline"
                            colorScheme="red"
                            onClick={onRemove}
                            size="xs"
                            aria-label="Remove file"
                            icon={<DeleteIcon />}
                            isDisabled={disableRemove}
                        />
                    )}
                </HasAccess>
            </Box>
        </Stack>
    );
}
