import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import { Box, Stack, useToast } from '@chakra-ui/react';
import axios from 'axios';
import AuthHeading from 'parts/Auth/components/AuthHeading/AuthHeading';
import AuthDescription from 'parts/Auth/components/AuthDescription/AuthDescription';
import AuthSubmitButton from 'parts/Auth/components/AuthSubmitButton/AuthSubmitButton';
/* import AuthInfoDocuments from 'parts/Auth/components/AuthInfoDocuments/AuthInfoDocuments'; */
/* import AuthRegistrationLink from 'parts/Auth/components/AuthRegistrationLink/AuthRegistrationLink'; */
import FormInputField from 'new-components/FormInputField/FormInputField';
import Link from 'new-components/Link/Link';

interface SetNewPasswordFormInputFields {
    newPassword: string;
}

/**
 * Set new password component, takes reset token from search params.
 * FOR UNAUTH USERS USE.
 *
 * @route /set-new-password?token=
 * @author Nikita Snetkov
 */
const SetNewPassword = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const formInitialValues = {
        newPassword: ''
    };

    const onFormSubmit = async (
        data: SetNewPasswordFormInputFields,
        helpers: FormikHelpers<SetNewPasswordFormInputFields>
    ) => {
        helpers.setSubmitting(true);

        await axios
            .post('/profiles/reset', {
                password: data.newPassword,
                token
            })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Пароль успешно изменен',
                    description: 'Авторизуйтесь в системе'
                });

                navigate('/login');
            })
            .catch((error) => {
                toast({
                    status: 'error',
                    title: error.response.data.message || 'Произошла неизвестная ошибка'
                });
            });

        helpers.setSubmitting(false);
    };

    /* Checks for token availability, if there is no token - navigate to /reset-password */
    useEffect(() => {
        let navigateTimeout: null | NodeJS.Timeout = null;

        if (!token) {
            toast({
                status: 'error',
                title: 'Неверная ссылка восстановления',
                description: 'Вы будете перенаправлены через 5 секунд'
            });

            navigateTimeout = setTimeout(() => {
                navigate('/reset-password');
            }, 5000);
        }

        return () => {
            if (navigateTimeout) clearTimeout(navigateTimeout);
        };
    }, [token]);

    return (
        <>
            <AuthHeading>Установка нового пароля</AuthHeading>
            <AuthDescription>
                <li> Мин. длинна - 8 символов. </li>
                <li> 1 Спец. символ. </li>
                <li> 1 Заглавный символ. </li>
                <li> 1 Цифра. </li>
            </AuthDescription>
            <Formik initialValues={formInitialValues} onSubmit={onFormSubmit}>
                {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <FormInputField
                                label="Новый пароль"
                                placeholder="Введите новый пароль"
                                name="newPassword"
                                type="password"
                                autoComplete="new-password"
                                autoFocus
                                isRequired
                                isDisabled={isSubmitting}
                                value={values.newPassword}
                                error={errors.newPassword}
                                handleChange={handleChange}
                            />
                            <AuthSubmitButton
                                isLoading={isSubmitting}
                                isDisabled={isSubmitting || !values.newPassword || !!errors.newPassword}
                            >
                                Установить новый пароль
                            </AuthSubmitButton>
                            <Box display="flex">
                                Вспомнили пароль?&nbsp;
                                <Link to="/login">Войти</Link>
                            </Box>
                            {/* <AuthRegistrationLink /> */}
                        </Stack>
                    </Form>
                )}
            </Formik>
            {/* <AuthInfoDocuments /> */}
        </>
    );
};

export default SetNewPassword;
