import useSWR from 'swr';
import axios from 'axios';
import _ from 'lodash';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';
import { equipBalancesService } from 'api/services';
import { userSelector } from 'redux/reducers/userSlice';
import { useTable, useSortBy, usePagination, Column, useAsyncDebounce } from 'react-table';
import {
    Text,
    useToast,
    Box,
    TableContainer,
    Spinner,
    Stack,
    Heading,
    Flex,
    Spacer,
    Button as ChakraButton
} from '@chakra-ui/react';
import { TablePagination, TableCustom } from '../../components/react-table';
import LoadingOverlay from '../../components/LoadingOverlay';
import { generateTitle } from '../../utils';
import { fetcher } from '../../utils/client';
import 'moment/locale/ru';
import { REQUEST_STATUSES } from '../../constants';
import BalanceTableToolbar from './BalanceTableToolbar';

const GetBalance = () => {
    // const { token } = useSelector(userSelector);
    const [isLoading, setIsLoading] = useState(true);
    // const [equipBalances, setBalances] = useState([]);
    const [equipBalances, setEquipBalances] = useState([]);
    const [totalEquipBalances, setTotalEquipBalances] = useState(0);
    useTitle(generateTitle('Отчет по отделам'));
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const { data: userData } = useSelector(userSelector);
    const subc = userData?.subc;
    const [filterSiteId, setFilterSiteId] = useState('');
    const [filterEquipId, setFilterEquipId] = useState('');
    const { data: onecRequest } = useSWR(
        subc ? `/equip-balances/lastOnecRequest/${subc?.id}` : null,
        fetcher,
        {
            refreshInterval: 10000
        }
    );

    const [_pageCount, setPageCount] = useState(0);

    const data = useMemo(() => equipBalances, [equipBalances]);

    const getColumns = () => {
        const columns = [
            {
                accessor: 'site_title',
                Header: 'БТС'
            },
            {
                accessor: 'equip_title',
                Header: 'Оборудование'
            },
            {
                accessor: 'equip_product_code',
                Header: 'Продукт код'
            },
            {
                accessor: 'quantity',
                Header: 'Кол-во'
            }
        ];
        return columns;
    };
    const columns = useMemo(() => getColumns(), []) as Column[];
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, sortBy: [{ id: 'updated_at', desc: true }] },
            stateReducer(newState, action) {
                if (action.type === 'toggleSortBy') {
                    return { ...newState, pageIndex: 0 };
                }

                return newState;
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount: _pageCount,
            autoResetPage: false,
            autoResetSortBy: false
        },
        useSortBy,
        usePagination
    );

    useHeaderTitle(`Просмотр остатка ГПО`);

    // useCheckToken(token, getBalancesInfo);

    const [isLoadingUpdateBalance, setIsLoadingUpdateBalance] = useState(false);

    const [isLoadingExportBalance, setIsLoadingExportBalance] = useState(false);

    const handleExportBalance = async () => {
        setIsLoadingExportBalance(true);

        await axios
            .post('/equip-balances/uploadInExcel', { id: onecRequest?.id })
            .then((res) => {
                window.open(`${process.env.REACT_APP_API_BASE_URL}${res.data.path}`);
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: 'Не удалось получить отчет'
                });
                console.error(err);
            })
            .finally(() => {
                setIsLoadingExportBalance(false);
            });
    };

    const getEquipBalances = ({
        _limit,
        _start,
        _sort,
        prod_id_contains,
        site_id_contains,
        subc,
        onecRequest
    }: any) => {
        const params: any = {
            _start,
            _limit,
            _sort,
            equip_product_code_contains: prod_id_contains,
            site_title_contains: site_id_contains
        };
        if (subc) {
            params.subc = subc?.id;
        }
        if (onecRequest) {
            params.onec_request = onecRequest?.id;
        }
        // Removing empty values from params
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

        return equipBalancesService.list({ params: finalParams });
    };

    const getTotalEquipBalances = ({ prod_id_contains, site_id_contains, subc, onecRequest }: any) => {
        const params: any = {
            equip_product_code_contains: prod_id_contains,
            site_title_contains: site_id_contains
        };
        if (subc) {
            params.subc = subc?.id;
        }
        if (onecRequest) {
            params.onec_request = onecRequest?.id;
        }
        // Removing empty values from params
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

        return equipBalancesService.count({ params: finalParams });
    };

    const fetchAPIData = ({
        _limit,
        _start,
        _sort,
        pageSize,
        prod_id_contains,
        site_id_contains,
        subc,
        onecRequest
    }: any) => {
        setIsLoading(true);
        Promise.all([
            getEquipBalances({
                _limit,
                _start,
                _sort,
                prod_id_contains,
                site_id_contains,
                subc,
                onecRequest
            }),
            getTotalEquipBalances({
                prod_id_contains,
                site_id_contains,
                subc,
                onecRequest
            })
        ])
            .then((responses) => {
                setEquipBalances(responses[0].data);
                setTotalEquipBalances(responses[1].data);
                setPageCount(Math.ceil(responses[1].data / pageSize));
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                toast({
                    status: 'error',
                    title: `Не удалось получить остаток`
                });
                setIsLoading(false);
            });
    };

    const fetchData = useCallback(
        ({ pageSize, pageIndex, sortBy, filterEquipId, filterSiteId, subc, onecRequest }) => {
            fetchAPIData({
                _limit: pageSize,
                _start: pageSize * pageIndex,
                _sort: sortBy.length ? `${sortBy[0].id}:${sortBy[0].desc ? 'DESC' : 'ASC'}` : null,
                pageSize,
                prod_id_contains: filterEquipId,
                site_id_contains: filterSiteId,
                subc,
                onecRequest
            });
        },
        []
    );

    useEffect(() => {
        fetchData({
            pageIndex,
            pageSize,
            sortBy,
            filterEquipId,
            filterSiteId,
            subc,
            onecRequest
        });
    }, [fetchData, pageIndex, pageSize, sortBy, filterEquipId, filterSiteId, subc, onecRequest]);

    const updateData = () => {
        fetchData({
            pageIndex,
            pageSize,
            sortBy,
            filterEquipId,
            filterSiteId,
            subc,
            onecRequest
        });
    };

    const handleUpdateBalance = () => {
        setIsLoadingUpdateBalance(true);
        axios
            .post('/equip-balances/updateBalanceByCounterparty', { counterparty: subc })
            .then(() => {
                updateData();
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: 'Не удалось получить отчет'
                });
                console.error(err);
            })
            .finally(() => {
                setIsLoadingUpdateBalance(false);
                onecRequest.status = REQUEST_STATUSES.INPROCESS;
            });
    };

    const handleFilterSiteId = useAsyncDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterSiteId(event.target.value);
        gotoPage(0);
    }, 500);

    const handleFilterEquipId = useAsyncDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterEquipId(event.target.value);
        gotoPage(0);
    }, 500);

    return (
        <LoadingOverlay Spinner={<Spinner color="blue.500" />} active={isLoading}>
            <Box>
                <Box shadow="md" borderWidth="1px" p={3} bg="white" rounded="md">
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Heading as="h1" fontSize="md">
                            Остатки
                        </Heading>
                    </Stack>
                    <Flex>
                        <Box display="flex" alignItems="center">
                            <Text fontSize="16px" fontWeight={600}>
                                {onecRequest?.status === REQUEST_STATUSES.INPROCESS ? (
                                    `Статус остатка - в процессе загрузки. Данные от ${new Date(
                                        onecRequest?.date
                                    ).toLocaleString('ru-Ru')}`
                                ) : onecRequest?.status === REQUEST_STATUSES.COMPLETED ? (
                                    `Статус остатка - успешно обновлено. Данные от ${new Date(
                                        onecRequest?.date
                                    ).toLocaleString('ru-Ru')}`
                                ) : onecRequest?.status === REQUEST_STATUSES.FAILED ? (
                                    <Text fontSize="16px" fontWeight={600} color="tomato">
                                        {`Статус остатка - ошибка . Данные от ${new Date(
                                            onecRequest?.date
                                        ).toLocaleString('ru-Ru')}`}
                                    </Text>
                                ) : (
                                    'Статус остатка - не загружено'
                                )}
                            </Text>
                        </Box>
                        <Spacer />
                        <Box display="flex" alignItems="center">
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <ChakraButton
                                    size="sm"
                                    colorScheme="purple"
                                    ml="auto"
                                    onClick={handleUpdateBalance}
                                    isLoading={isLoadingUpdateBalance}
                                    isDisabled={onecRequest?.forbidden}
                                >
                                    {onecRequest?.forbidden
                                        ? 'Обновить остатки - временно недоступно'
                                        : 'Обновить остатки'}
                                </ChakraButton>
                                {onecRequest?.id ? (
                                    <ChakraButton
                                        size="sm"
                                        colorScheme="purple"
                                        ml="10px"
                                        onClick={handleExportBalance}
                                        isLoading={isLoadingExportBalance}
                                    >
                                        Скачать EXCEL
                                    </ChakraButton>
                                ) : (
                                    ''
                                )}
                            </Stack>
                        </Box>
                    </Flex>
                </Box>
                <Box shadow="md" borderWidth="1px" p={3} bg="white" mt={2} rounded="md">
                    <Flex h="100%" direction="column">
                        <BalanceTableToolbar
                            filterSiteId={filterSiteId}
                            filterEquipId={filterEquipId}
                            onFilterSiteId={handleFilterSiteId}
                            onFilterEquipId={handleFilterEquipId}
                            totalEquipBalances={totalEquipBalances}
                        />

                        <TableContainer sx={{ flexGrow: 1, overflowY: 'auto', height: '100%', padding: 1.5 }}>
                            <TableCustom
                                size="sm"
                                getTableProps={getTableProps}
                                headerGroups={headerGroups}
                                getTableBodyProps={getTableBodyProps}
                                page={page}
                                prepareRow={prepareRow}
                            />
                        </TableContainer>

                        <TablePagination
                            gotoPage={gotoPage}
                            canPreviousPage={canPreviousPage}
                            previousPage={previousPage}
                            pageIndex={pageIndex}
                            pageCount={pageCount}
                            pageSizeOptions={[5, 10, 25]}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            nextPage={nextPage}
                            canNextPage={canNextPage}
                        />
                    </Flex>
                </Box>
            </Box>
        </LoadingOverlay>
    );
};
export default GetBalance;
