import axios from 'axios';
import qs from 'qs';

const list = ({ params, cancelToken }: any) =>
    axios.get(`/sites`, { params, paramsSerializer: (params) => qs.stringify(params), cancelToken });

const getById = (id: number | string, { params, cancelToken }: any) =>
    axios.get(`/sites/${id}`, { params, paramsSerializer: (params) => qs.stringify(params), cancelToken });

const detail = (id: number | string) => axios.get(`sites/${id}`);

const count = ({ params, cancelToken }: any) =>
    axios.get(`/sites/count`, { params, paramsSerializer: (params) => qs.stringify(params), cancelToken });

const exportData = (data: any) => axios.post(`sites/exportInExcel`, data);

const upload = (data: any) => axios.post('sites/upload', data);

const startProcess = (data: any) => axios.post('sites/startProcess', data);

const resetProcess = (data: any) => axios.post('sites/resetProcess', data);

const startTssr = (id: number) =>
    axios.post('/process-instances/tssr', {
        type: 'site',
        id
    });

const update = (id: number, data: any) => axios.patch(`sites/${id}`, data);

export default {
    list,
    detail,
    count,
    exportData,
    upload,
    startTssr,
    startProcess,
    update,
    resetProcess,
    getById
};
