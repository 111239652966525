import {
    Button,
    ButtonGroup,
    Flex,
    FormControl,
    Heading,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast
} from '@chakra-ui/react';
import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { matListsService } from 'api/services';
import {
    CheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CloseIcon,
    DeleteIcon,
    EditIcon
} from '@chakra-ui/icons';
import { useFormik } from 'formik';
import HasAccess from 'guards/HasAccess';
import { IMatListEquipment, IMatListEquipmentItem } from '../../utils/interfaces/MatList';
import { ERoleNames } from '../../../../constants';

interface IProps {
    refetchMatList: () => void;
    equipments: IMatListEquipment;
    hasEditEquipmentRoles: ERoleNames[];
    hasShowIssuedQtyRoles: ERoleNames[];
    isDisabled?: boolean;
}

const initialValues = {
    qty: 0
};

const MatListEquipments: FC<IProps> = ({
    hasShowIssuedQtyRoles,
    equipments,
    hasEditEquipmentRoles,
    refetchMatList,
    isDisabled
}) => {
    const alertRef = useRef(null);
    const toast = useToast();
    const [displayedCategories, setDisplayedCategories] = useState<string[]>([]);
    const [itemToDelete, setItemToDelete] = useState<null | IMatListEquipmentItem>(null);
    const getItemTitle = (item: IMatListEquipmentItem) =>
        item.equipment?.sap_code || item.title || 'Неизвестное оборудование';
    const [itemToEdit, setItemToEdit] = useState<null | IMatListEquipmentItem>(null);

    const onDelete = () => {
        if (!itemToDelete) return;
        matListsService
            .deleteEquipmentFromList(itemToDelete.id)
            .then(() => {
                toast({
                    status: 'success',
                    title: `Оборудование ${getItemTitle(itemToDelete)} успешно удалено из заявки`
                });
                if (refetchMatList) {
                    refetchMatList();
                }
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось удалить оборудование из списка'
                });
            });
    };

    const isItemToEdit = (id: number) => itemToEdit?.id === id;

    const editItemForm = useFormik({
        initialValues,
        onSubmit: (values) => {
            if (!itemToEdit) return;
            matListsService
                .changeQty(itemToEdit.id, values.qty)
                .then(() => {
                    setItemToEdit(null);
                    toast({
                        status: 'success',
                        title: `Оборудование успешно отредактирвоано`
                    });
                    refetchMatList();
                })
                .catch((e) => {
                    toast({
                        status: 'error',
                        title: 'Ошибка',
                        description: e.response.data.message || 'Не удалось отредактировать оборудование'
                    });
                });
        }
    });

    useEffect(() => {
        if (itemToEdit === null) {
            editItemForm.setFieldValue('qty', 0);
        } else {
            editItemForm.setFieldValue('qty', itemToEdit.qty);
        }
    }, [itemToEdit]);

    const handleClickCategory = (category: string) => {
        const categoryIndex = displayedCategories.indexOf(category);
        if (categoryIndex === -1) {
            setDisplayedCategories([...displayedCategories, category]);
        } else {
            const copyDisplayedCategories = [...displayedCategories];
            copyDisplayedCategories.splice(categoryIndex, 1);
            setDisplayedCategories(copyDisplayedCategories);
        }
    };

    useEffect(() => {
        if (!equipments) return;
        setDisplayedCategories(Object.keys(equipments));
    }, [equipments]);

    let itemCounter = 0;
    return (
        <>
            <Heading as="h3" size="sm" mb={2}>
                Список оборудования
            </Heading>
            <TableContainer>
                <Table whiteSpace="wrap" variant="simple">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>сайт id</Th>
                            <Th>SAP код</Th>
                            <Th>код продукта</Th>
                            <Th>наименование</Th>
                            <Th>количество</Th>
                            <HasAccess roleNames={hasShowIssuedQtyRoles}>
                                <Th>Ожидает выдачи</Th>
                                <Th>Выдано</Th>
                            </HasAccess>
                            <HasAccess roleNames={hasEditEquipmentRoles}>
                                <Th>действия</Th>
                            </HasAccess>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.entries(equipments).map(([category, items], index) => (
                            <>
                                <Tr
                                    cursor="pointer"
                                    onClick={() => handleClickCategory(category)}
                                    key={`category-${index}`}
                                >
                                    <Td colSpan={7} style={{ fontWeight: 'bold' }}>
                                        <Flex alignItems="center">
                                            {displayedCategories.includes(category) ? (
                                                <ChevronDownIcon h={5} w={5} />
                                            ) : (
                                                <ChevronRightIcon h={5} w={5} />
                                            )}
                                            {category} ({items.length} шт.)
                                        </Flex>
                                    </Td>
                                </Tr>
                                {items.map((item, i) => {
                                    itemCounter += 1;
                                    return (
                                        displayedCategories.includes(category) && (
                                            <Tr key={`item-${itemCounter}`}>
                                                <Td>{itemCounter}</Td>
                                                <Td>{item.siteId || '-'}</Td>
                                                <Td>{getItemTitle(item)}</Td>
                                                {item.equipment ? (
                                                    <>
                                                        <Td>{item.equipment.s_product_code}</Td>
                                                        <Td>{item.equipment.s_equip_title}</Td>
                                                        <Td>
                                                            {isItemToEdit(item.id) ? (
                                                                <FormControl isRequired>
                                                                    <NumberInput
                                                                        allowMouseWheel
                                                                        size="sm"
                                                                        min={
                                                                            item.awaitingQty +
                                                                                item.issuedQty || 1
                                                                        }
                                                                        width="80px"
                                                                        name="qty"
                                                                        onChange={(value) => {
                                                                            editItemForm.setFieldValue(
                                                                                'qty',
                                                                                value
                                                                            );
                                                                        }}
                                                                        value={editItemForm.values.qty}
                                                                    >
                                                                        <NumberInputField
                                                                            placeholder=""
                                                                            sx={{
                                                                                paddingInlineStart:
                                                                                    'var(--chakra-space-1-5)',
                                                                                borderColor: 'gray.200'
                                                                            }}
                                                                        />
                                                                        <NumberInputStepper borderColor="gray.200">
                                                                            <NumberIncrementStepper
                                                                                onChange={
                                                                                    editItemForm.handleChange
                                                                                }
                                                                            />
                                                                            <NumberDecrementStepper
                                                                                onChange={
                                                                                    editItemForm.handleChange
                                                                                }
                                                                            />
                                                                        </NumberInputStepper>
                                                                    </NumberInput>
                                                                </FormControl>
                                                            ) : (
                                                                <>
                                                                    {item.qty}
                                                                    {item.equipment.s_unit?.toLowerCase() ||
                                                                        ''}
                                                                </>
                                                            )}
                                                        </Td>
                                                        <HasAccess roleNames={hasShowIssuedQtyRoles}>
                                                            <Td>
                                                                {item.awaitingQty || 0}{' '}
                                                                {item.equipment.s_unit?.toLowerCase() || ''}
                                                            </Td>
                                                            <Td>
                                                                {item.issuedQty || 0}{' '}
                                                                {item.equipment.s_unit?.toLowerCase() || ''}
                                                            </Td>
                                                        </HasAccess>
                                                        <HasAccess roleNames={hasEditEquipmentRoles}>
                                                            <Td>
                                                                {!isItemToEdit(item.id) ? (
                                                                    <ButtonGroup>
                                                                        <Button
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                setItemToEdit(item)
                                                                            }
                                                                            type="button"
                                                                            isDisabled={isDisabled}
                                                                        >
                                                                            <EditIcon />
                                                                        </Button>
                                                                        <Button
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                setItemToDelete(item)
                                                                            }
                                                                            type="button"
                                                                            isDisabled={
                                                                                item.awaitingQty > 0 ||
                                                                                isDisabled
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                ) : (
                                                                    <>
                                                                        <ButtonGroup>
                                                                            <Button
                                                                                onClick={(e) => {
                                                                                    const event =
                                                                                        e as any as FormEvent<HTMLFormElement>;
                                                                                    editItemForm.handleSubmit(
                                                                                        event
                                                                                    );
                                                                                }}
                                                                                size="sm"
                                                                                type="submit"
                                                                                isDisabled={
                                                                                    editItemForm.values.qty <=
                                                                                    0
                                                                                }
                                                                                colorScheme="green"
                                                                            >
                                                                                <CheckIcon />
                                                                            </Button>
                                                                            <Button
                                                                                size="sm"
                                                                                onClick={() =>
                                                                                    setItemToEdit(null)
                                                                                }
                                                                            >
                                                                                <CloseIcon />
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </>
                                                                )}
                                                            </Td>
                                                        </HasAccess>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Td colSpan={3} color="red">
                                                            Неподтвержденное оборудование{' '}
                                                        </Td>
                                                        <HasAccess roleNames={hasEditEquipmentRoles}>
                                                            <Td>
                                                                <ButtonGroup>
                                                                    <Button
                                                                        isDisabled
                                                                        size="sm"
                                                                        onClick={() => setItemToEdit(item)}
                                                                        type="button"
                                                                    >
                                                                        <EditIcon />
                                                                    </Button>
                                                                    <Button
                                                                        size="sm"
                                                                        onClick={() => setItemToDelete(item)}
                                                                        type="button"
                                                                    >
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </Td>
                                                        </HasAccess>
                                                    </>
                                                )}
                                            </Tr>
                                        )
                                    );
                                })}
                            </>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            {itemToDelete && (
                <ConfirmationDialog
                    modalRef={alertRef}
                    onClose={() => setItemToDelete(null)}
                    body={`Вы уверены что хотите удалить оборудование ${getItemTitle(
                        itemToDelete
                    )} из списка?`}
                    title="Удаление оборудования"
                    onAccept={onDelete}
                    acceptText="Удалить"
                    declineText="Отмена"
                    isOpen
                />
            )}
        </>
    );
};

export default MatListEquipments;
