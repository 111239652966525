import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Button
} from '@chakra-ui/react';
import { ErrorMessage as EM, FieldAttributes, Field, useField, useFormikContext } from 'formik';
import React from 'react';
import DatePicker, { DatePickerProps } from '../../components/DatePicker';

const ErrorMessage = EM as FieldAttributes<any>;

const DatePickerField = ({ ...props }: Partial<DatePickerProps>) => {
    const { setFieldValue } = useFormikContext();
    // @ts-ignore
    const [field] = useField(props);
    return (
        <DatePicker
            {...field}
            {...props}
            onChange={(val) => {
                setFieldValue(field.name, val);
            }}
        />
    );
};

const StringPickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [show, setShow] = React.useState(false);
    // @ts-ignore
    const [field] = useField(props);
    const [field_auto] = useField({ name: 'hard_copy_id_auto', disabled: false });
    const handleClick = () => {
        setShow(!show);
        if (show) {
            setFieldValue(field.name, '');
        } else {
            setFieldValue(field.name, field_auto.value);
        }
    };

    return (
        <InputGroup size="md">
            <Input
                disabled={props.disabled}
                type="text-area"
                value={show ? field_auto.value : field.value}
                onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                }}
                placeholder="Укажите номер документа поставки"
                variant="outline"
                borderColor="gray.400"
            />
            {!props.disabled ? (
                <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? 'Ручной' : 'Авто'}
                    </Button>
                </InputRightElement>
            ) : (
                ''
            )}
        </InputGroup>
    );
};

interface MainFormProps {
    disabled?: boolean;
    accessDataDeliveryDocument?: boolean;
}

const MainForm = ({ disabled, accessDataDeliveryDocument }: MainFormProps) => (
    <Box shadow="md" borderWidth="1px" my={3} p={3} bg="white" rounded="md">
        <FormControl>
            <Flex flexWrap="wrap">
                <Box flex="0 1 330px" mr={2} my={1}>
                    <FormLabel htmlFor="act_date">Дата документа поставки</FormLabel>
                    <DatePickerField name="act_date" disabled={!accessDataDeliveryDocument} />
                    <Box mt={1} color="red" fontSize="sm">
                        <ErrorMessage name="act_date" component="div" className="field-error" />
                    </Box>
                </Box>
                <Box flex="0 1 330px" mr={2} my={1}>
                    <FormLabel htmlFor="hard_copy_id">Номер документа поставки</FormLabel>
                    <StringPickerField name="hard_copy_id" disabled={!accessDataDeliveryDocument} />
                    <Box mt={1} color="red" fontSize="sm">
                        <ErrorMessage name="hard_copy_id" component="div" className="field-error" />
                    </Box>
                </Box>
                <Box flex="0 1 330px" mr={2} my={1}>
                    <FormLabel htmlFor="comments">Комментарий</FormLabel>
                    <Field
                        id="comments"
                        name="comments"
                        bg="white"
                        as={Input}
                        placeholder="Укажите комментарий"
                        variant="outline"
                        borderColor="gray.400"
                        disabled={!accessDataDeliveryDocument && disabled}
                    />
                    <Box mt={1} color="red" fontSize="sm">
                        <ErrorMessage name="comments" component="div" className="field-error" />
                    </Box>
                </Box>
            </Flex>
        </FormControl>
    </Box>
);

export default MainForm;
