import {
    Stack,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Heading,
    useToast,
    Button
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { AxiosResponse } from 'axios';
import { IComment } from 'interfaces/comment';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
import CommentFormModal from 'new-components/CommentFormModal';

interface IProps {
    id: number;
    getComments: (id: number) => Promise<AxiosResponse<IComment[]>>;
    addComment?: (data: any) => void;
}

const Comments: FC<IProps> = ({ id, getComments, addComment }) => {
    const toast = useToast();
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [comments, setComments] = useState<IComment[]>([]);
    const user = useSelector(userSelector);

    const handleCommentModalOpen = () => {
        setShowCommentModal(true);
    };

    const handleCommentModalClose = () => {
        setShowCommentModal(false);
    };

    const handleSubmitComment = async (message: string) => {
        const data = {
            message,
            id
        };
        if (addComment) {
            addComment(data);
            setComments([...comments, { ...data, user: user.data, created_at: new Date() }]);
            setShowCommentModal(false);
        }
    };

    const handleGetComments = async () => {
        if (comments.length > 0) return;
        getComments(id)
            .then((res) => {
                setComments(res.data);
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось получить историю'
                });
            });
    };

    const formatDate = (date: Date) => {
        const dateConverted = new Date(date);
        const formattedDate = dateConverted.toLocaleDateString('en-GB').replace(/\//g, ':');
        const formattedTime = `${dateConverted.getHours().toString().padStart(2, '0')}:${dateConverted
            .getMinutes()
            .toString()
            .padStart(2, '0')}`;

        const finalString = `${formattedDate} в ${formattedTime}`;
        return finalString;
    };

    return (
        <Box>
            <Heading as="h3" size="sm" mb={2} mt={2}>
                Комментарии
            </Heading>
            <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton onClick={handleGetComments}>
                            <Box as="span" flex="1" textAlign="left" color="purple">
                                Посмотреть все комментарии
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {comments.map((item) => (
                            <Box border="1px solid black" p={2} borderRadius={12} mt={2}>
                                <Stack gap="unset">
                                    <Stack flexDirection="row">
                                        <Box as="p" fontWeight="bold">
                                            {item.user.full_name}:
                                        </Box>
                                        <Box as="p" fontWeight="bold">
                                            {item.user.email}
                                        </Box>
                                    </Stack>
                                    <Box as="span" fontSize={12}>
                                        {formatDate(item.created_at)}
                                    </Box>
                                    <Box mt={3} fontSize={16}>
                                        {item.message}
                                    </Box>
                                </Stack>
                            </Box>
                        ))}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            {showCommentModal && (
                <CommentFormModal
                    onClose={handleCommentModalClose}
                    onSubmit={handleSubmitComment}
                    title="Комментирование"
                />
            )}
            <Button float="right" mt={2} onClick={handleCommentModalOpen}>
                Добавить комментарий
            </Button>
        </Box>
    );
};

export default Comments;
