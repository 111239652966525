import { combineReducers } from '@reduxjs/toolkit';
import { globalReducer } from 'redux/reducers/globalSlice';
import { userReducer } from 'redux/reducers/userSlice';

const rootReducer = combineReducers({
    user: userReducer,
    global: globalReducer
});

export default rootReducer;
