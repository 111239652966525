import { Heading, Box, Link, Badge } from '@chakra-ui/react';
import useSWR from 'swr';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { useSelector } from 'react-redux';
import Table from './Table';
import { fetcher } from '../utils/client';
import { userSelector } from '../redux/reducers/userSlice';

export const ExportJobs = () => {
    const user = useSelector(userSelector);
    const { data } = useSWR(`/export-jobs?_sort=updated_at:DESC&owner=${user.data.id}&_limit=50`, fetcher);
    useHeaderTitle(`Просмотр адресного плана на экспорт`);
    const processData = (data: any[]) =>
        data.map(({ path, id, type, isCompleted, started_at, finished_at }) => ({
            id,
            type,
            isCompleted: isCompleted ? (
                <Badge variant="outline" colorScheme="green">
                    Завершен
                </Badge>
            ) : null,
            path: isCompleted ? (
                <Link href={`${process.env.REACT_APP_API_BASE_URL}${path}`} color="#8ab4f8">
                    Скачать
                </Link>
            ) : null,
            started_at: new Date(started_at).toLocaleString('Ru-ru'),
            finished_at: finished_at ? new Date(finished_at).toLocaleString('Ru-ru') : null
        }));
    return (
        <Box px={3} py={1} pb={3}>
            <Heading as="h2" fontSize="2xl" mb={3}>
                ЭАП на экспорт
            </Heading>
            <Table
                headers={['ID', 'Тип ЭАП', 'Состояние', 'Действие', 'Время начала', 'Время окончания']}
                data={data && processData(data)}
            />
        </Box>
    );
};

export default () => (
    <Box bg="white" p={2} borderRadius={10}>
        <ExportJobs />
    </Box>
);
