/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDropzone, Accept } from 'react-dropzone';
import styled from 'styled-components';
// @chakra-ui
import { Box, useTheme } from '@chakra-ui/react';
//
import BlockContent from './BlockContent';
import MultiFilePreview from './MultiFilePreview';

// ----------------------------------------------------------------------

const DropZoneStyle = styled.div`
    outline: none;
    overflow: hidden;
    position: relative;
    padding: ${(props) => props.theme.space[5]} ${(props) => props.theme.space[1]};
    border-radius: ${(props) => props.theme.radii.md};
    background-color: #f4f6f8;
    border: 1px dashed ${(props) => props.theme.colors.gray[300]};
    &:hover {
        opacity: 0.72;
        cursor: pointer;
    }
`;

// ----------------------------------------------------------------------

interface UploadMultiFileProps {
    error?: boolean;
    files: Array<File> | null;
    helperText?: JSX.Element;
    sx?: React.CSSProperties;
    accept: Accept;
    onRemove: Function;
    onDrop: (acceptedFiles: Array<File>) => void;
    maxSize: number;
}

export default function UploadMultiFile({
    error = false,
    files,
    helperText,
    sx,
    onRemove,
    ...other
}: UploadMultiFileProps) {
    const theme = useTheme();

    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        multiple: true,
        ...other
    });

    return (
        <Box width="full" sx={sx}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'red.500',
                        borderColor: 'red.400',
                        bgcolor: 'red.300'
                    })
                }}
                theme={theme}
            >
                <input {...getInputProps()} />

                <BlockContent />
            </DropZoneStyle>

            <MultiFilePreview files={files || []} onRemove={onRemove} sx={{ marginTop: 1 }} />

            {helperText && helperText}
        </Box>
    );
}
