import axios from 'axios';
import qs from 'qs';
import { IProject } from '../../interfaces/works';

const list = ({ params, cancelToken }: any) =>
    axios.get(`/works`, { params, paramsSerializer: (params) => qs.stringify(params), cancelToken });

const detail = (id: number | string) => axios.get(`works/${id}`);

const count = ({ params, cancelToken }: any) =>
    axios.get(`/works/count`, { params, paramsSerializer: (params) => qs.stringify(params), cancelToken });

const exportData = (data: any, { params }: any) =>
    axios.post(`/works/exportInExcel`, data, { params, paramsSerializer: (params) => qs.stringify(params) });

const upload = (data: any) => axios.post('/works/upload', data);

const startTssr = (id: number) =>
    axios.post('/process-instances/tssr', {
        type: 'work',
        id
    });

const startSarProcess = (data: any) => axios.post('/works/startProcess', data);
const resetProcess = (data: any) => axios.post('/works/resetProcess', data);

const startAfu = (workId: number) =>
    axios.post('/process-instances/afu', {
        id: workId
    });

const getTypes = () => axios.get<{ work_type: string[] }>('/works/dicrinary');
const getProjects = () => axios.get<IProject[]>(`/projects`);

export default {
    list,
    detail,
    count,
    exportData,
    upload,
    startTssr,
    startAfu,
    getProjects,
    startSarProcess,
    getTypes,
    resetProcess
};
