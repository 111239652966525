import {
    Box,
    Button,
    Fade,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Stack,
    useToast
} from '@chakra-ui/react';
import { Select } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const formLabelStyle = {
    fontSize: '1rem',
    color: '#1A202C',
    marginBottom: '0.5rem'
};

const initialValues = {
    name: '',
    fuel: '',
    volume: '',
    fuelingDate: new Date(),
    fuel_card: {
        card_number: '',
        id: 0
    }
};

const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Поле обязательное'),
    fuel: Yup.string().required('Поле обязательное'),
    volume: Yup.string().required('Поле обязательное'),
    fuelingDate: Yup.date(),
    fuel_card: Yup.object().required('Поле обязательное')
});

export default function BGUGSMEdit() {
    const { id } = useParams();
    const [fuelCards, setFuelCards] = useState([]);

    const [user, setUser] = useState<any>(null);

    const [oldVolume, setOldVolume] = useState<Number>(0);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            if (values.fuel === 'Канистра' && oldVolume !== Number(values.volume)) {
                const reminders = (await axios.get(`/remainders?_where[user.id_eq]=${user.id}`)).data[0];
                if (!reminders) {
                    toast({
                        status: 'error',
                        title: 'У вас не зарегестрирована канистра'
                    });
                    return;
                }

                if (Number(oldVolume) > Number(values.volume)) {
                    await axios.put(`/remainders/${reminders.id}`, {
                        gas: Number(reminders.gas) + (Number(oldVolume) - Number(values.volume))
                    });

                    setOldVolume(Number(values.volume));
                }

                if (Number(oldVolume) < Number(values.volume)) {
                    if (Number(reminders.gas) - (Number(values.volume) - Number(oldVolume)) < 0) {
                        toast({
                            status: 'error',
                            title: 'У вас недостаточно топлива в ваших остатках'
                        });
                        return;
                    }
                    await axios.put(`/remainders/${reminders.id}`, {
                        gas: Number(reminders.gas) - (Number(values.volume) - Number(oldVolume))
                    });
                    setOldVolume(Number(values.volume));
                }
            }

            const updateData = {
                name: values.name,
                fuel: values.fuel,
                volume: values.volume,
                fuelingDate: values.fuelingDate,
                fuel_card: values.fuel_card
            };
            axios
                .put(`/gas-reports/${id}`, updateData)
                .then(() => {
                    toast({
                        status: 'success',
                        title: `Успешно обновлено`
                    });
                    setSubmitting(false);
                })
                .catch((error) => {
                    toast({
                        status: 'error',
                        title: 'При обновлении произошла ошибка'
                    });
                    console.error(error);
                    setSubmitting(false);
                });
        },
        validationSchema: ValidationSchema
    });

    const getData = async () => {
        // TODO починить findOne
        const { data } = await axios.get(`/gas-reports?id=${id}`);

        const tmp = data.docs[0];

        const tmpData = {
            name: tmp.name,
            fuel: tmp.fuel,
            volume: tmp.volume,
            fuelingDate: tmp.fuelingDate,
            fuel_card: tmp.fuel_card
        };

        formik.setValues(tmpData);

        setOldVolume(Number(data.volume));
        setUser(data.user);
    };

    const getFuelCards = async () => {
        const cards = await axios.get(`/fuel-cards`);
        if (cards.status === 200) {
            setFuelCards(cards.data.docs);
        }
    };

    useEffect(() => {
        getData();
        getFuelCards();
    }, []);

    return (
        <Box bg="white" maxWidth="500px" marginX="auto" borderRadius={10} p={3}>
            <Fade in style={{ display: 'flex', width: '100%', height: '100%' }}>
                <Box width="100%" display="flex" flexDir="column">
                    <form onSubmit={formik.handleSubmit}>
                        <Heading as="h4" size="md" mb={3}>
                            Заправка БГУ
                        </Heading>
                        <Stack spacing={2} width="100%">
                            <FormControl>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.fuel_card}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Карта заправки</FormLabel>
                                    <Select
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        showSearch
                                        value={formik.values.fuel_card.card_number || undefined}
                                        onChange={(value) => {
                                            const newCard = fuelCards.find((item: any) => item.id === value);
                                            formik.setFieldValue('fuel_card', newCard);
                                        }}
                                    >
                                        {fuelCards.map((item: any, index: number) => (
                                            <Select.Option value={item.id} key={index}>
                                                {item.card_number}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.fuel_card}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.name}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Имя</FormLabel>
                                    <Input
                                        value={formik.values.name || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('name', value.target.value);
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">{formik.errors.name}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.volume}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Количество топлива</FormLabel>
                                    <Input
                                        value={formik.values.volume || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('volume', value.target.value);
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">{formik.errors.volume}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.fuelingDate}
                                    </FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Дата заправки</FormLabel>
                                    <Input
                                        type="datetime-local"
                                        value={moment(formik.values.fuelingDate).format('YYYY-MM-DDThh:mm')}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'fuelingDate',
                                                moment(e.target.value).toDate()
                                            );
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.fuelingDate}
                                    </FormErrorMessage>
                                </Box>
                            </FormControl>
                        </Stack>
                        <Button
                            type="submit"
                            colorScheme="purple"
                            width="100%"
                            mt="1rem"
                            isLoading={formik.isSubmitting}
                            isDisabled={formik.isSubmitting}
                            loadingText="Обновляем..."
                        >
                            Сохранить
                        </Button>
                    </form>
                </Box>
            </Fade>
        </Box>
    );
}
