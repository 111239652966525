import {
    Box,
    Button,
    Fade,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Stack,
    useToast
} from '@chakra-ui/react';
import { Select } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const formLabelStyle = {
    fontSize: '1rem',
    color: '#1A202C',
    marginBottom: '0.5rem'
};

const initialValues = {
    bgu: {
        generator_brand: '',
        id: 0
    },
    siteID: '',
    bgu_start: new Date(),
    bgu_finish: new Date()
};

const ValidationSchema = Yup.object().shape({
    bgu: Yup.object().required('Поле обязательное'),
    siteID: Yup.string().required('Поле обязательное'),
    bgu_start: Yup.date(),
    bgu_finish: Yup.date()
});

export default function EditBguWorkshifts() {
    const { id } = useParams();

    const [generatos, setGenerators] = useState([]);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const formik = useFormik({
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            const updateData = {
                bgu: values.bgu.id,
                siteID: values.siteID,
                bgu_start: moment(values.bgu_start).toDate(),
                bgu_finish: moment(values.bgu_finish).toDate()
            };
            axios
                .put(`/bgu-worksheefts/${id}`, updateData)
                .then(() => {
                    toast({
                        status: 'success',
                        title: `Успешно обновлено`
                    });
                    setSubmitting(false);
                })
                .catch((error) => {
                    toast({
                        status: 'error',
                        title: 'При обновлении произошла ошибка'
                    });
                    console.error(error);
                    setSubmitting(false);
                });
        },
        validationSchema: ValidationSchema
    });

    const getData = async () => {
        const { data } = await axios.get(`/bgu-worksheefts/${id}`);

        console.log(data);

        const tmpData = {
            bgu: data.bgu,
            siteID: data.siteID,
            bgu_start: data.bgu_start,
            bgu_finish: data.bgu_finish ? data.bgu_finish : ''
        };

        formik.setValues(tmpData);
    };
    const getGenerators = async () => {
        const cars = await axios.get(`/generators?isStationary=false`);
        if (cars.status === 200) {
            setGenerators(cars.data.docs);
        }
    };

    useEffect(() => {
        getData();
        getGenerators();
    }, []);

    return (
        <Box bg="white" maxWidth="500px" marginX="auto" borderRadius={10} p={3}>
            <Fade in style={{ display: 'flex', width: '100%', height: '100%' }}>
                <Box width="100%" display="flex" flexDir="column">
                    <form onSubmit={formik.handleSubmit}>
                        <Heading as="h4" size="md" mb={3}>
                            Смена БГУ
                        </Heading>
                        <Stack spacing={2} width="100%">
                            <FormControl>
                                <FormLabel style={formLabelStyle}>Генератор</FormLabel>
                                <Select
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    showSearch
                                    value={formik.values.bgu.generator_brand || undefined}
                                    onChange={(value) => {
                                        const newBgu = generatos.find((item: any) => item.id === value);
                                        formik.setFieldValue('bgu', newBgu);
                                    }}
                                >
                                    {generatos.map((item: any, index: number) => (
                                        <Select.Option value={item.id} key={index}>
                                            {item.generator_brand}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.siteID}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Сайт</FormLabel>
                                    <Input
                                        value={formik.values.siteID || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('name', value.target.value);
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">{formik.errors.siteID}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.bgu_start}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Дата начала смены</FormLabel>
                                    <Input
                                        type="datetime-local"
                                        value={moment(formik.values.bgu_start)
                                            .zone('+0600')
                                            .format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'bgu_start',
                                                moment(e.target.value).toDate()
                                            );
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">{formik.errors.bgu_start}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.bgu_finish}
                                    </FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Дата окончания смены</FormLabel>
                                    <Input
                                        type="datetime-local"
                                        value={moment(formik.values.bgu_finish)
                                            .zone('+0600')
                                            .format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e) => {
                                            console.log(e);
                                            formik.setFieldValue(
                                                'bgu_finish',
                                                moment(e.target.value).toDate()
                                            );
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.bgu_finish}
                                    </FormErrorMessage>
                                </Box>
                            </FormControl>
                        </Stack>
                        <Button
                            type="submit"
                            colorScheme="purple"
                            width="100%"
                            mt="1rem"
                            isLoading={formik.isSubmitting}
                            isDisabled={formik.isSubmitting}
                            loadingText="Обновляем..."
                        >
                            Сохранить
                        </Button>
                    </form>
                </Box>
            </Fade>
        </Box>
    );
}
