import { FormLabel } from '@chakra-ui/react';
import React, { FC } from 'react';
import DatePicker from '../../../../components/DatePicker';

interface IProps {
    signingDate?: Date;
    onChangeSingingDate: (value: Date) => void;
    startDate?: Date;
    onChangeStartDate: (value: Date) => void;
}
const OrderAcceptanceTechActFormFields: FC<IProps> = ({
    signingDate,
    onChangeSingingDate,
    startDate,
    onChangeStartDate
}) => (
    <>
        <div>
            <FormLabel htmlFor="signingDate">Дата подписания тех.акта</FormLabel>
            <DatePicker
                name="signingDate"
                id="signingDate"
                value={signingDate}
                onChange={(value) => onChangeSingingDate(value)}
            />
        </div>
        <div>
            <FormLabel htmlFor="startDate">Дата интеграции</FormLabel>
            <DatePicker
                name="startDate"
                id="startDate"
                value={startDate}
                onChange={(value) => onChangeStartDate(value)}
            />
        </div>
    </>
);

export default OrderAcceptanceTechActFormFields;
