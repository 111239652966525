import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, usePagination, Column } from 'react-table';
import _ from 'lodash';
import {
    useToast,
    TableContainer,
    Flex,
    Spinner,
    Link as ChakraLink,
    useDisclosure,
    Badge,
    IconButton,
    Box,
    Button,
    Text
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer.types';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { useTitle } from 'react-use';
import { generateTitle, getActSignInfo } from 'utils';
import { actService } from 'api/services';
import { TableCustom, TablePagination } from 'components/react-table';
import LoadingOverlay from 'components/LoadingOverlay';
import axios from 'axios';
import ActSignModal from './ActSignModal';
import { userSelector } from '../../redux/reducers/userSlice';
import { ACT_STATUSES, ONEC_STEPS, ACT_STEPS } from '../../constants';
import ActDeclineModal from './ActDeclineModal';
import ActTableToolbar from './ActTableToolbar';

export default function ActList() {
    const userData = useSelector(userSelector);

    const subc = userData.role?.type === 'other' ? userData.data?.subc?.id : null;

    const [isLoading, setIsLoading] = useState(false);

    const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);

    const [selectedActId, setSelectedActId] = useState<number | null>(null);

    const [filterStatus, setFilterStatus] = useState('');

    const [acts, setActs] = useState([]);

    const [totalActs, setTotalActs] = useState(0);

    const [_pageCount, setPageCount] = useState(0);

    const user = useSelector((state: RootState) => state.user);

    const { isOpen, onOpen, onClose } = useDisclosure();

    useTitle(generateTitle('Акты выполненных ЭАП'));

    useHeaderTitle('Просмотр списка актов');

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const data = useMemo(() => acts, [acts]);

    const handleSign = (id: number) => {
        setSelectedGroupId(id);
        onOpen();
    };

    const handleDecline = (id: number) => {
        setSelectedActId(id);
    };

    const handleDeclineClose = () => {
        setSelectedActId(null);
    };

    const handleResend = async (actId: number) => {
        setIsLoading(true);
        await axios
            .put(`/acts/resend/${actId}`)
            .then(() => {
                // eslint-disable-next-line no-use-before-define
                updateData();
                toast({
                    title: 'Акт успешно отправлен в 1C',
                    status: 'success'
                });
                onClose();
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getColumns = () => {
        const columns = [
            {
                accessor: 'id',
                Header: 'ID акта',
                Cell: ({ cell: { value } }: any) => (
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        as={Link}
                        className="order-link"
                        to={`/acts/${value}`}
                    >
                        {value}
                    </ChakraLink>
                )
            },
            {
                accessor: 'order.id',
                Header: 'ID заказа',
                Cell: ({ cell: { value } }: any) => (
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        as={Link}
                        className="order-link"
                        to={`/orders/${value}`}
                        target="_blank"
                    >
                        {value}
                    </ChakraLink>
                )
            },
            {
                accessor: 'order.site_id',
                Header: 'ID сайта'
            },
            {
                accessor: 'hard_copy_id',
                Header: 'Бум. номер'
            },
            {
                accessor: 'status',
                Header: 'Статус',
                Cell: ({ cell: { row } }: any) => {
                    let result = <Box>{row.original.status}</Box>;
                    if (
                        row.original.onec_step === ONEC_STEPS.FAILED &&
                        row.original.step === ACT_STEPS.SIGNED
                    ) {
                        result = (
                            <Box>
                                {result}
                                <Flex alignItems="center">
                                    <Box fontWeight="bold">Ошибка при отправке в 1C</Box>:
                                    <Button
                                        aria-label="Повторить отправку в 1C"
                                        size="xs"
                                        colorScheme="red"
                                        variant="outline"
                                        ml={1}
                                        onClick={() => {
                                            handleResend(row.original.id);
                                        }}
                                    >
                                        Повторить
                                    </Button>
                                </Flex>
                            </Box>
                        );
                    } else if (
                        row.original.onec_step === ONEC_STEPS.INPROCESS &&
                        row.original.step === ACT_STEPS.SIGNED
                    ) {
                        result = (
                            <Box>
                                {result}
                                <Flex alignItems="center">
                                    <Box fontWeight="bold">В процессе отправки в 1C</Box>
                                    <Button
                                        aria-label="Отправить сейчас в 1c"
                                        size="xs"
                                        colorScheme="red"
                                        variant="outline"
                                        ml={1}
                                        onClick={() => {
                                            handleResend(row.original.id);
                                        }}
                                    >
                                        Отправить сейчас в 1c
                                    </Button>
                                </Flex>
                            </Box>
                        );
                    }
                    if (row?.original?.resultCheck?.status === 'failed') {
                        result = (
                            <Box>
                                {result}
                                <Flex alignItems="center">
                                    <Box fontWeight="bold">
                                        <Text color="red">{row?.original?.resultCheck?.message}</Text>
                                    </Box>
                                </Flex>
                            </Box>
                        );
                    }
                    const [currentRole, anotherRole] = getActSignInfo(
                        row.original.step,
                        row.original.signFiles?.esigns,
                        user?.role?.name,
                        user?.data
                    );
                    if (currentRole) {
                        result = (
                            <Box>
                                {result}
                                <Flex my={1}>
                                    <Badge colorScheme={currentRole.color} mr={1} variant="outline">
                                        {currentRole.text}
                                    </Badge>
                                    <Badge colorScheme={anotherRole.color} variant="outline">
                                        {anotherRole.text}
                                    </Badge>
                                </Flex>
                            </Box>
                        );
                    }
                    return result;
                }
            },
            {
                Header: 'Документ',
                Cell: ({ cell: { row } }: any) => {
                    if (!row.original.signedFiles?.esigns?.length) return null;

                    const { doc_link, file_name, signed_doc } = row.original.signedFiles.esigns[0];

                    return (
                        <ChakraLink
                            color="blue.600"
                            _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                            isExternal
                            href={`${process.env.REACT_APP_API_BASE_URL}${signed_doc || doc_link}`}
                        >
                            {file_name}
                        </ChakraLink>
                    );
                }
            },
            {
                Header: 'Действия',
                Cell: ({ cell: { row } }: any) => {
                    const [currentRole] = getActSignInfo(
                        row.original.step,
                        row.original.signFiles?.esigns,
                        user?.role?.name,
                        user?.data
                    );
                    if (currentRole?.showSign) {
                        return (
                            <Flex>
                                <IconButton
                                    aria-label="Подписать"
                                    size="sm"
                                    colorScheme="purple"
                                    variant="outline"
                                    icon={<CheckIcon />}
                                    mr={1}
                                    onClick={() => {
                                        handleSign(row.original.signFiles.id);
                                    }}
                                />
                                <IconButton
                                    aria-label="Отклонить"
                                    size="sm"
                                    colorScheme="red"
                                    variant="outline"
                                    icon={<CloseIcon />}
                                    onClick={() => {
                                        handleDecline(row.original.id);
                                    }}
                                />
                            </Flex>
                        );
                    }

                    return null;
                }
            },
            {
                accessor: 'updated_at',
                Header: 'Дата изменения',
                Cell: ({ cell: { value } }: any) => new Date(value).toLocaleString('ru')
            }
        ];
        if (!subc) {
            const newColumn: any = {
                accessor: 'subc_name',
                disableSortBy: true,
                Header: 'Подрядчик'
            };
            columns.splice(3, 0, newColumn);
        }
        return columns;
    };

    const columns = useMemo(() => getColumns(), []) as Column[];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        // pagination
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            data,
            columns,
            initialState: {
                pageIndex: 0,
                sortBy: [{ id: 'updated_at', desc: true }]
            },
            stateReducer(newState, action) {
                if (action.type === 'toggleSortBy') {
                    return { ...newState, pageIndex: 0 };
                }

                return newState;
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount: _pageCount,
            autoResetPage: false,
            autoResetSortBy: false
        },
        useSortBy,
        usePagination
    );

    const handleFilterStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilterStatus(event.target.value);
        gotoPage(0);
    };

    const getOrders = ({
        _limit,
        _start,
        _sort,
        id_contains,
        site_id_contains,
        status,
        assignedToMe,
        work,
        subc,
        type,
        orderType,
        totalAmount
    }: any) => {
        const params: any = {
            _start,
            _limit,
            _sort,
            id_contains,
            site_id_contains,
            status,
            assignedToMe,
            work,
            type,
            orderType,
            totalAmount
        };
        if (subc) {
            params.subc = subc;
        }
        // Removing empty values from params
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

        return actService.list({ params: finalParams });
    };

    const getTotalOrders = ({
        site_id_contains,
        id_contains,
        status,
        assignedToMe,
        work,
        subc,
        type,
        orderType,
        totalAmount
    }: any) => {
        const params: any = {
            site_id_contains,
            id_contains,
            status,
            assignedToMe,
            work,
            type,
            orderType,
            totalAmount
        };
        if (subc) {
            params.subc = subc;
        }
        // Removing empty values from params
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

        return actService.count({ params: finalParams });
    };

    const fetchAPIData = ({
        _limit,
        _start,
        _sort,
        pageSize,
        id_contains,
        site_id_contains,
        status,
        assignedToMe,
        work,
        type,
        orderType,
        totalAmount,
        subc
    }: any) => {
        setIsLoading(true);
        Promise.all([
            getOrders({
                _limit,
                _start,
                _sort,
                id_contains,
                site_id_contains,
                status,
                assignedToMe,
                work,
                subc,
                type,
                orderType,
                totalAmount
            }),
            getTotalOrders({
                id_contains,
                site_id_contains,
                status,
                assignedToMe,
                work,
                subc,
                type,
                orderType,
                totalAmount
            })
        ])
            .then((responses) => {
                setActs(responses[0].data);
                setTotalActs(responses[1].data);
                setPageCount(Math.ceil(responses[1].data / pageSize));
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                toast({
                    status: 'error',
                    title: `Не удалось получить список актов`
                });
                setIsLoading(false);
            });
    };

    const fetchData = useCallback(
        ({
            pageSize,
            pageIndex,
            sortBy,
            filterSiteId,
            filterActId,
            filterStatus,
            filterAssign,
            filterType,
            filterOrderType,
            filterTotalAmount,
            workId,
            subc
        }) => {
            fetchAPIData({
                _limit: pageSize,
                _start: pageSize * pageIndex,
                _sort: sortBy.length ? `${sortBy[0].id}:${sortBy[0].desc ? 'DESC' : 'ASC'}` : null,
                id_contains: filterActId,
                site_id_contains: filterSiteId,
                status: filterStatus,
                assignedToMe: filterAssign,
                type: filterType,
                orderType: filterOrderType,
                totalAmount: filterTotalAmount,
                work: workId,
                pageSize,
                subc
            });
        },
        []
    );

    useEffect(() => {
        fetchData({
            pageIndex,
            pageSize,
            sortBy,
            filterStatus,
            subc
        });
    }, [fetchData, pageIndex, pageSize, sortBy, filterStatus, subc]);

    const updateData = () => {
        fetchData({
            pageIndex,
            pageSize,
            sortBy,
            filterStatus,
            subc
        });
    };

    return (
        <LoadingOverlay
            Spinner={<Spinner color="blue.500" />}
            active={isLoading}
            styles={{
                wrapper: (base) => ({
                    ...base,
                    overflow: 'auto',
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column'
                })
            }}
        >
            <Flex h="100%" direction="column" fontSize="initial">
                <ActTableToolbar
                    filterStatus={filterStatus}
                    onFilterStatus={handleFilterStatus}
                    totalActs={totalActs}
                    optionsStatus={Object.values(ACT_STATUSES)}
                />

                <TableContainer
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        height: '100%',
                        padding: 1.5
                    }}
                >
                    <TableCustom
                        size="sm"
                        getTableProps={getTableProps}
                        headerGroups={headerGroups}
                        getTableBodyProps={getTableBodyProps}
                        page={page}
                        prepareRow={prepareRow}
                    />
                </TableContainer>

                <TablePagination
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    pageCount={pageCount}
                    previousPage={previousPage}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pageSizeOptions={[5, 10, 25]}
                    setPageSize={setPageSize}
                />
                <ActSignModal
                    onClose={onClose}
                    isOpen={isOpen}
                    groupId={selectedGroupId}
                    updateData={updateData}
                />
                <ActDeclineModal
                    onClose={handleDeclineClose}
                    updateData={updateData}
                    isOpen={!!selectedActId}
                    actId={selectedActId}
                />
            </Flex>
        </LoadingOverlay>
    );
}
