import { useState, useEffect } from 'react';
import axios from 'axios';
import downloadFile from 'utils/downloadFile';

const useGetExportJobData = (jobId: string | null, exportFileName?: string) => {
    const [jobData, setJobData] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!jobId) return;

        let isMounted = true;
        let timeoutId: NodeJS.Timeout;
        const maxDuration = 120000;
        const pollInterval = 3000;
        const fetchJobData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/export-jobs/${jobId}`);
                if (isMounted) {
                    setJobData(response.data);
                    if (response.data.isCompleted) {
                        clearTimeout(timeoutId);
                        setLoading(false);
                        if (response.data.path) {
                            downloadFile(
                                `${process.env.REACT_APP_API_BASE_URL}${response.data.path}`,
                                exportFileName
                            );
                        }
                    }
                }
            } catch (error) {
                if (isMounted) {
                    setError('Failed to fetch job data');
                    setLoading(false);
                }
            }
        };

        const pollJobData = () => {
            if (isMounted) {
                fetchJobData();
                timeoutId = setTimeout(pollJobData, pollInterval);
            }
        };

        pollJobData();

        const timerId = setTimeout(() => {
            if (isMounted) {
                clearTimeout(timeoutId);
                setLoading(false);
                setError('Request timed out');
            }
        }, maxDuration);

        // eslint-disable-next-line consistent-return
        return () => {
            isMounted = false;
            clearTimeout(timeoutId);
            clearTimeout(timerId);
        };
    }, [jobId]);

    return { jobData, loading, error };
};

export default useGetExportJobData;
