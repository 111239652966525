import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import includes from 'lodash/includes';
// antd
import { Button, Collapse, Table, Input, Switch, Row, Select } from 'antd';
import { ColumnsType } from 'antd/es/table';
// hooks
import useDebounce from 'hooks/useDebounce';
import { useToast } from '@chakra-ui/react';
// redux
import { userSelector } from 'redux/reducers/userSlice';
import { useSelector } from 'react-redux';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined
} from '@ant-design/icons';
// import HelpIcon from '@material-ui/icons/Help';
//

export default function WorkScans({ scans, onUpdateScans, onSave, work }: any) {
    const { role } = useSelector(userSelector);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const isEmployee = work.subc.id === 1108;

    const isWorkCompleted = work.scans_status !== null && work.scans_status !== -1;

    const showSaveBtn = includes(
        ['Отдел эксплуатации', 'Отдел строительства', 'Подрядная организация', 'Специалист по проектам'],
        role?.name
    );

    const disableSaveBtn =
        (role?.name === 'Отдел эксплуатации' && isWorkCompleted) ||
        (role?.name === 'Отдел строительства' && isWorkCompleted) ||
        (role?.name === 'Специалист по проектам' && work.scans_os_approve && work.scans_oe_approve) ||
        (role?.name === 'Подрядная организация' && isWorkCompleted);

    const columnAccess = {
        isNotValid: {
            show: includes(
                [
                    'Отдел эксплуатации',
                    'Отдел строительства',
                    'Отдел логистики',
                    'Подрядная организация',
                    'ADMIN',
                    'Специалист по проектам'
                ],
                role?.name
            ),
            edit:
                (role?.name === 'Отдел эксплуатации' && !isWorkCompleted) ||
                (role?.name === 'Отдел строительства' && !isWorkCompleted) ||
                (role?.name === 'Специалист по проектам' &&
                    (!work.scans_os_approve || !work.scans_oe_approve)) ||
                (role?.name === 'Подрядная организация' && !isWorkCompleted)
        },
        comment: {
            show: includes(
                [
                    'Отдел эксплуатации',
                    'Отдел строительства',
                    'Отдел логистики',
                    'Подрядная организация',
                    'ADMIN',
                    'Специалист по проектам'
                ],
                role?.name
            ),
            edit:
                (isEmployee && role?.name === 'Отдел эксплуатации' && !isWorkCompleted) ||
                (role?.name === 'Подрядная организация' && !isWorkCompleted)
        },
        transfer: {
            show: includes(
                [
                    'Отдел эксплуатации',
                    'Отдел строительства',
                    'Отдел логистики',
                    'Подрядная организация',
                    'ADMIN',
                    'Специалист по проектам'
                ],
                role?.name
            ),
            edit:
                (isEmployee && role?.name === 'Отдел эксплуатации' && !isWorkCompleted) ||
                (role?.name === 'Подрядная организация' && !isWorkCompleted)
        },
        onecComment: {
            show: includes(
                [
                    'Отдел эксплуатации',
                    'Отдел строительства',
                    'Отдел логистики',
                    'Подрядная организация',
                    'ADMIN',
                    'Специалист по проектам'
                ],
                role?.name
            ),
            edit: false
        }
    };

    const getColumns = (scanPos: number) => {
        const columns: ColumnsType<any> = [
            {
                title: 'Результат сверки',
                dataIndex: 'errors',
                key: 'errors',
                align: 'center',
                render: (field: any, record: any, index: number) => (
                    <>
                        {scans[scanPos]?.data[index]?.is_not_valid ? (
                            <MinusCircleOutlined style={{ color: 'gray' }} />
                        ) : field == null ||
                          field?.length === 0 ||
                          (scans[scanPos].data[index].comment &&
                              scans[scanPos].data[index].main_category !== 'DIR_CONSUMABLES' &&
                              scans[scanPos].data[index].main_category !== 'Consumables' &&
                              field.length) ? (
                            <CheckCircleOutlined style={{ color: 'green' }} />
                        ) : field?.length > 0 &&
                          field?.filter((error: any) => error.status === 'Отказ')?.length === 0 ? (
                            <ExclamationCircleOutlined style={{ color: 'orange' }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: 'red' }} />
                        )}
                    </>
                ),
                width: 130
            },
            {
                title: 'ID сайта',
                dataIndex: 'sid',
                key: 'sid',
                width: 90
            },
            {
                title: 'Наименование',
                dataIndex: 'title',
                key: 'title',
                width: 200
            },
            {
                title: 'Серийный номер',
                dataIndex: 'serial',
                key: 'serial',
                width: 150
            },
            {
                title: 'Ед.изм.',
                dataIndex: 'unit',
                key: 'unit',
                width: 60
            },
            {
                title: 'Кол-во',
                dataIndex: 'count',
                key: 'count',
                width: 60
            },
            {
                title: 'Дата',
                dataIndex: 'datecreate',
                key: 'datecreate',
                render: (text: string) => (
                    <span>{moment(new Date(text)).add(6, 'hours').format('DD.MM.YYYY, HH:mm:ss')}</span>
                ),
                width: 155
            }
        ];

        if (columnAccess.isNotValid.show) {
            const column = {
                title: 'Ошибочное',
                dataIndex: 'is_not_valid',
                key: 'is_not_valid',
                render: (field: any, record: any, index: number) =>
                    columnAccess.isNotValid.edit ? (
                        <Switch
                            checked={scans[scanPos].data[index].is_not_valid}
                            onChange={(value) => {
                                const updated = [...scans];
                                updated[scanPos].data[index].is_not_valid = value;
                                onUpdateScans(updated);
                            }}
                        />
                    ) : field ? (
                        'Да'
                    ) : (
                        'Нет'
                    ),
                width: 95
            };

            columns.push(column);
        }
        if (columnAccess.isNotValid.show) {
            const column = {
                title: 'Код продукта',
                dataIndex: 'prodcode',
                key: 'prodcode',
                render: (field: any, record: any, index: number) =>
                    columnAccess.isNotValid.edit ? (
                        <>
                            <Select
                                style={{ width: '100%' }}
                                value={scans[scanPos].data[index]?.prodcode}
                                onChange={(value) => {
                                    const updated = [...scans];
                                    updated[scanPos].data[index].prodcode = value;
                                    onUpdateScans(updated);
                                }}
                            >
                                {scans[scanPos].data[index].replacement?.map((replacements: any) => (
                                    <Select.Option value={replacements}>{replacements}</Select.Option>
                                ))}
                            </Select>
                        </>
                    ) : (
                        field
                    ),
                width: 200
            };
            columns.push(column);
        }

        if (columnAccess.comment.show) {
            const column = {
                title: 'Комментарий',
                dataIndex: 'comment',
                key: 'comment',
                render: (field: any, record: any, index: number) =>
                    columnAccess.comment.edit ? (
                        <Input
                            disabled={
                                role?.name === 'Отдел эксплуатации' &&
                                !includes([1, 2, 3], work.s_bs_002_main_status)
                            }
                            value={scans[scanPos].data[index]?.comment || ''}
                            onChange={(e) => {
                                const updated = [...scans];
                                updated[scanPos].data[index].comment = e.target.value;
                                onUpdateScans(updated);
                            }}
                        />
                    ) : (
                        field
                    ),
                width: 170
            };

            columns.push(column);
        }

        if (columnAccess.transfer.show) {
            const column = {
                title: 'Перенос с',
                dataIndex: 'transfer',
                key: 'transfer',
                render: (field: any, record: any, index: number) =>
                    columnAccess.transfer.edit ? (
                        // eslint-disable-next-line no-use-before-define
                        <TransferInput
                            status={scans[scanPos].data[index].hasError ? 'error' : ''}
                            placeholder="ID сайта"
                            value={scans[scanPos].data[index].transfer || ''}
                            onChange={(data: object) => {
                                const updated = [...scans];
                                updated[scanPos].data[index] = {
                                    ...updated[scanPos].data[index],
                                    ...data
                                };
                                onUpdateScans(updated);
                            }}
                        />
                    ) : (
                        field
                    ),
                width: 120
            };

            columns.push(column);
        }

        if (columnAccess.onecComment.show) {
            const column = {
                title: 'Комментарий (1С)',
                dataIndex: 'onecComment',
                key: 'onecComment',
                width: 170
            };

            columns.push(column);
        }
        const column = {
            title: 'Ошибки при сверке',
            dataIndex: 'errors',
            key: 'errors',
            render: (field: any) =>
                field && field.length > 0 ? (
                    <ul>
                        {field.map((error: any, index: number) => (
                            <li style={{ color: error.status === 'Отказ' ? 'red' : '' }} key={index}>
                                {error.description}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <span>Ошибок не найдено</span>
                ),
            width: 170
        };

        columns.push(column);

        /* const descriptionStatus = (statusText: any) => {
            switch (statusText) {
                case 'Ошибка - БТС не найдена!':
                    return 'ID сайта в системе 1С не найдена! Необходимо обратиться к ведущему инженеру, чтобы данный БТС добавить в систему 1С';
                case 'Ошибка - Номенклатура не найдена!':
                    return 'Оборудование по продукт коду не удалось найти в 1С! Необходимо обратиться в службу логистики';
                case 'Ошибка - Серийный номер не найден!':
                    return 'Серийный номер не удалось найти в 1С! Необходимо обратиться в службу логистики';
                case 'Внимание - Серия должна быть пустая!':
                    return 'Удалите серийный номер';
                case 'Ошибка - Монтаж. Нет в U2000!':
                    return 'Оборудование отсутствует в технических системах (U2000/SOEM), обратитесь в техническую дирекцию';
                case 'Ошибка - Демонтаж. Есть в U2000!':
                    return 'Оборудование присутствует в технических системах (U2000/SOEM), обратитесь в техническую дирекцию';
                case 'Внимание - Основное средство не обнаружено!':
                    return 'Демонтаж невозвожен т.к. оборудование не удалось идентифицировать как основное средство!';
                case 'Внимание - Состояние ОС не допускает монтаж':
                    return 'Оборудование имеет статус не допускающий монтаж. Монтаж возможен только с оборудованием у которых состояние принято к учету(метод начисления)';
                case 'Внимание - Состояние ОС не допускает демонтаж':
                    return 'Демонтаж возможен только с оборудованием у которых состояние принято к учету фактом';
                case 'Успех':
                    return 'Успех';
                default:
                    return 'Описание не найдено!';
            }
        }; */

        /* const column = {
            title: 'Статус U2000',
            dataIndex: 'statusU2000',
            key: 'statusU2000',
            render: (field: any) =>
                field ? (
                    <HStack>
                        <Center>
                            <Box>{field}</Box>
                        </Center>
                        <Tooltip label={descriptionStatus(field)} hasArrow>
                            <Center>
                                <Icon as={FaQuestionCircle} width="16px" height="16px" />
                            </Center>
                            {/* <Box display="flex" mt="3px" mr={3} cursor="pointer" onClick={() => 'navigate'}>
                            {field}
                            <Icon as={FaQuestionCircle} width="16px" height="16px" />
                        </Box> }
                        </Tooltip>
                    </HStack>
                ) : (
                    field
                ),
            width: 300
        }; */

        return columns;
    };

    const handleSave = (index: number) => {
        const countErr = scans[index].data.filter((item: any) => item.hasError === true).length;

        if (countErr > 0) {
            toast({
                status: 'error',
                title: 'Вы ввели неверный ID сайта!'
            });
            return;
        }

        const data = {
            ...scans[index],
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            data: scans[index].data.map(({ hasError, ...rest }: any) => ({
                ...rest
            }))
        };

        onSave(data);
    };

    return (
        <Collapse accordion defaultActiveKey="0">
            {scans.map((scan: any, index: number) => (
                <Collapse.Panel header={scan.type === 6 ? 'Монтаж' : 'Демонтаж'} key={scan.id}>
                    <Table
                        tableLayout="fixed"
                        size="small"
                        pagination={false}
                        bordered
                        dataSource={scan.data}
                        columns={getColumns(index)}
                        scroll={{ x: '100%' }}
                    />
                    <Row justify="end" style={{ marginTop: '10px' }}>
                        {showSaveBtn && (
                            <Button disabled={disableSaveBtn} onClick={() => handleSave(index)}>
                                Сохранить изменения
                            </Button>
                        )}
                    </Row>
                </Collapse.Panel>
            ))}
        </Collapse>
    );
}

// ----------------------------------------------------------------------

function TransferInput({ onChange, ...props }: any) {
    const [value, setValue] = useState(props.value);

    const debouncedValue = useDebounce(value, 500);

    useEffect(() => {
        if (!debouncedValue) {
            onChange({ hasError: false });
            return;
        }

        if (debouncedValue.toLowerCase() === 'техническая дирекция') {
            onChange({ hasError: false });
            return;
        }

        axios
            .post('/sites/check', { site: debouncedValue })
            .then(() => {
                onChange({ hasError: false });
            })
            .catch(() => {
                onChange({ hasError: true });
            });
    }, [debouncedValue]);

    return (
        <Input
            {...props}
            onChange={(e) => {
                setValue(e.target.value);
                onChange({ transfer: e.target.value, hasError: true });
            }}
        />
    );
}
