import { useTitle } from 'react-use';
import { generateTitle } from 'utils';
import axios from 'axios';
import { useToast, Select, Stack, Checkbox, Link, Box } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import FormInputField from 'new-components/FormInputField/FormInputField';
import AuthSubmitButton from 'parts/Auth/components/AuthSubmitButton/AuthSubmitButton';
import { useNavigate } from 'react-router-dom';
import { ExternalLinkIcon } from '@chakra-ui/icons';

interface RequestAccountFormInputFields {
    userName: string;
    userSurname: string;
    userPatronymic: string;
    userPhoneNumber: string;
    userMail: string;
    userSubc: string;
    userRole: string;
    isUserAgreementChecked: boolean;
}

const RequestAccount = () => {
    const formInitialValues: RequestAccountFormInputFields = {
        userName: '',
        userSurname: '',
        userPatronymic: '',
        userPhoneNumber: '',
        userMail: '',
        userSubc: '',
        userRole: '',
        isUserAgreementChecked: true
    };

    const Roles = [
        'SITEACQUIS',
        'SUPPLY',
        'Группа лицензии и разрешительных документов',
        'Группа проектирования инфраструктуры сети',
        'Отдел договоров аренды',
        'Отдел контроля качества сервисов и локальных решений',
        'Отдел контроля финансов - заказов и договоров',
        'Отдел логистики',
        'Отдел планирования радиосети',
        'Отдел оптимизации сети',
        'Отдел планирования трансмиссионной сети',
        'Отдел сети радиодоступа - систем LI и дата центров',
        'Отдел строительства',
        'Отдел эксплуатации',
        'Подрядная организация',
        'Субподрядчик',
        'Служба внутреннего аудита',
        'Служба мониторинга контроля качества и эффективности сети',
        'Специалист по проектам',
        'Отдел контроля оборудования',
        'Склад',
        'Администратор эксплуатации'
    ];

    useTitle(generateTitle('Создание аккаунта'));

    const navigate = useNavigate();

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    return (
        <Formik
            initialValues={formInitialValues}
            onSubmit={async (data: RequestAccountFormInputFields, { setSubmitting }) => {
                await axios
                    .post('/registration-requests', {
                        name: data.userName,
                        surname: data.userSurname,
                        patronymic: data.userPatronymic,
                        phoneNumber: data.userPhoneNumber,
                        email: data.userMail,
                        roleName: data.userRole
                    })
                    .then(() => {
                        toast({
                            status: 'success',
                            title: `Заявка успешно создана. Вам отправлено письмо для подтверждения почты.`
                        });
                        setTimeout(() => {
                            navigate('/login');
                        }, 2000);
                    })
                    .catch((error) => {
                        console.error(error);
                        toast({
                            status: 'error',
                            title: error.response.data.message || 'Произошла неизвестная ошибка'
                        });
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <Link href="/login" textColor="#647DEE">
                        ← Вернуться назад
                    </Link>
                    <Stack spacing={2} mt={2}>
                        <FormInputField
                            label="Имя"
                            placeholder="Введите имя"
                            name="userName"
                            autoFocus
                            isRequired
                            isDisabled={isSubmitting}
                            value={values.userName}
                            handleChange={handleChange}
                        />
                        <FormInputField
                            label="Фамилия"
                            placeholder="Введите фамилию"
                            name="userSurname"
                            isRequired
                            isDisabled={isSubmitting}
                            value={values.userSurname}
                            handleChange={handleChange}
                        />
                        <FormInputField
                            label="Отчество"
                            placeholder="Введите отчество"
                            name="userPatronymic"
                            isDisabled={isSubmitting}
                            value={values.userPatronymic}
                            handleChange={handleChange}
                            isRequired={false}
                        />
                        <FormInputField
                            label="Номер телефона"
                            placeholder="Введите номер телефона"
                            name="userPhoneNumber"
                            isRequired={false}
                            isDisabled={isSubmitting}
                            value={values.userPhoneNumber}
                            handleChange={handleChange}
                        />
                        <FormInputField
                            label="Коропративный email"
                            placeholder="Введите корпоративную почту"
                            name="userMail"
                            isRequired
                            isDisabled={isSubmitting}
                            value={values.userMail}
                            handleChange={handleChange}
                        />
                        <Select
                            placeholder="Выберите роль"
                            value={values.userRole}
                            onChange={handleChange}
                            id="userRole"
                            name="userRole"
                        >
                            {Roles.map((userRole: any, i: number) => (
                                <option value={userRole} key={i}>
                                    {userRole}
                                </option>
                            ))}
                        </Select>
                        <Box display="flex" flexDirection="row">
                            <Checkbox
                                onChange={handleChange}
                                id="isUserAgreementChecked"
                                name="isUserAgreementChecked"
                                colorScheme="purple"
                                isDisabled={isSubmitting}
                                defaultChecked
                            >
                                <div>
                                    <Link
                                        href={`${process.env.REACT_APP_API_BASE_URL}/uploads/CIP-Tracker-user-agreement-v2.pdf`}
                                        textColor="#647DEE"
                                    >
                                        Я согласен(на) с пользовательским соглашением.
                                        <ExternalLinkIcon mx={1} mb={0.5} />
                                    </Link>
                                </div>
                            </Checkbox>
                        </Box>
                        <AuthSubmitButton
                            isDisabled={
                                !values.userName ||
                                !values.userSurname ||
                                !values.userMail ||
                                !values.userRole ||
                                !values.isUserAgreementChecked
                            }
                            isLoading={isSubmitting}
                        >
                            Отправить заявку
                        </AuthSubmitButton>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};
export default RequestAccount;
