import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    Link as ChakraLink,
    ModalOverlay,
    Box,
    Spinner
} from '@chakra-ui/react';
import QRCode from 'react-qr-code';
import React, { FC, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Spin } from 'antd';
import useSWR from 'swr';
import { fetcher } from '../utils/client';
import { IEsign } from '../interfaces/esigns';

interface ISigning {
    uuid: string;
    comment?: string;
    id: number;
    file: IEsign;
    status: string;
}

interface IProps {
    onClose: () => void;
    initService: (id: number) => Promise<AxiosResponse<ISigning>>;
    id: number;
}

const QrModal: FC<IProps> = ({ onClose, initService, id }) => {
    const [signing, setSigning] = useState<ISigning | null>(null);
    const [loading, setLoading] = useState(false);
    const { data: signingData } = useSWR<ISigning>(signing ? `/signings/${signing.id}` : null, fetcher, {
        refreshInterval: 4000
    });

    useEffect(() => {
        setLoading(true);
        initService(id)
            .then((res) => {
                setSigning(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <Modal onClose={onClose} isOpen isCentered autoFocus={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Отсканируйте QR с помощью приложения Egov mobile</ModalHeader>
                <ModalCloseButton
                    top="0.5rem"
                    right="0.75rem"
                    _focus={{
                        boxShadow: 'none'
                    }}
                />
                <ModalBody>
                    {loading && <Spin />}
                    {signingData && (
                        <>
                            <Box my={1}>
                                Документ:{' '}
                                <ChakraLink
                                    color="blue.600"
                                    _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                                    isExternal
                                    href={`${process.env.REACT_APP_API_BASE_URL}${signingData.file.doc_link}`}
                                >
                                    {signingData.file.file_name}
                                </ChakraLink>
                            </Box>

                            {signingData.status === 'canceled' && (
                                <div>
                                    <div> Заяка отклонена.</div>
                                    <div> {signingData.comment || 'Причина неизвестна'}</div>
                                </div>
                            )}
                            {signingData.status === 'completed' && <div>Документ успешно подписан.</div>}
                            {signingData.status === 'inProgress' && (
                                <div>
                                    <Spinner color="blue.500" /> В процессе подписания
                                </div>
                            )}
                            {(signingData.status === 'inProgress' || signingData.status === 'initiated') && (
                                <QRCode
                                    value={`mobileSign:${process.env.REACT_APP_API_BASE_URL}/signings/${signingData.uuid}/init`}
                                />
                            )}
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
export default QrModal;
