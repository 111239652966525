import React, { FC, useState, useEffect } from 'react';
import { Empty, Select, Spin } from 'antd';
import axios from 'axios';
import useDebounce from 'hooks/useDebounce';

interface IProps {
    value: string;
    onChange: (value: string, option?: any) => void;
    fetchUrl: string;
    placeholder?: string;
    disabled?: boolean;
    valueKey: string;
    labelFormatter: (option: any) => string;
    queryKey?: string;
}

const SelectWithAutocomplete: FC<IProps> = ({
    value,
    onChange,
    fetchUrl,
    placeholder,
    disabled,
    queryKey,
    valueKey,
    labelFormatter
}) => {
    const [options, setOptions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const debouncedSearchText = useDebounce(searchText, 500);

    const fetchOptions = async (searchValue: string) => {
        if (!searchValue) {
            setOptions([]);
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.get(`${fetchUrl}?${queryKey}=${searchValue}`);
            setOptions(response.data);
        } catch (error) {
            console.error('Failed to fetch options', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchOptions(debouncedSearchText);
    }, [debouncedSearchText]);

    const handleSearch = (searchValue: string) => {
        setSearchText(searchValue);
    };

    return (
        <Select
            defaultValue={value ? labelFormatter(value) : undefined}
            value={value}
            onSearch={handleSearch}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            showSearch
            style={{ width: '100%' }}
            filterOption={false}
            loading={isLoading}
            notFoundContent={isLoading ? <Spin size="small" /> : <Empty description="Нет совпадений" />}
            options={options.map((option) => ({
                value: option[valueKey],
                label: labelFormatter(option),
                ...option
            }))}
        />
    );
};

export default SelectWithAutocomplete;
