import { useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import {
    Button as ChakraButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useToast,
    VStack
} from '@chakra-ui/react';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { orderService } from 'api/services';
import useSWR from 'swr';
import { Button, Divider } from 'antd';
import axios from 'axios';
import FileItem from 'components/FileItem';
import ConfirmationDialog, { ConfirmationItems } from 'components/ConfirmationDialog';
import TSSRstatuses from 'components/TSSRstatuses';
import { fetcher } from '../../../../utils/client';
import WorkTssrInstruction from '../../../Works/components/WorkTssrInstruction';
import SurveyList from '../../../Works/components/SurveyList';
import HasAccess from '../../../../guards/HasAccess';
import { ERoleNames } from '../../../../constants';

const TssrUpload = () => {
    const { orderID } = useParams();
    const [loadedTSSR, setLoadedTSSR] = useState<any>(null);
    const [loaderSurveyList, setLoaderSurveyList] = useState<any>(null);
    const { data: order, mutate } = useSWR(orderID ? `/orders/${orderID}` : null, fetcher, {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });
    const [isTSSRModalOpened, setIsTSSRModalOpened] = useState(false);
    const [isSurveyListModalOpened, setIsSurveyListModalOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const [requiresConfirmation, confirm] = useState((): string | null => null);
    const alertRef = useRef(null);
    const isWaitingStartProcess =
        order?.workStatus === 'notStarted' || order?.status === 'returnedForRevision';
    const handleRemoveFile = async (id: number) => {
        await axios
            .post('/esigns/delete', {
                id
            })
            .catch((err) => {
                toast({
                    title: 'Не удалось удалить файл',
                    status: 'error'
                });
                console.error(err);
            });
    };

    const handleSurveyListUpload = () => {
        setIsLoading(true);
        const data = new FormData();
        if (orderID) {
            data.append('id', orderID);
        }
        data.append('field', 'surveyList');
        data.append('file', loaderSurveyList.file);
        console.log('handleSurveyListUpload', data);
        axios
            .post('/orders/upload', data)
            .then(() => {
                toast({
                    status: 'success',
                    title: `Лист обследования был успешно загружен`
                });
                setIsSurveyListModalOpened(false);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast({
                    status: 'error',
                    title: err?.response?.data?.message
                });
                setIsSurveyListModalOpened(false);
                setIsLoading(false);
            });
    };

    const handleSurveyListUploadClick = () => {
        console.log('handleSurveyListUploadClick');
        setIsSurveyListModalOpened(true);
    };
    console.log('IsSurveyListModalOpened', isSurveyListModalOpened);
    const startTssrProcess = () => {
        setIsLoading(true);
        orderService
            .startTssr(order.id)
            .then(() => {
                setIsLoading(false);
                toast({
                    title: 'Процесс согласования успешно запущен',
                    status: 'success'
                });
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
                setIsLoading(false);
            });
    };

    const confirmationActions: ConfirmationItems = {
        startTssrProcess: {
            message: 'Вы уверены что хотите начать процесс? Новые файлы не загружены.',
            func: startTssrProcess
        }
    };

    const isTssrInProcess = order?.tssr_status === 1;
    const tssrCount = order?.tssr?.esigns?.length || 0;
    const canRemove = !order?.tssr_status;
    const canUpload = order?.tssr_status === -1;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',

                backgroundColor: '#ffffff',
                padding: '16px',
                borderRadius: '3px',
                height: 'auto'
            }}
        >
            <Modal
                isCentered
                isOpen={isSurveyListModalOpened}
                onClose={() => setIsSurveyListModalOpened(false)}
                autoFocus={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Загрузка листа обследования</ModalHeader>
                    <ModalCloseButton top="0.5rem" right="0.75rem" />
                    <ModalBody>
                        <Dragger
                            name="file"
                            maxCount={1}
                            disabled={isLoading}
                            onChange={async (info) => {
                                setLoaderSurveyList(info);
                            }}
                            beforeUpload={() => false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Нажмите или переместите сюда файл для загрузки</p>
                        </Dragger>
                    </ModalBody>
                    <ModalFooter justifyContent="strech">
                        <Stack direction="row" justifyContent="flex-end">
                            <ChakraButton
                                size="sm"
                                width="full"
                                onClick={() => setIsSurveyListModalOpened(false)}
                                marginRight="0.75rem"
                            >
                                Закрыть
                            </ChakraButton>
                            <ChakraButton
                                size="sm"
                                isDisabled={!loaderSurveyList}
                                colorScheme="purple"
                                width="full"
                                onClick={handleSurveyListUpload}
                            >
                                Подтвердить
                            </ChakraButton>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal
                isCentered
                isOpen={isTSSRModalOpened}
                onClose={() => setIsTSSRModalOpened(false)}
                autoFocus={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Загрузка TSSR</ModalHeader>
                    <ModalCloseButton top="0.5rem" right="0.75rem" />
                    <ModalBody>
                        <Dragger
                            name="file"
                            maxCount={1}
                            disabled={isLoading}
                            onChange={async (info) => {
                                setLoadedTSSR(info);
                            }}
                            beforeUpload={() => false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Нажмите или переместите сюда файл для загрузки</p>
                        </Dragger>
                    </ModalBody>
                    <ModalFooter justifyContent="strech">
                        <Stack direction="row" justifyContent="flex-end">
                            <ChakraButton
                                size="sm"
                                width="full"
                                onClick={() => setIsTSSRModalOpened(false)}
                                marginRight="0.75rem"
                            >
                                Закрыть
                            </ChakraButton>
                            <ChakraButton
                                size="sm"
                                colorScheme="purple"
                                width="full"
                                isDisabled={!loadedTSSR}
                                onClick={() => {
                                    setIsLoading(true);
                                    const data = new FormData();
                                    data.append('id', orderID!);
                                    data.append('field', 'tssr');
                                    data.append('file', loadedTSSR.file);
                                    axios
                                        .post('/orders/upload', data)
                                        .then(() => {
                                            toast({
                                                status: 'success',
                                                title: `TSSR был успешно загружен`
                                            });
                                            setIsTSSRModalOpened(false);
                                            // getOrder();
                                        })
                                        .catch((err) => {
                                            setIsLoading(false);
                                            toast({
                                                status: 'error',
                                                title: err?.response?.data?.message
                                            });
                                            setIsTSSRModalOpened(false);
                                        });
                                }}
                            >
                                Подтвердить
                            </ChakraButton>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {order?.surveyList?.esigns ? (
                <>
                    <HasAccess roleNames={[ERoleNames.SUBC, ERoleNames.EXECUTOR]}>
                        {(isWaitingStartProcess || canUpload) && (
                            <>
                                {order.work && (
                                    <WorkTssrInstruction
                                        siteId={order?.work?.site_id}
                                        feSiteId={order?.work?.fe_site_id}
                                        workType={order?.work?.work_type}
                                    />
                                )}
                                <Button
                                    style={{
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                        background: '#1F2229',
                                        color: 'white',
                                        borderRadius: '6px'
                                    }}
                                    onClick={() => setIsTSSRModalOpened(true)}
                                >
                                    Загрузить TSSR
                                </Button>
                                <Divider style={{ background: '#1F2229' }} />
                            </>
                        )}
                    </HasAccess>
                    {order?.tssr?.esigns ? (
                        <>
                            <div style={{ minWidth: '400px' }}>
                                <h2
                                    style={{
                                        fontSize: '24px',
                                        textAlign: 'center'
                                    }}
                                >
                                    Загруженные TSSR
                                </h2>
                                <VStack width="full" spacing={0.5}>
                                    {order?.tssr?.esigns
                                        ? order.tssr.esigns.map((item: any) => (
                                              <FileItem
                                                  fileName={item.file_name}
                                                  date={item.updated_at}
                                                  onDownload={() => {
                                                      const url = item?.from_sad
                                                          ? `https://sad.tele2.kz/tele2${item.doc_link.replace(
                                                                '.',
                                                                '',
                                                                1
                                                            )}`
                                                          : `${process.env.REACT_APP_API_BASE_URL}${item.doc_link}`;
                                                      window.open(url);
                                                  }}
                                                  hasRemove={[ERoleNames.SUBC, ERoleNames.EXECUTOR]}
                                                  onRemove={
                                                      canRemove ? () => handleRemoveFile(item.id) : null
                                                  }
                                              />
                                          ))
                                        : null}
                                </VStack>
                            </div>
                        </>
                    ) : (
                        <>
                            <Divider style={{ background: '#1F2229' }} />
                            <span style={{ fontSize: '14px' }}>Загруженных TSSR не найдено</span>
                        </>
                    )}
                </>
            ) : (
                isWaitingStartProcess && (
                    <HasAccess roleNames={[ERoleNames.SUBC, ERoleNames.EXECUTOR]}>
                        <div style={{ color: 'red' }}>
                            Для загрузки TSSR сначала загрузите лист обследования
                        </div>
                    </HasAccess>
                )
            )}
            <SurveyList
                order={order}
                isTssrInProcess={isTssrInProcess}
                onSurveyListUploadClick={handleSurveyListUploadClick}
            />
            <Divider style={{ background: '#1F2229' }} />

            <HasAccess roleNames={[ERoleNames.SUBC, ERoleNames.EXECUTOR]}>
                {(isWaitingStartProcess || order.tssr_status === -1) && (
                    <>
                        <Divider style={{ background: '#1F2229' }} />
                        <ChakraButton
                            width="full"
                            colorScheme="purple"
                            onClick={() => {
                                if (!order.newTssrIsUploaded) {
                                    confirm('startTssrProcess');
                                    return;
                                }
                                startTssrProcess();
                            }}
                            isDisabled={!order.tssr?.esigns || isTssrInProcess || !tssrCount}
                            variant="outline"
                        >
                            Начать процесс
                        </ChakraButton>
                        <ConfirmationDialog
                            modalRef={alertRef}
                            onClose={() => confirm(null)}
                            body={confirmationActions[requiresConfirmation as string]?.message}
                            title="Начать процесс"
                            onAccept={() => {
                                confirmationActions[requiresConfirmation as string].func();
                            }}
                            acceptText="Начать"
                            declineText="Отменить"
                            isOpen={requiresConfirmation !== null}
                        />
                    </>
                )}
            </HasAccess>
            <>
                <div>
                    <TSSRstatuses data={order} mutate={mutate} />
                </div>
            </>
        </div>
    );
};

export default TssrUpload;
