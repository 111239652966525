import {
    Text,
    Modal as ChakraModal,
    Button as ChakraButton,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Box,
    Flex
} from '@chakra-ui/react';

interface OrderDeclienModalProps {
    onClose: () => void;
    isOpen: boolean;
    details: any;
    message: string | null;
}

export default function RSDErrorsModal({ onClose, isOpen, message, details }: OrderDeclienModalProps) {
    return (
        <ChakraModal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Ошибка при загрузке файла RSD</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={1} color="red">
                        {message}
                    </Text>
                    {details?.notFoundSiteIDs
                        ? details?.notFoundSiteIDs.map((siteID: string, index: null) => (
                              <Box p={2} key={index}>
                                  {siteID}
                              </Box>
                          ))
                        : details?.map(
                              (data: { message: string; path: string; type: string }, index: number) => (
                                  <Flex mb={2} key={index}>
                                      <Box mr={2}>{data.message}</Box>
                                      <Box mr={2}>{data.path}</Box>
                                      <Box mr={2}>{data.type}</Box>
                                  </Flex>
                              )
                          )}
                </ModalBody>
                <ModalFooter textAlign="right">
                    <ChakraButton colorScheme="purple" onClick={onClose}>
                        Ok
                    </ChakraButton>
                </ModalFooter>
            </ModalContent>
        </ChakraModal>
    );
}
