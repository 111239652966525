import { extendTheme } from '@chakra-ui/react';

const theme = {
    styles: {
        global: {
            html: {
                fontSize: '16px'
            },
            body: {
                width: '100%'
            }
        }
    },
    space: {
        px: '1px',
        0.5: '0.25rem',
        1: '0.5rem', // 8px
        1.5: '0.75rem', // 12px
        2: '1rem', // 16px
        2.5: '1.25rem', // 20px
        3: '1.5rem', // 24px
        3.5: '1.75rem', // 28px
        4: '2rem',
        5: '2.5rem',
        6: '3rem',
        7: '3.5rem',
        8: '4rem',
        9: '4.5rem',
        10: '5rem',
        12: '6rem',
        14: '7rem',
        16: '8rem',
        20: '10rem',
        24: '12rem',
        28: '14rem',
        32: '16rem',
        36: '18rem',
        40: '20rem',
        44: '22rem',
        48: '24rem',
        52: '26rem',
        56: '28rem',
        60: '30rem',
        64: '32rem',
        72: '36rem',
        80: '40rem',
        96: '48rem'
    },
    components: {
        FormLabel: {
            baseStyle: {
                fontSize: '12px',
                color: '#818694',
                marginBottom: '0.5'
            }
        },
        Select: {
            sizes: {
                sm: {
                    field: {
                        paddingInlineStart: 'var(--chakra-space-1-5)',
                        borderRadius: 'var(--chakra-radii-md)'
                    }
                },
                md: {
                    field: {
                        paddingInlineStart: 2
                    }
                }
            }
        },
        Input: {
            sizes: {
                md: {
                    field: {
                        paddingInlineStart: 2
                    }
                },
                sm: {
                    field: {
                        borderRadius: 'var(--chakra-radii-md)'
                    }
                }
            }
        },
        Button: {
            baseStyle: {
                _focus: {
                    boxShadow: 'none'
                }
            },
            sizes: {
                md: {
                    paddingInlineStart: 2,
                    paddingInlineEnd: 2
                },
                sm: {
                    paddingInlineStart: 2,
                    paddingInlineEnd: 2
                }
            }
        },
        Link: {
            baseStyle: {
                _focus: {
                    boxShadow: 'none'
                }
            }
        },
        Accordion: {
            baseStyle: {
                panel: {
                    paddingInline: 2,
                    paddingY: 1
                },
                button: {
                    paddingInline: 2,
                    paddingY: 1,
                    _focus: {
                        boxShadow: 'none'
                    }
                }
            }
        },
        Popover: {
            baseStyle: {
                content: {
                    borderColor: 'gray.200',
                    _focus: {
                        boxShadow: 'none'
                    }
                },
                body: {
                    paddingInline: '0.75rem',
                    paddingY: '0.5rem'
                },
                header: {
                    borderColor: 'gray.200',
                    borderStyle: 'solid',
                    paddingInline: '0.75rem',
                    paddingY: '0.5rem',
                    fontWeight: 'semibold'
                }
            }
        },
        NumberInput: {
            baseStyle: {
                stepper: {
                    _last: {
                        borderTopStyle: 'solid',
                        borderTopColor: 'gray.200',
                        marginTop: '-1px',
                        borderTopWidth: '1px'
                    }
                }
            },

            sizes: {
                sm: {
                    field: {
                        borderRadius: 'var(--chakra-radii-md)'
                    }
                }
            }
        },
        Divider: {
            baseStyle: {
                borderColor: 'gray.200'
            }
        },
        Modal: {
            baseStyle: {
                header: {
                    paddingInline: '1.5rem',
                    paddingY: '1rem'
                },
                body: {
                    paddingInline: '1.5rem',
                    paddingY: '0.5rem'
                },
                footer: {
                    paddingInline: '1.5rem',
                    paddingY: '1rem'
                }
            }
        },
        Table: {
            sizes: {
                sm: {
                    th: {
                        paddingInline: '1rem',
                        paddingY: '0.25rem'
                    },
                    td: {
                        paddingInline: '1rem',
                        paddingY: '0.25rem'
                    }
                },
                md: {
                    th: {
                        paddingInline: '1.5rem',
                        paddingY: '0.75rem'
                    },
                    td: {
                        paddingInline: '1.5rem',
                        paddingY: '0.75rem'
                    }
                }
            }
        },
        Checkbox: {
            baseStyle: {
                control: {
                    _focus: {
                        boxShadow: 'none'
                    }
                }
            }
        },
        Switch: {
            baseStyle: {
                track: {
                    _focus: {
                        boxShadow: 'none'
                    }
                }
            }
        }
    }
};

export default extendTheme(theme);
