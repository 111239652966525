import React, { useState } from 'react';
import { Card, Row, Col, Typography, Button, Modal } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { IEsignWithOrderInfo } from 'interfaces/esigns';
import HasAccess from '../../../guards/HasAccess';
import { ENGINEERS_GROUP_ROLES } from '../../../constants';

const { Title, Text } = Typography;

interface OrderSelectedFileProps {
    file: IEsignWithOrderInfo;
    changeEditMode: (value: boolean) => void;
    editMode: boolean;
    canEdit: boolean;
}

const OrderSelectedFile: React.FC<OrderSelectedFileProps> = ({ file, changeEditMode, editMode, canEdit }) => {
    const [confirmEditModal, setConfirmEditModal] = useState(false);
    const handleConfirmEdit = () => {
        setConfirmEditModal(false);
        changeEditMode(true);
    };
    return (
        <>
            <Card style={{ marginTop: 16 }}>
                <Row align="middle" gutter={16}>
                    <Col>
                        <FileOutlined style={{ fontSize: '32px', color: '#08c' }} />
                    </Col>
                    <Col>
                        <Title level={4}>{file.file_name}</Title>
                        <Text>{file.order?.code}</Text>
                    </Col>
                </Row>

                <Row justify="end" style={{ marginTop: 16 }}>
                    <Button
                        type="link"
                        style={{ marginRight: 16 }}
                        href={`${process.env.REACT_APP_API_BASE_URL}${file.doc_link}`}
                    >
                        Скачать
                    </Button>
                    <HasAccess roleNames={ENGINEERS_GROUP_ROLES}>
                        {canEdit &&
                            (editMode ? (
                                <Button type="primary" onClick={() => changeEditMode(false)} danger>
                                    Отменить изменение
                                </Button>
                            ) : (
                                <Button type="primary" onClick={() => setConfirmEditModal(true)}>
                                    Изменить
                                </Button>
                            ))}
                    </HasAccess>
                </Row>
            </Card>
            <Modal
                title="Предупреждение"
                visible={confirmEditModal}
                onOk={handleConfirmEdit}
                onCancel={() => setConfirmEditModal(false)}
            >
                Если вы загрузите новый файл, то работы по старому мат.листу будут утеряны. Продолжить?
            </Modal>
        </>
    );
};

export default OrderSelectedFile;
