import { Accept } from 'react-dropzone';
// @chakra-ui
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Button
} from '@chakra-ui/react';
// components
import { FUploadSingleFile } from 'components/form';

// ----------------------------------------------------------------------

interface WorkUploadModalProps {
    accept?: Accept;
    header: string;
    fieldName: string;
    open: boolean;
    onDrop: (acceptedFiles: Array<File>) => void;
    onRemove: () => void;
    onSubmit: () => void;
    onClose: () => void;
    isLoading: boolean;
}

export default function WorkUploadModal({
    accept,
    fieldName,
    header,
    open,
    onClose,
    onDrop,
    onSubmit,
    onRemove,
    isLoading
}: WorkUploadModalProps) {
    return (
        <Modal onClose={onClose} isOpen={open} isCentered autoFocus={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{header}</ModalHeader>
                <ModalCloseButton top="0.5rem" right="0.75rem" />
                <ModalBody>
                    <FUploadSingleFile
                        accept={accept}
                        name={fieldName}
                        maxSize={31457280}
                        onDrop={onDrop}
                        onRemove={onRemove}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={isLoading}
                        disabled={isLoading}
                        colorScheme="purple"
                        onClick={onSubmit}
                    >
                        Подтвердить
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
