import { ChangeEvent, FC } from 'react';
import { Box, Checkbox, Text } from '@chakra-ui/react';

interface FormCheckboxFieldProps {
    isChecked: boolean;
    name: string;
    label: string;
    isDisabled: boolean;
    error?: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * @component
 * @param { string } isChecked Checkbox value
 * @param { string } name Checkbox name
 * @param { string } label Label showed on the left of checkbox
 * @param { boolean } isDisabled If true checkbox will be disabled
 * @param { string } error Error showed below the checkbox
 * @param { (e: ChangeEvent<HTMLInputElement>) => void } handleChange Formik handleChange function
 */
const FormCheckboxField: FC<FormCheckboxFieldProps> = ({
    isChecked,
    name,
    label,
    isDisabled,
    error,
    handleChange
}) => (
    <Box>
        <Checkbox
            colorScheme="purple"
            fontWeight={600}
            color="#647DEE"
            _hover={{
                color: '#4567ff'
            }}
            transition="color .1s ease-in-out"
            disabled={isDisabled}
            name={name}
            isChecked={isChecked}
            onChange={handleChange}
            defaultChecked
        >
            {label}
        </Checkbox>
        {error && (
            <Text mt={1} color="red">
                {error}
            </Text>
        )}
    </Box>
);

export default FormCheckboxField;
