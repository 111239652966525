import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentHeaderTitle } from 'redux/reducers/globalSlice';

const useHeaderTitle = (title: string) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentHeaderTitle(title));
    }, [title]);
};

export default useHeaderTitle;
