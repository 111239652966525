import axios from 'axios';
import qs from 'qs';

const list = ({ department, type, params, cancelToken }: any) =>
    axios.get(`/report/${department}/${type}`, {
        params,
        paramsSerializer: (params) => qs.stringify(params),
        cancelToken
    });

const count = ({ department, type, params, cancelToken }: any) =>
    axios.get(`/report/${department}/${type}/count`, {
        params,
        paramsSerializer: (params) => qs.stringify(params),
        cancelToken
    });

const exportAfu = () => axios.post('/reports/exportAfu');

const exportOrders = ({ params }: any, withConfigs: boolean) =>
    axios.post(
        '/reports/exportOrders',
        { orderConfigs: withConfigs },
        {
            params,
            paramsSerializer: (params) => qs.stringify(params)
        }
    );

export default {
    list,
    count,
    exportAfu,
    exportOrders
};
