/* eslint-disable @typescript-eslint/no-unused-vars */
// @chakra-ui
import { Box, Button, Stack, Text, useToast } from '@chakra-ui/react';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { IOrderWorkType } from 'interfaces/orders';
import { orderService, reportService, subcsService } from 'api/services';
import useGetExportJobData from 'hooks/useGetExportJobData';
import Filters, { TFilterElement } from 'new-components/Filters';
import { ISubc } from 'interfaces/subc';
import { orderStatusesMap, orderWorkStatusesMap } from '../utils/maps';
import constants, { ERoleNames } from '../../../constants';

interface OrderTableToolbarProps {
    filter: {
        [key: string]: string;
    };
    onChangeFilter: ChangeEventHandler;
    isDisabledFilter: boolean;
    totalOrders: number;
    resetFilter: () => void;
}

export default function OrderTableToolbar({
    filter,
    onChangeFilter,
    totalOrders,
    resetFilter,
    isDisabledFilter
}: OrderTableToolbarProps) {
    const [subcs, setSubcs] = useState<ISubc[]>();
    const [orderWorkTypes, setOrderWorkTypes] = useState<IOrderWorkType[]>();
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const [exportOrdersJobId, setExportOrdersJobId] = useState('');
    const { loading } = useGetExportJobData(exportOrdersJobId);

    const getOrderWorksType = () => {
        orderService
            .getWorkTypes()
            .then((res) => {
                setOrderWorkTypes(res.data);
            })
            .catch((err) => console.error(err));
    };
    const getSubcs = () => {
        subcsService
            .list()
            .then((res) => {
                setSubcs(res.data);
            })
            .catch((err) => console.error(err));
    };
    useEffect(() => {
        getSubcs();
        getOrderWorksType();
    }, []);

    const handleExportOrders = (withConfigs: boolean) => {
        reportService
            .exportOrders({ params: filter }, withConfigs)
            .then((res) => {
                toast({
                    description:
                        'Запрос на выгрузку файла принят. Результат будет скачан вашим браузером в папку "Загрузки" и отправлен вам на почту в течение 5 минут. Просмотреть список файлов на выгрузку вы можете нажав на иконку в правом верхнем углу',
                    status: 'success'
                });
                setExportOrdersJobId(res.data.job_id);
            })
            .catch((err) => {
                console.error(err);
                toast({
                    description: 'Ошибка при выгрузке данных'
                });
            });
    };

    const filterElements: TFilterElement[] = [
        {
            type: 'input',
            placeholder: 'ID заказа',
            value: filter.id || '',
            name: 'id'
        },
        {
            type: 'input',
            placeholder: 'Код заказа',
            value: filter.code_conatins || '',
            name: 'code_contains'
        },
        {
            type: 'input',
            placeholder: 'ID сайта',
            value: filter['work.site_id'] || '',
            name: 'work.site_id_contains'
        },
        {
            type: 'select',
            name: 'subc',
            value: filter.subc,
            emptyValue: 'Все подрядчики',
            options: subcs?.map((subcon) => ({ value: String(subcon.id), label: subcon.name })) || [],
            accessControl: { type: 'HasNotAccess', roles: [ERoleNames.SUBC, ERoleNames.EXECUTOR] }
        },
        {
            type: 'select',
            name: 'workType',
            value: filter.workType,
            emptyValue: 'Все типы работ',
            options: orderWorkTypes?.map((type) => ({ value: String(type.id), label: type.title })) || []
        },
        {
            type: 'select',
            name: 'work.site.s_reg',
            value: filter['work.site.s_reg'],
            emptyValue: 'Все регионы',
            options: constants.dropdownData.s_reg.map((region) => ({ value: region, label: region }))
        },
        {
            type: 'select',
            name: 'status',
            value: filter.status,
            emptyValue: 'Все статусы заказа',
            options: Object.entries(orderStatusesMap).map(([key, value]) => ({
                value: key,
                label: value.label
            }))
        },
        {
            type: 'select',
            name: 'workStatus',
            value: filter.workStatus,
            emptyValue: 'Все статусы работы',
            options: Object.entries(orderWorkStatusesMap).map(([key, value]) => ({
                value: key,
                label: value.label
            }))
        }
    ];

    return (
        <Box maxW="100%">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text fontWeight="bold" whiteSpace="nowrap">
                    Всего заказов ({totalOrders})
                </Text>
                <Stack direction="row" alignItems="center">
                    <Button
                        size="sm"
                        mr={1}
                        flexShrink={0}
                        colorScheme="teal"
                        ml="auto"
                        onClick={() => handleExportOrders(true)}
                        isLoading={loading}
                        loadingText="Загрузка"
                    >
                        Экспорт заказов с ТЦП
                    </Button>
                    <Button
                        size="sm"
                        mr={1}
                        flexShrink={0}
                        colorScheme="teal"
                        ml="auto"
                        onClick={() => handleExportOrders(false)}
                        isLoading={loading}
                        loadingText="Загрузка"
                    >
                        Экспорт заказов
                    </Button>
                </Stack>
            </Stack>
            <Box my={2}>
                <Filters
                    onReset={resetFilter}
                    filters={filter}
                    readonly={isDisabledFilter}
                    filterElements={filterElements}
                    onChangeFilter={onChangeFilter}
                />
            </Box>
        </Box>
    );
}
