import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { saveState, getState } from '../utils/localStorage';

const preloadedState = getState();

const store = configureStore({
    reducer: rootReducer,
    preloadedState
});

store.subscribe(() => {
    saveState(store.getState());
});

export default store;
