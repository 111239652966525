import axios from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer.types';

interface ActivityProviderProps {
    children: JSX.Element;
}

function ActivityProvider({ children }: ActivityProviderProps) {
    const { isAuthed } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (isAuthed) {
            axios
                .post('/daily-activities', {
                    source: 'web'
                })
                .catch((err) => console.error(err));
        }
    }, [isAuthed]);

    return children;
}

export default ActivityProvider;
