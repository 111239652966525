import { ErrorMessage as EM, FieldAttributes } from 'formik';
import React, { forwardRef } from 'react';

// @chakra-ui
import { Box, Skeleton, Flex } from '@chakra-ui/react';
import SiteConfigurationForm, { NDS_PERCENTAGE } from './SiteConfigurationForm';
import { currencyFormat } from '../../utils';

const ErrorMessage = EM as FieldAttributes<any>;

function SiteConfigurations({ disabled, neSiteConfigs, feSiteConfigs, orderID }: any, ref: any) {
    // eslint-disable-next-line react/destructuring-assignment
    const { ref_ne, ref_fe } = ref.current;

    return (
        <Box
            shadow="md"
            borderWidth="1px"
            mt={3}
            p={3}
            bg="white"
            rounded={8}
            position="relative"
            color={disabled ? '#6E6B7B' : 'auto'}
        >
            {neSiteConfigs ? (
                <Box>
                    <Box mt={2} color="red" fontSize="sm">
                        <ErrorMessage name="neSites" component="div" className="field-error" />
                    </Box>
                    <SiteConfigurationForm
                        title={`Сайт ${neSiteConfigs}`}
                        resource="neSites"
                        ref={ref_ne}
                        disabled={disabled}
                        orderID={orderID}
                    />
                </Box>
            ) : (
                <Skeleton w="100%" h={2} colorScheme="teal" />
            )}
            {feSiteConfigs && (
                <SiteConfigurationForm
                    title={`Сайт(FE) ${feSiteConfigs}`}
                    resource="feSites"
                    ref={ref_fe}
                    disabled={disabled}
                    orderID={orderID}
                />
            )}
            <Flex mt={2} p={1} pr={5} justifyContent="flex-end">
                <Box mr={3}>
                    <Box fontWeight="bold">ИТОГО с НДС</Box>
                    <Box fontWeight="bold" mt={1}>
                        В том числе НДС(12%)
                    </Box>
                </Box>
                <Box>
                    <Box>
                        {currencyFormat(
                            (ref_ne.current.totalAmount + ref_fe.current.totalAmount) * (1 + NDS_PERCENTAGE)
                        )}
                    </Box>
                    <Box mt={1}>
                        {currencyFormat(
                            (ref_ne.current.totalAmount + ref_fe.current.totalAmount) * NDS_PERCENTAGE
                        )}
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
}

export default forwardRef(SiteConfigurations);
