import {
    Box,
    Button,
    Fade,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Stack,
    useToast
} from '@chakra-ui/react';
import { Select } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const formLabelStyle = {
    fontSize: '1rem',
    color: '#1A202C',
    marginBottom: '0.5rem'
};

const fuelTypes = ['АИ 92', 'АИ 95', 'АИ 98', 'ДТЛ', 'ДТЗ'];

const initialValues = {
    car: {
        state_number: '',
        id: 0
    },
    fuel: '',
    name: '',
    volume: '',
    odometer: '',
    fuel_card: {
        card_number: '',
        id: 0
    },
    fuelingDate: new Date()
};

const ValidationSchema = Yup.object().shape({
    car: Yup.object().required('Поле обязательное'),
    fuel: Yup.string().required('Поле обязательное'),
    name: Yup.string().required('Поле обязательное'),
    odometer: Yup.number().required('Поле обязательное'),
    fuelingDate: Yup.date(),
    volume: Yup.number().required('Поле обязательное'),
    fuel_card: Yup.object().required('Поле обязательное')
});

export default function CarGSMEdit() {
    const { id } = useParams();

    const [cars, setCars] = useState([]);
    const [fuelCards, setFuelCards] = useState([]);
    const [user, setUser] = useState<any>(null);

    const [oldVolume, setOldVolume] = useState<Number>(0);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            if (values.fuel === 'Канистра' && oldVolume !== Number(values.volume)) {
                const reminders = (await axios.get(`/remainders?_where[user.id_eq]=${user.id}`)).data[0];
                if (!reminders) {
                    toast({
                        status: 'error',
                        title: 'У вас не зарегестрирована канистра'
                    });
                    return;
                }

                if (Number(oldVolume) > Number(values.volume)) {
                    await axios.put(`/remainders/${reminders.id}`, {
                        gas: Number(reminders.gas) + (Number(oldVolume) - Number(values.volume))
                    });

                    setOldVolume(Number(values.volume));
                }

                if (Number(oldVolume) < Number(values.volume)) {
                    if (Number(reminders.gas) - (Number(values.volume) - Number(oldVolume)) < 0) {
                        toast({
                            status: 'error',
                            title: 'У вас недостаточно топлива в ваших остатках'
                        });
                        return;
                    }
                    await axios.put(`/remainders/${reminders.id}`, {
                        gas: Number(reminders.gas) - (Number(values.volume) - Number(oldVolume))
                    });
                    setOldVolume(Number(values.volume));
                }
            }
            const updateData = {
                car: values.car.id,
                fuel: values.fuel,
                name: values.name,
                odometer: String(values.odometer),
                fuelingDate: moment(values.fuelingDate).toDate(),
                volume: String(values.volume),
                fuel_card: values.fuel_card.id
            };
            axios
                .put(`/gas-reports/${id}`, updateData)
                .then(() => {
                    toast({
                        status: 'success',
                        title: `Успешно обновлено`
                    });
                    setSubmitting(false);
                })
                .catch((error) => {
                    toast({
                        status: 'error',
                        title: 'При обновлении произошла ошибка'
                    });
                    console.error(error);
                    setSubmitting(false);
                });
        },
        validationSchema: ValidationSchema
    });

    const getData = async () => {
        const { data } = await axios.get(`/gas-reports/${id}`);

        const tmpData = {
            car: data.car,
            fuel: data.fuel,
            name: data.name,
            volume: data.volume,
            odometer: data.odometer,
            fuel_card: data.fuel_card,
            fuelingDate: data.fuelingDate
        };

        setOldVolume(Number(data.volume));
        setUser(data.user);
        formik.setValues(tmpData);
    };

    const getCars = async () => {
        const cars = await axios.get(`/cars-oes`);
        if (cars.status === 200) {
            setCars(cars.data.docs);
        }
    };

    const getFuelCards = async () => {
        const cards = await axios.get(`/fuel-cards`);
        if (cards.status === 200) {
            setFuelCards(cards.data.docs);
        }
    };

    useEffect(() => {
        getData();
        getCars();
        getFuelCards();
    }, []);

    return (
        <Box bg="white" maxWidth="500px" marginX="auto" borderRadius={10} p={3}>
            <Fade in style={{ display: 'flex', width: '100%', height: '100%' }}>
                <Box width="100%" display="flex" flexDir="column">
                    <form onSubmit={formik.handleSubmit}>
                        <Heading as="h4" size="md" mb={3}>
                            Заправка Авто
                        </Heading>
                        <Stack spacing={2} width="100%">
                            <FormControl>
                                <FormLabel style={formLabelStyle}>Госномер авто</FormLabel>
                                <Select
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    showSearch
                                    value={formik.values.car.state_number || undefined}
                                    onChange={(value) => {
                                        const newCar = cars.find((item: any) => item.id === value);
                                        formik.setFieldValue('car', newCar);
                                    }}
                                >
                                    {cars.map((item: any, index: number) => (
                                        <Select.Option value={item.id} key={index}>
                                            {item.state_number}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.fuel_card}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Карта заправки</FormLabel>
                                    <Select
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        showSearch
                                        value={formik.values.fuel_card.card_number || undefined}
                                        onChange={(value) => {
                                            const newCard = fuelCards.find((item: any) => item.id === value);
                                            formik.setFieldValue('fuel_card', newCard);
                                        }}
                                    >
                                        {fuelCards.map((item: any, index: number) => (
                                            <Select.Option value={item.id} key={index}>
                                                {item.card_number}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.fuel_card}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.fuel}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Топливо</FormLabel>
                                    <Select
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        showSearch
                                        value={formik.values.fuel || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('fuel', value);
                                        }}
                                    >
                                        {fuelTypes.map((item: any, index: number) => (
                                            <Select.Option value={item} key={index}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.name}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.name}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Имя</FormLabel>
                                    <Input
                                        value={formik.values.name || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('name', value.target.value);
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">{formik.errors.name}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.volume}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Количество топлива</FormLabel>
                                    <Input
                                        value={formik.values.volume || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('volume', value.target.value);
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">{formik.errors.volume}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.odometer}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Одометр</FormLabel>
                                    <Input
                                        value={formik.values.odometer || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('odometer', value.target.value);
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">{formik.errors.odometer}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.fuelingDate}
                                    </FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Дата заправки</FormLabel>
                                    <Input
                                        type="datetime-local"
                                        value={moment(formik.values.fuelingDate)
                                            .zone('+0600')
                                            .format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'fuelingDate',
                                                moment(e.target.value).toDate()
                                            );
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.fuelingDate}
                                    </FormErrorMessage>
                                </Box>
                            </FormControl>
                        </Stack>
                        <Button
                            type="submit"
                            colorScheme="purple"
                            width="100%"
                            mt="1rem"
                            isLoading={formik.isSubmitting}
                            isDisabled={formik.isSubmitting}
                            loadingText="Обновляем..."
                        >
                            Сохранить
                        </Button>
                    </form>
                </Box>
            </Fade>
        </Box>
    );
}
