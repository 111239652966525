interface ISiteBase {
    sitename: string;
}
export enum ESiteActivityStatus {
    ACTIVE = 'active',
    DISMANTLED = 'dismantled',
    READY_TO_DISMANTLE = 'ready_to_dismantle',
    PENDING = 'pending'
}
export const SiteActivityStatusDictionary = {
    [ESiteActivityStatus.ACTIVE]: 'Активен',
    [ESiteActivityStatus.DISMANTLED]: 'Демонтирован',
    [ESiteActivityStatus.READY_TO_DISMANTLE]: 'Готов к демонтажу',
    [ESiteActivityStatus.PENDING]: 'Кабинетный'
};
export interface ISiteForm extends ISiteBase {
    createdSiteReg: string;
    createdSiteId: string;
    createdSiteLat: string;
    createdSiteLng: string;
    kato: string;
}

export interface ISite extends ISiteBase {
    s_new_site_id: string;
    activityStatus: ESiteActivityStatus;
    id: number;
    price_region: string;
    history_site_approves?: string[];
}
