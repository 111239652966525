import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
import axios from 'axios';
import { useEffect, useState } from 'react';

interface IFuel {
    gas: Number;
    diesel: Number;
}

export default function FuelRemainders() {
    const { data } = useSelector(userSelector);
    const [fuel, setFuel] = useState<IFuel | null>(null);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const [createLoading, setCreateLoading] = useState(false);

    const getData = async () => {
        const fuelRemainders = (await axios.get(`/remainders?user=${data.id}`)).data[0];

        if (fuelRemainders) setFuel({ gas: fuelRemainders.gas, diesel: fuelRemainders.diesel });
    };

    const createRemainders = async () => {
        setCreateLoading(true);
        const remaindersData = await axios.post(`/remainders`, { user: data.id });

        if (remaindersData.status === 200) {
            setFuel({ gas: remaindersData.data.gas, diesel: remaindersData.data.diesel });
            toast({
                status: 'success',
                title: `Остатки успешно зарегистрированы!`
            });
        } else {
            toast({
                status: 'error',
                title: 'При запросе возникла ошибка произошла ошибка'
            });
        }

        setCreateLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <Box display="flex" justifyContent="center" mt="14">
            <Box backgroundColor="white" padding="5" borderRadius="10px" maxWidth="50%">
                {fuel ? (
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <Text fontSize="3xl" fontWeight="bold">
                            Остатки топлива
                        </Text>
                        <Box mt={4}>
                            <Text mt={2} fontSize="xl">
                                Бензин - {fuel.gas} л
                            </Text>
                            <Text mt={2} fontSize="xl">
                                Дизель - {fuel.diesel} л
                            </Text>
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <Text fontSize="lg" lineHeight={8}>
                            У вас еще не зарегистрированы остатки,
                            <br />
                            нажмите на кнопку для их регистрации
                        </Text>
                        <Button
                            colorScheme="purple"
                            width="50%"
                            mt="1rem"
                            px="10px"
                            py="8px"
                            onClick={() => createRemainders()}
                            isLoading={createLoading}
                            isDisabled={createLoading}
                            loadingText="Заводим остатки..."
                        >
                            Зарегистрировать
                            <br />
                            остатки
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
