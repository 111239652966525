import {
    Box,
    Button,
    Fade,
    useToast,
    Stack,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    Select
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { equipmentsService } from 'api/services';
import Label from 'new-components/ui/Label';
import React from 'react';
import useHeaderTitle from '../../hooks/useHeaderTitle';
import constants from '../../constants';

const initialValues = {
    sap_code: '',
    s_unit: 'Шт.',
    s_product_code: '',
    s_equip_title: ''
};

const CreateEquipment = () => {
    useHeaderTitle('Создание оборудования');
    const ValidationSchema = Yup.object().shape({
        sap_code: Yup.string().required('Поле обязательное'),
        s_product_code: Yup.string().required('Поле обязательное'),
        s_unit: Yup.string().required('Поле обязательное')
    });

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            equipmentsService
                .addEquipment(values)
                .then(() => {
                    toast({
                        status: 'success',
                        title: 'Оборудование было успешно создано'
                    });
                    resetForm();
                    setSubmitting(false);
                })
                .catch((e) => {
                    toast({
                        status: 'error',
                        title: e.response?.data?.message || 'При создании оборудования произошла ошибка'
                    });
                    setSubmitting(false);
                });
        },
        validationSchema: ValidationSchema
    });

    return (
        <Box bg="white" maxWidth="500px" width="400px" marginX="auto" borderRadius={10} p={3}>
            <Fade in style={{ display: 'flex', width: '100%', height: '100%' }}>
                <Box width="100%" display="flex" flexDir="column">
                    <form onSubmit={formik.handleSubmit}>
                        <Heading as="h4" size="md" mb={3}>
                            Данные по новому оборудованию
                        </Heading>
                        <Stack spacing={2} width="100%">
                            <FormControl isRequired>
                                <Label text="SAP Code оборудования" />
                                <Input
                                    name="sap_code"
                                    onChange={formik.handleChange}
                                    value={formik.values.sap_code}
                                    disabled={false}
                                    placeholder="Введите SAP Code оборудования"
                                />
                                <FormErrorMessage mt="0.5rem">errors.createdSiteId</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired>
                                <Label text="Product Code оборудования" />
                                <Input
                                    name="s_product_code"
                                    onChange={formik.handleChange}
                                    value={formik.values.s_product_code}
                                    disabled={false}
                                    placeholder="Введите Product Code оборудования"
                                />
                                <FormErrorMessage mt="0.5rem">errors.createdSiteId</FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <Label text="Название оборудования" />
                                <Input
                                    name="s_equip_title"
                                    onChange={formik.handleChange}
                                    value={formik.values.s_equip_title}
                                    disabled={false}
                                    placeholder="Введите название оборудования"
                                />
                                <FormErrorMessage mt="0.5rem">errors.createdSiteId</FormErrorMessage>
                            </FormControl>
                            <FormControl
                                isInvalid={!!formik.errors.s_unit && (formik.touched.s_unit as boolean)}
                                isRequired
                            >
                                <Label text="Единица измерения" />
                                <Select
                                    style={{ width: '100%' }}
                                    name="s_unit"
                                    onChange={formik.handleChange}
                                    disabled={formik.isSubmitting}
                                    placeholder="Выберите регион сайта"
                                    value={formik.values.s_unit}
                                >
                                    {constants.dropdownData.equipment_s_unit.map(
                                        (item: string, i: number) => (
                                            <option value={item} key={i}>
                                                {item}
                                            </option>
                                        )
                                    )}
                                </Select>
                                <FormErrorMessage mt="0.5rem">{formik.errors.s_unit}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                        <Button
                            type="submit"
                            colorScheme="purple"
                            width="100%"
                            mt="1rem"
                            isLoading={formik.isSubmitting}
                            isDisabled={formik.isSubmitting}
                            loadingText="Создаем..."
                        >
                            Создать оборудование
                        </Button>
                    </form>
                </Box>
            </Fade>
        </Box>
    );
};

export default CreateEquipment;
