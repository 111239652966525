import {
    Box,
    Card,
    CardBody,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { IHistoryItem } from '../interfaces/history';
import { ACTION_TYPES, STATUSES } from '../constants';

interface IProps {
    getHistory: (id: number) => Promise<AxiosResponse<IHistoryItem[]>>;
    onClose: () => void;
    id: number;
}

const HistoryModal: FC<IProps> = ({ getHistory, onClose, id }) => {
    const toast = useToast();
    const [history, setHistory] = useState<IHistoryItem[]>([]);
    useEffect(() => {
        getHistory(id)
            .then((res) => {
                setHistory(res.data);
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось получить историю'
                });
            });
    }, []);

    return (
        <Modal isOpen onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>История</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack gap={2}>
                        {history.map((item) => (
                            <Card>
                                <CardBody p={1}>
                                    <Stack gap={1}>
                                        <div>{new Date(item.created_at).toLocaleString('ru-Ru')}</div>
                                        <div>
                                            Действие:{' '}
                                            {ACTION_TYPES[item.actionType] || 'Неизвестное действие'}
                                        </div>
                                        {item.status && (
                                            <Stack direction="row">
                                                <Text> Cтатус:</Text>
                                                <Text size="sm" color={STATUSES[item.status]?.color || ''}>
                                                    {STATUSES[item.status]?.label || 'Неизвестный статус'}
                                                </Text>
                                            </Stack>
                                        )}
                                        {item.user && (
                                            <div>
                                                Инициатор: {item.user.full_name} ({item.user.email})
                                            </div>
                                        )}
                                        {item.comment && <div>Комментарий: {item.comment}</div>}
                                        {item.esign && (
                                            <Box>
                                                <Link
                                                    color="blue.600"
                                                    _hover={{
                                                        color: 'blue.600',
                                                        textDecoration: 'underline'
                                                    }}
                                                    isExternal
                                                    href={`${process.env.REACT_APP_API_BASE_URL}${item.esign.doc_link}`}
                                                >
                                                    {item.esign.file_name}
                                                </Link>
                                            </Box>
                                        )}
                                    </Stack>
                                </CardBody>
                            </Card>
                        ))}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
export default HistoryModal;
