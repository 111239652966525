/* eslint-disable no-use-before-define */
import { useRef, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
// @chakra-ui
import {
    IconButton,
    Text,
    Button,
    Stack,
    Box,
    VStack,
    Divider,
    useBreakpointValue,
    useToast,
    Heading
} from '@chakra-ui/react';
import { DownloadIcon, DeleteIcon } from '@chakra-ui/icons';
// form
import { FormikProps } from 'formik';
// hooks
import useToggle from 'hooks/useToggle';
// components
import { FormProvider } from 'components/form';
// services
import { siteService } from 'api/services';
//
import WorkUplaodModal from '../WorkUploadModal';

// ----------------------------------------------------------------------

interface WorkRltAokFilesProps {
    work: any;
    updateWork: () => void;
}

export default function WorkRltAokFiles({ work, updateWork }: WorkRltAokFilesProps) {
    const [isLoadingAok, setIsLoadingAok] = useState(false);
    const [isLoadingExtra, setIsLoadingExtra] = useState(false);

    const formikRefAok = useRef<FormikProps<any>>(null);
    const formikRefExtra = useRef<FormikProps<any>>(null);

    const { toggle: openAok, onOpen: onOpenAok, onClose: onCloseAok } = useToggle();
    const { toggle: openExtra, onOpen: onOpenExtra, onClose: onCloseExtra } = useToggle();

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const handleDropAok = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];

        if (file) {
            formikRefAok.current!.setFieldValue('fileAok', file);
        }
    };

    const handleDropExtra = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];

        if (file) {
            formikRefExtra.current!.setFieldValue('fileExtra', file);
        }
    };

    const handleRemoveAok = () => {
        formikRefAok.current!.setFieldValue('fileAok', null);
    };

    const handleRemoveExtra = () => {
        formikRefExtra.current!.setFieldValue('fileExtra', null);
    };

    const handleSubmitAok = () => {
        const { fileAok } = formikRefAok.current!.values;

        if (!fileAok) return;

        const formData = new FormData();
        formData.append('id', work.id);
        formData.append('field', 's_cand_abs_prot');
        formData.append('file', fileAok);

        setIsLoadingAok(true);
        siteService
            .upload(formData)
            .then(() => {
                setIsLoadingAok(false);
                onCloseAok();
                updateWork();
            })
            .catch((err) => {
                setIsLoadingAok(false);
                toast({
                    title: err.response?.data?.message,
                    status: 'error'
                });
                console.error(err.response);
            });
    };

    const handleSubmitExtra = () => {
        const { fileExtra } = formikRefExtra.current!.values;

        if (!fileExtra) return;

        const formData = new FormData();
        formData.append('id', work.id);
        formData.append('field', '');
        formData.append('file', fileExtra);

        setIsLoadingExtra(true);
        siteService
            .upload(formData)
            .then(() => {
                setIsLoadingExtra(false);
                onCloseExtra();
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response?.data?.message,
                    status: 'error'
                });
                setIsLoadingExtra(false);
                console.error(err);
            });
    };

    const handleRemove = (id: number) => {
        axios
            .post('/esigns/delete', {
                id
            })
            .then(() => {
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: 'Не удалось удалить файл',
                    status: 'error'
                });
                console.error(err);
            });
    };

    const disableUpload = !_.includes([null, -1], work.s_cand_abs_prot_appr_main_status);

    return (
        <Stack
            spacing={{ base: 1, md: 2.5 }}
            direction={{ base: 'column', md: 'row' }}
            divider={
                <Divider
                    height="auto"
                    orientation={useBreakpointValue({ base: 'horizontal', md: 'vertical' })}
                />
            }
            width="full"
            overflowX="auto"
            p={1.5}
        >
            <Box width="full" minWidth="500px">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Heading fontSize="md" as="h4">
                        Загруженные AOK
                    </Heading>
                    <Button
                        size="sm"
                        colorScheme="purple"
                        onClick={onOpenAok}
                        flexShrink={0}
                        disabled={disableUpload}
                    >
                        Загрузить AOK
                    </Button>
                </Stack>

                <VStack mt={2} width="full" spacing={0.5}>
                    {work.s_cand_abs_prot?.esigns?.length > 0 ? (
                        work.s_cand_abs_prot.esigns.map(
                            ({ id, file_name, created_at, doc_link, banOnDeletion }: any) => (
                                <FileItem
                                    key={id}
                                    fileName={file_name}
                                    date={created_at}
                                    onDownload={() => {
                                        window.open(`${process.env.REACT_APP_API_BASE_URL}${doc_link}`);
                                    }}
                                    onRemove={() => handleRemove(id)}
                                    disableRemove={disableUpload || banOnDeletion}
                                />
                            )
                        )
                    ) : (
                        <Text>Загруженных файлов не найдено</Text>
                    )}
                </VStack>

                <FormProvider
                    innerRef={formikRefAok}
                    initialValues={{
                        fileAok: null
                    }}
                >
                    <WorkUplaodModal
                        isLoading={isLoadingAok}
                        fieldName="fileAok"
                        header="Загрузка AOK"
                        open={openAok}
                        onClose={onCloseAok}
                        onDrop={handleDropAok}
                        onRemove={handleRemoveAok}
                        onSubmit={handleSubmitAok}
                    />
                </FormProvider>
            </Box>

            <Box width="full" minWidth="500px">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Heading fontSize="md" as="h4">
                        Загруженные доп. файлы
                    </Heading>
                    <Button
                        size="sm"
                        colorScheme="purple"
                        onClick={onOpenExtra}
                        flexShrink={0}
                        // disabled={disableUpload}
                        disabled
                    >
                        Загрузить доп. файлы
                    </Button>
                </Stack>

                <VStack mt={2} width="full" spacing={0.5}>
                    {work.s_sar_agreement_files?.esigns?.length ? (
                        work.s_sar_agreement_files.esigns.map(
                            ({ id, file_name, created_at, doc_link }: any) => (
                                <FileItem
                                    key={id}
                                    fileName={file_name}
                                    date={created_at}
                                    onDownload={() => {
                                        window.open(`${process.env.REACT_APP_API_BASE_URL}${doc_link}`);
                                    }}
                                    onRemove={() => handleRemove(id)}
                                    disableRemove={disableUpload}
                                />
                            )
                        )
                    ) : (
                        <Text>Загруженных файлов не найдено</Text>
                    )}
                </VStack>

                <FormProvider innerRef={formikRefExtra} initialValues={{ fileExtra: null }}>
                    <WorkUplaodModal
                        isLoading={isLoadingExtra}
                        fieldName="fileExtra"
                        header="Загрузка доп. файлов"
                        open={openExtra}
                        onClose={onCloseExtra}
                        onDrop={handleDropExtra}
                        onRemove={handleRemoveExtra}
                        onSubmit={handleSubmitExtra}
                    />
                </FormProvider>
            </Box>
        </Stack>
    );
}

// ----------------------------------------------------------------------

interface FileItemProps {
    fileName: string;
    date: string;
    disableRemove: boolean;
    onDownload: () => void;
    onRemove: () => void;
}

function FileItem({ fileName, date, onDownload, onRemove, disableRemove }: FileItemProps) {
    return (
        <Stack direction="row" spacing={1} alignItems="center" fontSize="sm" width="full">
            <Box flexGrow={1} wordBreak="break-word">
                {fileName}
            </Box>
            <Box flexShrink={0}>{new Date(date).toLocaleString('ru')}</Box>
            <Box flexShrink={0}>
                <IconButton
                    variant="outline"
                    colorScheme="purple"
                    onClick={onDownload}
                    size="xs"
                    aria-label="Download file"
                    icon={<DownloadIcon />}
                    mr="5px"
                />
                <IconButton
                    variant="outline"
                    colorScheme="red"
                    onClick={onRemove}
                    size="xs"
                    aria-label="Remove file"
                    icon={<DeleteIcon />}
                    disabled={disableRemove}
                />
            </Box>
        </Stack>
    );
}
