import { Stack, Box, VStack, Heading, Button, Text, useToast } from '@chakra-ui/react';
import { FormProvider } from 'components/form';
import { FormikProps } from 'formik';
import { useRef, useState } from 'react';
import useToggle from 'hooks/useToggle';
import { workService } from 'api/services';
import axios from 'axios';
import { userSelector } from 'redux/reducers/userSlice';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { FileItem } from './Sar/SarFiles';
import WorkUplaodModal from '../../WorksRollout/components/WorkUploadModal';

const WorkDocuments = (props: any) => {
    const user = useSelector(userSelector);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const { work, updateWork } = props;
    const { candidateAdditionalStatus } = work;

    const formikRef = useRef<FormikProps<any>>(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleStart = () => {
        setIsLoading(true);
        workService
            .startSarProcess({
                work_id: work.id,
                type: 'candidateAdditional'
            })
            .then(() => {
                setIsLoading(false);
                toast({
                    title: 'Процесс согласования успешно запущен',
                    status: 'success'
                });
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
                setIsLoading(false);
            });
    };

    const handleDelete = (id: string) => {
        axios
            .post('/esigns/delete', {
                id
            })
            .then(() => {
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: 'Не удалось удалить файл',
                    status: 'error'
                });
                console.error(err);
            });
    };
    const { toggle, onOpen, onClose } = useToggle();

    const handleDrop = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];
        if (file) {
            formikRef.current!.setFieldValue('file', file);
        }
    };

    const handleRemove = () => {
        formikRef.current!.setFieldValue('file', null);
    };

    const handleSubmit = () => {
        const { file } = formikRef.current!.values;
        if (!file) return;

        const formData = new FormData();
        formData.append('work_id', work.id);
        formData.append('field', 'candidateAdditional');
        formData.append('file', file);

        setIsLoading(true);
        workService
            .upload(formData)
            .then(() => {
                setIsLoading(false);
                onClose();
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response?.data?.message,
                    status: 'error'
                });
                setIsLoading(false);
                console.error(err);
            });
    };

    const disableUploadCandidate =
        _.includes([2], work.candidateAdditionalStatus) || user.role?.type !== 'rnp';
    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="center" mb={1}>
                {user.role && user.role.type === 'rnp' ? (
                    <Button
                        size="sm"
                        colorScheme="purple"
                        onClick={onOpen}
                        flexShrink={0}
                        isDisabled={disableUploadCandidate}
                    >
                        Загрузить документ
                    </Button>
                ) : null}
            </Stack>
            <VStack p={2} width="full" spacing={0.5}>
                {work.candidateAdditional && work?.candidateAdditional?.esigns?.length > 0 ? (
                    <>
                        <Heading fontSize="md" as="h4">
                            Загруженный документ
                        </Heading>
                        {work?.candidateAdditional.esigns.map(
                            ({ id, file_name, created_at, doc_link, banOnDeletion }: any) => (
                                <FileItem
                                    key={id}
                                    fileName={file_name}
                                    date={created_at}
                                    onDownload={() => {
                                        window.open(`${process.env.REACT_APP_API_BASE_URL}${doc_link}`);
                                    }}
                                    onRemove={() => handleDelete(id)}
                                    disableRemove={banOnDeletion}
                                />
                            )
                        )}
                    </>
                ) : (
                    <Text>Загруженных файлов не найдено</Text>
                )}
            </VStack>
            <Stack p={1.5} alignItems="center">
                {user.role && user.role.type === 'rnp' ? (
                    _.includes([null, -1], candidateAdditionalStatus) ? (
                        <Button
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            colorScheme="purple"
                            onClick={handleStart}
                            variant="outline"
                        >
                            Начать процесс
                        </Button>
                    ) : null
                ) : null}
            </Stack>
            <FormProvider innerRef={formikRef} initialValues={{ file: null }}>
                <WorkUplaodModal
                    isLoading={isLoading}
                    fieldName="file"
                    header="Загрузка документов"
                    open={toggle}
                    onClose={onClose}
                    onDrop={handleDrop}
                    onRemove={handleRemove}
                    onSubmit={handleSubmit}
                />
            </FormProvider>
        </Box>
    );
};

export default WorkDocuments;
