import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @chakra-ui
import { Tabs, TabList, Tab, Box, Divider, HStack, Button, useToast } from '@chakra-ui/react';
// hooks
import useTabs from 'hooks/useTabs';
import useHeaderTitle from 'hooks/useHeaderTitle';
// services
import { siteService } from 'api/services';
// components
import LoadingOverlay from 'components/LoadingOverlay';
// sections
import { Badge, Tree, Image, Modal } from 'antd';
import { WorkScans } from 'Routes/Works/components';
import { CheckIcon, DownloadIcon, ViewIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { OrderList } from 'Routes/Order';
import { WorkRltSL, WorkRltAFU, WorkRltAOK, WorkRltSAR, WorkRltTssr } from './components';

// ----------------------------------------------------------------------

export default function WorkRlt() {
    const [isLoading, setIsLoading] = useState(false);

    const [work, setWork] = useState<any>(null);

    const [rsd, setRsd] = useState<any>({});

    const { currentTab, onChangeTab } = useTabs('sar');

    const { trueSiteID: id, siteID } = useParams();

    useHeaderTitle(`Сайт ${siteID}`);

    const [photosTree, setPhotosTree] = useState<any>(null);
    const [scans, setScans] = useState([]);

    const [isMontageButtonBlock, setIsMontageButtonBlock] = useState(false);
    const [isDemontageButtonBlock, setIsDemontageButtonBlock] = useState(false);

    const [trueWork, setTrueWork] = useState<any>(null);

    const getExcelMontageMatList = async () => {
        setIsMontageButtonBlock(true);
        await axios
            .post('/works/uploadMatListInExcel', {
                id: trueWork?.id,
                type: 6
            })
            .then((res) => {
                window.open(`${process.env.REACT_APP_API_BASE_URL}${res.data.path}`);
            })
            .finally(() => {
                setIsMontageButtonBlock(false);
            });
    };
    const getExcelDemontageMatList = async () => {
        setIsDemontageButtonBlock(true);
        await axios
            .post('/works/uploadMatListInExcel', {
                id: trueWork?.id,
                type: 7
            })
            .then((res) => {
                window.open(`${process.env.REACT_APP_API_BASE_URL}${res.data.path}`);
            })
            .finally(() => {
                setIsDemontageButtonBlock(false);
            });
    };

    const onUpdateScans = useCallback((newScans: any) => {
        setScans(newScans);
    }, []);

    const getScans = () => {
        axios.get(`/work-scans/${trueWork?.id}`).then((response) => {
            if (response.data.data[0]?.data[0]) {
                // setScansDate(new Date(response.data.data[0].created_at).toLocaleString('ru-RU'));
                setScans(response.data.data);
            }
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (trueWork) getScans();
    }, [trueWork]);

    const getWork = () => {
        setIsLoading(true);
        siteService
            .detail(id!)
            .then((res) => {
                setIsLoading(false);
                setWork(res.data.data);

                axios
                    .get(`/works/${res.data.data.rolloutWork}`)
                    .then((response) => {
                        setTrueWork(response.data.data);

                        const parsedPhotos: { [key: string]: any } = {};

                        if (response.data.data?.rsd[0]) {
                            setRsd(response.data.data?.rsd[0]);
                        }
                        if (response.data.data?.images?.esigns) {
                            const photosArray = response.data.data?.images?.esigns;
                            photosArray.forEach((photo: any) => {
                                const date = new Date(photo.date || photo.published_at).toLocaleDateString(
                                    'ru-RU'
                                );
                                if (!parsedPhotos[date]) {
                                    parsedPhotos[date] = [];
                                }
                                parsedPhotos[date] = [...parsedPhotos[date], photo];
                            });

                            const treeData: any = [];
                            Object.keys(parsedPhotos)
                                .slice(0)
                                .reverse()
                                .forEach((key) => {
                                    treeData.push({
                                        title: key,
                                        key,
                                        children: [
                                            {
                                                title: () => (
                                                    <Image.PreviewGroup>
                                                        {parsedPhotos[key].map((image: any) => (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column'
                                                                }}
                                                            >
                                                                <Image
                                                                    key={image.doc_link}
                                                                    width={200}
                                                                    src={
                                                                        image?.from_sad
                                                                            ? `https://sad.tele2.kz/tele2${image.doc_link.replace(
                                                                                  '.',
                                                                                  '',
                                                                                  1
                                                                              )}`
                                                                            : `${process.env.REACT_APP_API_BASE_URL}${image.doc_link}`
                                                                    }
                                                                    style={{ paddingRight: '6px' }}
                                                                />
                                                                {image.file_name.slice(0, 20)}...
                                                            </div>
                                                        ))}
                                                    </Image.PreviewGroup>
                                                ),
                                                isLeaf: true
                                            }
                                        ]
                                    });
                                });
                            setPhotosTree(treeData);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    useEffect(() => {
        getWork();
    }, []);

    const updateWork = () => {
        getWork();
    };

    const toast = useToast();

    const onSave = useCallback((data: any) => {
        axios
            .patch('/work-scans', data)
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Изменения успешно сохранены'
                });
                getScans();
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: 'Произошла ошибка при сохранении'
                });
                console.error(err);
            });
    }, []);

    const [isPhotoDecisionHistoryModalOpened, setIsPhotoDecisionHistoryModalOpened] = useState(false);

    const matListDepartments = [
        {
            key: 'oe',
            label: 'Отдел эксплуатации'
        }
    ];

    const openPhotoHistoryModal = () => {
        Modal.info({
            width: '50vw',
            title: 'История согласования фотографий',
            visible: isPhotoDecisionHistoryModalOpened,
            content: (
                <div>
                    {trueWork.s_bs_002_main_comments ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: trueWork.s_bs_002_main_comments
                            }}
                        />
                    ) : (
                        <span> Комментарии отсутствуют </span>
                    )}
                </div>
            ),
            onOk() {
                setIsPhotoDecisionHistoryModalOpened(false);
            }
        });
    };

    const [isScansDecisionHistoryModalOpened, setIsScansDecisionHistoryModalOpened] = useState(false);

    const openScansHistoryModal = () => {
        Modal.info({
            width: '50vw',
            title: 'История согласования сканов',
            visible: isScansDecisionHistoryModalOpened,
            content: (
                <div>
                    {trueWork.s_bs_002_main_mat_list_comments ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: trueWork.s_bs_002_main_mat_list_comments
                            }}
                        />
                    ) : (
                        <span> Комментарии отсутствуют </span>
                    )}
                </div>
            ),
            onOk() {
                setIsScansDecisionHistoryModalOpened(false);
            }
        });
    };
    const finishScansWork = (data: any) => {
        axios
            .post('/process-instances/startScansApprove', { work_id: data })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'ЭАП отправлен на согласование'
                });
                getWork();
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: err.response.data.message
                        ? err.response.data.message
                        : 'Произошла неизвестная ошибка'
                });
                console.error(err);
            });
    };

    const TABS = [
        {
            label: 'SAR',
            value: 'sar',
            component: work ? <WorkRltSAR work={work} updateWork={updateWork} /> : null
        },
        {
            label: 'AOK',
            value: 'aok',
            component: <WorkRltAOK work={work} updateWork={updateWork} />
        },
        {
            label: 'Договор аренды (SL)',
            value: 'sl',
            component: <WorkRltSL work={work} updateWork={updateWork} />
        },
        {
            label: 'TSSR',
            value: 'tssr',
            component: <WorkRltTssr work={work} updateWork={updateWork} rsd={rsd} />
        },
        {
            label: 'АФУ отчет',
            value: 'afu',
            component: <WorkRltAFU />
        },
        {
            label: 'АМ/АД',
            value: 'amad',
            component: (
                <Box m={2}>
                    <h2
                        style={{
                            fontSize: '24px',
                            textAlign: 'center',
                            marginBottom: 14
                        }}
                    >
                        Статус согласования фотографий
                    </h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Box display="flex" flexDir="row" margin="auto">
                            <HStack spacing="6px">
                                <Badge
                                    style={
                                        trueWork?.s_bs_002_main_maint_appr !== null
                                            ? trueWork?.s_bs_002_main_maint_appr?.status === false
                                                ? { backgroundColor: 'red' }
                                                : { backgroundColor: '#52c41a' }
                                            : { backgroundColor: '#3FCBFF' }
                                    }
                                    count={
                                        trueWork?.s_bs_002_main_maint_appr !== null
                                            ? trueWork?.s_bs_002_main_maint_appr?.status === true
                                                ? `Отдел эксплуатации: Согласовано | Дата: ${new Date(
                                                      trueWork?.s_bs_002_main_maint_appr?.date
                                                  ).toLocaleString('ru-RU')}`
                                                : `Отдел эксплуатации: Отказано | Дата: ${new Date(
                                                      trueWork?.s_bs_002_main_maint_appr?.date
                                                  ).toLocaleString('ru-RU')}`
                                            : `Отдел эксплуатации: На рассмотрении`
                                    }
                                />
                            </HStack>
                        </Box>
                    </div>
                    <div>
                        <Box as="button" display="flex" flexDir="row" margin="auto" mt="12px">
                            <Button
                                colorScheme="purple"
                                size="sm"
                                width="280px"
                                onClick={() => {
                                    openPhotoHistoryModal();
                                }}
                            >
                                <ViewIcon marginRight={1} />
                                Просмотреть историю решений
                            </Button>
                        </Box>
                        {photosTree && (
                            <>
                                <span> Фотографии </span>
                                <Tree.DirectoryTree selectable={false} treeData={photosTree} />
                            </>
                        )}
                        <br />
                    </div>
                    <>
                        <Divider style={{ background: '#1F2229' }} />
                    </>
                    <h2
                        style={{
                            fontSize: '24px',
                            textAlign: 'center',
                            marginBottom: 14,
                            marginTop: 14
                        }}
                    >
                        Документы приемки
                    </h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Box display="flex" flexDir="row" margin="auto">
                            {trueWork ? (
                                <HStack spacing="6px">
                                    {matListDepartments?.map((matListDepartment) => (
                                        <Badge
                                            style={
                                                trueWork[`scans_${matListDepartment?.key}_approve`] !== null
                                                    ? trueWork[`scans_${matListDepartment?.key}_approve`]
                                                          ?.status === false
                                                        ? { backgroundColor: 'red' }
                                                        : {
                                                              backgroundColor: '#52c41a'
                                                          }
                                                    : { backgroundColor: '#3FCBFF' }
                                            }
                                            count={
                                                trueWork[`scans_${matListDepartment?.key}_approve`] !== null
                                                    ? trueWork[`scans_${matListDepartment?.key}_approve`]
                                                          ?.status === true
                                                        ? `${
                                                              matListDepartment?.label
                                                          }: Согласовано | Дата: ${new Date(
                                                              trueWork[
                                                                  `scans_${matListDepartment?.key}_approve`
                                                              ]?.date
                                                          ).toLocaleString('ru-RU')}`
                                                        : `${
                                                              matListDepartment?.label
                                                          }: Отказано | Дата: ${new Date(
                                                              trueWork[
                                                                  `scans_${matListDepartment?.key}_approve`
                                                              ]?.date
                                                          ).toLocaleString('ru-RU')}`
                                                    : `${matListDepartment?.label}: На рассмотрении`
                                            }
                                        />
                                    ))}
                                    <Badge
                                        style={
                                            trueWork.mat_lists_ol_approve !== null
                                                ? trueWork.mat_lists_ol_approve?.status === false
                                                    ? { backgroundColor: 'red' }
                                                    : { backgroundColor: '#52c41a' }
                                                : { backgroundColor: '#3FCBFF' }
                                        }
                                        count={
                                            trueWork.mat_lists_ol_approve !== null
                                                ? trueWork.mat_lists_ol_approve?.status === true
                                                    ? `Отдел логистики: Согласовано | Дата: ${new Date(
                                                          trueWork.mat_lists_ol_approve?.date
                                                      ).toLocaleString('ru-RU')}`
                                                    : `Отдел логистики: Отказано | Дата: ${new Date(
                                                          trueWork.mat_lists_ol_approve?.date
                                                      ).toLocaleString('ru-RU')}`
                                                : trueWork.s_bs_002_main_status === 5
                                                ? `Отдел логистики: На рассмотрении`
                                                : `Отдел логистики: Ожидает подписи ГПО`
                                        }
                                    />
                                </HStack>
                            ) : null}
                        </Box>
                    </div>
                    <div>
                        <Box as="button" display="flex" flexDir="column" margin="auto">
                            <Button
                                marginTop="12px"
                                colorScheme="purple"
                                size="sm"
                                width="280px"
                                onClick={() => {
                                    openScansHistoryModal();
                                }}
                            >
                                <ViewIcon marginRight={1} />
                                Просмотреть историю решений
                            </Button>
                            <Button
                                marginTop="12px"
                                colorScheme="whatsapp"
                                size="sm"
                                width="280px"
                                onClick={() => {
                                    finishScansWork(trueWork.id);
                                }}
                                disabled={trueWork?.scans_status >= 1}
                                isLoading={isLoading}
                            >
                                <CheckIcon marginRight={1} marginBottom="2px" />
                                Завершить АП
                            </Button>
                        </Box>
                        {trueWork && scans.length ? (
                            <div>
                                <HStack spacing={2} marginBottom={1}>
                                    <Button
                                        colorScheme="purple"
                                        size="sm"
                                        width="180px"
                                        disabled={isMontageButtonBlock}
                                        onClick={getExcelMontageMatList}
                                    >
                                        <DownloadIcon marginRight={1} marginBottom="2px" />
                                        Акт Монтажа
                                    </Button>
                                    <Button
                                        colorScheme="purple"
                                        size="sm"
                                        width="180px"
                                        disabled={isDemontageButtonBlock}
                                        onClick={getExcelDemontageMatList}
                                    >
                                        <DownloadIcon marginRight={1} marginBottom="2px" />
                                        Акт Демонтажа
                                    </Button>
                                </HStack>
                                <WorkScans
                                    scans={scans}
                                    onUpdateScans={onUpdateScans}
                                    onSave={onSave}
                                    work={trueWork}
                                />
                            </div>
                        ) : null}
                    </div>
                </Box>
            )
        }
    ];

    return (
        <Box bgColor="white" borderRadius={10} p={2}>
            <LoadingOverlay
                active={isLoading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        height: '100%'
                    })
                }}
            >
                <Box>
                    <Tabs isFitted mb={2} onChange={(index) => onChangeTab(TABS[index].value)}>
                        <TabList borderColor="gray.200">
                            {TABS.map((tab) => (
                                <Tab
                                    sx={{
                                        paddingInline: '1rem',
                                        paddingY: '0.5rem',
                                        _focus: {
                                            boxShadow: 'none'
                                        }
                                    }}
                                    key={tab.value}
                                >
                                    {tab.label}
                                </Tab>
                            ))}
                        </TabList>
                    </Tabs>

                    {TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                    })}
                </Box>
            </LoadingOverlay>
        </Box>
    );
}
