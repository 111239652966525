import { DownloadIcon } from '@chakra-ui/icons';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Box,
    Button,
    useToast,
    VStack
} from '@chakra-ui/react';
import { Image, Modal } from 'antd';
import axios from 'axios';
import { UploadMultiFile } from 'components/upload';
import { useEffect, useState } from 'react';

interface ObserveImagesProps {
    work: any;
    updateWork: () => void;
    user: any;
}

export default function ObserveImages({ work, updateWork, user }: ObserveImagesProps) {
    const [observingImages, setObservingImages] = useState<{ [key: string]: any }>({});

    const observeImagesFieldNames = [
        { name: 'Общий вид', field: 'observe_images' },
        { name: 'Доп. Данные', field: 'observe_images_additional' },
        { name: 'АФУ', field: 'observe_images_afu' },
        { name: 'Кабинет', field: 'observe_images_cab' },
        { name: 'Заземление', field: 'observe_images_ground' },
        { name: 'Питание', field: 'observe_images_power' },
        { name: 'Трасса', field: 'observe_images_tr' }
    ];

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    useEffect(() => {
        const updatedObservingImages: { [key: string]: any } = {};
        observeImagesFieldNames.forEach(({ field }) => {
            if (work[field] != null && Array.isArray(work[field].esigns)) {
                updatedObservingImages[field] = work[field].esigns;
            }
        });
        setObservingImages(updatedObservingImages);
    }, [work]);

    const [isLoading, setIsLoading] = useState(false);
    const [uploadPhotos, setUploadPhotos] = useState<any[]>([]);
    const [isObserveImagesModalOpened, setIsObserveImagesModalOpened] = useState(false);
    const [lastSelectedField, setLastSelectedField] = useState('');

    const handleUpload = async (field: string) => {
        for (let i = 0; i < uploadPhotos.length; i += 1) {
            const data = new FormData();
            data.append('field', field);
            data.append('work_id', work.id);
            data.append('file', uploadPhotos[i]);

            setIsLoading(true);
            // eslint-disable-next-line no-await-in-loop
            await axios
                .post('/works/upload', data)
                .then(() => {
                    toast({
                        status: 'success',
                        title: 'Фото успешно загружены'
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    toast({
                        status: 'error',
                        title: err.response.data.message
                            ? err.response.data.message
                            : 'Произошла неизвестная ошибка'
                    });
                    setIsLoading(false);
                });
        }
        setUploadPhotos([]);
        updateWork();
    };
    const handleRemoveObserveImage = (file: File) => {
        const filteredItems = [...uploadPhotos].filter((_file) => _file !== file);
        setUploadPhotos(filteredItems);
    };

    return (
        <>
            {observeImagesFieldNames.map(({ name, field }) => (
                <Box justifyContent="space-between" flexDirection="row" flexWrap="wrap" mt={2}>
                    <VStack spacing={2}>
                        <Accordion allowToggle>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            {name}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Image.PreviewGroup>
                                        {observingImages[field]?.map((photo: any) => (
                                            <Box mt={1}>
                                                <Image
                                                    key={photo.doc_link}
                                                    width={200}
                                                    src={`${process.env.REACT_APP_API_BASE_URL}${photo.doc_link}`}
                                                    style={{ paddingRight: '6px' }}
                                                />
                                                <span>{photo.file_name.slice(0, 20)}...</span>
                                            </Box>
                                        ))}
                                    </Image.PreviewGroup>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                        <VStack spacing={1} ml={4}>
                            {user.role.name === 'Подрядная организация' && (
                                <Box>
                                    <Button
                                        colorScheme="purple"
                                        size="sm"
                                        width="fit-content"
                                        rightIcon={<DownloadIcon marginRight={1} marginBottom="2px" />}
                                        onClick={() => {
                                            setIsObserveImagesModalOpened(true);
                                            setLastSelectedField(field);
                                        }}
                                    >
                                        Загрузить фото по {name}
                                    </Button>
                                </Box>
                            )}
                        </VStack>
                    </VStack>
                </Box>
            ))}
            <Modal
                title="Загрузка предварительных фото"
                visible={isObserveImagesModalOpened}
                confirmLoading={isLoading}
                onCancel={() => setIsObserveImagesModalOpened(false)}
                onOk={() => {
                    handleUpload(lastSelectedField);
                }}
            >
                <UploadMultiFile
                    files={uploadPhotos}
                    onRemove={handleRemoveObserveImage}
                    accept={{
                        'image/png': ['.png'],
                        'image/jpeg': ['.jpeg', '.jpg']
                    }}
                    onDrop={(uploadPhotos) => {
                        setUploadPhotos(uploadPhotos);
                    }}
                    maxSize={51200000}
                />
            </Modal>
        </>
    );
}
