/* eslint-disable no-use-before-define */
import { useState } from 'react';
import _ from 'lodash';
// @chakra-ui
import { Badge, Stack, Heading, Button, useToast, HStack } from '@chakra-ui/react';
// services
import siteService from 'api/services/site-service';

// ----------------------------------------------------------------------

interface WorkRltSARStatusProps {
    updateWork: () => void;
    work: any;
}

export default function WorkRltSARStatus({ work, updateWork }: WorkRltSARStatusProps) {
    const [isLoading, setIsLoading] = useState(false);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const { s_candidate_status, siteCandidateLeasingApproveStatus } = work;

    const leasingField = getSARstatus(siteCandidateLeasingApproveStatus, s_candidate_status);

    const { color, text } = getStatus(s_candidate_status);

    const disableStart = !work.s_candidate_recieved?.esigns?.length;

    const handleStart = () => {
        setIsLoading(true);
        siteService
            .startProcess({
                id: work.id,
                type: 'sar'
            })
            .then(() => {
                setIsLoading(false);
                toast({
                    title: 'Процесс согласования успешно запущен',
                    status: 'success'
                });
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
                setIsLoading(false);
            });
    };

    const handleStop = () => {
        setIsLoading(true);
        siteService
            .resetProcess({
                id: work.id,
                type: 'sar'
            })
            .then(() => {
                setIsLoading(false);
                toast({
                    title: 'Процесс согласования успешно остановлен',
                    status: 'success'
                });
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
                setIsLoading(false);
            });
    };

    return (
        <>
            <Stack p={1.5} alignItems="center">
                {_.includes([null, -1], s_candidate_status) ? (
                    <Button
                        disabled={isLoading || disableStart}
                        isLoading={isLoading}
                        colorScheme="purple"
                        onClick={handleStart}
                        variant="outline"
                    >
                        Начать процесс
                    </Button>
                ) : (
                    <Button
                        colorScheme="red"
                        variant="outline"
                        onClick={handleStop}
                        isLoading={isLoading}
                        disabled={isLoading}
                    >
                        Остановить процесс
                    </Button>
                )}

                <Heading as="h4" fontSize="md">
                    Статус согласования
                </Heading>
                <Badge colorScheme={color} fontSize="0.8em">
                    {text}
                </Badge>
                <HStack flexDirection="row">
                    <HStack flexDirection="column">
                        <Heading as="h4" fontSize="md">
                            Leasing
                        </Heading>
                        <Badge colorScheme={leasingField.sarStatusColor} fontSize="0.8em">
                            {leasingField.sarStatusText}
                        </Badge>
                    </HStack>
                </HStack>
            </Stack>
        </>
    );
}

function getStatus(status: number | null) {
    let color;
    let text;

    if (status === null) {
        color = 'gray';
        text = 'Ожидает начало процесса';
    } else if (status === 1) {
        color = 'yellow';
        text = 'На рассмотрении';
    } else if (status === 2) {
        color = 'green';
        text = 'Согласовано';
    } else {
        color = 'red';
        text = 'Отклонено';
    }

    return {
        color,
        text
    };
}

function getSARstatus(status: number | null, sarMainStatus: number | null) {
    let sarStatusColor;
    let sarStatusText;

    if (sarMainStatus != null) {
        if (status === null) {
            sarStatusColor = 'yellow';
            sarStatusText = 'На рассмотрении';
        } else if (status === 1) {
            sarStatusColor = 'green';
            sarStatusText = 'Согласовано';
        } else if (status === 2) {
            sarStatusColor = 'red';
            sarStatusText = 'Отклонено';
        }
    } else {
        sarStatusColor = 'gray';
        sarStatusText = 'Ожидает начало процесса';
    }

    return {
        sarStatusColor,
        sarStatusText
    };
}
