import { FC, SVGProps } from 'react';

export const Tele2Logo: FC<SVGProps<SVGSVGElement>> = () => (
    <svg
        width="60"
        height="20"
        viewBox="-2.554907 -2.554907 141.67997 47.26355"
        style={{ minWidth: '60px', maxWidth: '60px', maxHeight: '20px' }}
        id="svg14582"
    >
        <defs id="defs14584" />
        <g transform="translate(-97.873361,-24.50665)" id="g2992">
            <path
                d="m 180.86548,47.84668 c 0,-0.65918 0.52734,-7.011719 7.52441,-7.011719 4.63868,0 6.31348,2.626954 7.19239,7.011719 l -14.7168,0 z m 7.77344,-13.833007 c -10.41993,0 -16.82618,7.910156 -16.82618,17.387695 0,8.188475 4.69239,17.270512 16.82618,17.270512 11.01073,0 14.14062,-7.065438 15.41503,-11.015625 l -5.74218,0 c -1.32324,0 -2.28515,0.522463 -3.18359,1.63085 -0.37598,0.429687 -2.29981,2.631837 -6.167,2.631837 -7.83203,0 -7.94434,-6.215812 -8.0957,-8.339839 l 23.76465,0 c 0.66894,-9.541017 -4.375,-19.56543 -15.99121,-19.56543"
                id="path14455"
                style={{ fill: '#5c3887', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            />
            <path
                d="m 210.23559,22.509766 0,37.065427 c 0.0684,6.245125 1.21583,8.413087 8.99415,8.413087 l -0.005,-38.168944 c -0.0635,-6.142578 -1.25488,-7.30957 -8.98926,-7.30957"
                id="path14457"
                style={{ fill: '#5c3887', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            />
            <path
                d="m 227.69653,22.509766 0,37.065427 c 0.0684,6.245125 1.22071,8.413087 8.99415,8.413087 l -0.01,-38.168944 c -0.0586,-6.142578 -1.24511,-7.30957 -8.98437,-7.30957"
                id="path14459"
                style={{ fill: '#5c3887', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            />
            <path
                d="m 161.91528,55.23438 c 0,0 -2.06054,-0.07325 -2.85156,2.094725 -1.12304,3.085938 -3.17872,4.589837 -6.67969,4.589837 -5.44434,0 -7.51464,-5.185537 -7.51464,-10.512692 0,-5.058594 2.1289,-10.571289 7.70995,-10.571289 3.48145,0 5.32715,1.557618 6.11818,3.579101 0.42968,1.098633 1.06932,2.094728 3.0664,2.094728 l 6.3623,0 c -0.4834,-8.50586 -7.79785,-12.495117 -15.49317,-12.495117 -11.33788,0 -16.80663,8.222656 -16.80663,17.783202 0,10.70313 7.42676,16.875005 16.61621,16.875005 8.03711,0 14.60449,-4.033213 15.86425,-13.4375 l -6.3916,0 z"
                id="path14461"
                style={{ fill: '#5c3887', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            />
            <path
                d="m 95.626216,67.98828 9.960934,0 0,-14.643553 5.72754,-5.805665 c 0,0 5.53224,8.217781 9.24805,13.818355 3.73048,5.585938 5.51758,6.630863 12.17774,6.630863 l 4.8291,0 -19.4873,-27.51953 17.76366,-17.958984 -4.86816,0 c -4.71191,0 -7.76366,0.170899 -11.50879,4.135742 -3.76465,3.950196 -13.88184,14.72168 -13.88184,14.72168 l 0,-10.014648 c -0.0146,-6.59668 -1.14257,-8.857422 -9.960934,-8.857422 l 0,45.493162 z"
                id="path14463"
                style={{ fill: '#5c3887', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            />
        </g>
    </svg>
);

export default Tele2Logo;
