import { ENGINEERS_GROUP_ROLES, ERoleNames } from '../../../constants';
import { EAcceptanceDocsStatuses, EAcceptanceDocsTypes } from './enums';
import { IOrderAcceptanceDocs } from './interfaces/OrderAcceptance';

// eslint-disable-next-line import/prefer-default-export
export const orderAcceptanceAccesses: {
    finishWork: (docs: IOrderAcceptanceDocs) => ERoleNames[];
    docs: { [key in EAcceptanceDocsTypes]: { [key: string]: ERoleNames[] } };
} = {
    docs: {
        techSupervisionAct: {
            upload: [ERoleNames.SUBC, ERoleNames.EXECUTOR],
            resolve: ENGINEERS_GROUP_ROLES
        },
        techAct: {
            upload: ENGINEERS_GROUP_ROLES,
            resolve: [ERoleNames.OS, ERoleNames.ADMIN_OE],
            editDetail: [ERoleNames.OS, ERoleNames.ADMIN_OE]
        },
        materialsReport: {
            upload: [ERoleNames.SUBC, ERoleNames.EXECUTOR],
            resolve: [ERoleNames.KO],
            changeRequired: [ERoleNames.OS, ERoleNames.ADMIN_OE]
        },
        commissioningCert: {
            upload: [ERoleNames.OS, ERoleNames.ADMIN_OE, ERoleNames.TR, ERoleNames.OE, ERoleNames.KO],
            resolve: [ERoleNames.OS, ERoleNames.ADMIN_OE, ERoleNames.TR, ERoleNames.KO, ERoleNames.OE],
            changeRequired: [ERoleNames.OS, ERoleNames.ADMIN_OE]
        }
    },
    finishWork: (docs) => {
        const docStatus = {
            materialsReport: {
                required: docs.materialsReport?.isRequired,
                confirmed: docs.materialsReport?.status === EAcceptanceDocsStatuses.confirmed
            },
            techSupervisionAct: {
                required: docs.techSupervisionAct?.isRequired,
                confirmed: docs.techSupervisionAct?.status === EAcceptanceDocsStatuses.confirmed
            },
            techAct: {
                required: docs.techAct?.doc?.isRequired,
                confirmed: docs.techAct?.doc?.status === EAcceptanceDocsStatuses.confirmed
            },
            commissioningCert: {
                required: docs.commissioningCert?.doc?.isRequired,
                confirmed: docs.commissioningCert?.doc?.status === EAcceptanceDocsStatuses.confirmed
            }
        };

        const isConfirmedRequiredDocs = Object.values(docStatus)
            .filter((doc) => doc.required)
            .every((doc) => doc.confirmed);
        return isConfirmedRequiredDocs ? [ERoleNames.OS, ERoleNames.ADMIN_OE] : [];
    }
};
