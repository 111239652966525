// @ts-nocheck

import React, { ChangeEventHandler, useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useSelector } from 'react-redux';
// @chakra-ui
import {
    Box,
    Button,
    Fade,
    useToast,
    Stack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input as ChakraInput,
    useDisclosure,
    Heading,
    Textarea
} from '@chakra-ui/react';
// antd
import { Select } from 'antd';
// hooks
import useHeaderTitle from 'hooks/useHeaderTitle';
// redux
import { userSelector } from 'redux/reducers/userSlice';
import useDebounce from 'hooks/useDebounce';
import { IProject, IWorkForm } from '../../interfaces/works';
import { ISite } from '../../interfaces/sites';
import SiteForm from '../Sites/components/SiteForm';

// ----------------------------------------------------------------------

const formLabelStyle = {
    fontSize: '1rem',
    color: '#1A202C',
    marginBottom: '0.5rem'
};

const initialValues: IWorkForm = {
    id: '',
    project: '',
    plannedHW: '',
    plannedBand: '',
    workType: '',
    optionalSitename: '',
    site: {
        sitename: '',
        kato: '',
        createdSiteId: '',
        createdSiteLat: '',
        createdSiteLng: '',
        createdSiteReg: ''
    }
};

export default function CreateWork() {
    const [siteOptions, setSiteOptions] = useState<
        { id: number; s_new_site_id: string; isNew?: boolean; sitename?: string }[]
    >([]);
    const [projectOptions, setProjectOptions] = useState<IProject[]>([]);

    const [workTypeOptions, setWorkTypeOptions] = useState<any>([]);
    const [isNewSite, setIsNewSite] = useState(false);

    const { role } = useSelector(userSelector);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [siteIdSearchText, setSiteIdSearchText] = useState<string>('');
    const debouncedSiteIdSearchText = useDebounce(siteIdSearchText, 700);

    useHeaderTitle('Создание ЭАП');

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const resetOptions = () => {
        setSiteOptions([]);
    };
    const getValidationSchema = (workType) => {
        const schema = {
            site: Yup.object().shape({
                createdSiteId: Yup.string().required('Поле обязательное'),
                sitename: Yup.string().required('Поле обязательное')
            }),
            optionalSitename: Yup.string(),
            workType: Yup.string().required('Поле обязательное'),
            project: Yup.string().required('Поле обязательное')
        };

        if (workType === 'NCP' && !isNewSite) {
            schema.optionalSitename = Yup.string().required('Поле обязательное для NCP');
        }

        return Yup.object().shape(schema);
    };

    const validate = (values) => {
        const validationSchema = getValidationSchema(values.workType);
        try {
            validationSchema.validateSync(values, { abortEarly: false });
            return {};
        } catch (error) {
            return error.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message;
                return acc;
            }, {});
        }
    };

    const formik = useFormik({
        initialValues,
        validate,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            axios
                .post('/works/createWork', values)
                .then(() => {
                    toast({
                        status: 'success',
                        title: `ЭАП был успешно создан`
                    });
                    resetForm();
                    resetOptions();
                    setSubmitting(false);
                })
                .catch((error) => {
                    toast({
                        status: 'error',
                        title: error.response?.data?.message || 'При создании ЭАП произошла ошибка'
                    });
                    console.error(error);
                    setSubmitting(false);
                });
        }
    });

    const siteExists = (sites: ISite[], searchText: string) => {
        const site = sites.find((site) => site.s_new_site_id === searchText);

        if (!site) return false;
        return true;
    };

    const handleSearchSiteOptions = (searchText: string) => {
        if (!searchText) {
            setSiteOptions([]);
            return;
        }
        axios.get(`/sitesName?site=${searchText}`).then((res) => {
            let sites = res.data;
            if (!siteExists(sites, searchText)) {
                sites = [{ s_new_site_id: searchText, isNew: true, id: 1 }, ...sites];
            }
            setSiteOptions(sites);
        });
    };
    const getProjects = () => {
        axios
            .get(`/projects`)
            .then((res) => {
                setProjectOptions(res.data);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    useEffect(() => {
        handleSearchSiteOptions(debouncedSiteIdSearchText);
    }, [debouncedSiteIdSearchText]);

    const getWorkTypes = () => {
        axios
            .get('/works/dicrinary?limit=2000')
            .then((res) => {
                setWorkTypeOptions(res.data.work_type);
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        getWorkTypes();
        getProjects();
    }, []);
    const handleSiteIdSelect = (value: string) => {
        const siteId = value.split('+')[0];
        const trueId = value.split('+')[1];
        if (trueId) {
            axios
                .get(`/sites/${trueId}`)
                .then((res) => {
                    formik.setValues({
                        ...formik.values,
                        site: {
                            createdSiteId: siteId,
                            sitename: res.data.data.sitename,
                            kato: res.data.data.kato?.katoId || '',
                            createdSiteLat: res.data.data.s_lat,
                            createdSiteLng: res.data.data.s_lng,
                            createdSiteReg: res.data.data.s_reg
                        }
                    });
                    setIsNewSite(false);
                })
                .catch((err) => console.error(err));
        } else {
            formik.setFieldValue('site', {
                sitename: '',
                kato: '',
                createdSiteLat: '',
                createdSiteLng: '',
                createdSiteReg: '',
                createdSiteId: siteId
            });
            setIsNewSite(true);
        }
    };

    const siteChangeHandler: ChangeEventHandler<HTMLFormElement> = (e) => {
        formik.setFieldValue('site', { ...formik.values.site, [e.target.name]: e.target.value });
    };
    const setSiteFieldValueHandler = (fieldName: string, value: string) => {
        formik.setFieldValue('site', { ...formik.values.site, [fieldName]: value });
    };

    return (
        <Box bg="white" maxWidth="500px" width="400px" marginX="auto" borderRadius={10} p={3}>
            <Fade in style={{ display: 'flex', width: '100%', height: '100%' }}>
                <Box width="100%" display="flex" flexDir="column">
                    <form onSubmit={formik.handleSubmit}>
                        <Heading as="h4" size="md" mb={3}>
                            Данные по новому ЭАП
                        </Heading>
                        <Stack spacing={2} width="100%">
                            <FormControl
                                isRequired
                                isInvalid={
                                    !!formik.errors.site?.createdSiteId &&
                                    (formik.touched.site?.createdSiteId as boolean)
                                }
                            >
                                <FormLabel style={formLabelStyle}>ID сайта</FormLabel>
                                <Select
                                    placeholder="Введите для поиска"
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    showSearch
                                    value={formik.values.site?.createdSiteId}
                                    onSearch={(value) => {
                                        setSiteIdSearchText(value);
                                    }}
                                    onChange={handleSiteIdSelect}
                                    optionLabelProp="value"
                                >
                                    {siteOptions.map((opt) => {
                                        console.log('opt', opt);
                                        console.log(opt.sitename);

                                        if (opt.isNew) {
                                            return (
                                                <Select.Option
                                                    value={opt.s_new_site_id}
                                                    key={opt.s_new_site_id}
                                                >
                                                    {opt.s_new_site_id}{' '}
                                                    <span style={{ color: 'red' }}>новый!</span>
                                                </Select.Option>
                                            );
                                        }
                                        return (
                                            <Select.Option
                                                value={`${opt.s_new_site_id}+${opt.id}`}
                                                key={opt.s_new_site_id + opt.id}
                                            >
                                                {opt.s_new_site_id} {opt.sitename ? ` (${opt.sitename})` : ``}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                                <FormErrorMessage mt="0.5rem">
                                    {formik.errors.site?.createdSiteId}
                                </FormErrorMessage>
                            </FormControl>
                            {formik.values.site.createdSiteId && (
                                <>
                                    {isNewSite ? (
                                        <SiteForm
                                            hideIdField
                                            values={formik.values.site}
                                            onChange={siteChangeHandler}
                                            errors={formik.errors.site || {}}
                                            touched={formik.touched.site || {}}
                                            isSubmitting={formik.isSubmitting}
                                            setFieldValue={setSiteFieldValueHandler}
                                        />
                                    ) : (
                                        <Box>
                                            <div>Название: {formik.values.site.sitename || 'Не указано'}</div>
                                            <div>
                                                Широта: {formik.values.site.createdSiteLat || 'Не указана'}
                                            </div>
                                            <div>
                                                Долгота: {formik.values.site.createdSiteLng || 'Не указана'}
                                            </div>
                                            <div>КАТО: {formik.values.site.kato || 'Не указано'}</div>
                                            <div>
                                                Регион: {formik.values.site.createdSiteReg || 'Не указан'}
                                            </div>
                                        </Box>
                                    )}
                                </>
                            )}
                            <FormControl
                                isRequired
                                isInvalid={!!formik.errors.workType && (formik.touched.workType as boolean)}
                            >
                                <FormLabel style={formLabelStyle}>Тип ЭАП</FormLabel>
                                <Select
                                    style={{ width: '100%', marginTop: '4px' }}
                                    showSearch
                                    value={formik.values.workType}
                                    onChange={(value) => formik.setFieldValue('workType', value)}
                                >
                                    {workTypeOptions.map((type: string, idx: number) => (
                                        <Select.Option value={type} key={idx}>
                                            {type}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <FormErrorMessage mt="0.5rem">{formik.errors.workType}</FormErrorMessage>
                            </FormControl>
                            {!isNewSite && formik.values.workType === 'NCP' && (
                                <FormControl
                                    isInvalid={
                                        !!formik.errors.optionalSitename &&
                                        (formik.touched.optionalSitename as boolean)
                                    }
                                >
                                    <FormLabel style={formLabelStyle}>Sitename ЭАП</FormLabel>
                                    <ChakraInput
                                        value={formik.values.optionalSitename}
                                        onChange={(e) =>
                                            formik.setFieldValue('optionalSitename', e.target.value)
                                        }
                                    />
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.optionalSitename}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                            <FormControl
                                isRequired
                                isInvalid={!!formik.errors.project && (formik.touched.project as boolean)}
                            >
                                <FormLabel style={formLabelStyle}>Проект</FormLabel>
                                <Select
                                    style={{ width: '100%', marginTop: '4px' }}
                                    showSearch
                                    value={formik.values.project}
                                    onChange={(value) => formik.setFieldValue('project', value)}
                                >
                                    {projectOptions.map((project: IProject, idx: number) => (
                                        <Select.Option value={project.id} key={idx}>
                                            {project.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <FormErrorMessage mt="0.5rem">{formik.errors.project}</FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <FormLabel style={formLabelStyle}>Planned HW</FormLabel>
                                <Textarea
                                    name="plannedHW"
                                    value={formik.values.plannedHW}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage mt="0.5rem">{formik.errors.workType}</FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <FormLabel style={formLabelStyle}>Planned band</FormLabel>
                                <Textarea
                                    name="plannedBand"
                                    value={formik.values.plannedBand}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                            {_.includes(
                                ['ADMIN', 'Отдел строительства', 'Отдел эксплуатации'],
                                role?.name
                            ) ? (
                                <Button
                                    mt="0.5rem"
                                    size="sm"
                                    onClick={() => onOpen()}
                                    variant="outline"
                                    colorScheme="purple"
                                >
                                    Создать тип ЭАП
                                </Button>
                            ) : null}
                        </Stack>
                        <Button
                            type="submit"
                            colorScheme="purple"
                            width="100%"
                            mt="1rem"
                            isLoading={formik.isSubmitting}
                            isDisabled={formik.isSubmitting}
                            loadingText="Создаем..."
                        >
                            Создать ЭАП
                        </Button>
                    </form>
                </Box>
            </Fade>
            <CreateWorkTypeModal isOpen={isOpen} onClose={onClose} getWorkTypes={getWorkTypes} />
        </Box>
    );
}

// ----------------------------------------------------------------------

function CreateWorkTypeModal({ onClose, isOpen, getWorkTypes }: any) {
    const [newWorkType, setNewWorkType] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const createWorkType = () => {
        setIsLoading(true);
        axios
            .post(`/work-types`, {
                name: newWorkType
            })
            .then(() => {
                toast({
                    status: 'success',
                    title: `Тип ЭАП "${newWorkType}" был успешно создан`
                });
                setIsLoading(false);
                onClose();
                getWorkTypes();
                setNewWorkType('');
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: err.response?.data?.message || 'При создании типа ЭАП произошла ошибка'
                });
                setIsLoading(false);
                console.error(err);
            });
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        createWorkType();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered autoFocus={false}>
            <ModalOverlay />
            <form onSubmit={handleSubmit}>
                <ModalContent>
                    <ModalHeader>Создать тип ЭАП</ModalHeader>
                    <ModalCloseButton top="0.5rem" right="0.75rem" />
                    <ModalBody>
                        <FormControl>
                            <FormLabel style={formLabelStyle}>Тип ЭАП</FormLabel>
                            <ChakraInput
                                value={newWorkType}
                                onChange={(e) => setNewWorkType(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose} mr="0.75rem">
                            Отмена
                        </Button>
                        <Button colorScheme="purple" type="submit" disabled={isLoading} isLoading={isLoading}>
                            Создать
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
}
