// @chakra-ui
import { Box, Divider } from '@chakra-ui/react';
// services
// components
import DecisionHistory from 'components/DecisionHistory';
//
import WorkRltSLFiles from './WorkRltSLFiles';
import WorkRltSLStatus from './WorkRltSLStatus';
// import WorkRltSLForm from './WorkRltSLForm';

// ----------------------------------------------------------------------

interface WorkRltSlProps {
    work: any;
    updateWork: () => void;
}

export default function WorkRltSL({ work, updateWork }: WorkRltSlProps) {
    return (
        <Box>
            {/* <WorkRltSLForm work={work} updateWork={updateWork} /> */}
            <Box px={1.5}>
                <Divider />
            </Box>
            <WorkRltSLFiles work={work} updateWork={updateWork} />
            <WorkRltSLStatus work={work} updateWork={updateWork} />
            <Box p={1.5}>
                <DecisionHistory history={work.s_sl_status_cmnt || ''} />
            </Box>
        </Box>
    );
}
