import { Box, Tab, TabList, Tabs } from '@chakra-ui/react';
import { Link, Outlet } from 'react-router-dom';
import useHeaderTitle from '../../hooks/useHeaderTitle';

const Reports = () => {
    useHeaderTitle('Отчеты');
    return (
        <Box bgColor="white" borderRadius={10} p={2}>
            <Tabs>
                <TabList>
                    <Tab as={Link} to="/reports/orders">
                        Заказы
                    </Tab>
                    <Tab as={Link} to="/reports/works">
                        ЭАП
                    </Tab>
                </TabList>
            </Tabs>
            <Box my={2}>
                <Outlet />
            </Box>
        </Box>
    );
};
export default Reports;
