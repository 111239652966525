import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalSliceState } from 'redux/reducers/globalSlice.types';
import { RootState } from 'redux/rootReducer.types';

const initialState: GlobalSliceState = {
    isSidebarOpened: true,
    currentHeaderTitle: 'Главная',
    worksSearchValue: '',
    authFallbackLink: ''
};

const global = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setIsSidebarOpened: (state, { payload }: PayloadAction<boolean>) => {
            state.isSidebarOpened = payload;
        },
        setCurrentHeaderTitle: (state, { payload }: PayloadAction<string>) => {
            state.currentHeaderTitle = payload;
        },
        setWorksSearchValue: (state, { payload }: PayloadAction<string>) => {
            state.worksSearchValue = payload;
        },
        setAuthFallbackLink: (state, { payload }: PayloadAction<string>) => {
            state.authFallbackLink = payload;
        }
    }
});

export const globalReducer = global.reducer;
export const globalSelector = (state: RootState) => state.global;
export const { setIsSidebarOpened, setCurrentHeaderTitle, setWorksSearchValue, setAuthFallbackLink } =
    global.actions;
