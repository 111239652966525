import { Link } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import { Flex, useToast, Text, Box, Link as ChakraLink } from '@chakra-ui/react';
import { Badge, Input, Table, Button, Modal, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Dragger from 'antd/lib/upload/Dragger';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import useSWR from 'swr';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { generateTitle } from '../utils';
import { fetcher } from '../utils/client';

const Signing = () => {
    const [searchInput, setSearchInput] = useState('');
    const [start, setStart] = useState<any>(0);
    const [pageSize, setPageSize] = useState<any>(50);
    const [isSignModalOpened, setIsSignModalOpened] = useState(false);
    const [isESignKeyModalOpened, setIsESignKeyModalOpened] = useState(false);
    const [loadedESign, setLoadedESign] = useState<any>(null);
    const [selectedGroupID, setSelectedGroupID] = useState<any>(null);
    const [eSignKey, setESignKey] = useState('');
    const [isSignPending, setIsSignPending] = useState(false);
    const [isCertInfoModalOpened, setIsCertInfoModalOpened] = useState(false);
    const [responsible, setResponsible] = useState<null | string>(null);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    useHeaderTitle(`Просмотр АП на подписание`);
    useTitle(generateTitle('Подписание документов'));

    const { data: responsibleData } = useSWR('/esign-groups/responsible', fetcher);

    const { data, mutate: mutateData } = useSWR(
        `/esign-groups?_limit=${pageSize}&_start=${start}${
            searchInput ? `&site_id_contains=${searchInput}` : ''
        }${responsible ? `&responsible=${responsible}` : ''} `,
        fetcher
    );

    const [works, setWorks] = useState<any>(null);
    const { data: esignCount, mutate: mutateEsignCount } = useSWR(
        `/esign-groups/countSigning${searchInput ? `?site_id_contains=${searchInput}` : ''}${
            responsible ? `${searchInput ? '&' : '?'}responsible=${responsible}` : ''
        }`,
        fetcher
    );
    const onPaginationChange = (pageNumber: number) => {
        setStart(pageNumber - 1);
    };
    const onPageSizeChange = (current: number, size: number) => {
        setPageSize(size);
    };

    const [certInfo, setCertInfo] = useState<any>(null);

    const onModalOk = async () => {
        const formData = new FormData();
        formData.append('file', loadedESign.file);
        formData.append('password', eSignKey);
        setIsSignPending(true);
        await axios
            .post('/esigns/createSignNew', formData)
            .then((result) => {
                setIsESignKeyModalOpened(false);
                setIsCertInfoModalOpened(true);
                setCertInfo(result.data);
            })
            .catch((e) => {
                console.error(e);
                toast({
                    status: 'error',
                    title: e.response.data.message
                });
            });
        setIsSignPending(false);
    };

    const signOk = async () => {
        setIsSignPending(true);
        await axios
            .post('/esigns/signDocs', {
                data: certInfo,
                id: selectedGroupID,
                cert: certInfo.cert,
                password: eSignKey
            })
            .then(() => {
                setIsCertInfoModalOpened(false);
                setIsSignPending(false);
                toast({
                    status: 'success',
                    title: 'Документ успешно подписан'
                });
                mutateData();
                mutateEsignCount();
            })
            .catch((e) => {
                console.error(e);
                toast({
                    status: 'error',
                    title: e.response.data.message
                });
            });
        setIsSignPending(false);
    };

    useEffect(() => {
        const newWorks: any = [];
        data?.forEach((item: any) => {
            if (item.esigns) {
                const newEsigns: any = [];
                item.esigns.forEach((sign: any) => {
                    newEsigns.push({ ...sign, ...item, id: nanoid(), group_id: item.id });
                });
                const newItem = {
                    ...item,
                    children: newEsigns
                };
                newWorks.push(newItem);
            }
        });
        setWorks(newWorks);
    }, [data]);

    const columns: ColumnsType<any> = [
        {
            title: 'Site ID /',
            dataIndex: 'site_id',
            sorter: (a: any, b: any) => a.site_id.length - b.site_id.length,
            filterMode: 'tree',
            filterSearch: true,
            fixed: 'left',
            // @ts-ignore
            onFilter: (value, record) => record.site_id.toLowerCase().includes(value.toLowerCase()),
            filters: [
                { text: 'AK', value: 'AK' },
                { text: 'AL', value: 'AL' },
                { text: 'AS', value: 'AS' },
                { text: 'AT', value: 'AT' },
                { text: 'AU', value: 'AU' },
                { text: 'KA', value: 'KA' },
                { text: 'KO', value: 'KO' },
                { text: 'KS', value: 'KS' },
                { text: 'KZ', value: 'KZ' },
                { text: 'PA', value: 'PA' },
                { text: 'PE', value: 'PE' },
                { text: 'SE', value: 'SE' },
                { text: 'SH', value: 'SH' },
                { text: 'TA', value: 'TA' },
                { text: 'UK', value: 'UK' },
                { text: 'UR', value: 'UR' }
            ]
        },
        {
            title: 'ID ЭАП',
            dataIndex: 'work_id',
            render: (value) => (
                <ChakraLink
                    color="blue.600"
                    _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                    as={Link}
                    to={`/works/${value}`}
                >
                    {value}
                </ChakraLink>
            )
        },
        {
            title: 'Тип ЭАП',
            dataIndex: 'work_type',
            sorter: (a: any, b: any) => (a.work_type?.length || '') - (b.work_type?.length || '')
        },
        {
            title: 'ID FE Сайта',
            dataIndex: 'fe_site_id',
            sorter: (a: any, b: any) => a.fe_site_id.length - b.fe_site_id.length,
            filterMode: 'tree',
            filterSearch: true,
            // @ts-ignore
            onFilter: (value, record) => record.fe_site_id.toLowerCase().includes(value.toLowerCase()),
            filters: [
                {
                    text: 'AL',
                    value: 'AL'
                },
                {
                    text: 'ZHR',
                    value: 'ZHR'
                },
                {
                    text: 'AS',
                    value: 'AS'
                },
                {
                    text: 'KA',
                    value: 'KA'
                },
                {
                    text: 'Test',
                    value: 'Test'
                },
                {
                    text: 'SH',
                    value: 'SH'
                },
                {
                    text: 'KZ',
                    value: 'KZ'
                }
            ]
        },
        {
            title: 'SAD ID',
            dataIndex: 'process_key',
            sorter: (a: any, b: any) => (a.process_key?.length || '') - (b.process_key?.length || '')
        },
        {
            title: 'Документ',
            render: (value, record) => (
                <a
                    target="_blank"
                    style={{ color: '#008abd' }}
                    href={`${process.env.REACT_APP_API_BASE_URL}${record.doc_link}`}
                    rel="noreferrer"
                >
                    {record.file_name}
                </a>
            )
        },
        {
            title: 'Подписка подрядчиком',
            render: (status, record) =>
                record.status?.sign_by_subcon === true ? (
                    <Badge count="Подписано" style={{ backgroundColor: '#52c41a' }} />
                ) : record.status?.sign_by_subcon === false ? (
                    <Badge count="Ожидает" style={{ backgroundColor: '#3FCBFF' }} />
                ) : (
                    ''
                )
        },
        {
            title: 'Подписано Kcell',
            render: (status, record) =>
                record.status?.sign_by_sad === true ? (
                    <Badge count="Подписано" style={{ backgroundColor: '#52c41a' }} />
                ) : record.status?.sign_by_sad === false ? (
                    <Badge count="Ожидает" style={{ backgroundColor: '#3FCBFF' }} />
                ) : (
                    ''
                )
        },
        {
            title: 'Статус',
            render: (status, record) =>
                record.signed_doc === true ? (
                    <Badge count="Подписано" style={{ backgroundColor: '#52c41a' }} />
                ) : record.signed_doc === false ? (
                    <Badge count="Не подписан" style={{ backgroundColor: 'red' }} />
                ) : (
                    ''
                )
        },
        {
            title: 'Действие',
            dataIndex: 'work_type',
            render: (value, record) =>
                !(record.status?.sign_by_sad === true || record.status?.sign_by_sad === false) && (
                    <Button
                        disabled={!!record.status?.sign_by_sad}
                        style={{ width: '150px' }}
                        onClick={() => {
                            setSelectedGroupID(record.id);
                            setIsSignModalOpened(true);
                        }}
                    >
                        Подписать все
                    </Button>
                )
        },
        {
            title: 'Обновлено',
            dataIndex: 'updated_at',
            // @ts-ignore
            sorter: (a: any, b: any) => new Date(a.updated_at) - new Date(b.updated_at),
            render: (text: string) => new Date(text).toLocaleString('ru-RU')
        }
    ];
    return (
        <Box bgColor="white" borderRadius={10} p={2}>
            <Modal
                title="Подписание АМ/АД"
                visible={isESignKeyModalOpened}
                onCancel={() => setIsESignKeyModalOpened(false)}
                okButtonProps={{ disabled: isSignPending }}
                cancelButtonProps={{ disabled: isSignPending }}
                onOk={onModalOk}
            >
                <Flex flexDir="column">
                    <Text mb={1} textAlign="center" fontSize="md">
                        Введите пароль от ключа ЭЦП
                    </Text>
                    <Input
                        autoComplete="new-password"
                        placeholder="Пароль"
                        value={eSignKey}
                        onChange={(e) => setESignKey(e.target.value)}
                        disabled={isSignPending}
                        type="password"
                    />
                </Flex>
            </Modal>
            <Modal
                title="Подписание АМ/АД"
                visible={isCertInfoModalOpened}
                onCancel={() => setIsCertInfoModalOpened(false)}
                okButtonProps={{ disabled: isSignPending }}
                cancelButtonProps={{ disabled: isSignPending }}
                onOk={signOk}
            >
                <Flex flexDir="column">
                    <Text fontSize={18} fontWeight={600}>
                        Информация о ключе:
                    </Text>
                    <Text>Выдано для: {certInfo?.fieldcert?.WhomIssued.replace('CN=', '')}</Text>
                    <Text>Страна: {certInfo?.fieldcert?.Country.replace('C=', '')}</Text>
                    <Text>Дата выдачи: {certInfo?.fieldcert?.Date1.replace('notBefore=', '')}</Text>
                    <Text>Дата истечения: {certInfo?.fieldcert?.Date2.replace('notAfter=', '')}</Text>
                    <Text>Кем выдано: {certInfo?.fieldcert?.IssuedBy.replace('CN=', '')}</Text>
                </Flex>
            </Modal>
            <Modal
                title="Подписание АМ/АД"
                visible={isSignModalOpened}
                onCancel={() => setIsSignModalOpened(false)}
                onOk={async () => {
                    if (loadedESign) {
                        setIsSignModalOpened(false);
                        setIsESignKeyModalOpened(true);
                    } else {
                        toast({
                            status: 'info',
                            title: 'Загрузите ЭЦП'
                        });
                    }
                }}
            >
                <Dragger
                    name="file"
                    maxCount={1}
                    disabled={!data}
                    onChange={async (info) => {
                        setLoadedESign(info);
                    }}
                    beforeUpload={() => false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Загрузите ключ ЭЦП</p>
                </Dragger>
            </Modal>
            <Table
                bordered
                loading={!data}
                size="small"
                indentSize={0}
                scroll={{ x: true }}
                rowKey="id"
                title={() => (
                    <Flex alignItems="center">
                        <Text fontSize="md" fontWeight={600}>
                            ЭАП требующие подписания
                        </Text>
                        <Button
                            onClick={() => {
                                setResponsible(null);
                                mutateData();
                                mutateEsignCount();
                            }}
                            disabled={!responsible}
                            style={{ marginLeft: 'auto' }}
                        >
                            Очистить
                        </Button>
                        <Select
                            value={responsible}
                            onChange={(val) => {
                                setResponsible(val);
                                mutateData();
                                mutateEsignCount();
                            }}
                            style={{ width: '300px' }}
                            placeholder="Ответственный"
                            options={responsibleData || []}
                        />
                        <Input
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            style={{ width: '300px', marginLeft: '10px' }}
                            placeholder="Поиск по Site ID"
                        />
                    </Flex>
                )}
                pagination={{
                    pageSize,
                    total: esignCount || 1,
                    onChange: onPaginationChange,
                    onShowSizeChange: onPageSizeChange
                }}
                dataSource={works}
                columns={columns}
            />
        </Box>
    );
};

export default Signing;
