/* eslint-disable no-use-before-define */
import { useState } from 'react';
import _ from 'lodash';
// @chakra-ui
import { Stack, Button, useToast } from '@chakra-ui/react';
// services
import { workService } from 'api/services';
import { userSelector } from 'redux/reducers/userSlice';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

interface WorkRltSARStatusProps {
    updateWork: () => void;
    work: any;
}

export default function WorkRltSARStatus({ work, updateWork }: WorkRltSARStatusProps) {
    const user = useSelector(userSelector);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const { candidateStatus } = work;

    const disableStart = !work.candidateRecieved?.esigns?.length;

    const handleStart = () => {
        setIsLoading(true);
        workService
            .startSarProcess({
                work_id: work.id,
                type: 'sar'
            })
            .then(() => {
                setIsLoading(false);
                toast({
                    title: 'Процесс согласования успешно запущен',
                    status: 'success'
                });
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
                setIsLoading(false);
            });
    };

    return (
        <>
            <Stack p={1.5} alignItems="center">
                {user.role && user.role.type === 'rnp' ? (
                    _.includes([null, -1], candidateStatus) ? (
                        <Button
                            isDisabled={isLoading || disableStart}
                            isLoading={isLoading}
                            colorScheme="purple"
                            onClick={handleStart}
                            variant="outline"
                        >
                            Начать процесс
                        </Button>
                    ) : null
                ) : null}

                {/* <Heading as="h4" fontSize="md">
                    Статус согласования
                </Heading>
                <Badge colorScheme={color} fontSize="0.8em">
                    {text}
                </Badge>
                <HStack flexDirection="row">
                    <HStack flexDirection="column">
                        <Heading as="h4" fontSize="md">
                            Leasing
                        </Heading>
                        <Badge colorScheme={leasingField.sarStatusColor} fontSize="0.8em">
                            {leasingField.sarStatusText}
                        </Badge>
                    </HStack>
                </HStack> */}
            </Stack>
        </>
    );
}
