import {
    Text,
    Box,
    HStack,
    Modal,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    useToast,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    FormLabel,
    Select,
    ModalOverlay,
    ModalCloseButton,
    Checkbox
} from '@chakra-ui/react';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { FaUserPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
import { useEffect, useState } from 'react';
import AdminPanelButton from 'components/Administration/components/AdminPanelButton';
import axios from 'axios';
import { useFormik } from 'formik';

const Administration = () => {
    const navigate = useNavigate();
    const user = useSelector(userSelector);
    const [subconList, setSubconList] = useState<any>([]);

    const [isAddingRespEngModalOpen, setIsAddingRespEngModalOpen] = useState(false);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    if (user.data.isRegistrationApprover !== true) {
        return <span>Доступ запрещен</span>;
    }

    const initialValues = {
        region: '',
        subcon: '',
        type: '',
        email: '',
        canFinishWork: false
    };

    const regionList = [
        'AK',
        'AL',
        'AS',
        'AT',
        'AU',
        'KA',
        'KO',
        'KS',
        'KZ',
        'PA',
        'PE',
        'SE',
        'SH',
        'TA',
        'UK',
        'UR',
        'Test'
    ];

    const formik = useFormik({
        initialValues,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const respEng = {
                region: values.region,
                subc: values.subcon || null,
                email: values.email,
                type: values.type,
                canFinishWork: values.canFinishWork
            };
            axios
                .post('/responsible-engineers', respEng)
                .then((response) => {
                    toast({
                        status: 'success',
                        title: `${response.data.full_name} назначен ответственным`
                    });
                    resetForm();
                    setSubmitting(false);
                })
                .catch((error) => {
                    toast({
                        status: 'error',
                        title: error.response.data.message || 'Произошла неизвестная ошибка'
                    });
                    console.error(error);
                    setSubmitting(false);
                });
        }
    });

    const getSubconList = () => {
        axios
            .get('/subcs')
            .then((res) => {
                setSubconList(res.data);
            })
            .catch((err) => console.error(err));
    };
    useEffect(() => {
        getSubconList();
    }, []);

    useHeaderTitle(`Панель администрирования`);
    return (
        <>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={5}>
                <Box bgColor="white" borderRadius={10} p={2} mt={1}>
                    <HStack spacing={2}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <FaUserPlus size="130px" />
                            <AdminPanelButton onClick={() => navigate(`/requests`)}>
                                Добавление пользователя
                            </AdminPanelButton>
                        </Box>
                    </HStack>
                </Box>
            </Box>
            <Modal
                isOpen={isAddingRespEngModalOpen}
                onClose={() => {
                    setIsAddingRespEngModalOpen(false);
                }}
                isCentered
                autoFocus={false}
            >
                <ModalOverlay />
                <form onSubmit={formik.handleSubmit}>
                    <ModalContent flexDirection="column">
                        <ModalBody>
                            <ModalCloseButton top="0.2rem" right="0.2rem" />
                            <Tabs isFitted>
                                <TabList>
                                    <Tab>Назначение инженера ОС</Tab>
                                    <Tab>Назначение инженера ОЭ</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Box mt={2}>
                                            <FormLabel>Регион</FormLabel>
                                            <Select
                                                placeholder="Выберите регион"
                                                style={{ width: '100%' }}
                                                value={formik.values.region}
                                                onChange={(e) =>
                                                    formik.setFieldValue('region', e.target.value)
                                                }
                                            >
                                                {regionList.map((region: any, i: number) => (
                                                    <option value={region} key={i}>
                                                        {region}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Box>
                                        <Box mt={2}>
                                            <FormLabel>Подрядчик</FormLabel>
                                            <Select
                                                placeholder="Выберите подрядчика"
                                                style={{ width: '100%' }}
                                                value={formik.values.subcon}
                                                onChange={(e) =>
                                                    formik.setFieldValue('subcon', e.target.value)
                                                }
                                            >
                                                {subconList?.map((subcon: any) => (
                                                    <option value={subcon.id} key={subcon.id}>
                                                        {subcon.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Box>
                                        <Input
                                            mt={2}
                                            placeholder="Введите корп. почту сотрудника ОС"
                                            onChange={(e) => {
                                                formik.setFieldValue('email', e.target.value);
                                                formik.setFieldValue('type', 'os');
                                            }}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <FormLabel>Регион</FormLabel>
                                        <Box mt={2}>
                                            <Select
                                                placeholder="Выберите регион"
                                                style={{ width: '100%' }}
                                                value={formik.values.region}
                                                onChange={(e) =>
                                                    formik.setFieldValue('region', e.target.value)
                                                }
                                            >
                                                {regionList?.map((region: any) => (
                                                    <option value={region} key={region}>
                                                        {region}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Box>
                                        <Input
                                            mt={2}
                                            placeholder="Введите корп. почту сотрудника ОЭ"
                                            onChange={(e) => {
                                                formik.setFieldValue('email', e.target.value);
                                                formik.setFieldValue('type', 'oe');
                                            }}
                                        />
                                        <Text mt={2}> Может завершать адресный план? </Text>
                                        <Checkbox
                                            mt={1}
                                            onChange={(e) =>
                                                formik.setFieldValue('canFinishWork', e.target.checked)
                                            }
                                        />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                            <ModalFooter>
                                <Button
                                    type="submit"
                                    colorScheme="green"
                                    width="100%"
                                    mt="1rem"
                                    isLoading={formik.isSubmitting}
                                    isDisabled={formik.isSubmitting}
                                    loadingText="Добавляем..."
                                >
                                    Добавить
                                </Button>
                            </ModalFooter>
                        </ModalBody>
                    </ModalContent>
                </form>
            </Modal>
        </>
    );
};
export default Administration;
