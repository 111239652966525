import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import { Box, Stack, useToast } from '@chakra-ui/react';
import axios from 'axios';
import AuthHeading from 'parts/Auth/components/AuthHeading/AuthHeading';
import AuthDescription from 'parts/Auth/components/AuthDescription/AuthDescription';
import AuthSubmitButton from 'parts/Auth/components/AuthSubmitButton/AuthSubmitButton';
/* import AuthInfoDocuments from 'parts/Auth/components/AuthInfoDocuments/AuthInfoDocuments'; */
/* import AuthRegistrationLink from 'parts/Auth/components/AuthRegistrationLink/AuthRegistrationLink'; */
import FormInputField from 'new-components/FormInputField/FormInputField';
import Link from 'new-components/Link/Link';

interface ResetPasswordFormInputFields {
    login: string;
}

/**
 * Reset password component, takes login and then send email with
 * reset token via API.
 *
 * @route /reset-password
 * @author Nikita Snetkov
 */
const ResetPassword = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const formInitialValues = {
        login: ''
    };

    const onFormSubmit = async (
        data: ResetPasswordFormInputFields,
        helpers: FormikHelpers<ResetPasswordFormInputFields>
    ) => {
        helpers.setSubmitting(true);

        await axios
            .post('/profiles/forgot', {
                username: data.login
            })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Инструкция по восстановлению пароля отправлена вам на почту',
                    description: 'При отсутствии письма проверьте папку "Спам"'
                });

                navigate('/login');
            })
            .catch((error) => {
                toast({
                    status: 'error',
                    title: error.response.data.message || 'Произошла неизвестная ошибка'
                });
            });

        helpers.setSubmitting(false);
    };

    return (
        <>
            <AuthHeading>Восстановление пароля</AuthHeading>
            <AuthDescription>
                Для того, чтобы получить ссылку востановления пароля, введите ваш логин или email
            </AuthDescription>
            <Formik initialValues={formInitialValues} onSubmit={onFormSubmit}>
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <FormInputField
                                label="Логин или email"
                                placeholder="Введите логин или email"
                                name="login"
                                autoComplete="username"
                                autoFocus
                                isRequired
                                isDisabled={isSubmitting}
                                value={values.login}
                                handleChange={handleChange}
                            />
                            <AuthSubmitButton
                                isDisabled={isSubmitting || !values.login}
                                isLoading={isSubmitting}
                            >
                                Восстановить
                            </AuthSubmitButton>
                            <Box display="flex">
                                Вспомнили пароль?&nbsp;
                                <Link to="/login">Войти</Link>
                            </Box>
                            {/* <AuthRegistrationLink /> */}
                        </Stack>
                    </Form>
                )}
            </Formik>
            {/* <AuthInfoDocuments /> */}
        </>
    );
};

export default ResetPassword;
