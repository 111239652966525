// @ts-nocheck

import LoadingOverlay from 'components/LoadingOverlay';
import { Link } from 'react-router-dom';

import { TableContainer, Spinner, Link as ChakraLink, Box } from '@chakra-ui/react';
import { TableCustom, TablePagination } from 'components/react-table';

import { useTable, useSortBy, usePagination, Column, useAsyncDebounce } from 'react-table';

import { useEffect, useState, useMemo } from 'react';
import { fetcher } from 'utils/client';
import useSWR from 'swr';
import getSearchParameters from './helpers/getParameters';
import EquipmentTableToolbar from './components/EquipmentTableToolbar';

const Equipments = () => {
    const [equipmentsParameters, setEquipmentsParameters] = useState('');
    const [totalEquipmentsParameters, setTotalEquipmentsParameters] = useState('');
    const [filterEquipmentSapCode, setFilterEquipmentSapCode] = useState('');
    const [_pageCount, setPageCount] = useState(0);

    const { data: equipments, isLoading } = useSWR(
        equipmentsParameters
            ? `/equip-directories?${equipmentsParameters}
        `
            : null,
        fetcher
    );

    const { data: totalEquipments } = useSWR(
        totalEquipmentsParameters
            ? `/equip-directories/count?${totalEquipmentsParameters}
          `
            : `/equip-directories/count`,
        fetcher
    );

    const getColumns = () => {
        const columns = [
            {
                accessor: 'id',
                Header: 'ID',
                Cell: ({ cell: { value } }: any) => (
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        as={Link}
                        target="_blank"
                        to={`/equipments/${value}`}
                    >
                        {value}
                    </ChakraLink>
                )
            },
            {
                accessor: 'sap_code',
                Header: 'SAP код'
            },
            {
                accessor: 'category.title',
                Header: 'Категория'
            },

            {
                accessor: 's_equip_title',
                Header: 'Название'
            },
            {
                accessor: 's_unit',
                Header: 'Ед. измерения'
            },
            {
                accessor: 's_product_code',
                Header: 'Код продукта'
            },
            {
                accessor: 'updated_at',
                Header: 'Дата изменения',
                Cell: ({ cell: { value } }: any) => (value ? new Date(value).toLocaleString('ru') : null)
            }
        ];
        return columns;
    };

    const columns = useMemo(() => getColumns(), []) as Column[];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            data: equipments || [],
            columns,
            initialState: {
                pageIndex: 0,
                sortBy: [{ id: 'updated_at', desc: true }]
            },
            stateReducer(newState, action) {
                if (action.type === 'toggleSortBy') {
                    return { ...newState, pageIndex: 0 };
                }

                return newState;
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount: _pageCount,
            autoResetPage: false,
            autoResetSortBy: false
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        if (totalEquipments) {
            setPageCount(Math.ceil(totalEquipments / pageSize));
        }
    }, [totalEquipments]);

    const handleFilterEquipmentSapCode = useAsyncDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterEquipmentSapCode(event.target.value);
        gotoPage(0);
    }, 500);

    useEffect(() => {
        const [finalParamsString, finalParamsTotalString] = getSearchParameters({
            _limit: pageSize,
            _start: pageSize * pageIndex,
            _sort: sortBy.length ? `${sortBy[0].id}:${sortBy[0].desc ? 'DESC' : 'ASC'}` : null,
            sap_code_contains: filterEquipmentSapCode
        });
        setTotalEquipmentsParameters(finalParamsTotalString);
        setEquipmentsParameters(finalParamsString);
    }, [pageIndex, pageSize, sortBy, filterEquipmentSapCode]);

    return (
        <Box bgColor="white" borderRadius={10} p={2}>
            <LoadingOverlay
                Spinner={<Spinner color="blue.500" />}
                active={isLoading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        overflow: 'auto',
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column'
                    })
                }}
            >
                <>
                    <EquipmentTableToolbar
                        onEquipmentChange={handleFilterEquipmentSapCode}
                        totalEquipments={totalEquipments}
                        equipmentSapCode={filterEquipmentSapCode}
                    />
                    <TableContainer
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            height: '100%',
                            padding: 1.5
                        }}
                    >
                        <TableCustom
                            size="sm"
                            getTableProps={getTableProps}
                            headerGroups={headerGroups}
                            getTableBodyProps={getTableBodyProps}
                            page={page}
                            prepareRow={prepareRow}
                        />
                    </TableContainer>

                    <TablePagination
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        pageCount={pageCount}
                        previousPage={previousPage}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        pageSizeOptions={[5, 10, 25]}
                        setPageSize={setPageSize}
                    />
                </>
            </LoadingOverlay>
        </Box>
    );
};

export default Equipments;
