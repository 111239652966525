// @ts-nocheck
import { FC, useEffect, useState } from 'react';
import { matListsService } from 'api/services';
import {
    Card,
    CardBody,
    Modal as ChakraModal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { IHistoryItem } from '../../utils/interfaces/MatList';

import matListStatusMap from '../../utils/maps';

interface IParams {
    id: number;
    onClose: () => void;
}

const MatListStatusHistoryModal: FC<IParams> = ({ id, onClose }) => {
    const matListStatuses = Object.keys(matListStatusMap);
    const toast = useToast();
    const [history, setHistory] = useState<IHistoryItem[]>([]);
    useEffect(() => {
        matListsService
            .getStatusHistory(id)
            .then((res) => {
                setHistory(res.data);
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось получить историю'
                });
            });
    }, []);
    return (
        <div>
            <ChakraModal isOpen onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>История статусов</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack gap={2}>
                            {history.map((item, i) => {
                                let isRollback = false;
                                if (history[i + 1]) {
                                    const currentStatusIndex = matListStatuses.indexOf(item.status);
                                    const nextItemStatusIndex = matListStatuses.indexOf(
                                        history[i + 1].status
                                    );
                                    isRollback = currentStatusIndex < nextItemStatusIndex;
                                }

                                return (
                                    <Card>
                                        <CardBody p={1}>
                                            <Stack gap={1}>
                                                <div>{new Date(item.created_at).toLocaleString('ru-Ru')}</div>
                                                <Stack direction="row">
                                                    <Text> Переведен в статус:</Text>
                                                    <Text color={isRollback ? 'red' : 'green'}>
                                                        {matListStatusMap[item.status]}
                                                    </Text>
                                                </Stack>

                                                <div>Инициатор: {item.user.full_name}</div>
                                                {item.comment && <div>Комментарий: {item.comment}</div>}
                                            </Stack>
                                        </CardBody>
                                    </Card>
                                );
                            })}
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </ChakraModal>
        </div>
    );
};
export default MatListStatusHistoryModal;
