import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../redux/reducers/userSlice';
import { ERoleNames } from '../constants';

interface IProps {
    children: ReactNode;
    roleNames: ERoleNames[] | null;
}

const HasAccess: FC<IProps> = ({ children, roleNames }) => {
    if (!roleNames) return null;
    const userData = useSelector(userSelector);

    return (
        <>
            {userData.role &&
            (roleNames.includes(userData?.role?.name as ERoleNames) ||
                userData?.role?.name === ERoleNames.ADMIN)
                ? children
                : null}{' '}
        </>
    );
};
export default HasAccess;
