import {
    Button,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tooltip,
    Text,
    useDisclosure,
    Input,
    Textarea,
    useToast
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
import { useState } from 'react';
import axios from 'axios';

const ReportError = () => {
    const toast = useToast();
    const { data: userData } = useSelector(userSelector);

    const defaultData = {
        userData: `${userData.full_name || userData.username} // ${userData.email}`,
        errorCategory: '',
        error: ''
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [data, setData] = useState(defaultData);

    const onDataChange = (field: string, value: string) => {
        setData({
            ...data,
            [field]: value
        });
    };

    const sendRequest = async () => {
        setIsRequestPending(true);
        await axios
            .post('/analytics/user-report', data)
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Благодарим вас за обращение, заявка была отправлена!'
                });
                onClose();
                setData(defaultData);
            })
            .catch((error) => {
                console.error(error);
                toast({
                    status: 'error',
                    title: 'Внутренняя ошибка сервера',
                    position: 'bottom-right'
                });
            });
        setIsRequestPending(false);
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader borderBottom="1px solid" borderColor="gray.300">
                        Сообщить об ошибке
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody
                        paddingTop="30px"
                        paddingBottom="30px"
                        borderBottom="1px solid"
                        borderColor="gray.300"
                    >
                        <Text fontWeight={700} textAlign="center" fontSize={18} mb={4}>
                            Если вы столкнулись с ошибкой или проблемой, <br /> пожалуйста, заполните форму
                            обращения
                        </Text>
                        <Text as="label">Ваши данные</Text>
                        <Input mt="3px" value={data.userData} disabled mb={2} />
                        <Text as="label" htmlFor="errorCategory">
                            Раздел в котором произошла проблема
                        </Text>
                        <Input
                            id="errorCategory"
                            mt="3px"
                            mb={2}
                            value={data.errorCategory}
                            onChange={(e) => {
                                onDataChange('errorCategory', e.target.value);
                            }}
                        />
                        <Text as="label" htmlFor="error">
                            Описание проблемы
                        </Text>
                        <Textarea
                            id="error"
                            mt="3px"
                            paddingInlineEnd={1.5}
                            paddingInlineStart={1.5}
                            minHeight="130px"
                            value={data.error}
                            onChange={(e) => {
                                onDataChange('error', e.target.value);
                            }}
                        />
                        <Text fontWeight={700} textAlign="center" fontSize={18} mt={4}>
                            или
                        </Text>
                        <Text fontSize={18} mt={1} textAlign="center">
                            Обратитесь на почту kcell-ciptracker-support@tele2.kz
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="purple"
                            variant="outline"
                            onClick={onClose}
                            isLoading={isRequestPending}
                        >
                            Отмена
                        </Button>
                        <Button
                            colorScheme="purple"
                            ml="auto"
                            onClick={sendRequest}
                            isLoading={isRequestPending}
                            loadingText="Отправляем..."
                        >
                            Отправить
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Tooltip label="Сообщить об ошибке" colorScheme="red" hasArrow>
                <IconButton
                    position="absolute"
                    bottom="30px"
                    left="30px"
                    colorScheme="red"
                    borderRadius="100%"
                    width="32px"
                    height="40px"
                    aria-label="Сообщить об ошибке"
                    icon={<QuestionIcon width="24px" height="24px" />}
                    onClick={onOpen}
                />
            </Tooltip>
        </>
    );
};

export default ReportError;
