/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { InboxOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons';
import { Badge, Button, Collapse, Image, Input, Modal, Spin, Table, Tree, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Dragger from 'antd/lib/upload/Dragger';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { userSelector } from 'redux/reducers/userSlice';
import { TSSRLink } from 'Routes/Works/Work.styles';
import useHeaderTitle from 'hooks/useHeaderTitle';
import {
    Box,
    Button as ChakraButton,
    Divider,
    Flex,
    HStack,
    IconButton,
    Text,
    useToast
} from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';
import moment from 'moment';
import { CalendarIcon, CheckIcon } from '@chakra-ui/icons';
import { UploadChangeParam } from 'antd/es/upload';
import { generateTitle, getTextByApproveStatus } from '../utils';

const Task = () => {
    const navigate = useNavigate();
    const { taskID } = useParams();
    const { role } = useSelector(userSelector);
    const [task, setTask] = useState<any>(null);
    const [isCommentModalOpened, setIsCommentModalOpened] = useState(false);
    const [commentValue, setCommentValue] = useState('');
    const [decision, setDecision] = useState<any>(null);
    const [dataSource, setDataSource] = useState<any>(null);
    const [isCommentHistroyModalOpened, setIsCommentHistoryModalOpened] = useState(false);
    const [disabledSign, setDisabledSign] = useState(false);

    useEffect(() => {
        if (task?.storage?.mat_lists?.esigns) {
            const countSigned = task.storage.mat_lists.esigns.filter(
                (esign: any) => esign.status.sign_by_subcon === true
            ).length;

            // if at least one is signed
            if (countSigned > 0) {
                setDisabledSign(true);
            }
        }
    }, [task]);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const [rsd, setRSD] = useState<any>(null);

    useTitle(generateTitle(`Задача ${taskID}`));

    useHeaderTitle(task?.tasks[0] ? `${task?.tasks[0]?.name} по задаче #${taskID}` : `Просмотр списка задач`);

    const makeDecision = () => {
        if (decision === 0 && !commentValue) {
            toast({ status: 'error', title: 'Заполните комментарий' });
        } else {
            axios
                .post('/works/makeDecision', {
                    work_id: task.storage.id,
                    field: task.tasks[0].field,
                    comment: commentValue,
                    value: decision
                })
                .then(() => {
                    axios
                        .post('/tasks/complete', {
                            id: task.tasks[0].id
                        })
                        .then(() => {
                            if (decision === 0) {
                                toast({
                                    status: 'success',
                                    title: 'Задача отправлена на доработку'
                                });
                            } else {
                                toast({
                                    status: 'success',
                                    title: 'Успешно согласовано'
                                });
                            }

                            setIsCommentModalOpened(false);
                            setCommentValue('');
                            navigate('/tasks');
                        })
                        .catch((err) => {
                            toast({
                                status: 'error',
                                title: 'Произошла ошибка'
                            });
                            console.error(err);
                        });
                })
                .catch((err) => {
                    toast({
                        status: 'error',
                        title: 'Произошла ошибка'
                    });
                    console.error(err);
                });
        }
    };

    const [scans, setScans] = useState<any>([]);

    const [montageLink, setMontageLink] = useState('');
    const [demontageLink, setDemontageLink] = useState('');
    const [modifiedScansMontage, setModifiedScansMontage] = useState<any>([]);
    const [modifiedScansDemontage, setModifiedScansDemontage] = useState<any>([]);
    const [isCommentingPending, setIsCommentingPending] = useState(false);

    const [photosTree, setPhotosTree] = useState<any>([]);

    const getTask = async () => {
        try {
            const res = await axios.get(`/task-groups/${taskID}`);

            setTask(res.data);

            const parsedPhotos: { [key: string]: any } = {};

            if (res.data.storage?.images?.esigns) {
                const photosArray = res.data.storage.images.esigns;
                photosArray.forEach((photo: any) => {
                    const date = new Date(photo.created_at).toLocaleDateString('ru-RU');
                    if (!parsedPhotos[date]) {
                        parsedPhotos[date] = [];
                    }
                    parsedPhotos[date] = [...parsedPhotos[date], photo];
                });

                const treeData: any = [];
                Object.keys(parsedPhotos)
                    .slice(0)
                    .reverse()
                    .forEach((key) => {
                        treeData.push({
                            title: key,
                            key,
                            children: [
                                {
                                    title: () => (
                                        <Image.PreviewGroup>
                                            {parsedPhotos[key].map((image: any) => (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <Image
                                                        key={image.doc_link}
                                                        width={200}
                                                        src={`${process.env.REACT_APP_API_BASE_URL}${image.doc_link}`}
                                                        style={{ paddingRight: '6px' }}
                                                    />
                                                    {image.file_name.slice(0, 20)}...
                                                </div>
                                            ))}
                                        </Image.PreviewGroup>
                                    ),
                                    isLeaf: true
                                }
                            ]
                        });
                    });

                setPhotosTree(treeData);
            }

            if (res.data.storage.scans?.montage.scans) {
                setModifiedScansMontage(res.data.storage.scans?.montage.scans);
            } else {
                setModifiedScansMontage(res.data.storage.scans?.montage);
            }
            if (res.data.storage.scans?.demontage.scans) {
                setModifiedScansDemontage(res.data.storage.scans?.demontage.scans);
            } else {
                setModifiedScansDemontage(res.data.storage.scans?.demontage);
            }

            if (role?.name !== 'Подрядная организация' || res.data.tasks[0].name !== 'Подписать мат. листы') {
                axios
                    .get(`/rsds?site=${res.data.storage.site.id}`)
                    .then((response) => {
                        setRSD(response.data.data);
                    })
                    .catch((err) => {
                        toast({
                            status: 'error',
                            title: 'Не удалось получить данные по RSD'
                        });
                        console.error(err);
                    });

                axios
                    .get(`/work-scans/${res.data.storage.id}`)
                    .then((response) => {
                        if (response.data.data[0]?.data[0]) {
                            setScans(response.data.data);
                        }

                        const newDataSource = [
                            {
                                key: '1',
                                field: 'Site ID',
                                value: res.data.storage.site_id
                            },
                            {
                                key: '2',
                                field: 'Маркер аварийности сайта',
                                value: res.data.site?.s_main_accident_rate
                            },
                            {
                                key: '3',
                                field: 'Категория ЭАП',
                                value: res.data.site?.s_main_work_category
                            },
                            {
                                key: '4',
                                field: 'Решение',
                                value: res.data.site?.s_main_accid_mark_cmnt
                            },
                            {
                                key: '5',
                                field: 'Подрядчик',
                                value: res.data.storage.subc.name
                            },
                            {
                                key: '6',
                                field: 'Тип ЭАП',
                                value: res.data.storage.work_type
                            },
                            {
                                key: '40',
                                field: 'Сканы',
                                value: response.data[0]?.data.title,
                                render: () => <span>123</span>
                            },
                            {
                                key: '7',
                                field: 'TSSR opt File',
                                value: res.data.storage?.tssr?.esigns
                            },
                            {
                                key: '8',
                                field: 'Дата последней загрузки TSSR',
                                value: new Date(res.data.storage.updated_at).toLocaleString('ru-RU')
                            },
                            {
                                key: '9',
                                field: 'TSSR RP approve',
                                value: getTextByApproveStatus(res.data.storage.tssr_rp_approve?.status)
                            },
                            {
                                key: '10',
                                field: 'TSSR DT approve',
                                value: getTextByApproveStatus(res.data.storage.tssr_dt_approve?.status)
                            },
                            {
                                key: '11',
                                field: 'TSSR TR approve',
                                value: getTextByApproveStatus(res.data.storage.tssr_tr_approve?.status)
                            },
                            {
                                key: '12',
                                field: 'TSSR RL approve',
                                value: getTextByApproveStatus(res.data.storage.tssr_rl_approve?.status)
                            },
                            {
                                key: '66',
                                field: 'Scans OS approve',
                                value: getTextByApproveStatus(
                                    res.data.storage.scans_os_approve?.status
                                        ? res.data.storage.scans_os_approve?.status
                                        : res.data.storage.scans_os_approve === null
                                        ? null
                                        : true
                                )
                            },
                            {
                                key: '67',
                                field: 'Scans OE approve',
                                value: getTextByApproveStatus(
                                    res.data.storage.scans_oe_approve?.status
                                        ? res.data.storage.scans_oe_approve?.status
                                        : res.data.storage.scans_oe_approve === null
                                        ? null
                                        : true
                                )
                            },
                            {
                                key: '13',
                                field: 'TSSR opt decision',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '14',
                                field: 'Дата последнего решения TSSR',
                                value: new Date(res.data.storage.updated_at).toLocaleString('ru-RU')
                            },
                            {
                                key: '15',
                                field: 'TSSR opt comments',
                                value: res.data.storage.tssr_cmnt
                            },
                            {
                                key: '16',
                                field: 'TSSR opt agreement files',
                                value: res.data.storage?.tssr_files?.esigns
                            },
                            {
                                key: '17',
                                field: 'Equip scan init',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '29',
                                field: 'Equip photos from mob',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '18',
                                field: 'Construct opt approve',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '19',
                                field: 'Maintenance otp approve',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '20',
                                field: 'Acceptance comments',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '21',
                                field: 'Acceptance decision',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '22',
                                field: 'TI Finish',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '23',
                                field: 'NOC First confirmation',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '24',
                                field: 'RSD',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '25',
                                field: 'Subcon comment to photos',
                                value: res.data.storage.task_id
                            },
                            {
                                key: '26',
                                field: 'TSD',
                                value: res.data.site?.s_tsd?.esigns
                            }
                        ];
                        setDataSource(newDataSource);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else {
                if (res.data.storage.scans?.montage?.scans) {
                    setModifiedScansMontage(res.data.storage.scans?.montage?.scans);
                } else {
                    setModifiedScansMontage(res.data.storage.scans?.montage);
                }
                if (res.data.storage.scans?.demontage?.scans) {
                    setModifiedScansDemontage(res.data.storage.scans?.demontage?.scans);
                } else {
                    setModifiedScansDemontage(res.data.storage.scans?.demontage);
                }

                setDemontageLink(
                    `${process.env.REACT_APP_API_BASE_URL}${res.data.storage.scans?.demontage.link}`
                );
                setMontageLink(
                    `${process.env.REACT_APP_API_BASE_URL}${res.data.storage.scans?.montage.link}`
                );
            }

            setDemontageLink(
                `${process.env.REACT_APP_API_BASE_URL}${res.data.storage.scans?.demontage.link}`
            );
            setMontageLink(`${process.env.REACT_APP_API_BASE_URL}${res.data.storage.scans?.montage.link}`);
        } catch (err) {
            toast({
                status: 'error',
                title: 'Не удалось получить данные по задаче'
            });
            console.error(err);
        }
    };

    useEffect(() => {
        getTask();
    }, []);

    const markAsInvalid = (data: any, record: any, idx: number) => {
        data.data[idx] = { ...record, is_not_valid: 1 };
        axios
            .put('/work-scans', data)
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Информация успешно обновлено'
                });
                getTask();
            })
            .catch(() => {
                toast({
                    status: 'error',
                    title: 'Ошибка при обновлении'
                });
            });
    };
    const openCommentHistoryModal = () => {
        Modal.info({
            width: '60vw',
            title: 'История принятия решений',
            visible: isCommentHistroyModalOpened,
            content: (
                <div>
                    {task.storage.s_bs_002_main_mat_list_comments ? (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: task.storage.s_bs_002_main_mat_list_comments
                            }}
                        />
                    ) : (
                        <span> Комментарии отсутствуют </span>
                    )}
                </div>
            ),
            onOk() {
                setIsCommentHistoryModalOpened(false);
            }
        });
    };
    const matListDepartments = [
        {
            key: 'oe',
            label: 'Отдел эксплуатации'
        }
    ];

    const scansColumns = (data: any) => [
        {
            title: 'ID сайта',
            dataIndex: 'sid',
            key: 'sid'
        },
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Код продукта',
            dataIndex: 'prodcode',
            key: 'prodcode'
        },
        {
            title: 'Серийный номер',
            dataIndex: 'serial',
            key: 'serial'
        },
        {
            title: 'Ед. измерения',
            dataIndex: 'unit',
            key: 'unit'
        },
        {
            title: 'Кол-во',
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: 'Дата',
            dataIndex: 'datecreate',
            key: 'datecreate',
            render: (text: string) => (
                <span>{moment(text).add(6, 'hours').format('DD.MM.YYYY, HH:mm:ss')}</span>
            )
        },
        {
            title: 'Ошибочное',
            dataIndex: 'actions',
            key: 'actions',
            render: (field: any, record: any, idx: number) =>
                !record.is_not_valid ? (
                    <Button onClick={() => markAsInvalid(data, record, idx)}>Ошибочное</Button>
                ) : null
        }
    ];

    const subconScansMontageColumns = [
        {
            title: 'ID сайта',
            dataIndex: 'sid',
            key: 'sid'
        },
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Код продукта',
            dataIndex: 'prodcode',
            key: 'prodcode'
        },
        {
            title: 'Серийный номер',
            dataIndex: 'serial',
            key: 'serial'
        },
        {
            title: 'Ед. измерения',
            dataIndex: 'unit',
            key: 'unit'
        },
        {
            title: 'Кол-во',
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: 'Дата',
            dataIndex: 'datecreate',
            key: 'datecreate',
            render: (text: string) => (
                <span>{moment(text).add(6, 'hours').format('DD.MM.YYYY, HH:mm:ss')}</span>
            )
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            key: 'comment',
            render: (field: any, record: any, i: any) => (
                <>
                    {task.tasks[0].name !== 'Подписать мат. листы' ||
                    role?.name === 'Подрядная организация' ? (
                        <Input
                            autoComplete="new-password"
                            value={modifiedScansMontage[i]?.comment}
                            onChange={(e) => {
                                e.preventDefault();
                                if (role?.name === 'Подрядная организация') {
                                    const savedScans = [...modifiedScansMontage];
                                    savedScans[i].comment = e.target.value;
                                    setModifiedScansMontage(savedScans);
                                }
                            }}
                        />
                    ) : (
                        <span>{modifiedScansMontage[i]?.comment}</span>
                    )}
                </>
            )
        },
        {
            title: 'Ошибочное',
            dataIndex: 'is_not_valid',
            key: 'is_not_valid',
            render: (field: any) => (field ? 'Да' : 'Нет')
        },
        {
            title: 'Перенос с',
            dataIndex: 'transfer',
            key: 'transfer'
        },
        {
            title: 'Комментарий (1С)',
            dataIndex: 'onecComment',
            key: 'onecComment'
        }
    ];

    const subconScansDemontageColumns = [
        {
            title: 'ID сайта',
            dataIndex: 'sid',
            key: 'sid'
        },
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Код продукта',
            dataIndex: 'prodcode',
            key: 'prodcode'
        },
        {
            title: 'Серийный номер',
            dataIndex: 'serial',
            key: 'serial'
        },
        {
            title: 'Ед. измерения',
            dataIndex: 'unit',
            key: 'unit'
        },
        {
            title: 'Кол-во',
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: 'Дата',
            dataIndex: 'datecreate',
            key: 'datecreate',
            render: (text: string) => (
                <span>{moment(text).add(6, 'hours').format('DD.MM.YYYY, HH:mm:ss')}</span>
            )
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            key: 'comment',
            render: (field: any, record: any, i: any) => (
                <>
                    {task.tasks[0].name !== 'Подписать мат. листы' ||
                    role?.name === 'Подрядная организация' ? (
                        <Input
                            value={modifiedScansDemontage[i]?.comment}
                            onChange={(e) => {
                                e.preventDefault();
                                if (role?.name === 'Подрядная организация') {
                                    const savedScans = [...modifiedScansDemontage];
                                    savedScans[i].comment = e.target.value;
                                    setModifiedScansDemontage(savedScans);
                                }
                            }}
                        />
                    ) : (
                        <span>{modifiedScansMontage[i]?.comment}</span>
                    )}
                </>
            )
        },
        {
            title: 'Ошибочное',
            dataIndex: 'is_not_valid',
            key: 'is_not_valid',
            render: (field: any) => (field ? 'Да' : 'Нет')
        },
        {
            title: 'Перенос с',
            dataIndex: 'transfer',
            key: 'transfer'
        },
        {
            title: 'Комментарий (1С)',
            dataIndex: 'onecComment',
            key: 'onecComment'
        }
    ];

    const saveComments = () => {
        setIsCommentingPending(true);
        axios
            .post('/works/addCommentsToScan', {
                id: task.storage.id,
                scans: { montage: modifiedScansMontage, demontage: modifiedScansDemontage }
            })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Комментарии успешно сохранены'
                });
                window.location.reload();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsCommentingPending(false);
            });
    };

    const columns = [
        {
            title: 'Поле',
            dataIndex: 'field',
            key: 'field',
            width: '30%'
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            render: (any: any, record: any) =>
                record.field === 'TSSR opt File' ||
                record.field === 'TSSR opt agreement files' ||
                record.field === 'TSD' ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {record.value &&
                            record.value.map((link: any) => (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${process.env.REACT_APP_API_BASE_URL}${link.doc_link}`}
                                    key={link.doc_link}
                                    style={{ marginBottom: '8px', color: 'blue' }}
                                >
                                    {link.file_name} (
                                    {link.date
                                        ? new Date(link.date).toLocaleString('ru-RU')
                                        : 'перенесено с SAD'}
                                    )
                                </a>
                            ))}
                    </div>
                ) : record.field === 'Сканы' ? (
                    <>
                        {photosTree && (
                            <>
                                <span>Фотографии</span>
                                <Tree.DirectoryTree selectable={false} treeData={photosTree} />
                                <br />
                            </>
                        )}
                        {scans[0] &&
                            scans.map((scan: any, i: any) => {
                                if (scan.type === 6) {
                                    return (
                                        <>
                                            <span>Монтаж</span>
                                            <Table
                                                style={{ width: '50vw' }}
                                                scroll={{ x: true }}
                                                pagination={false}
                                                bordered
                                                dataSource={scans[i].data}
                                                columns={scansColumns(scans[i])}
                                            />
                                            <br />
                                        </>
                                    );
                                }
                                return (
                                    <>
                                        <span>Демонтаж</span>
                                        <Table
                                            style={{ width: '50vw' }}
                                            scroll={{ x: true }}
                                            pagination={false}
                                            bordered
                                            dataSource={scans[i].data}
                                            columns={scansColumns(scans[i])}
                                        />
                                    </>
                                );
                            })}
                    </>
                ) : record.field === 'RSD' && rsd && rsd[0] ? (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                overflowX: 'auto',
                                width: '100%'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '20%',
                                    borderRight: '1px solid #1F2229',
                                    paddingRight: '6px'
                                }}
                            >
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Site ID
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Site config
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Sector
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Direction
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Lat
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Long
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Height
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Mech.Tilt
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    El. Tilt 800
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    El. Tilt 900
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    El. Tilt 1800
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    El. Tilt 2100
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Filters
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Antenna Type
                                </span>
                                <span style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}>
                                    Дата загрузки
                                </span>
                            </div>
                            {rsd.map((item: any) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingLeft: '6px',
                                        paddingRight: '6px',
                                        borderRight: '1px solid #1F2229',
                                        width: 'auto'
                                    }}
                                >
                                    <span
                                        style={{ fontSize: '14px', marginBottom: '12px', fontWeight: 'bold' }}
                                    >
                                        {item.new_site_id}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.s_f_900} / {item.s_f_1800} / {item.s_f_2100}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.sector}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.direction}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>{item.lat}</span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>{item.lng}</span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.height}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.mech_tilt}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.el_tilt_800}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.el_tilt_900}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.el_tilt_1800}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.el_tilt_2100}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {item.filters}
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '14px',
                                            marginBottom: '12px',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {item.antenna_type}
                                    </span>
                                    <span style={{ fontSize: '14px', marginBottom: '12px' }}>
                                        {new Date(item.created_at).toLocaleString('ru')}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : record.field === 'Site ID' ? (
                    <span
                        role="button"
                        style={{ color: '#3FCBFF', cursor: 'pointer' }}
                        onClick={() => navigate(`/sites/${task.storage.site_id}/${task.storage.site.id}`)}
                    >
                        {record.value}
                    </span>
                ) : record.field === 'TSD' ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {record.value &&
                            record.value.map((link: any) => (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${process.env.REACT_APP_API_BASE_URL}${link.doc_link}`}
                                    key={link.doc_link}
                                    style={{ marginBottom: '8px' }}
                                >
                                    {link.file_name}
                                </a>
                            ))}
                        {role?.name === 'Отдел планирования трансмиссионной сети' && (
                            <Upload
                                multiple
                                name="file"
                                showUploadList={false}
                                maxCount={1}
                                onChange={async (info) => {
                                    const formData = new FormData();
                                    formData.append('id', task.site.id!);
                                    formData.append('field', 's_tsd');
                                    // @ts-ignore
                                    formData.append('file', info.file);
                                    await axios
                                        .post('/sites/upload', formData)
                                        .then(async () => {
                                            toast({
                                                status: 'success',
                                                title: 'TSD успешно загружена'
                                            });
                                            getTask();
                                        })
                                        .catch((err) => {
                                            toast({
                                                status: 'error',
                                                title: err.response.data.message
                                            });
                                        });
                                }}
                                beforeUpload={() => false}
                            >
                                <Button icon={<UploadOutlined />}>Загрузить TSD</Button>
                            </Upload>
                        )}
                    </div>
                ) : record.field === 'TSSR opt comments' ? (
                    <div dangerouslySetInnerHTML={{ __html: record.value }} />
                ) : record.field === 'TSSR opt File' ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {record.value &&
                            record.value.map((item: any) => (
                                <a
                                    href={`${process.env.REACT_APP_API_BASE_URL}${item.doc_link}`}
                                    key={item.file_name}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <TSSRLink>{item.file_name}</TSSRLink>
                                </a>
                            ))}
                    </div>
                ) : (
                    <span>{record?.value}</span>
                )
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [isESignKeyModalOpened, setIsESignKeyModalOpened] = useState(false);
    const [isSignPending, setIsSignPending] = useState(false);
    const [loadedESign, setLoadedESign] = useState<any>(null);
    const [eSignKey, setESignKey] = useState('');
    const [isSignModalOpened, setIsSignModalOpened] = useState(false);
    const [loadedSubcFile, setLoadedSubcFile] = useState<any>(null);
    const [isSubcFileModalOpened, setIsSubcFileModalOpened] = useState(false);

    const [isCertInfoModalOpened, setIsCertInfoModalOpened] = useState(false);
    const [certInfo, setCertInfo] = useState<any>(null);

    const onFileDelete = async (file: any) => {
        setIsLoadingFile(true);
        try {
            await axios.post('/esigns/delete', { id: file.id });
        } catch (e) {
            toast({
                id: 'file-delete-error',
                position: 'bottom-right',
                status: 'error',
                duration: 3000,
                description: 'Ошибка при удалении файла'
            });
            setIsLoadingFile(false);
            return;
        }
        toast({
            id: 'file-upload-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Файл успешно удален'
        });
        await getTask();
        setIsLoadingFile(false);
    };

    const onFileUpload = async (data: UploadChangeParam, id: any) => {
        setIsLoadingFile(true);
        const formData = new FormData();
        formData.append('work_id', id);
        formData.append('field', 'mat_list_docs');
        // @ts-ignore
        formData.append('file', data.file);
        try {
            await axios.post('/works/upload', formData);
        } catch (err: any) {
            toast({
                id: 'file-upload-error',
                position: 'bottom-right',
                status: 'error',
                duration: 3000,
                description: err.response.data.message
            });
            setIsLoadingFile(false);
            return;
        }
        toast({
            id: 'file-upload-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Файл успешно загружен'
        });
        await getTask();
        setIsLoadingFile(false);
    };

    const tableFile = () => (
        <div>
            <Collapse defaultActiveKey="0">
                <Collapse.Panel header="Прикрепленные файлы" key="1">
                    {task?.storage?.mat_list_docs?.esigns.map((file: any) => (
                        <Flex alignItems="center" mt={2}>
                            <a
                                target="_blank"
                                href={`${process.env.REACT_APP_API_BASE_URL}${file.doc_link}`}
                                rel="noreferrer"
                                style={{
                                    padding: '3px',
                                    color: '#008abd'
                                }}
                            >
                                {file.file_name} (
                                {moment(file.date ? file.date : file.created_at).format('L')})
                            </a>
                            {role?.name === 'Подрядная организация' && (
                                <IconButton
                                    ml={1}
                                    aria-label="remove-btn"
                                    icon={<FiTrash2 />}
                                    size="sm"
                                    disabled={isLoadingFile}
                                    onClick={() => onFileDelete(file)}
                                />
                            )}
                        </Flex>
                    ))}
                    {task?.storage?.mat_list_docs?.esigns ? (
                        task?.storage?.mat_list_docs?.esigns?.length === 0 ? (
                            <Box mt={2} color="#6E6B7B">
                                Нет файлов
                            </Box>
                        ) : null
                    ) : null}
                    {role?.name === 'Подрядная организация' && (
                        <Box mt={2}>
                            <Upload
                                multiple
                                name="file"
                                showUploadList={false}
                                maxCount={1}
                                onChange={(data: any) => onFileUpload(data, task.storage.id)}
                                beforeUpload={() => false}
                            >
                                <ChakraButton
                                    aria-label="upload-btn"
                                    variant="outline"
                                    colorScheme="twitter"
                                    size="sm"
                                    disabled={isLoadingFile}
                                    isLoading={isLoadingFile}
                                >
                                    Загрузить доп.файл
                                </ChakraButton>
                            </Upload>
                        </Box>
                    )}
                </Collapse.Panel>
            </Collapse>
        </div>
    );

    return (
        <Box bgColor="white" borderRadius={10} p={2} display="flex" flexDir="column">
            <Modal
                title="Подписание АМ/АД"
                visible={isCertInfoModalOpened}
                okButtonProps={{ loading: isSignPending }}
                cancelButtonProps={{ disabled: isSignPending }}
                okText="Подписать"
                cancelText="Отмена"
                onCancel={() => setIsCertInfoModalOpened(false)}
                confirmLoading={isLoading}
                onOk={async () => {
                    setIsLoading(true);
                    setIsCertInfoModalOpened(true);
                    const formData = new FormData();
                    formData.append('file', loadedESign.file);
                    formData.append('password', eSignKey);
                    setIsSignPending(true);
                    await axios
                        .post('/esigns/signDocs', {
                            data: certInfo,
                            id: task.storage[task.tasks[0].field].id,
                            cert: certInfo.cert,
                            password: eSignKey
                        })
                        .then(() => {
                            axios
                                .post('/tasks/complete', {
                                    id: task.tasks[0].id
                                })
                                .then(() => {
                                    setIsESignKeyModalOpened(false);
                                    toast({
                                        status: 'success',
                                        title: 'Успешно подписано'
                                    });
                                    setDisabledSign(true);
                                    navigate('/tasks');
                                })
                                .catch((err) => {
                                    toast({
                                        status: 'error',
                                        title: 'Произошла ошибка'
                                    });
                                    console.error(err);
                                })
                                .finally(() => {
                                    setIsSignPending(false);
                                });
                        })
                        .catch((err) => {
                            setIsESignKeyModalOpened(false);
                            toast({
                                status: 'error',
                                title: 'Ошибка сертификата'
                            });
                            setIsSignPending(false);
                            console.error(err);
                        });
                    setIsLoading(false);
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text fontSize={18} fontWeight={600}>
                        Информация о ключе:
                    </Text>
                    <Text>Выдано для: {certInfo?.fieldcert?.WhomIssued.replace('CN=', '')}</Text>
                    <Text>Страна: {certInfo?.fieldcert?.Country.replace('C=', '')}</Text>
                    <Text>Дата выдачи: {certInfo?.fieldcert?.Date1.replace('notBefore=', '')}</Text>
                    <Text>Дата истечения: {certInfo?.fieldcert?.Date2.replace('notAfter=', '')}</Text>
                    <Text>Кем выдано: {certInfo?.fieldcert?.IssuedBy.replace('CN=', '')}</Text>
                </div>
            </Modal>
            <Modal
                title="Подписание АМ/АД"
                visible={isESignKeyModalOpened}
                onCancel={() => setIsESignKeyModalOpened(false)}
                confirmLoading={isLoading}
                onOk={async () => {
                    setIsLoading(true);
                    const formData = new FormData();
                    formData.append('file', loadedESign.file);
                    formData.append('password', eSignKey);
                    await axios
                        .post('/esigns/createSignNew', formData)
                        .then(async (res) => {
                            setCertInfo(res.data);
                            setIsCertInfoModalOpened(true);
                            setIsESignKeyModalOpened(false);
                        })
                        .catch((err) => {
                            toast({
                                status: 'error',
                                title: err.response.data.message
                            });
                            console.error(err);
                        });
                    setIsLoading(false);
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ textAlign: 'center', fontSize: '16px', marginBottom: '8px' }}>
                        Введите пароль от ключа ЭЦП
                    </span>
                    <Input
                        autoComplete="new-password"
                        placeholder="Пароль"
                        value={eSignKey}
                        onChange={(e) => setESignKey(e.target.value)}
                        disabled={isSignPending}
                        type="password"
                    />
                </div>
            </Modal>
            <Modal
                title="Подписание АМ/АД"
                visible={isSignModalOpened}
                onCancel={() => setIsSignModalOpened(false)}
                onOk={async () => {
                    if (loadedESign) {
                        setIsSignModalOpened(false);
                        setIsESignKeyModalOpened(true);
                    } else {
                        toast({
                            status: 'info',
                            title: 'Загрузите ЭЦП'
                        });
                    }
                }}
            >
                <Dragger
                    name="file"
                    maxCount={1}
                    disabled={isLoading}
                    onChange={async (info) => {
                        setLoadedESign(info);
                    }}
                    beforeUpload={() => false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Загрузите ключ ЭЦП</p>
                </Dragger>
            </Modal>
            <Modal
                title={decision === 0 ? 'Комментирование ОТКАЗА' : 'Комментирование ПРИНЯТИЯ'}
                visible={isCommentModalOpened}
                onCancel={() => setIsCommentModalOpened(false)}
                onOk={() => makeDecision()}
            >
                <TextArea
                    placeholder="Комментарий"
                    value={commentValue}
                    onChange={(e) => setCommentValue(e.target.value)}
                />
            </Modal>
            <Modal
                title="Загрузка доп.файла"
                confirmLoading={isLoading}
                visible={isSubcFileModalOpened}
                onCancel={() => setIsSubcFileModalOpened(false)}
                onOk={async () => {
                    setIsLoading(true);
                    const formData = new FormData();
                    formData.append('work_id', task.storage.id!);
                    formData.append('field', 'mat_list_docs');
                    // @ts-ignore
                    formData.append('file', loadedSubcFile.file);
                    await axios
                        .post('/works/upload', formData)
                        .then(async () => {
                            toast({
                                status: 'success',
                                title: 'Доп.файл загружен'
                            });
                            getTask();
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            toast({
                                status: 'error',
                                title: err.response.data.message
                            });
                            setIsLoading(false);
                        });
                    setIsSubcFileModalOpened(false);
                }}
            >
                <Dragger
                    name="file"
                    maxCount={1}
                    disabled={isLoading}
                    onChange={async (info) => {
                        setLoadedSubcFile(info);
                    }}
                    beforeUpload={() => false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Загрузите файл </p>
                </Dragger>
            </Modal>
            {task ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        {/*  Статусы согласования */}
                        <Box display="flex" flexDir="column">
                            <Text fontWeight={600}>Статусы согласования Мат.Листов:</Text>
                            <HStack spacing="6px" marginTop="3px">
                                {matListDepartments.map((matListDepartment) => (
                                    <Badge
                                        style={
                                            task.storage[`scans_${matListDepartment.key}_approve`] !== null
                                                ? task.storage[`scans_${matListDepartment.key}_approve`] ===
                                                  false
                                                    ? { backgroundColor: 'red' }
                                                    : { backgroundColor: '#52c41a' }
                                                : { backgroundColor: '#3FCBFF' }
                                        }
                                        count={
                                            task.storage[`scans_${matListDepartment.key}_approve`] !== null
                                                ? task.storage[`scans_${matListDepartment.key}_approve`]
                                                      .status === true
                                                    ? `${
                                                          matListDepartment.label
                                                      }: Согласовано | Дата: ${new Date(
                                                          task.storage[
                                                              `scans_${matListDepartment.key}_approve`
                                                          ].date
                                                      ).toLocaleString('ru-RU')}`
                                                    : `${
                                                          matListDepartment.label
                                                      }: Отказано | Дата: ${new Date(
                                                          task.storage[
                                                              `scans_${matListDepartment.key}_approve`
                                                          ].date
                                                      ).toLocaleString('ru-RU')}`
                                                : `${matListDepartment.label}: На рассмотрении`
                                        }
                                    />
                                ))}
                                <Badge
                                    style={
                                        task.storage.mat_lists_ol_approve !== null
                                            ? task.storage.mat_lists_ol_approve.status === false
                                                ? { backgroundColor: 'red' }
                                                : { backgroundColor: '#52c41a' }
                                            : { backgroundColor: '#3FCBFF' }
                                    }
                                    count={
                                        task.storage.mat_lists_ol_approve !== null
                                            ? task.storage.mat_lists_ol_approve.status === true
                                                ? `Отдел логистики: Согласовано | Дата: ${new Date(
                                                      task.storage.mat_lists_ol_approve.date
                                                  ).toLocaleString('ru-RU')}`
                                                : `Отдел логистики: Отказано | Дата: ${new Date(
                                                      task.storage.mat_lists_ol_approve.date
                                                  ).toLocaleString('ru-RU')}`
                                            : task.s_bs_002_main_status === 5
                                            ? `Отдел логистики: На рассмотрении`
                                            : `Отдел логистики: Ожидает подписи ГПО`
                                    }
                                />
                            </HStack>
                            <HStack spacing="6px" marginTop="10px">
                                <ChakraButton
                                    onClick={() => {
                                        openCommentHistoryModal();
                                    }}
                                    colorScheme="purple"
                                    size="sm"
                                    width="240px"
                                >
                                    <CalendarIcon marginRight={1} marginBottom="2px" />
                                    История согласований
                                </ChakraButton>
                                {/* {role?.name === 'Подрядная организация' && (
                                    <ChakraButton
                                        colorScheme="purple"
                                        size="sm"
                                        width="240px"
                                        onClick={() => {
                                            setIsSubcFileModalOpened(true);
                                        }}
                                    >
                                        <DownloadIcon marginRight={1} marginBottom="2px" />
                                        Загрузить доп.файл
                                    </ChakraButton>
                                )} */}
                                <ChakraButton
                                    colorScheme="whatsapp"
                                    size="sm"
                                    width="240px"
                                    disabled={
                                        role?.name === 'Подрядная организация' ||
                                        task.tasks[0].name === 'Подписать мат. листы'
                                            ? disabledSign
                                            : false
                                    }
                                    onClick={() => {
                                        if (
                                            role?.name === 'Подрядная организация' ||
                                            task.tasks[0].name === 'Подписать мат. листы'
                                        ) {
                                            setIsSignModalOpened(true);
                                        } else {
                                            setIsCommentModalOpened(true);
                                            setDecision(1);
                                        }
                                    }}
                                >
                                    <CheckIcon marginRight={1} marginBottom="2px" />
                                    {role?.name === 'Подрядная организация' ||
                                    task.tasks[0].name === 'Подписать мат. листы'
                                        ? 'Подписать'
                                        : 'Согласовать'}
                                </ChakraButton>
                                {/* {role?.name !== 'Подрядная организация' && */}
                                {/*    task.tasks[0].name !== 'Подписать мат. листы' && ( */}
                                {/*        <ChakraButton */}
                                {/*            colorScheme="red" */}
                                {/*            size="sm" */}
                                {/*            width="240px" */}
                                {/*            onClick={() => { */}
                                {/*                setIsCommentModalOpened(true); */}
                                {/*                setDecision(0); */}
                                {/*            }} */}
                                {/*        > */}
                                {/*            {role?.name === 'Подрядная организация' */}
                                {/*                ? 'Отправить в архив' */}
                                {/*                : 'Отправить на доработку'} */}
                                {/*        </ChakraButton> */}
                                {/*    )} */}
                            </HStack>
                        </Box>
                        {/* Кнопки экшнов */}
                    </div>
                    <Divider marginBottom={1} marginTop={1} />
                    {role?.name === 'Подрядная организация' ||
                    task.tasks[0].name === 'Подписать мат. листы' ? (
                        <>
                            <ChakraButton
                                marginLeft="auto"
                                colorScheme="purple"
                                isLoading={isCommentingPending}
                                onClick={() => {
                                    saveComments();
                                }}
                                size="sm"
                            >
                                Сохранить комментарии (все)
                            </ChakraButton>
                            <br />
                            {tableFile()}
                            <br />
                            {modifiedScansMontage && modifiedScansMontage[0] && (
                                <>
                                    <Button style={{ width: '160px' }} href={montageLink} target="_blank">
                                        Акт монтажа
                                        <LinkOutlined />
                                    </Button>
                                    <br />
                                    <Table
                                        scroll={{ x: true }}
                                        pagination={false}
                                        bordered
                                        dataSource={modifiedScansMontage}
                                        columns={subconScansMontageColumns}
                                        title={() => (
                                            <Box display="flex" flexDir="row" alignItems="center">
                                                <Text>Монтаж</Text>
                                            </Box>
                                        )}
                                    />
                                    <br />
                                </>
                            )}
                            {modifiedScansDemontage && modifiedScansDemontage[0] && (
                                <>
                                    <Button style={{ width: '160px' }} href={demontageLink} target="_blank">
                                        Акт демонтажа
                                        <LinkOutlined />
                                    </Button>
                                    <br />
                                    <Table
                                        scroll={{ x: true }}
                                        pagination={false}
                                        bordered
                                        dataSource={modifiedScansDemontage}
                                        columns={subconScansDemontageColumns}
                                        title={() => (
                                            <Box display="flex" flexDir="row" alignItems="center">
                                                <Text>Демонтаж</Text>
                                            </Box>
                                        )}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Table pagination={false} bordered dataSource={dataSource} columns={columns} />
                        </>
                    )}
                </>
            ) : (
                <Spin style={{ margin: 'auto' }} />
            )}
        </Box>
    );
};

export default Task;
