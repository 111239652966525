// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky_th {
    position: sticky !important;
    left: -1rem;
    top: 0;
    z-index: 1;
    background-color: aliceblue;
}

.sticky_td {
    position: sticky !important;
    left: -1rem;
    top: 0;
    z-index: 1;
    background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Routes/Sites/SiteList/SiteList.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,WAAW;IACX,MAAM;IACN,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,MAAM;IACN,UAAU;IACV,uBAAuB;AAC3B","sourcesContent":[".sticky_th {\n    position: sticky !important;\n    left: -1rem;\n    top: 0;\n    z-index: 1;\n    background-color: aliceblue;\n}\n\n.sticky_td {\n    position: sticky !important;\n    left: -1rem;\n    top: 0;\n    z-index: 1;\n    background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
