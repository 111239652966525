import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useToast,
    Text
} from '@chakra-ui/react';
import { Select } from 'antd';
import axios from 'axios';
import DatePicker from 'components/DatePicker';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
import * as Yup from 'yup';

export default function CarGsmPerPeriod() {
    const user = useSelector(userSelector);

    const initialValues = {
        at: moment(new Date()).startOf('month').toDate(),
        to: new Date(),
        email: user.data.email,
        car: null,
        startMonthRemainder: '',
        endMonthRemainder: '',
        isForAccounting: false
    };

    const [cars, setCars] = useState([]);
    const [reminders, setReminders] = useState<Array<any>>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const getData = async () => {
        const cars = await axios.get(`/cars-oes`);
        if (cars.status === 200) {
            const tmp = cars.data.docs.map((item: any) => ({
                label: item.state_number,
                value: item.id
            }));

            const tmpReminders = cars.data.docs.map((item: any) => ({
                id: item.id,
                startMonthRemainder: item.start_month_remainder
            }));
            setReminders(tmpReminders);
            setCars(tmp);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const ValidationSchema = Yup.object().shape({
        at: Yup.date().required('Поле обязательное'),
        to: Yup.date().required('Поле обязательное'),
        email: Yup.string().email('Введите корректный email').required('Поле email должно быть заполнено'),
        car: Yup.number().required('Поле обязательное'),
        startMonthRemainder: Yup.number()
            .min(0, 'Минимальное значение для поля 0')
            .required('Поле обязательное'),
        endMonthRemainder: Yup.number()
            .min(0, 'Минимальное значение для поля 0')
            .required('Поле обязательное'),
        isForAccounting: Yup.boolean().default(true)
    });

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const formik = useFormik({
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            if (!values.isForAccounting) {
                setSubmitting(true);
                axios
                    .post(`/gas-reports/createGasReport`, {
                        ...values,
                        at: moment(values.at).format('YYYY-MM-DD'),
                        to: moment(values.to).format('YYYY-MM-DD')
                    })
                    .then(() => {
                        toast({
                            status: 'success',
                            title: `Успешно запрошено, проверьте указанную почту`
                        });
                        setSubmitting(false);
                    })
                    .catch((error) => {
                        toast({
                            status: 'error',
                            title: 'При запросе возникла ошибка произошла ошибка'
                        });
                        console.error(error);
                        setSubmitting(false);
                    });
            } else {
                setSubmitting(false);
                setIsModalOpen(true);
            }
        },
        validationSchema: ValidationSchema
    });

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const setForAccounting = () => {
        setIsSending(true);
        axios
            .post(`/gas-reports/createGasReport`, {
                ...formik.values,
                at: moment(formik.values.at).format('YYYY-MM-DD'),
                to: moment(formik.values.to).format('YYYY-MM-DD')
            })
            .then(() => {
                toast({
                    status: 'success',
                    title: `Успешно запрошено, проверьте указанную почту`
                });
                setIsSending(false);
                setIsModalOpen(false);
            })
            .catch((error) => {
                toast({
                    status: 'error',
                    title: 'При запросе возникла ошибка произошла ошибка'
                });
                setIsSending(false);
                setIsModalOpen(false);
                console.error(error);
            });
    };

    return (
        <>
            <Modal isOpen={isModalOpen} onClose={closeModal} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Создать отчет для бухгалтерии</ModalHeader>
                    <ModalCloseButton top="0.5rem" right="0.75rem" />
                    <ModalBody
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Text textAlign="center">
                            А вы уверены, что данный отчет должен быть сформирован?
                            <br />
                            Остатки будут записаны и перенесены на следующий месяц Данный отчет необходимо
                            загрузить в 1С ЭДО
                        </Text>
                        <Button
                            colorScheme="purple"
                            width="40%"
                            mt="1rem"
                            px="10px"
                            py="5px"
                            onClick={() => setForAccounting()}
                            isLoading={isSending}
                            isDisabled={isSending}
                            loadingText="Запрашиваем данные..."
                        >
                            Подтверждаю
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Box display="flex" justifyContent="center" mt="14">
                <Box backgroundColor="white" padding="5" borderRadius="10px" maxWidth="50%">
                    <form onSubmit={formik.handleSubmit}>
                        <Box display="flex" mt="0.5">
                            <Box mr="2">
                                <FormControl
                                    isInvalid={!!formik.errors.at && (formik.touched.at as boolean)}
                                    isRequired
                                >
                                    <FormErrorMessage mt="0.5rem">{formik.errors.at}</FormErrorMessage>
                                    <DatePicker
                                        value={formik.values.at}
                                        onChange={(value) => formik.setFieldValue('at', value)}
                                        name="at"
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormErrorMessage mt="0.5rem">{formik.errors.to}</FormErrorMessage>
                                <DatePicker
                                    value={formik.values.to}
                                    onChange={(value) => formik.setFieldValue('to', value)}
                                    name="to"
                                />
                            </Box>
                        </Box>
                        <Box mt="2">
                            <FormControl
                                isInvalid={!!formik.errors.email && (formik.touched.email as boolean)}
                                isRequired
                            >
                                <FormErrorMessage mt="0.5rem">{formik.errors.email}</FormErrorMessage>
                                <Input
                                    placeholder="Email"
                                    value={formik.values.email}
                                    onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Box mt="2">
                            <FormControl isInvalid={!!formik.errors.car} isRequired>
                                {formik.touched.car}
                                <FormErrorMessage mt="0.5rem">{formik.errors.car}</FormErrorMessage>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Выберите машину"
                                    defaultValue={formik.values.car}
                                    onChange={(value) => {
                                        if (value && reminders) {
                                            formik.setFieldValue(
                                                'startMonthRemainder',
                                                reminders.find((item) => item.id === value)
                                                    .startMonthRemainder
                                            );
                                        } else {
                                            formik.setFieldValue('startMonthRemainder', '');
                                        }
                                        formik.setFieldValue('car', value);
                                    }}
                                    options={cars}
                                />
                            </FormControl>
                        </Box>
                        <Box mt="2">
                            <FormControl
                                isInvalid={
                                    !!formik.errors.startMonthRemainder &&
                                    (formik.touched.startMonthRemainder as boolean)
                                }
                                isRequired
                            >
                                <FormErrorMessage mt="0.5rem">
                                    {formik.errors.startMonthRemainder}
                                </FormErrorMessage>
                                <Input
                                    placeholder="Остаток на начало месяца"
                                    value={formik.values.startMonthRemainder}
                                    onChange={(e) =>
                                        formik.setFieldValue('startMonthRemainder', e.target.value)
                                    }
                                />
                            </FormControl>
                        </Box>
                        <Box mt="2">
                            <FormControl
                                isInvalid={
                                    !!formik.errors.endMonthRemainder &&
                                    (formik.touched.endMonthRemainder as boolean)
                                }
                                isRequired
                            >
                                <FormErrorMessage mt="0.5rem">
                                    {formik.errors.endMonthRemainder}
                                </FormErrorMessage>
                                <Input
                                    placeholder="Остаток на конец месяца"
                                    value={formik.values.endMonthRemainder}
                                    onChange={(e) =>
                                        formik.setFieldValue('endMonthRemainder', e.target.value)
                                    }
                                />
                            </FormControl>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Button
                                type="submit"
                                colorScheme="purple"
                                width="40%"
                                mt="1rem"
                                px="10px"
                                py="5px"
                                isLoading={formik.isSubmitting}
                                isDisabled={formik.isSubmitting}
                                loadingText="Запрашиваем данные..."
                                onClick={() => {
                                    formik.setFieldValue('isForAccounting', false);
                                }}
                            >
                                Запросить отчет
                            </Button>
                            <Button
                                type="submit"
                                colorScheme="purple"
                                width="40%"
                                mt="1rem"
                                px="10px"
                                py="5px"
                                loadingText="Запрашиваем данные..."
                                onClick={() => {
                                    formik.setFieldValue('isForAccounting', true);
                                }}
                            >
                                <span>
                                    Запросить отчет для <br />
                                    бухгалтерии
                                </span>
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </>
    );
}
