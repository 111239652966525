/* eslint-disable no-use-before-define */
import { useState } from 'react';
import _ from 'lodash';
// @chakra-ui
import { Badge, Stack, Heading, Button, useToast, HStack } from '@chakra-ui/react';
// services
import siteService from 'api/services/site-service';

// ----------------------------------------------------------------------

interface WorkRltAokStatusProps {
    updateWork: () => void;
    work: any;
}

export default function WorkRltAokStatus({ work, updateWork }: WorkRltAokStatusProps) {
    const [isLoading, setIsLoading] = useState(false);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const {
        s_cand_abs_prot_appr_main_status: mainStatus,
        s_cand_abs_prot_appr: statusDate,
        s_cand_abs_prot_pm_appr_status: PMapprove,
        s_cand_abs_prot_hb_appr_status: HBapprove,
        s_cand_abs_prot_ha_appr_status: HAapprove,
        s_cand_abs_prot_appr_status: RPapprove
    } = work;

    const { statusColor, statusText } = getStatus(mainStatus, statusDate);

    const PMfields = getAOKstatus(PMapprove, mainStatus);

    const RPfields = getAOKstatus(RPapprove, mainStatus);

    const HBfields = getAOKstatus(HBapprove, mainStatus);

    const HAfields = getAOKstatus(HAapprove, mainStatus);

    const disableStart = !_.includes([null, -1], mainStatus) || !work.s_cand_abs_prot?.esigns?.length;

    const handleStart = () => {
        setIsLoading(true);
        siteService
            .startProcess({
                id: work.id,
                type: 'aok'
            })
            .then(() => {
                setIsLoading(false);
                toast({
                    title: 'Процесс согласования успешно запущен',
                    status: 'success'
                });
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
                setIsLoading(false);
            });
    };

    return (
        <Stack p={1.5} alignItems="center">
            <Button
                disabled={isLoading || disableStart}
                isLoading={isLoading}
                colorScheme="purple"
                onClick={handleStart}
                variant="outline"
            >
                Начать процесс
            </Button>
            <Heading as="h4" fontSize="md">
                Статус согласования
            </Heading>
            <Badge colorScheme={statusColor} fontSize="0.8em">
                {statusText}
            </Badge>
            <HStack flexDirection="row">
                <HStack flexDirection="column">
                    <Heading as="h4" fontSize="md">
                        Специалист по проектам
                    </Heading>
                    <Badge colorScheme={PMfields.AOKstatusColor} fontSize="0.8em">
                        {PMfields.AOKstatusText}
                    </Badge>
                </HStack>
                <HStack flexDirection="column">
                    <Heading as="h4" fontSize="md">
                        Глава Acqusition
                    </Heading>
                    <Badge colorScheme={HAfields.AOKstatusColor} fontSize="0.8em">
                        {HAfields.AOKstatusText}
                    </Badge>
                </HStack>
                <HStack flexDirection="column">
                    <Heading as="h4" fontSize="md">
                        RNP
                    </Heading>
                    <Badge colorScheme={RPfields.AOKstatusColor} fontSize="0.8em">
                        {RPfields.AOKstatusText}
                    </Badge>
                </HStack>
                <HStack flexDirection="column">
                    <Heading as="h4" fontSize="md">
                        Глава отдела строительства
                    </Heading>
                    <Badge colorScheme={HBfields.AOKstatusColor} fontSize="0.8em">
                        {HBfields.AOKstatusText}
                    </Badge>
                </HStack>
            </HStack>
        </Stack>
    );
}

function getStatus(status: number | null, statusDate: string | null) {
    let statusColor;
    let statusText;

    statusDate = statusDate ? new Date(statusDate).toLocaleString('ru') : '';

    if (status === null) {
        statusColor = 'gray';
        statusText = 'Ожидает начало процесса';
    } else if (status === 1) {
        statusColor = 'yellow';
        statusText = 'Ожидает согласование PM';
    } else if (status === 2) {
        statusColor = 'green';
        statusText = `Согласовано ${statusDate}`;
    } else if (status === 3) {
        statusColor = 'yellow';
        statusText = 'Ожидает согласования RNP/Acquisition';
    } else if (status === 4) {
        statusColor = 'yellow';
        statusText = 'Ожидает согласование главы отдела строительства';
    } else {
        statusColor = 'red';
        statusText = `Отклонено ${statusDate}`;
    }
    return {
        statusColor,
        statusText
    };
}

function getAOKstatus(status: number | null, AOKmainStatus: number | null) {
    let AOKstatusColor;
    let AOKstatusText;

    if (AOKmainStatus != null) {
        if (status === null) {
            AOKstatusColor = 'yellow';
            AOKstatusText = 'На рассмотрении';
        } else if (status === 1) {
            AOKstatusColor = 'green';
            AOKstatusText = 'Согласовано';
        } else if (status === 2) {
            AOKstatusColor = 'red';
            AOKstatusText = 'Отклонено';
        }
    } else {
        AOKstatusColor = 'gray';
        AOKstatusText = 'Ожидает начало процесса';
    }

    return {
        AOKstatusColor,
        AOKstatusText
    };
}
