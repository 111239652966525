import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Textarea,
    useToast
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { matListsService } from 'api/services';
import { useFormik } from 'formik';
import Label from 'new-components/ui/Label';
import * as Yup from 'yup';
import MatListEquipmentForm from '../Equipment/MatListEquipmentForm';
import MatListActionButtons from './MatListActionButtons';
import { IMatList, IMatListEquipmentForm, IRejectMatListFrom } from '../../utils/interfaces/MatList';
import { ERoleNames } from '../../../../constants';

interface IProps {
    isDisabled?: boolean;
    matList: IMatList;
    getMatList: () => void;
    hasUnconfirmedEquipment: boolean;
    hasEditEquipmentRoles: ERoleNames[];
}

const rejectFormValidationSchema = Yup.object().shape({
    comment: Yup.string().required('Поле обязательное')
});

const MatListActions: FC<IProps> = ({
    isDisabled,
    matList,
    getMatList,
    hasUnconfirmedEquipment,
    hasEditEquipmentRoles
}) => {
    const toast = useToast();

    const [isAddNewEquipment, setIsAddNewEquipment] = useState(false);
    const [isShowRejectForm, setIsShowRejectForm] = useState(false);

    const rejectForm = useFormik<IRejectMatListFrom>({
        initialValues: {
            comment: ''
        },
        validationSchema: rejectFormValidationSchema,
        onSubmit: (values) => {
            if (!matList) return;
            matListsService
                .reject(matList.id, values)
                .then(() => {
                    getMatList();
                    toast({
                        status: 'info',
                        title: `Заявка на оборудование отклонена`
                    });
                    setIsShowRejectForm(false);
                })
                .catch((e) => {
                    toast({
                        status: 'error',
                        title: 'Ошибка',
                        description: e.response.data.message || 'Не удалось отклонить заявку'
                    });
                });
        }
    });

    const onApproveMatList = () => {
        matListsService
            .approve(matList.id)
            .then(() => {
                getMatList();
                toast({
                    status: 'success',
                    title: `Заявка успешно подтверждена!`
                });
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось подтвердить заявку'
                });
            });
    };
    const addMatListEquipment = (values: IMatListEquipmentForm) => {
        if (!matList) return;
        matListsService
            .addMatListEquipment({ ...values, matList: matList.id })
            .then((res) => {
                getMatList();
                setIsAddNewEquipment(false);
                toast({
                    status: 'success',
                    title: `Оборудование ${res.data.equipment.sap_code} успешно добавлено!`
                });
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Что-то пошло не так'
                });
            });
    };
    return (
        <Box p={3} mt={5}>
            {isShowRejectForm && (
                <Modal size="xl" isOpen={isShowRejectForm} onClose={() => setIsShowRejectForm(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Отклонение заявки</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <form onSubmit={rejectForm.handleSubmit}>
                                <FormControl>
                                    <Label text="Причина отклонения заявки" />
                                    <Textarea
                                        name="comment"
                                        value={rejectForm.values.comment}
                                        onChange={rejectForm.handleChange}
                                        placeholder="Введите причину отклонения заказа"
                                        size="sm"
                                    />
                                </FormControl>
                                <Stack alignItems="end">
                                    <ButtonGroup my={1}>
                                        <Button type="button" onClick={() => setIsShowRejectForm(false)}>
                                            Отмена
                                        </Button>
                                        <Button
                                            colorScheme="purple"
                                            disabled={!rejectForm.isValid}
                                            type="submit"
                                        >
                                            Отклонить заявку
                                        </Button>
                                    </ButtonGroup>
                                </Stack>
                            </form>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
            {isAddNewEquipment ? (
                <MatListEquipmentForm
                    onSubmit={addMatListEquipment}
                    onCancel={() => setIsAddNewEquipment(false)}
                />
            ) : (
                <MatListActionButtons
                    isDisabled={isDisabled}
                    hasEditEquipmentRoles={hasEditEquipmentRoles}
                    isDisabledStartProcess={hasUnconfirmedEquipment}
                    onReject={() => setIsShowRejectForm(true)}
                    matListStatus={matList.status}
                    onAddEquipment={() => setIsAddNewEquipment(true)}
                    onApprove={onApproveMatList}
                />
            )}
        </Box>
    );
};

export default MatListActions;
