import Sidebar from 'parts/Layout/Sidebar';
import Header from 'parts/Layout/Header';
import { Box, Flex } from '@chakra-ui/react';
import { Outlet, Navigate } from 'react-router-dom';
import ReportError from 'parts/Layout/ReportError';
import { useSelector } from 'react-redux';
import { globalSelector } from 'redux/reducers/globalSlice';
import { getState } from '../../utils/localStorage';

const Layout = () => {
    const state = getState();
    const { isSidebarOpened } = useSelector(globalSelector);

    if (!state?.user.token) {
        return <Navigate to="/login" />;
    }
    return (
        <>
            <Flex>
                <Sidebar />
                <Flex mx="auto" flexDir="column">
                    <Header />
                    <Box
                        width={isSidebarOpened ? 'calc(100vw - 290px)' : 'calc(100vw - 126px)'}
                        transition="all .2s ease-in-out;"
                        height="calc(100vh - 120px)"
                        mb="16px"
                        mt="10px"
                        filter="drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.06))"
                        overflow="auto"
                        display="flex"
                        flexDir="column"
                        overflowX="hidden"
                    >
                        <Outlet />
                    </Box>
                    <ReportError />
                    {/* <ReviewModal /> */}
                </Flex>
            </Flex>
        </>
    );
};

export default Layout;
