export enum EAcceptanceDocsStatuses {
    awaitingConfirmation = 'awaitingConfirmation',
    returnedForRevision = 'returnedForRevision',
    confirmed = 'confirmed',
    notLoaded = 'notLoaded'
}

export enum EAcceptanceDocsTypes {
    techSupervisionAct = 'techSupervisionAct',
    techAct = 'techAct',
    commissioningCert = 'commissioningCert',
    materialsReport = 'materialsReport'
}

export enum EOrderStatuses {
    returnedWithComments = 'returnedWithComments',
    completed = 'completed',
    waitingApprove = 'waitingApprove',
    cancelled = 'cancelled',
    approved = 'approved',
    revisionApprove = 'revisionApprove',
    waitingCancelApprove = 'waitingCancelApprove',
    waitingSubcAssign = 'waitingSubcAssign',
    subcSigning = 'subcSigning',
    onConfirmation = 'onConfirmation',
    signing = 'signing'
}

export enum EOrderWorkStatuses {
    inProgress = 'inProgress',
    onWarehouse = 'onWarehouse',
    commissioningCertApproved = 'commissioningCertApproved',
    commissioningCertVerification = 'commissioningCertVerification',
    matListRework = 'matListRework',
    matListVerification = 'matListVerification',
    matListApproved = 'matListApproved',
    equipmentIssued = 'equipmentIssued',
    cancelled = 'cancelled',
    returnedForRevision = 'returnedForRevision',
    agreed = 'agreed',
    tssrApproval = 'tssrApproval',
    completed = 'completed',
    notStarted = 'notStarted',
    techActVerification = 'techActVerification',
    techActRevision = 'techActRevision',
    techActApproved = 'techActApproved',
    equipmentAndProjectNotRequired = 'equipmentAndProjectNotRequired',
    projectUploaded = 'projectUploaded',
    commissioningCertUploaded = 'commissioningCertUploaded',
    commissioningCertRevision = 'commissioningCertRevision',
    equipmentNotRequired = 'equipmentNotRequired'
}
