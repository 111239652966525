import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, usePagination, Column } from 'react-table';
// @chakra-ui
import { TableContainer, Flex, Spinner, Link as ChakraLink, Box, Badge } from '@chakra-ui/react';
// redux
// hooks
import useHeaderTitle from 'hooks/useHeaderTitle';
import { useTitle } from 'react-use';
// utils
import { generateTitle } from 'utils';
// components
import { TableCustom, TablePagination } from 'components/react-table';
import LoadingOverlay from 'components/LoadingOverlay';
import useSWR from 'swr';
import { fetcher } from 'utils/client';
import createQueryString from 'utils/createQueryString';
import useFilter from 'hooks/useFilter';
import OrderTableToolbar from './components/OrderTableToolbar';
import { EOrderStatuses, EOrderWorkStatuses } from './utils/enums';
import { orderStatusesMap, orderWorkStatusesMap } from './utils/maps';

export default function OrderList() {
    const [isLoading, setIsLoading] = useState(false);

    const [parametersOrders, setParametersOrders] = useState('');
    const { data: orders } = useSWR(
        parametersOrders
            ? `/orders?${parametersOrders}
            `
            : null,
        fetcher
    );

    const [parametersTotalOrders, setParametersTotalOrders] = useState('');
    const { data: totalOrders } = useSWR(
        parametersTotalOrders
            ? `/orders/count?${parametersTotalOrders}
            `
            : `/orders/count`,
        fetcher
    );

    const [_pageCount, setPageCount] = useState(0);

    useTitle(generateTitle('Заказы'));

    useHeaderTitle('Просмотр списка заказов');

    const data = useMemo(() => orders, [orders]);

    const getColumns = () => {
        const columns = [
            {
                accessor: 'id',
                Header: 'ID заказа',
                Cell: ({ cell: { value } }: any) => (
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        as={Link}
                        target="_blank"
                        className="order-link"
                        to={`/orders/${value}`}
                    >
                        {value}
                    </ChakraLink>
                )
            },
            {
                accessor: 'work.site.s_reg',
                Header: 'Регион'
            },
            {
                accessor: 'code',
                Header: 'Код заказа'
            },
            {
                accessor: 'subc.name',
                disableSortBy: true,
                Header: 'Подрядчик'
            },
            {
                accessor: 'work.site_id',
                Header: 'ID сайта'
            },
            {
                accessor: 'work.sitename',
                Header: 'sitename'
            },
            {
                accessor: 'work.id',
                Header: 'ID ЭАП',
                Cell: ({ cell: { value } }: any) => (
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        as={Link}
                        target="_blank"
                        to={`/works/${value}`}
                    >
                        {value}
                    </ChakraLink>
                )
            },
            {
                accessor: 'status',
                Header: 'Статус заказа',
                Cell: ({ cell: { value } }: { cell: { value: EOrderStatuses } }) => (
                    <Badge colorScheme={orderStatusesMap[value]?.color || ''}>
                        {orderStatusesMap[value]?.label || value}
                    </Badge>
                )
            },
            {
                accessor: 'workStatus',
                Header: 'Статус работы',
                Cell: ({ cell: { value } }: { cell: { value: EOrderWorkStatuses } }) => (
                    <Badge colorScheme={orderWorkStatusesMap[value]?.color || ''}>
                        {orderWorkStatusesMap[value]?.label || value}
                    </Badge>
                )
            },
            {
                accessor: 'work.work_type',
                Header: 'Тип ЭАП',
                Cell: ({ cell: { value } }: any) => <Box whiteSpace="break-spaces">{value}</Box>
            },
            {
                accessor: 'workType.title',
                Header: 'Тип работ'
            },
            {
                accessor: 'updated_at',
                Header: 'Дата изменения',
                Cell: ({ cell: { value } }: any) => new Date(value).toLocaleString('ru')
            }
        ];
        return columns;
    };

    const columns = useMemo(() => getColumns(), []) as Column[];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        // pagination
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            data: data || [],
            columns,
            initialState: {
                pageIndex: 0,
                sortBy: [{ id: 'updated_at', desc: true }]
            },
            stateReducer(newState, action) {
                if (action.type === 'toggleSortBy') {
                    return { ...newState, pageIndex: 0 };
                }

                return newState;
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount: _pageCount,
            autoResetPage: false,
            autoResetSortBy: false
        },
        useSortBy,
        usePagination
    );

    const { filter, debouncedFilter, handleSetFilter, isDisabledFilter, resetFilters } = useFilter({
        onChangeCallback: () => {
            gotoPage(0);
        }
    });

    const getOrdersSWR = () => {
        const params: any = {
            _start: pageSize * pageIndex,
            _limit: pageSize,
            _sort: sortBy.length ? `${sortBy[0].id}:${sortBy[0].desc ? 'DESC' : 'ASC'}` : null,
            ...debouncedFilter
        };

        const queryString = createQueryString(params);
        const countQueryString = createQueryString(filter);

        setParametersOrders(queryString);
        setParametersTotalOrders(countQueryString);
    };

    useEffect(() => {
        getOrdersSWR();
    }, [pageIndex, pageSize, sortBy, debouncedFilter]);

    useEffect(() => {
        if (orders) {
            setIsLoading(false);
        }
    }, [orders]);

    useEffect(() => {
        if (totalOrders) {
            setPageCount(Math.ceil(totalOrders / pageSize));
        }
    }, [totalOrders]);

    return (
        <LoadingOverlay
            Spinner={<Spinner color="blue.500" />}
            active={isLoading}
            styles={{
                wrapper: (base) => ({
                    ...base,
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column'
                })
            }}
        >
            <>
                <Flex h="100%" direction="column" fontSize="initial">
                    <OrderTableToolbar
                        resetFilter={resetFilters}
                        filter={filter}
                        isDisabledFilter={isDisabledFilter}
                        onChangeFilter={handleSetFilter}
                        totalOrders={totalOrders}
                    />
                    <TableContainer
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            height: '100%',
                            padding: 1.5
                        }}
                    >
                        <TableCustom
                            size="sm"
                            getTableProps={getTableProps}
                            headerGroups={headerGroups}
                            getTableBodyProps={getTableBodyProps}
                            page={page}
                            prepareRow={prepareRow}
                        />
                    </TableContainer>
                    <TablePagination
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        pageCount={pageCount}
                        previousPage={previousPage}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        pageSizeOptions={[5, 10, 25]}
                        setPageSize={setPageSize}
                    />
                </Flex>
            </>
        </LoadingOverlay>
    );
}
