/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Select,
    Spin,
    Upload,
    Image,
    Tree,
    Input,
    Table,
    Badge,
    DatePicker,
    Collapse,
    Space
} from 'antd';
import axios from 'axios';
import {
    Button as ChakraButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    Text,
    useToast,
    Box,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Badge as ChakraBadge
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { userSelector } from 'redux/reducers/userSlice';
import { useTitle } from 'react-use';
import useHeaderTitle from 'hooks/useHeaderTitle';
import moment from 'moment';
import { UploadChangeParam } from 'antd/es/upload';
import RSDTable from 'components/RSDTable';
import Work from 'Routes/Works/Work';
import { generateTitle } from 'utils';
import 'moment/locale/ru';
import './Site.css';
import { WorkRltSAR, WorkRltSL } from 'Routes/WorksRollout/components';
import _ from 'lodash';
import SiteDetails from 'components/Site/SiteTabs/SiteDetails/SiteDetails';
import SiteLayout from 'components/Site/SiteLayout';

const Site = () => {
    moment.locale('ru');

    return (
        <SiteLayout>
            <Box bgColor="white" borderRadius={15} p={2}>
                <Tabs mb={2} isFitted>
                    <TabList>
                        <Tab>Главная</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel p={1}>
                            <SiteDetails />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </SiteLayout>
    );
};
export default Site;
