// @chakra-ui
import { Stack, Divider, Spacer, useBreakpointValue } from '@chakra-ui/react';
//
import TablePaginationButtons, { TablePaginationButtonsProps } from './TablePaginationButtons';
import TablePageSize, { TablePageSizeProps } from './TablePageSize';
import TableGoToPage, { TableGoToPageProps } from './TableGoToPage';

// ----------------------------------------------------------------------

interface TablePaginationProps extends TablePaginationButtonsProps, TablePageSizeProps, TableGoToPageProps {}

const TablePagination: React.FC<TablePaginationProps> = ({
    gotoPage,
    canPreviousPage,
    previousPage,
    pageIndex,
    pageCount,
    pageSizeOptions,
    pageSize,
    setPageSize,
    nextPage,
    canNextPage
}: TablePaginationProps) => {
    const isBase = useBreakpointValue({ base: true, md: false });

    return (
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between" spacing={1} p={1.5}>
            <TablePaginationButtons
                gotoPage={gotoPage}
                nextPage={nextPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
            />

            {!isBase ? <Divider orientation="vertical" /> : null}

            <TableGoToPage gotoPage={gotoPage} pageCount={pageCount} pageIndex={pageIndex} />

            <TablePageSize
                gotoPage={gotoPage}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                setPageSize={setPageSize}
            />

            {!isBase ? <Spacer /> : null}
        </Stack>
    );
};

export default TablePagination;
