// @chakra-ui
import { List, ListItem, Stack, Text, IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
// ----------------------------------------------------------------------

interface MultiFilePreviewProps {
    files: Array<File>;
    onRemove: Function;
    sx?: React.CSSProperties;
}

export default function MultiFilePreview({ files, onRemove, sx }: MultiFilePreviewProps) {
    return (
        <List sx={sx}>
            {files.map((file) => (
                <ListItem key={file.name}>
                    <Stack width="full" justifyContent="space-between" direction="row" alignItems="center">
                        <Text fontSize="sm" noOfLines={1}>
                            {file.name}
                        </Text>
                        <IconButton
                            size="xs"
                            variant="ghost"
                            aria-label="Remove file"
                            icon={<CloseIcon />}
                            onClick={() => onRemove(file)}
                        />
                    </Stack>
                </ListItem>
            ))}
        </List>
    );
}
