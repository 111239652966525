import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Stack, Button, Text, Box, ButtonGroup } from '@chakra-ui/react';
import Filters, { TFilterElement } from 'new-components/Filters';
import { workService } from 'api/services';
import constants from '../../../constants';
import { WORK_STATUSES } from '../utils/maps';

interface WorkTableToolbarProps {
    onExportWorks: () => void;
    filter: Record<string, string>;
    onChangeFilter: ChangeEventHandler;
    loadingExportWorks: boolean;
    totalWorks: number;
    showUpload: boolean;
    isDisabledFilter: boolean;
    upload: (data: any) => void;
    resetFilters: () => void;
}
const WorkTableToolbar: React.FC<WorkTableToolbarProps> = ({
    resetFilters,
    onExportWorks,
    filter,
    isDisabledFilter,
    onChangeFilter,
    loadingExportWorks,
    totalWorks,
    showUpload,
    upload
}) => {
    const [workTypes, setWorkTypes] = useState<string[]>([]);
    const [projects, setProjects] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [workTypesResponse, projectsResponse] = await Promise.all([
                    workService.getTypes(),
                    workService.getProjects()
                ]);

                setWorkTypes(workTypesResponse.data.work_type);
                setProjects(projectsResponse.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const filterElements: TFilterElement[] = [
        {
            type: 'input',
            placeholder: 'ID сайта',
            value: filter.site_id,
            name: 'site_id_contains'
        },
        {
            type: 'input',
            placeholder: 'Год проекта',
            value: filter.projectDate,
            name: 'projectDate_contains'
        },
        {
            type: 'select',
            name: 'workStatus.current',
            value: filter['workStatus.current'],
            emptyValue: 'Все статусы ЭАП',
            options: Object.entries(WORK_STATUSES).map(([key, value]) => ({
                value: key,
                label: value
            }))
        },
        {
            type: 'select',
            name: 'site.s_reg',
            value: filter['site.s_reg'],
            emptyValue: 'Все регионы',
            options: constants.dropdownData.s_reg.map((region) => ({ value: region, label: region }))
        },
        {
            type: 'select',
            name: 'work_type',
            value: filter.work_type,
            emptyValue: 'Все типы работ',
            options: workTypes.map((type) => ({ value: type, label: type }))
        },
        {
            type: 'select',
            name: 'project',
            value: filter.project,
            emptyValue: 'Все проекты',
            options: projects.map((project) => ({ value: project.id, label: project.title }))
        }
    ];

    return (
        <Box maxW="100%">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text fontWeight="bold" whiteSpace="nowrap">
                    Всего ЭАП ({totalWorks})
                </Text>
                <ButtonGroup>
                    {showUpload && (
                        <Button flexShrink={0} size="sm" colorScheme="teal" onClick={upload}>
                            Импорт ЭАП
                        </Button>
                    )}
                    <Button
                        flexShrink={0}
                        disabled={loadingExportWorks}
                        isLoading={loadingExportWorks}
                        size="sm"
                        colorScheme="teal"
                        onClick={onExportWorks}
                    >
                        Экспорт ЭАП
                    </Button>
                </ButtonGroup>
            </Stack>
            <Box my={2}>
                <Filters
                    onReset={resetFilters}
                    readonly={isDisabledFilter}
                    filters={filter}
                    filterElements={filterElements}
                    onChangeFilter={onChangeFilter}
                />
            </Box>
        </Box>
    );
};

export default WorkTableToolbar;
