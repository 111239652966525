import { Box, Card, CardBody, Heading, Stack, useToast, Link as ChakraLink, Spinner } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { ISigningItem } from '../interfaces/signings';

interface IProps {
    getSignings: (id: number) => Promise<AxiosResponse<ISigningItem[]>>;
    id: number;
}

const Signings: FC<IProps> = ({ getSignings, id }) => {
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const [signings, setSignings] = useState<ISigningItem[]>([]);

    const fetchSignings = () => {
        setLoading(true);
        getSignings(id)
            .then((res) => {
                setSignings(res.data);
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response?.data?.message || 'Не удалось получить подписи'
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchSignings();
        const interval = setInterval(fetchSignings, 5000);
        return () => clearInterval(interval);
    }, [id]);

    return (
        <Box shadow="md" borderWidth="1px" my={3} p={3} bg="white" rounded="md">
            <Heading as="h2" fontSize="md" mb={2}>
                Подписания
            </Heading>
            <Stack gap={2}>
                {loading && <Spinner color="blue.500" />}
                {signings.length === 0 && !loading && <div>Подписаний нет</div>}
                {signings.map((item) => (
                    <Card>
                        <CardBody p={1}>
                            <Stack gap={1}>
                                <div>Подписант: {item.user.full_name}</div>
                                <div>
                                    Документ:
                                    <ChakraLink
                                        color="blue.600"
                                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                                        isExternal
                                        href={`${process.env.REACT_APP_API_BASE_URL}${item.file.doc_link}`}
                                    >
                                        {item.file.file_name}
                                    </ChakraLink>
                                </div>
                            </Stack>
                        </CardBody>
                    </Card>
                ))}
            </Stack>
        </Box>
    );
};
export default Signings;
