import styled from 'styled-components';

const AdminPanelButton = styled.button`
    height: 44px;
    width: 230px;
    background: #7c2eab;
    color: white;
    cursor: pointer;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    border: 0;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
        background: #652d86;
    }
`;

export default AdminPanelButton;
