import { useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
// @chakra-ui
import {
    Heading,
    Button,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    VStack,
    Text,
    Stack,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
// components
import UploadSingleFile from 'components/upload/UploadSingleFile';
import FileItem from 'components/FileItem';
// services
import workService from 'api/services/work-service';

// ----------------------------------------------------------------------

interface WorkAfuFilesProps {
    work: any;
    updateWork: () => void;
}

export default function WorkAfuFiles({ updateWork, work }: WorkAfuFilesProps) {
    const [isLoading, setIsLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState<null | File>(null);

    const { isOpen, onClose, onOpen } = useDisclosure();

    const handleDrop = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const handleSubmit = () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('work_id', work.id);
        formData.append('field', 's_bs_002_ant_rep_recieved');
        formData.append('file', selectedFile);

        setIsLoading(true);
        workService
            .upload(formData)
            .then(() => {
                setIsLoading(false);
                onClose();
                updateWork();
            })
            .catch((err) => {
                setIsLoading(false);
                toast({
                    title: err.response?.data?.message,
                    status: 'error'
                });
                console.error(err);
            });
    };

    const handleRemove = (id: number) => {
        axios
            .post('/esigns/delete', {
                id
            })
            .then(() => {
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: 'Не удалось удалить файл',
                    status: 'error'
                });
                console.error(err);
            });
    };

    const disableAction = _.includes([1, 2], work.ant_rep_status);

    return (
        <>
            <Stack p={1.5} width="full" overflowX="auto">
                <Box width="700px">
                    <Button size="sm" colorScheme="purple" onClick={onOpen} disabled={disableAction}>
                        Загрузить файл
                    </Button>
                    <Heading as="h4" fontSize="md" mt={1}>
                        Загруженные файлы
                    </Heading>
                    <VStack mt={2} spacing={0.5}>
                        {work.s_bs_002_ant_rep_recieved?.esigns?.length > 0 ? (
                            work.s_bs_002_ant_rep_recieved.esigns.map(
                                ({ id, file_name, created_at, doc_link, banOnDeletion }: any) => (
                                    <FileItem
                                        key={id}
                                        fileName={file_name}
                                        date={created_at}
                                        onDownload={() => {
                                            window.open(`${process.env.REACT_APP_API_BASE_URL}${doc_link}`);
                                        }}
                                        onRemove={() => handleRemove(id)}
                                        disableRemove={disableAction || banOnDeletion}
                                    />
                                )
                            )
                        ) : (
                            <Text>Нет загруженных файлов</Text>
                        )}
                    </VStack>
                </Box>
            </Stack>
            <Modal onClose={onClose} isOpen={isOpen} isCentered autoFocus={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Загрузка файла</ModalHeader>
                    <ModalCloseButton top="0.5rem" right="0.75rem" />
                    <ModalBody>
                        <UploadSingleFile
                            file={selectedFile}
                            accept={{
                                'application/pdf': ['.pdf'],
                                'application/msword': ['.doc'],
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                                    '.docx'
                                ]
                            }}
                            maxSize={31457280}
                            onDrop={handleDrop}
                            onRemove={() => {
                                setSelectedFile(null);
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            disabled={isLoading}
                            colorScheme="purple"
                            onClick={handleSubmit}
                        >
                            Подтвердить
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
