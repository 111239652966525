import {
    Link as ChakraLink,
    Badge,
    Button,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Stack
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import HasAccess from 'guards/HasAccess';
import { TTrStatus } from 'Routes/Order/utils/types/MatList';
import { useNavigate } from 'react-router-dom';
import { GrDocumentPdf } from 'react-icons/gr';
import { SiMicrosoftexcel } from 'react-icons/si';
import { ERoleNames } from '../../../../constants';
import { ITr } from '../../utils/interfaces/MatList';
import TrDetailModal from './TrDetailModal';

interface IProps {
    rolesHasEditTrsStatus: ERoleNames[];
    trs: ITr[];
    matListId: number;
}

const TrsTable: FC<IProps> = ({ matListId, trs, rolesHasEditTrsStatus }) => {
    const navigate = useNavigate();
    const [selectedTrId, setSelectedTrId] = useState<number | null>(null);
    const statusesMap: Record<TTrStatus, { label: string; color: string }> = {
        new: {
            label: 'Новый',
            color: 'default'
        },
        finished: {
            label: 'Выдано',
            color: 'green'
        },
        ready_to_receive: {
            label: 'Готов к выдаче',
            color: 'purple'
        }
    };
    const buttonsMap: Partial<Record<TTrStatus, { label: string; color: string }>> = {
        new: {
            label: 'Готов к выдаче',
            color: 'purple'
        },
        ready_to_receive: {
            label: 'Выдать TR',
            color: 'green'
        }
    };

    return (
        <>
            {selectedTrId && <TrDetailModal onClose={() => setSelectedTrId(null)} trId={selectedTrId} />}
            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>TR номер</Th>
                            <Th>TR-документ</Th>
                            <Th>Статус</Th>
                            <Th>Накладная</Th>
                            <Th>Номер склада</Th>
                            <HasAccess roleNames={rolesHasEditTrsStatus}>
                                <Th>действия</Th>
                            </HasAccess>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {trs.map((item, i) => (
                            <Tr>
                                <Td>{i + 1}</Td>
                                <Td>
                                    <Button
                                        onClick={() => setSelectedTrId(item.id)}
                                        type="button"
                                        variant="link"
                                        colorScheme="purple"
                                    >
                                        {item.sapNumber}
                                    </Button>
                                </Td>
                                <Td>
                                    <Stack direction="row" alignItems="center" gap="10px">
                                        <ChakraLink
                                            color="blue.600"
                                            _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                                            isExternal
                                            href={`${process.env.REACT_APP_API_BASE_URL}${item.esignPdf.doc_link}`}
                                        >
                                            <GrDocumentPdf size="20px" />
                                        </ChakraLink>
                                        <ChakraLink
                                            color="blue.600"
                                            _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                                            isExternal
                                            href={`${process.env.REACT_APP_API_BASE_URL}${item.esignExcel.doc_link}`}
                                        >
                                            <SiMicrosoftexcel size="23px" />
                                        </ChakraLink>
                                    </Stack>
                                </Td>
                                <Td>
                                    <Badge colorScheme={statusesMap[item.status].color}>
                                        {statusesMap[item.status].label}
                                    </Badge>
                                </Td>
                                <Td>
                                    {item.esignInvoice && (
                                        <ChakraLink
                                            color="blue.600"
                                            _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                                            isExternal
                                            href={`${process.env.REACT_APP_API_BASE_URL}${item.esignInvoice.doc_link}`}
                                        >
                                            <GrDocumentPdf size="20px" />
                                        </ChakraLink>
                                    )}
                                </Td>
                                <Td>{item.warehouseNumber}</Td>
                                <HasAccess roleNames={rolesHasEditTrsStatus}>
                                    {buttonsMap[item.status] && (
                                        <Td>
                                            <Button
                                                onClick={() => {
                                                    navigate(
                                                        `/mat-list/${matListId}/trs/${item.id}/issuance`
                                                    );
                                                }}
                                                size="sm"
                                                type="button"
                                                colorScheme={buttonsMap[item.status]?.color}
                                            >
                                                {buttonsMap[item.status]?.label}
                                            </Button>
                                        </Td>
                                    )}
                                </HasAccess>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};

export default TrsTable;
