import { Button, Fade, Flex, Input, Text } from '@chakra-ui/react';
import { Table } from 'antd';
import axios from 'axios';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function DGUEdit() {
    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Бренд генераторв',
            dataIndex: 'generator_brand',
            key: 'generator_brand'
        },
        {
            title: 'Тип топлива',
            dataIndex: 'fuel',
            key: 'fuel'
        },
        {
            title: 'Количество литров',
            dataIndex: 'volume',
            key: 'volume'
        },
        {
            title: 'Дата заправки',
            dataIndex: 'fuelingDate',
            key: 'fuelingDate'
        },
        {
            title: '',
            key: 'key',
            dataIndex: 'key',
            render: (text: any, record: any) => (
                <Link to={`DGU/${record.id}`}>
                    <Button
                        style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}
                        colorScheme="purple"
                    >
                        Редактировать
                    </Button>
                </Link>
            )
        }
    ];

    const [data, setData] = useState<Array<any>>([]);
    const [count, setCount] = useState(0);
    const [start, setStart] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [generatorBrand, setgeneratorBrand] = useState('');
    const [userName, setUserName] = useState('');
    const [date, setDate] = useState('');

    const debouncedUserName = useDebounce(userName, 500);

    const debouncedGeneratorBrand = useDebounce(generatorBrand, 500);
    const debouncedDate = useDebounce(date, 500);

    const getData = async () => {
        try {
            setIsLoading(true);
            let req = `/gas-reports/count?goal=dgu&_start=${start}&_limit=10&_sort=created_at:DESC`;
            if (debouncedGeneratorBrand !== '')
                req += `&_where[dgu.generator_brand_contains]=${debouncedGeneratorBrand}`;

            if (debouncedUserName !== '') req += `&_where[name_contains]=${debouncedUserName}`;

            if (debouncedDate !== '')
                req = req
                    .split('&_sort=created_at:DESC')
                    .join(
                        `&_sort=created_at:DESC&_where[fuelingDate_gte]=${moment(debouncedDate).format(
                            'YYYY-MM-DD'
                        )}&_where[fuelingDate_lte]=${moment(debouncedDate)
                            .add(1, 'days')
                            .format('YYYY-MM-DD')}`
                    );

            const [count, gasReports] = await Promise.all<[number, Array<any>]>([
                (await axios.get(req)).data,
                (await axios.get(req.split('count').join('find-without-pagination'))).data
            ]);

            const tmpData = gasReports.map((item: any) => ({
                name: item.name,
                fuel: item.fuel,
                volume: item.volume,
                generator_brand: item.dgu.generator_brand,
                fuelingDate: moment(item.fuelingDate).format('DD.MM.YYYY HH:mm'),
                id: item.id
            }));

            setData(tmpData);
            setCount(count);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
    }, [start, debouncedGeneratorBrand, debouncedUserName, debouncedDate]);

    const onPaginationChange = (page: number) => {
        console.log(page - 1);
        setStart((page - 1) * 10);
    };
    const onPageSizeChange = (current: number) => {
        setStart((current - 1) * 10);
    };

    return (
        <Fade in>
            <Table
                bordered
                loading={isLoading}
                scroll={{ x: true }}
                pagination={{
                    pageSize: 10,
                    total: count || 1,
                    onChange: onPaginationChange,
                    onShowSizeChange: onPageSizeChange
                }}
                title={() => (
                    <Flex alignItems="center">
                        <Text fontSize="md" fontWeight={600}>
                            Список ГСМ ДГУ
                        </Text>
                        <Input
                            type="text"
                            placeholder="Имя пользователя"
                            maxWidth="250"
                            ml="10"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                        <Input
                            type="text"
                            placeholder="Бренд генератора"
                            maxWidth="250"
                            ml="10"
                            value={generatorBrand}
                            onChange={(e) => setgeneratorBrand(e.target.value)}
                        />

                        <Input
                            type="date"
                            maxWidth="250"
                            ml="10"
                            value={moment(date).format('YYYY-MM-DD')}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Flex>
                )}
                dataSource={data}
                columns={columns}
            />
        </Fade>
    );
}
