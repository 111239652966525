/* eslint-disable import/prefer-default-export */
import { Accept } from 'react-dropzone';
// form
import { Field, FieldProps } from 'formik';
// @chakra-ui
import { FormErrorMessage } from '@chakra-ui/react';
// components
import { UploadSingleFile } from 'components/upload';

// ----------------------------------------------------------------------

interface FUploadSingleFileProps {
    name: string;
    accept?: Accept;
    maxSize: number;
    onDrop: (acceptedFiles: Array<File>) => void;
    onRemove: Function;
}

export function FUploadSingleFile({ name, onRemove, accept, ...other }: FUploadSingleFileProps) {
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps) => {
                const checkError = !!meta.error && !field.value;

                return (
                    <UploadSingleFile
                        file={field.value}
                        error={checkError}
                        helperText={
                            checkError ? <FormErrorMessage px={2}>{meta.error}</FormErrorMessage> : undefined
                        }
                        onRemove={onRemove}
                        accept={accept || {}}
                        {...other}
                    />
                );
            }}
        </Field>
    );
}
