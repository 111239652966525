/* eslint-disable @typescript-eslint/no-unused-vars */
// @chakra-ui
import {
    Stack,
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    Spacer,
    Button,
    useBreakpointValue,
    Select
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';

// ----------------------------------------------------------------------

interface BalanceTableToolbarProps {
    filterSiteId: string;
    filterEquipId: string;
    onFilterSiteId: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFilterEquipId: (event: React.ChangeEvent<HTMLInputElement>) => void;
    totalEquipBalances: number;
}

export default function OrderTableToolbar({
    filterSiteId,
    filterEquipId,
    onFilterSiteId,
    onFilterEquipId,
    totalEquipBalances
}: BalanceTableToolbarProps) {
    const alignItems = useBreakpointValue({ base: 'stretch', md: 'center' });

    return (
        <Stack
            width="full"
            direction={{ base: 'column', md: 'row' }}
            p={1.5}
            spacing={1}
            justifyContent="space-between"
            alignItems={alignItems}
        >
            <Text fontWeight="bold" whiteSpace="nowrap">
                Всего ({totalEquipBalances})
            </Text>
            <InputGroup size="sm" w="xs">
                <InputLeftElement pointerEvents="none">
                    <Search2Icon color="gray.300" />
                </InputLeftElement>
                <Input
                    paddingLeft={4}
                    placeholder="Поиск по продукт коду"
                    defaultValue={filterEquipId || ''}
                    onChange={onFilterEquipId}
                />
            </InputGroup>

            <InputGroup size="sm" w="xs">
                <InputLeftElement pointerEvents="none">
                    <Search2Icon color="gray.300" />
                </InputLeftElement>
                <Input
                    paddingLeft={4}
                    placeholder="Поиск по ID сайта"
                    defaultValue={filterSiteId || ''}
                    onChange={onFilterSiteId}
                />
            </InputGroup>

            <Spacer />
        </Stack>
    );
}
