import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Table } from 'antd';
import {
    Box,
    useToast,
    Button as ChakraButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { equipmentsService } from '../../api/services';

interface IEquipmentColumn {
    field: string;
    disabled?: boolean;
    value: string;
}

const Equipment = () => {
    const { equipmentId } = useParams();
    const [cols, setCols] = useState<IEquipmentColumn[]>([]);
    const [equipmentData, setEquipmentData] = useState<Record<string, string>>();
    const [modalOpen, setModalOpen] = useState(false);
    const [tempFieldSave, setTempFieldSave] = useState<Record<string, string>>();
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const navigate = useNavigate();

    const getEquipmentInfo = useCallback(async () => {
        if (equipmentId) {
            const { data } = await equipmentsService.getByEquipmentId(equipmentId);
            setEquipmentData(data);
            const cols: IEquipmentColumn[] = [
                {
                    field: 'Sap код',
                    value: data.sap_code
                },
                {
                    field: 'Категория',
                    value: data.category.title,
                    disabled: true
                },
                {
                    field: 'Название',
                    value: data.s_equip_title
                },
                {
                    field: 'Код продукта',
                    value: data.s_product_code
                },
                {
                    field: 'Единица измерения',
                    value: data.s_unit
                }
            ];
            setCols(cols);
        }
    }, []);

    useEffect(() => {
        getEquipmentInfo();
    }, [getEquipmentInfo]);

    const handleEquipmentFieldChange = (field: string, value: string) => {
        const temp = { ...tempFieldSave, [field]: value };
        setTempFieldSave(temp);
    };

    const handleFieldSave = async (field: string) => {
        if (equipmentId && tempFieldSave) {
            try {
                await equipmentsService.updateEquipment(equipmentId, field, tempFieldSave[field]);
                toast({
                    status: 'success',
                    title: `${field} успешно обновлен`
                });
            } catch (err) {
                toast({
                    status: 'error',
                    title: `При обновлении произошла ошибка`
                });
            }
        }
    };

    const handleEquipmentDelete = async () => {
        if (equipmentId) {
            try {
                await equipmentsService.deleteEquipment(equipmentId);
                navigate('/equipments');
            } catch (err) {
                toast({
                    status: 'error',
                    title: `При обновлении произошла ошибка`
                });
            }
        }
    };

    const bs002Columns = [
        {
            title: 'Поле',
            dataIndex: 'field',
            key: 'field',
            width: 200
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            width: 200,
            render: (any: any, record: IEquipmentColumn) =>
                record.disabled ? (
                    <span>{record.value}</span>
                ) : (
                    <>
                        <Input
                            style={{ minWidth: '230px', maxWidth: 'fit-content' }}
                            defaultValue={record.value}
                            onChange={(e) => {
                                if (record.value !== e.target.value) {
                                    handleEquipmentFieldChange(record.field, e.target.value);
                                }
                            }}
                        />
                        <Button onClick={() => handleFieldSave(record.field)} style={{ marginLeft: '6px' }}>
                            Сохранить
                        </Button>
                    </>
                )
        }
    ];

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    return (
        <Box p={2} borderRadius={10} bg="white" display="flex" flexDir="column">
            <ChakraButton mb="20px" w="fit-content" onClick={handleModalOpen} colorScheme="red">
                Удалить Оборудование
            </ChakraButton>
            <Modal isOpen={modalOpen} onClose={handleModalClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Удалить это оборудование?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <>
                            Вы собираетесь удалить оборудование {equipmentData?.s_equip_title}. Вы уверены в
                            этом?
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <ChakraButton colorScheme="purple" mr="auto" onClick={handleModalClose}>
                            Нет
                        </ChakraButton>
                        <ChakraButton colorScheme="red" onClick={handleEquipmentDelete}>
                            Удалить
                        </ChakraButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Table
                tableLayout="fixed"
                loading={false}
                pagination={false}
                bordered
                style={{ width: '100%' }}
                dataSource={cols}
                columns={bs002Columns}
            />
        </Box>
    );
};

export default Equipment;
