// @chakra-ui
import { Box } from '@chakra-ui/react';
//
import ActList from './ActList';

export default function Orders() {
    return (
        <Box bgColor="white" borderRadius={10} p={2}>
            <ActList />
        </Box>
    );
}
