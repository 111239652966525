import { Heading } from '@chakra-ui/react';
import ComponentWithChildren from 'utils/componentWithChildren';

/**
 * @component
 * @param { ReactNode } children ReactNode
 */
const AuthHeading: ComponentWithChildren = ({ children }) => (
    <Heading as="h1" fontWeight={500} fontSize="32px" mb={1}>
        {children}
    </Heading>
);

export default AuthHeading;
