import { Box, Button, FormControl, FormErrorMessage, useToast } from '@chakra-ui/react';
import { Select } from 'antd';
import axios from 'axios';
import Input from 'components/Input/Input';
import { useFormik } from 'formik';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

const locations = [
    { value: 'Актау', lable: 'Актау' },
    { value: 'Тараз', lable: 'Тараз' },
    { value: 'Шымкент', lable: 'Шымкент' },
    { value: 'Актобе', lable: 'Актобе' },
    { value: 'Атырау', lable: 'Атырау' },
    { value: 'Караганда', lable: 'Караганда' },
    { value: 'Кокшетау', lable: 'Кокшетау' },
    { value: 'Костанай', lable: 'Костанай' },
    { value: 'Кызылорда', lable: 'Кызылорда' },
    { value: 'Павлодар', lable: 'Павлодар' },
    { value: 'Петропавловск', lable: 'Петропавловск' },
    { value: 'Семей', lable: 'Семей' },
    { value: 'Тараз', lable: 'Тараз' },
    { value: 'Усть-Каменогорск', lable: 'Усть-Каменогорск' },
    { value: 'Уральск', lable: 'Уральск' },
    { value: 'Алматы', lable: 'Алматы' },
    { value: 'Астана', lable: 'Астана' }
];

const isStationary = [
    { value: 'БГУ', lable: 'БГУ' },
    { value: 'ДГУ', lable: 'ДГУ' }
];

const initialValues = {
    generator_brand: '',
    fuel_per_hour: '',
    isStationary: null,
    location: '',
    uniqId: ''
};

const ValidationSchema = Yup.object().shape({
    generator_brand: Yup.string().required('Поле обязательное'),
    fuel_per_hour: Yup.number().required('Поле обязательное'),
    isStationary: Yup.string().nullable(true).required('Поле обязательное'),
    location: Yup.string().required('Поле обязательное'),
    uniqId: Yup.string().required('Поле обязательное')
});

export default function CreateGenerator() {
    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const [siteOptions, setSiteOptions] = useState<{ id: number; s_new_site_id: string }[]>([]);

    const [sites, setSites] = useState<Array<any>>([]);
    const [siteIdSearchText, setSiteIdSearchText] = useState<string>('');
    const debouncedSiteIdSearchText = useDebounce(siteIdSearchText, 700);

    const getData = async () => {
        const sitesData = await axios.get('/sites');
        if (sitesData.status === 200) {
            const sitesTmp = sitesData.data.map((item: any) => ({ value: item.s_sid, label: item.s_sid }));
            setSites(sitesTmp);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            setSubmitting(true);
            console.log(values);
            axios
                .post(`/generators`, {
                    ...values,
                    isStationary: values.isStationary,
                    generator_brand: `${values.uniqId.split('_').join('-')}_${values.generator_brand}`
                })
                .then(() => {
                    toast({
                        status: 'success',
                        title: `Генератор успешно создан`
                    });
                    setSubmitting(false);
                    formik.setFieldValue('isStationary', null);
                    formik.setFieldValue('location', '');
                    resetForm();
                })
                .catch((error) => {
                    toast({
                        status: 'error',
                        title: 'При запросе возникла ошибка произошла ошибка'
                    });
                    console.error(error);
                    setSubmitting(false);
                });
        },
        validationSchema: ValidationSchema
    });

    const handleSearchSiteOptions = (searchText: string) => {
        if (!searchText) {
            setSiteOptions([]);
            return;
        }
        axios.get(`/sitesName?site=${searchText}`).then((res) => {
            console.log(res);
            setSiteOptions(res.data);
        });
    };

    useEffect(() => {
        handleSearchSiteOptions(debouncedSiteIdSearchText);
    }, [debouncedSiteIdSearchText]);

    return (
        <Box display="flex" justifyContent="center" mt="14">
            <Box backgroundColor="white" padding="5" borderRadius="10px" width="30%">
                <form onSubmit={formik.handleSubmit}>
                    <Box>
                        <FormControl
                            isInvalid={
                                !!formik.errors.generator_brand && (formik.touched.generator_brand as boolean)
                            }
                            isRequired
                        >
                            <FormErrorMessage mt="0.5rem">{formik.errors.generator_brand}</FormErrorMessage>
                            <Input
                                placeholder="производитель генератора"
                                value={formik.values.generator_brand}
                                onChange={(e) => formik.setFieldValue('generator_brand', e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <FormControl
                            isInvalid={
                                !!formik.errors.fuel_per_hour && (formik.touched.fuel_per_hour as boolean)
                            }
                            isRequired
                        >
                            <FormErrorMessage mt="0.5rem">{formik.errors.fuel_per_hour}</FormErrorMessage>
                            <Input
                                placeholder="норма расхода"
                                value={String(formik.values.fuel_per_hour)}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'fuel_per_hour',
                                        e.target.value.replace(/\D+\/,/g, '')
                                    )
                                }
                            />
                        </FormControl>
                    </Box>
                    <Box mt="2">
                        <FormControl
                            isInvalid={
                                !!formik.errors.isStationary && (formik.touched.isStationary as boolean)
                            }
                            isRequired
                        >
                            <FormErrorMessage mt="0.5rem">{formik.errors.isStationary}</FormErrorMessage>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Выберите тип генератора"
                                defaultValue={[]}
                                onChange={(value: any) => {
                                    const tmp = value === 'ДГУ';
                                    formik.setFieldValue('isStationary', tmp);
                                    if (tmp === true) {
                                        formik.setFieldValue('uniqId', sites[0].value);
                                    }

                                    if (formik.values.isStationary === false) {
                                        formik.setFieldValue('uniqId', '');
                                    }
                                }}
                                options={isStationary}
                            />
                        </FormControl>
                    </Box>
                    {formik.values.isStationary !== null && (
                        <Box mt="2">
                            <FormControl
                                isInvalid={!!formik.errors.uniqId && (formik.touched.uniqId as boolean)}
                                isRequired
                            >
                                <FormErrorMessage mt="0.5rem">{formik.errors.uniqId}</FormErrorMessage>
                                {formik.values.isStationary === true && (
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Выберите сайт ДГУ"
                                        optionFilterProp="children"
                                        showSearch
                                        value={formik.values.uniqId || undefined}
                                        onChange={(value: any) => {
                                            formik.setFieldValue('uniqId', value);
                                        }}
                                        onSearch={(value) => {
                                            setSiteIdSearchText(value);
                                        }}
                                    >
                                        {siteOptions.map((opt) => (
                                            <Select.Option value={opt.s_new_site_id} key={opt.s_new_site_id}>
                                                {opt.s_new_site_id}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                {formik.values.isStationary === false && (
                                    <Input
                                        placeholder="Идентификатор 1С или уникальный идентификатор"
                                        value={formik.values.uniqId}
                                        onChange={(e) => formik.setFieldValue('uniqId', e.target.value)}
                                    />
                                )}
                            </FormControl>
                        </Box>
                    )}
                    <Box mt="2">
                        <FormControl
                            isInvalid={!!formik.errors.location && (formik.touched.location as boolean)}
                            isRequired
                        >
                            <FormErrorMessage mt="0.5rem">{formik.errors.location}</FormErrorMessage>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Выберите город"
                                defaultValue={[]}
                                onChange={(value) => {
                                    formik.setFieldValue('location', value);
                                }}
                                options={locations}
                            />
                        </FormControl>
                    </Box>
                    <Button colorScheme="purple" marginTop="3" type="submit">
                        Зарегистрировать генератор
                    </Button>
                </form>
            </Box>
        </Box>
    );
}
