import {
    Badge,
    Box,
    Button,
    Text,
    Textarea,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Spinner,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td
} from '@chakra-ui/react';
import axios from 'axios';
import { UploadMultiFile } from 'components/upload';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { generateTitle } from 'utils';
import addHours from 'utils/addHours';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
import _ from 'lodash';
import workIntegrationService from 'api/services/work-integration-service';
import DecisionHistory from 'components/DecisionHistory';
import { Image } from 'antd';

type TIntegrationType = 'ti' | 'tr' | 'bss' | 'rno' | 'rnp' | 'noc';

type TApproveValue = 'Not started' | 'Started' | 'Finished';

// const processSequence: TIntegrationType[] = ['ti', 'tr', 'rnp', 'bss', 'rno', 'noc'];

const INTEGRATION_ACCESSES = {
    ti: {
        roles: [
            'admin',
            'construct',
            'other',
            'head_build',
            'head_of_netw_develop',
            'noc_admin',
            'maintenance'
        ]
    },
    tr: {
        roles: ['admin', 'tr', 'head_trans', 'noc_admin']
    },
    bss: {
        roles: ['admin', 'bss', 'noc_admin']
    },
    rno: {
        roles: ['admin', 'rno', 'noc_admin']
    },
    rnp: {
        roles: ['admin', 'rnp', 'noc_admin']
    },
    noc: {
        roles: ['admin', 'noc', 'noc_admin']
    }
};

function isTypeAllowedReassign(
    currStepDep: TIntegrationType,
    currDep: TIntegrationType,
    reassignemntStack: string,
    processSequence: TIntegrationType[]
) {
    const reassignemntStackArr = !reassignemntStack ? [] : reassignemntStack.split(',');
    const lastStepDep = !reassignemntStackArr.length ? currStepDep : _.first(reassignemntStackArr);
    const index = _.indexOf(processSequence, lastStepDep);
    const allowedDeps = _.slice(processSequence, 0, index + 1);
    if (reassignemntStackArr.find((el) => el === currDep)) {
        return false;
    }
    return _.includes(allowedDeps, currDep);
}

interface IWorkApprove {
    status: boolean | null;
    user: any;
    comment: string | null;
    userComment: string | null;
    created_at: string;
    updated_at: string;
}

interface IIntegration {
    id: number;
    assigned_step: TIntegrationType;
    ti_approve: IWorkApprove | null;
    tr_approve: IWorkApprove | null;
    bss_approve: IWorkApprove | null;
    rno_approve: IWorkApprove | null;
    rnp_approve: IWorkApprove | null;
    noc_approve: IWorkApprove | null;
    logs: any;
    work: any;
    reAssigned: boolean | null;
    photos: any;
    isFinalPhase: boolean;
    reassignmentStack: string;
    status: 'IDLE' | 'IN_PROGRESS' | 'FINISHED';
    actionsHistory: string;
}

const WorkIntegration = () => {
    const params = useParams();
    const toast = useToast();
    const { integrationID } = params;

    const [pageTitle, setPageTitle] = useState('Интеграции ЭАП');
    const [integration, setIntegration] = useState<IIntegration | null>(null);
    const { isOpen: isLogsModalOpened, onOpen: onLogsModalOpen, onClose: onLogsModalClose } = useDisclosure();
    const {
        isOpen: isReassignModalOpened,
        onOpen: onReassignModalOpen,
        onClose: onReassignModalClose
    } = useDisclosure();
    const {
        isOpen: isUploadPhotoModalOpened,
        onOpen: onUploadPhotoModalOpen,
        onClose: onUploadPhotoModalClose
    } = useDisclosure();
    const [selectedReassign, setSelectedReassign] = useState<null | TIntegrationType>(null);
    const [isAssignLoading, setIsAssignLoading] = useState(false);
    const [isActionLoading, setIsActionLoading] = useState(false);
    const [isUploadPhotosLoading, setIsUploadPhotosLoading] = useState(false);
    const [photos, setPhotos] = useState<any[]>([]);
    const [comment, setComment] = useState('');
    const [processSequence, setProcessSequence] = useState<TIntegrationType[] | null>(null);

    const [requiresComment, doAction] = useState((): string | null => null);

    const { role } = useSelector(userSelector);

    const getWorkIntegration = async () => {
        await axios
            .get(`/work-integrations/${integrationID}`)
            .then((res) => {
                setIntegration(res.data.workIntegration);
                setProcessSequence(res.data.processSequence);
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: 'Ой, при получении интеграций произошла ошибка, попробуйте еще раз',
                    status: 'error'
                });
            });
    };

    const assignIntegration = async () => {
        if (!comment) toast({ status: 'error', title: 'Укажите комментарий' });
        else {
            setIsAssignLoading(true);
            await axios
                .post(`/work-integrations/assign`, {
                    integrationType: selectedReassign,
                    id: integrationID,
                    comment
                })
                .then(() => {
                    toast({
                        status: 'success',
                        title: `Успешно переназначено на ${selectedReassign?.toUpperCase()}`
                    });
                    getWorkIntegration();
                    setComment('');
                    onReassignModalClose();
                })
                .catch((err) => {
                    console.error(err);
                    toast({
                        status: 'error',
                        title: `При назначении на ${selectedReassign?.toUpperCase()} произошла ошибка`
                    });
                })
                .finally(() => {
                    setIsAssignLoading(false);
                });
        }
    };

    const doActionOnCurrentIntegration = async (actionType: 'start' | 'finish') => {
        setIsActionLoading(true);
        await axios
            .post(`/work-integrations/action`, {
                type: actionType,
                comment,
                id: integrationID
            })
            .then(() => {
                getWorkIntegration();
                toast({
                    status: 'success',
                    title: `Успешно`
                });
                setComment('');
                onReassignModalClose();
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: 'Не удалось выполнить действие',
                    status: 'error'
                });
            })
            .finally(() => {
                setIsActionLoading(false);
            });
    };

    const uploadPhotos = async () => {
        setIsUploadPhotosLoading(true);
        for (let i = 0; i < photos.length; i += 1) {
            const data = new FormData();
            data.append('id', integration?.id?.toString() || '');
            data.append('file', photos[i]);

            // eslint-disable-next-line no-await-in-loop
            await axios
                .post('/work-integrations/uploadPhotos', data)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    toast({
                        status: 'error',
                        title: 'Не удалось загрузить фото'
                    });
                });
        }
        setIsUploadPhotosLoading(false);
        toast({
            status: 'success',
            title: 'Фото успешно загружены'
        });
        onUploadPhotoModalClose();
        doActionOnCurrentIntegration('start');
    };

    useHeaderTitle(pageTitle);
    useTitle(pageTitle);

    useEffect(() => {
        getWorkIntegration();
    }, []);

    useEffect(() => {
        if (integration) {
            setPageTitle(
                generateTitle(`Этапы ЭАП ${integration.work.id} по ${integration.work.site.s_new_site_id}`)
            );
        }
    }, [integration]);

    const getApproveValueByType = (type: TIntegrationType): TApproveValue => {
        const approve = integration![`${type}_approve`];
        if (approve) {
            if (approve.status === null) return 'Not started';
            if (approve.status === false) return 'Started';
            return 'Finished';
        }
        return 'Not started';
    };

    const getBadgeStatusColor = (type: TIntegrationType): string => {
        const value = getApproveValueByType(type);

        if (value === 'Not started') return 'gray';
        if (value === 'Started') return 'telegram';
        return 'green';
    };

    const getDate = (type: TIntegrationType): string | undefined | null =>
        addHours(integration![`${type}_approve`]?.updated_at, 0);

    const handleRemovePhoto = (file: File) => {
        const filteredItems = [...photos].filter((_file) => _file !== file);
        setPhotos(filteredItems);
    };

    const handleRestoreProcess = () => {
        workIntegrationService
            .restoreProcess({ id: integration!.id })
            .then(() => {
                toast({
                    title: 'Процесс возобновлен',
                    status: 'success'
                });
                getWorkIntegration();
            })
            .catch((error) => {
                toast({
                    title: error.response.data.message,
                    status: 'error'
                });
            });
    };

    const isMyStep = useMemo(() => {
        const currentStep = integration?.assigned_step;
        if (!currentStep) return false;
        const stepRoles = INTEGRATION_ACCESSES[currentStep].roles;
        const currentRole = role?.type;
        return _.includes(stepRoles, currentRole);
    }, [integration?.assigned_step, role?.type]);

    if (!processSequence) {
        return null;
    }

    const showRestoreBtn =
        integration &&
        integration.assigned_step === 'noc' &&
        role &&
        _.includes(['noc', 'admin', 'noc_admin'], role!.type);

    const actions = {
        assign: {
            message: '',
            actionName: 'Переназначить',
            func: assignIntegration,
            header: `Переназначение на ${selectedReassign?.toUpperCase()}`,
            isCommentRequired: true
        },
        finish: {
            message: '',
            actionName: 'Завершить',
            func: () => doActionOnCurrentIntegration('finish'),
            header: 'Завершение этапа',
            isCommentRequired: false
        }
    };

    console.log('role', role);

    return (
        <Box p={2} borderRadius={10} bg="white" display="flex" flexDir="column">
            {integration ? (
                <>
                    {/* Logs modal */}
                    <Modal size="6xl + xl" isOpen={isLogsModalOpened} onClose={onLogsModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                Логи интеграций ЭАП #{integration.work.id} по {integration.work?.site?.s_sid}
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <TableContainer>
                                    <Table variant="striped">
                                        <Thead>
                                            <Tr>
                                                <Th>Действие</Th>
                                                <Th>Комментарий</Th>
                                                <Th>Инициатор</Th>
                                                <Th>Когда произошло</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {[...integration.logs].reverse().map((item: IWorkApprove) => (
                                                <Tr>
                                                    <Td
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.comment || ''
                                                        }}
                                                    />
                                                    <Td>{item.userComment}</Td>
                                                    <Td>
                                                        {item.user?.full_name ||
                                                            'Окончание предыдущего процесса'}
                                                    </Td>
                                                    <Td>{addHours(item.created_at, 0)}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="purple" onClick={onLogsModalClose}>
                                    Закрыть
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    {/* Reassign modal */}
                    <Modal size="sm" isOpen={isReassignModalOpened} onClose={onReassignModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                {requiresComment && _.get(actions, requiresComment).header}
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text>
                                    Комментарий{' '}
                                    {requiresComment && _.get(actions, requiresComment).isCommentRequired
                                        ? '(обязательно)'
                                        : '(необязательно)'}
                                </Text>
                                <Textarea value={comment} onChange={(e) => setComment(e.target.value)} />
                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    colorScheme="red"
                                    onClick={
                                        requiresComment ? _.get(actions, requiresComment).func : () => {}
                                    }
                                    mr="auto"
                                    isLoading={isAssignLoading}
                                >
                                    {requiresComment && _.get(actions, requiresComment).actionName}
                                </Button>
                                <Button
                                    colorScheme="purple"
                                    onClick={onReassignModalClose}
                                    isLoading={isAssignLoading}
                                >
                                    Закрыть
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    {/* Upload photo modal */}
                    <Modal size="xl" isOpen={isUploadPhotoModalOpened} onClose={onUploadPhotoModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Загрузка фотографий серийных номеров оборудования</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <UploadMultiFile
                                    files={photos}
                                    onRemove={handleRemovePhoto}
                                    accept={{
                                        'image/png': ['.png'],
                                        'image/jpeg': ['.jpeg', '.jpg']
                                    }}
                                    onDrop={(acceptedFiles) => {
                                        console.log(acceptedFiles);
                                        setPhotos(acceptedFiles);
                                    }}
                                    maxSize={51200000}
                                />
                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    colorScheme="green"
                                    mr="auto"
                                    onClick={
                                        photos.length
                                            ? uploadPhotos
                                            : () => {
                                                  doActionOnCurrentIntegration('start');
                                                  onUploadPhotoModalClose();
                                              }
                                    }
                                    isLoading={isUploadPhotosLoading}
                                    // disabled={!photos[0]}
                                >
                                    Загрузить и начать
                                </Button>
                                <Button
                                    colorScheme="purple"
                                    onClick={onUploadPhotoModalClose}
                                    isLoading={isUploadPhotosLoading}
                                >
                                    Закрыть
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="column" width="80%">
                            <Text mb={1} fontWeight="bold">
                                Фотографии:
                            </Text>
                            <Box display="flex" flexDirection="row" flexWrap="wrap">
                                {integration.photos ? (
                                    integration.photos.esigns.map(({ doc_link }: any) => (
                                        <Box m="3px">
                                            <Image
                                                key={doc_link}
                                                width={150}
                                                src={`${process.env.REACT_APP_API_BASE_URL}${doc_link}`}
                                            />
                                        </Box>
                                    ))
                                ) : (
                                    <Text>Фотографии не загружена</Text>
                                )}
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Button colorScheme="purple" onClick={onLogsModalOpen}>
                                История
                            </Button>
                            {showRestoreBtn && (
                                <Button colorScheme="purple" mt="0.5rem" onClick={handleRestoreProcess}>
                                    Возобновить процесс
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Box
                        margin="30px auto"
                        textAlign="center"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Text fontSize="2xl" fontWeight={500}>
                            Текущий этап:
                        </Text>
                        <Text fontWeight={500} mt="10px" color="twitter.700" fontSize="2xl">
                            {integration?.assigned_step?.toUpperCase()}
                        </Text>
                        <Badge mt="6px" colorScheme={getBadgeStatusColor(integration?.assigned_step)}>
                            {getApproveValueByType(integration?.assigned_step)}
                        </Badge>
                        {isMyStep && (
                            <Box display="flex" mt="20px">
                                {integration.assigned_step === 'ti' && (
                                    <Button
                                        w="100px"
                                        colorScheme="purple"
                                        mr="10px"
                                        disabled={
                                            integration[`${integration.assigned_step}_approve`]
                                                ? integration[`${integration.assigned_step}_approve`]
                                                      ?.status === false
                                                : false
                                        }
                                        isLoading={isActionLoading}
                                        onClick={() => {
                                            if (integration.assigned_step === 'ti') {
                                                onUploadPhotoModalOpen();
                                            } else {
                                                doActionOnCurrentIntegration('start');
                                            }
                                        }}
                                    >
                                        Start
                                    </Button>
                                )}
                                <Button
                                    w="100px"
                                    colorScheme="green"
                                    disabled={
                                        integration.assigned_step === 'noc' &&
                                        integration?.noc_approve?.status === true
                                            ? true
                                            : !integration[`${integration.assigned_step}_approve`]
                                            ? !integration[`${integration.assigned_step}_approve`]?.status ===
                                              true
                                            : false
                                    }
                                    isLoading={isActionLoading}
                                    onClick={() => {
                                        doAction('finish');
                                        onReassignModalOpen();
                                    }}
                                >
                                    Finish
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="space-around">
                        {processSequence.map((type) => (
                            <Box
                                key={type}
                                w="18%"
                                display="flex"
                                flexDir="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Text fontSize="22px">
                                    {type === 'ti'
                                        ? integration.work.subc === 1108
                                            ? 'СОД'
                                            : 'ССС'
                                        : type.toUpperCase()}
                                </Text>
                                <Badge mt="6px" size="xl" colorScheme={getBadgeStatusColor(type)}>
                                    {getApproveValueByType(type)}
                                </Badge>
                                {getDate(type) && (
                                    <>
                                        <Text mt="10px" color="blackAlpha.700" textAlign="center">
                                            Последнее действие совершено:
                                        </Text>
                                        <Text
                                            textAlign="center"
                                            mt="2px"
                                            dangerouslySetInnerHTML={{ __html: getDate(type) || '' }}
                                        />
                                    </>
                                )}
                                {isMyStep && (
                                    <Button
                                        mt="14px"
                                        colorScheme="purple"
                                        variant="outline"
                                        onClick={() => {
                                            setSelectedReassign(type);
                                            doAction('assign');
                                            onReassignModalOpen();
                                        }}
                                        disabled={
                                            integration.assigned_step === type ||
                                            !isTypeAllowedReassign(
                                                integration.assigned_step,
                                                type,
                                                integration.reassignmentStack,
                                                processSequence
                                            ) ||
                                            (integration.assigned_step === 'ti' &&
                                                !!integration.reAssigned &&
                                                integration.isFinalPhase) ||
                                            integration.status === 'FINISHED'
                                        }
                                        isLoading={isAssignLoading}
                                    >
                                        Переназначить
                                    </Button>
                                )}
                            </Box>
                        ))}
                    </Box>
                    <DecisionHistory
                        history={integration.actionsHistory}
                        defaultIndex={0}
                        mt={3}
                        title="История этапов ЭАП"
                    />
                </>
            ) : (
                <Box height="600px">
                    <Spinner margin="auto" />
                </Box>
            )}
        </Box>
    );
};

export default WorkIntegration;
