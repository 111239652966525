import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Badge, Box, Button, Heading, useSteps, useToast } from '@chakra-ui/react';
import Stepper from 'new-components/ui/Stepper';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
import { matListsService } from 'api/services';
import Comments from 'new-components/Comments';
import MatListEquipments from './MatList/Equipment/MatListEquipments';
import { IMatList } from './utils/interfaces/MatList';
import { ERoleNames, MAT_LIST_STATUSES } from '../../constants';
import MatListStatusHistoryModal from './MatList/History/MatListStatusHistoryModal';
import MatListActions from './MatList/Actions/MatListActions';
import Trs from './MatList/Trs/Trs';
import HasAccess from '../../guards/HasAccess';
import matListStatusMap from './utils/maps';
import { OrderContext } from './OrderCreate/OrderCreate';

const OrderMatList = () => {
    const [matList, setMatList] = useState<IMatList | null>(null);

    const steps = [
        { status: 'created', title: MAT_LIST_STATUSES.created },
        { status: 'confirmation_on_oe', title: MAT_LIST_STATUSES.confirmation_on_oe },
        {
            status: 'confirmation_on_tr',
            title: MAT_LIST_STATUSES.confirmation_on_tr,
            isHide: !matList?.hasTransmissionEquipment && matList?.status !== 'confirmation_on_tr'
        },
        { status: 'confirmation_on_ko', title: MAT_LIST_STATUSES.confirmation_on_ko },
        { status: 'waiting_tr', title: MAT_LIST_STATUSES.waiting_tr },
        { status: 'processing_tr', title: MAT_LIST_STATUSES.processing_tr },
        { status: 'done', title: MAT_LIST_STATUSES.done }
    ];

    const order = useContext(OrderContext);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length
    });
    const [isListIncludesUnconfirmedEquipment, setIsListIncludesUnconfirmedEquipment] = useState(false);
    const user = useSelector(userSelector);
    const toast = useToast();
    const { orderID } = useParams();

    const [isShowHistory, setIsShowHistory] = useState(false);
    const getActiveStep = (status: string) => steps.findIndex((item) => item.status === status);

    const getMatList = () => {
        matListsService
            .getByOrderId({ orderId: orderID, params: { syncEquip: true } })
            .then((res) => {
                setMatList(res.data);
                setActiveStep(getActiveStep(res.data.status));
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось получить мат. лист'
                });
            });
    };

    useEffect(() => {
        getMatList();
    }, [orderID]);

    const checkIncludesUnconfirmedEquipment = () => {
        if (!matList) return;
        let includesUnconfirmed = false;
        Object.entries(matList.equipments).forEach(([key, equipmentItems]) => {
            if (equipmentItems.find((item) => item.equipment === null)) {
                includesUnconfirmed = true;
            }
        });
        setIsListIncludesUnconfirmedEquipment(includesUnconfirmed);
    };

    useEffect(() => {
        if (matList && Object.keys(matList.equipments).length !== 0) {
            checkIncludesUnconfirmedEquipment();
        }
    }, [matList]);

    const getRolesShowTrs = () => {
        if (!matList) return [];
        switch (matList.status) {
            case 'waiting_tr':
                return [ERoleNames.SUBC, ERoleNames.KO, ERoleNames.EXECUTOR];
            case 'processing_tr':
                return [ERoleNames.SUBC, ERoleNames.EXECUTOR, ERoleNames.KO, ERoleNames.SKLAD];
            case 'done':
                return [ERoleNames.SUBC, ERoleNames.EXECUTOR, ERoleNames.KO, ERoleNames.SKLAD];
            default:
                return [];
        }
    };

    const getEditEquipmentRoles = () => {
        switch (matList?.status) {
            case 'created':
                return [ERoleNames.SUBC, ERoleNames.EXECUTOR];
            case 'confirmation_on_tr':
                return [ERoleNames.TR];
            case 'confirmation_on_ko':
            case 'waiting_tr':
            case 'processing_tr':
                return [ERoleNames.KO];
            default:
                return [];
        }
    };

    const getShowIssuedQtyRoles = () => {
        switch (matList?.status) {
            case 'processing_tr':
                return [ERoleNames.KO, ERoleNames.SUBC, ERoleNames.EXECUTOR, ERoleNames.SKLAD];
            default:
                return [];
        }
    };

    const handleSubmitComment = async (data: any) => {
        await matListsService.addComment(data.id, data.message);
        toast({
            status: 'success',
            title: 'Комментарий добавлен'
        });
    };

    if (!matList || !user.role || !order) return null;
    return (
        <div>
            <Box mb={5}>
                <Heading as="h3" size="sm" mb={2}>
                    Статус заказа оборудования{' '}
                    <Button
                        size="xs"
                        variant="ghost"
                        onClick={() => setIsShowHistory(true)}
                        colorScheme="purple"
                    >
                        История статусов
                    </Button>
                </Heading>
                {matList.status === 'done' ? (
                    <Badge p={1} colorScheme="green">
                        {matListStatusMap[matList.status]}
                    </Badge>
                ) : (
                    <Stepper
                        activeStep={activeStep}
                        steps={steps.filter((item) => !item.isHide).map((item) => ({ title: item.title }))}
                    />
                )}

                {isShowHistory && (
                    <MatListStatusHistoryModal onClose={() => setIsShowHistory(false)} id={matList.id} />
                )}
            </Box>
            <Box>
                <MatListEquipments
                    isDisabled={order.displayConfig.isStopProcess}
                    equipments={matList.equipments}
                    refetchMatList={getMatList}
                    hasEditEquipmentRoles={getEditEquipmentRoles()}
                    hasShowIssuedQtyRoles={getShowIssuedQtyRoles()}
                />
                <Comments
                    id={matList?.id}
                    getComments={matListsService.getComments}
                    addComment={handleSubmitComment}
                />
                <MatListActions
                    isDisabled={order.displayConfig.isStopProcess}
                    hasEditEquipmentRoles={getEditEquipmentRoles()}
                    matList={matList}
                    getMatList={getMatList}
                    hasUnconfirmedEquipment={isListIncludesUnconfirmedEquipment}
                />
                <HasAccess roleNames={getRolesShowTrs()}>
                    <Trs
                        matListStatus={matList.status}
                        rolesHasDownloadTrs={
                            matList.status === 'waiting_tr' || matList.status === 'processing_tr'
                                ? [ERoleNames.KO]
                                : []
                        }
                        rolesHasEditTrsStatus={matList.status === 'processing_tr' ? [ERoleNames.SKLAD] : []}
                        onSendWarehouseCallback={getMatList}
                        matListId={matList.id}
                    />
                </HasAccess>
            </Box>
        </div>
    );
};

export default OrderMatList;
