/* eslint-disable no-use-before-define */
import { useState, useRef } from 'react';
import _ from 'lodash';
// @chakra-ui
import { Badge, Stack, Heading, Text, Box, Button, useToast } from '@chakra-ui/react';
// components
import ConfirmationDialog, { ConfirmationItems } from 'components/ConfirmationDialog';
// services
import siteService from 'api/services/site-service';

// ----------------------------------------------------------------------

const DEPARTMENTS = [
    {
        key: 'dt',
        label: 'Design Team'
    },
    {
        key: 'rl',
        label: 'Legalization Team'
    },
    {
        key: 'oe',
        label: 'Отдел эксплуотации'
    },
    {
        key: 'le',
        label: 'Отдел договоров и аренды'
    },
    {
        key: 'tr',
        label: 'Transport Unit Team'
    },
    {
        key: 'rp',
        label: 'Отдел планирования радиосети'
    }
];

interface WorkRltTssrStatusProps {
    work: any;
    updateWork: () => void;
}

export default function WorkRltTssrStatus({ work, updateWork }: WorkRltTssrStatusProps) {
    const [isLoading, setIsLoading] = useState(false);

    const [requiresConfirmation, confirm] = useState((): string | null => null);

    const alertRef = useRef(null);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const disableAction = work.s_tssr_status === 1 || !_.get(work, 's_tssr_recieved.esigns.length', 0);

    const startProcess = () => {
        setIsLoading(true);
        siteService
            .startTssr(work.id)
            .then(() => {
                setIsLoading(false);
                toast({
                    title: 'Процесс согласования успешно запущен',
                    status: 'success'
                });
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
                setIsLoading(false);
            });
    };

    const statuses =
        work.s_tssr_status !== null ? (
            DEPARTMENTS.map((dep) => {
                const status = _.get(work, `s_tssr_${dep.key}_approved_status`, '');
                const date = _.get(work, `s_tssr_${dep.key}_approved`, '');

                const [color, text] =
                    status === null
                        ? ['yellow', 'На рассмотрении']
                        : status === 1
                        ? ['green', `Согласовано ${date ? new Date(date).toLocaleString('ru') : ''} `]
                        : ['red', `Отклонено ${date ? new Date(date).toLocaleString('ru') : ''} `];

                return (
                    <Box textAlign="center" key={dep.key} p={0.5}>
                        <Text fontWeight="semibold">{dep.label}</Text>
                        <Badge colorScheme={color}>{text}</Badge>
                    </Box>
                );
            })
        ) : (
            <Badge colorScheme="gray">Ожидает начало процесса</Badge>
        );

    const confirmationActions: ConfirmationItems = {
        startProcess: {
            message: 'Вы уверены что хотите начать процесс? Новые файлы не загружены.',
            func: startProcess
        }
    };

    return (
        <>
            <Stack p={1.5} alignItems="center">
                <Button
                    colorScheme="purple"
                    variant="outline"
                    disabled={isLoading || disableAction}
                    isLoading={isLoading}
                    onClick={() => {
                        if (!work.newTssrIsUploaded) {
                            confirm('startProcess');
                            return;
                        }
                        startProcess();
                    }}
                >
                    Начать процесс
                </Button>
                <Heading as="h4" fontSize="md">
                    Статус согласования
                </Heading>
                <Stack direction="row" flexWrap="wrap" justifyContent="center">
                    {statuses}
                </Stack>
            </Stack>
            <ConfirmationDialog
                modalRef={alertRef}
                onClose={() => confirm(null)}
                body={confirmationActions[requiresConfirmation as string]?.message}
                title="Начать процесс"
                onAccept={() => {
                    confirmationActions[requiresConfirmation as string].func();
                }}
                acceptText="Отменить"
                declineText="Начать"
                isOpen={requiresConfirmation !== null}
            />
        </>
    );
}
