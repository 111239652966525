import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserRole, UserSliceState } from 'redux/reducers/userSlice.types';
import { RootState } from 'redux/rootReducer.types';

const initialState: UserSliceState = {
    isAuthed: false,
    token: null,
    data: null,
    username: null,
    role: null
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsAuthed: (state, { payload }: PayloadAction<boolean>) => {
            state.isAuthed = payload;
        },
        setToken: (state, { payload }: PayloadAction<string | null>) => {
            state.token = payload;
        },
        setUsername: (state, { payload }: PayloadAction<string | null>) => {
            state.username = payload;
        },
        setRole: (state, { payload }: PayloadAction<IUserRole | null>) => {
            state.role = payload;
        },
        setData: (state, { payload }: PayloadAction<any>) => {
            state.data = payload;
        },
        clearUserData: (state) => {
            state.isAuthed = false;
            state.role = null;
            state.token = null;
            state.username = null;
        }
    }
});

export const userReducer = user.reducer;
export const userSelector = (state: RootState) => state.user;
export const { setIsAuthed, setToken, setUsername, setRole, setData, clearUserData } = user.actions;
