import axios from 'axios';
import React from 'react';
import { useFormik } from 'formik';
// @chakra-ui
import { Box, Button, Fade, Stack, useToast, Heading } from '@chakra-ui/react';
// hooks
import useHeaderTitle from 'hooks/useHeaderTitle';
//

import { ISiteForm } from 'interfaces/sites';
import SiteForm from './components/SiteForm';
import { siteFormValidationSchema } from './sites.validation-schema';

const initialValues: ISiteForm = {
    createdSiteReg: '',
    createdSiteId: '',
    sitename: '',
    createdSiteLat: '',
    createdSiteLng: '',
    kato: ''
};
// ----------------------------------------------------------------------

const CreateSite = () => {
    useHeaderTitle('Создание сайта');
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const formik = useFormik<ISiteForm>({
        initialValues,
        onSubmit: (data, { setSubmitting, resetForm }) => {
            axios
                .post('/sites/createSite?creatingWithForm=1', data)
                .then(() => {
                    toast({
                        status: 'success',
                        title: `Сайт ${data.createdSiteId} был успешно создан`,
                        duration: 6000,
                        containerStyle: { maxWidth: '460px' }
                    });
                    resetForm();
                })
                .catch((error) => {
                    toast({
                        status: 'error',
                        title: 'При создании сайта произошла ошибка',
                        description: error?.response?.data?.message || 'Соединение было разорвано'
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
        validationSchema: siteFormValidationSchema
    });

    return (
        <Box bg="white" width="450px" marginX="auto" borderRadius={10} p={3}>
            <Fade in style={{ display: 'flex', width: '100%', height: '100%' }}>
                <Box width="100%" display="flex" flexDir="column">
                    <form onSubmit={formik.handleSubmit}>
                        <Heading as="h4" size="md" mb={3}>
                            Данные нового сайта
                        </Heading>
                        <Stack spacing={2} width="100%">
                            <SiteForm
                                values={formik.values}
                                onChange={formik.handleChange}
                                errors={formik.errors}
                                touched={formik.touched}
                                isSubmitting={formik.isSubmitting}
                                setFieldValue={formik.setFieldValue}
                            />
                        </Stack>
                        <Button
                            type="submit"
                            mt="1rem"
                            colorScheme="purple"
                            width="100%"
                            isLoading={formik.isSubmitting}
                            isDisabled={formik.isSubmitting}
                            loadingText="Создаем..."
                        >
                            Создать сайт
                        </Button>
                    </form>
                </Box>
            </Fade>
        </Box>
    );
};

export default CreateSite;
