import {
    Box,
    Button,
    Heading,
    IconButton,
    Input,
    Table,
    TableContainer,
    Tbody,
    Td,
    Tfoot,
    Th,
    Thead,
    Tr,
    Flex
} from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';
import React, { forwardRef } from 'react';
import { FieldArray, Field, ErrorMessage as EM, FieldAttributes, useFormikContext } from 'formik';
import TCPSearch from './TCPSearch';
import { currencyFormat } from '../../utils';

interface SiteConfigurationFormProps {
    title: string;
    resource: string;
    disabled?: boolean;
    orderID: number;
}

const ErrorMessage = EM as FieldAttributes<any>;

export const NDS_PERCENTAGE = 0.12; // 12% НДС

function SiteConfigurationForm({ title, resource, orderID, disabled }: SiteConfigurationFormProps, ref: any) {
    const { values, setFieldValue } = useFormikContext<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onClearTable = () => {
        setFieldValue(resource, []);
    };
    const disabledSearch = true;
    // eslint-disable-next-line
    ref.current.totalAmount = values[resource].reduce(
        (acc: number, { price, count }: any) => acc + price * count,
        0
    );
    return (
        <Box mt={2}>
            <Heading as="h1" fontSize="md">
                {title}
            </Heading>
            <TableContainer mt={2}>
                <Table variant="simple" size="sm" border="1px solid #EDF2F7">
                    <Thead bg="aliceblue">
                        <Tr>
                            <Th px={3} py="1.5">
                                #ТЦП
                            </Th>
                            <Th px={3} py="1.5">
                                Вид ЭАП
                            </Th>
                            <Th px={3} py="1.5">
                                Ед. Изм.
                            </Th>
                            <Th px={3} py="1.5">
                                Кол-во.
                            </Th>
                            <Th px={3} py="1.5">
                                Доступное кол-во.
                            </Th>
                            <Th px={3} py="1.5">
                                Цена за ед.
                            </Th>
                            <Th px={3} py="1.5">
                                Сумма
                            </Th>
                            <Th px={3} py="1.5" />
                        </Tr>
                    </Thead>
                    {/* @ts-ignore */}
                    <FieldArray name={resource}>
                        {({ remove, push }) => (
                            <Tbody>
                                {!disabledSearch && (
                                    <Tr>
                                        <Td px={3} py="1.5" colSpan={8}>
                                            <Flex alignItems="center">
                                                <Box margin="6px" w="100%">
                                                    <TCPSearch
                                                        onChange={({
                                                            id,
                                                            ext_id,
                                                            name,
                                                            type,
                                                            unit,
                                                            price,
                                                            coefficient,
                                                            count,
                                                            onec_id
                                                        }) => {
                                                            push({
                                                                id,
                                                                ext_id,
                                                                name: type,
                                                                workType: name,
                                                                unit,
                                                                count,
                                                                price: price || 1,
                                                                notes: '',
                                                                coefficient,
                                                                onec_id,
                                                                order_count: count
                                                            });
                                                        }}
                                                        orderID={orderID}
                                                    />
                                                </Box>
                                                <Button
                                                    onClick={onClearTable}
                                                    colorScheme="red"
                                                    variant="outline"
                                                    size="sm"
                                                    ml={1}
                                                >
                                                    Очистить таблицу
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )}
                                {values[resource].length > 0 &&
                                    values[resource].map((site: any, index: any) => (
                                        <Tr key={site.onec_id}>
                                            <Td>{site.ext_id}</Td>
                                            <Td whiteSpace="break-spaces" maxW="600px">
                                                {site.workType}
                                            </Td>
                                            <Td>{String(site.unit).toLowerCase()}</Td>
                                            <Td>
                                                {disabled ? (
                                                    <Box>{site.count}</Box>
                                                ) : (
                                                    <Flex flexDir="column" overflow="hidden">
                                                        <Field
                                                            name={`${resource}.${index}.count`}
                                                            as={Input}
                                                            borderColor="gray.400"
                                                            w="50px"
                                                            sx={{
                                                                paddingInlineStart: 2,
                                                                paddingInlineEnd: 0
                                                            }}
                                                        />
                                                        <Box mt={1} color="red" fontSize="xs">
                                                            <ErrorMessage
                                                                name={`${resource}.${index}.count`}
                                                            />
                                                        </Box>
                                                    </Flex>
                                                )}
                                            </Td>
                                            <Td>
                                                <Box>{site.order_count || site.count}</Box>
                                            </Td>
                                            <Td>{currencyFormat(site.price)}</Td>
                                            <Td textAlign="right" px={3}>
                                                {currencyFormat(Number(site.price) * site.count)}
                                            </Td>
                                            <Td>
                                                {!disabled && (
                                                    <IconButton
                                                        aria-label="remove-btn"
                                                        icon={<FiTrash2 color="red" />}
                                                        variant="unstyled"
                                                        onClick={() => remove(index)}
                                                    />
                                                )}
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        )}
                    </FieldArray>

                    <Tfoot>
                        <Tr>
                            <Td colSpan={6} textAlign="right" px={3} py="1.5">
                                Всего на сумму
                            </Td>
                            <Td textAlign="right" px={3} py="1.5">
                                {
                                    // eslint-disable-next-line react/destructuring-assignment
                                    currencyFormat(ref.current.totalAmount)
                                }
                            </Td>
                            <Td />
                        </Tr>
                        <Tr>
                            <Td colSpan={6} textAlign="right" px={3} py="1.5">
                                Всего на сумму c НДС
                            </Td>
                            <Td textAlign="right" px={3} py="1.5">
                                {
                                    // eslint-disable-next-line react/destructuring-assignment
                                    currencyFormat(ref.current.totalAmount * (1 + NDS_PERCENTAGE))
                                }
                            </Td>
                            <Td />
                        </Tr>
                        <Tr>
                            <Td colSpan={6} textAlign="right" px={3} py="1.5">
                                В том числе НДС(12%)
                            </Td>
                            <Td textAlign="right" px={3} py="1.5">
                                {
                                    // eslint-disable-next-line react/destructuring-assignment
                                    currencyFormat(ref.current.totalAmount * NDS_PERCENTAGE)
                                }
                            </Td>
                            <Td />
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default forwardRef(SiteConfigurationForm);
