import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy, usePagination, useAsyncDebounce, useFilters, Column } from 'react-table';
import axios, { CancelTokenSource } from 'axios';
import qs from 'qs';
import _ from 'lodash';
// @chakra-ui
import {
    useTheme,
    useToast,
    TableContainer,
    Flex,
    Button,
    Badge,
    ListItem,
    List,
    Link,
    Spinner,
    Box
} from '@chakra-ui/react';
// hooks
import useHeaderTitle from 'hooks/useHeaderTitle';
import { useTitle } from 'react-use';
// utils
import { generateTitle } from 'utils';
// components
import LoadingOverlay from 'components/LoadingOverlay';
import { TablePagination, TableCustom } from 'components/react-table';
// sections
import { TaskTableToolbar } from './components';

// ----------------------------------------------------------------------

let source: CancelTokenSource | null = null;

export default function TaskList() {
    const theme = useTheme();

    const navigate = useNavigate();

    useTitle(generateTitle('Задачи'));

    useHeaderTitle('Просмотр списка задач');

    const [isLoading, setIsLoading] = useState(false);

    const [filterSiteId, setFilterSiteId] = useState('');

    const [tasks, setTasks] = useState([]);

    const [totalTasks, setTotalTasks] = useState(0);

    const [_pageCount, setPageCount] = useState(0);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const data = useMemo(() => tasks, [tasks]);

    const getColumns = () => [
        {
            accessor: 'work.site_id',
            Header: 'ID сайта',
            Cell: ({ cell: { value, row } }: any) => (
                <Button
                    colorScheme="purple"
                    minWidth="90px"
                    size="sm"
                    onClick={() => navigate(`/tasks/${row.original.id}`)}
                >
                    {value}
                </Button>
            )
        },
        { accessor: 'name', Header: 'Задача', disableSortBy: true },
        {
            accessor: 'status',
            Header: 'Статус',
            disableSortBy: true,
            Cell: ({ cell: { value } }: any) => {
                if (value !== 0) return null;

                return (
                    <Badge variant="subtle" colorScheme="red">
                        Ожидает решения
                    </Badge>
                );
            }
        },
        { accessor: 'work.work_type', Header: 'Тип ЭАП' },
        {
            accessor: 'work.tssr.esigns',
            Header: 'TSSR',
            disableSortBy: true,
            Cell: ({ cell: { value } }: any) => {
                if (!value?.length) return null;

                return (
                    <List>
                        {value.map((item: any, index: number) => (
                            <ListItem key={index}>
                                <Link
                                    href={`${process.env.REACT_APP_API_BASE_URL}${item.doc_link}`}
                                    rel="noreferrer"
                                    isExternal
                                    sx={{
                                        color: theme.colors.blue[600],
                                        _hover: {
                                            color: theme.colors.blue[600],
                                            textDecoration: 'underline'
                                        }
                                    }}
                                >
                                    {item.file_name}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                );
            }
        },
        { accessor: 'work.subc.name', Header: 'Подрядчик', disableSortBy: true },
        {
            accessor: 'updated_at',
            Header: 'Дата изменения',
            Cell: ({ cell: { value } }: any) => new Date(value).toLocaleString('ru-RU')
        }
    ];

    const columns = useMemo(() => getColumns(), []) as Column[];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        // pagination
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, sortBy: [{ id: 'updated_at', desc: true }] },
            stateReducer(newState, action) {
                if (action.type === 'toggleSortBy') {
                    return { ...newState, pageIndex: 0 };
                }

                return newState;
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount: _pageCount,
            autoResetPage: false,
            autoResetSortBy: false
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const getTasks = ({ _limit, _start, _sort, cancelToken, ...filters }: any) => {
        const params = {
            ...filters,
            _start,
            _limit,
            _sort
        };
        // Removing empty values from params
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
        return axios.get('/task-groups', {
            params: finalParams,
            paramsSerializer: (params) => qs.stringify(params),
            cancelToken
        });
    };

    const getTotalTasks = ({ cancelToken, ...filters }: any) => {
        const params = {
            ...filters
        };
        // Removing empty values from params
        const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
        return axios.get('/task-groups/count', {
            cancelToken,
            params: finalParams
        });
    };

    const fetchAPIData = ({ _limit, _start, _sort, pageSize, cancelToken, ...filters }: any) => {
        setIsLoading(true);
        const firstPromise = getTasks({
            ...filters,
            _limit,
            _start,
            _sort,
            cancelToken
        });
        const secondPromise = getTotalTasks({
            ...filters,
            cancelToken
        });
        Promise.all([firstPromise, secondPromise])
            .then((responses) => {
                setTasks(responses[0].data);
                setTotalTasks(responses[1].data);
                setPageCount(Math.ceil(responses[1].data / pageSize));
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (axios.isCancel(err)) {
                    return;
                }
                toast({
                    status: 'error',
                    title: `Не удалось получить список задач`
                });
                setIsLoading(false);
            });
    };

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, cancelToken, filterSiteId }) => {
        fetchAPIData({
            'work.site_id_contains': filterSiteId,
            '_limit': pageSize,
            '_start': pageSize * pageIndex,
            '_sort': sortBy.length ? `${sortBy[0].id}:${sortBy[0].desc ? 'DESC' : 'ASC'}` : null,
            pageSize,
            cancelToken
        });
    }, []);

    useEffect(() => {
        if (source) {
            source.cancel();
        }
        source = axios.CancelToken.source();
        const cancelToken = source.token;
        fetchData({ pageIndex, pageSize, sortBy, filterSiteId, cancelToken });
    }, [fetchData, pageIndex, pageSize, sortBy, filterSiteId]);

    const handleFilterSiteId = useAsyncDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterSiteId(event.target.value);
        gotoPage(0);
    }, 500);

    return (
        <Box bgColor="white" borderRadius={10} p={2}>
            <LoadingOverlay
                Spinner={<Spinner color="blue.500" />}
                active={isLoading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        overflow: 'auto',
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column'
                    })
                }}
            >
                <Flex direction="column" h="100%">
                    <TaskTableToolbar
                        onFilterSiteId={handleFilterSiteId}
                        totalTasks={totalTasks}
                        filterSiteId={filterSiteId}
                    />
                    <TableContainer sx={{ flexGrow: 1, overflowY: 'auto', height: '100%', padding: 1.5 }}>
                        <TableCustom
                            size="sm"
                            getTableProps={getTableProps}
                            headerGroups={headerGroups}
                            getTableBodyProps={getTableBodyProps}
                            page={page}
                            prepareRow={prepareRow}
                        />
                    </TableContainer>
                    <TablePagination
                        gotoPage={gotoPage}
                        canPreviousPage={canPreviousPage}
                        previousPage={previousPage}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        pageSizeOptions={[5, 10, 25]}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                    />
                </Flex>
            </LoadingOverlay>
        </Box>
    );
}
