import { FC, ReactNode } from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

interface LinkProps {
    children: ReactNode;
    to: string;
}

/**
 * @component
 * @param { ReactNode } children ReactNode
 * @param { string } to Link href
 */
const Link: FC<LinkProps> = ({ children, to }) => (
    <ReactRouterDomLink to={to}>
        <Text
            fontWeight={600}
            color="#647DEE"
            _hover={{
                color: '#4567ff'
            }}
            transition="color .1s ease-in-out"
        >
            {children}
        </Text>
    </ReactRouterDomLink>
);

export default Link;
