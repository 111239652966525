// allowedFields.ts
import { ButtonGroup, Link as ChakraLink } from '@chakra-ui/react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const renderSiteId = ({ cell: { value, row } }: { cell: { value: string; row: any } }) => (
    <ButtonGroup size="sm">
        <ChakraLink
            color="blue.600"
            _hover={{ color: 'blue.600', textDecoration: 'underline' }}
            as={Link}
            target="_blank"
            mr={3}
            className="site-link"
            to={`/sites/${value}/${row.original.id}`}
        >
            {value}
        </ChakraLink>
    </ButtonGroup>
);
const renderDate = ({ cell: { value } }: { cell: { value: string } }) => (
    <>{value ? moment(value).format('DD.MM.YYYY') : '_'}</>
);

export const allowedFields = [
    { field: 's_new_site_id', title: 'SITE ID', render: renderSiteId },
    { field: 's_reg', title: 'REG' },
    { field: 'sitename', title: 'SITE NAME' },
    { field: 'kato.katoId', title: 'KATO' },
    { field: 'kato.area', title: 'KATO ОБЛАСТЬ' },
    { field: 's_lat', title: 'LAT' },
    { field: 's_lng', title: 'LONG' },
    { field: 'buildingType', title: 'Building Type' },
    { field: 'importanceClass', title: 'Класс важности сайта' },
    { field: 's_main_accident_rate', title: 'МАРКЕР АВАРИЙНОСТИ САЙТА' },
    { field: 's_main_accident_rate_cmnt', title: 'КОММЕНТ К АВАРИЙНОСТИ САЙТА' },
    { field: 'toa.toa_2g', title: 'ТОА 2G', render: renderDate },
    { field: 'toa.toa_3g', title: 'ТОА 3G', render: renderDate },
    { field: 'toa.toa_4g', title: 'ТОА 4G', render: renderDate },
    { field: 'toa.toa_5g', title: 'ТОА 5G', render: renderDate },
    { field: 'currentHw', title: 'Current HW' },
    { field: 'currentBand', title: 'Current band' }
];

export default allowedFields;
