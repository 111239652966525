/* eslint-disable @typescript-eslint/no-unused-vars */
// @chakra-ui
import {
    Stack,
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    Spacer,
    Button,
    useBreakpointValue,
    Select
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';

// ----------------------------------------------------------------------

interface ActTableToolbarProps {
    filterStatus: string;
    onFilterStatus: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    totalActs: number;
    optionsStatus: Array<string>;
}

export default function OrderTableToolbar({
    filterStatus,
    onFilterStatus,
    totalActs,
    optionsStatus
}: ActTableToolbarProps) {
    const alignItems = useBreakpointValue({ base: 'stretch', md: 'center' });

    return (
        <Stack
            width="full"
            direction={{ base: 'column', md: 'row' }}
            p={1.5}
            spacing={1}
            justifyContent="space-between"
            alignItems={alignItems}
        >
            <Text fontWeight="bold" whiteSpace="nowrap">
                Всего актов ({totalActs})
            </Text>

            <Select onChange={onFilterStatus} value={filterStatus} size="sm" w="xs">
                <option value="" selected>
                    Все статусы
                </option>
                <option key="activeOnly" value="activeOnly">
                    Только активные (все кроме завершенных, подписанных)
                </option>
                <option key="everything" value="everything">
                    Архив
                </option>
                {optionsStatus.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </Select>

            <Spacer />
        </Stack>
    );
}
