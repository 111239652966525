import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
// @chakra-ui
import { Input, Select, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { Select as AntSelect } from 'antd';
import useDebounce from 'hooks/useDebounce';
import { ISiteForm } from 'interfaces/sites';
import { IKato } from 'interfaces/kato';
import constants from '../../../constants';

const formLabelStyle = {
    fontSize: '1rem',
    color: '#1A202C',
    marginBottom: '0.5rem'
};

interface IProps {
    values: ISiteForm;
    onChange: (e: React.ChangeEvent<any>) => void;
    errors: FormikErrors<ISiteForm>;
    touched: FormikTouched<ISiteForm>;
    isSubmitting: boolean;
    setFieldValue: (field: string, value: string) => void;
    hideIdField?: boolean;
}

const SiteForm: FC<IProps> = ({
    hideIdField,
    values,
    onChange,
    errors,
    touched,
    isSubmitting,
    setFieldValue
}) => {
    const [katoOptions, setKatoOptions] = useState([]);
    const [katoSearchText, setKatoSearchText] = useState('');
    const debouncedKatoSearchText = useDebounce(katoSearchText, 700);
    const handleSearchKatoOptions = (searchText: string) => {
        if (!katoSearchText) {
            setKatoOptions([]);
            return;
        }
        axios.get(`/katos?katoId_contains=${searchText}`).then((res) => {
            setKatoOptions(res.data);
        });
    };
    useEffect(() => {
        handleSearchKatoOptions(debouncedKatoSearchText);
    }, [debouncedKatoSearchText]);

    return (
        <>
            {!hideIdField && (
                <FormControl
                    isInvalid={!!errors.createdSiteId && (touched.createdSiteId as boolean)}
                    isRequired
                >
                    <FormLabel style={formLabelStyle}>ID сайта</FormLabel>
                    <Input
                        name="createdSiteId"
                        onChange={onChange}
                        value={values.createdSiteId}
                        disabled={isSubmitting}
                        placeholder="Введите ID сайта"
                    />
                    <FormErrorMessage mt="0.5rem">{errors.createdSiteId}</FormErrorMessage>
                </FormControl>
            )}
            <FormControl
                isInvalid={!!errors.createdSiteReg && (touched.createdSiteReg as boolean)}
                isRequired
            >
                <FormLabel style={formLabelStyle}>Регион сайта</FormLabel>
                <Select
                    style={{ width: '100%' }}
                    name="createdSiteReg"
                    onChange={onChange}
                    disabled={isSubmitting}
                    placeholder="Выберите регион сайта"
                    value={values.createdSiteReg}
                >
                    {constants.dropdownData.s_reg.map((region: string, i: number) => (
                        <option value={region} key={i}>
                            {region}
                        </option>
                    ))}
                </Select>
                <FormErrorMessage mt="0.5rem">{errors.createdSiteReg}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.sitename && (touched.sitename as boolean)}>
                <FormLabel style={formLabelStyle}>Название сайта</FormLabel>
                <Input
                    name="sitename"
                    onChange={onChange}
                    value={values.sitename}
                    disabled={isSubmitting}
                    placeholder="Введите название сайта"
                />
                <FormErrorMessage mt="0.5rem">{errors.sitename}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.kato && (touched.kato as boolean)}>
                <FormLabel style={formLabelStyle}>КАТО</FormLabel>
                <AntSelect
                    placeholder="Введите для поиска"
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    showSearch
                    id="kato"
                    value={values.kato || undefined}
                    onSearch={(value: string) => {
                        setKatoSearchText(value);
                    }}
                    onChange={(value) => setFieldValue('kato', value)}
                >
                    {katoOptions.map((opt: IKato) => (
                        <AntSelect.Option value={opt.id} key={`kato-opt${opt.id}`}>
                            {opt.katoId}
                        </AntSelect.Option>
                    ))}
                </AntSelect>
                <FormErrorMessage mt="0.5rem">{errors.kato}</FormErrorMessage>
            </FormControl>
            <FormControl
                isRequired
                isInvalid={!!errors.createdSiteLat && (touched.createdSiteLat as boolean)}
            >
                <FormLabel style={formLabelStyle}>Широта</FormLabel>
                <Input
                    name="createdSiteLat"
                    onChange={onChange}
                    value={values.createdSiteLat}
                    disabled={isSubmitting}
                    placeholder="Введите широту"
                />
                <FormErrorMessage mt="0.5rem">{errors.createdSiteLat}</FormErrorMessage>
            </FormControl>
            <FormControl
                isRequired
                isInvalid={!!errors.createdSiteLng && (touched.createdSiteLng as boolean)}
            >
                <FormLabel style={formLabelStyle}>Долгота</FormLabel>
                <Input
                    name="createdSiteLng"
                    onChange={onChange}
                    value={values.createdSiteLng}
                    disabled={isSubmitting}
                    placeholder="Введите долготу"
                />
                <FormErrorMessage mt="0.5rem">{errors.createdSiteLng}</FormErrorMessage>
            </FormControl>
        </>
    );
};

export default SiteForm;
