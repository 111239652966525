import React, { useState } from 'react';
import { Table, Spin, Button, DatePicker, Typography } from 'antd';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import moment from 'moment';

interface IToaRow {
    key: string;
    uniqueKey: string;
    title: string;
    render: (value: any) => any;
    renderForEdit?: (value: any, record: any, handleInputChange: (key: string, value: any) => void) => any;
}

interface IProps {
    toaData: any;
    setSiteData: (data: any) => void;
    canEdit: boolean;
}

interface IFieldTitles {
    [key: string]: string;
}

const SiteToaDetails: React.FC<IProps> = ({ toaData, setSiteData, canEdit }) => {
    const [editingData, setEditingData] = useState<any>({});
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const canEditToa = canEdit;
    const [loading, setLoading] = useState<boolean>(false);

    const toaRows: IToaRow[] = [
        {
            key: 'toa_2g',
            uniqueKey: 'toa_2g',
            title: 'TOA 2G',
            render: (value) => (value ? moment(value).format('DD.MM.YYYY') : ''),
            renderForEdit: (value, record, handleInputChange) => (
                <DatePicker
                    style={{ width: '100%' }}
                    format="DD.MM.YYYY"
                    placeholder="Выберите дату"
                    value={value ? moment(value) : null}
                    onChange={(date) => handleInputChange(record.key, date ? date.toISOString() : '')}
                />
            )
        },
        {
            key: 'toa_3g',
            uniqueKey: 'toa_3g',
            title: 'TOA 3G',
            render: (value) => (value ? moment(value).format('DD.MM.YYYY') : ''),
            renderForEdit: (value, record, handleInputChange) => (
                <DatePicker
                    style={{ width: '100%' }}
                    format="DD.MM.YYYY"
                    placeholder="Выберите дату"
                    value={value ? moment(value) : null}
                    onChange={(date) => handleInputChange(record.key, date ? date.toISOString() : '')}
                />
            )
        },
        {
            key: 'toa_4g',
            uniqueKey: 'toa_4g',
            title: 'TOA 4G',
            render: (value) => (value ? moment(value).format('DD.MM.YYYY') : ''),
            renderForEdit: (value, record, handleInputChange) => (
                <DatePicker
                    style={{ width: '100%' }}
                    format="DD.MM.YYYY"
                    placeholder="Выберите дату"
                    value={value ? moment(value) : null}
                    onChange={(date) => handleInputChange(record.key, date ? date.toISOString() : '')}
                />
            )
        },
        {
            key: 'toa_5g',
            uniqueKey: 'toa_5g',
            title: 'TOA 5G',
            render: (value) => (value ? moment(value).format('DD.MM.YYYY') : ''),
            renderForEdit: (value, record, handleInputChange) => (
                <DatePicker
                    style={{ width: '100%' }}
                    format="DD.MM.YYYY"
                    placeholder="Выберите дату"
                    value={value ? moment(value) : null}
                    onChange={(date) => handleInputChange(record.key, date ? date.toISOString() : '')}
                />
            )
        }
    ];

    const fieldTitles: IFieldTitles = toaRows.reduce((acc, row) => {
        acc[row.key] = row.title;
        return acc;
    }, {} as IFieldTitles);

    const handleInputChange = (key: string, value: any) => {
        setEditingData({ ...editingData, [key]: value });
        setSiteData((prevData: any) => ({ ...prevData, toa: { ...toaData, [key]: value } }));
    };

    const handleSave = async (key: string) => {
        const newValue = editingData[key];
        setLoading(true);
        try {
            await axios.patch(`/toa/${toaData.id}`, { field: key, value: newValue });
            const fieldTitle = fieldTitles[key] || key;
            toast({ status: 'success', title: `${fieldTitle} успешно обновлен` });
            setEditingData({});
        } catch (error: any) {
            toast({
                status: 'error',
                title: error?.response?.data?.message || 'Ошибка при сохранении данных'
            });
        } finally {
            setLoading(false);
        }
    };

    const rowRender = (text: string, record: any) => {
        const columnDef = toaRows.find((col) => col.uniqueKey === record.key);
        if (columnDef) {
            if (canEditToa && columnDef.renderForEdit) {
                return columnDef.renderForEdit(text, record, handleInputChange);
            }
            return columnDef.render(text);
        }
        return text;
    };

    const actionRender = (_: string, record: any) =>
        canEditToa && record.editable ? (
            <Button type="primary" disabled={!editingData[record.key]} onClick={() => handleSave(record.key)}>
                Сохранить
            </Button>
        ) : null;

    const columns = [
        {
            title: 'Технология',
            dataIndex: 'title',
            key: 'title',
            width: '30%'
        },
        {
            title: 'Дата ТОА',
            dataIndex: 'value',
            key: 'value',
            render: (text: string, record: any) => rowRender(text, record),
            width: canEdit ? '50%' : '70%'
        },
        ...(canEdit
            ? [
                  {
                      title: 'Действия',
                      key: 'actions',
                      render: (text: string, record: any) => actionRender(text, record),
                      width: '20%'
                  }
              ]
            : [])
    ];

    const dataSource = toaRows.map((col) => ({
        key: col.uniqueKey,
        title: col.title,
        value: toaData?.[col.key],
        editable: col.renderForEdit !== undefined
    }));

    return (
        <Spin spinning={loading}>
            <Typography style={{ fontSize: 20, margin: '20px 0' }}>TOA Details</Typography>
            <Table dataSource={dataSource} columns={columns} pagination={false} bordered rowKey="key" />
        </Spin>
    );
};

export default SiteToaDetails;
