import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    FormControl,
    Heading,
    Icon,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { matListTrsService } from 'api/services';
import { ITrDetail, ITrEquipment } from '../utils/interfaces/MatList';
import TrEquipmentInvalidDetailModal from './Trs/TrEquipmentInvalidDetailModal';
import TrIssueModal from './Trs/TrIssueModal';

export interface IItemsToIssue {
    [key: string]: number;
}
const TrDetail: FC = () => {
    const { trId } = useParams();
    const [issueTrModalShow, setIssueTrModalShow] = useState(false);
    const [detailItem, setDetailItem] = useState<null | ITrEquipment>(null);
    const [tr, setTr] = useState<null | ITrDetail>(null);
    const [itemsToIssue, setItemsToIssue] = useState<IItemsToIssue>({});
    const toast = useToast();
    const navigate = useNavigate();
    useEffect(() => {
        if (!trId) return;
        matListTrsService
            .getTrWithEquipment(+trId)
            .then((res) => {
                setTr(res.data);
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не получить информацию по TR'
                });
            });
    }, []);
    const onChangeQty = (value: number, trId: number) => {
        const itemsToIssueCopy = { ...itemsToIssue };
        itemsToIssueCopy[trId] = value;
        setItemsToIssue(itemsToIssueCopy);
    };

    const getMaxQty = (item: ITrEquipment): number => {
        const maxByMatList =
            item.matListEquipment.qty - item.matListEquipment.awaitingQty - item.matListEquipment.issuedQty;
        return maxByMatList < item.qty ? maxByMatList : item.qty;
    };

    useEffect(() => {
        if (!tr) return;
        const equipments = tr?.trEquipments;
        if (!equipments || equipments.length === 0) return;

        const toIssueQty: IItemsToIssue = {};
        equipments.forEach((item) => {
            if (tr.status === 'ready_to_receive') {
                toIssueQty[item.id] = item.issuedQty;
            } else if (tr.status === 'new') {
                toIssueQty[item.id] = getMaxQty(item);
            }
        });
        setItemsToIssue(toIssueQty);
    }, [tr]);
    const onConfirm = () => {
        if (tr?.status === 'ready_to_receive') {
            setIssueTrModalShow(true);
            return;
        }

        if (!trId) return;
        matListTrsService
            .nextStep(+trId, { issuanceEquipment: itemsToIssue })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Успешно!',
                    description: `TR подготовлен к выдаче!`
                });
                navigate(-1);
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message
                });
            });
    };
    return (
        <div>
            {issueTrModalShow && tr && (
                <TrIssueModal
                    itemsToIssue={itemsToIssue}
                    tr={tr}
                    onClose={() => setIssueTrModalShow(false)}
                />
            )}
            {detailItem && (
                <TrEquipmentInvalidDetailModal
                    maxQty={getMaxQty(detailItem)}
                    item={detailItem}
                    onClose={() => setDetailItem(null)}
                />
            )}
            <Box bgColor="white" borderRadius={10} p={4}>
                <Heading as="h3" mb={2} size="md">
                    {tr?.status === 'new'
                        ? 'Выберите количество готового к выдаче оборудования'
                        : 'Убедитесь в правильности указанного оборудования'}
                </Heading>
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th>SAP код</Th>
                                <Th>код продукта</Th>
                                <Th>наименование</Th>
                                <Th>запрошено</Th>
                                <Th>
                                    {tr?.status === 'ready_to_receive' ? 'количество к выдаче' : 'выдать'}
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {tr?.trEquipments.map((item, i) => {
                                const maxQty = getMaxQty(item);
                                return (
                                    <Tr>
                                        <Td>{i + 1}</Td>
                                        {item.matListEquipment.equipment && (
                                            <>
                                                <Td>{item.matListEquipment.equipment.sap_code}</Td>
                                                <Td>{item.matListEquipment.equipment.s_product_code}</Td>
                                                <Td>{item.matListEquipment.equipment.s_equip_title}</Td>
                                                <Td>
                                                    <Flex flexDir="row" alignItems="center">
                                                        {item.qty} {item.matListEquipment.equipment.s_unit}{' '}
                                                        {item.qty > maxQty && tr?.status === 'new' && (
                                                            <Button
                                                                onClick={() => setDetailItem(item)}
                                                                px={0}
                                                                size="xs"
                                                                variant="text"
                                                            >
                                                                <Icon
                                                                    as={BsFillInfoCircleFill}
                                                                    color="red.300"
                                                                    width="15px"
                                                                    height="15px"
                                                                />
                                                            </Button>
                                                        )}
                                                    </Flex>
                                                </Td>
                                                {tr?.status === 'ready_to_receive' ? (
                                                    <Td>
                                                        {item.issuedQty}{' '}
                                                        {item.matListEquipment.equipment.s_unit}{' '}
                                                    </Td>
                                                ) : (
                                                    <Td>
                                                        <Stack
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                            direction="row"
                                                        >
                                                            <FormControl width="auto" isRequired>
                                                                <NumberInput
                                                                    allowMouseWheel
                                                                    size="sm"
                                                                    min={0}
                                                                    max={maxQty}
                                                                    width="80px"
                                                                    name="qty"
                                                                    onChange={(value) =>
                                                                        onChangeQty(+value, item.id)
                                                                    }
                                                                    value={itemsToIssue[item.id] || 0}
                                                                >
                                                                    <NumberInputField
                                                                        placeholder=""
                                                                        sx={{
                                                                            paddingInlineStart:
                                                                                'var(--chakra-space-1-5)',
                                                                            borderColor: 'gray.200'
                                                                        }}
                                                                    />
                                                                    <NumberInputStepper borderColor="gray.200">
                                                                        <NumberIncrementStepper
                                                                            onChange={() =>
                                                                                onChangeQty(
                                                                                    itemsToIssue[item.id] + 1,
                                                                                    item.id
                                                                                )
                                                                            }
                                                                        />
                                                                        <NumberDecrementStepper
                                                                            onChange={() =>
                                                                                onChangeQty(
                                                                                    itemsToIssue[item.id] - 1,
                                                                                    item.id
                                                                                )
                                                                            }
                                                                        />
                                                                    </NumberInputStepper>
                                                                </NumberInput>
                                                            </FormControl>
                                                            <Button
                                                                type="button"
                                                                size="xs"
                                                                onClick={() => onChangeQty(0, item.id)}
                                                            >
                                                                <CloseIcon />
                                                            </Button>
                                                        </Stack>
                                                    </Td>
                                                )}
                                            </>
                                        )}
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                <ButtonGroup my={2} width="100%" justifyContent="end">
                    <Button type="button" onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    <Button onClick={onConfirm} colorScheme="green">
                        Подтвердить
                    </Button>
                </ButtonGroup>
            </Box>
        </div>
    );
};

export default TrDetail;
