import React from 'react';
// @chakra-ui
import { IconButton, Text, Stack } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';

// ----------------------------------------------------------------------

export interface TablePaginationButtonsProps {
    gotoPage: Function;
    nextPage: Function;
    previousPage: Function;
    canPreviousPage: boolean;
    canNextPage: boolean;
    pageCount: number;
    pageIndex: number;
}

const TablePaginationButtons: React.FC<TablePaginationButtonsProps> = ({
    gotoPage,
    canPreviousPage,
    previousPage,
    pageCount,
    nextPage,
    canNextPage,
    pageIndex
}) => (
    <Stack alignItems="center" width="auto" direction="row">
        <Stack direction="row" spacing={1}>
            <IconButton
                size="sm"
                onClick={() => gotoPage(0)}
                isDisabled={!canPreviousPage}
                icon={<ArrowLeftIcon h={3} w={3} />}
                aria-label="Первая стр."
            />
            <IconButton
                size="sm"
                onClick={() => previousPage()}
                isDisabled={!canPreviousPage}
                icon={<ChevronLeftIcon h={6} w={6} />}
                aria-label="Предыдущая стр."
            />
            <IconButton
                size="sm"
                onClick={() => nextPage()}
                isDisabled={!canNextPage}
                icon={<ChevronRightIcon h={6} w={6} />}
                aria-label="Следующая стр."
            />
            <IconButton
                size="sm"
                onClick={() => gotoPage(pageCount - 1)}
                isDisabled={!canNextPage}
                icon={<ArrowRightIcon h={3} w={3} />}
                aria-label="Последняя стр."
            />
        </Stack>

        <Text ml={2} fontSize="sm">
            Стр.{' '}
            <Text fontWeight="bold" as="span">
                {!pageCount ? 0 : pageIndex + 1}
            </Text>{' '}
            из{' '}
            <Text fontWeight="bold" as="span">
                {pageCount}
            </Text>
        </Text>
    </Stack>
);

export default TablePaginationButtons;
