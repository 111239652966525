import axios from 'axios';

export const getErrorMessage = (error: any) => {
    const { status } = error.response;
    let result;
    switch (status) {
        case 403:
            result = 'Доступ запрещен. Только авторизованные пользователи могут запрашивать данный ресурс';
            break;
        case 404:
            result = 'Запрашиваемый ресурс не найден';
            break;
        case 400:
            result = `Некорректный запрос. ${error.message}`;
            break;
        default:
            result = 'Что-то пошло не так';
    }
    return result;
};

export const fetcher = (url: string) => axios.get(url).then((res) => res.data);
