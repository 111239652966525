import * as React from 'react';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList
} from '@choc-ui/chakra-autocomplete';
import useSWR from 'swr';
import { useState } from 'react';
import { fetcher } from '../../../utils/client';

interface AutoCompleteProps {
    onChange: (data: any) => void;
    region?: string;
    coefficient: number;
    contragent: number;
    treatyNum: number;
    sitename?: string;
}

function TCPSearch({ onChange, coefficient, region, contragent, treatyNum, sitename }: AutoCompleteProps) {
    const [search, setSearch] = useState('');
    const [key, setKey] = useState(0);
    const { data } = useSWR(
        search && region && treatyNum
            ? `/order-works-search?region=${region}&treaty_num=${treatyNum}&contragent_id=${contragent}&search=${search}&coefficient=${coefficient}`
            : null,
        fetcher
    );

    const onChangeHandler = (onec_id: string) => {
        const selectedTcp = data.find((orderWork: any) => orderWork.onec_id === onec_id);
        if (selectedTcp) {
            onChange({ ...selectedTcp, sitename, uniqueId: Math.floor(1000 + Math.random() * 9000) });
        }
        setSearch('');
        // Update the key to force re-render
        setKey((prevKey) => prevKey + 1);
    };

    return (
        <AutoComplete
            onChange={onChangeHandler}
            disableFilter
            maxSuggestions={15}
            suggestWhenEmpty={false}
            key={key}
        >
            <AutoCompleteInput
                variant="filled"
                placeholder="Введите № или наименование вида эАП"
                borderColor="gray.100"
                onChange={(event) => setSearch(event.target.value)}
                value={search}
                disabled={!treatyNum || !region}
                className="tcp-search"
            />
            <AutoCompleteList zIndex={100}>
                {data?.map((tcp: any) => (
                    <AutoCompleteItem key={tcp.onec_id} value={tcp.onec_id} className="tcp-search-item">
                        {`${tcp.ext_id}.${tcp.name}`}
                    </AutoCompleteItem>
                ))}
            </AutoCompleteList>
        </AutoComplete>
    );
}

export default TCPSearch;
