import {
    Button,
    Card,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useToast,
    Stack,
    ButtonGroup
} from '@chakra-ui/react';
import moment from 'moment/moment';
import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import { EditIcon } from '@chakra-ui/icons';
import Label from '../../../../new-components/ui/Label';
import { IOrderAcceptanceTechAct } from '../../utils/interfaces/OrderAcceptance';
import HasAccess from '../../../../guards/HasAccess';
import { orderAcceptanceAccesses } from '../../utils/accesses';
import OrderAcceptanceTechActFormFields from './OrderAcceptanceTechActFormFields';
import { orderService } from '../../../../api/services';

interface IProps {
    techAct: IOrderAcceptanceTechAct;
    onSuccessUpdate: () => void;
}
const OrderAcceptanceTechActDetail: FC<IProps> = ({ techAct, onSuccessUpdate }) => {
    const [showEditDetail, setShowEditDetail] = useState(false);
    const [startDate, setStartDate] = useState<Date>();
    const [signingDate, setSigningDate] = useState<Date>();
    const toast = useToast();
    useEffect(() => {
        if (!techAct.signingDate || !techAct.startDate) return;
        setStartDate(new Date(techAct.startDate));
        setSigningDate(new Date(techAct.signingDate));
    }, []);

    const onUpdate: FormEventHandler = (e) => {
        e.preventDefault();
        if (!startDate || !signingDate) return;
        orderService
            .editAcceptanceDocTechAct(techAct.id, {
                startDate,
                signingDate
            })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Тех акт отредактирован'
                });
                setShowEditDetail(false);
                onSuccessUpdate();
            })
            .catch((err) => {
                const resError = err.response.data.message;
                toast({
                    status: 'error',
                    title: resError.title || 'Ошибка',
                    description: resError.details || 'Не удалось отредактировать тех.акт'
                });
            });
    };

    if (!techAct.signingDate || !techAct.startDate) return null;
    return (
        <>
            {showEditDetail && (
                <Modal isOpen onClose={() => setShowEditDetail(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader borderBottom="1px solid" borderColor="gray.300">
                            Изменение тех. акта
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <form>
                                <Stack gap={2}>
                                    <OrderAcceptanceTechActFormFields
                                        startDate={startDate}
                                        onChangeSingingDate={setSigningDate}
                                        signingDate={signingDate}
                                        onChangeStartDate={setStartDate}
                                    />
                                    <ButtonGroup>
                                        <Button
                                            colorScheme="purple"
                                            variant="outline"
                                            onClick={() => setShowEditDetail(false)}
                                        >
                                            Отмена
                                        </Button>
                                        <Button
                                            onClick={onUpdate}
                                            disabled={!startDate || !signingDate}
                                            type="submit"
                                            colorScheme="purple"
                                            ml="auto"
                                        >
                                            Сохранить
                                        </Button>
                                    </ButtonGroup>
                                </Stack>
                            </form>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
            <Card p={2}>
                <Stack gap={1}>
                    <Stack direction="row" justifyContent="space-between">
                        <Label text="Дополнительная информация" />
                        <HasAccess roleNames={orderAcceptanceAccesses.docs.techAct.editDetail}>
                            <Button variant="link">
                                <EditIcon onClick={() => setShowEditDetail(true)} />
                            </Button>
                        </HasAccess>
                    </Stack>
                    <div>Дата подписания: {moment(techAct.signingDate).format('DD.MM.YYYY')}</div>
                    <div>Дата интеграции: {moment(techAct.startDate).format('DD.MM.YYYY')}</div>
                </Stack>
            </Card>
        </>
    );
};

export default OrderAcceptanceTechActDetail;
