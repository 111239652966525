import { Box, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { generateTitle } from 'utils';
import HasNotAccess from '../../guards/HasNotAccess';
import { ERoleNames } from '../../constants';

const Main = () => {
    useTitle(generateTitle('Главная'));
    return (
        <Box shadow="md" borderWidth="1px" p={3} bg="white" rounded="md" h="100%">
            <Text textAlign="center" fontSize="xl" fontWeight={500}>
                Добрый день
            </Text>
            <Text textAlign="center" marginTop="40px" fontSize="2xl" fontWeight={600}>
                Выберите раздел:
            </Text>

            <Box display="flex" marginTop="20px" justifyContent="center">
                <HasNotAccess roleNames={[ERoleNames.EXECUTOR, ERoleNames.SUBC, ERoleNames.SZ]}>
                    <Link to="/sites">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            p="10"
                            shadow="base"
                            borderWidth="1px"
                            bg="white"
                            transition="all .2s ease-in-out"
                            _hover={{
                                transform: 'scale(1.09)'
                            }}
                            fontWeight={600}
                        >
                            Список сайтов
                        </Box>
                    </Link>
                    <Link to="/works">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            p="10"
                            shadow="base"
                            borderWidth="1px"
                            bg="white"
                            ml="20px"
                            transition="all .2s ease-in-out"
                            _hover={{
                                transform: 'scale(1.09)'
                            }}
                            fontWeight={600}
                        >
                            Список элементов адресного плана
                        </Box>
                    </Link>
                </HasNotAccess>
                <Link to="/orders">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p="10"
                        shadow="base"
                        borderWidth="1px"
                        bg="white"
                        ml="20px"
                        transition="all .2s ease-in-out"
                        _hover={{
                            transform: 'scale(1.09)'
                        }}
                        fontWeight={600}
                    >
                        Список заказов
                    </Box>
                </Link>
            </Box>
            <Text textAlign="center" marginTop="20px">
                или выберите из нужного в боковом меню слева
            </Text>
        </Box>
    );
};

export default Main;
