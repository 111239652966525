import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    useToast
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Upload } from 'antd';
import Label from 'new-components/ui/Label';
import { matListTrsService } from 'api/services';
import { IItemsToIssue } from '../../../Works/IssuanceTr';
import { ITrDetail } from '../../utils/interfaces/MatList';

interface IProps {
    itemsToIssue: IItemsToIssue;
    onClose: () => void;
    tr: ITrDetail;
}
const TrIssueModal: FC<IProps> = ({ itemsToIssue, onClose, tr }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [fileInvoice, setFileInvoice] = useState<any>();
    // TODO Разобраться с formik + файловый инпут

    const formik = useFormik({
        initialValues: {
            sapNumber: ''
        },
        onSubmit: async () => {
            if (!fileInvoice?.file) return;
            const formData = new FormData();
            formData.append('invoice', fileInvoice?.file);
            formData.append('issuanceEquipment', JSON.stringify(itemsToIssue));
            await matListTrsService
                .nextStep(tr.id, formData)
                .then(() => {
                    toast({
                        status: 'success',
                        title: 'Успешно!',
                        description: `TR выдан!`
                    });
                    navigate(-1);
                })
                .catch((e) => {
                    toast({
                        status: 'error',
                        title: 'Ошибка',
                        description: e.response.data.message
                    });
                });
        }
    });
    return (
        <Modal isOpen onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderBottom="1px solid" borderColor="gray.300">
                    Выдача TR #{tr?.sapNumber}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack width={300} alignItems="top">
                            <Box my={2}>
                                <Label text="Накладная" />
                                <Stack gap={2}>
                                    <Upload
                                        multiple
                                        accept=".pdf"
                                        name="file"
                                        showUploadList
                                        maxCount={1}
                                        onChange={(file) => setFileInvoice(file)}
                                        beforeUpload={() => false}
                                    >
                                        <Button width="100%">Загрузить PDF</Button>
                                    </Upload>
                                </Stack>
                            </Box>
                            <Button isDisabled={!fileInvoice?.file} colorScheme="green" type="submit">
                                Выдать TR
                            </Button>
                        </Stack>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
export default TrIssueModal;
