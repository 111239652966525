import { IoIosListBox, IoIosPricetag, IoMdCreate } from 'react-icons/io';
import { HiDocumentReport, HiScale } from 'react-icons/hi';
import { IconType } from 'react-icons';

const navConfig: Array<{
    subheader: string;
    cantViewAs?: string;
    items: Array<{
        icon: IconType;
        title: string;
        path: string;
        cantViewAs?: string;
    }>;
}> = [
    {
        subheader: 'Сайты',
        cantViewAs: 'Подрядная организация, Субподрядчик, Группа проверки и согласования заказов',
        items: [
            {
                title: 'Список сайтов (rollout)',
                path: '/sites',
                icon: IoIosListBox
            },
            {
                icon: IoMdCreate,
                title: 'Создание сайта',
                path: '/create/site',
                cantViewAs:
                    'Отдел планирования трансмиссионной сети, Группа проектирования инфраструктуры сети, Группа лицензии и разрешительных документов, Отдел логистики, Отдел эксплуатации, Служба мониторинга контроля качества и эффективности сети, Отдел оптимизации сети, Подрядная организация, Специалист по проектам, Отдел сети радиодоступа - систем LI и дата центров, Отдел договоров аренды'
            }
        ]
    },
    {
        subheader: 'АП',
        cantViewAs: 'Подрядная организация, Субподрядчик, Группа проверки и согласования заказов',
        items: [
            {
                icon: IoIosListBox,
                title: 'Адресный план (модернизация)',
                path: '/works'
            },
            {
                icon: IoMdCreate,
                title: 'Создание ЭАП',
                path: '/create/work',
                cantViewAs:
                    'Группа проектирования инфраструктуры сети, Отдел логистики, Склад, Служба внутреннего аудита, Отдел оптимизации сети, Отдел эксплуатации, Отдел строительства, Отдел контроля качества сервисов и локальных решений, Отдел контроля оборудования, Отдел контроля финансов - заказов и договоров, Группа лицензии и разрешительных документов, Служба мониторинга контроля качества и эффективности сети, Подрядная организация, Специалист по проектам, Отдел сети радиодоступа - систем LI и дата центров, Отдел договоров аренды'
            }
        ]
    },
    {
        subheader: 'Заказы',
        cantViewAs:
            'Отдел логистики, Служба мониторинга контроля качества и эффективности сети, Специалист по проектам, Отдел сети радиодоступа - систем LI и дата центров',
        items: [
            {
                icon: IoIosPricetag,
                title: 'Список заказов',
                path: '/orders'
            }
        ]
    },
    {
        subheader: 'Отчеты',
        cantViewAs: 'Подрядная организация, Субподрядчик, Группа проверки и согласования заказов',
        items: [
            {
                icon: HiDocumentReport,
                title: 'Отчеты',
                path: '/reports/orders'
            }
        ]
    },
    {
        subheader: 'Оборудование',
        cantViewAs:
            'Субподрядчик, Группа проверки и согласования заказов, Отдел планирования трансмиссионной сети, Группа проектирования инфраструктуры сети, Группа лицензии и разрешительных документов, Отдел логистики, Служба мониторинга контроля качества и эффективности сети, Специалист по проектам, Отдел сети радиодоступа - систем LI и дата центров, Отдел договоров аренды, Отдел строительства, Отдел эксплуатации, Склад, Отдел планирования радиосети, Подрядная организация',
        items: [
            {
                icon: HiScale,
                title: 'Оборудование',
                path: '/equipments'
            },
            {
                icon: IoMdCreate,
                title: 'Создание оборудования',
                path: '/equipments/create',
                cantViewAs:
                    'Группа проектирования инфраструктуры сети, Группа лицензии и разрешительных документов, Отдел логистики, Служба мониторинга контроля качества и эффективности сети, Подрядная организация, Специалист по проектам, Отдел сети радиодоступа - систем LI и дата центров, Отдел договоров аренды'
            }
        ]
    }
];

export default navConfig;
