// @chakra-ui
import { Stack, Input, InputGroup, InputLeftElement, Button, Text, Spacer, Box } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';

// icons
import { Upload } from 'antd';
import React from 'react';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface SiteTableToolbarProps {
    onFilterSiteId: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onExportSites: () => void;
    onExportSSD: () => void;
    onExportRSD: () => void;
    filterSiteId?: string;
    totalSites: number;
    loadingExportSSD: boolean;
    loadingExportSites: boolean;
    loadingExportRSD: boolean;
    showExportSSD: boolean;
    showExportRSD: boolean;
    filterRegion: any;
    showUpload: boolean;
    showUploadRSD: boolean;
    uploadRsd: (data: any) => void;
    upload: (data: any) => void;
}

const SiteTableToolbar: React.FC<SiteTableToolbarProps> = ({
    filterSiteId,
    onFilterSiteId,
    onExportSites,
    onExportSSD,
    onExportRSD,
    totalSites,
    loadingExportSSD,
    loadingExportSites,
    loadingExportRSD,
    showExportSSD,
    showExportRSD,
    filterRegion,
    showUploadRSD,
    showUpload,
    uploadRsd,
    upload
}: SiteTableToolbarProps) => (
    <Box width="full" p={1.5}>
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between" spacing={1}>
            <Text alignSelf="center" fontWeight="bold">
                Список сайтов ({totalSites})
            </Text>

            <InputGroup size="sm" width="auto">
                <InputLeftElement pointerEvents="none">
                    <Search2Icon color="gray.300" />
                </InputLeftElement>
                <Input
                    paddingLeft={4}
                    placeholder="Поиск по ID сайта"
                    defaultValue={filterSiteId || ''}
                    onChange={onFilterSiteId}
                />
            </InputGroup>

            <Spacer />
            {showUpload && (
                <Button flexShrink={0} size="sm" colorScheme="teal" onClick={(data: any) => upload(data)}>
                    Импорт сайтов
                </Button>
            )}
            {showUploadRSD && (
                <Upload
                    multiple
                    name="file"
                    showUploadList={false}
                    maxCount={1}
                    onChange={(data: any) => uploadRsd(data)}
                    beforeUpload={() => false}
                >
                    <Button flexShrink={0} aria-label="upload-btn" size="sm" colorScheme="teal">
                        Импорт RSD
                    </Button>
                </Upload>
            )}
            <Button
                flexShrink={0}
                isLoading={loadingExportSites}
                disabled={loadingExportSites}
                size="sm"
                colorScheme="teal"
                onClick={onExportSites}
            >
                Экспорт сайтов
            </Button>

            {showExportRSD && (
                <Button
                    flexShrink={0}
                    isLoading={loadingExportRSD}
                    disabled={loadingExportRSD}
                    size="sm"
                    colorScheme="teal"
                    onClick={onExportRSD}
                >
                    Экспорт RSD
                </Button>
            )}

            {showExportSSD && (
                <Button
                    flexShrink={0}
                    isLoading={loadingExportSSD}
                    disabled={loadingExportSSD}
                    size="sm"
                    colorScheme="teal"
                    onClick={onExportSSD}
                >
                    Экспорт SSD
                </Button>
            )}
        </Stack>
        {filterRegion.length ? <Text mt={1}>Выбранные регионы: {filterRegion.join(', ')}</Text> : null}
    </Box>
);

export default SiteTableToolbar;
