import { Box, Button, ScaleFade, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { generateTitle } from 'utils';

const Welcome = () => {
    const navigate = useNavigate();

    const goToLogin = () => {
        localStorage.setItem('isWelcomePageVisited', '1');
        navigate('/login');
    };

    useEffect(() => {
        /* if (localStorage.getItem('isWelcomePageVisited') === '1') {
            navigate('/login');
        } */
        goToLogin();
    }, []);

    useTitle(generateTitle('Добро пожаловать!'));

    return (
        <ScaleFade in delay={0.16}>
            <Box
                height="100vh"
                width="100vw"
                display="flex"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
            >
                <Box mt="auto" />
                <img src="/images/welcome-screen.svg" alt="Добро пожаловать!" style={{ height: '240px' }} />
                <Text fontSize="4xl" textAlign="center" mt={3} fontWeight={600}>
                    Добро пожаловать в CIP Tracker!
                </Text>
                <Text fontSize="2xl" textAlign="center" mt={6} fontWeight={600} width={600}>
                    Платформа SAD была полностью пересена в CIP Tracker,
                    <span style={{ color: 'green' }}>
                        &nbsp;все ваши данные были сохранены и доступны в CIP Tracker, весь функционал
                        доступный вам ранее - доступен и сейчас. Вы можете авторизоваться используя свой логин
                        SAD. Инструкции по восстановлению пароля были ранее высланы вам на почту.
                    </span>
                </Text>
                <Button colorScheme="whatsapp" size="lg" mt={3} onClick={goToLogin}>
                    Начать работу!
                </Button>
                <Text fontSize="1xl" textAlign="center" mt="auto" mb={3} fontWeight={600} width={600}>
                    С 21.05.2022 платформа SAD доступна исключительно для просмотра, редактирование отключено.
                    В случае необходимости редактирования в SAD просьба обращаться на почту
                    kcell-ciptracker-support@tele2.kz
                </Text>
            </Box>
        </ScaleFade>
    );
};

export default Welcome;
