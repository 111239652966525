import { Box, Button, FormControl, FormErrorMessage, useToast } from '@chakra-ui/react';
import { Select } from 'antd';
import axios from 'axios';
import Input from 'components/Input/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const locations = [
    { value: 'Актау', lable: 'Актау' },
    { value: 'Тараз', lable: 'Тараз' },
    { value: 'Шымкент', lable: 'Шымкент' },
    { value: 'Актобе', lable: 'Актобе' },
    { value: 'Атырау', lable: 'Атырау' },
    { value: 'Караганда', lable: 'Караганда' },
    { value: 'Кокшетау', lable: 'Кокшетау' },
    { value: 'Костанай', lable: 'Костанай' },
    { value: 'Кызылорда', lable: 'Кызылорда' },
    { value: 'Павлодар', lable: 'Павлодар' },
    { value: 'Петропавловск', lable: 'Петропавловск' },
    { value: 'Семей', lable: 'Семей' },
    { value: 'Тараз', lable: 'Тараз' },
    { value: 'Усть-Каменогорск', lable: 'Усть-Каменогорск' },
    { value: 'Уральск', lable: 'Уральск' },
    { value: 'Алматы', lable: 'Алматы' },
    { value: 'Астана', lable: 'Астана' }
];

const initialValues = {
    state_number: '',
    summer_fuel_consumption: '',
    winter_fuel_consumption: '',
    car_brand: '',
    region: '',
    location: ''
};

const ValidationSchema = Yup.object().shape({
    state_number: Yup.string().required('Поле обязательное'),
    summer_fuel_consumption: Yup.number().required('Поле обязательное'),
    winter_fuel_consumption: Yup.number().required('Поле email должно быть заполнено'),
    car_brand: Yup.string().required('Поле обязательное'),
    region: Yup.string().required('Поле обязательное'),
    location: Yup.string().required('Поле обязательное')
});

export default function CreateCar() {
    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            setSubmitting(true);
            axios
                .post(`/cars-oes`, {
                    ...values
                })
                .then(() => {
                    toast({
                        status: 'success',
                        title: `Успешно запрошено, проверьте указанную почту`
                    });
                    setSubmitting(false);
                    resetForm();
                })
                .catch((error) => {
                    console.log({ ...error });
                    toast({
                        status: 'error',
                        title:
                            error.response.status === 409
                                ? error.response.data.message
                                : 'При запросе возникла ошибка'
                    });
                    setSubmitting(false);
                });
        },
        validationSchema: ValidationSchema
    });

    return (
        <Box display="flex" justifyContent="center" mt="14">
            <Box backgroundColor="white" padding="5" borderRadius="10px" width="30%">
                <form onSubmit={formik.handleSubmit}>
                    <Box>
                        <FormControl
                            isInvalid={
                                !!formik.errors.state_number && (formik.touched.state_number as boolean)
                            }
                            isRequired
                        >
                            <FormErrorMessage mt="0.5rem">{formik.errors.state_number}</FormErrorMessage>
                            <Input
                                placeholder="госномер авто"
                                value={formik.values.state_number}
                                onChange={(e) => formik.setFieldValue('state_number', e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <FormControl
                            isInvalid={!!formik.errors.car_brand && (formik.touched.car_brand as boolean)}
                            isRequired
                        >
                            <FormErrorMessage mt="0.5rem">{formik.errors.car_brand}</FormErrorMessage>
                            <Input
                                placeholder="марка авто"
                                value={formik.values.car_brand}
                                onChange={(e) => formik.setFieldValue('car_brand', e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <FormControl
                            isInvalid={
                                !!formik.errors.summer_fuel_consumption &&
                                (formik.touched.summer_fuel_consumption as boolean)
                            }
                            isRequired
                        >
                            <FormErrorMessage mt="0.5rem">
                                {formik.errors.summer_fuel_consumption}
                            </FormErrorMessage>
                            <Input
                                placeholder="норма расхода летом"
                                value={String(formik.values.summer_fuel_consumption)}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'summer_fuel_consumption',
                                        e.target.value.replace(/\D+\/,/g, '')
                                    )
                                }
                            />
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <FormControl
                            isInvalid={
                                !!formik.errors.winter_fuel_consumption &&
                                (formik.touched.winter_fuel_consumption as boolean)
                            }
                            isRequired
                        >
                            <FormErrorMessage mt="0.5rem">
                                {formik.errors.winter_fuel_consumption}
                            </FormErrorMessage>
                            <Input
                                placeholder="норма расхода зимой"
                                value={String(formik.values.winter_fuel_consumption)}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'winter_fuel_consumption',
                                        e.target.value.replace(/\D+\/,/g, '')
                                    )
                                }
                            />
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <FormControl
                            isInvalid={!!formik.errors.region && (formik.touched.region as boolean)}
                            isRequired
                        >
                            <FormErrorMessage mt="0.5rem">{formik.errors.region}</FormErrorMessage>
                            <Input
                                placeholder="регион"
                                value={formik.values.region}
                                onChange={(e) => formik.setFieldValue('region', e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box mt="2">
                        <FormControl isInvalid={!!formik.errors.location} isRequired>
                            <FormErrorMessage mt="0.5rem">{formik.errors.location}</FormErrorMessage>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Выберите город"
                                defaultValue={[]}
                                onChange={(value) => {
                                    formik.setFieldValue('location', value);
                                }}
                                options={locations}
                            />
                        </FormControl>
                    </Box>
                    <Button colorScheme="purple" marginTop="3" type="submit">
                        Зарегистрировать авто
                    </Button>
                </form>
            </Box>
        </Box>
    );
}
