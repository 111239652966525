import {
    Box,
    Button,
    Fade,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Stack,
    useToast
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const formLabelStyle = {
    fontSize: '1rem',
    color: '#1A202C',
    marginBottom: '0.5rem'
};

const initialValues = {
    name: '',
    odometerStart: '',
    odometerFinish: '',
    shift_start: new Date(),
    shift_end: new Date()
};

const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Поле обязательное'),
    odometerStart: Yup.number().required('Поле обязательное'),
    odometerFinish: Yup.number().required('Поле обязательное'),
    shift_start: Yup.date(),
    shift_end: Yup.date()
});

export default function WorkShiftEditing() {
    const { id } = useParams();

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const formik = useFormik({
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            const updateData = {
                name: values.name,
                odometerStart: String(values.odometerStart),
                odometerFinish: String(values.odometerFinish),
                shift_start: moment(values.shift_start).toDate(),
                shift_end: moment(values.shift_end).toDate()
            };

            axios
                .put(`/driver-work-shifts/${id}`, updateData)
                .then(() => {
                    toast({
                        status: 'success',
                        title: `Успешно обновлено`
                    });
                    setSubmitting(false);
                })
                .catch((error) => {
                    toast({
                        status: 'error',
                        title: 'При обновлении произошла ошибка'
                    });
                    console.error(error);
                    setSubmitting(false);
                });
        },
        validationSchema: ValidationSchema
    });

    const getData = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver-work-shifts/${id}`);

        const tmpData = {
            name: data.name,
            odometerStart: data.odometerStart,
            odometerFinish: data.odometerFinish,
            shift_start: data.shift_start,
            shift_end: data.shift_end ? data.shift_end : new Date()
        };

        formik.setValues(tmpData);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Box bg="white" maxWidth="500px" marginX="auto" borderRadius={10} p={3}>
            <Fade in style={{ display: 'flex', width: '100%', height: '100%' }}>
                <Box width="100%" display="flex" flexDir="column">
                    <form onSubmit={formik.handleSubmit}>
                        <Heading as="h4" size="md" mb={3}>
                            Смена водителя
                        </Heading>
                        <Stack spacing={2} width="100%">
                            <FormControl>
                                <Box>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.name}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Имя</FormLabel>
                                    <Input
                                        value={formik.values.name || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('name', value.target.value);
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">{formik.errors.name}</FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.odometerStart}
                                    </FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Начало смены(одометр)</FormLabel>
                                    <Input
                                        value={formik.values.odometerStart || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('odometerStart', value.target.value);
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.odometerStart}
                                    </FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.odometerFinish}
                                    </FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Конец смены(одометр)</FormLabel>
                                    <Input
                                        value={formik.values.odometerFinish || undefined}
                                        onChange={(value) => {
                                            formik.setFieldValue('odometerFinish', value.target.value);
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.odometerFinish}
                                    </FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.shift_start}
                                    </FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Дата начала смены</FormLabel>
                                    <Input
                                        type="datetime-local"
                                        value={moment(formik.values.shift_start)
                                            .zone('+0600')
                                            .format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            formik.setFieldValue(
                                                'shift_start',
                                                moment(e.target.value).toDate()
                                            );
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">
                                        {formik.errors.shift_start}
                                    </FormErrorMessage>
                                </Box>
                                <Box mt={2}>
                                    <FormErrorMessage mt="0.5rem">{formik.errors.shift_end}</FormErrorMessage>
                                    <FormLabel style={formLabelStyle}>Дата окончания смены</FormLabel>
                                    <Input
                                        type="datetime-local"
                                        value={moment(formik.values.shift_end)
                                            .zone('+0600')
                                            .format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e) => {
                                            console.log(e);
                                            formik.setFieldValue(
                                                'shift_end',
                                                moment(e.target.value).toDate()
                                            );
                                        }}
                                    />
                                    <FormErrorMessage mt="0.5rem">{formik.errors.shift_end}</FormErrorMessage>
                                </Box>
                            </FormControl>
                        </Stack>
                        <Button
                            type="submit"
                            colorScheme="purple"
                            width="100%"
                            mt="1rem"
                            isLoading={formik.isSubmitting}
                            isDisabled={formik.isSubmitting}
                            loadingText="Обновляем..."
                        >
                            Сохранить
                        </Button>
                    </form>
                </Box>
            </Fade>
        </Box>
    );
}
