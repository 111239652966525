/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const TSSRLink = styled.div`
    display: flex;
    transition: all 0.2s ease-in-out;
    border-radius: 3px;
    padding: 6px;

    :hover {
        color: white;
        background: #1f2229;
    }

    > span {
        padding-left: 16px;
    }
`;

const SiteIDLink = styled.div`
    width: fit-content;
    margin: 16px 0;
    font-size: 18px;
    color: white;
    background: #1f2229;
    padding: 8px;
    border-radius: 6px;
    user-select: none;
    transition: all 0.2s ease-in-out;
`;

export { TSSRLink, SiteIDLink };
