import axios from 'axios';
import qs from 'qs';

const list = ({ params, cancelToken }: any) =>
    axios.get(`/rsds`, { params, paramsSerializer: (params) => qs.stringify(params), cancelToken });

const count = ({ params, cancelToken }: any) =>
    axios.get(`/rsds/count`, { params, paramsSerializer: (params) => qs.stringify(params), cancelToken });

const update = (id: number, data: any) => axios.patch(`/rsds/${id}`, data);

const massUpdate = (data: any) => axios.patch('/rsds', data);

const create = (data: any) => axios.post('/rsds', { data });

const getOptions = () => axios.get('/rsds/dicrinary');

const remove = (id: number) => axios.delete(`/rsds/${id}`);

export default {
    list,
    count,
    update,
    create,
    getOptions,
    remove,
    massUpdate
};
