import { ORDER_STEPS, ACT_STEPS } from '../constants';

export const generateTitle = (prefix: string): string => `${prefix} · CIP Tracker`;

export const getTextByApproveStatus = (status: boolean | null): string =>
    status === true ? 'Согласовано' : status === false ? 'Отказано' : 'Ожидает согласования';

export const getMainWorkStatusByCode = (code: number) => {
    let text;
    switch (code) {
        case 1:
            text = 'Заявка создана. АП еще не проведен.';
            break;
        case 2:
            text = 'АП проведен, необходимо согласовать заявку.';
            break;
        case 3:
            text = 'Заявка отклонена с замечаниями для подрядчиков.';
            break;
        case 4:
            text = 'Заявка согласована и в архиве.';
            break;
        case 5:
            text = 'ЭАП подписан ГПО, ожидается решение ОЛ.';
            break;
        case 6:
            text = 'Согласовано отделом логистики, ожидает подписания ОЭ.';
            break;
        default:
            text = 'Статус не определен.';
    }
    return text;
};

export const isTSSRApproved = (data: any) => {
    const DEPARTMENTS = [
        {
            key: 'dt',
            label: 'Design Team'
        },
        {
            key: 'rl',
            label: 'Legalization Team'
        },
        {
            key: 'oe',
            label: 'Отдел эксплуотации'
        },
        {
            key: 'le',
            label: 'Отдел договоров и аренды'
        },
        {
            key: 'tr',
            label: 'Transport Unit Team'
        },
        {
            key: 'rp',
            label: 'Отдел планирования радиосети'
        }
    ];
    return DEPARTMENTS.every((dep) => data[`tssr_${dep.key}_approve`]?.status);
};

export const currencyFormat = (amount: string | number, currency = '₸') =>
    // @ts-ignore
    `${Number(amount).toLocaleString('ru-RU')} ${currency}`;

export const getRange = (start: number, end: number, step = 1) => {
    const output = [];
    if (typeof end === 'undefined') {
        end = start;
        start = 0;
    }
    for (let i = start; i <= end; i += step) {
        output.push(i);
    }
    return output;
};

export const getOrderSignInfo = (step: number, esigns?: any, role?: string, can_sign?: any) => {
    if (!esigns?.length || step !== ORDER_STEPS.SIGNED || !role || !esigns[0].status) {
        return [];
    }
    const { sign_by_subcon, sign_by_sad } = esigns[0].status;
    if (sign_by_sad && sign_by_subcon) return [];
    const signingStatusIsWaiting = esigns[0]?.signingStatus === 'waiting';
    if (role === 'Подрядная организация') {
        return [
            {
                showSign: !sign_by_subcon && can_sign !== false && !signingStatusIsWaiting,
                showDecline: !sign_by_subcon,
                color: sign_by_subcon ? 'green' : 'yellow',
                text: sign_by_subcon
                    ? 'Подписан ГПО'
                    : signingStatusIsWaiting
                    ? 'Подписывается'
                    : 'Ожидает ГПО'
            },
            {
                color: sign_by_sad ? 'green' : 'yellow',
                text: sign_by_sad ? 'Подписан МТС' : signingStatusIsWaiting ? 'Подписывается' : 'Ожидает МТС'
            }
        ];
    }
    if (
        role === 'Отдел строительства' ||
        role === 'SITEACQUIS' ||
        role === 'Отдел эксплуатации' ||
        role === 'HEAD_OF_NETW_DEVELOP' ||
        role === 'HEAD_TECHNICAL'
    ) {
        return [
            {
                showSign: can_sign !== false && !sign_by_sad && !signingStatusIsWaiting,
                showDecline: !sign_by_sad,
                color: sign_by_sad ? 'green' : 'yellow',
                text: sign_by_sad ? 'Подписан МТС' : signingStatusIsWaiting ? 'Подписывается' : 'Ожидает МТС'
            },
            {
                color: sign_by_subcon ? 'green' : 'yellow',
                text: sign_by_subcon
                    ? 'Подписан ГПО'
                    : signingStatusIsWaiting
                    ? 'Подписывается'
                    : 'Ожидает ГПО'
            }
        ];
    }
    return [];
};

export const getActSignInfo = (step: number, esigns?: any, role?: string, userData?: any) => {
    if (!esigns?.length || step !== ACT_STEPS.SIGNED || !role) {
        return [];
    }
    const { sign_by_subcon, sign_by_sad } = esigns[0].status;
    if (sign_by_sad && sign_by_subcon) return [];
    if (role === 'Подрядная организация') {
        return [
            {
                showSign: !sign_by_subcon && userData?.can_sign,
                showDecline: !sign_by_subcon,
                color: sign_by_subcon ? 'green' : 'yellow',
                text: sign_by_subcon ? 'Подписан ГПО' : 'Ожидает ГПО'
            },
            {
                color: sign_by_sad ? 'green' : 'yellow',
                text: sign_by_sad ? 'Подписан МТС' : 'Ожидает МТС'
            }
        ];
    }
    if (
        role === 'Отдел строительства' ||
        role === 'SITEACQUIS' ||
        role === 'Отдел эксплуатации' ||
        role === 'HEAD_OF_NETW_DEVELOP' ||
        role === 'HEAD_TECHNICAL'
    ) {
        return [
            {
                showSign: userData?.can_sign !== false && !sign_by_sad,
                showDecline: !sign_by_sad,
                color: sign_by_sad ? 'green' : 'yellow',
                text: sign_by_sad ? 'Подписан МТС' : 'Ожидает МТС'
            },
            {
                color: sign_by_subcon ? 'green' : 'yellow',
                text: sign_by_subcon ? 'Подписан ГПО' : 'Ожидает ГПО'
            }
        ];
    }
    return [];
};
