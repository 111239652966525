import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { setAuthFallbackLink } from 'redux/reducers/globalSlice';
import { userSelector } from '../redux/reducers/userSlice';

interface Props {
    children?: JSX.Element;
}

export default function AuthGuard({ children }: Props) {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);

    const renewUserData = async () => {
        /* try {
            const userData: any = await getMeFromAPI();

            console.log(userData);
            dispatch(setData(userData));
            dispatch(setRole(userData!.role));
        } catch (error) {
            toast.error(error as ToastContent);
        } */
    };

    useLayoutEffect(() => {
        let renewUserDataInterval: null | NodeJS.Timer = null;

        if (!user.isAuthed) {
            const loc = window.location;
            dispatch(setAuthFallbackLink(loc.pathname + loc.search + loc.hash));
        } else {
            renewUserData();

            renewUserDataInterval = setInterval(() => {
                if (user.isAuthed) {
                    renewUserData();
                }
            }, 180000);
        }

        return () => {
            if (renewUserDataInterval) clearInterval(renewUserDataInterval);
        };
    }, []);

    return user.isAuthed === true ? (children as JSX.Element) : <Navigate to="/login" replace />;
}
