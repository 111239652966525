import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Box, Card, CardBody, Stack, useToast, Radio, RadioGroup, Button, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import useDebounce from 'hooks/useDebounce';
import OrderProjectFiles from 'Routes/Order/components/OrderProjectFiles';
import OrderEquipment from 'Routes/Order/components/OrderEquipment';
import { FileExcelOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ordersProjectsService, orderService } from '../../api/services';
import { IOrderInProject } from '../../interfaces/orders';
import { IEsignWithOrderInfo } from '../../interfaces/esigns';
import UploadProjectFile from './components/UploadProjectFile';
import SelectExistingFiles from './components/SelectExistingFiles';
import ChangeRequiredProject from './components/ChangeRequiredProject';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { OrderContext } from './OrderCreate/OrderCreate';
import HistoryModal from '../../new-components/HistoryModal';
import HasAccess from '../../guards/HasAccess';
import { ENGINEERS_GROUP_ROLES } from '../../constants';
import { userSelector } from '../../redux/reducers/userSlice';

export interface IOrderProject {
    isEquipmentRequired: boolean;
    order: IOrderInProject;
    esign: IEsignWithOrderInfo;
    id: number;
}

const OrderProject: FC = () => {
    const { data: user } = useSelector(userSelector);
    const { orderID } = useParams();
    const [showHistory, setShowHistory] = useState(false);
    const [project, setProject] = useState<IOrderProject | null>(null);
    const [existingEsigns, setExistingEsigns] = useState<IEsignWithOrderInfo[]>([]);
    const [fileOption, setFileOption] = useState('select');
    const [filenameSearch, setFilenameSearch] = useState<string>('');
    const debounceFilenameSearch = useDebounce(filenameSearch, 500);
    const [editMode, setEditMode] = useState(false);
    const toast = useToast();
    const order = useContext(OrderContext);
    const [showConfirmStartProcessModal, setShowConfirmStartProcessModal] = useState(false);
    const alertRef = useRef(null);
    const canEdit =
        project?.order.workStatus === 'notStarted' ||
        project?.order.workStatus === 'equipmentNotRequired' ||
        project?.order.workStatus === 'projectUploaded' ||
        project?.order.workStatus === 'equipmentAndProjectNotRequired' ||
        project?.order.workStatus === 'matListRework';
    const canStartProcess = canEdit;
    const fetchProject = () => {
        if (!orderID) return;
        ordersProjectsService.getByOrderId(+orderID).then((res) => {
            setProject(res.data);
        });
    };

    const fetchExistingEsigns = (filename?: string) => {
        orderService.getTssrs(filename).then((res) => {
            setExistingEsigns(res.data);
        });
    };

    useEffect(() => {
        fetchProject();
    }, []);

    useEffect(() => {
        if (canEdit && ENGINEERS_GROUP_ROLES.includes(user?.role.name)) {
            fetchExistingEsigns(debounceFilenameSearch);
        }
    }, [debounceFilenameSearch, canEdit]);

    const onStartProject = () => {
        if (!project) return;
        ordersProjectsService
            .startProcess(project.id)
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Успешно обновлено'
                });
                fetchProject();
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось обновить'
                });
            });
    };

    if (!project) return null;

    return (
        <div>
            {showHistory && (
                <HistoryModal
                    id={project.id}
                    getHistory={ordersProjectsService.getHistory}
                    onClose={() => setShowHistory(false)}
                />
            )}
            <Box>
                <Flex justifyContent="space-between">
                    <div>{!canEdit && <OrderEquipment project={project} />}</div>
                    <Flex gap={2}>
                        <a href="/MR_example.xlsx" target="_blank" rel="noopener noreferrer">
                            <Button size="xs" variant="outline" colorScheme="green">
                                <FileExcelOutlined style={{ marginRight: 5 }} /> Скачать шаблон мат. листа
                            </Button>
                        </a>

                        <Button
                            size="xs"
                            onClick={() => setShowHistory(true)}
                            variant="outline"
                            colorScheme="purple"
                        >
                            История
                        </Button>
                    </Flex>
                </Flex>
                <Card my={2} variant="outline" size="sm">
                    <CardBody p={1}>
                        <Stack gap={1}>
                            <OrderProjectFiles
                                canEdit={canEdit}
                                editMode={editMode}
                                project={project}
                                onEdit={setEditMode}
                            />
                        </Stack>
                        <HasAccess roleNames={ENGINEERS_GROUP_ROLES}>
                            {(editMode || !project.esign) && (
                                <>
                                    {canEdit && (
                                        <>
                                            <RadioGroup onChange={setFileOption} value={fileOption}>
                                                <Stack direction="column" margin={1}>
                                                    <Radio value="select">Выбрать из существующих</Radio>
                                                    <Radio value="upload">Загрузить новый файл</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {fileOption === 'upload' ? (
                                                <UploadProjectFile
                                                    isDisabled={order?.displayConfig.isStopProcess}
                                                    isEdit={!!project?.esign}
                                                    refetchProject={fetchProject}
                                                    project={project}
                                                />
                                            ) : (
                                                <SelectExistingFiles
                                                    existingEsigns={existingEsigns}
                                                    refetchProject={fetchProject}
                                                    project={project}
                                                    onSearch={setFilenameSearch}
                                                    isDisabled={order?.displayConfig.isStopProcess}
                                                    setEditMode={setEditMode}
                                                />
                                            )}
                                            <ChangeRequiredProject
                                                isDisabled={order?.displayConfig.isStopProcess}
                                                refetchProject={fetchProject}
                                                project={project}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </HasAccess>
                    </CardBody>
                </Card>
                {canStartProcess && (
                    <HasAccess roleNames={ENGINEERS_GROUP_ROLES}>
                        {!order?.displayConfig.hasMatList && (
                            <Stack my={1} direction="row" justifyContent="end">
                                <Button
                                    isDisabled={order?.displayConfig.isStopProcess}
                                    onClick={() => setShowConfirmStartProcessModal(true)}
                                    colorScheme="purple"
                                >
                                    Начать процесс
                                </Button>
                            </Stack>
                        )}

                        {showConfirmStartProcessModal && (
                            <ConfirmationDialog
                                title="Подтверждение"
                                onAccept={onStartProject}
                                body="Вы уверены, что хотите начать процесс?"
                                acceptButtonColor="green"
                                isOpen
                                modalRef={alertRef}
                                onClose={() => setShowConfirmStartProcessModal(false)}
                            />
                        )}
                    </HasAccess>
                )}
            </Box>
        </div>
    );
};

export default OrderProject;
