import { Button, Input, Radio, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { userSelector } from 'redux/reducers/userSlice';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { SiteIDLink } from 'Routes/Works/Work.styles';
import { Fade, useToast, Box } from '@chakra-ui/react';
import { generateTitle } from '../utils';

const SiteStructureDetails = () => {
    const { sad_id } = useParams();
    const { role } = useSelector(userSelector);
    const [data, setData] = useState<{
        id: number;
        sad_id: number;
        s_site_main_cab?: string | null;
        s_site_main_place?: string | null;
        sad_station_id: string | null;
        s_site_type: string | null;
        s_site_type_det: string | null;
        s_comment_main: string | null;
        s_rt_roof_type?: string | null;
        s_rt_wall_type?: string | null;
        s_rt_struct_type?: string | null;
        s_gt_site_height: string | null;
        s_gt_struct_type: string | null;
        s_gt_equip_place: string | null;
        s_3m_pole: string | null;
        s_4m_pole: string | null;
        s_6m_pole: string | null;
        s_6quad_pole: string | null;
        s_9quad_pole: string | null;
        s_other_pole: string | null;
        s_other_pole_cmnt?: string | null;
        s_2_offset: string | null;
        s_3_offset: string | null;
        s_16_offset: string | null;
        s_other_offset: string | null;
        s_other_offset_cmnt?: string | null;
        s_pole_base?: string | null;
        s_antivandal_cage: string | null;
        s_pole_cond: string | null;
        s_risk_cathegory: string | null;
        s_risk_conclusion?: string | null;
        s_main_accident_rate?: string | null;
        s_main_accident_rate_cmnt?: string | null;
        s_main_work_category: string | null;
        s_main_accid_mark_cmnt: string | null;
        site: any;
    } | null>(null);

    useTitle(generateTitle(`SSD ${data?.site?.s_new_site_id}`));

    useHeaderTitle(`Просмотр SSD сайта #${data?.site?.s_new_site_id}`);

    const [id, setId] = useState('');

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (role?.name === 'Группа проектирования инфраструктуры сети') {
            setDisabled(false);
        }
    }, [role]);

    const getData = () => {
        axios
            .get(`/site-struct-details?site_id=${sad_id}`)
            .then((res) => {
                if (res.data.data) {
                    setData(res.data?.data);
                    setId(res.data?.data?.id);
                } else {
                    toast({
                        status: 'info',
                        title: `Данные по SSD не найдены`
                    });
                }
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: `Произошла неизвестная ошибка при получении SSD`
                });
                console.error(err);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const save = () => {
        axios
            .patch(`/site-struct-details/${id}`, {
                data
            })
            .then(() => {
                toast({
                    status: 'success',
                    title: `Успешно сохранено`
                });
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: `При сохранении произошла ошибка`
                });
                console.error(err);
            });
    };

    return (
        <Box bgColor="white" borderRadius={10} p={2}>
            <Fade in>
                {data && (
                    <>
                        <div
                            style={{
                                padding: '16px 30px',
                                backgroundColor: '#ffffff',
                                borderRadius: '6px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Категория ЭАП:
                                <Select
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_main_work_category}
                                    onChange={(e) => setData({ ...data, s_main_work_category: e })}
                                    disabled={disabled}
                                >
                                    <Select.Option value="">-</Select.Option>
                                    <Select.Option value="0">0</Select.Option>
                                    <Select.Option value="1">1</Select.Option>
                                    <Select.Option value="1.5">1.5</Select.Option>
                                    <Select.Option value="2">2</Select.Option>
                                    <Select.Option value="3">3</Select.Option>
                                    <Select.Option value="4">4</Select.Option>
                                    <Select.Option value="5">5</Select.Option>
                                    <Select.Option value="6">6</Select.Option>
                                </Select>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Решение:
                                <TextArea
                                    disabled={disabled}
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_main_accid_mark_cmnt || ''}
                                    onChange={(e) =>
                                        setData({ ...data, s_main_accid_mark_cmnt: e.target.value })
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Маркер аварийности сайта:
                                <Select
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_main_accident_rate}
                                    onChange={(e) => setData({ ...data, s_main_accident_rate: e })}
                                    disabled={disabled}
                                >
                                    <Select.Option value="">-</Select.Option>
                                    <Select.Option value="1">1</Select.Option>
                                    <Select.Option value="2">2</Select.Option>
                                    <Select.Option value="3">3</Select.Option>
                                    <Select.Option value="4">4</Select.Option>
                                </Select>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Комментарий к аварийности сайта:
                                <Input
                                    value={data.s_main_accident_rate_cmnt || ''}
                                    onChange={(e) =>
                                        setData({ ...data, s_main_accident_rate_cmnt: e.target.value })
                                    }
                                    style={{ flex: '0 1 700px' }}
                                    disabled={disabled}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Тип стойки:
                                <Select
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_site_main_cab}
                                    onChange={(e) => setData({ ...data, s_site_main_cab: e })}
                                    disabled={disabled}
                                >
                                    <Select.Option value="">-</Select.Option>
                                    <Select.Option value="Indoor/Индор">Indoor/Индор</Select.Option>
                                    <Select.Option value="Outdoor/Аутдор">Outdoor/Аутдор</Select.Option>
                                </Select>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Расположение:
                                <Select
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_site_main_place}
                                    onChange={(e) => setData({ ...data, s_site_main_place: e })}
                                    disabled={disabled}
                                >
                                    <Select.Option value="">-</Select.Option>
                                    <Select.Option value="Container/Контейнер">
                                        Container/Контейнер
                                    </Select.Option>
                                    <Select.Option value="Equipment room/Выгородка">
                                        Equipment room/Выгородка
                                    </Select.Option>
                                    <Select.Option value="Equipment hall/Аппаратный зал">
                                        Equipment hall/Аппаратный зал
                                    </Select.Option>
                                    <Select.Option value="On Groud/На земле">On Groud/На земле</Select.Option>
                                    <Select.Option value="On rool/На крыше">On rool/На крыше</Select.Option>
                                </Select>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Основной тип сайта:
                                <Radio.Group
                                    disabled={disabled}
                                    style={{ flex: '0 1 700px' }}
                                    onChange={(e) => setData({ ...data, s_site_type: e.target.value })}
                                    value={data.s_site_type}
                                >
                                    <Radio value="(21) - Rooftop/Крышевая" style={{ width: '100%' }}>
                                        (21) - Rooftop/Крышевая
                                    </Radio>
                                    <Radio
                                        value="GFcolocation/Башня, совместно с др операторами"
                                        style={{ width: '100%' }}
                                    >
                                        GFcolocation/Башня, совместно с др операторами
                                    </Radio>
                                    <Radio value="On Ground/На Земле" style={{ width: '100%' }}>
                                        On Ground/На Земле
                                    </Radio>
                                    <Radio value="GFown/Башня, собственная" style={{ width: '100%' }}>
                                        GFown/Башня, собственная
                                    </Radio>
                                    <Radio
                                        value="IBS DAS/Распределенная антенная система в здании"
                                        style={{ width: '100%' }}
                                    >
                                        IBS DAS/Распределенная антенная система в здании
                                    </Radio>
                                    <Radio value="Rooftop+IBS/Крышевая+IBS" style={{ width: '100%' }}>
                                        Rooftop+IBS/Крышевая+IBS
                                    </Radio>
                                </Radio.Group>
                            </div>
                            {data.s_site_type === '(21) - Rooftop/Крышевая' ||
                            data.s_site_type === 'Rooftop+IBS/Крышевая+IBS' ? (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Типы Rooftop:
                                        <Radio.Group
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            onChange={(e) =>
                                                setData({ ...data, s_site_type_det: e.target.value })
                                            }
                                            value={data.s_site_type_det}
                                        >
                                            <Radio
                                                value="Private_Residential/Частное_жилое"
                                                style={{ width: '100%' }}
                                            >
                                                Private_Residential/Частное_жилое
                                            </Radio>
                                            <Radio
                                                value="Public_Residential/Общественное_жилое"
                                                style={{ width: '100%' }}
                                            >
                                                Public_Residential/Общественное_жилое
                                            </Radio>
                                            <Radio value="Industrial/Промышленное" style={{ width: '100%' }}>
                                                Industrial/Промышленное
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Roof/Крыша
                                        <Select
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_rt_roof_type}
                                            onChange={(e) => setData({ ...data, s_rt_roof_type: e })}
                                        >
                                            <Select.Option value="">-</Select.Option>
                                            <Select.Option value="Бетонная конструкция">
                                                Бетонная конструкция
                                            </Select.Option>
                                            <Select.Option value="Стальная конструкция">
                                                Стальная конструкция
                                            </Select.Option>
                                            <Select.Option value="Нет каркаса">Нет каркаса</Select.Option>
                                            <Select.Option value="Контейнер">Контейнер</Select.Option>
                                        </Select>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Wall/Стены
                                        <Select
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_rt_wall_type}
                                            onChange={(e) => setData({ ...data, s_rt_wall_type: e })}
                                        >
                                            <Select.Option value="">-</Select.Option>
                                            <Select.Option value="Кирпич">Кирпич</Select.Option>
                                            <Select.Option value="Блоки">Блоки</Select.Option>
                                            <Select.Option value="Бетонные плиты">
                                                Бетонные плиты
                                            </Select.Option>
                                            <Select.Option value="Другое">Другое</Select.Option>
                                        </Select>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div>Structure / Структура</div>
                                        <Select
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_rt_struct_type}
                                            onChange={(e) => setData({ ...data, s_rt_struct_type: e })}
                                        >
                                            <Select.Option value="">-</Select.Option>
                                            <Select.Option value="Плоская бетонная">
                                                Плоская бетонная
                                            </Select.Option>
                                            <Select.Option value="Наклонная бетонная">
                                                Наклонная бетонная
                                            </Select.Option>
                                            <Select.Option value="Легкая плоская по фермам">
                                                Легкая плоская по фермам
                                            </Select.Option>
                                            <Select.Option value="Легкая наклонная по фермам">
                                                Легкая наклонная по фермам
                                            </Select.Option>
                                            <Select.Option value="Легкая наклонная по фермам">
                                                Другое
                                            </Select.Option>
                                        </Select>
                                    </div>
                                </>
                            ) : data.s_site_type === 'GFcolocation/Башня, совместно с др операторами' ? (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        GFcolocation Types / Типы башен совместной установки
                                        <Radio.Group
                                            disabled={disabled}
                                            style={{
                                                flex: '0 1 700px'
                                            }}
                                            onChange={(e) =>
                                                setData({ ...data, s_site_type_det: e.target.value })
                                            }
                                            value={data.s_site_type_det}
                                        >
                                            <Radio
                                                style={{ width: '100%' }}
                                                value="(31) - KT, KTR, LCC, other operators/КТ, КТР, LCC, др операторы"
                                            >
                                                (31) - KT, KTR, LCC, other operators/КТ, КТР, LCC, др
                                                операторы
                                            </Radio>
                                            <Radio
                                                value="(32) - other owner/другие собственники"
                                                style={{ width: '100%' }}
                                            >
                                                (32) - other owner/другие собственники
                                            </Radio>
                                            <Radio
                                                value="(51) - Dual purpose mast 18m/ОДН18м"
                                                style={{ width: '100%' }}
                                            >
                                                (51) - Dual purpose mast 18m/ОДН18м
                                            </Radio>
                                            <Radio
                                                value="(52) - Dual purpose mast 21m/ОДН21м"
                                                style={{ width: '100%' }}
                                            >
                                                (52) - Dual purpose mast 21m/ОДН21м
                                            </Radio>
                                            <Radio
                                                value="(53) - Dual purpose mast 25m/ОДН25м"
                                                style={{ width: '100%' }}
                                            >
                                                (53) - Dual purpose mast 25m/ОДН25м
                                            </Radio>
                                            <Radio
                                                value="(54) - Dual purpose mast 30m/ОДН30м"
                                                style={{ width: '100%' }}
                                            >
                                                (54) - Dual purpose mast 30m/ОДН30м
                                            </Radio>
                                            <Radio
                                                value="(12) - monopole 20m/монополь 20м"
                                                style={{ width: '100%' }}
                                            >
                                                (12) - monopole 20m/монополь 20м
                                            </Radio>
                                            <Radio
                                                value="(13) - monopole 25m/монополь 25м"
                                                style={{ width: '100%' }}
                                            >
                                                (13) - monopole 25m/монополь 25м
                                            </Radio>
                                            <Radio
                                                value="(14) - monopole 30m/монополь 30м"
                                                style={{ width: '100%' }}
                                            >
                                                (14) - monopole 30m/монополь 30м
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Tower Site Hight / Высота объекта / башни:*
                                        <Input
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_gt_site_height || ''}
                                            onChange={(e) =>
                                                setData({ ...data, s_gt_site_height: e.target.value })
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Tower Structure / Конструкция башни:*
                                        <Select
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_gt_struct_type}
                                            onChange={(e) => setData({ ...data, s_gt_struct_type: e })}
                                        >
                                            <Select.Option value="steel pipe leg x 3/3 опоры из сталь труб">
                                                steel pipe leg x 3/3 опоры из сталь труб
                                            </Select.Option>
                                            <Select.Option value="steel pipe leg x 4/4 опоры из сталь труб">
                                                steel pipe leg x 4/4 опоры из сталь труб
                                            </Select.Option>
                                            <Select.Option value="steel profile leg x 3/3 опоры из сталь профиля">
                                                steel profile leg x 3/3 опоры из сталь профиля
                                            </Select.Option>
                                            <Select.Option value="steel profile leg x4/4 опоры из сталь профиля">
                                                steel profile leg x4/4 опоры из сталь профиля
                                            </Select.Option>
                                            <Select.Option value="other/другое">other/другое</Select.Option>
                                        </Select>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Tower Equipment Placement / Размещение оборудования на башне:*
                                        <Select
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_gt_equip_place}
                                            onChange={(e) => setData({ ...data, s_gt_equip_place: e })}
                                        >
                                            <Select.Option value="Площадка">Площадка</Select.Option>
                                            <Select.Option value="Опоры">Опоры</Select.Option>
                                            <Select.Option value="Другое-и опоры, и площадка">
                                                Другое-и опоры, и площадка
                                            </Select.Option>
                                        </Select>
                                    </div>
                                </>
                            ) : data.s_site_type === 'On Ground/На Земле' ? (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        MOC Types / Типы MOC
                                        <Radio.Group
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            onChange={(e) =>
                                                setData({ ...data, s_site_type_det: e.target.value })
                                            }
                                            value={data.s_site_type_det || ''}
                                        >
                                            <Radio
                                                value="(41) - container pole 0-5m/трубост. на конт. 0-5м"
                                                style={{ width: '100%' }}
                                            >
                                                (41) - container pole 0-5m/трубост. на конт. 0-5м
                                            </Radio>
                                            <Radio
                                                value="(42) - container pole > 5m/трубост. на конт. > 5м"
                                                style={{ width: '100%' }}
                                            >
                                                (42) - container pole {'>'} 5m/трубост. на конт.{'>'} 5м
                                            </Radio>
                                            <Radio
                                                value="MOC 16m / Мачта на контейнере 16м"
                                                style={{ width: '100%' }}
                                            >
                                                MOC 16m / Мачта на контейнере 16м
                                            </Radio>
                                            <Radio
                                                value="MOC18m / Мачта на контейнере 18м"
                                                style={{ width: '100%' }}
                                            >
                                                MOC18m / Мачта на контейнере 18м
                                            </Radio>
                                            <Radio value="Pole/Трубостойка" style={{ width: '100%' }}>
                                                Pole/Трубостойка
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Tower Site Hight / Высота объекта / башни:*
                                        <Input
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_gt_site_height || ''}
                                            onChange={(e) =>
                                                setData({ ...data, s_gt_site_height: e.target.value })
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Tower Structure / Конструкция башни:*
                                        <Select
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_gt_struct_type}
                                            onChange={(e) => setData({ ...data, s_gt_struct_type: e })}
                                        >
                                            <Select.Option value="steel pipe leg x 3/3 опоры из сталь труб">
                                                steel pipe leg x 3/3 опоры из сталь труб
                                            </Select.Option>
                                            <Select.Option value="steel pipe leg x 4/4 опоры из сталь труб">
                                                steel pipe leg x 4/4 опоры из сталь труб
                                            </Select.Option>
                                            <Select.Option value="steel profile leg x 3/3 опоры из сталь профиля">
                                                steel profile leg x 3/3 опоры из сталь профиля
                                            </Select.Option>
                                            <Select.Option value="steel profile leg x4/4 опоры из сталь профиля">
                                                steel profile leg x4/4 опоры из сталь профиля
                                            </Select.Option>
                                            <Select.Option value="other/другое">other/другое</Select.Option>
                                        </Select>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Tower Equipment Placement / Размещение оборудования на башне:*
                                        <Select
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_gt_equip_place}
                                            onChange={(e) => setData({ ...data, s_gt_equip_place: e })}
                                        >
                                            <Select.Option value="platform/площадка">
                                                platform/площадка
                                            </Select.Option>
                                            <Select.Option value="legs/опоры">legs/опоры</Select.Option>
                                            <Select.Option value="other-both legs and platform/другое-и опоры, и площадка">
                                                other-both legs and platform/другое-и опоры, и площадка
                                            </Select.Option>
                                        </Select>
                                    </div>
                                </>
                            ) : data.s_site_type === 'GFown/Башня, собственная' ? (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        GFown Types / Типы собств башен
                                        <Radio.Group
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            onChange={(e) =>
                                                setData({ ...data, s_site_type_det: e.target.value })
                                            }
                                            value={data.s_site_type_det}
                                        >
                                            <Radio
                                                value="(12) - monopole 20m/монополь 20м"
                                                style={{ width: '100%' }}
                                            >
                                                (12) - monopole 20m/монополь 20м
                                            </Radio>
                                            <Radio
                                                value="(13) - monopole 25m/монополь 25м"
                                                style={{ width: '100%' }}
                                            >
                                                (13) - monopole 25m/монополь 25м
                                            </Radio>
                                            <Radio
                                                value="(14) - monopole 30m/монополь 30м"
                                                style={{ width: '100%' }}
                                            >
                                                (14) - monopole 30m/монополь 30м
                                            </Radio>
                                            <Radio
                                                value="(16) - tower 40m/башня 40м"
                                                style={{ width: '100%' }}
                                            >
                                                (16) - tower 40m/башня 40м
                                            </Radio>
                                            <Radio
                                                value="(17) - tower 60m/башня 60м"
                                                style={{ width: '100%' }}
                                            >
                                                (17) - tower 60m/башня 60м
                                            </Radio>
                                            <Radio
                                                value="(18) - tower 80m/башня 80м"
                                                style={{ width: '100%' }}
                                            >
                                                (18) - tower 80m/башня 80м
                                            </Radio>
                                            <Radio
                                                value="(19) - tower 90m/башня 90м"
                                                style={{ width: '100%' }}
                                            >
                                                (19) - tower 90m/башня 90м
                                            </Radio>
                                            <Radio
                                                value="(70) - Loaded mast 15m/LM пригруженная опора 15м"
                                                style={{ width: '100%' }}
                                            >
                                                (70) - Loaded mast 15m/LM пригруженная опора 15м
                                            </Radio>
                                            <Radio
                                                value="(71) - Loaded mast 18m/LM пригруженная опора 18м"
                                                style={{ width: '100%' }}
                                            >
                                                (71) - Loaded mast 18m/LM пригруженная опора 18м
                                            </Radio>
                                            <Radio
                                                value="(72) - Loaded mast 21m/LM пригруженная опора 21м"
                                                style={{ width: '100%' }}
                                            >
                                                (72) - Loaded mast 21m/LM пригруженная опора 21м
                                            </Radio>
                                            <Radio
                                                value="(73) - Loaded mast 25m/LM пригруженная опора 25м"
                                                style={{ width: '100%' }}
                                            >
                                                (73) - Loaded mast 25m/LM пригруженная опора 25м
                                            </Radio>
                                            <Radio
                                                value="(74) - Loaded mast 30m/LM пригруженная опора 30м"
                                                style={{ width: '100%' }}
                                            >
                                                (74) - Loaded mast 30m/LM пригруженная опора 30м
                                            </Radio>
                                            <Radio
                                                value="Concrete pole 18-25 m/Бетонная опора 18-25м"
                                                style={{ width: '100%' }}
                                            >
                                                Concrete pole 18-25 m/Бетонная опора 18-25м
                                            </Radio>
                                            <Radio
                                                value="Tower HDG 15 m/Башня HDG 15м"
                                                style={{ width: '100%' }}
                                            >
                                                Tower HDG 15 m/Башня HDG 15м
                                            </Radio>
                                            <Radio
                                                value="Tower HDG HDG 18 m/Башня HDG 18м"
                                                style={{ width: '100%' }}
                                            >
                                                Tower HDG HDG 18 m/Башня HDG 18м
                                            </Radio>
                                            <Radio
                                                value="Tower HDG HDG 21 m/Башня HDG 21м"
                                                style={{ width: '100%' }}
                                            >
                                                Tower HDG HDG 21 m/Башня HDG 21м
                                            </Radio>
                                            <Radio
                                                value="Tower HDG HDG 25 m/Башня HDG 25м"
                                                style={{ width: '100%' }}
                                            >
                                                Tower HDG HDG 25 m/Башня HDG 25м
                                            </Radio>
                                            <Radio
                                                value="Tower HDG HDG 30 m/Башня HDG 30м"
                                                style={{ width: '100%' }}
                                            >
                                                Tower HDG HDG 30 m/Башня HDG 30м
                                            </Radio>
                                            <Radio
                                                value="rooftop mast 10 m/мачта на крыше 10м"
                                                style={{ width: '100%' }}
                                            >
                                                rooftop mast 10 m/мачта на крыше 10м
                                            </Radio>
                                            <Radio
                                                value="rooftop mast 12 m/мачта на крыше 12м"
                                                style={{ width: '100%' }}
                                            >
                                                rooftop mast 12 m/мачта на крыше 12м
                                            </Radio>
                                            <Radio
                                                value="rooftop mast 15 m/мачта на крыше 15м"
                                                style={{ width: '100%' }}
                                            >
                                                rooftop mast 15 m/мачта на крыше 15м
                                            </Radio>
                                            <Radio
                                                value="painted mast 15 m/мачта крашеная 15м"
                                                style={{ width: '100%' }}
                                            >
                                                painted mast 15 m/мачта крашеная 15м
                                            </Radio>
                                            <Radio
                                                value="painted mast 18 m/мачта крашеная 18м"
                                                style={{ width: '100%' }}
                                            >
                                                painted mast 18 m/мачта крашеная 18м
                                            </Radio>
                                            <Radio
                                                value="painted mast 21 m/мачта крашеная 21м"
                                                style={{ width: '100%' }}
                                            >
                                                painted mast 21 m/мачта крашеная 21м
                                            </Radio>
                                            <Radio
                                                value="painted mast 25 m/мачта крашеная 25м"
                                                style={{ width: '100%' }}
                                            >
                                                painted mast 25 m/мачта крашеная 25м
                                            </Radio>
                                            <Radio value="other/другое" style={{ width: '100%' }}>
                                                other/другое
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Tower Site Hight / Высота объекта / башни:*
                                        <Input
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_gt_site_height || ''}
                                            onChange={(e) =>
                                                setData({ ...data, s_gt_site_height: e.target.value })
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Tower Structure / Конструкция башни:*
                                        <Select
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_gt_struct_type}
                                            onChange={(e) => setData({ ...data, s_gt_struct_type: e })}
                                        >
                                            <Select.Option value="steel pipe leg x 3/3 опоры из сталь труб">
                                                steel pipe leg x 3/3 опоры из сталь труб
                                            </Select.Option>
                                            <Select.Option value="steel pipe leg x 4/4 опоры из сталь труб">
                                                steel pipe leg x 4/4 опоры из сталь труб
                                            </Select.Option>
                                            <Select.Option value="steel profile leg x 3/3 опоры из сталь профиля">
                                                steel profile leg x 3/3 опоры из сталь профиля
                                            </Select.Option>
                                            <Select.Option value="steel profile leg x4/4 опоры из сталь профиля">
                                                steel profile leg x4/4 опоры из сталь профиля
                                            </Select.Option>
                                            <Select.Option value="other/другое">other/другое</Select.Option>
                                        </Select>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        Tower Equipment Placement / Размещение оборудования на башне:*
                                        <Select
                                            disabled={disabled}
                                            style={{ flex: '0 1 700px' }}
                                            value={data.s_gt_equip_place}
                                            onChange={(e) => setData({ ...data, s_gt_equip_place: e })}
                                        >
                                            <Select.Option value="Площадка">Площадка</Select.Option>
                                            <Select.Option value="Опоры">Опоры</Select.Option>
                                            <Select.Option value="Другое-и опоры, и площадка">
                                                Другое-и опоры, и площадка
                                            </Select.Option>
                                        </Select>
                                    </div>
                                </>
                            ) : null}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div>Комментарии:</div>
                                <div style={{ flex: '0 1 700px' }}>
                                    <Input
                                        disabled={disabled}
                                        value={data.s_comment_main || ''}
                                        placeholder="Comment"
                                        onChange={(e) => setData({ ...data, s_comment_main: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <SiteIDLink>Трубостойка для антенн</SiteIDLink>
                        <div
                            style={{
                                padding: '16px 30px',
                                backgroundColor: '#ffffff',
                                borderRadius: '6px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                3m tripode pole (pcs) / Трипод 3м (кол):
                                <Input
                                    disabled={disabled}
                                    value={data.s_3m_pole || ''}
                                    type="number"
                                    onChange={(e) => setData({ ...data, s_3m_pole: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                4,5m tripode pole (pcs) / Трипод 4,5м (кол):
                                <Input
                                    disabled={disabled}
                                    value={data.s_4m_pole || ''}
                                    type="number"
                                    onChange={(e) => setData({ ...data, s_4m_pole: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                6m tripode pole (pcs) / Типод 6м (кол):
                                <Input
                                    disabled={disabled}
                                    type="number"
                                    value={data.s_6m_pole || ''}
                                    onChange={(e) => setData({ ...data, s_6m_pole: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                6m quadropod (pcs) / Квадропод 6м (кол):
                                <Input
                                    disabled={disabled}
                                    type="number"
                                    value={data.s_6quad_pole || ''}
                                    onChange={(e) => setData({ ...data, s_6quad_pole: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                9m quadropod (pcs) / Кваропод 9м (кол):
                                <Input
                                    disabled={disabled}
                                    type="number"
                                    value={data.s_9quad_pole || ''}
                                    onChange={(e) => setData({ ...data, s_9quad_pole: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                other tripode pole (pcs) / Др триподы (кол):
                                <Input
                                    disabled={disabled}
                                    type="number"
                                    value={data.s_other_pole || ''}
                                    onChange={(e) => setData({ ...data, s_other_pole: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                other tripode pole comment / Др триподы Комментарий:
                                <Input
                                    disabled={disabled}
                                    value={data.s_other_pole_cmnt || ''}
                                    onChange={(e) => setData({ ...data, s_other_pole_cmnt: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                2m offset pole (pcs) / Оффсет 2м (кол):
                                <Input
                                    disabled={disabled}
                                    type="number"
                                    value={data.s_2_offset || ''}
                                    onChange={(e) => setData({ ...data, s_2_offset: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                3m offset pole (pcs) / Оффсет 3м (кол):
                                <Input
                                    disabled={disabled}
                                    type="number"
                                    value={data.s_3_offset || ''}
                                    onChange={(e) => setData({ ...data, s_3_offset: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                16m wall offset pole (pcs) / Опора 16м оффсет к стене (кол):
                                <Input
                                    disabled={disabled}
                                    type="number"
                                    value={data.s_16_offset || ''}
                                    onChange={(e) => setData({ ...data, s_16_offset: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                other offset pole (pcs) / др оффсеты (кол):
                                <Input
                                    disabled={disabled}
                                    type="number"
                                    value={data.s_other_offset || ''}
                                    onChange={(e) => setData({ ...data, s_other_offset: e.target.value })}
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                other offset pole Comment / др оффсеты Коммент:
                                <Input
                                    disabled={disabled}
                                    value={data.s_other_offset_cmnt || ''}
                                    onChange={(e) =>
                                        setData({ ...data, s_other_offset_cmnt: e.target.value })
                                    }
                                    style={{ flex: '0 1 700px' }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Pole basement / Основание трубостойки:*
                                <Select
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_pole_base}
                                    onChange={(e) => setData({ ...data, s_pole_base: e })}
                                    disabled={disabled}
                                >
                                    <Select.Option value="">-</Select.Option>
                                    <Select.Option value="Concrete Pole basement/бетонное основание трубост">
                                        Concrete Pole basement/бетонное основание трубост
                                    </Select.Option>
                                    <Select.Option value="Welded Pole, no basement/трубост на сварке, без основания">
                                        Welded Pole, no basement/трубост на сварке, без основания
                                    </Select.Option>
                                    <Select.Option value="Anchor Pole, no basement/трубост на анкерах, без основания">
                                        Anchor Pole, no basement/трубост на анкерах, без основания
                                    </Select.Option>
                                    <Select.Option value="offset pole to roof / без основания, обжимным креплением">
                                        offset pole to roof / без основания, обжимным креплением
                                    </Select.Option>
                                </Select>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Antivandal cage installation / Установка антивандального ограждения:*
                                <Select
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_antivandal_cage}
                                    onChange={(e) => setData({ ...data, s_antivandal_cage: e })}
                                    disabled={disabled}
                                >
                                    <Select.Option value="">-</Select.Option>
                                    <Select.Option value="YES/да">YES/да</Select.Option>
                                    <Select.Option value="NO/нет">NO/нет</Select.Option>
                                </Select>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Pole condition / Состояние трубостойки:*
                                <Select
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_pole_cond}
                                    onChange={(e) => setData({ ...data, s_pole_cond: e })}
                                    disabled={disabled}
                                >
                                    <Select.Option value="">-</Select.Option>
                                    <Select.Option value="GOOD/хорошо">GOOD/хорошо</Select.Option>
                                    <Select.Option value="BAD: pole not vertical/неуд: трубостойка не вертикальна">
                                        BAD: pole not vertical/неуд: трубостойка не вертикальна
                                    </Select.Option>
                                    <Select.Option value="BAD: pole not straight/неуд: трубостойка искривлена">
                                        BAD: pole not straight/неуд: трубостойка искривлена
                                    </Select.Option>
                                    <Select.Option value="BAD: anchor, welding in bad condition/неуд. анкера, сварка в плохом состоянии">
                                        BAD: anchor, welding in bad condition/неуд: анкера, сварка в плохом
                                        состоянии
                                    </Select.Option>
                                    <Select.Option value="BAD: whole construction bad/неуд: в целом неудачная конструкция">
                                        BAD: whole construction bad/неуд: в целом неудачная конструкция
                                    </Select.Option>
                                </Select>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Risk cathegory for Site / Категория рисков для объекта:*
                                <Select
                                    disabled={disabled}
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_risk_cathegory}
                                    onChange={(e) => setData({ ...data, s_risk_cathegory: e })}
                                >
                                    <Select.Option value="">-</Select.Option>
                                    <Select.Option value="no risk/риски не зафиксированы">
                                        no risk/риски не зафиксированы
                                    </Select.Option>
                                    <Select.Option value="risk: overloaded/риски: перегрузка">
                                        risk: overloaded/риски: перегрузка
                                    </Select.Option>
                                    <Select.Option value="risk: further maintanance not possible/риски: дальнейшее обслуживание и эксплуатация невозможны">
                                        risk: further maintanance not possible/риски: дальнейшее обслуживание
                                        и эксплуатация невозможны
                                    </Select.Option>
                                    <Select.Option value="risk: further equipment installation forbiden/риски: дальнейшая установка оборудования запрещена">
                                        risk: further equipment installation forbiden/риски: дальнейшая
                                        установка оборудования запрещена
                                    </Select.Option>
                                    <Select.Option value="risk: periodical maintanance needed/риски: требуется периодич обслуживание">
                                        risk: periodical maintanance needed/риски: требуется периодич
                                        обслуживание
                                    </Select.Option>
                                    <Select.Option value="risk: some infructructure missing/риски: утрачены элементы конструкции">
                                        risk: some infructructure missing/риски: утрачены элементы конструкции
                                    </Select.Option>
                                </Select>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                Risk Conclusion / Заключение о Рисках:*
                                <Select
                                    style={{ flex: '0 1 700px' }}
                                    value={data.s_risk_conclusion}
                                    onChange={(e) => setData({ ...data, s_risk_conclusion: e })}
                                    disabled={disabled}
                                >
                                    <Select.Option value="">-</Select.Option>
                                    <Select.Option value="reject/отказ">reject/отказ</Select.Option>
                                    <Select.Option value="need site reconstruction/треб реконструкция объекта">
                                        need site reconstruction/треб реконструкция объекта
                                    </Select.Option>
                                    <Select.Option value="need pole reconstruction/треб реконструкция трубостоек">
                                        need pole reconstruction/треб реконструкция трубостоек
                                    </Select.Option>
                                    <Select.Option value="not allowed additional activities/доп ЭАП запрещены">
                                        not allowed additional activities/доп ЭАП запрещены
                                    </Select.Option>
                                    <Select.Option value="risk: some infructructure missing/риски: утрачены элементы конструкции">
                                        risk: some infructructure missing/риски: утрачены элементы конструкции
                                    </Select.Option>
                                </Select>
                            </div>
                        </div>
                        {role?.name === 'Группа проектирования инфраструктуры сети' && (
                            <div
                                style={{
                                    marginTop: '16px',
                                    padding: '16px 30px',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '6px'
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        style={{
                                            background: '#1F2229',
                                            color: 'white',
                                            borderRadius: '6px',
                                            width: '240px'
                                        }}
                                        disabled={disabled}
                                        onClick={() => save()}
                                    >
                                        Сохранить
                                    </Button>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Fade>
        </Box>
    );
};

export default SiteStructureDetails;
