import { SingleDatepicker } from 'chakra-dayzed-datepicker';

export interface DatePickerProps {
    value: Date | undefined;
    onChange: (date: Date) => void;
    name: string;
    id?: string;
    configs?: {};
    disabled?: boolean;
}

const DatePicker = ({ value, onChange, name, id, configs, disabled }: DatePickerProps) => (
    <SingleDatepicker
        id={id}
        date={value}
        name={name}
        onDateChange={onChange}
        disabled={disabled}
        propsConfigs={{
            ...configs,
            inputProps: { borderColor: 'gray.400' },
            dayOfMonthBtnProps: {
                borderColor: 'blue.200',
                selectedBg: 'blue.200',
                _hover: {
                    bg: 'blue.400'
                }
            }
        }}
    />
);

export default DatePicker;
