import {
    Box,
    Button,
    Flex,
    Heading,
    Skeleton,
    Spinner,
    Link as ChakraLink,
    Badge,
    useDisclosure,
    useToast,
    Stack,
    Switch,
    FormControl,
    FormLabel,
    IconButton
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import {
    number as YupNumber,
    object as YupObject,
    string as YupString,
    array as YupArray,
    mixed as YupMixed
} from 'yup';
import useSWR, { mutate } from 'swr';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { Collapse, Table as Tableandt, Upload } from 'antd';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { FiTrash2 } from 'react-icons/fi';
import { UploadChangeParam } from 'antd/es/upload';
import { fetcher } from '../../utils/client';
import { userSelector } from '../../redux/reducers/userSlice';
import LoadingOverlay from '../../components/LoadingOverlay';
import Table from '../../components/Table';
import { ONEC_STEPS, ACT_STATUSES, ACT_STEPS } from '../../constants';
import ActSignModal from './ActSignModal';
import { getActSignInfo } from '../../utils';
import ActDeclineModal from './ActDeclineModal';
import NotificationModal from './NotificationModal';
import SiteConfigurations from './SiteConfigurations';
import MainForm from './MainForm';

const validationSchema = () =>
    YupObject().shape({
        neSites: YupArray()
            .of(
                YupObject().shape({
                    notes: YupString(),
                    count: YupMixed().test(
                        'isLarger',
                        'Выбранное количество превышает допустимое',
                        (value, testContext) => {
                            if (testContext.parent.order_count < value || value < 1) return false;
                            return true;
                        }
                    ),
                    id: YupNumber(),
                    price: YupNumber(),
                    coefficient: YupNumber(),
                    unit: YupString(),
                    workType: YupString(),
                    ext_id: YupString(),
                    onec_id: YupString(),
                    region: YupString()
                })
            )
            .required('Поле обязательное'),
        feSites: YupArray().of(
            YupObject().shape({
                notes: YupString(),
                count: YupMixed().test(
                    'isLarger',
                    'Выбранное количество превышает допустимое',
                    (value, testContext) => {
                        if (testContext.parent.order_count < value || value < 1) return false;
                        return true;
                    }
                ),
                id: YupNumber(),
                price: YupNumber(),
                coefficient: YupNumber(),
                unit: YupString(),
                workType: YupString(),
                ext_id: YupString(),
                onec_id: YupString(),
                region: YupString()
            })
        )
    });

function ActCreate() {
    const [dataSource, setDataSource] = useState([]);
    const [arrayValidate, setArrayValidate] = useState([]);
    const { role, data: userData } = useSelector(userSelector);
    const toast = useToast({ position: 'bottom-right', duration: 3000 });
    const navigate = useNavigate();
    const { orderID, actID } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDeclineOpen, onOpen: onDeclineOpen, onClose: onDeclineClose } = useDisclosure();
    const {
        isOpen: isNotificationOpen,
        onOpen: onNotificationOpen,
        onClose: onNotificationClose
    } = useDisclosure();

    const [isLoading, setIsLoading] = useState(false);
    const ref_ne = useRef<{ totalAmount: number }>({ totalAmount: 0 });
    const ref_fe = useRef<{ totalAmount: number }>({ totalAmount: 0 });
    const ref = useRef({ ref_ne, ref_fe });

    if ((role?.type === 'construct' || role?.type === 'maintenance') && !actID) {
        return <span>Доступ запрещен</span>;
    }

    useHeaderTitle(actID ? `Просмотр акта #${actID}` : 'Создание акта');

    const { data: act } = useSWR(actID ? `/acts/${actID}` : null, fetcher, {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });

    const { data: actStatus } = useSWR(actID ? `/acts/${actID}` : null, fetcher, {
        refreshInterval: 4000
    });

    const { data: orderDetail } = useSWR(
        orderID ? `/orders/${orderID}` : `/orders/${act?.order?.id}`,
        fetcher
    );

    const scrollId = (id: string) => {
        document.querySelector(`#id${id}`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    const onFileDelete = async (
        file: any,
        setFieldValue: (target: string, value: []) => void,
        idx: number,
        files: []
    ) => {
        setIsLoading(true);
        try {
            await axios.post('/esigns/delete', { id: file.id });
        } catch (e) {
            toast({
                id: 'file-delete-error',
                position: 'bottom-right',
                status: 'error',
                duration: 3000,
                description: 'Ошибка при удалении файла'
            });
            return;
        }
        setIsLoading(false);
        toast({
            id: 'file-upload-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Файл успешно удален'
        });
        files.splice(idx, 1);
        setFieldValue('files', files);
    };

    const onFileUpload = async (
        data: UploadChangeParam,
        id: any,
        setFieldValue: (target: string, value: any[]) => void,
        files: []
    ) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('field', 'files');
        // @ts-ignore
        formData.append('file', data.file);
        let result;
        try {
            result = await axios.post('/acts/upload', formData);
        } catch (err: any) {
            toast({
                id: 'file-upload-error',
                position: 'bottom-right',
                status: 'error',
                duration: 3000,
                description: err.response.data.message
            });
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
        toast({
            id: 'file-upload-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Файл успешно загружен'
        });
        setFieldValue('files', [...files, { ...result.data.data }]);
    };

    const onApproveClick = async () => {
        if (
            (role?.type === 'construct' || role?.type === 'maintenance') &&
            actStatus?.status === ACT_STATUSES.CREATED &&
            arrayValidate?.length !== 0
        ) {
            const descriptionValidateForUser: any = arrayValidate?.find(
                (item: any) => item.status === 'failed'
            );
            if (descriptionValidateForUser?.length !== 0 && descriptionValidateForUser) {
                scrollId('_siteID');
                toast({
                    id: 'act-revision-error',
                    position: 'bottom-right',
                    status: 'error',
                    duration: 3000,
                    description: descriptionValidateForUser?.message
                });
                return;
            }
        }
        try {
            await axios.put(`/acts/approve/${actID}`, {});
        } catch (error: any) {
            scrollId(error?.response?.data?.id);
            toast({
                id: 'act-revision-error',
                position: 'bottom-right',
                status: 'error',
                duration: 3000,
                description: error?.response?.data?.message || 'Ошибка при отправке запроса'
            });
            return;
        }
        toast({
            id: 'act-revision-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Акт принят!'
        });
        setIsLoading(true);
        await mutate(`/acts/${actID}`);
        setIsLoading(false);
    };
    const onRejectClick = async () => {
        try {
            await axios.put(`/acts/reject/${actID}`, {});
        } catch (e) {
            toast({
                id: 'act-reject-error',
                position: 'top-right',
                status: 'error',
                duration: 5000,
                description: 'Ошибка при отправке запроса'
            });
            return;
        }
        toast({
            id: 'act-reject-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Акт отклонен'
        });
        setIsLoading(true);
        await mutate(`/acts/${actID}`);
        setIsLoading(false);
    };

    const onHideClick = async () => {
        try {
            await axios.put(`/acts/hide/${actID}`, {});
        } catch (error: any) {
            toast({
                id: 'act-archive-error',
                position: 'bottom-right',
                status: 'error',
                duration: 3000,
                description: error?.response?.data?.message || 'Ошибка при отправке запроса'
            });
            return;
        }
        toast({
            id: 'act-archive-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Акт успешно изменил состояние'
        });
        setIsLoading(true);
        await mutate(`/acts/${actID}`);
        setIsLoading(false);
    };

    const handleSign = () => {
        onOpen();
    };

    const handleDecline = () => {
        onDeclineOpen();
    };

    const handleNotification = () => {
        let descriptionValidateForUser: any = {};
        if (arrayValidate?.length !== 0) {
            descriptionValidateForUser = arrayValidate?.find(
                (item: any) => item.field === 'dateCheckForFines'
            );
        }

        if (!act && descriptionValidateForUser?.status === 'failed') {
            onNotificationOpen();
        }
    };

    const handleResend = async () => {
        setIsLoading(true);
        await axios
            .put(`/acts/resend/${actID}`)
            .then(async () => {
                // eslint-disable-next-line no-use-before-define
                toast({
                    title: 'Акт успешно отправлен в 1C',
                    status: 'success'
                });
                await mutate(`/acts/${actID}`);
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateData = async () => {
        await mutate(`/acts/${actID}`);
    };

    const onCloseMainForm = async () => {
        navigate('/acts');
    };

    let disabled = !(
        !act ||
        ((role?.type === 'construct' || role?.type === 'maintenance') &&
            act.step === ACT_STEPS.CREATED &&
            actStatus?.status !== ACT_STATUSES.REVISION_BY_SUBC &&
            actStatus?.status === ACT_STATUSES.CANCELED)
    );

    const accessDataDeliveryDocument =
        role?.type === 'other' &&
        (!act ||
            actStatus?.status === ACT_STATUSES.REVISION_BY_SUBC ||
            actStatus?.status === ACT_STATUSES.WAITINGS_SIGNS_BY_SUBC);

    if (!disabled) {
        disabled = actStatus?.status === ACT_STATUSES.CANCELED;
    }
    const getControls = (validateForm: any, setTouched: any) => {
        if (
            (role?.type === 'construct' || role?.type === 'maintenance') &&
            actStatus?.status === ACT_STATUSES.WAITINGS_SIGNS_OS &&
            act.step === ACT_STEPS.CREATED
        ) {
            return [
                <Button
                    colorScheme="red"
                    bg="white"
                    variant="outline"
                    border="none"
                    mr={2}
                    onClick={onDeclineOpen}
                >
                    Отклонить акт
                </Button>,
                <Button
                    type="button"
                    colorScheme="green"
                    onClick={() => {
                        validateForm().then((resultErrors: any) => {
                            if (_.isEmpty(resultErrors)) {
                                onApproveClick();
                            } else {
                                setTouched({ ...resultErrors });
                            }
                        });
                    }}
                >
                    Принять и отправить в 1C
                </Button>
            ];
        }
        if (
            (role?.type === 'construct' || role?.type === 'maintenance') &&
            actStatus?.status === ACT_STATUSES.CREATED
        ) {
            return [
                <Button
                    colorScheme="red"
                    bg="white"
                    variant="outline"
                    border="none"
                    mr={2}
                    onClick={onDeclineOpen}
                >
                    Отклонить акт
                </Button>,
                <Button colorScheme="green" onClick={() => onApproveClick()}>
                    Принять и отправить на подписание ГПО
                </Button>
            ];
        }
        if (role?.type === 'other' && actStatus?.status === ACT_STATUSES.REVISION_BY_SUBC) {
            return [
                <Button colorScheme="red" variant="outline" mr={2} onClick={onRejectClick}>
                    Отклонить и закрыть
                </Button>,
                <Button colorScheme="green" onClick={() => onApproveClick()}>
                    Принять
                </Button>
            ];
        }

        if (role?.type === 'other' && actStatus?.status === ACT_STATUSES.WAITINGS_SIGNS_BY_SUBC) {
            return [
                <Button colorScheme="red" variant="outline" mr={2} onClick={onRejectClick}>
                    Отклонить и закрыть
                </Button>,
                // <Button
                //     type="button"
                //     colorScheme="green"
                //     onClick={() => {
                //         validateForm().then((resultErrors: any) => {
                //             if (_.isEmpty(resultErrors)) {
                //                 onApproveClick();
                //             } else {
                //                 setTouched({ ...resultErrors });
                //             }
                //         });
                //     }}
                // >
                //     Принять и подписать
                // </Button>
                <Button type="submit" colorScheme="green">
                    Принять и подписать
                </Button>
            ];
        }

        if (disabled) {
            return [];
        }
        return [
            <Button colorScheme="gray" bgColor="white" mr={2} onClick={() => navigate('/acts')}>
                Назад
            </Button>,
            <Button type="submit" colorScheme="green">
                {role?.type === 'other' ? 'Отправить на согласование' : 'Отправить на приемку ГПО'}
            </Button>
        ];
    };

    const getOrderInfo = () => {
        const cols: any = [];

        cols.push({
            key: cols.length + 1,
            field: 'Заказ',
            value: (
                <ChakraLink
                    color="blue.600"
                    _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                    as={Link}
                    className="order-link"
                    to={`/orders/${orderID || act?.order.id}`}
                    target="_blank"
                >
                    {orderID || act?.order.id}
                </ChakraLink>
            )
        });

        if (act) {
            let statusColumn = <Box>{actStatus.status}</Box>;
            if (actStatus?.onec_step === ONEC_STEPS.FAILED && actStatus?.step === ACT_STEPS.SIGNED) {
                statusColumn = (
                    <Box>
                        {statusColumn}
                        <Flex alignItems="center">
                            <Box fontWeight="bold">Ошибка при отправке в 1C</Box>
                            <Button
                                aria-label="Повторить отравку в 1c"
                                size="xs"
                                colorScheme="red"
                                variant="outline"
                                ml={1}
                                onClick={() => {
                                    handleResend();
                                }}
                            >
                                Повторить
                            </Button>
                            <Button
                                aria-label="Показать ошибки"
                                size="xs"
                                colorScheme="purple"
                                variant="outline"
                                ml={1}
                                onClick={() => {
                                    scrollId('_log');
                                }}
                            >
                                Показать ошибки
                            </Button>
                        </Flex>
                    </Box>
                );
            } else if (
                actStatus?.onec_step === ONEC_STEPS.INPROCESS &&
                actStatus?.step === ACT_STEPS.SIGNED
            ) {
                statusColumn = (
                    <Box>
                        {statusColumn}
                        <Flex alignItems="center">
                            <Box fontWeight="bold">В процессе отправки в 1C</Box>
                            <Button
                                aria-label="Отправить сейчас в 1c"
                                size="xs"
                                colorScheme="red"
                                variant="outline"
                                ml={1}
                                onClick={() => {
                                    handleResend();
                                }}
                            >
                                Отправить сейчас
                            </Button>
                        </Flex>
                    </Box>
                );
            }

            const [currentRole, anotherRole] = getActSignInfo(
                act.step,
                act.signFiles?.esigns,
                role?.name,
                userData
            );

            if (currentRole) {
                statusColumn = (
                    <Box>
                        {statusColumn}
                        <Flex mt={1} mb={2}>
                            <Badge colorScheme={currentRole.color} mr={1} variant="outline">
                                {currentRole.text}
                            </Badge>
                            <Badge colorScheme={anotherRole.color} variant="outline">
                                {anotherRole.text}
                            </Badge>
                        </Flex>
                        {currentRole.showSign && (
                            <Flex>
                                <Button
                                    type="button"
                                    colorScheme="green"
                                    size="xs"
                                    onClick={handleSign}
                                    mr={1}
                                >
                                    Подписать
                                </Button>
                                <Button
                                    type="button"
                                    colorScheme="red"
                                    variant="outline"
                                    size="xs"
                                    onClick={handleDecline}
                                >
                                    Отклонить
                                </Button>
                            </Flex>
                        )}
                    </Box>
                );
            }
            cols.push({
                key: cols.length + 1,
                field: 'Инициатор',
                value: act.owner.full_name
            });
            cols.push({
                key: cols.length + 1,
                field: 'Подрядчик',
                value: act.subc.full_name
            });
            cols.push({
                key: cols.length + 1,
                field: 'Дата отправки',
                value: new Date(act.created_at).toLocaleString('ru-Ru')
            });
            cols.push({
                key: cols.length + 1,
                field: 'Статус',
                value: statusColumn
            });
            if (
                (role?.type === 'construct' || role?.type === 'maintenance') &&
                (actStatus?.status === ACT_STATUSES.CANCELED || actStatus?.status === ACT_STATUSES.CREATED)
            ) {
                cols.push({
                    key: cols.length + 1,
                    field: 'Архив',
                    value: (
                        <FormControl display="flex" alignItems="center">
                            <Switch isChecked={act?.hide} onChange={onHideClick} />
                            <FormLabel
                                htmlFor="archive-act"
                                marginBottom="0px"
                                marginInlineStart="0.75rem"
                                fontWeight="normal"
                                fontSize="sm"
                                color="gray.700"
                            >
                                {actStatus?.hide ? 'Вернуть из архива' : 'В архив'}
                            </FormLabel>
                        </FormControl>
                    )
                });
            }
            if (actStatus?.status === ACT_STATUSES.SIGNED) {
                cols.push({
                    key: cols.length + 1,
                    field: 'Сумма оплаты',
                    value: act.payment_amount
                });
                cols.push({
                    key: cols.length + 1,
                    field: 'Дата оплаты',
                    value: act?.payment_date ? (
                        <span>{moment(act?.payment_date).format('DD.MM.YYYY')}</span>
                    ) : (
                        ''
                    )
                });
                cols.push({
                    key: cols.length + 1,
                    field: 'ЭСФ',
                    value: act?.have_esf ? (
                        <span>{`Регистрационный номер: ${act?.esf_id}`}</span>
                    ) : (
                        <span>Не найдена</span>
                    )
                });
            }

            if (act.signFiles?.esigns[0]) {
                const { doc_link, file_name, signed_doc } = act.signFiles.esigns[0];
                cols.push({
                    key: cols.length + 1,
                    field: 'Документ',
                    value: (
                        <ChakraLink
                            color="blue.600"
                            _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                            isExternal
                            href={`${process.env.REACT_APP_API_BASE_URL}${signed_doc || doc_link}`}
                        >
                            {file_name}
                        </ChakraLink>
                    )
                });
            }
        }
        if (act?.reject_comments) {
            cols.push({
                key: cols.length + 1,
                field: `Комментарий`,
                value: (
                    <Box>
                        <Box fontWeight="bold">{act.rejected_person}:</Box>
                        <Box fontStyle="italic">{act.reject_comments}</Box>
                    </Box>
                )
            });
        }

        if (actStatus?.status_change_logs_from_onec)
            cols.push({
                key: cols.length + 1,
                field: <Box mr={5}>История изменения статусов (1C)</Box>,
                value: (
                    <Box
                        fontWeight="bold"
                        lineHeight="normal"
                        dangerouslySetInnerHTML={{ __html: actStatus.status_change_logs_from_onec }}
                    />
                )
            });
        setDataSource(cols);
    };

    const actHeader = [
        {
            title: 'Поле',
            dataIndex: 'field',
            key: 'field',
            width: 100
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            width: 600
        }
    ];

    const actHeaderOneC = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text: string) => <span>{new Date(text).toLocaleString('ru-Ru')}</span>
        },
        {
            title: 'Статус',
            dataIndex: 'Статус',
            key: 'Статус'
        },
        {
            title: 'Описание',
            dataIndex: 'descriptionForUser',
            key: 'descriptionForUser',
            render: (text: string) => <span dangerouslySetInnerHTML={{ __html: text }} />
        }
    ];

    const tableOneCStatuses = () => (
        <div id="id_log">
            {actStatus?.last_onec_statuses ? (
                actStatus?.last_onec_statuses?.length !== 0 ? (
                    <Box shadow="md" borderWidth="1px" p={3} bg="white" rounded="md">
                        <Collapse defaultActiveKey={['1']}>
                            <Collapse.Panel header="Логирование 1С" key="1">
                                <Tableandt
                                    tableLayout="fixed"
                                    loading={isLoading}
                                    pagination={false}
                                    scroll={{ x: true }}
                                    bordered={false}
                                    dataSource={actStatus?.last_onec_statuses}
                                    columns={actHeaderOneC}
                                    showHeader={false}
                                />
                            </Collapse.Panel>
                        </Collapse>
                    </Box>
                ) : null
            ) : null}
        </div>
    );

    useEffect(() => {
        getOrderInfo();
        handleNotification();
    }, [act, arrayValidate]);

    const validateAct = useCallback((actId, orderID) => {
        axios
            .post('/acts/validateAct', { act: actId, order: orderID })
            .then((res) => {
                if (res.data.length) {
                    setArrayValidate(res.data);
                }
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        validateAct(actID, orderID);
    }, [actID, orderID]);

    return (
        <LoadingOverlay Spinner={<Spinner color="blue.500" />} active={isLoading}>
            <Box>
                <Box shadow="md" borderWidth="1px" p={3} bg="white" rounded="md">
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Heading as="h1" fontSize="md">
                            Детали
                        </Heading>
                    </Stack>
                    <Box my={3} color="#6E6B7B" fontSize="sm">
                        <Tableandt
                            loading={isLoading}
                            pagination={false}
                            scroll={{ x: true }}
                            bordered={false}
                            dataSource={dataSource}
                            columns={actHeader}
                            showHeader={false}
                        />
                    </Box>
                </Box>
                {(actID && act === undefined) || (orderID && orderDetail === undefined) ? (
                    <Skeleton w="100%" h={2} colorScheme="teal" mt={1} />
                ) : (
                    <Formik
                        initialValues={{
                            actID: act?.id || null,
                            hard_copy_id: act?.hard_copy_id || '',
                            hard_copy_id_auto:
                                (accessDataDeliveryDocument && !!orderDetail
                                    ? `${orderDetail?.hard_copy_id}_${
                                          orderDetail?.acts?.length > 0 ? orderDetail?.acts?.length + 1 : 0
                                      }`
                                    : '') || '',
                            comments: act?.comments || '',
                            act_date: act?.act_date
                                ? new Date(act.act_date)
                                : accessDataDeliveryDocument
                                ? new Date()
                                : null,
                            files: act?.files?.esigns || [],
                            neSites:
                                (act?.act_work_configs || orderDetail?.order_work_configs)
                                    .filter(({ is_fe_site }: any) => !is_fe_site)
                                    .map((item: any) => ({
                                        notes: item.notes,
                                        count: item.count,
                                        order_count: item?.act_left_count || item?.count,
                                        ext_id: item.ext_id,
                                        workType: item.name,
                                        unit: item.unit,
                                        coefficient: item.coefficient,
                                        price: item.price,
                                        onec_id: item.onec_id,
                                        region: item.region,
                                        type: item.type,
                                        id: item.id
                                    })) || [],
                            feSites:
                                (act?.act_work_configs || orderDetail?.order_work_configs)
                                    .filter(({ is_fe_site }: any) => is_fe_site)
                                    .map((item: any) => ({
                                        notes: item.notes,
                                        count: item.count,
                                        order_count: item?.act_left_count || item?.count,
                                        ext_id: item.ext_id,
                                        workType: item.name,
                                        unit: item.unit,
                                        coefficient: item.coefficient,
                                        price: item.price,
                                        onec_id: item.onec_id,
                                        region: item.region,
                                        type: item.type,
                                        id: item.id
                                    })) || []
                        }}
                        validate={(values) => {
                            const errors: any = {};
                            if (
                                role?.type === 'other' &&
                                (actStatus?.status === ACT_STATUSES.WAITINGS_SIGNS_OS ||
                                    actStatus?.status === ACT_STATUSES.WAITINGS_SIGNS_BY_SUBC)
                            ) {
                                if (!values?.act_date) {
                                    errors.act_date = 'Поле обязательное';
                                }
                                if (!values?.hard_copy_id) {
                                    errors.act_date = 'Поле обязательное';
                                }
                            }
                            if (values?.neSites.length === 0) {
                                errors.neSites = 'Заказ должен содержать минимум 1 ЭАП (ТЦП)';
                            }
                            for (let i = 0; i < values?.neSites.length; i += 1) {
                                if (Number(values.neSites[i].count) > Number(values.neSites[i].order_count)) {
                                    errors.neSites = 'Выбранное количество превышает допустимое';
                                } else if (Number(values.neSites[i].count) === 0) {
                                    errors.neSites = 'Укажите количество';
                                }
                            }
                            console.log({ errors });
                            return errors;
                        }}
                        enableReinitialize
                        onSubmit={async (values) => {
                            if (
                                (role?.type === 'construct' || role?.type === 'maintenance') &&
                                actStatus?.status === ACT_STATUSES.CREATED &&
                                arrayValidate?.length !== 0
                            ) {
                                const descriptionValidateForUser: any = arrayValidate?.find(
                                    (item: any) => item.status === 'failed'
                                );
                                if (descriptionValidateForUser?.length !== 0 && descriptionValidateForUser) {
                                    scrollId('_siteID');
                                    toast({
                                        id: 'act-revision-error',
                                        position: 'bottom-right',
                                        status: 'error',
                                        duration: 3000,
                                        description: descriptionValidateForUser?.message
                                    });
                                    return;
                                }
                            }
                            setIsLoading(true);
                            let result;
                            try {
                                result = await axios[act ? 'put' : 'post'](`/acts${act ? `/${actID}` : ''}`, {
                                    hard_copy_id: values.hard_copy_id,
                                    comments: values?.comments || '',
                                    act_date: values.act_date,
                                    order: orderID,
                                    data: [
                                        ...values.neSites.map((site: any) => ({
                                            notes: site.notes,
                                            count: site.count,
                                            ext_id: site.ext_id,
                                            name: site.workType,
                                            unit: site.unit,
                                            coefficient: site.coefficient,
                                            price: site.price,
                                            onec_id: site.onec_id,
                                            region: site.region,
                                            type: site.type,
                                            id: site.id
                                        })),
                                        ...values.feSites.map((site: any) => ({
                                            notes: site.notes,
                                            count: site.count,
                                            ext_id: site.ext_id,
                                            name: site.workType,
                                            unit: site.unit,
                                            coefficient: site.coefficient,
                                            price: site.price,
                                            onec_id: site.onec_id,
                                            region: site.region,
                                            type: site.type,
                                            is_fe_site: true,
                                            is: site.id
                                        }))
                                    ]
                                });
                            } catch (err: any) {
                                console.error(err.response?.data?.message || 'Ошибка при сохранении акта');
                                toast({
                                    id: 'act-create-error',
                                    position: 'bottom-right',
                                    status: 'error',
                                    duration: 3000,
                                    description: err.response?.data?.message || 'Ошибка при сохранении акта'
                                });
                                setIsLoading(false);
                                return;
                            }
                            if (act) {
                                await mutate(`/acts/${actID}`);
                                setIsLoading(false);
                                if (
                                    role?.type === 'other' &&
                                    actStatus?.status === ACT_STATUSES.WAITINGS_SIGNS_BY_SUBC
                                ) {
                                    handleSign();
                                }
                                return;
                            }
                            console.log({ result });
                            navigate(`/acts/${result.data.data.id}`);
                            setIsLoading(false);
                            toast({
                                id: 'act-create-success',
                                position: 'bottom-right',
                                status: 'success',
                                duration: 3000,
                                description: 'Акт отправлен на согласование'
                            });
                        }}
                        validationSchema={validationSchema}
                    >
                        {({ values, handleSubmit, setFieldValue, validateForm, setTouched }) => (
                            <Form onSubmit={handleSubmit}>
                                <div id="id1">
                                    <MainForm
                                        disabled={disabled}
                                        accessDataDeliveryDocument={accessDataDeliveryDocument}
                                    />
                                </div>
                                <SiteConfigurations
                                    ref={ref}
                                    neSiteConfigs={orderDetail?.work.site_id || act?.order?.work.site_id}
                                    feSiteConfigs={
                                        orderDetail?.work.fe_site_id || act?.order?.work.fe_site_id
                                    }
                                    disabled={disabled}
                                    orderID={orderID || act?.order?.id}
                                />
                                {act?.order_approvers?.length > 0 && (
                                    <Box shadow="md" borderWidth="1px" p={3} bg="white" mt={2} rounded="md">
                                        <Heading as="h1" fontSize="md" mb={2}>
                                            История согласований
                                        </Heading>
                                        <Table
                                            headers={[
                                                'Этап',
                                                'Пользователь',
                                                'Период',
                                                'Статус',
                                                'Комментарий',
                                                'Номер Согласования'
                                            ]}
                                            data={act.order_approvers.map(
                                                ({
                                                    stage,
                                                    user,
                                                    period,
                                                    status,
                                                    comment,
                                                    approvalNumber
                                                }: any) => ({
                                                    stage,
                                                    user,
                                                    period,
                                                    status,
                                                    comment,
                                                    approvalNumber
                                                })
                                            )}
                                        />
                                    </Box>
                                )}
                                {act && (
                                    <Box shadow="md" borderWidth="1px" my={3} p={3} bg="white" rounded="md">
                                        <Heading as="h1" fontSize="md">
                                            Прикрепленные файлы
                                        </Heading>
                                        {values.files.map((file: any, idx: number) => (
                                            <Flex alignItems="center" mt={2}>
                                                <a
                                                    target="_blank"
                                                    href={`${process.env.REACT_APP_API_BASE_URL}${file.doc_link}`}
                                                    rel="noreferrer"
                                                    style={{
                                                        padding: '3px',
                                                        color: '#008abd'
                                                    }}
                                                >
                                                    {file.file_name} (
                                                    {moment(file.date ? file.date : file.created_at).format(
                                                        'L'
                                                    )}
                                                    )
                                                </a>
                                                {(role?.type === 'construct' ||
                                                    role?.type === 'maintenance') &&
                                                    !disabled && (
                                                        <IconButton
                                                            ml={1}
                                                            aria-label="remove-btn"
                                                            icon={<FiTrash2 />}
                                                            onClick={() =>
                                                                onFileDelete(
                                                                    file,
                                                                    setFieldValue,
                                                                    idx,
                                                                    values.files
                                                                )
                                                            }
                                                        />
                                                    )}
                                            </Flex>
                                        ))}
                                        {values.files.length === 0 ? (
                                            <Box mt={2} color="#6E6B7B">
                                                Нет файлов
                                            </Box>
                                        ) : null}
                                        {(role?.type === 'construct' || role?.type === 'maintenance') &&
                                            act.step === ACT_STEPS.CREATED &&
                                            actStatus?.status !== ACT_STATUSES.REVISION_BY_SUBC && (
                                                <Box mt={2}>
                                                    <Upload
                                                        multiple
                                                        name="file"
                                                        showUploadList={false}
                                                        maxCount={1}
                                                        onChange={(data: any) =>
                                                            onFileUpload(
                                                                data,
                                                                act.id,
                                                                setFieldValue,
                                                                values.files
                                                            )
                                                        }
                                                        beforeUpload={() => false}
                                                    >
                                                        <Button
                                                            aria-label="upload-btn"
                                                            size="sm"
                                                            colorScheme="purple"
                                                            variant="outline"
                                                            disabled={
                                                                actStatus?.status === ACT_STATUSES.CANCELED
                                                            }
                                                        >
                                                            Загрузить файл
                                                        </Button>
                                                    </Upload>
                                                </Box>
                                            )}
                                    </Box>
                                )}
                                {act && tableOneCStatuses()}
                                <Flex justifyContent="right" mt={3}>
                                    {getControls(validateForm, setTouched)}
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                )}
                <ActDeclineModal
                    onClose={onDeclineClose}
                    updateData={updateData}
                    isOpen={isDeclineOpen}
                    actId={act?.id}
                />
                <NotificationModal
                    onClose={onNotificationClose}
                    onCloseMainForm={onCloseMainForm}
                    isOpen={isNotificationOpen}
                />
                <ActSignModal
                    onClose={onClose}
                    isOpen={isOpen}
                    groupId={act?.signFiles?.id}
                    updateData={updateData}
                />
            </Box>
        </LoadingOverlay>
    );
}

export default ActCreate;
