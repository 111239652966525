/* eslint-disable no-use-before-define */
import { useRef, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
// @chakra-ui
import {
    IconButton,
    Text,
    Button,
    Stack,
    Box,
    VStack,
    Divider,
    useBreakpointValue,
    useToast,
    Heading
} from '@chakra-ui/react';
import { DownloadIcon, DeleteIcon } from '@chakra-ui/icons';
// form
import { FormikProps } from 'formik';
// hooks
import useToggle from 'hooks/useToggle';
// components
import { FormProvider } from 'components/form';
// services
import { workService } from 'api/services';
//
import { userSelector } from 'redux/reducers/userSlice';
import { useSelector } from 'react-redux';
import WorkUplaodModal from '../../../WorksRollout/components/WorkUploadModal';
// ----------------------------------------------------------------------

interface WorkRltSARFilesProps {
    work: any;
    updateWork: () => void;
}

export default function WorkRltSARFiles({ work, updateWork }: WorkRltSARFilesProps) {
    const user = useSelector(userSelector);
    const [isLoadingSar, setIsLoadingSar] = useState(false);
    const [isLoadingExtra, setIsLoadingExtra] = useState(false);

    const formikRefSar = useRef<FormikProps<any>>(null);
    const formikRefExtra = useRef<FormikProps<any>>(null);

    const { toggle: openSar, onOpen: onOpenSar, onClose: onCloseSar } = useToggle();
    const { toggle: openExtra, onOpen: onOpenExtra, onClose: onCloseExtra } = useToggle();

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const handleDropSar = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];

        if (file) {
            formikRefSar.current!.setFieldValue('fileSar', file);
        }
    };

    const handleDropExtra = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];

        if (file) {
            formikRefExtra.current!.setFieldValue('fileExtra', file);
        }
    };

    const handleRemoveSar = () => {
        formikRefSar.current!.setFieldValue('fileSar', null);
    };

    const handleRemoveExtra = () => {
        formikRefExtra.current!.setFieldValue('fileExtra', null);
    };

    const handleSubmitSar = () => {
        const { fileSar } = formikRefSar.current!.values;

        if (!fileSar) return;

        const formData = new FormData();
        formData.append('work_id', work.id);
        formData.append('field', 'candidateRecieved');
        formData.append('file', fileSar);

        setIsLoadingSar(true);
        workService
            .upload(formData)
            .then(() => {
                setIsLoadingSar(false);
                onCloseSar();
                updateWork();
            })
            .catch((err) => {
                setIsLoadingSar(false);
                toast({
                    title: err.response?.data?.message,
                    status: 'error'
                });
                console.error(err.response);
            });
    };

    const handleSubmitExtra = () => {
        const { fileExtra } = formikRefExtra.current!.values;

        if (!fileExtra) return;

        const formData = new FormData();
        formData.append('work_id', work.id);
        formData.append('field', 'candidateAdditional');
        formData.append('file', fileExtra);

        setIsLoadingExtra(true);
        workService
            .upload(formData)
            .then(() => {
                setIsLoadingExtra(false);
                onCloseExtra();
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response?.data?.message,
                    status: 'error'
                });
                setIsLoadingExtra(false);
                console.error(err);
            });
    };

    const handleRemove = (id: number) => {
        axios
            .post('/esigns/delete', {
                id
            })
            .then(() => {
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: 'Не удалось удалить файл',
                    status: 'error'
                });
                console.error(err);
            });
    };

    const disableUploadCandidate = _.includes([1, 2], work.candidateStatus) || user.role?.type !== 'rnp';

    return (
        <Stack
            spacing={{ base: 1, md: 2.5 }}
            direction={{ base: 'column', md: 'row' }}
            divider={
                <Divider
                    height="auto"
                    orientation={useBreakpointValue({ base: 'horizontal', md: 'vertical' })}
                />
            }
            width="full"
            overflowX="auto"
            p={1.5}
        >
            <Box width="full" minWidth="500px">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Heading fontSize="md" as="h4">
                        Загруженные SAR
                    </Heading>
                    {user.role && user.role.type === 'rnp' ? (
                        <Button
                            size="sm"
                            colorScheme="purple"
                            onClick={onOpenSar}
                            flexShrink={0}
                            isDisabled={disableUploadCandidate}
                        >
                            Загрузить SAR
                        </Button>
                    ) : null}
                </Stack>

                <VStack mt={2} width="full" spacing={0.5}>
                    {work.candidateRecieved?.esigns?.length > 0 ? (
                        work.candidateRecieved.esigns.map(
                            ({ id, file_name, created_at, doc_link, banOnDeletion }: any) => (
                                <FileItem
                                    key={id}
                                    fileName={file_name}
                                    date={created_at}
                                    onDownload={() => {
                                        window.open(`${process.env.REACT_APP_API_BASE_URL}${doc_link}`);
                                    }}
                                    onRemove={() => handleRemove(id)}
                                    disableRemove={banOnDeletion}
                                />
                            )
                        )
                    ) : (
                        <Text>Загруженных файлов не найдено</Text>
                    )}
                </VStack>
                <FormProvider
                    innerRef={formikRefSar}
                    initialValues={{
                        fileSar: null
                    }}
                >
                    <WorkUplaodModal
                        isLoading={isLoadingSar}
                        fieldName="fileSar"
                        header="Загрузка SAR"
                        open={openSar}
                        onClose={onCloseSar}
                        onDrop={handleDropSar}
                        onRemove={handleRemoveSar}
                        onSubmit={handleSubmitSar}
                    />
                </FormProvider>
            </Box>

            <Box width="full" minWidth="500px">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Heading fontSize="md" as="h4">
                        Загруженные доп. файлы
                    </Heading>
                    {user.role && user.role.type === 'rnp' ? (
                        <Button
                            size="sm"
                            colorScheme="purple"
                            onClick={onOpenExtra}
                            flexShrink={0}
                            isDisabled={disableUploadCandidate}
                        >
                            Загрузить доп. файлы
                        </Button>
                    ) : null}
                </Stack>

                <VStack mt={2} width="full" spacing={0.5}>
                    {work.candidateAdditional?.esigns?.length ? (
                        work.candidateAdditional.esigns.map(
                            ({ id, file_name, created_at, doc_link }: any) => (
                                <FileItem
                                    key={id}
                                    fileName={file_name}
                                    date={created_at}
                                    onDownload={() => {
                                        window.open(`${process.env.REACT_APP_API_BASE_URL}${doc_link}`);
                                    }}
                                    onRemove={() => handleRemove(id)}
                                    disableRemove={disableUploadCandidate}
                                />
                            )
                        )
                    ) : (
                        <Text>Загруженных файлов не найдено</Text>
                    )}
                </VStack>

                <FormProvider innerRef={formikRefExtra} initialValues={{ fileExtra: null }}>
                    <WorkUplaodModal
                        isLoading={isLoadingExtra}
                        fieldName="fileExtra"
                        header="Загрузка доп. файлов"
                        open={openExtra}
                        onClose={onCloseExtra}
                        onDrop={handleDropExtra}
                        onRemove={handleRemoveExtra}
                        onSubmit={handleSubmitExtra}
                    />
                </FormProvider>
            </Box>
        </Stack>
    );
}

// ----------------------------------------------------------------------

interface FileItemProps {
    fileName: string;
    date: string;
    disableRemove: boolean;
    onDownload: () => void;
    onRemove: () => void;
}

export const FileItem = ({ fileName, date, onDownload, onRemove, disableRemove }: FileItemProps) => (
    <Stack direction="row" spacing={1} alignItems="center" fontSize="sm" width="full">
        <Box flexGrow={1} wordBreak="break-word">
            {fileName}
        </Box>
        <Box flexShrink={0}>{new Date(date).toLocaleString('ru')}</Box>
        <Box flexShrink={0}>
            <IconButton
                variant="outline"
                colorScheme="purple"
                onClick={onDownload}
                size="xs"
                aria-label="Download file"
                icon={<DownloadIcon />}
                mr="5px"
            />
            <IconButton
                variant="outline"
                colorScheme="red"
                onClick={onRemove}
                size="xs"
                aria-label="Remove file"
                icon={<DeleteIcon />}
                isDisabled={disableRemove}
            />
        </Box>
    </Stack>
);
