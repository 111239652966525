import { Box, Link } from '@chakra-ui/react';

/**
 * @component
 */
const AuthInfoDocuments = () => (
    <Box position="absolute" bottom={6}>
        <Link
            color="#647DEE"
            _hover={{
                color: '#4567ff'
            }}
            transition="color .1s ease-in-out"
            href="mailto:kcell-ciptracker-support@tele2.kz"
            isExternal
        >
            Написать в службу поддержки
        </Link>
        {/* <RouterLink to="/terms-of-use">Условия использования</Link> */}
        {/* <RouterLink to="/policy">Политика конфиденциальности</Link> */}
    </Box>
);

export default AuthInfoDocuments;
