// @ts-nocheck

import {
    Badge,
    Box,
    Button,
    Checkbox,
    Flex,
    Heading,
    HStack,
    IconButton,
    Link as ChakraLink,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Skeleton,
    Spinner,
    Stack,
    Text,
    Textarea,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { array as YupArray, number as YupNumber, object as YupObject, string as YupString } from 'yup';
import useSWR, { mutate } from 'swr';
import useSWRImmutable from 'swr/immutable';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useHeaderTitle from 'hooks/useHeaderTitle';
import { Select, Table as Tableandt, Tabs, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import React, { createContext, useEffect, useRef, useState } from 'react';
import TssrUpload from 'Routes/Order/OrderCreate/TSSR/Tssr';
import useDebounce from 'hooks/useDebounce';
import HistoryModal from 'new-components/HistoryModal';
import { fetcher } from '../../../utils/client';
import MainForm from './MainForm';
import { userSelector } from '../../../redux/reducers/userSlice';
import LoadingOverlay from '../../../components/LoadingOverlay';
import SiteConfigurations from './SiteConfigurations';
import { ENGINEERS_GROUP_ROLES, ERoleNames, ORDER_STATUSES } from '../../../constants';
import OrderSignModal from '../components/OrderSignModal';
import OrderDeclineModal from '../components/OrderDeclineModal';
import 'moment/locale/ru';
import OrderProject from '../OrderProject';
import { orderStatusesMap, orderWorkStatusesMap } from '../utils/maps';
import { IOrderDetail } from '../../../interfaces/orders';
import { EOrderStatuses } from '../utils/enums';
import OrderMatList from '../OrderMatList';
import OrderAcceptance from '../OrderAcceptance';
import CommentFormModal from '../../../new-components/CommentFormModal';
import { orderService } from '../../../api/services';
import HasAccess from '../../../guards/HasAccess';
import OrderTsdRsd from '../components/OrderTsdRsd';
import { IUser } from '../../../interfaces/users';
import QrModal from '../../../new-components/QrModal';
import Signings from '../../../new-components/Signings';

export const OrderContext = createContext<IOrderDetail | null>(null);

const validationSchema = () =>
    YupObject().shape({
        allowedExpDays: YupNumber().required('Поле обязательное'),
        contragent: YupString().required('Поле обязательное'),
        price_region: YupString().required('Поле обязательное'),
        neSites: YupArray()
            .of(
                YupObject().shape({
                    notes: YupString(),
                    count: YupNumber()
                        .required('Поле обязательное')
                        .typeError('Должно быть дробное число след.формата: 10.2'),
                    id: YupNumber(),
                    price: YupNumber(),
                    coefficient: YupNumber(),
                    unit: YupString(),
                    workType: YupString(),
                    ext_id: YupString(),
                    region: YupString()
                })
            )
            .required('Поле обязательное'),
        feSites: YupArray().of(
            YupObject().shape({
                notes: YupString(),
                count: YupNumber()
                    .required('Поле обязательное')
                    .typeError('Должно быть дробное число след.формата: 10.2'),
                id: YupNumber(),
                price: YupNumber(),
                coefficient: YupNumber(),
                unit: YupString(),
                workType: YupString(),
                ext_id: YupString(),
                region: YupString()
            })
        )
    });

function OrderCreate() {
    const [isShowSignModal, setIsShowSignModal] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [arrayValidate] = useState([]);
    const formikRef = useRef(null);
    const [docsHistoryIsOpen, setDocsHistoryIsOpen] = useState(false);
    const [sitenameSearchText, setSitenameSearchText] = useState<string>('');
    const debouncedSitenameSearchText = useDebounce(sitenameSearchText, 700);
    const [sitenameOptions, setSitenameOptions] = useState<{ id: number; sitename: string }[]>([]);
    const { role } = useSelector(userSelector);
    const toast = useToast({ position: 'bottom-right', duration: 3000 });
    const [isOrderTr, setIsOrderTr] = useState(false);
    const [connectedSitename, setConnectedSitename] = useState('');
    const [connectedSitenameHasChanged, setConnectedSitenameHasChanged] = useState(false);
    const [edit, setEdit] = useState(false);
    const [comment, setComment] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState<Record<string, string>[]>();
    const [orderWorkTypes, setOrderWorkTypes] = useState<Record<string, string>[]>();
    const [selectedSubc, setSelectedSubc] = useState<Record<string, string>>();
    const [selectedOrderWorkType, setSelectedOrderWorkType] = useState<Record<string, string>>({});
    const [selectedTreatyNum, setSelectedTreatyNum] = useState<{ [key: string]: string }>();
    const navigate = useNavigate();
    const { workID, orderID } = useParams();
    const [declineType, setDeclineType] = useState<String | null>(null);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [lastSelectedSubcId, setLastSelectedSubcId] = useState(null);
    const ref_ne = useRef<{ totalAmount: number }>({ totalAmount: 0 });
    const ref_fe = useRef<{ totalAmount: number }>({ totalAmount: 0 });
    const ref = useRef({ ref_ne, ref_fe });
    if (role?.type === 'other' && !orderID) {
        return <span>Доступ запрещен</span>;
    }

    const handleSearchSitenameOptions = (searchText: string) => {
        if (!searchText) {
            setSitenameOptions([]);
            return;
        }
        axios.get(`/sitesName?sitename=${searchText}`).then((res) => {
            const sites = res.data;
            setSitenameOptions(sites);
        });
    };
    useEffect(() => {
        handleSearchSitenameOptions(debouncedSitenameSearchText);
    }, [debouncedSitenameSearchText]);

    useHeaderTitle(orderID ? `Просмотр заказа #${orderID}` : 'Создание заказа');

    const { data: discounts } = useSWRImmutable('/discounts?_sort=value:ASC', fetcher);

    const { data: order } = useSWR<IOrderDetail>(orderID ? `/orders/${orderID}` : null, fetcher, {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });

    const { data: orderStatus } = useSWR(orderID ? `/orders/${orderID}` : null, fetcher, {
        refreshInterval: 4000
    });
    const subc = order?.subc || selectedSubc;

    const orderWorkType = order?.workType || selectedOrderWorkType;
    const treatyNum = (edit && order?.contragent.treaty_num) || selectedTreatyNum;

    const { data: priceRegions } = useSWRImmutable('/price-regions?_sort=name:ASC&_limit=-1', fetcher);
    const { data: executors } = useSWR<IUser[]>(
        role?.name === ERoleNames.SUBC && '/orders/executors',
        fetcher
    );
    const { data: existingPriceRegions } = useSWRImmutable(
        subc && treatyNum && `/price-regions-unique?contragent_id=${subc?.id}&treaty_num=${treatyNum}`,
        fetcher
    );

    const { data: workDetail } = useSWR(workID ? `/works/${workID}` : null, fetcher);
    const { data: contragents } = useSWR(
        subc?.id !== undefined ? `/contragents?contragent_id=${subc?.id}&_sort=treaty_num:ASC` : null,
        fetcher
    );
    const sitename = order?.work?.sitename || workDetail?.data?.sitename;
    const updateData = async () => {
        await mutate(`/orders/${orderID}`);
    };
    const scrollId = (id: string) => {
        document.querySelector(`#id${id}`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    const onApproveClick = async () => {
        try {
            await axios.put(`/orders/approve/${orderID}`, {});
        } catch (error: any) {
            scrollId(error?.response?.data?.id);
            toast({
                id: 'order-revision-error',
                position: 'bottom-right',
                status: 'error',
                duration: 3000,
                description: error?.response?.data?.message || 'Ошибка при отправке запроса'
            });
            return;
        }

        toast({
            id: 'order-revision-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Заказ успешно согласован'
        });
        setIsLoading(true);
        await mutate(`/orders/${orderID}`);
        setIsLoading(false);
    };

    const handleChangeTrCheckbox = async (event: any) => {
        setConnectedSitenameHasChanged(true);
        setConnectedSitename(null);
        setIsOrderTr(event.target.checked);
        if (!order) return;
        try {
            setIsLoading(true);
            if (!event.target.checked) {
                await orderService.updateOrder(order.id, {
                    field: 'connectedSitename',
                    value: ''
                });
            }
            await orderService.updateOrder(order?.id, {
                field: 'isTr',
                value: event.target.checked
            });
        } catch (err) {
            console.log('err', err);
        }
        setIsLoading(false);
    };
    const onApproveCancel = async () => {
        if (!order?.id) return;
        orderService
            .approveCancel(order.id)
            .then(() => {
                toast({
                    status: 'info',
                    title: 'Заказ отменен'
                });
            })
            .catch(() => {
                toast({
                    status: 'error',
                    title: 'Ошибка отмены заказа'
                });
            });
    };

    const handleDecline = () => {
        setDeclineType('reject');
    };

    const onFileDelete = async (
        file: any,
        setFieldValue: (target: string, value: []) => void,
        idx: number,
        files: []
    ) => {
        setIsLoading(true);
        try {
            await axios.post('/esigns/delete', { id: file.id });
        } catch (e) {
            toast({
                id: 'file-delete-error',
                position: 'bottom-right',
                status: 'error',
                duration: 3000,
                description: 'Ошибка при удалении файла'
            });
            return;
        }
        setIsLoading(false);
        toast({
            id: 'file-upload-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Файл успешно удален'
        });
        files.splice(idx, 1);
        setFieldValue('files', files);
    };

    const onFileUpload = async (
        data: UploadChangeParam,
        id: any,
        setFieldValue: (target: string, value: any[]) => void,
        files: []
    ) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('field', 'files');
        // @ts-ignore
        formData.append('file', data.file);
        let result;
        try {
            result = await axios.post('/orders/upload', formData);
        } catch (err: any) {
            toast({
                id: 'file-upload-error',
                position: 'bottom-right',
                status: 'error',
                duration: 3000,
                description: err.response.data.message
            });
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
        toast({
            id: 'file-upload-success',
            position: 'bottom-right',
            status: 'success',
            duration: 3000,
            description: 'Файл успешно загружен'
        });
        setFieldValue('files', [...files, { ...result.data.data }]);
    };

    const neSiteConfigs = order?.work.site_id || workDetail?.data.site_id;
    const feSiteConfigs =
        order?.work.fe_site_id || (workDetail?.data.fe_site_id !== '---' && workDetail?.data.fe_site_id);
    const workType = order?.work.work_type || workDetail?.data.work_type;
    const region = workDetail?.data.site.price_region || order?.work.site.price_region;

    const order_memos =
        order?.work.order_memo?.esigns ||
        order?.work.work_memos?.reduce((acc: any, work_memo: any) => [...acc, ...work_memo.esigns], []);

    const disabled =
        ((role?.name && !ENGINEERS_GROUP_ROLES.includes(role?.name) && role?.name !== ERoleNames.ADMIN) ||
            (order && order.status !== EOrderStatuses.returnedWithComments)) &&
        !edit;

    const getControls = () => {
        if (role?.name === ERoleNames.SUBC && orderStatus.status === 'subcSigning') {
            return (
                <>
                    <Button colorScheme="red" variant="outline" mr={2} onClick={handleDecline}>
                        Вернуть на корректировку в Кселл
                    </Button>
                </>
            );
        }

        if (
            role?.name === ERoleNames.SUBC &&
            (orderStatus.status === 'waitingApprove' || orderStatus?.status === 'revisionApprove')
        ) {
            return (
                <>
                    <Button colorScheme="red" variant="outline" mr={2} onClick={handleDecline}>
                        Вернуть на корректировку в Кселл
                    </Button>
                    <Button colorScheme="green" onClick={() => onApproveClick()}>
                        Принять
                    </Button>
                </>
            );
        }

        if (role?.name === ERoleNames.SZ && orderStatus.status === 'onConfirmation') {
            return (
                <>
                    <Button colorScheme="red" variant="outline" mr={2} onClick={handleDecline}>
                        Вернуть на корректировку в Кселл
                    </Button>
                    <Button colorScheme="green" onClick={() => onApproveClick()}>
                        Отправить на подписание ГПО
                    </Button>
                </>
            );
        }

        if (role?.name === ERoleNames.SUBC && orderStatus.status === 'waitingCancelApprove') {
            return (
                <>
                    <Button colorScheme="red" variant="outline" mr={2} onClick={handleDecline}>
                        Вернуть на корректировку в Кселл
                    </Button>
                    <Button colorScheme="purple" onClick={() => onApproveCancel()}>
                        Подтвердить отмену заказа
                    </Button>
                </>
            );
        }

        if (disabled) {
            return null;
        }
        return (
            <>
                <Button type="submit" colorScheme="green">
                    Отправить на согласование
                </Button>
            </>
        );
    };

    const onChangeExecutor = async (id) => {
        orderService
            .changeExecutor(order.id, id)
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Субподрядчик успешно изменен'
                });
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: err.response?.data?.message || 'Не удалось изменить субподрядчика'
                });
            });
    };

    const getOrderInfo = async () => {
        const cols: any = [];

        const neSiteConfigsColumn = <Box>{neSiteConfigs}</Box>;

        cols.push({
            key: '1',
            field: 'Сайт ID(NE)',
            value: neSiteConfigsColumn
        });

        cols.push({
            key: cols.length + 1,
            field: 'Sitename',
            value: sitename
        });

        if (!order) {
            cols.push({
                key: cols.length + 1,
                field: 'Тип работ',
                type: 'Select',
                value: ''
            });
            cols.push({
                key: cols.length + 1,
                field: 'Подрядчик',
                type: 'Select',
                value: ''
            });
        }

        cols.push({
            key: cols.length + 1,
            field: 'Тип ЭАП',
            value: workType
        });

        if (order) {
            cols.push({
                key: cols.length + 1,
                field: 'Код заказа',
                value: order.code
            });
            cols.push({
                key: cols.length + 1,
                field: 'Инициатор',
                value: order.owner.full_name
            });
            cols.push({
                key: cols.length + 1,
                field: 'Подрядчик',
                value: order.subc.full_name
            });

            cols.push({
                key: cols.length + 1,
                field: 'Субподрядчик',
                type: role?.name === ERoleNames.SUBC ? 'Select' : '',
                value: order.executor?.full_name || '-'
            });

            cols.push({
                key: cols.length + 1,
                field: 'Тип работ',
                value: orderWorkType.title
            });
            cols.push({
                key: cols.length + 1,
                field: 'Дата отправки',
                value: new Date(order.created_at).toLocaleString('ru-Ru')
            });
            cols.push({
                key: cols.length + 1,
                field: 'Статус заказа',
                value: (
                    <Badge colorScheme={orderStatusesMap[order.status]?.color}>
                        {orderStatusesMap[order.status]?.label || order.status}
                    </Badge>
                )
            });
            cols.push({
                key: cols.length + 1,
                field: 'Статус работы',
                value: (
                    <Badge colorScheme={orderWorkStatusesMap[order.workStatus]?.color}>
                        {orderWorkStatusesMap[order.workStatus]?.label || 'Неизвестен'}
                    </Badge>
                )
            });
            if (order.engineer) {
                cols.push({
                    key: cols.length + 1,
                    field: 'Ответственный инженер',
                    value: order.engineer.full_name
                });
            }
            if (order.signedFiles?.esigns?.[0]) {
                const { doc_link, file_name, signed_doc } = order.signedFiles.esigns[0];
                cols.push({
                    key: cols.length + 1,
                    field: 'Документ',
                    value: (
                        <ChakraLink
                            color="blue.600"
                            _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                            isExternal
                            href={`${process.env.REACT_APP_API_BASE_URL}${signed_doc || doc_link}`}
                        >
                            {file_name}
                        </ChakraLink>
                    )
                });
            }
        }
        if (order?.reject_comments) {
            cols.push({
                key: cols.length + 1,
                field: `Комментарий ${order.rejected_person}`,
                value: order.reject_comments
            });
        }

        setDataSource(cols);
    };

    const handleSubcSelect = (subcId: number) => {
        const currentValues = formikRef.current.values;

        if (!order && currentValues.neSites.length > 0) {
            setModalIsOpen(true);
            setLastSelectedSubcId(subcId);
        } else if (dropdownOptions) {
            setSelectedSubc(dropdownOptions.find((option) => option.id === subcId));
        }
    };

    const handleResetFormik = () => {
        formikRef.current.resetForm();
        setModalIsOpen(false);
        setSelectedSubc(dropdownOptions.find((option) => option.id === lastSelectedSubcId));
    };

    const handleOrderWorkType = (data: string) => {
        const [title, type, id] = data.split('+');
        setSelectedOrderWorkType({ title, type, id });
    };

    const handleContragentSelect = (contragent: { [key: string]: string }) => {
        setSelectedTreatyNum(contragent);
    };

    const handleChange = async (data: string) => {
        setConnectedSitename(data);
        setConnectedSitenameHasChanged(true);
        if (order?.id) {
            await orderService.updateOrder(order.id, {
                field: 'connectedSitename',
                value: data
            });
            await orderService.changeOrderConfig(
                order.id,
                order.order_work_configs.map((tcp) => tcp.id),
                {
                    newSitename: data
                }
            );
        }
    };

    const isTrDisabled = () => {
        if (role?.type === 'tr') {
            return false;
        }
        if (role?.type === 'maintenance') {
            return false;
        }
        return true;
    };

    const orderHeader = [
        {
            title: 'Поле',
            dataIndex: 'field',
            key: 'field',
            width: 100
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            width: 600,
            render: (any: any, record: any) => {
                if (record.type === 'Select') {
                    if (record.field === 'Подрядчик') {
                        return (
                            <>
                                <Select
                                    key={record.key}
                                    style={{ minWidth: '230px', maxWidth: 'fit-content' }}
                                    defaultValue={record.value}
                                    onChange={handleSubcSelect}
                                >
                                    {dropdownOptions?.map((option: any) => (
                                        <Select.Option key={option.id ?? option} value={option.id ?? option}>
                                            {option.name ?? option}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </>
                        );
                    }
                    if (record.field === 'Субподрядчик') {
                        return (
                            <Select
                                key={record.key}
                                style={{ minWidth: '230px', maxWidth: 'fit-content' }}
                                defaultValue={record.value}
                                onChange={onChangeExecutor}
                            >
                                <Select.Option key="executor-null" value={null}>
                                    -
                                </Select.Option>
                                {executors?.map((option: any) => (
                                    <Select.Option key={`executor-${option.id}`} value={option.id}>
                                        {option.full_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        );
                    }
                    if (record.field === 'Тип работ') {
                        return (
                            <>
                                <Select
                                    style={{ minWidth: '230px', maxWidth: 'fit-content' }}
                                    defaultValue={record.title}
                                    onChange={handleOrderWorkType}
                                >
                                    {orderWorkTypes?.map((option: any) => (
                                        <Select.Option
                                            key={option.title}
                                            value={`${option.title}+${option.type}+${option.id}`}
                                        >
                                            {option.title ?? option}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </>
                        );
                    }
                }
                if (record.field === 'Sitename') {
                    return (
                        <HStack spacing="32px">
                            <Box>{record.value}</Box>
                            <HStack>
                                <Box width="60px">
                                    <Flex width="60px" direction="column" alignItems="center">
                                        <Checkbox
                                            onChange={handleChangeTrCheckbox}
                                            size="lg"
                                            isChecked={isOrderTr}
                                            isDisabled={isTrDisabled()}
                                        />
                                        <Text fontSize="10px">TR работы</Text>
                                    </Flex>
                                </Box>
                                {isOrderTr && (
                                    <Select
                                        placeholder="Введите для поиска"
                                        style={{ width: '200px' }}
                                        optionFilterProp="children"
                                        showSearch
                                        value={connectedSitename || sitename}
                                        onSearch={(value) => {
                                            setSitenameSearchText(value);
                                        }}
                                        onChange={handleChange}
                                        optionLabelProp="value"
                                        disabled={
                                            role?.name === ERoleNames.EXECUTOR ||
                                            role?.name === ERoleNames.SUBC
                                        }
                                    >
                                        {sitenameOptions.map((opt) => (
                                            <Select.Option value={`${opt.sitename}`} key={opt.id}>
                                                {opt.sitename}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </HStack>
                        </HStack>
                    );
                }
                return record.value;
            }
        }
    ];

    const getDictionaries = async () => {
        try {
            const { data } = await axios.get(`/subcs?isOrganization=true`);
            const { data: orderWorkTypes } = await axios.get('/order-work-types');
            setOrderWorkTypes(orderWorkTypes);
            setDropdownOptions(data);
        } catch (err) {
            toast({
                status: 'error',
                title: 'Ошибка при загрузке подрядчиков'
            });
        }
    };

    useEffect(() => {
        getDictionaries();
    }, [subc]);

    useEffect(() => {
        if (!order) {
            getDictionaries();
        } else {
            setComment(order.comment);
            setConnectedSitename(order.connectedSitename);
            setIsOrderTr(order.isTr);
        }
    }, [order]);

    useEffect(() => {
        getOrderInfo();
    }, [order, neSiteConfigs, feSiteConfigs, workType, region, arrayValidate]);

    const treatyNumHash = contragents
        ?.filter(({ treaty_num }: any) => treaty_num.length)
        .reduce((acc: any, { id, treaty_num }: any) => ({ ...acc, [id]: treaty_num }), {});

    const priceRegionsHash = priceRegions?.reduce(
        (acc: any, { id, name }: any) => ({ ...acc, [id]: name }),
        {}
    );

    const onCancel = (comment: string) => {
        if (!order?.id) return;
        orderService
            .cancel(order.id, comment)
            .then(() => {
                toast({
                    status: 'info',
                    title: 'Отправлено на согласование ГПО'
                });
            })
            .catch(() => {
                toast({
                    status: 'error',
                    title: 'Ошибка отмены заказа'
                });
            })
            .finally(() => {
                setShowCancelModal(false);
            });
    };
    const onEdit = () => {
        setEdit(true);
    };

    return (
        <OrderContext.Provider value={order || null}>
            <Tabs
                defaultActiveKey="1"
                style={{ backgroundColor: '#ffffff', padding: '3px 16px' }}
                className="work-tabs"
            >
                <Tabs.TabPane tab="Заказ" key="1">
                    <LoadingOverlay Spinner={<Spinner color="blue.500" />} active={isLoading}>
                        <Box>
                            <Box shadow="md" borderWidth="1px" p={3} bg="white" rounded="md">
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Heading as="h1" fontSize="md">
                                        Заказ на ЭАП
                                    </Heading>
                                    <Box>
                                        {order?.exportJob && (
                                            <>
                                                {order?.exportJob?.isCompleted ? (
                                                    <ChakraLink
                                                        color="blue.600"
                                                        _hover={{
                                                            color: 'blue.600',
                                                            textDecoration: 'underline'
                                                        }}
                                                        isExternal
                                                        href={`${process.env.REACT_APP_API_BASE_URL}${order?.orderFile?.doc_link}`}
                                                        mr={2}
                                                    >
                                                        {order?.orderFile?.file_name}
                                                    </ChakraLink>
                                                ) : (
                                                    <Box as="span" color="blue.600" mr={2}>
                                                        Создаем шаблон документa
                                                        <Spinner size="xs" ml={1} />
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                        {order?.orderFile && (
                                            <Button
                                                size="xs"
                                                onClick={() => setDocsHistoryIsOpen(true)}
                                                variant="outline"
                                                colorScheme="purple"
                                            >
                                                Документы
                                            </Button>
                                        )}
                                        {docsHistoryIsOpen && (
                                            <HistoryModal
                                                id={orderID}
                                                getHistory={orderService.getOrderDocsHistory}
                                                onClose={() => setDocsHistoryIsOpen(false)}
                                            />
                                        )}
                                    </Box>
                                </Stack>
                                <Box my={3} color="#6E6B7B" fontSize="sm">
                                    <Tableandt
                                        loading={isLoading}
                                        pagination={false}
                                        scroll={{ x: true }}
                                        bordered={false}
                                        dataSource={dataSource}
                                        columns={orderHeader}
                                        showHeader={false}
                                    />
                                    <Text as="div" mb="3px" mt="10px">
                                        Комментарий к заказу
                                    </Text>
                                    <Textarea
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        paddingInlineStart={1.5}
                                        disabled={disabled}
                                        paddingInlineEnd={1.5}
                                        placeholder="Введите комментарий по заказу"
                                    />
                                </Box>
                            </Box>
                            {showCancelModal && (
                                <CommentFormModal
                                    title="Отмена заказа"
                                    placeholder="Введите комментарий для подрядчика"
                                    onClose={() => setShowCancelModal(false)}
                                    onSubmit={onCancel}
                                />
                            )}
                            {(orderID && order === undefined) || (workID && workDetail === undefined) ? (
                                <Skeleton w="100%" h={2} colorScheme="teal" mt={1} />
                            ) : (
                                <Formik
                                    initialValues={{
                                        orderID: order?.id || null,
                                        allowedExpDays: order?.allowedExpDays || '',
                                        startDate: order?.startDate ? new Date(order.startDate) : null,
                                        completeDate: order?.expirationDate
                                            ? new Date(order.expirationDate)
                                            : null,
                                        isUrgentTask: order?.isCritical || false,
                                        discount: order?.discount?.id || '',
                                        discountValue: order?.discount?.value || 0,
                                        contragent: order?.contragent.id || '',
                                        price_region: order?.price_region || '',
                                        exploitationDate: order?.exploitationDate || '',
                                        files: order?.files?.esigns || [],
                                        neSites:
                                            order?.order_work_configs
                                                .filter(({ is_fe_site }: any) => !is_fe_site)
                                                .map((item: any) => ({
                                                    notes: item.notes,
                                                    count: item.count,
                                                    ext_id: item.ext_id,
                                                    workType: item.name,
                                                    unit: item.unit,
                                                    coefficient: item.coefficient,
                                                    price: item.price,
                                                    region: item.region,
                                                    type: item.type,
                                                    id: item.id,
                                                    sitename: item.sitename
                                                })) || [],
                                        feSites:
                                            order?.order_work_configs
                                                .filter(({ is_fe_site }: any) => is_fe_site)
                                                .map((item: any) => ({
                                                    notes: item.notes,
                                                    count: item.count,
                                                    ext_id: item.ext_id,
                                                    workType: item.name,
                                                    unit: item.unit,
                                                    coefficient: item.coefficient,
                                                    price: item.price,
                                                    region: item.region,
                                                    type: item.type,
                                                    id: item.id,
                                                    sitename: item.sitename
                                                })) || []
                                    }}
                                    validate={(values) => {
                                        const errors: any = {};
                                        if (role?.type === 'construct' || role?.type === 'maintenance') {
                                            if (!values?.startDate) {
                                                errors.startDate = 'Поле обязательное';
                                            }
                                            if (!values?.completeDate) {
                                                errors.completeDate = 'Поле обязательное';
                                            }
                                        }

                                        if (values?.completeDate && values?.startDate) {
                                            if (values.completeDate < values.startDate) {
                                                errors.startDate = 'Дата позже окончания ЭАП';
                                                errors.completeDate = 'Дата раньше начала ЭАП';
                                                scrollId('1');
                                            }
                                        }

                                        if (values?.neSites.length === 0) {
                                            errors.neSites = 'Заказ должен содержать минимум 1 ЭАП (ТЦП)';
                                        }
                                        return errors;
                                    }}
                                    enableReinitialize={false}
                                    onSubmit={async (values) => {
                                        if (
                                            (role?.type === 'construct' || role?.type === 'maintenance') &&
                                            orderStatus?.status === ORDER_STATUSES.CREATED &&
                                            arrayValidate?.length !== 0
                                        ) {
                                            const descriptionValidateForUser: any = arrayValidate?.find(
                                                (item: any) => item.status === 'failed'
                                            );
                                            if (
                                                descriptionValidateForUser?.length !== 0 &&
                                                descriptionValidateForUser
                                            ) {
                                                scrollId('_siteID');
                                                toast({
                                                    id: 'order-revision-error',
                                                    position: 'bottom-right',
                                                    status: 'error',
                                                    duration: 3000,
                                                    description: descriptionValidateForUser?.message
                                                });
                                                return;
                                            }
                                        }
                                        setIsLoading(true);
                                        let result;
                                        try {
                                            if (
                                                selectedSubc &&
                                                role.name === ERoleNames.OPM &&
                                                order?.status === EOrderStatuses.waitingSubcAssign
                                            ) {
                                                await orderService.selectSubc(order?.id, selectedSubc.id);
                                            } else if (subc) {
                                                result = await axios[order ? 'put' : 'post'](
                                                    `/orders${order ? `/${orderID}` : ''}`,
                                                    {
                                                        work: order?.work ?? workID,
                                                        site_id: neSiteConfigs,
                                                        fe_site_id: feSiteConfigs || null,
                                                        allowedExpDays: values.allowedExpDays,
                                                        contragent: values.contragent,
                                                        price_region: values.price_region,
                                                        exploitationDate: values.exploitationDate,
                                                        startDate: values.startDate,
                                                        expirationDate: values.completeDate,
                                                        isCritical: values.isUrgentTask,
                                                        discount: values.discount,
                                                        total_amount_ne: ref_ne.current.totalAmount,
                                                        total_amount_fe: ref_fe.current.totalAmount,
                                                        subc: subc.id,
                                                        workType: orderWorkType.id,
                                                        isTr: isOrderTr,
                                                        connectedSitename,
                                                        comment,
                                                        data: [
                                                            ...values.neSites.map((site: any) => ({
                                                                notes: site.notes,
                                                                count: site.count,
                                                                ext_id: site.ext_id,
                                                                name: site.workType,
                                                                unit: site.unit,
                                                                coefficient: site.coefficient,
                                                                price: site.price,
                                                                region: site.region,
                                                                type: site.type,
                                                                sitename: site.sitename
                                                            })),
                                                            ...values.feSites.map((site: any) => ({
                                                                notes: site.notes,
                                                                count: site.count,
                                                                ext_id: site.ext_id,
                                                                name: site.workType,
                                                                unit: site.unit,
                                                                coefficient: site.coefficient,
                                                                price: site.price,
                                                                region: site.region,
                                                                type: site.type,
                                                                is_fe_site: true,
                                                                sitename: site.sitename
                                                            }))
                                                        ]
                                                    }
                                                );
                                            }
                                        } catch (e) {
                                            console.error(e);
                                            toast({
                                                id: 'order-create-error',
                                                position: 'bottom-right',
                                                status: 'error',
                                                duration: 3000,
                                                description: 'Ошибка при сохранении заказа'
                                            });
                                            setIsLoading(false);
                                            setEdit(false);
                                            return;
                                        }
                                        if (order) {
                                            await mutate(`/orders/${orderID}`);
                                            setIsLoading(false);
                                            setEdit(false);
                                            return;
                                        }
                                        if (result) {
                                            navigate(`/orders/${result.data.data.order_id}`);
                                        }

                                        setIsLoading(false);
                                        setEdit(false);
                                        toast({
                                            id: 'order-create-success',
                                            position: 'bottom-right',
                                            status: 'success',
                                            duration: 3000,
                                            description: 'Заказ отправлен на согласование'
                                        });
                                    }}
                                    validationSchema={validationSchema(role?.type as string)}
                                    innerRef={formikRef}
                                >
                                    {({ values, setFieldValue, handleSubmit }) => {
                                        const clearTcp = () => {
                                            setFieldValue('neSites', []);
                                            setFieldValue('feSites', []);
                                        };

                                        return (
                                            <Form onSubmit={handleSubmit}>
                                                {contragents ? (
                                                    <div id="id1">
                                                        <MainForm
                                                            contragents={treatyNumHash}
                                                            onContragentsChange={handleContragentSelect}
                                                            clearTcp={clearTcp}
                                                            priceRegions={priceRegions}
                                                            existingPriceRegions={existingPriceRegions}
                                                            disabled={disabled}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Skeleton w="100%" h={2} colorScheme="teal" />
                                                )}
                                                <Modal
                                                    isOpen={modalIsOpen}
                                                    onClose={() => setModalIsOpen(false)}
                                                    size="xl"
                                                >
                                                    <ModalContent>
                                                        <ModalCloseButton />
                                                        <ModalHeader>Подтвердите действие</ModalHeader>
                                                        <ModalBody>
                                                            Выбранные ТЦП сбросятся, вы точно хотите поменять
                                                            подрядчика?
                                                        </ModalBody>
                                                        <ModalFooter>
                                                            <Stack gap={2} flexDirection="row">
                                                                <Button onClick={() => setModalIsOpen(false)}>
                                                                    Нет
                                                                </Button>
                                                                <Button onClick={handleResetFormik}>
                                                                    Да
                                                                </Button>
                                                            </Stack>
                                                        </ModalFooter>
                                                    </ModalContent>
                                                </Modal>

                                                <SiteConfigurations
                                                    ref={ref}
                                                    neSiteConfigs={neSiteConfigs}
                                                    feSiteConfigs={feSiteConfigs}
                                                    disabled={disabled}
                                                    urgent={values.isUrgentTask}
                                                    priceRegion={
                                                        priceRegionsHash?.[values.price_region] || ''
                                                    }
                                                    treatyNum={treatyNumHash?.[values.contragent] || ''}
                                                    contragent={subc?.id}
                                                    discounts={discounts}
                                                    discount={values.discountValue}
                                                    discountValue={values.discountValue}
                                                    sitename={
                                                        connectedSitename ||
                                                        order?.connectedSitename ||
                                                        sitename ||
                                                        order?.work?.site?.sitename ||
                                                        workDetail?.data?.site?.sitename ||
                                                        ''
                                                    }
                                                    setConnectedSitenameHasChanged={
                                                        setConnectedSitenameHasChanged
                                                    }
                                                    connectedSitenameHasChanged={connectedSitenameHasChanged}
                                                    isNewOrder={!orderID}
                                                />
                                                {order && (
                                                    <Box
                                                        shadow="md"
                                                        borderWidth="1px"
                                                        my={3}
                                                        p={3}
                                                        bg="white"
                                                        rounded="md"
                                                    >
                                                        <Heading as="h2" fontSize="md" mb={2}>
                                                            Файлы
                                                        </Heading>
                                                        {values.files.map((file: any, idx: number) => (
                                                            <Flex alignItems="center" mt={2}>
                                                                <a
                                                                    target="_blank"
                                                                    href={`${process.env.REACT_APP_API_BASE_URL}${file.doc_link}`}
                                                                    rel="noreferrer"
                                                                    style={{
                                                                        padding: '3px',
                                                                        color: '#008abd'
                                                                    }}
                                                                >
                                                                    {file.file_name} (
                                                                    {moment(
                                                                        file.date
                                                                            ? file.date
                                                                            : file.created_at
                                                                    ).format('L')}
                                                                    )
                                                                </a>
                                                                <HasAccess roleNames={ENGINEERS_GROUP_ROLES}>
                                                                    <IconButton
                                                                        ml={1}
                                                                        aria-label="remove-btn"
                                                                        icon={<FiTrash2 />}
                                                                        onClick={() =>
                                                                            onFileDelete(
                                                                                file,
                                                                                setFieldValue,
                                                                                idx,
                                                                                values.files
                                                                            )
                                                                        }
                                                                    />
                                                                </HasAccess>
                                                            </Flex>
                                                        ))}
                                                        {values.files.length === 0 &&
                                                        (!order_memos || order_memos.length === 0) ? (
                                                            <Box mt={2} color="#6E6B7B">
                                                                Нет файлов
                                                            </Box>
                                                        ) : null}
                                                        <HasAccess roleNames={ENGINEERS_GROUP_ROLES}>
                                                            <Box mt={2}>
                                                                <Upload
                                                                    multiple
                                                                    name="file"
                                                                    showUploadList={false}
                                                                    maxCount={1}
                                                                    onChange={(data: any) =>
                                                                        onFileUpload(
                                                                            data,
                                                                            order.id,
                                                                            setFieldValue,
                                                                            values.files
                                                                        )
                                                                    }
                                                                    beforeUpload={() => false}
                                                                >
                                                                    <Button
                                                                        aria-label="upload-btn"
                                                                        size="sm"
                                                                        colorScheme="purple"
                                                                        variant="outline"
                                                                    >
                                                                        Загрузить доп.файл
                                                                    </Button>
                                                                </Upload>
                                                            </Box>
                                                        </HasAccess>
                                                    </Box>
                                                )}
                                                {order && <OrderTsdRsd order={order} />}
                                                {order && order.hasSigning && (
                                                    <Signings
                                                        getSignings={orderService.getSignings}
                                                        id={order.id}
                                                    />
                                                )}

                                                <Flex justifyContent="right" mt={3}>
                                                    {order && (
                                                        <HasAccess roleNames={ENGINEERS_GROUP_ROLES}>
                                                            {!edit && order?.displayConfig.isCanCancel && (
                                                                <Button
                                                                    colorScheme="red"
                                                                    onClick={() => setShowCancelModal(true)}
                                                                    mx={2}
                                                                    variant="outline"
                                                                >
                                                                    Отменить заказ
                                                                </Button>
                                                            )}
                                                            {order?.displayConfig.isCanEdit && disabled && (
                                                                <Button onClick={onEdit}>
                                                                    Редактировать заказ{' '}
                                                                </Button>
                                                            )}
                                                            {edit && (
                                                                <Button mx={1} onClick={() => setEdit(false)}>
                                                                    Отменить редактирование
                                                                </Button>
                                                            )}
                                                        </HasAccess>
                                                    )}
                                                    {getControls()}
                                                    {order?.canSign && (
                                                        <Button
                                                            colorScheme="purple"
                                                            onClick={() => setIsShowSignModal(true)}
                                                        >
                                                            Подписать заказ
                                                        </Button>
                                                    )}
                                                </Flex>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            )}
                            {isShowSignModal && (
                                <QrModal
                                    initService={orderService.generateSignQr}
                                    id={order.id}
                                    onClose={() => setIsShowSignModal(false)}
                                />
                            )}
                            {order && (
                                <OrderDeclineModal
                                    onClose={() => {
                                        setDeclineType(null);
                                    }}
                                    updateData={updateData}
                                    isOpen={Boolean(declineType)}
                                    url={`/orders/${declineType}/${order.id}`}
                                />
                            )}
                        </Box>
                    </LoadingOverlay>
                </Tabs.TabPane>
                {/* TODO after */}
                {order && (
                    <>
                        {order?.displayConfig?.hasProject && (
                            <Tabs.TabPane tab="Проект/эскизная схема" key="4">
                                <OrderProject />
                                <OrderTsdRsd order={order} />
                            </Tabs.TabPane>
                        )}
                        {order.displayConfig?.hasMatList && (
                            <Tabs.TabPane tab="Оборудование" key="5" className="tab-item__orders">
                                <OrderMatList />
                            </Tabs.TabPane>
                        )}
                        {order?.displayConfig?.hasTssr && (
                            <Tabs.TabPane tab="TSSR" key="2">
                                <TssrUpload />
                            </Tabs.TabPane>
                        )}
                        {order.displayConfig?.isStartAcceptance && (
                            <Tabs.TabPane tab="Приёмка работ" key="3">
                                <OrderAcceptance
                                    isOrderFinished={order.workStatus === EOrderStatuses.completed}
                                />
                            </Tabs.TabPane>
                        )}
                    </>
                )}
            </Tabs>
        </OrderContext.Provider>
    );
}

export default OrderCreate;
