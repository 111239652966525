import React, { FC } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import OrderSelectedFile from 'Routes/Order/components/OrderSelectedFile';
import { IOrderProject } from '../OrderProject';

interface IProps {
    project: IOrderProject;
    onEdit: (value: boolean) => void;
    editMode: boolean;
    canEdit: boolean;
}

const OrderProjectFiles: FC<IProps> = ({ project, onEdit, editMode, canEdit }) => (
    <Box display="flex" gap={2} flexDirection="column" marginBottom={2}>
        <Heading as="h3" fontSize="large">
            Прикрепленные файлы:
        </Heading>
        {project?.esign ? (
            <OrderSelectedFile
                canEdit={canEdit}
                editMode={editMode}
                file={project.esign}
                changeEditMode={onEdit}
            />
        ) : (
            <div>Не загружены</div>
        )}
    </Box>
);

export default OrderProjectFiles;
