import { useEffect } from 'react';
// form
import { useFormikContext } from 'formik';

interface FormProviderProps {
    onChangeValues: Function;
}

const FormObserver: React.FC<FormProviderProps> = ({ onChangeValues }: FormProviderProps) => {
    const { values } = useFormikContext();
    useEffect(() => {
        onChangeValues(values);
    }, [values]);
    return null;
};

export default FormObserver;
