import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import BGUGsmPerPeriod from 'components/GSM/BGUGSMPerPeriond';
import CarGsmPerPeriod from 'components/GSM/CarGsmPerPeriod';
import DGUGsmPerPeriod from 'components/GSM/DGUGSMPerPeriod';

const screens = ['ГСМ авто', 'ГСМ БГУ', 'ГСМ ДГУ'];

export default function GasReportsPerPeriod() {
    return (
        <Box>
            <Box display="flex" justifyContent="center" mt="4">
                <Tabs
                    variant="soft-rounded"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    colorScheme="purple"
                >
                    <TabList>
                        {screens.map((item) => (
                            <Tab>{item}</Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <CarGsmPerPeriod />
                        </TabPanel>
                        <TabPanel>
                            <BGUGsmPerPeriod />
                        </TabPanel>
                        <TabPanel>
                            <DGUGsmPerPeriod />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    );
}
