import { Checkbox, useToast } from '@chakra-ui/react';
import React, { FC, useRef, useState } from 'react';
import { IOrderProject } from '../OrderProject';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { ordersProjectsService } from '../../../api/services';

interface IProps {
    project: IOrderProject;
    isDisabled?: boolean;
    refetchProject: () => void;
}
const ChangeRequiredProject: FC<IProps> = ({ project, refetchProject, isDisabled }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const toast = useToast();
    const alertRef = useRef(null);

    const onAccept = () => {
        ordersProjectsService
            .update(project.id, { isEquipmentRequired: !project.isEquipmentRequired })
            .then(() => {
                toast({
                    status: 'success',
                    title: `Успешно обновлено`
                });

                refetchProject();
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не получить обновить'
                });
            });
    };
    return (
        <>
            {showConfirmModal && (
                <ConfirmationDialog
                    title="Подтверждение"
                    onAccept={onAccept}
                    body="Вы уверены, что хотите изменить требование оборудования?"
                    acceptButtonColor="green"
                    isOpen
                    modalRef={alertRef}
                    onClose={() => setShowConfirmModal(false)}
                />
            )}
            <Checkbox
                isDisabled={isDisabled}
                onChange={() => setShowConfirmModal(true)}
                isChecked={!project.isEquipmentRequired}
            >
                Оборудование не требуется
            </Checkbox>
            ;
        </>
    );
};

export default ChangeRequiredProject;
