import { Upload } from 'antd';
import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    Stack,
    useToast
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { matListsService, matListTrsService } from 'api/services';
import { useFormik } from 'formik';
import Label from 'new-components/ui/Label';
import HasAccess from 'guards/HasAccess';
import { ERoleNames } from '../../../../constants';
import TrsTable from './TrsTable';
import { ITr } from '../../utils/interfaces/MatList';
import { TMatListStatuses } from '../../utils/types/MatList';

interface IProps {
    matListId: number;
    onSendWarehouseCallback?: () => void;
    rolesHasDownloadTrs: ERoleNames[];
    rolesHasEditTrsStatus: ERoleNames[];
    matListStatus: TMatListStatuses;
}

const Trs: FC<IProps> = ({
    rolesHasDownloadTrs,
    matListStatus,
    matListId,
    onSendWarehouseCallback,
    rolesHasEditTrsStatus
}) => {
    const toast = useToast();
    const [trs, setTrs] = useState<ITr[]>([]);
    const [filePdf, setFilePdf] = useState<any>();
    const [fileExcel, setFileExcel] = useState<any>();
    const [showTrForm, setShowTrForm] = useState(false);
    const sendToWarehouse = () => {
        matListsService
            .sendToWarehouse(matListId)
            .then(() => {
                if (onSendWarehouseCallback) {
                    onSendWarehouseCallback();
                    toast({
                        status: 'success',
                        title: 'Успешно!',
                        description: `TR отправлены на склад`
                    });
                }
            })
            .catch((e) => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: e.response.data.message || 'Не удалось отправить на склад'
                });
            });
    };
    const getTrs = () => {
        matListTrsService.getList(matListId).then((res) => {
            setTrs(res.data);
        });
    };

    // TODO Разобраться с formik + файловый инпут
    const formik = useFormik({
        initialValues: {
            sapNumber: '',
            warehouseNumber: ''
        },
        onSubmit: async (values) => {
            if (!fileExcel?.file || !filePdf) return;
            const formData = new FormData();
            formData.append('pdf', filePdf?.file);
            formData.append('excel', fileExcel?.file);
            formData.append('matListId', String(matListId));
            formData.append('sapNumber', String(values.sapNumber));
            formData.append('warehouseNumber', String(values.warehouseNumber));
            await matListTrsService
                .upload(formData)
                .then(async () => {
                    toast({
                        status: 'success',
                        title: 'TR успешно загружен'
                    });
                    setShowTrForm(false);
                    setFilePdf(null);
                    setFileExcel(null);
                    formik.resetForm();
                    getTrs();
                })
                .catch((err) => {
                    console.log(err.response.data);
                    const resError = err.response.data.message;
                    toast({
                        status: 'error',
                        title: resError.title || 'Ошибка',
                        description: resError.details || 'Не удалось загрузить TR'
                    });
                });
        }
    });

    useEffect(() => {
        getTrs();
    }, []);

    return (
        <Box my={5}>
            <Heading as="h3" size="sm" mb={1}>
                TR
            </Heading>
            {trs.length > 0 && (
                <TrsTable rolesHasEditTrsStatus={rolesHasEditTrsStatus} trs={trs} matListId={matListId} />
            )}
            <HasAccess roleNames={rolesHasDownloadTrs}>
                {showTrForm && (
                    <Box my={2}>
                        <Heading as="h4" size="sm" mb={1}>
                            Добавление TR
                        </Heading>
                        <form onSubmit={formik.handleSubmit}>
                            <Stack width={300} alignItems="top">
                                <div>
                                    <FormControl>
                                        <Box>
                                            <FormErrorMessage mt="0.5rem">
                                                {formik.errors.sapNumber}
                                            </FormErrorMessage>
                                            <Label text="TR номер" />
                                            <Input
                                                value={formik.values.sapNumber || undefined}
                                                name="sapNumber"
                                                id="sapNumber"
                                                onChange={formik.handleChange}
                                            />
                                            <FormErrorMessage mt="0.5rem">
                                                {formik.errors.sapNumber}
                                            </FormErrorMessage>
                                        </Box>
                                    </FormControl>
                                </div>
                                <Box my={2}>
                                    <Label text="TR-файлы" />
                                    <Stack gap={2}>
                                        <Upload
                                            multiple
                                            accept=".pdf"
                                            name="file"
                                            showUploadList
                                            maxCount={1}
                                            onChange={(file) => setFilePdf(file)}
                                            beforeUpload={() => false}
                                        >
                                            <Button width="100%">Загрузить PDF</Button>
                                        </Upload>
                                        <Upload
                                            multiple
                                            accept=".xls,.xlsx"
                                            name="file"
                                            showUploadList
                                            maxCount={1}
                                            onChange={(file) => setFileExcel(file)}
                                            beforeUpload={() => false}
                                        >
                                            <Button width="100%">Загрузить Excel</Button>
                                        </Upload>
                                    </Stack>
                                </Box>

                                <div>
                                    <FormControl>
                                        <Box>
                                            <Label text="Номер склада" />
                                            <Input
                                                value={formik.values.warehouseNumber || undefined}
                                                name="warehouseNumber"
                                                id="warehouseNumber"
                                                onChange={formik.handleChange}
                                            />
                                            <FormErrorMessage mt="0.5rem">
                                                {formik.errors.warehouseNumber}
                                            </FormErrorMessage>
                                        </Box>
                                    </FormControl>
                                </div>

                                <Button
                                    isDisabled={
                                        !fileExcel ||
                                        !filePdf ||
                                        !formik.values.sapNumber ||
                                        !formik.values.warehouseNumber
                                    }
                                    colorScheme="green"
                                    type="submit"
                                >
                                    Сохранить
                                </Button>
                                <Button type="button" variant="outlined" onClick={() => setShowTrForm(false)}>
                                    Отмена
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                )}
                <ButtonGroup my={2}>
                    {!showTrForm && <Button onClick={() => setShowTrForm(true)}> + Добавить TR</Button>}
                    {trs.length > 0 && !showTrForm && matListStatus === 'waiting_tr' && (
                        <Button colorScheme="purple" onClick={sendToWarehouse} type="button">
                            Отправить на склад
                        </Button>
                    )}
                </ButtonGroup>
            </HasAccess>
        </Box>
    );
};

export default Trs;
