import { TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, Table as CTable, Skeleton } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface TableProps {
    aboveTable?: ReactNode;
    belowTable?: ReactNode;
    headers: (ReactNode | string)[];
    data: { [key: string]: ReactNode | string }[];
}

function Table({ aboveTable = null, belowTable, headers, data }: TableProps) {
    return (
        <TableContainer>
            <CTable variant="simple" border="1px solid #EDF2F7" size="sm">
                <Thead bg="aliceblue">
                    <Tr>
                        {headers.map((header) => (
                            <Th px={3} py="1.5">
                                {header}
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {aboveTable}
                    {data === undefined ? (
                        <Tr>
                            <Td colSpan={headers.length}>
                                <Skeleton w="100%" h={3} colorScheme="teal" />
                            </Td>
                        </Tr>
                    ) : data?.length > 0 ? (
                        data.map((row, idx) => (
                            <Tr key={idx}>
                                {Object.values(row).map((value, rIdx) => (
                                    <Td key={rIdx}>{value}</Td>
                                ))}
                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Td colSpan={headers.length} textAlign="center">
                                Записей нет
                            </Td>
                        </Tr>
                    )}
                </Tbody>
                {belowTable && <Tfoot>{belowTable}</Tfoot>}
            </CTable>
        </TableContainer>
    );
}

export default Table;
