import { Text } from '@chakra-ui/react';
import ComponentWithChildren from 'utils/componentWithChildren';

/**
 * @component
 * @param { ReactNode } children ReactNode
 */
const AuthDescription: ComponentWithChildren = ({ children }) => <Text mb={3}>{children}</Text>;

export default AuthDescription;
