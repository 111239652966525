import axios from 'axios';
import qs from 'qs';
import { IOrderAcceptanceTechActForm } from '../../Routes/Order/utils/interfaces/OrderAcceptance';
import { IOrderWorkType } from '../../interfaces/orders';

const list = ({ params }: any) =>
    axios.get(`orders`, { params, paramsSerializer: (params) => qs.stringify(params) });

const count = ({ params }: any) =>
    axios.get(`orders/count`, { params, paramsSerializer: (params) => qs.stringify(params) });

const details = (orderId: number | string) => axios.get(`/orders/${orderId}`);

const uploadAcceptanceDoc = (id: number, data: FormData) =>
    axios.patch(`/orders/acceptance-docs/${id}`, data);

const selectSubc = (id: number, subc: number) => axios.patch(`/orders/${id}/selectSubc`, { subc });

const getAcceptanceDocs = (id: number) => axios.get(`/orders/${id}/acceptance-docs`);
const getTssrs = (filename?: string) => axios.get(`/orders/tssrs${filename ? `?filename=${filename}` : ''}`);
const approveAcceptanceDoc = (id: number, data: { comment: string }) =>
    axios.patch(`/orders/acceptance-docs/${id}/approve`, data);
const rejectAcceptanceDoc = (id: number, data: { comment: string }) =>
    axios.patch(`/orders/acceptance-docs/${id}/reject`, data);
const getAcceptanceDocHistory = (id: number) => axios.get(`/orders/acceptance-docs/${id}/history`);
const getOrderDocsHistory = (id: number) => axios.get(`/orders/${id}/history/doc`);
const changeAcceptanceDocRequired = (id: number) =>
    axios.patch(`/orders/acceptance-docs/${id}/change-required`);
const addAcceptanceOtherDoc = (orderId: number, data: FormData) =>
    axios.post(`/orders/${orderId}/acceptance-docs`, data);

const deleteAcceptanceDoc = (id: number) => axios.delete(`/orders/acceptance-docs/${id}`);
const editAcceptanceDocTechAct = (id: number, data: IOrderAcceptanceTechActForm) =>
    axios.put(`/orders/acceptance-docs/tech-acts/${id}`, data);

const startTssr = (id: number) =>
    axios.post('/orders/tssr', {
        type: 'order',
        id
    });

const changeOrderConfig = (orderId: number, configIds: number[] | string[], data: {}) =>
    axios.put('/orders/updateConfig', {
        orderId,
        configIds,
        data
    });

const updateOrder = (orderId: number | string, data: {}) =>
    axios.put(`/orders/updateOrder/${orderId}`, {
        data
    });

const changeExecutor = (orderId: number, executorId: number) =>
    axios.patch(`/orders/${orderId}/executor`, {
        executorId
    });

const finish = (id: number) => axios.patch(`orders/${id}/finish`);
const cancel = (id: number, comment: string) => axios.patch(`orders/${id}/cancel`, { comment });
const approveCancel = (id: number) => axios.patch(`orders/${id}/cancel/approve`);

const getWorkTypes = () => axios.get<IOrderWorkType[]>('/order-work-types');
const generateSignQr = (id: number) => axios.get(`orders/${id}/sign/init`);
const getSignings = (id: number) => axios.get(`orders/${id}/signings`);

export default {
    list,
    getSignings,
    approveCancel,
    changeExecutor,
    finish,
    getTssrs,
    getWorkTypes,
    editAcceptanceDocTechAct,
    deleteAcceptanceDoc,
    addAcceptanceOtherDoc,
    changeAcceptanceDocRequired,
    cancel,
    rejectAcceptanceDoc,
    getAcceptanceDocs,
    getAcceptanceDocHistory,
    approveAcceptanceDoc,
    count,
    details,
    generateSignQr,
    startTssr,
    changeOrderConfig,
    uploadAcceptanceDoc,
    updateOrder,
    getOrderDocsHistory,
    selectSubc
};
