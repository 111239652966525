import * as React from 'react';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList
} from '@choc-ui/chakra-autocomplete';
import useSWR from 'swr';
import { useState } from 'react';
import { fetcher } from '../../utils/client';

interface AutoCompleteProps {
    onChange: (data: any) => void;
    orderID: number;
}

function TCPSearch({ onChange, orderID }: AutoCompleteProps) {
    const [search, setSearch] = useState('');
    const { data } = useSWR(
        search && orderID ? `/acts-search?orderID=${orderID}&search=${search}` : null,
        fetcher
    );
    const onChangeHandler = (onec_id: string) => {
        const tcp = data.filter((orderWork: any) => orderWork.onec_id === onec_id);
        onChange(tcp[0]);
        setSearch('');
    };
    return (
        <AutoComplete onChange={onChangeHandler} disableFilter maxSuggestions={15} suggestWhenEmpty={false}>
            <AutoCompleteInput
                variant="filled"
                placeholder="Введите № или наименование вида ЭАП"
                borderColor="gray.100"
                onChange={(event) => setSearch(event.target.value)}
                value={search}
                className="tcp-search"
            />
            <AutoCompleteList zIndex={100}>
                {data?.map((tcp: any) => (
                    <AutoCompleteItem key={tcp.onec_id} value={tcp.onec_id} className="tcp-search-item">
                        {`${tcp.ext_id}.${tcp.name}`}
                    </AutoCompleteItem>
                ))}
            </AutoCompleteList>
        </AutoComplete>
    );
}

export default TCPSearch;
