import { createContext, useEffect, useMemo, useCallback, useRef } from 'react';
// @chakra-ui
import { useToast } from '@chakra-ui/react';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer.types';
// services
import { reviewService } from 'api/services';
// hooks
import useToggle from 'hooks/useToggle';

// ----------------------------------------------------------------------

type RatingContextType = {
    isOpen: boolean;
    onRate: (data: any) => void;
    onClose: () => void;
};

const RatingContext = createContext<RatingContextType | null>(null);

// ----------------------------------------------------------------------

interface RatingProviderProps {
    children: JSX.Element;
}

// in seconds
const TIME = 120;

function RatingProvider({ children }: RatingProviderProps) {
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const { isAuthed } = useSelector((state: RootState) => state.user);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const { toggle, onOpen, onClose } = useToggle();

    useEffect(() => {
        const check = async () => {
            const res = await reviewService.check();

            if (!res.data.allowed) return;

            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                onOpen();
            }, TIME * 1000);
        };

        if (isAuthed) {
            check().catch(console.error);
        }
    }, [isAuthed]);

    const handleRate = useCallback((data) => {
        reviewService
            .rate({ ...data, action: 'rate' })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Благодарим вас за отзыв!'
                });
            })
            .catch(console.error);
        onClose();
    }, []);

    const handleClose = useCallback(() => {
        reviewService.rate({ action: 'close' }).catch(console.error);
        onClose();
    }, []);

    const value = useMemo(
        () => ({ onRate: handleRate, onClose: handleClose, isOpen: toggle }),
        [handleRate, handleClose, toggle]
    );

    return <RatingContext.Provider value={value}>{children}</RatingContext.Provider>;
}

export { RatingProvider, RatingContext, RatingContextType };
