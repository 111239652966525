import { useState } from 'react';
import {
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Text,
    Textarea,
    useToast
} from '@chakra-ui/react';
import axios from 'axios';

const ChangeWorkMainStatus = (props: any) => {
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const { work, updateWork, userRole } = props;
    const [commentsModalOpened, setCommentsModalOpened] = useState(false);
    const [lastSelectedDecision, setLastSelectedDecision] = useState(1);
    const [isDecisionPending, setIsDecisionPending] = useState(false);
    const [lastSelectedFieldName, setLastSelectedFieldName] = useState('');
    return (
        <Box mb={2}>
            <Modal isOpen={commentsModalOpened} onClose={() => setCommentsModalOpened(false)} size="xl">
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>
                        Комментирование {lastSelectedDecision === 1 ? 'принятия' : 'отказа'}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={1}>Комментарий</Text>
                        <Textarea id="commentsTextarea" paddingInlineStart={2} paddingInlineEnd={2} />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="purple" mr="auto" onClick={() => setCommentsModalOpened(false)}>
                            Закрыть
                        </Button>
                        <Button
                            colorScheme={lastSelectedDecision === 1 ? 'green' : 'red'}
                            isLoading={isDecisionPending}
                            loadingText="Обработка..."
                            onClick={async () => {
                                setIsDecisionPending(true);
                                if (
                                    lastSelectedDecision === 0 &&
                                    // @ts-ignore
                                    !document.getElementById('commentsTextarea')?.value
                                ) {
                                    toast({
                                        status: 'error',
                                        title: 'Заполните комментарий при отказе!'
                                    });
                                    setIsDecisionPending(false);
                                } else {
                                    await axios
                                        .post('/works/makeDecision', {
                                            work_id: work.id,
                                            field: 'cancel',
                                            value: lastSelectedDecision,
                                            // @ts-ignore
                                            comment: (
                                                document.getElementById(
                                                    'commentsTextarea'
                                                ) as HTMLInputElement
                                            )?.value
                                        })
                                        .then(() => {
                                            updateWork();
                                            toast({
                                                title: `${lastSelectedFieldName} успешно ${
                                                    lastSelectedDecision === 1 ? 'принято' : 'отклонено'
                                                }`,
                                                status: 'success'
                                            });
                                            setCommentsModalOpened(false);
                                        })
                                        .catch(() => {
                                            toast({
                                                title: 'Ошибка при обновлении',
                                                status: 'error'
                                            });
                                        })
                                        .finally(() => {
                                            setIsDecisionPending(false);
                                        });
                                }
                            }}
                        >
                            {lastSelectedDecision === 1 ? 'Принять' : 'Отклонить'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {!work?.workStatus?.cancelled ? (
                <Button
                    mt={2}
                    colorScheme="red"
                    onClick={() => {
                        setLastSelectedFieldName('cancelled');
                        setLastSelectedDecision(0);
                        setCommentsModalOpened(true);
                    }}
                >
                    Отменить ЭАП
                </Button>
            ) : userRole.type === 'rnp' ? (
                <Button
                    mt={2}
                    colorScheme="blue"
                    onClick={() => {
                        setLastSelectedFieldName('cancelled');
                        setLastSelectedDecision(1);
                        setCommentsModalOpened(true);
                    }}
                >
                    Возобновить ЭАП
                </Button>
            ) : null}
        </Box>
    );
};

export default ChangeWorkMainStatus;
