import { Stack, Input, InputGroup, InputLeftElement, Text, useBreakpointValue } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import useHeaderTitle from '../../../hooks/useHeaderTitle';

interface IEquipmentTableToolbarProps {
    equipmentSapCode: string;
    totalEquipments: string;
    onEquipmentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EquipmentTableToolbar = (props: IEquipmentTableToolbarProps) => {
    const { equipmentSapCode, totalEquipments, onEquipmentChange } = props;
    const alignItems = useBreakpointValue({ base: 'stretch', md: 'center' });
    useHeaderTitle('Справочник оборудования');
    return (
        <Stack
            width="full"
            direction={{ base: 'column', md: 'row' }}
            p={1.5}
            spacing={1}
            justifyContent="space-between"
            alignItems={alignItems}
        >
            <Text fontWeight="bold" whiteSpace="nowrap">
                Всего оборудования ({totalEquipments})
            </Text>

            <InputGroup size="sm" w="xs">
                <InputLeftElement pointerEvents="none">
                    <Search2Icon color="gray.300" />
                </InputLeftElement>
                <Input
                    paddingLeft={4}
                    placeholder="Поиск по SAP Code оборудования"
                    defaultValue={equipmentSapCode || ''}
                    onChange={onEquipmentChange}
                />
            </InputGroup>
        </Stack>
    );
};

export default EquipmentTableToolbar;
