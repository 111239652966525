import {
    Box,
    Flex,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper as ChakraStepper,
    StepStatus,
    StepTitle
} from '@chakra-ui/react';
import { FC } from 'react';

interface IProps {
    steps: { title: string; description?: string }[];
    activeStep: number;
}
const Stepper: FC<IProps> = ({ steps, activeStep }) => (
    <ChakraStepper colorScheme="purple" size="sm" gap={0} index={activeStep}>
        {steps.map((step) => (
            <Flex alignItems="center" gap={1}>
                <StepIndicator>
                    <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                </StepIndicator>
                <Box>
                    <StepTitle>{step.title}</StepTitle>
                    {step.description && <StepDescription>{step.description}</StepDescription>}
                </Box>
            </Flex>
        ))}
    </ChakraStepper>
);

export default Stepper;
