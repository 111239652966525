import { useEffect } from 'react';
// @ts-ignore
import * as Tableau from 'tableau-api-js';

const Kanban = () => {
    function initViz() {
        const containerDiv = document.getElementById('vizContainer');
        const vizUrl =
            'https://tableau.tele2.kz/views/CIP-TrackerKanban/Story1?:origin=card_share_link&:embed=n';
        const options = {
            width: 1800,
            height: 900
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const viz = new Tableau.Viz(containerDiv, vizUrl, options);
    }

    useEffect(() => {
        initViz();
    }, []);

    return (
        <>
            <div id="vizContainer" />;
        </>
    );
};

export default Kanban;
