// @chakra-ui
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel,
    Heading,
    AccordionProps
} from '@chakra-ui/react';

// ----------------------------------------------------------------------

interface DecisionHistoryProps extends AccordionProps {
    history: string;
    title?: string;
}

export default function DecisionHistory({ history, title = '', ...other }: DecisionHistoryProps) {
    return (
        <Accordion allowToggle {...other}>
            <AccordionItem
                sx={{
                    borderStyle: 'solid',
                    borderColor: 'gray.200'
                }}
            >
                <h2>
                    <AccordionButton>
                        <Box flex="1" textAlign="center">
                            <Heading as="h4" fontSize="md">
                                {title || 'История принятия решений'}
                            </Heading>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel
                    paddingBottom="1rem"
                    fontStyle="italic"
                    fontSize="sm"
                    dangerouslySetInnerHTML={{ __html: history || 'Нет истории' }}
                    lineHeight={1.5}
                />
            </AccordionItem>
        </Accordion>
    );
}
