import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const siteFormValidationSchema = Yup.object().shape({
    createdSiteReg: Yup.string().required('Поле обязательное'),
    createdSiteId: Yup.string()
        .required('Поле обязательное')
        .matches(/^\S*$/, 'ID сайта не должно содержать пробелы'),
    createdSiteLat: Yup.string()
        .required('Поле обязательное')
        .test('test-lat', 'Широта (от -90 до 90) должна записываться в виде десятичной дроби', (value) =>
            isLatitude(value)
        ),
    createdSiteLng: Yup.string()
        .required('Поле обязательное')
        .test('test-lng', 'Долгота (от -180 до 180) должна записываться в виде десятичной дроби', (value) =>
            isLongitude(value)
        ),
    kato: Yup.string().required('Поле обязательное')
});

function isLatitude(lat: string | number = '') {
    return Number.isFinite(+lat) && Math.abs(+lat) <= 90;
}

function isLongitude(lng: string | number = '') {
    return Number.isFinite(+lng) && Math.abs(+lng) <= 180;
}
