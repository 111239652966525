import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setData, setIsAuthed, setRole, setToken, setUsername, userSelector } from 'redux/reducers/userSlice';
import { Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, Stack, useToast } from '@chakra-ui/react';
import { AuthWrapperOutletContext } from 'parts/Auth/AuthWrapper';
import AuthHeading from 'parts/Auth/components/AuthHeading/AuthHeading';
import AuthSubmitButton from 'parts/Auth/components/AuthSubmitButton/AuthSubmitButton';
import AuthInfoDocuments from 'parts/Auth/components/AuthInfoDocuments/AuthInfoDocuments';
/* import AuthRegistrationLink from 'parts/Auth/components/AuthRegistrationLink/AuthRegistrationLink'; */
import FormInputField from 'new-components/FormInputField/FormInputField';
import FormCheckboxField from 'new-components/FormCheckboxField/FormCheckboxField';
import Link from 'new-components/Link/Link';
import AuthRegistrationLink from 'parts/Auth/components/AuthRegistrationLink/AuthRegistrationLink';
import { RepeatClockIcon } from '@chakra-ui/icons';

interface LoginPasswordFormInputFields {
    login: string;
    password: string;
    otp: string;
    rememberMe: boolean;
}

/**
 * @route /login
 * @author Nikita Snetkov
 */
const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { animateLogin } = useOutletContext<AuthWrapperOutletContext>();
    const toast = useToast();
    const { isAuthed } = useSelector(userSelector);

    const [savedLogin, setSavedLogin] = useState<string | null>(null);

    const formInitialValues = {
        login: savedLogin || '',
        password: '',
        otp: '',
        rememberMe: !!savedLogin
    };

    const saveLoginToLocalStorageIfNeeded = (rememberMeChecked: boolean, login: string) => {
        if (rememberMeChecked) {
            localStorage.setItem('savedLogin', login);
        } else {
            localStorage.removeItem('savedLogin');
        }
    };

    const [is2FARequired, setIs2FARequired] = useState(false);

    const resendOtpCode = (userLogin: any) => {
        axios
            .post('/profiles/resendOtpCode', { user: userLogin })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'OTP код успешно отправлен вам на почту'
                });
            })
            .catch((error) => {
                toast({
                    status: 'error',
                    title: error.response.data.message || 'Произошла неизвестная ошибка'
                });
            });
    };

    const onFormSubmit = async (
        data: LoginPasswordFormInputFields,
        helpers: FormikHelpers<LoginPasswordFormInputFields>
    ) => {
        helpers.setSubmitting(true);

        await axios
            .post('/profiles/login', {
                identifier: data.login,
                password: data.password,
                otp: data.otp ? data.otp : undefined
            })
            .then((res) => {
                if (res.data.status !== 'require-otp') {
                    // TODO: убрать этот треш с пятью переменными
                    dispatch(setIsAuthed(true));
                    dispatch(setData(res.data.user));
                    dispatch(setToken(res.data.jwt));
                    dispatch(setUsername(res.data.user.username));
                    dispatch(setRole(res.data.user.role));

                    animateLogin();
                    saveLoginToLocalStorageIfNeeded(data.rememberMe, data.login);

                    toast({
                        status: 'success',
                        title: 'Вы успешно авторизовались в систему',
                        description: `Добро пожаловать, ${
                            res.data.user?.full_name || res.data.user?.username
                        }!`,
                        duration: 3000
                    });

                    setTimeout(() => {
                        navigate('/main');
                    }, 2000);
                } else {
                    setIs2FARequired(true);
                    toast({
                        status: 'info',
                        title: res.data.message
                    });
                }
            })
            .catch((error) => {
                if (error.response.data.message.includes('OTP')) {
                    setIs2FARequired(true);
                }
                toast({
                    status: 'error',
                    title: error.response.data.message || 'Произошла неизвестная ошибка'
                });
            });

        helpers.setSubmitting(false);
    };

    /* Check local storage for saved login */
    useEffect(() => {
        const savedLogin = localStorage.getItem('savedLogin');
        setSavedLogin(savedLogin || '');
    }, []);

    /* If user already authed - redirect to /sites */
    useEffect(() => {
        if (isAuthed) {
            navigate('/main');
        }
    }, []);

    return (
        <>
            <AuthHeading>Вход в систему</AuthHeading>
            {/* TODO: sprint 23 maybe? */}
            {/*           <Button variant="outline" display="flex" flexDir="row" colorScheme="purple">
                <Tele2Logo />
                <Text mt="4px" ml={1} color="black">
                    Войти через аккаунт Tele2
                </Text>
            </Button>
            <DividerWithText>или войти по логину</DividerWithText> */}
            {savedLogin !== null && (
                <Formik initialValues={formInitialValues} onSubmit={onFormSubmit}>
                    {({ values, handleChange, handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            <Stack spacing={2} mt={2}>
                                <FormInputField
                                    label="Логин или почта"
                                    placeholder="Введите логин или почту"
                                    name="login"
                                    autoComplete="username"
                                    autoFocus
                                    isRequired
                                    isDisabled={isSubmitting || is2FARequired}
                                    value={values.login}
                                    handleChange={handleChange}
                                />
                                <FormInputField
                                    label="Пароль"
                                    placeholder="Введите пароль"
                                    name="password"
                                    type="password"
                                    autoComplete="password"
                                    isRequired
                                    isDisabled={isSubmitting || is2FARequired}
                                    value={values.password}
                                    handleChange={handleChange}
                                />
                                {is2FARequired && (
                                    <>
                                        <FormInputField
                                            label="OTP"
                                            placeholder="Введите OTP с почты"
                                            name="otp"
                                            type="text"
                                            isRequired
                                            isDisabled={isSubmitting}
                                            value={values.otp}
                                            handleChange={handleChange}
                                        />
                                        <Button
                                            display="flex"
                                            size="sm"
                                            width="fit-content"
                                            textColor="white"
                                            backgroundColor="#647DEE"
                                            _hover={{
                                                backgroundColor: '#4567ff'
                                            }}
                                            rightIcon={<RepeatClockIcon />}
                                            onClick={() => {
                                                resendOtpCode(values.login);
                                            }}
                                        >
                                            Отправить код повторно
                                        </Button>
                                    </>
                                )}
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <FormCheckboxField
                                        label="Запомнить меня"
                                        isDisabled={isSubmitting}
                                        handleChange={handleChange}
                                        isChecked={values.rememberMe}
                                        name="rememberMe"
                                    />
                                    <Link to="/reset-password">Забыли пароль?</Link>
                                </Box>
                                <AuthSubmitButton
                                    isDisabled={!values.login || !values.password || isSubmitting}
                                    isLoading={isSubmitting}
                                >
                                    Авторизоваться
                                </AuthSubmitButton>
                                <AuthRegistrationLink />
                            </Stack>
                        </Form>
                    )}
                </Formik>
            )}
            <AuthInfoDocuments />
        </>
    );
};

export default Login;
