import {
    Box,
    Button,
    Flex,
    Heading,
    IconButton,
    Input,
    Table,
    TableContainer,
    Tbody,
    Td,
    Textarea,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import { Select } from 'antd';
import { FiTrash2 } from 'react-icons/fi';
import React, { forwardRef, useEffect, useState } from 'react';
import { ErrorMessage as EM, Field, FieldArray, FieldAttributes, useFormikContext } from 'formik';
import { orderService } from 'api/services';
import useDebounce from 'hooks/useDebounce';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/userSlice';
import TCPSearch from './TCPSearch';
import { currencyFormat } from '../../../utils';
import { ERoleNames } from '../../../constants';

interface SiteConfigurationFormProps {
    title: string;
    resource: string;
    priceRegion: string;
    treatyNum: number;
    urgent: boolean;
    disabled?: boolean;
    contragent: number;
    discount: string;
    discountValue: number;
    sitename?: string;
    isNewOrder?: boolean;
    setConnectedSitenameHasChanged?: (a: boolean) => void;
    connectedSitenameHasChanged?: boolean;
}

const ErrorMessage = EM as FieldAttributes<any>;

export const NDS_PERCENTAGE = 0.12; // 12% НДС
const DEFAULT_COEFFICIENT = 1.0;
const URGENT_ORDER_COEFFICIENT = 1.35;

function SiteConfigurationForm(
    {
        title,
        resource,
        priceRegion,
        treatyNum,
        urgent,
        disabled,
        contragent,
        discount,
        discountValue,
        sitename,
        isNewOrder,
        setConnectedSitenameHasChanged,
        connectedSitenameHasChanged
    }: SiteConfigurationFormProps,
    ref: any
) {
    const [sitenameOptions, setSitenameOptions] = useState<{ id: number; sitename: string }[]>([]);
    const { role } = useSelector(userSelector);

    const [sitenameSearchText, setSitenameSearchText] = useState<string>('');
    const debouncedSitenameSearchText = useDebounce(sitenameSearchText, 700);
    const { values, setFieldValue } = useFormikContext<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onClearTable = () => {
        setFieldValue(resource, []);
    };

    // eslint-disable-next-line
    ref.current.totalAmount = values[resource].reduce(
        (acc: number, { price, count }: any) => {
        let finalPrice = Number((Number(price) * count).toFixed(2));

        if (discountValue) {
            finalPrice = Number(
                (Number((price - price * (discountValue / 100)).toFixed(2)) * count).toFixed(2)
            );
        } else if (urgent) {
            finalPrice = Number((Number(price * URGENT_ORDER_COEFFICIENT) * count).toFixed(2));
        }
        return acc + finalPrice;
    }, 0);

    const totalAmountDiscount = values[resource].reduce(
        (acc: number, { price, count }: any) =>
            acc +
            (discountValue
                ? Number((Number((price * (discountValue / 100)).toFixed(2)) * count).toFixed(2))
                : 0),
        0
    );

    const handleChange = async (data: string) => {
        if (setConnectedSitenameHasChanged) {
            setConnectedSitenameHasChanged(false);
        }
        const [newSitename, tcpOrderId, uniqueId] = data.split('+');
        const newTcps = values[resource].map((tcp: any) => {
            if (isNewOrder) {
                // eslint-disable-next-line eqeqeq
                if (tcp.uniqueId == uniqueId) {
                    return { ...tcp, sitename: newSitename };
                }
                return tcp;
            }
            // eslint-disable-next-line eqeqeq
            if (tcp.id == tcpOrderId) {
                return { ...tcp, sitename: newSitename };
            }
            return tcp;
        });

        if (isNewOrder) {
            setFieldValue(resource, newTcps);
        } else {
            await orderService.changeOrderConfig(values.orderID, [tcpOrderId], {
                newSitename
            });
            setFieldValue(resource, newTcps);
        }
    };

    const handleSearchSitenameOptions = (searchText: string) => {
        if (!searchText) {
            setSitenameOptions([]);
            return;
        }
        axios.get(`/sitesName?sitename=${searchText}`).then((res) => {
            const sites = res.data;
            setSitenameOptions(sites);
        });
    };

    useEffect(() => {
        handleSearchSitenameOptions(debouncedSitenameSearchText);
    }, [debouncedSitenameSearchText]);

    return (
        <Box mt={2}>
            <Heading as="h1" fontSize="md">
                {title}
            </Heading>
            <TableContainer mt={2}>
                <Table variant="simple" size="sm" border="1px solid #EDF2F7">
                    <Thead bg="aliceblue">
                        <Tr>
                            <Th px={3} py="1.5">
                                #ТЦП
                            </Th>
                            <Th px={3} py="1.5">
                                Sitename
                            </Th>
                            <Th px={3} py="1.5">
                                Наименование работ по ТЦП
                            </Th>
                            <Th px={3} py="1.5">
                                Ед. Изм.
                            </Th>
                            <Th px={3} py="1.5">
                                Кол-во.
                            </Th>
                            <Th px={3} py="1.5">
                                {discount ? 'Цена со скидкой за ед.' : 'Цена за ед.'}
                            </Th>
                            <Th px={3} py="1.5">
                                Дополнительно
                            </Th>
                            <Th px={3} py="1.5">
                                Сумма
                            </Th>
                            <Th px={3} py="1.5" />
                        </Tr>
                    </Thead>
                    {/* @ts-ignore */}
                    <FieldArray name={resource}>
                        {({ remove, push }) => (
                            <Tbody>
                                {!disabled && (
                                    <Tr>
                                        <Td px={3} py="1.5" colSpan={8}>
                                            <Flex alignItems="center">
                                                <Box margin="6px" w="100%">
                                                    <TCPSearch
                                                        onChange={({
                                                            id,
                                                            ext_id,
                                                            name,
                                                            type,
                                                            unit,
                                                            price,
                                                            coefficient,
                                                            onec_id,
                                                            region,
                                                            sitename,
                                                            uniqueId
                                                        }) => {
                                                            push({
                                                                id,
                                                                ext_id,
                                                                name: type,
                                                                workType: name,
                                                                unit,
                                                                count: 1,
                                                                price: price || 1,
                                                                notes: '',
                                                                coefficient,
                                                                onec_id,
                                                                region,
                                                                sitename,
                                                                uniqueId
                                                            });
                                                        }}
                                                        sitename={sitename}
                                                        region={priceRegion}
                                                        treatyNum={treatyNum}
                                                        coefficient={DEFAULT_COEFFICIENT}
                                                        contragent={contragent}
                                                    />
                                                </Box>
                                                <Button
                                                    onClick={onClearTable}
                                                    colorScheme="red"
                                                    variant="outline"
                                                    size="sm"
                                                    ml={1}
                                                >
                                                    Очистить таблицу
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )}
                                {values[resource].length > 0 &&
                                    values[resource].map((tcpOrder: any, index: any) => (
                                        <Tr key={tcpOrder.uniqueId || tcpOrder.id}>
                                            <Td>{tcpOrder.ext_id}</Td>
                                            <Td>
                                                <Select
                                                    placeholder="Введите для поиска"
                                                    style={{ width: '100%' }}
                                                    optionFilterProp="children"
                                                    showSearch
                                                    value={
                                                        connectedSitenameHasChanged
                                                            ? sitename
                                                            : tcpOrder.sitename
                                                    }
                                                    onSearch={(value) => {
                                                        setSitenameSearchText(value);
                                                    }}
                                                    onChange={handleChange}
                                                    optionLabelProp="value"
                                                    disabled={
                                                        role?.name === ERoleNames.EXECUTOR ||
                                                        role?.name === ERoleNames.SUBC
                                                    }
                                                >
                                                    {sitenameOptions.map((opt) => (
                                                        <Select.Option
                                                            value={`${opt.sitename}+${tcpOrder.id}+${tcpOrder.uniqueId}`}
                                                            key={opt.id}
                                                        >
                                                            {opt.sitename}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Td>
                                            <Td whiteSpace="break-spaces" maxW="600px">
                                                {tcpOrder.workType}
                                            </Td>
                                            <Td>{String(tcpOrder.unit).toLowerCase()}</Td>
                                            <Td>
                                                {disabled ? (
                                                    <Box>{tcpOrder.count}</Box>
                                                ) : (
                                                    <Flex flexDir="column" overflow="hidden">
                                                        <Field
                                                            name={`${resource}.${index}.count`}
                                                            as={Input}
                                                            borderColor="gray.400"
                                                            w="50px"
                                                            sx={{
                                                                paddingInlineStart: 2,
                                                                paddingInlineEnd: 0
                                                            }}
                                                        />
                                                        <Box mt={1} color="red" fontSize="xs">
                                                            <ErrorMessage
                                                                name={`${resource}.${index}.count`}
                                                            />
                                                        </Box>
                                                    </Flex>
                                                )}
                                            </Td>
                                            <Td>
                                                {currencyFormat(
                                                    discount
                                                        ? Number(
                                                              (
                                                                  Number(tcpOrder.price) -
                                                                  Number(tcpOrder.price) *
                                                                      (discountValue / 100)
                                                              ).toFixed(2)
                                                          )
                                                        : tcpOrder.price
                                                )}
                                            </Td>
                                            <Td whiteSpace="break-spaces">
                                                {disabled ? (
                                                    <Box>{tcpOrder.notes}</Box>
                                                ) : (
                                                    <Flex flexDir="column" overflow="hidden">
                                                        <Field
                                                            name={`${resource}.${index}.notes`}
                                                            as={Textarea}
                                                            borderColor="gray.400"
                                                            type="text"
                                                            rows={1}
                                                            sx={{
                                                                paddingInlineStart: 2,
                                                                paddingInlineEnd: 0
                                                            }}
                                                            resize="vertical"
                                                        />
                                                        <Box mt={1} color="red" fontSize="xs">
                                                            <ErrorMessage
                                                                name={`${resource}.${index}.notes`}
                                                            />
                                                        </Box>
                                                    </Flex>
                                                )}
                                            </Td>
                                            <Td textAlign="right" px={3}>
                                                {currencyFormat(
                                                    discount
                                                        ? Number(
                                                              (
                                                                  Number(
                                                                      (
                                                                          Number(tcpOrder.price) -
                                                                          Number(tcpOrder.price) *
                                                                              (discountValue / 100)
                                                                      ).toFixed(2)
                                                                  ) * tcpOrder.count
                                                              ).toFixed(2)
                                                          )
                                                        : Number(
                                                              (
                                                                  Number(tcpOrder.price) * tcpOrder.count
                                                              ).toFixed(2)
                                                          )
                                                )}
                                            </Td>
                                            <Td>
                                                {!disabled && (
                                                    <IconButton
                                                        aria-label="remove-btn"
                                                        icon={<FiTrash2 color="red" />}
                                                        variant="unstyled"
                                                        onClick={() => remove(index)}
                                                    />
                                                )}
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        )}
                    </FieldArray>

                    <Tfoot>
                        <Tr>
                            <Td colSpan={6} textAlign="right" px={3} py="1.5">
                                {discount ? 'Всего на сумму со скидкой' : 'Всего ЭАП на сумму'}
                            </Td>
                            <Td textAlign="right" px={3} py="1.5">
                                {
                                    // eslint-disable-next-line react/destructuring-assignment
                                    currencyFormat(ref.current.totalAmount)
                                }
                            </Td>
                            <Td />
                        </Tr>
                        {discount ? (
                            <Tr>
                                <Td colSpan={6} textAlign="right" px={3} py="1.5">
                                    Всего скидка
                                </Td>
                                <Td textAlign="right" px={3} py="1.5">
                                    {
                                        // eslint-disable-next-line react/destructuring-assignment
                                        currencyFormat(totalAmountDiscount)
                                    }
                                </Td>
                                <Td />
                            </Tr>
                        ) : (
                            ''
                        )}
                        <Tr>
                            <Td colSpan={6} textAlign="right" px={3} py="1.5">
                                Всего на сумму c НДС
                            </Td>
                            <Td textAlign="right" px={3} py="1.5">
                                {currencyFormat(
                                    // eslint-disable-next-line react/destructuring-assignment
                                    Number((ref.current.totalAmount * (1 + NDS_PERCENTAGE)).toFixed(2))
                                )}
                            </Td>
                            <Td />
                        </Tr>
                        <Tr>
                            <Td colSpan={6} textAlign="right" px={3} py="1.5">
                                В том числе НДС(12%)
                            </Td>
                            <Td textAlign="right" px={3} py="1.5">
                                {currencyFormat(
                                    // eslint-disable-next-line react/destructuring-assignment
                                    Number((ref.current.totalAmount * NDS_PERCENTAGE).toFixed(2))
                                )}
                            </Td>
                            <Td />
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default forwardRef(SiteConfigurationForm);
