// @chakra-ui
import { Spinner, Box } from '@chakra-ui/react';

interface BaseStyles {
    [key: string]: React.CSSProperties;
}

const baseStyles: BaseStyles = {
    wrapper: {
        position: 'relative'
    },
    overlay: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        transition: 'opacity 200ms ease-in',
        background: 'rgba(255, 255, 255, 0.7)',
        color: '#fff',
        opacity: '1',
        zIndex: 800,
        display: 'flex'
    },
    content: {
        margin: 'auto'
    }
};

export interface Styles {
    wrapper?: (base: React.CSSProperties) => React.CSSProperties;
    overlay?: (base: React.CSSProperties) => React.CSSProperties;
    content?: (base: React.CSSProperties) => React.CSSProperties;
}

export interface LoadingOverlayProps {
    children: JSX.Element;
    active: boolean;
    Spinner?: JSX.Element;
    styles?: Styles;
}

const LoadingOverlay = ({ children, active, Spinner: CustomSpinner, styles }: LoadingOverlayProps) => (
    <Box
        className="wrapper"
        as="div"
        sx={styles?.wrapper ? styles?.wrapper(baseStyles.wrapper) : baseStyles.wrapper}
    >
        {active ? (
            <Box
                as="div"
                className="overlay"
                sx={styles?.overlay ? styles?.overlay(baseStyles.overlay) : baseStyles.overlay}
            >
                <Box
                    as="div"
                    className="content"
                    sx={styles?.content ? styles?.content(baseStyles.content) : baseStyles.content}
                >
                    <Box className="spinner">
                        {CustomSpinner !== undefined ? CustomSpinner : <Spinner color="blue.500" />}
                    </Box>
                </Box>
            </Box>
        ) : null}
        {children}
    </Box>
);

export default LoadingOverlay;
