/* eslint-disable import/prefer-default-export */
// @chakra-ui
import { Checkbox, Grid, GridItem, GridProps } from '@chakra-ui/react';
// form
import { Field, FieldProps } from 'formik';

interface FMultiCheckboxProps extends GridProps {
    name: string;
    options: Array<{
        label: string;
        value: string;
    }>;
}

export const FMultiCheckbox = ({ name, options, ...other }: FMultiCheckboxProps) => (
    <Field name={name}>
        {({ field, form }: FieldProps) => {
            const onSelected = (option: any) =>
                field.value.includes(option)
                    ? field.value.filter((value: any) => value !== option)
                    : [...field.value, option];

            return (
                <Grid templateColumns="repeat(2, 1fr)" gap={1} width="max-content" {...other}>
                    {options.map((option) => (
                        <GridItem key={option.value}>
                            <Checkbox
                                value={option.value}
                                isChecked={field.value.includes(option.value)}
                                onChange={() => {
                                    form.setFieldValue(field.name, onSelected(option.value));
                                }}
                            >
                                {option.label}
                            </Checkbox>
                        </GridItem>
                    ))}
                </Grid>
            );
        }}
    </Field>
);
