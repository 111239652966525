import React, { useEffect, useState } from 'react';
import { Table, Spin, Button, Select, Input, Typography } from 'antd';
import axios from 'axios';

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer.types';
import { Flex, useToast } from '@chakra-ui/react';
import { IKato } from 'interfaces/kato';
import SelectWithAutocomplete from 'Routes/AutoComplete';
import EsignCard from 'components/EsignCard';
import SiteToaDetails from 'components/Site/SiteTabs/SiteDetails/SiteToaDetails';
import SiteWorkList from 'components/Site/SiteTabs/SiteDetails/SiteWorkList';
import { useTitle } from 'react-use';
import { generateTitle } from 'utils';
import useHeaderTitle from 'hooks/useHeaderTitle';
import SiteOrderList from 'components/Site/SiteTabs/SiteDetails/SiteOrderList';
import { siteService } from 'api/services';
import constants, { ERoleNames } from '../../../../constants';

interface ISiteRow {
    key: string;
    uniqueKey: string;
    title: string;
    render: (value: any) => any;
    renderForEdit?: (value: any, record: any, handleInputChange: (key: string, value: any) => void) => any;
    fetchOptions?: () => Promise<any[]>;
}

interface IFieldTitles {
    [key: string]: string;
}

const SiteDetails: React.FC = () => {
    const [siteData, setSiteData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { trueSiteID, siteID } = useParams<{ trueSiteID: string; siteID: string }>();
    const [editingData, setEditingData] = useState<any>({});
    const { role } = useSelector((state: RootState) => state.user);
    const toast = useToast({ position: 'bottom-right', duration: 4000 });
    const canEditSite = role?.name === ERoleNames.RNP;
    const regionOptions = constants.dropdownData.s_reg?.map((item) => ({ value: item, label: item }));

    const handleKatoChange = (_: any, option: any) => {
        setSiteData({ ...siteData, kato: option });
        setEditingData({ ...editingData, kato: option.id });
    };
    useTitle(
        generateTitle(
            siteData?.s_new_site_id
                ? `Сайт ${siteData?.s_new_site_id} (${siteData.sitename})`
                : 'Список сайтов (rollout)'
        )
    );

    useHeaderTitle(
        siteData?.s_new_site_id
            ? `Сайт ${siteData?.s_new_site_id} (${siteData.sitename})`
            : 'Список сайтов (rollout)'
    );
    const siteRows: ISiteRow[] = [
        {
            key: 's_new_site_id',
            uniqueKey: 's_new_site_id',
            title: 'Site ID',
            render: (value) => value
            // renderForEdit: (value, record, handleInputChange) => (
            //     <Input value={value} onChange={(e) => handleInputChange(record.key, e.target.value)} />
            // )
        },
        {
            key: 'sitename',
            uniqueKey: 'sitename',
            title: 'Site Name',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Input value={value} onChange={(e) => handleInputChange(record.key, e.target.value)} />
            )
        },
        {
            key: 's_lat',
            uniqueKey: 's_lat',
            title: 'Latitude',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Input value={value} onChange={(e) => handleInputChange(record.key, e.target.value)} />
            )
        },
        {
            key: 's_lng',
            uniqueKey: 's_lng',
            title: 'Longitude',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Input value={value} onChange={(e) => handleInputChange(record.key, e.target.value)} />
            )
        },
        {
            key: 'buildingHeight',
            uniqueKey: 'buildingHeight',
            title: 'Building Height',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Input
                    value={value}
                    placeholder="Введите значение высоты постройки"
                    onChange={(e) => handleInputChange(record.key, e.target.value)}
                />
            )
        },
        {
            key: 'buildingType',
            uniqueKey: 'buildingType',
            title: 'Building Type',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Input
                    value={value}
                    placeholder="Введите тип постройки"
                    onChange={(e) => handleInputChange(record.key, e.target.value)}
                />
            )
        },
        {
            key: 'existingBand',
            uniqueKey: 'existingBand',
            title: 'Existing Band',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Input
                    value={value}
                    placeholder="Введите Existing Band"
                    onChange={(e) => handleInputChange(record.key, e.target.value)}
                />
            )
        },
        {
            key: 's_district',
            uniqueKey: 's_district',
            title: 'Область',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Input
                    value={value}
                    placeholder="Введите область"
                    onChange={(e) => handleInputChange(record.key, e.target.value)}
                />
            )
        },
        {
            key: 'locality',
            uniqueKey: 'locality',
            title: 'Населенный пункт',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Input
                    value={value}
                    placeholder="Введите населенный пункт"
                    onChange={(e) => handleInputChange(record.key, e.target.value)}
                />
            )
        },
        {
            key: 's_site_address',
            uniqueKey: 's_site_address',
            title: 'Адрес',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Input
                    value={value}
                    placeholder="Введите адрес"
                    onChange={(e) => handleInputChange(record.key, e.target.value)}
                />
            )
        },
        {
            key: 's_reg',
            uniqueKey: 's_reg',
            title: 'Region',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Select
                    style={{ width: '100%' }}
                    value={value}
                    onChange={(val) => handleInputChange(record.key, val)}
                    options={regionOptions}
                />
            )
        },
        {
            key: 'kato',
            uniqueKey: 'kato',
            title: 'KATO',
            render: (kato: IKato) => kato?.katoId,
            renderForEdit: (value) => (
                <SelectWithAutocomplete
                    value={value?.katoId}
                    onChange={(_, option) => handleKatoChange(_, option)}
                    fetchUrl="/katos"
                    placeholder="Найти КАТО (по ID)"
                    queryKey="katoId_contains"
                    valueKey="katoId"
                    labelFormatter={(option) => option.katoId}
                />
            )
        },
        {
            key: 'kato',
            uniqueKey: 'katoArea',
            title: 'KATO Area',
            render: (kato: IKato) => kato?.area
        },
        {
            key: 'importanceClass',
            uniqueKey: 'importanceClass',
            title: 'Класс важности',
            render: (value) => value,
            renderForEdit: (value, record, handleInputChange) => (
                <Select
                    value={value}
                    onChange={(val) => handleInputChange(record.key, val)}
                    style={{ width: '100%' }}
                    placeholder="Выберите класс важности"
                    options={[
                        { value: '1', label: '1' },
                        { value: '2', label: '2' }
                    ]}
                />
            )
        }
    ];

    const fieldTitles: IFieldTitles = siteRows.reduce((acc, row) => {
        acc[row.key] = row.title;
        return acc;
    }, {} as IFieldTitles);

    const getSiteInfo = async () => {
        setLoading(true);
        try {
            const response = await siteService.getById(trueSiteID as string, {});
            setSiteData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (key: string, value: any) => {
        setSiteData({ ...siteData, [key]: value });
        setEditingData({ ...editingData, [key]: value });
    };
    const handleSave = async (key: string) => {
        const newValue = `${editingData[key] || ''}`;
        setLoading(true);
        try {
            await axios.patch('/sites', { id: siteData.id, field: key, value: newValue });
            const fieldTitle = fieldTitles[key] || key;
            toast({ status: 'success', title: `${fieldTitle} успешно обновлен` });
            setEditingData({});
        } catch (error: any) {
            toast({
                status: 'error',
                title: error?.response?.data?.message || 'Ошибка при сохранении данных'
            });
        } finally {
            setLoading(false);
        }
    };

    const rowRender = (text: string, record: any) => {
        const columnDef = siteRows.find((col) => col.uniqueKey === record.key);
        if (columnDef) {
            if (canEditSite && columnDef.renderForEdit) {
                return columnDef.renderForEdit(text, record, handleInputChange);
            }
            return columnDef.render(text);
        }
        return text;
    };

    const actionRender = (_: string, record: any) =>
        canEditSite && record.editable ? (
            <Button type="primary" disabled={!editingData[record.key]} onClick={() => handleSave(record.key)}>
                Сохранить
            </Button>
        ) : null;

    const columns = [
        {
            title: 'Поле',
            dataIndex: 'title',
            key: 'title',
            width: '30%'
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            render: (text: string, record: any) => rowRender(text, record),
            width: canEditSite ? '50%' : '70%'
        },
        ...(canEditSite
            ? [
                  {
                      title: 'Действия',
                      key: 'actions',
                      render: (text: string, record: any) => actionRender(text, record),
                      width: '20%'
                  }
              ]
            : [])
    ];

    const dataSource = siteRows.map((col) => ({
        key: col.uniqueKey,
        title: col.title,
        value: siteData?.[col.key],
        editable: col.renderForEdit !== undefined
    }));

    useEffect(() => {
        getSiteInfo();
    }, [siteID, trueSiteID]);

    return (
        <Spin spinning={loading}>
            <Table dataSource={dataSource} columns={columns} pagination={false} bordered rowKey="key" />
            {siteData?.rsds.length > 0 ? (
                <Flex flexDirection="column" mt={4}>
                    <Typography style={{ fontSize: 20, marginBottom: 20 }}>RSD Files</Typography>
                    {siteData?.rsds?.map((rsd: any) => (
                        <EsignCard key={`rsd_${rsd?.id}`} esign={rsd} />
                    ))}
                </Flex>
            ) : null}
            {siteData?.tsds.length > 0 ? (
                <Flex flexDirection="column" mt={4}>
                    <Typography style={{ fontSize: 20, marginBottom: 20 }}>TSD Files</Typography>
                    {siteData?.tsds?.map((tsd: any) => (
                        <EsignCard key={`tsd_${tsd?.id}`} esign={tsd} />
                    ))}
                </Flex>
            ) : null}
            {siteData?.toa && (
                <SiteToaDetails canEdit={canEditSite} toaData={siteData.toa} setSiteData={setSiteData} />
            )}
            <>
                <Typography style={{ fontSize: 20, marginBottom: 20, marginTop: 20 }}>Список ЭАП</Typography>
                <SiteWorkList siteId={siteID as string} />
            </>
            <>
                <Typography style={{ fontSize: 20, marginBottom: 20, marginTop: 20 }}>
                    Список заказов
                </Typography>
                <SiteOrderList siteId={siteID as string} />
            </>
        </Spin>
    );
};

export default SiteDetails;
