import axios from 'axios';
import qs from 'qs';

const list = ({ params }: any) =>
    axios.get(`equip-balances`, { params, paramsSerializer: (params) => qs.stringify(params) });

const count = ({ params }: any) =>
    axios.get(`equip-balances/count`, { params, paramsSerializer: (params) => qs.stringify(params) });

export default {
    list,
    count
};
