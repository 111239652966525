import _ from 'lodash';

const getParameters = ({ _limit, _start, _sort, sap_code_contains, status, totalAmountWithoutVat }: any) => {
    const params: any = {
        start: _start,
        limit: _limit,
        sort: _sort,
        sap_code_contains,
        status,
        totalAmountWithoutVat
    };
    // Removing empty values from params
    const finalParams = _.omitBy(params, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
    const finalParamsString = `_start=${finalParams?.start}&_sort=${finalParams?.sort}&_limit=${
        finalParams?.limit
    }${finalParams?.status ? `&status=${finalParams?.status}` : ''}${
        finalParams?.sap_code_contains ? `&sap_code_contains=${finalParams?.sap_code_contains}` : ''
    }${
        finalParams?.totalAmountWithoutVat
            ? `&totalAmountWithoutVat=${finalParams?.totalAmountWithoutVat}`
            : ''
    }`;
    const finalParamsTotalString = `${finalParams?.status ? `&status=${finalParams?.status}` : ''}
    ${finalParams?.sap_code_contains ? `&id_contains=${finalParams?.sap_code_contains}` : ''}${
        finalParams?.totalAmountWithoutVat
            ? `&totalAmountWithoutVat=${finalParams?.totalAmountWithoutVat}`
            : ''
    }`;

    return [finalParamsString, finalParamsTotalString];

    // setParametersOrders(finalParamsString);
    // setParametersTotalOrders(finalParamsTotalString);
};
export default getParameters;
