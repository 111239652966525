// @chakra-ui
import { Stack, Input, InputGroup, InputLeftElement, Spacer, Text } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';

// ----------------------------------------------------------------------

interface TaskTableToolbarProps {
    onFilterSiteId: (event: React.ChangeEvent<HTMLInputElement>) => void;
    totalTasks: number;
    filterSiteId: string;
}

const TaskTableToolbar: React.FC<TaskTableToolbarProps> = ({
    onFilterSiteId,
    totalTasks,
    filterSiteId
}: TaskTableToolbarProps) => (
    <Stack
        width="full"
        direction={{ base: 'column', md: 'row' }}
        spacing={1}
        p={1.5}
        justifyContent="space-between"
    >
        <Text alignSelf="center" fontWeight="bold">
            Список задач ({totalTasks})
        </Text>
        <InputGroup size="sm" width="auto">
            <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
                paddingLeft={4}
                placeholder="Поиск по ID сайта"
                onChange={onFilterSiteId}
                defaultValue={filterSiteId || ''}
            />
        </InputGroup>
        <Spacer />
    </Stack>
);

export default TaskTableToolbar;
