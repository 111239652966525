/* eslint-disable no-use-before-define */
import { useRef, useState } from 'react';
import _ from 'lodash';
// @chakra-ui
import {
    Text,
    Button,
    Stack,
    Box,
    VStack,
    Divider,
    useBreakpointValue,
    useToast,
    Heading,
    IconButton
} from '@chakra-ui/react';
import { DownloadIcon, DeleteIcon } from '@chakra-ui/icons';
// form
import { FormikProps } from 'formik';
// hooks
import useToggle from 'hooks/useToggle';
// components
import { FormProvider } from 'components/form';
// services
import { siteService } from 'api/services';
//
import axios from 'axios';
import WorkUploadModal from '../WorkUploadModal';

// ----------------------------------------------------------------------

interface WorkRltSLFilesProps {
    work: any;
    updateWork: () => void;
}

export default function WorkRltSLFiles({ work, updateWork }: WorkRltSLFilesProps) {
    const [isLoadingSL, setIsLoadingSL] = useState(false);
    const [isLoadingExtra, setIsLoadingExtra] = useState(false);

    const formikRefSL = useRef<FormikProps<any>>(null);
    const formikRefExtra = useRef<FormikProps<any>>(null);

    const { toggle: openSL, onOpen: onOpenSL, onClose: onCloseSL } = useToggle();
    const { toggle: openExtra, onOpen: onOpenExtra, onClose: onCloseExtra } = useToggle();

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const handleDropSL = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];

        if (file) {
            formikRefSL.current!.setFieldValue('fileSL', file);
        }
    };

    const handleDropExtra = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];

        if (file) {
            formikRefExtra.current!.setFieldValue('fileExtra', file);
        }
    };

    const handleRemoveSL = () => {
        formikRefSL.current!.setFieldValue('fileSL', null);
    };

    const handleRemoveExtra = () => {
        formikRefExtra.current!.setFieldValue('fileExtra', null);
    };

    const handleSubmitSL = () => {
        const { fileSL } = formikRefSL.current!.values;

        if (!fileSL) return;

        const formData = new FormData();
        formData.append('id', work.id);
        formData.append('field', 's_docs_subm');
        formData.append('file', fileSL);

        setIsLoadingSL(true);
        siteService
            .upload(formData)
            .then(() => {
                setIsLoadingSL(false);
                onCloseSL();
                updateWork();
            })
            .catch((err) => {
                setIsLoadingSL(false);
                toast({
                    title: err.response?.data?.message,
                    status: 'error'
                });
                console.error(err.response);
            });
    };

    const handleSubmitExtra = () => {
        const { fileExtra } = formikRefExtra.current!.values;

        if (!fileExtra) return;

        const formData = new FormData();
        formData.append('id', work.id);
        formData.append('field', 's_sl_agreetment_files');
        formData.append('file', fileExtra);

        setIsLoadingExtra(true);
        siteService
            .upload(formData)
            .then(() => {
                setIsLoadingExtra(false);
                onCloseExtra();
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response?.data?.message,
                    status: 'error'
                });
                setIsLoadingExtra(false);
                console.error(err);
            });
    };

    const handleRemove = (id: string | number) => {
        axios
            .post('/esigns/delete', {
                id
            })
            .then(() => {
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: 'Не удалось удалить файл',
                    status: 'error'
                });
                console.error(err);
            });
    };

    const disableAction = _.includes([1, 2], work.s_sl_main_status);

    return (
        <Box>
            <Stack
                spacing={{ base: 1, md: 2.5 }}
                direction={{ base: 'column', md: 'row' }}
                divider={
                    <Divider
                        height="auto"
                        orientation={useBreakpointValue({ base: 'horizontal', md: 'vertical' })}
                    />
                }
                width="full"
                overflowX="auto"
                p={1.5}
            >
                <Box width="full" minWidth="500px">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                        <Heading as="h4" fontSize="md">
                            Загруженные SL
                        </Heading>
                        <Button
                            size="sm"
                            colorScheme="purple"
                            onClick={onOpenSL}
                            flexShrink={0}
                            disabled={disableAction}
                        >
                            Загрузить SL
                        </Button>
                    </Stack>

                    <VStack mt={2} width="full" spacing={0.5}>
                        {work.s_docs_subm?.esigns?.length > 0 ? (
                            work.s_docs_subm.esigns.map(
                                ({ id, file_name, created_at, doc_link, banOnDeletion }: any) => (
                                    <FileItem
                                        key={id}
                                        fileName={file_name}
                                        date={created_at}
                                        onDownload={() => {
                                            window.open(`${process.env.REACT_APP_API_BASE_URL}${doc_link}`);
                                        }}
                                        onRemove={() => handleRemove(id)}
                                        disableRemove={disableAction || banOnDeletion}
                                    />
                                )
                            )
                        ) : (
                            <Text>Загруженных файлов не найдено</Text>
                        )}
                    </VStack>

                    <FormProvider
                        innerRef={formikRefSL}
                        initialValues={{
                            fileSL: null
                        }}
                    >
                        <WorkUploadModal
                            isLoading={isLoadingSL}
                            fieldName="fileSL"
                            header="Загрузка SL"
                            open={openSL}
                            onClose={onCloseSL}
                            onDrop={handleDropSL}
                            onRemove={handleRemoveSL}
                            onSubmit={handleSubmitSL}
                        />
                    </FormProvider>
                </Box>

                <Box width="full" minWidth="500px">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                        <Heading as="h4" fontSize="md">
                            Загруженные доп. файлы
                        </Heading>
                        <Button
                            size="sm"
                            colorScheme="purple"
                            onClick={onOpenExtra}
                            flexShrink={0}
                            disabled={disableAction}
                        >
                            Загрузить доп. файлы
                        </Button>
                    </Stack>

                    <VStack mt={2} width="full" spacing={0.5}>
                        {work.s_sl_agreetment_files?.esigns?.length ? (
                            work.s_sl_agreetment_files.esigns.map(
                                ({ id, file_name, created_at, doc_link }: any) => (
                                    <FileItem
                                        onRemove={() => handleRemove(id)}
                                        key={id}
                                        fileName={file_name}
                                        date={created_at}
                                        onDownload={() => {
                                            window.open(`${process.env.REACT_APP_API_BASE_URL}${doc_link}`);
                                        }}
                                        disableRemove={disableAction}
                                    />
                                )
                            )
                        ) : (
                            <Text>Загруженных файлов не найдено.</Text>
                        )}
                    </VStack>

                    <FormProvider innerRef={formikRefExtra} initialValues={{ fileExtra: null }}>
                        <WorkUploadModal
                            isLoading={isLoadingExtra}
                            fieldName="fileExtra"
                            header="Загрузка доп. файлов"
                            open={openExtra}
                            onClose={onCloseExtra}
                            onDrop={handleDropExtra}
                            onRemove={handleRemoveExtra}
                            onSubmit={handleSubmitExtra}
                        />
                    </FormProvider>
                </Box>
            </Stack>
        </Box>
    );
}

// ----------------------------------------------------------------------

interface FileItemProps {
    fileName: string;
    date: string;
    disableRemove: boolean;
    onDownload: () => void;
    onRemove: () => void;
}

function FileItem({ fileName, date, onDownload, onRemove, disableRemove }: FileItemProps) {
    return (
        <Stack direction="row" spacing={1} alignItems="center" fontSize="sm" width="full">
            <Box flexGrow={1} wordBreak="break-word">
                {fileName}
            </Box>
            <Box flexShrink={0}>{new Date(date).toLocaleString('ru')}</Box>
            <Box flexShrink={0} />
            <Box flexShrink={0}>
                <IconButton
                    variant="outline"
                    colorScheme="purple"
                    onClick={onDownload}
                    size="xs"
                    aria-label="Download file"
                    icon={<DownloadIcon />}
                    mr="5px"
                />
                <IconButton
                    variant="outline"
                    colorScheme="red"
                    onClick={onRemove}
                    size="xs"
                    aria-label="Remove file"
                    icon={<DeleteIcon />}
                    disabled={disableRemove}
                />
            </Box>
        </Stack>
    );
}
