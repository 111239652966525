// @chakra-ui
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button
} from '@chakra-ui/react';

// ----------------------------------------------------------------------

interface ConfirmationDialogProps {
    title?: string;
    body?: string;
    declineText?: string | null;
    onDecline?: () => void;
    acceptText?: string;
    onAccept?: () => void;
    isOpen: boolean;
    modalRef: React.RefObject<any>;
    onClose: () => void;
    acceptButtonColor?: string;
}

const mapText = (text: string): JSX.Element[] => {
    const textSplit = text.split('<br />');
    return textSplit.map((e: string) => (
        <span key={e}>
            {e}
            <br />
        </span>
    ));
};

const ConfirmationDialog = ({
    title = 'Вы уверены?',
    body = 'Вы уверены что хотите выполнить это действие?',
    declineText = 'Нет',
    acceptText = 'Да',
    onDecline,
    acceptButtonColor = 'red',
    onAccept,
    isOpen,
    modalRef,
    onClose
}: ConfirmationDialogProps): JSX.Element => {
    const bodyTxt = mapText(body);
    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={modalRef} onClose={() => onClose()} isCentered>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>{bodyTxt}</AlertDialogBody>

                    <AlertDialogFooter>
                        {declineText ? (
                            <Button
                                ref={modalRef as React.LegacyRef<HTMLButtonElement> | undefined}
                                onClick={() => {
                                    if (onDecline) onDecline();
                                    onClose();
                                }}
                            >
                                {declineText}
                            </Button>
                        ) : null}
                        <Button
                            colorScheme={acceptButtonColor}
                            ml="0.75rem"
                            ref={modalRef as React.LegacyRef<HTMLButtonElement> | undefined}
                            onClick={() => {
                                if (onAccept) onAccept();
                                onClose();
                            }}
                        >
                            {acceptText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export type ConfirmationItems = {
    [key: string]: {
        message: string;
        func: (args?: NodeJS.Dict<any>) => void | Promise<void>;
    };
};

export default ConfirmationDialog;
