import React from 'react';
// @chakra-ui
import { Select, StyleProps } from '@chakra-ui/react';

// ----------------------------------------------------------------------

export interface TablePageSizeProps extends StyleProps {
    gotoPage: Function;
    setPageSize: Function;
    pageSizeOptions: Array<number>;
    pageSize: number;
}

const TablePageSize: React.FC<TablePageSizeProps> = ({
    gotoPage,
    pageSizeOptions,
    pageSize,
    setPageSize,
    ...other
}) => (
    <Select
        size="sm"
        width="auto"
        value={pageSize}
        onChange={(e) => {
            setPageSize(Number(e.target.value));
            gotoPage(0);
        }}
        {...other}
    >
        {pageSizeOptions.map((pageSize: number) => (
            <option key={pageSize} value={pageSize}>
                Показать {pageSize}
            </option>
        ))}
    </Select>
);

export default TablePageSize;
