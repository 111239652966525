import { useState } from 'react';
import _ from 'lodash';
// @chakra-ui
import { Badge, Stack, Heading, Button, useToast } from '@chakra-ui/react';
// services
import siteService from 'api/services/site-service';

// ----------------------------------------------------------------------

interface WorkRltSLStatusProps {
    updateWork: () => void;
    work: any;
}

export default function WorkRltSLStatus({ work, updateWork }: WorkRltSLStatusProps) {
    const [isLoading, setIsLoading] = useState(false);

    const toast = useToast({ position: 'bottom-right', duration: 4000 });

    const { s_sl_main_status: status } = work;

    const disableAction = _.includes([1, 2], status) || !_.get(work, 's_docs_subm.esigns.length', 0);

    const [color, text] =
        status !== null
            ? status === 1
                ? ['yellow', 'На рассмотрении']
                : status === 2
                ? ['green', 'Согласовано']
                : ['red', 'Отклонено']
            : ['gray', 'Ожидает начало процесса'];

    const handleStart = () => {
        setIsLoading(true);
        siteService
            .startProcess({
                id: work.id,
                type: 'sl'
            })
            .then(() => {
                setIsLoading(false);
                toast({
                    title: 'Процесс согласования успешно запущен',
                    status: 'success'
                });
                updateWork();
            })
            .catch((err) => {
                toast({
                    title: err.response.data.message,
                    status: 'error'
                });
                setIsLoading(false);
            });
    };

    return (
        <Stack p={1.5} alignItems="center">
            <Button
                disabled={isLoading || disableAction}
                isLoading={isLoading}
                colorScheme="purple"
                onClick={handleStart}
                variant="outline"
            >
                Начать процесс
            </Button>
            <Heading as="h4" fontSize="md">
                Статус согласования
            </Heading>
            <Badge colorScheme={color} fontSize="0.8em">
                {text}
            </Badge>
        </Stack>
    );
}
