import React, { FC, useEffect, useState } from 'react';
import { Divider, Heading, Stack, Link as ChakraLink, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { IOrder } from '../../../interfaces/orders';
import { IFeSite } from '../../../interfaces/works';
import { IEsign } from '../../../interfaces/esigns';

interface IProps {
    order: IOrder;
}

const OrderTsdRsd: FC<IProps> = ({ order }) => {
    const [tsds, setTsds] = useState<IEsign[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        axios
            .get(`fe-sites?work=${order.work.id}`)
            .then((res) => {
                if (res.data.length === 0) return;
                setTsds(res.data.filter((item: IFeSite) => item.tsd).map((item: IFeSite) => item.tsd));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            <Divider />
            <Stack my={2} alignItems="center">
                <Heading size="md">RSD</Heading>
                {(!order?.work?.rsd || order?.work?.rsd?.esigns?.length === 0) && <div>RSD не загружено</div>}
                {order?.work?.rsd?.esigns?.map((esign) => (
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        isExternal
                        href={`${process.env.REACT_APP_API_BASE_URL}${esign.doc_link}`}
                    >
                        {esign.file_name}
                    </ChakraLink>
                ))}
            </Stack>
            <Divider />
            <Stack my={2} mb={5} alignItems="center">
                <Heading size="md">TSD</Heading>
                {loading && <Spinner color="blue.500" />}
                {!loading && tsds.length === 0 && <div>TSD не загружено</div>}
                {tsds.map((tsd) => (
                    <ChakraLink
                        color="blue.600"
                        _hover={{ color: 'blue.600', textDecoration: 'underline' }}
                        isExternal
                        href={`${process.env.REACT_APP_API_BASE_URL}${tsd.doc_link}`}
                    >
                        {tsd.file_name}
                    </ChakraLink>
                ))}
            </Stack>
        </>
    );
};

export default OrderTsdRsd;
