import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, useToast } from '@chakra-ui/react';
import axios from 'axios';
import AuthHeading from 'parts/Auth/components/AuthHeading/AuthHeading';
import AuthDescription from 'parts/Auth/components/AuthDescription/AuthDescription';

const MailConfirm = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const OnMailConfirm = () => {
        axios
            .post(`/registration-requests/confirmEmail`, { token })
            .then(() => {
                toast({
                    status: 'success',
                    title: 'Почта успешно подтверждена, заявка отправлена на согласование руководителю'
                });

                navigate('/login');
            })
            .catch((error) => {
                toast({
                    status: 'error',
                    title: error.response.data.message || 'Произошла неизвестная ошибка'
                });
            });
    };

    /* Checks for token availability, if there is no token - navigate to /login */
    useEffect(() => {
        let navigateTimeout: null | NodeJS.Timeout = null;

        if (!token) {
            toast({
                status: 'error',
                title: 'Неверная ссылка подтверждение',
                description: 'Вы будете перенаправлены через 5 секунд'
            });

            navigateTimeout = setTimeout(() => {
                navigate('/login');
            }, 5000);
        }

        return () => {
            if (navigateTimeout) clearTimeout(navigateTimeout);
        };
    }, [token]);

    return (
        <>
            <AuthHeading>Регистрация нового пользователя</AuthHeading>
            <AuthDescription>Подтвердите вашу почту</AuthDescription>
            <Button colorScheme="twitter" onClick={OnMailConfirm}>
                Подтвердить
            </Button>
        </>
    );
};

export default MailConfirm;
