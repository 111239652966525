import moment from 'moment';

const downloadFile = async (fileUrl: string, fileName?: string) => {
    try {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute(
            'download',
            `${fileName || 'Экспорт_файла'}_за_${moment().format('DD.MM.YYYY_HH:mm')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading the file', error);
    }
};
export default downloadFile;
