/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Squash as Hamburger } from 'hamburger-react';
// @chakra-ui
import { Box, Fade, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { globalSelector, setIsSidebarOpened } from 'redux/reducers/globalSlice';
import { userSelector } from 'redux/reducers/userSlice';
//
import Tele2Logo from 'icons/Tele2Logo';
import { RiAdminFill } from 'react-icons/ri';
import navConfig from './NavConfig';

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector(userSelector);
    const { isSidebarOpened } = useSelector(globalSelector);

    useEffect(() => {
        dispatch(setIsSidebarOpened(false));
    }, []);

    return (
        <Box
            width={isSidebarOpened ? '260px' : '96px'}
            transition="all .2s ease-in-out;"
            height="100vh"
            filter="drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.06))"
            backgroundColor="white"
        >
            <Box
                display="flex"
                flexDir="column"
                margin={isSidebarOpened ? '26px 16px' : '26px 16px'}
                overflow="auto"
                overflowX="hidden"
                height="100vh"
                className="sidebar__content"
            >
                {/* Tele2 Logo */}
                <Box
                    display="flex"
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                    height="48px"
                >
                    <Icon as={Tele2Logo} width="60px" height="48px" />
                    <Fade in={isSidebarOpened} delay={0.13}>
                        <Text
                            ml="6px"
                            fontSize="22px"
                            fontWeight={700}
                            userSelect="none"
                            style={{ textWrap: 'nowrap' }}
                        >
                            CIP TRACKER
                        </Text>
                    </Fade>
                </Box>
                <Box
                    display="flex"
                    marginLeft={isSidebarOpened ? '-12px' : '0'}
                    transition="all .3s ease-in-out"
                    mb={1}
                    width="60px"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Hamburger
                        size={28}
                        toggled={isSidebarOpened}
                        toggle={(value) => dispatch(setIsSidebarOpened(value as boolean))}
                    />
                </Box>
                {/* Links */}
                <Stack spacing={2} className="sidebar">
                    {navConfig.map(
                        (group) =>
                            !group.cantViewAs?.includes(data.role!.name) && (
                                <Box key={group.subheader} display="flex" flexDirection="column">
                                    {data.role.name === 'Подрядная организация' &&
                                    group.subheader === 'Сайты' ? null : (
                                        <Text
                                            fontSize={16}
                                            color="#B9B9C3"
                                            fontWeight={600}
                                            userSelect="none"
                                        >
                                            {group.subheader}
                                        </Text>
                                    )}
                                    {group.items.map(
                                        (item) =>
                                            !item.cantViewAs?.includes(data.role!.name) && (
                                                <Tooltip
                                                    key={item.title}
                                                    label={item.title}
                                                    aria-label="A tooltip"
                                                    isDisabled={isSidebarOpened}
                                                >
                                                    <Box
                                                        onClick={() => navigate(item.path)}
                                                        className={`sidebar-item_${item.path.replace(
                                                            /\//g,
                                                            '_'
                                                        )}`}
                                                        key={item.title}
                                                        width="100%"
                                                        height="42px"
                                                        display="flex"
                                                        alignItems="center"
                                                        borderRadius={6}
                                                        backgroundColor="#efefef"
                                                        fontWeight={600}
                                                        cursor="pointer"
                                                        mt={1}
                                                        transition="all .3s ease-in-out"
                                                        _hover={{
                                                            backgroundColor: '#7c2eab',
                                                            color: 'white'
                                                        }}
                                                    >
                                                        <Icon
                                                            as={item.icon}
                                                            height="18px"
                                                            width="18px"
                                                            ml={isSidebarOpened ? 2 : '23px'}
                                                            transition="margin-left .3s ease-in-out"
                                                        />
                                                        <Fade in={isSidebarOpened} delay={0.13}>
                                                            <Text ml="6px" userSelect="none">
                                                                {item.title}
                                                            </Text>
                                                        </Fade>
                                                    </Box>
                                                </Tooltip>
                                            )
                                    )}
                                </Box>
                            )
                    )}
                    {data.isRegistrationApprover && (
                        <>
                            <Text fontSize={16} color="#B9B9C3" fontWeight={600} userSelect="none">
                                Администрирование
                            </Text>
                            <Box
                                onClick={() => navigate('/administration')}
                                key="Панель администрирования"
                                width="100%"
                                height="42px"
                                display="flex"
                                alignItems="center"
                                borderRadius={6}
                                backgroundColor="#efefef"
                                fontWeight={600}
                                cursor="pointer"
                                mt={1}
                                transition="all .3s ease-in-out"
                                _hover={{
                                    backgroundColor: '#7c2eab',
                                    color: 'white'
                                }}
                            >
                                <Icon
                                    as={RiAdminFill}
                                    height="18px"
                                    width="18px"
                                    ml={isSidebarOpened ? 2 : '23px'}
                                    transition="margin-left .3s ease-in-out"
                                />
                                <Fade in={isSidebarOpened} delay={0.13}>
                                    <Text ml="6px" userSelect="none">
                                        Панель администрирования
                                    </Text>
                                </Fade>
                            </Box>
                        </>
                    )}
                </Stack>
            </Box>
        </Box>
    );
};

export default Sidebar;
