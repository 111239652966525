import {
    Text,
    Modal as ChakraModal,
    Button as ChakraButton,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter
} from '@chakra-ui/react';

interface OrderModalWindowProps {
    onClose: () => void;
    onCloseMainForm: () => void;
    isOpen: boolean;
}

export default function OrderModalWindow({ onClose, isOpen, onCloseMainForm }: OrderModalWindowProps) {
    return (
        <ChakraModal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Предупреждение</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={1}>
                        По данному заказу возможны штрафные санкции. Продолжая работать вы даете согласие
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <ChakraButton colorScheme="purple" mr="auto" onClick={onClose}>
                        Продолжить
                    </ChakraButton>
                    <ChakraButton
                        colorScheme="red"
                        onClick={async () => {
                            onCloseMainForm();
                            onClose();
                        }}
                    >
                        Отмена
                    </ChakraButton>
                </ModalFooter>
            </ModalContent>
        </ChakraModal>
    );
}
