import { Avatar, AvatarBadge, Box, Button, Icon, Text, Tooltip } from '@chakra-ui/react';
import { Popover } from 'antd';
import { useRef } from 'react';
import { GoChevronLeft } from 'react-icons/go';
import { HiDownload } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { globalSelector } from 'redux/reducers/globalSlice';
import { clearUserData, userSelector } from 'redux/reducers/userSlice';
// import Garland from 'parts/Layout/Garland';
import { clearState } from '../../utils/localStorage';
import HasNotAccess from '../../guards/HasNotAccess';
import { ERoleNames } from '../../constants';

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logOutButtonRef = useRef();

    const { data } = useSelector(userSelector);
    const { currentHeaderTitle, isSidebarOpened } = useSelector(globalSelector);

    const goBack = () => {
        navigate(-1);
    };

    const logOut = () => {
        dispatch(clearUserData());
        clearState();
        navigate('/login');
    };

    return (
        <Box
            width={isSidebarOpened ? 'calc(100vw - 290px)' : 'calc(100vw - 126px)'}
            mr="auto"
            ml="auto"
            height="82px"
            transition="all .2s ease-in-out;"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
        >
            <Box
                width="100%"
                height="60px"
                display="flex"
                alignItems="center"
                filter="drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.06))"
                backgroundColor="white"
                borderRadius={10}
            >
                {/* <Garland /> */}
                {/* Go back */}
                <Box
                    ml={2}
                    display="flex"
                    width="36px"
                    height="36px"
                    backgroundColor="#7c2eab"
                    borderRadius={6}
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    transition="all .2s ease-in-out"
                    _hover={{
                        backgroundColor: '#652d86'
                    }}
                    onClick={goBack}
                >
                    <Icon as={GoChevronLeft} color="white" width="22px" height="22px" />
                </Box>
                {/* Page label */}
                <Text ml={2} fontSize="22px" fontWeight={700} userSelect="none">
                    {currentHeaderTitle}
                </Text>
                <Box ml="auto" />
                {/* Tasks */}
                {/* KCELL_DISABLED */}
                {/*  {role?.name !== 'Отдел планирования трансмиссионной сети' &&
                    role?.name !== 'Группа проектирования инфраструктуры сети' &&
                    role?.name !== 'Группа лицензии и разрешительных документов' &&
                    role?.name !== 'Отдел эксплуатации' &&
                    role?.name !== 'Отдел логистики' &&
                    role?.name !== 'Служба мониторинга контроля качества и эффективности сети' &&
                    role?.name !== 'Отдел планирования радиосети' &&
                    role?.name !== 'Отдел оптимизации сети' &&
                    role?.name !== 'Специалист по проектам' &&
                    role?.name !== 'Отдел сети радиодоступа - систем LI и дата центров' &&
                    role?.name !== 'Отдел договоров аренды' && (
                        <Tooltip label={`Количество активных задач на вас: ${tasksCount}`} hasArrow>
                            <Box
                                ml="auto"
                                mr={3}
                                display="flex"
                                mt="3px"
                                cursor="pointer"
                                onClick={() => navigate('/tasks')}
                            >
                                <Badge
                                    count={tasksCount}
                                    overflowCount={9}
                                    size="small"
                                    color={tasksCount === 0 ? 'green' : 'red'}
                                >
                                    <Icon as={FcParallelTasks} width="24px" height="24px" />
                                </Badge>
                            </Box>
                        </Tooltip>
                    )} */}

                <HasNotAccess roleNames={[ERoleNames.SUBC, ERoleNames.EXECUTOR]}>
                    <Tooltip label="Список ЭАП на экспорт" hasArrow>
                        <Box
                            display="flex"
                            mt="3px"
                            mr={3}
                            cursor="pointer"
                            onClick={() => navigate('/export-jobs')}
                        >
                            <Icon as={HiDownload} fill="#7c2eab" width="24px" height="24px" />
                        </Box>
                    </Tooltip>
                </HasNotAccess>
                {/* Current user */}
                <Popover
                    trigger="hover"
                    style={{ borderRadius: '10px' }}
                    className="logout-popover"
                    content={
                        <Box width="120px" display="flex" alignItems="center">
                            <Button
                                ref={logOutButtonRef as any}
                                width="100%"
                                colorScheme="purple"
                                onClick={logOut}
                                className="logout-btn"
                            >
                                Выйти
                            </Button>
                        </Box>
                    }
                >
                    <Box display="flex" mr={2} alignItems="center" cursor="pointer">
                        <Box display="flex" flexDir="column" mr={1}>
                            <Text ml="auto" fontSize={16}>
                                {data?.full_name || data?.username}
                            </Text>
                            <Text color="#B9B9C3" ml="auto" fontWeight={400}>
                                {data?.role?.name}
                            </Text>
                        </Box>
                        <Avatar width="40px" height="40px">
                            <AvatarBadge boxSize="1em" bg="green.500" />
                        </Avatar>
                    </Box>
                </Popover>
            </Box>
        </Box>
    );
};

export default Header;
