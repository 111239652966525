import { useFormikContext } from 'formik';
import React, { forwardRef } from 'react';

// @chakra-ui
import { Box, Skeleton, Switch, FormControl, FormLabel, Flex } from '@chakra-ui/react';
import SiteConfigurationForm, { NDS_PERCENTAGE } from './SiteConfigurationForm';
import { currencyFormat } from '../../../utils';

function SiteConfigurations(
    {
        disabled,
        urgent,
        neSiteConfigs,
        feSiteConfigs,
        contragent,
        discount,
        discountValue,
        priceRegion,
        treatyNum,
        sitename,
        isNewOrder,
        setConnectedSitenameHasChanged,
        connectedSitenameHasChanged
    }: any,
    ref: any
) {
    const { setFieldValue, errors } = useFormikContext<any>();

    // eslint-disable-next-line react/destructuring-assignment
    const { ref_ne, ref_fe } = ref.current;
    const onUrgentChange = () => {
        setFieldValue('isUrgentTask', !urgent);
    };

    return (
        <Box
            shadow="md"
            borderWidth="1px"
            mt={3}
            p={3}
            bg="white"
            rounded={8}
            position="relative"
            color={disabled ? '#6E6B7B' : 'auto'}
        >
            <FormControl display="flex" alignItems="center">
                <Switch isChecked={urgent} onChange={onUrgentChange} id="urgent-works" disabled={disabled} />
                <FormLabel
                    htmlFor="urgent-works"
                    marginBottom="0px"
                    marginInlineStart="0.75rem"
                    fontWeight="semibold"
                    fontSize="1rem"
                    color="gray.700"
                >
                    Срочные заказы
                </FormLabel>
            </FormControl>

            {neSiteConfigs ? (
                <Box>
                    {typeof errors.neSites === 'string' && (
                        <Box mt={2} color="red" fontSize="sm">
                            <Box>{errors.neSites}</Box>
                        </Box>
                    )}
                    <SiteConfigurationForm
                        title={`Сайт ${neSiteConfigs}`}
                        resource="neSites"
                        urgent={urgent}
                        ref={ref_ne}
                        disabled={disabled}
                        contragent={contragent}
                        discount={discount}
                        priceRegion={priceRegion}
                        treatyNum={treatyNum}
                        discountValue={discountValue}
                        sitename={sitename}
                        isNewOrder={isNewOrder}
                        setConnectedSitenameHasChanged={setConnectedSitenameHasChanged}
                        connectedSitenameHasChanged={connectedSitenameHasChanged}
                    />
                </Box>
            ) : (
                <Skeleton w="100%" h={2} colorScheme="teal" />
            )}
            {feSiteConfigs && (
                <SiteConfigurationForm
                    title={`Сайт(FE) ${feSiteConfigs}`}
                    resource="feSites"
                    priceRegion={priceRegion}
                    treatyNum={treatyNum}
                    urgent={urgent}
                    ref={ref_fe}
                    disabled={disabled}
                    contragent={contragent}
                    discount={discount}
                    discountValue={discountValue}
                />
            )}
            <Flex mt={2} p={1} pr={5} justifyContent="flex-end">
                <Box mr={3}>
                    <Box fontWeight="bold">ИТОГО с НДС</Box>
                    <Box fontWeight="bold" mt={1}>
                        В том числе НДС(12%)
                    </Box>
                </Box>
                <Box>
                    <Box>
                        {currencyFormat(
                            Number(
                                (
                                    (ref_ne.current.totalAmount + ref_fe.current.totalAmount) *
                                    (1 + NDS_PERCENTAGE)
                                ).toFixed(2)
                            )
                        )}
                    </Box>
                    <Box mt={1}>
                        {currencyFormat(
                            Number(
                                (
                                    (ref_ne.current.totalAmount + ref_fe.current.totalAmount) *
                                    NDS_PERCENTAGE
                                ).toFixed(2)
                            )
                        )}
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
}

export default forwardRef(SiteConfigurations);
