import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'redux/store';
import GlobalStyles from 'styles/global.styles';
import 'antd/dist/antd.min.css';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import { SWRConfig } from 'swr';
import moment from 'moment';
import 'moment/locale/ru';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import CIPTRoutes from 'Routes/Routes';
import { RatingProvider } from 'contexts/RatingContext';
import ActivityProvider from 'providers/ActivityProvider';
import axios from 'axios';
import axiosConfig from './utils/axiosConfig';
import Theme from './theme';

moment.locale('ru');

const rootElement = document.getElementById('root') as HTMLElement;

const Index = () => {
    axiosConfig();
    const toast = useToast();

    const onError = (error: Error) => {
        console.error({ error });
    };

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 403) {
                toast({
                    title: `Недостаточно прав - ${error.config.url.split('?')[0]}`,
                    description: 'Обратитесь на почту kcell-ciptracker.support'
                });
            }

            return Promise.reject(error);
        }
    );

    return (
        <ConfigProvider locale={ru_RU}>
            <ChakraProvider theme={Theme}>
                <GlobalStyles />
                <SWRConfig value={{ onError }}>
                    <Provider store={store}>
                        <RatingProvider>
                            <ActivityProvider>
                                <CIPTRoutes />
                            </ActivityProvider>
                        </RatingProvider>
                    </Provider>
                </SWRConfig>
            </ChakraProvider>
        </ConfigProvider>
    );
};

createRoot(rootElement).render(<Index />);
